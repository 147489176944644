import React from 'react';
import numeral from 'numeral';
import { BidirectionalBar } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';

import config from 'config/config';
import { PartnerDepartmentStatisticsDto } from 'types/dto/contracts-service';

type SeriesField = 'yField_1' | 'yField_2';
export interface Data {
  xField: string;
  yField_1: number;
  yField_2: number;
  raw: PartnerDepartmentStatisticsDto;
}
interface Props {
  data: Data[];
  currency: string;
}

const ByDepartmentChart = ({ data, currency }: Props) => {
  const { t } = useTranslation();

  return (
    <BidirectionalBar
      data={data}
      xField="xField"
      yField={['yField_1', 'yField_2']}
      height={350}
      xAxis={{
        position: 'bottom',
      }}
      legend={{
        layout: 'horizontal',
        position: 'top-left',
        itemName: {
          formatter: v => {
            return v === 'yField_1'
              ? t('dashboard_extend.byDepartment.claimPaymentSum')
              : t('dashboard_extend.byDepartment.contractPaymentSum');
          },
        },
      }}
      tooltip={{
        shared: true,
        showMarkers: false,
        formatter: (
          datum: Pick<Data, 'xField'> &
            Partial<Pick<Data, 'yField_1' | 'yField_2'>> & { 'series-field-key': SeriesField },
        ) => {
          const key = datum['series-field-key'];
          const value = datum[key] ?? 0;

          return {
            name:
              key === 'yField_1'
                ? t('dashboard_extend.byDepartment.claimPaymentSum')
                : t('dashboard_extend.byDepartment.contractPaymentSum'),
            value: `${numeral(value).format(config.format.financial)} ${currency}`,
          };
        },
      }}
      color={['#df92be', '#fec777']}
      interactions={[
        {
          type: 'active-region',
        },
      ]}
      state={{
        active: {
          style: {
            lineWidth: 'none',
          },
        },
      }}
    />
  );
};

const Memoized = React.memo(ByDepartmentChart);

export default Memoized;
