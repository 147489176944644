//libs
import { createTypes } from 'redux-compose-reducer';
import { notification } from 'antd';
//api
import { getInsuranceCompanies } from 'api/insuranceCompanies';
//types
import { Dispatch } from 'redux';
import { AppState } from '../reducer';
import { IPagination } from 'components/TableFooter/TableFooter';
import { ResultList, IInsuranceCompany } from 'types/insuranceCompany';
import { AllActions } from './actionTypes';
import { InsuranceCompanyView } from 'types/dto/contracts-service';
export const TYPES = createTypes('insuranceCompanies', ['setData', 'setLoading', 'setPagination']);

// action creators
export const setData = (data: InsuranceCompanyView[]): AllActions<InsuranceCompanyView[]> => ({
  type: TYPES.setData,
  payload: data,
});
export const setLoading = (loading: boolean): AllActions<boolean> => ({ type: TYPES.setLoading, payload: loading });
export const setPagination = (pagination: IPagination): AllActions<IPagination> => ({
  type: TYPES.setPagination,
  payload: pagination,
});

//thunks
export const fetch = t => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch(setLoading(true));
  const {
    insuranceCompanies: { pagination },
  } = getState();
  try {
    const {
      data: { resultList = [], count = 0 },
    } = await getInsuranceCompanies(pagination);

    if (!resultList.length) {
      notification.warning({
        message: t('popup.nothing_found'),
        description: t('popup.check_request'),
      });
    }

    dispatch(setData(resultList));
    dispatch(setPagination({ ...pagination, total: count }));
  } catch (err) {
    notification.error({
      message: t('popup.loading_insurance_companies'),
    });
  } finally {
    dispatch(setLoading(false));
  }
};
