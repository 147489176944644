import { withStyles } from '@material-ui/core';
import LoadingSpinnerBase from '../../../LoadingSpinner';

const LoadingSpinner = withStyles({
  root: {
    margin: '0 auto',
  },
  //@ts-ignore
})(LoadingSpinnerBase);

export default LoadingSpinner;
