import { createTypes } from 'redux-compose-reducer';
import * as API from 'api/insurancePrograms';
import { notification } from 'antd';
import { Dispatch } from 'redux';

export const TYPES = createTypes('insurance', ['setLoading', 'setProgramInfo', 'clearProgramInfo', 'setPartners']);

export const getProgramInfo = (id: number, t) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setLoading, payload: true });
  try {
    const response = await API.getProgramInfo(id);
    dispatch({ type: TYPES.setProgramInfo, payload: response.data });
  } catch (error) {
    console.error(error);
    notification.error({
      message: t('popup.error'),
      description: t('popup.error_downloading_information'),
    });
  }
  dispatch({ type: TYPES.setLoading, payload: false });
};

export const clearProgramInfo = () => dispatch => {
  dispatch({ type: TYPES.clearProgramInfo });
};

export const getProgramPartners = t => async (dispatch, getState) => {
  const id = getState().insuranceProgramDetails.data.code;

  try {
    const { data } = await API.getInsuranceProgramPartners(id);
    dispatch({ type: TYPES.setPartners, payload: data });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.error_loading_partners'),
    });
  }
};
