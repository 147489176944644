import { createTypes } from 'redux-compose-reducer';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { StoreType } from 'types/store';

import { notification } from 'antd';
import { invert } from 'lodash';

import {
  getTerminationInsuranceProgram,
  getInsuranceProgram,
  getInsuranceCompanies,
  // insuranceCompanies
} from 'api/statistics';
import * as InsuredEventsAPI from 'api/InsuredEvents';
import { AppState } from '../reducer';

import { resolutionTypes, revertStatusAdapter } from 'components/tables/InsuredEventsTable/helper';
import { baseTimes } from 'components/filters/InsuredEventsFilters/InsuredEventsFilters';
import { PREDEFINED_FILTERS } from 'screens/InsuredEvents/list/columns/utils';
import { makerRequestParams } from 'utils/helpers';
import { CHOOSE_FILTER_TYPE } from '../../components/filters/components/Select/options';

export const TYPES = createTypes('insuredEventsList', [
  'fetch',
  'setSorting',
  'setFilters',
  'setSelectedChoose',
  'setPending',
  'setActiveTab',
  'setPagination',
  'setPrivateFilters',
  'setInsuredEventsListPrivateFilters',
  'setFilteredActiveTab',
  'setTotalInformation',
  'setInsuranceCompanies',
  'setPartners',
  'getPrivateFilteredData',
  'setInsuredELInsuranceProgram',
]);

type ThunkResult = ThunkAction<void, StoreType, null, Action>;
type TFetch = (params?: any, t?) => ThunkResult;
type TSetPartners = (t, partnerId?: any, partnerDepartmentId?: any) => ThunkResult;
type TInsuranceCompanies = (t, id?: number | string) => ThunkResult;
type TSetInsuranceProgram = (t, id?: number | string) => ThunkResult;

interface setSorting {
  field: string;
}

const normalizeQueryFilters = (params: any) =>
  Object.keys(params).reduce((accum: any, key: string) => {
    accum[key.replace('[]', '')] = Array.isArray(params[key]) ? makerRequestParams(params[key]) : params[key];
    return accum;
  }, {});

export const fetch: TFetch = (params, t): ThunkResult => async (dispatch, getState) => {
  dispatch(setPending(true));
  const { EVENT_DATE, APPLICATION_DATE, PAYMENT_DATE } = CHOOSE_FILTER_TYPE;
  let normalizedParams = params;

  if (params && typeof params === 'object') normalizedParams = normalizeQueryFilters(params);

  if (normalizedParams?.claimStatus) {
    normalizedParams.claimStatus = normalizedParams.claimStatus
      .split('|')
      .map(el => revertStatusAdapter[el])
      .join('|');
  }

  if (normalizedParams?.resolutionType) {
    normalizedParams.resolutionType = normalizedParams.resolutionType
      .split('|')
      .map(el => invert(resolutionTypes)[el])
      .join('|');
  }

  const {
    user: { authentication },
    insuredEventsList: {
      filters: { insuranceCompanyId, insuranceProgramId, insurancePartnerId, claimDate, ...rest },
      chooseType: { chooseFilterName },
      pagination: { perPage, current },
      sorting,
      activeTab,
    },
  }: AppState = getState();

  let selectedFilterName = chooseFilterName ? chooseFilterName : t(APPLICATION_DATE);

  let [startDate, endDate] = claimDate.split('..');

  const insuranceCompanyIdForQuery = authentication?.tenant ? authentication?.tenant.split('/')[0] : null;

  const query: any = {
    ...rest,
    partnerId: insurancePartnerId.id !== 0 ? insurancePartnerId.id : null,
    insuranceCompanyId: insuranceCompanyId.id || insuranceCompanyIdForQuery,
    insuranceProgramId: insuranceProgramId.id || null,
    ...PREDEFINED_FILTERS[activeTab!],
    ...normalizedParams,
    page: current,
    page_size: perPage,
  };

  switch (selectedFilterName) {
    case t(PAYMENT_DATE): {
      query.paymentDate = `${startDate}..${endDate}`;
      break;
    }
    case t(EVENT_DATE): {
      query.incidentDate = `${startDate}..${endDate}`;
      break;
    }
    case t(APPLICATION_DATE): {
      startDate += baseTimes.startTime;
      endDate += baseTimes.endTime;
      query.claimDate = `${startDate}..${endDate}`;
      break;
    }
  }

  if (sorting) {
    query.sorting =
      selectedFilterName === t(PAYMENT_DATE)
        ? '-paymentDate'
        : selectedFilterName === t(EVENT_DATE)
        ? '-incidentDate'
        : sorting.field;
  }
  const { page, page_size, ...privateFilters } = query;

  dispatch(
    setPrivateFilters({
      ...privateFilters,
      sorting: '-claimDate',
    }),
  );

  try {
    const {
      data: { resultList, count, total },
    } = await InsuredEventsAPI.fetch(query);

    dispatch(setPending(false));
    dispatch({ type: TYPES.fetch, data: resultList, total: count });
    dispatch({ type: TYPES.setTotalInformation, payload: total });
  } catch (err) {
    dispatch(setPending(false));
    notification.error({
      message: t('popup.error_update_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setPrivateFilters = (privateFilters: any) => ({
  type: TYPES.setPrivateFilters,
  privateFilters,
});

export const setInsuredEventsListPrivateFilters = (privateFiltersItems: any) => ({
  type: TYPES.setInsuredEventsListPrivateFilters,
  privateFiltersItems,
});

export const setFilteredActiveTab = (filteredActiveTab: any) => ({
  type: TYPES.setFilteredActiveTab,
  filteredActiveTab,
});

// data in privat filter modal
export const getFilteredPrivateData = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    insuredEventsList: { sorting, filters },
  }: AppState = getState();

  try {
    const response = await InsuredEventsAPI.getFilteredContracts({
      filters: {
        ...filters,
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-incidentDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
    });
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.getPrivateFilteredData, payload });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setSorting = (sorting: setSorting) => ({ type: TYPES.setSorting, ...sorting });
export const setPagination = (page: number, perPage: number) => ({
  type: TYPES.setPagination,
  page,
  perPage,
});

export const setFilters = (filters = {}) => ({ type: TYPES.setFilters, filters });

export const setSelectedChoose = (chooseType = {}) => ({ type: TYPES.setSelectedChoose, chooseType });

export const setPending = (pending: boolean) => ({ type: TYPES.setPending, pending });
export const setActiveTab = (activeTab: boolean) => ({ type: TYPES.setActiveTab, activeTab });

export const setInsuranceCompanies: TInsuranceCompanies = (t, id?: number): ThunkResult => async (
  dispatch,
  getState,
) => {
  dispatch(setPending(true));

  try {
    const {
      insuredEventsList: { insurancePrograms },
      user: { authentication },
    }: AppState = getState();

    const insuranceConpanyId = id || Number(authentication?.tenant);

    const response = (await getInsuranceCompanies(insuranceConpanyId)).data.resultList || [];

    dispatch({
      type: TYPES.setInsuranceCompanies,
      payload: { insuranceCompanies: response, insurancePrograms },
    });
    dispatch(setPending(false));
    dispatch(fetch({}, t));
  } catch (error) {
    dispatch(setPending(false));

    notification.error({
      message: t('popup.updating_company'),
      description: t('popup.please_try_again_later'),
    });
  }
};

export const setPartners: TSetPartners = (t, partnerId?: any, insuranceCompanyId?: any): ThunkResult => async (
  dispatch,
  getState,
) => {
  try {
    const {
      insuredEventsList: { insurancePrograms },
    }: AppState = getState();

    const response = (await getTerminationInsuranceProgram(partnerId, insuranceCompanyId)).data || [];

    dispatch({
      type: TYPES.setPartners,
      payload: { partners: response, insurancePrograms },
    });
  } catch (error) {
    notification.error({
      message: t('popup.updating_company'),
      description: t('popup.please_try_again_later'),
    });
  }
};

export const setInsuredELInsuranceProgram: TSetInsuranceProgram = (t, id?: number): ThunkResult => async (
  dispatch,
  getState,
) => {
  try {
    const programs = (await getInsuranceProgram(id)).data.resultList || [];

    dispatch({ type: TYPES.setInsuredELInsuranceProgram, payload: { insurancePrograms: programs } });
  } catch (error) {
    notification.error({
      message: t('popup.updating_program'),
      description: t('popup.try_again_products'),
    });
  }
};
