import createInstance from './axios';
import { IPagination } from '../types/brands';
import { BrandDto, ObjectSubtypeDto, ResultListDtoBrandView } from 'types/dto/contracts-service';

const api = createInstance('CONTRACTS');

export const deleteBrand = (id: number) => {
  return api.delete<void>(`/insurance-objects/brands/${id}`);
};

export const addBrand = (brandName: string, typeValue: string, subType: string) => {
  return api.post<BrandDto>(`/insurance-objects/brands`, {
    brand: brandName,
    type: typeValue,
    subtype: subType,
  });
};

export const getBrands = (pagination: IPagination, brandName: string = '', type: string) => {
  let requestParams = {
    brand: brandName,
    page: pagination.current,
    page_size: pagination.perPage,
    type,
  };

  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return api.get<ResultListDtoBrandView>(`/insurance-objects/brands/?count&`, {
    params: {
      ...requestParams,
    },
  });
};

export const editBrand = (id: number, brand: string, type: string, subType: string) => {
  return api.put<BrandDto>(`/insurance-objects/brands/${id}`, {
    brand: brand,
    type: type,
    subtype: subType,
  });
};

export const getBrandsTypes = async (insuranceObjectType: string) =>
  api.get<ObjectSubtypeDto[]>(`/insurance-objects/types-subtypes`, {
    params: {
      objectType: insuranceObjectType,
    },
  });
