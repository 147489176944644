import React from 'react';
import { Dayjs } from 'dayjs';
import { PickerDateProps } from 'antd-modern/es/date-picker/generatePicker';
import { css } from '@emotion/css';
import 'antd-modern/lib/date-picker/style/index';
import i18next from 'i18next';

// import { isDate } from 'utils/validators';
import { PickerAdapter } from 'utils/dayjsAdapter';

import InputContainer, { Margin, Info } from '../InputContainer/InputContainer';
import locales from 'config/locales';
import { Language } from 'types';

const cssRoot = css`
  && {
    width: 100%;
    border-radius: 12px;
    border: none;
    box-shadow: none;
  }
`;

type Props = PickerDateProps<Dayjs> & {
  label?: string;
  margin?: Margin;
  info?: Info;
  classNameContainer?: string;
};

export const DatePicker = ({ label, value, classNameContainer, margin, info, ...props }: Props) => {
  return (
    <InputContainer info={info} label={label} className={classNameContainer} margin={margin}>
      <PickerAdapter
        data-test={info?.name}
        className={cssRoot}
        locale={locales[i18next.language as Language].antd.DatePicker}
        // value={isDate(value) ? dayjs(value) : null}
        getPopupContainer={trigger => trigger}
        {...props}
      />
    </InputContainer>
  );
};

export const MonthPicker = ({ label, value, classNameContainer, margin, info, ...props }: Props) => {
  return (
    <InputContainer info={info} label={label} className={classNameContainer} margin={margin}>
      <PickerAdapter.MonthPicker
        data-test={info?.name}
        className={cssRoot}
        locale={locales[i18next.language as Language].antd.DatePicker}
        // value={isDate(value) ? dayjs(value) : null}
        getPopupContainer={trigger => trigger}
        {...props}
      />
    </InputContainer>
  );
};

export default DatePicker;
