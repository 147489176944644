import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CloseIcon } from 'components/Icons';
import Input from 'components/uikit/Input';
import Button from 'components/Button/Button';
import { ISearchBrand } from './interfaces/SearchBrand.interfaces';
import { setSearchValue, setPagination } from 'store/brands/actions';
import styles from './SearchBrand.module.less';
import { style, helperTextClasses } from './style';
import { useTranslation } from 'react-i18next';

const SearchBrand = ({ pagination }: ISearchBrand) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = style();
  const [brandValue, setBrandValue] = useState<string>('');

  const handleSubmit = () => {
    dispatch(setPagination(1, pagination.size));
    dispatch(setSearchValue(brandValue));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setBrandValue(value);
  };

  const resetValue = () => {
    setBrandValue('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapInput}>
        <Input
          className={classes.input}
          color="white"
          FormHelperTextProps={{
            classes: helperTextClasses(),
          }}
          fullWidth={true}
          value={brandValue}
          onChange={handleChange}
          label={t('brands_page.brand_name_search')}
        />
        <div className={styles.btn} onClick={resetValue}>
          <CloseIcon />
        </div>
      </div>
      <Button className={classes.button} onClick={handleSubmit} color={'primary'} size={'large'}>
        {t('brands_page.install_button')}
      </Button>
    </div>
  );
};

export default SearchBrand;
