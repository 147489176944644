import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SuccessPaymentPage } from './SuccessPayment';
import { useAsync } from '../../../hooks/useAsync';
import { getExternalContractData, getOrderData } from '../../../api/auth';
import { Spin } from 'antd';
import moment from 'moment';
import { DeniedPaymentPage } from './DeniedPayment';
import { PaymentResultDtoContractPaymentStatus } from 'types/dto/contracts-service';

interface IProps {
  externalId: number;
}

export const PaymentResultPage: React.FC<IProps> = ({ externalId }) => {
  const location = useLocation();
  const locationArr = location.pathname.split('/');
  const orderId = locationArr[locationArr.length - 1];
  const { doFetch: getOrder, status: orderStatus, value: orderData } = useAsync(getOrderData);
  const { doFetch: fetchEternalContract, value: contract } = useAsync(getExternalContractData);

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (locationArr[3]) {
      fetchEternalContract(locationArr[3]);
    }
  }, [locationArr[3]]);

  return (
    <>
      {orderData?.data.contractPaymentStatus === PaymentResultDtoContractPaymentStatus.FAILURE && (
        <DeniedPaymentPage orderData={orderData} />
      )}
      {orderStatus === 'pending' && <Spin />}
      {orderData?.data.contractPaymentStatus === PaymentResultDtoContractPaymentStatus.SUCCESS && (
        <SuccessPaymentPage
          contractNumber={contract?.data?.contractNumber}
          startDate={moment(contract?.data?.signDate).format('DD.MM.YYYY')}
          phoneNumber={contract?.data?.signatory.phoneNumber}
          externalId={locationArr[3]}
        />
      )}
    </>
  );
};
