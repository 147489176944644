import React, { FunctionComponent, useEffect, useState } from 'react';
import sign from '../../SignUp/SignUp.module.less';
import { Icon, Tooltip } from 'antd';
import CountDown from '../../../CountDown/CountDown';
import styles from '../../auth.module.less';
import { useTranslation } from 'react-i18next';

type THintCounter = {
  delay: number;
  onTimeEnd: () => void;
  step: number;
};

const HintCounter: FunctionComponent<THintCounter> = ({ delay: time, step, onTimeEnd }) => {
  const [delay, setDelayTime] = useState<number>(time);
  const { t } = useTranslation();
  let previousStep: number | null = null;

  useEffect(() => {
    if (step !== previousStep) {
      previousStep = step;
      setDelayTime(time);
    }
  }, [step]);

  return (
    <div className={sign.countDownContainer}>
      <Tooltip
        overlayClassName="tooltip_count"
        placement="bottomRight"
        title={
          <span>
            <b>{t('signup.warning')}</b> {t('signup.will_returned_previous_step')}
          </span>
        }
      >
        <Icon
          type="question-circle"
          className={styles.timerIcon}
          style={{ width: '20px', textAlign: 'right', height: '12px', color: '#F0F1F5' }}
          theme="filled"
        />
      </Tooltip>
      <CountDown
        delay={delay}
        onTick={() => setDelayTime(delay - 1000)}
        onComplete={() => {
          setDelayTime(time);
          onTimeEnd();
        }}
      />
    </div>
  );
};

export default HintCounter;
