import React from 'react';
import moment from 'moment';
import { notification } from 'antd';

import ContractDetailsTable from 'components/tables/ContractDetailsTables/ContractDetailsTable/ContractDetailsTable';
import PaymentsTable from 'components/tables/ContractDetailsTables/PaymentsTable/PaymentsTable';
import RepaymentsTable from 'components/tables/ContractDetailsTables/RepaymentsTable/RepaymentsTable';
import TerminationsTable from 'components/tables/ContractDetailsTables/TerminationsTable/TerminationsTable';
import SubAgreementsTable from 'components/tables/ContractDetailsTables/SubAgreementsTable/SubAgreementsTable';
import UserActivityTable from 'components/tables/ContractDetailsTables/UserActivityTable';

import { IContract } from 'types/contractDetails';
import { ROUTES } from 'constants/routes';
import ImportExportTable from 'components/tables/ContractDetailsTables/ImportExportTable/ImportExportTable';
import { JutloDetailsTable } from 'components/tables/ContractDetailsTables/JutloDetailsTable/JutloDetailsTable';
import _ from 'lodash';
import { getContractType } from 'utils/getContractType';

const getContractNumber = contract => {
  return `${contract.contractNumber.slice(0, contract.contractNumber.length - 3)}${contract.contractNumberEnding}`;
};

export const contractBreadcrumbs = (contract: IContract, contractNumber: string, t) => [
  {
    label: t('contract_details.home'),
    href: ROUTES.DASHBOARD.ROOT,
  },
  {
    label: t('contract_details.register_insurance_contracts'),
    href: ROUTES.CONTRACTS_LIST.ROOT,
  },
  {
    label: contract.id
      ? `${t('contract_details.insurance_contract')} №${contract.originalContractNumber || getContractNumber(contract)}`
      : t('contract_details.insurance_contract'),
    ...(contract?.originalContractId
      ? { href: `${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${contract.originalContractId}` }
      : {}),
  },
];

export const statusFormat = (status: string, startDate: string, t) => {
  switch (status) {
    case 'ACTIVE': {
      return t('contract_details.acting');
    }
    case 'PARTIALLY_PAID': {
      return t('contract_details.not_valid');
    }
    case 'DRAFT': {
      return t('contract_details.draft');
    }
    case 'FINISHED': {
      return t('contract_details.completed');
    }
    case 'PROLONGATED': {
      return t('contract_details.prolonged');
    }
    case 'PARTIALLY_ACTIVE_COVID':
    case 'PARTIALLY_ACTIVE':
      return `${t('contract_details.signed_action_will_begin')} ${moment(startDate).format('DD.MM.YYYY')}`;
  }
};

export enum ADD_TYPE {
  PROLONGATION = 'prolongation',
  SUBCONTRACT = 'subContract',
  CHANGE_PROG = 'changeProg',
}

export const tabsTables = (contract: IContract, savedRisks, isChangeProg, t, repaymentsDetails) => {
  const code = contract.insuranceProgram?.code || contract.insuranceProductCode;
  const risks =
    (savedRisks && !contract.id) || isChangeProg
      ? _.mergeWith(savedRisks, contract.objects[0].risks)
      : contract.contractObjects[0].risks;

  const clearRisks = (risks ?? []).filter(item => item.name);

  return [
    {
      title:
        code && code.includes('MM') ? t('contract_details.object_insurance') : t('contract_details.insurance_risks'),
      key: '1',
      component:
        code && code.includes('MM') ? (
          <JutloDetailsTable data={savedRisks ?? []} objects={contract.objects} />
        ) : (
          <ContractDetailsTable data={contract && clearRisks} keyStep="1" />
        ),
    },
    {
      title: t('contract_details.payments'),
      key: '2',
      component: <PaymentsTable contractId={contract.originalContractId || contract.id!} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.disbursement'),
      key: '3',
      component: <RepaymentsTable repaymentsDetails={repaymentsDetails} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.rupture'),
      key: '4',
      component: <TerminationsTable contractId={contract.id!} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.additional_agreements'),
      key: '5',
      component: (
        <SubAgreementsTable
          originalContractId={contract.originalContractId! || contract.id!}
          originalContractNumber={contract.originalContractNumber! || contract.contractNumber!}
        />
      ),
      disabled: !Number(contract.originalContractId! || contract.id!),
    },
    {
      title: t('contract_details.export_import'),
      key: '6',
      component: <ImportExportTable originalContractId={contract.originalContractId || contract.id || 0} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.user_activity'),
      key: '7',
      component: (
        <UserActivityTable
          conclusionDate={contract.conclusionDate}
          logContractId={contract.originalContractId || contract.id!}
        />
      ),
      disabled: !Number(contract.id!),
    },
  ];
};

export const successNotification = t => {
  notification.success({
    message: t('contract_details.offer_submitted_successfully'),
  });
};
export const errorNotification = t => {
  notification.error({
    message: t('contract_details.error_sending_offer'),
  });
};

export const getAffordableInsuranceProgram = contractTypeConfig => {
  const isMedicine = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['FD_A', 'FD_B'],
  });

  const isVZR = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['VZR'],
  });

  const isInternational = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MM_D', 'MED_A', 'MED_ZS'],
  });

  const isInternationalMyHome = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MED_A', 'MED_ZS'],
  });

  const isInternationalCheckMyHome = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MM_D'],
  });

  const vfAccidentMax = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['CV_VF_MAX'],
  });

  const isSpecialCitrus = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MZ_CI_C'],
  });

  const isCovide19Insk = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['CV_A_I', 'CV_B_I'],
  });

  const isMiniHousing = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MM_A', 'MM_B', 'MM_C'],
  });

  const isMiniCASCO = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MK_A_I', 'MK_B_I'],
  });

  return {
    isMedicine,
    isVZR,
    isInternational,
    isInternationalMyHome,
    isInternationalCheckMyHome,
    vfAccidentMax,
    isSpecialCitrus,
    isCovide19Insk,
    isMiniHousing,
    isMiniCASCO,
  };
};

export const getIfUserTypeNotCompany = (contract, userType) => {
  const userTypeInitialValue = (contract && contract.signatory && contract.signatory.type) || 'PERSON';
  const isUserTypeNotCompany = userType ? userType !== 'COMPANY' : userTypeInitialValue !== 'COMPANY';
  return isUserTypeNotCompany;
};

export const checkProlongationStatus = frequencyStatusValues => {
  const truthyStatuses = ['PAYMENT_REJECTED', 'ANNUL'];
  const falsyData = frequencyStatusValues.filter(
    frequencyStatusValue => !truthyStatuses.includes(frequencyStatusValue.claimStatus),
  );
  const falsyStatuses = falsyData.map(item => item.claimStatus);

  const trueSelected = frequencyStatusValues.some(item => truthyStatuses.includes(item.claimStatus));
  const falseSelected = frequencyStatusValues.some(item => falsyStatuses.includes(item.claimStatus));
  return trueSelected && !falseSelected;
};
