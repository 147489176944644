import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { actsListType } from 'types/actsList';
import { AnyAction } from 'redux';
import moment from 'moment';
import config from 'config';

const initialState: actsListType = {
  data: [],
  statistic: {
    reportCountContracts: '0',
    reportRecords: '0',
    reportSumInsurancePayments: '0',
    reportSumFee: '0',
    reportSumToPay: '0',
    reportSumPaid: '0',
    reportCountPaidContracts: '0',
    totalRecords: '0',
    totalSumInsurancePayments: '0',
    totalSumToPay: '0',
    totalSumFee: '0',
  },
  total: 0,
  pending: false,
  error: false,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: '',
    direction: '',
  },
  filters: {
    dateFrom: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}`,
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    actDate: `${moment().format('YYYY-MM-DD')}`,
    insuranceCompanyId: {
      id: null,
      name: null,
    },
    agentContractId: null,
    insuranceProgramId: {
      id: null,
      name: null,
    },
  },
  currentContract: {},
  companies: [],
  insuranceProgram: [],
  contracts: [],
  selectedContract: {
    id: null,
    contractNumber: null,
    contractDate: null,
  },
};

const setFilters = (state: actsListType, { payload }: AnyAction): actsListType => {
  return {
    ...state,
    filters: payload,
  };
};

const setCurrentContract = (state: actsListType, { payload }: AnyAction): actsListType => {
  return {
    ...state,
    currentContract: payload,
  };
};

const setTablePage = (state: actsListType, { payload, pending }: AnyAction): actsListType => {
  return {
    ...state,
    data: payload ? payload.acts : state.data,
    statistic: payload ? payload.statistic : state.statistic,
    total: payload ? payload.totalRecords : state.total,
    pending,
  };
};

const setPagination = (state: actsListType, { payload }: AnyAction): actsListType => {
  return {
    ...state,
    pagination: payload,
  };
};

const setSorting = (state: actsListType, { payload }: any): actsListType => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const setActsCompanies = (state: actsListType, { payload }: any): actsListType => ({
  ...state,
  filters: {
    ...state.filters,
    insuranceCompanyId:
      payload.companies[0] ||
      (state.companies && state.companies.length && state.companies[0]) ||
      state.filters.insuranceCompanyId,
    // insuranceProgramId: payload.insuranceProgram[0] || state.insuranceProgram[0] || state.filters.insuranceProgramId,
  },
  companies: payload.companies || state.companies,
  insuranceProgram: payload.insuranceProgram || state.insuranceProgram,
});

const setActsFiltersInsuranceProgram = (state: actsListType, { payload }: any): actsListType => ({
  ...state,
  insuranceProgram: payload.insuranceProgram || state.insuranceProgram,
});

const setActContracts = (state: actsListType, { payload }: any): actsListType => ({
  ...state,
  contracts: payload.contracts || state.contracts,
});

const setContractId = (state: actsListType, { payload }: any): actsListType => ({
  ...state,
  filters: {
    ...state.filters,
    agentContractId: payload.id || state.filters.agentContractId,
  },
});

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setFilters,
    setTablePage,
    setPagination,
    setSorting,
    setCurrentContract,
    setActsCompanies,
    setActContracts,
    setContractId,
    setActsFiltersInsuranceProgram,
  },
});
