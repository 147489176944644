import { createTypes } from 'redux-compose-reducer';
import * as API from 'api/InsuredEvents';
import { notification } from 'antd';
import { Dispatch } from 'redux';
import { annul, update, restore } from 'api/InsuredEvents';
import { create } from 'api/InsuredEvents';
import { AppState } from '../reducer';
import { IApplication, IInsuredEvent, IInsuredEventDocument } from '../../types/InsuredEvent';
import moment from 'moment';
import { fetch as insuredEventsListFetch } from 'store/insuredEventsList/actions';
import * as InsuredEventsAPI from 'api/InsuredEvents';
import { PREDEFINED_FILTERS } from 'screens/InsuredEvents/list/columns/utils';

export const TYPES = createTypes('insurance', [
  'save',
  'setLoading',
  'setInsuredEvent',
  'clearInsuredEvent',
  'setClientInfo',
  'setPaymentSumInfo',
  'setPaymentSum',
  'addNewAdditionalDeduction',
  'changeAdditionalDeduction',
  'setDocumentsInfo',
  'setDocumentReceiptDate',
  'addApplication',
  'setApplications',
  'annulInsurance',
]);

export const getInsuredEventInfo = (id: number, t) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setLoading, payload: true });
  try {
    const data = await API.getById(id);
    const { data: paymentSumInfo } = await API.getPaymentSumInfo(id);
    dispatch({ type: TYPES.setInsuredEvent, payload: data });
    dispatch({ type: TYPES.setPaymentSumInfo, payload: paymentSumInfo });
  } catch (error) {
    console.error(error);
    notification.error({
      message: t('popup.error'),
      description: t('popup.error_loading_information'),
    });
  }
  dispatch({ type: TYPES.setLoading, payload: false });
};

export const clearInsuredEventInfo = () => dispatch => {
  dispatch({ type: TYPES.clearInsuredEvent });
};

export const setInsuredEventInfo = (data: IInsuredEvent) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setInsuredEvent, payload: data });
};

export const setPaymentSum = (value: string) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setPaymentSum, payload: Number(value) });
};

export const addNewAdditionalDeduction = () => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.addNewAdditionalDeduction });
};

export const changeAdditionalDeduction = (index: number, data: any) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.changeAdditionalDeduction, payload: { index, data } });
};

export const updateDocumentsInfo = (id: number, documents: any, t) => async (dispatch: Dispatch) => {
  try {
    const { data } = await API.updateDocuments(id, documents);
    dispatch({ type: TYPES.setDocumentsInfo, payload: data });
  } catch (error) {
    notification.error({
      message: t('popup.getting_products'),
      description: t('popup.pdating_document_information'),
    });
  }
};

export const notifyClient = (id: number, cb: () => void, t) => async () => {
  try {
    await API.notifyClient(id);
    notification.success({
      message: t('popup.message_sent_successfully'),
    });
  } catch (error) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.message_not_sent'),
    });
  } finally {
    cb();
  }
};

export const setDocumentReceiptDate = (docCode: string) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setDocumentReceiptDate, payload: docCode });
};

export const setDocumentsInfo = (documents: IInsuredEventDocument[]) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setDocumentsInfo, payload: documents });
};

export const addApplication = () => async (dispatch: Dispatch, getState: () => AppState) => {
  const user = getState().user;

  const template = {
    id: null,
    role: user.role,
    applicantName: user.name,
    applicationDate: moment.utc().toDate(),
    details: null,
    description: null,
  };

  dispatch({ type: TYPES.addApplication, payload: template });
};

export const setApplications = (applications: IApplication[]) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setApplications, payload: applications });
};

export const save = t => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch({ type: TYPES.setLoading, payload: true });
  const {
    data: { paymentSumInfo, ...insuredEvent },
  } = getState().insurance;
  try {
    const { data: savedInsuredEvent } = insuredEvent.id
      ? await update(insuredEvent.id, insuredEvent)
      : await create(insuredEvent);
    const { data: savedPaymentSumInfo } = paymentSumInfo
      ? await API.savePaymentSumInfo(savedInsuredEvent.id, paymentSumInfo)
      : { data: paymentSumInfo };
    dispatch({ type: TYPES.setInsuredEvent, payload: { ...savedInsuredEvent, paymentSumInfo: savedPaymentSumInfo } });
  } catch (e) {
    if (e?.response?.data?.errors) {
      return e?.response?.data?.errors.forEach(error => {
        if (error.code === 'EXPRESS_PAYMENT_IMPOSSIBLE') {
          return notification.error({
            message: t('insured_events.express_payment_impossible'),
          });
        }
      });
    }

    console.error(e);
    notification.error({
      message: t('popup.save_error'),
    });
  }
  notification.success({
    message: t('popup.saved'),
    description: t('popup.insurance_information_updated'),
  });
  dispatch({ type: TYPES.setLoading, payload: false });
};

export const annulInsuranceEvent = (id, t) => async dispatch => {
  try {
    await annul(id);
    dispatch(getInsuredEventInfo(id, t));
  } catch (err) {
    notification.error({ message: t('popup.something_went_wrong') });
  }
};

export const restoreInsuranceEvent = (id, t) => async (dispatch: Dispatch, getState) => {
  const {
    insuredEventsList: {
      filters: { insuranceCompanyId, insuranceProgramId, ...rest },
      pagination: { perPage, current },
      sorting,
      activeTab,
    },
  }: AppState = getState();

  const query: any = {
    ...rest,
    partnerId: insuranceCompanyId.id,
    insuranceProgramId: insuranceProgramId.id || undefined,
    ...PREDEFINED_FILTERS[activeTab!],
    page: current,
    page_size: perPage,
  };

  if (sorting) {
    query.sorting = sorting.field;
  }

  try {
    const data = await restore(id);
    if (data.status === 200) {
      const {
        data: { resultList, count },
      } = await InsuredEventsAPI.fetch(query);
      dispatch({ type: insuredEventsListFetch(null, t), data: resultList, total: count });
    }
  } catch (err) {
    if (err?.response?.data?.errors) {
      return err.response.data.errors.forEach(er => {
        if (er.code === 'CANNOT_RESTORE_CONTRACT_CLAIM_CONTRACT_NOT_ACTIVE') {
          notification.error({ message: t('popup.operation_not_possible') });
        } else {
          notification.error({ message: t('popup.something_went_wrong') });
        }
      });
    }
  }
};

export const restoreInsuranceContractEvent = (id, t) => async (dispatch: Dispatch) => {
  try {
    const data = await restore(id);
    if (data.status === 200) {
      const { data: paymentSumInfo } = await API.getPaymentSumInfo(id);
      dispatch({ type: TYPES.setInsuredEvent, payload: data.data });
      dispatch({ type: TYPES.setPaymentSumInfo, payload: paymentSumInfo });
    }
  } catch (err) {
    if (err?.response?.data?.errors) {
      return err.response.data.errors.forEach(er => {
        if (er.code === 'CANNOT_RESTORE_CONTRACT_CLAIM_CONTRACT_NOT_ACTIVE') {
          notification.error({ message: t('popup.operation_not_possible') });
        } else {
          notification.error({ message: t('popup.something_went_wrong') });
        }
      });
    }
  }
};
