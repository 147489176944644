import React from 'react';
import moment from 'moment';
import { DatePicker, ConfigProvider } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getDatePickerLng } from 'utils/helpers';
import styles from './ActDetailsFilter.module.less';
import Input from '../components/Input/Input';
import { actDetailsSelector } from 'store/actDetails/selectors';
import { setActInfo } from 'store/actDetails/actions';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/reducer';

const ActDetailsFilter = () => {
  const dateFormat = 'DD.MM.YYYY';
  const { actInfo } = useSelector(actDetailsSelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDateChange = ({ actDate }: { actDate: string }) => {
    dispatch(setActInfo({ ...actInfo, actDate: moment(actDate).format('YYYY-MM-DD') }));
  };
  const currentLanguage = useSelector((store: AppState) => store.userCard.language);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
        <div className={styles.label}>{t('act_details.report_date')}</div>
        {actInfo && actInfo.actDate && (
          <ConfigProvider locale={getDatePickerLng(currentLanguage)}>
            <DatePicker
              disabled={actInfo ? !!actInfo.actSignDate : false}
              value={moment(actInfo.actDate) || moment()}
              allowClear={false}
              onChange={(m, dateString) => {
                const reversed = dateString
                  .split('.')
                  .reverse()
                  .join('-');
                handleDateChange({
                  actDate: reversed,
                });
              }}
              format={dateFormat}
              size="large"
              placeholder={t('act_details.choose_date')}
              className={styles.dateField}
            />
          </ConfigProvider>
        )}
      </div>
      <Input
        disabled={true}
        label={t('act_details.agency_company')}
        labelActive={(actInfo && actInfo.partnerName) || ''}
        value={(actInfo && actInfo.partnerName) || ''}
      />
      <Input
        disabled={true}
        showSearch
        label={t('act_details.agency_agreement')}
        labelActive={(actInfo && actInfo.agentContractNumber) || ''}
        value={(actInfo && actInfo.agentContractNumber) || ''}
      />
      <ConfigProvider locale={getDatePickerLng(currentLanguage)}>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>Від :</div>
          {actInfo && actInfo.agentContractDate && (
            <DatePicker
              value={moment(actInfo.agentContractDate) || moment()}
              allowClear={false}
              disabled={true}
              format={dateFormat}
              size="large"
              placeholder={t('act_details.choose_date')}
              className={styles.dateField}
            />
          )}
        </div>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('act_details.period_from')}</div>
          {actInfo && actInfo.dateFrom && (
            <DatePicker
              disabled={true}
              defaultValue={moment(actInfo.dateFrom)}
              allowClear={false}
              format={dateFormat}
              size="large"
              placeholder={t('act_details.choose_date')}
              className={styles.dateField}
            />
          )}
        </div>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('act_details.concluded_by')}</div>
          {actInfo && actInfo.dateTo && (
            <DatePicker
              disabled={true}
              defaultValue={moment(actInfo.dateTo)}
              allowClear={false}
              format={dateFormat}
              size="large"
              placeholder={t('act_details.choose_date')}
              className={styles.dateField}
            />
          )}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default ActDetailsFilter;
