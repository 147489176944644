import { IRegisterTypesOption } from 'types/registerTypes';

export enum TABS {
  insuranceType = 'insuranceType',
  InsuranceRisks = 'InsuranceRisks',
}

export const filtersDefaultValue = 'all';

export const firstOption = { value: 'Усі', name: 'Усі', id: filtersDefaultValue };

export const insuranceTypeOptions = ["Обов'язковий", 'Добровільний'];

export const getAllOptions = (options: { [key: string]: string | number }[]) => {
  return [{ ...firstOption }, ...options];
};

export const getSelectedValue = (name: string, options: IRegisterTypesOption[]) => {
  return options.find(option => {
    return option.name === name;
  });
};
