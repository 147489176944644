import React from 'react';
import { Menu as BaseMenu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { MenuProps } from 'antd/lib/menu';

export interface MenuItem extends MenuItemProps {
  id: string;
  children: React.ReactNode;
  label?: string;
}
interface Props extends MenuProps {
  menu: MenuItem[];
}

const Menu = ({ menu, ...props }: Props) => {
  return (
    <BaseMenu {...props}>
      {menu.map(({ children, id, ...menuProps }, i) => (
        <BaseMenu.Item key={id} {...menuProps}>
          {children}
        </BaseMenu.Item>
      ))}
    </BaseMenu>
  );
};

export default Menu;
