import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { checkPermissions, roleApproved } from 'roles/userCan';
import Page404 from '../components/Page404';

const PrivateRoute: React.FC<any> = ({ component: Component, user, role: [page, action], ...rest }) => {
  if (localStorage.getItem('accessToken') && roleApproved()) {
    const notUserId = page === 'USER_CARD' && +rest?.computedMatch?.params?.id !== +user?.userId;
    if (checkPermissions(page, action) && !notUserId) {
      return <Route {...rest} component={Component} />;
    }
    return <Route {...rest} component={Page404} />;
  }
  return <Redirect to={ROUTES.AUTH.LOGIN} />;
};

export default PrivateRoute;
