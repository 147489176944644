import React from 'react';
import PageHeader from 'components/PageHeader';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import { ROUTES } from 'constants/routes';
import MedAppTable from './components/MedAppTable';
import Button from 'components/Button';
import Plus from 'components/Button/icons/Plus';
import { useTranslation } from 'react-i18next';
import MedAppFilters from './components/MedAppFilters';
import history from 'routes/history';

const MedApp = () => {
  const { t } = useTranslation();

  const leadToCompanyConfiguration = (id: number | string) => {
    return history.push(`${ROUTES.MEDAPP.DETAILS.ROOT.replace(':id', `${id}`)}`);
  };

  const handleCreate = () => {
    return history.push(ROUTES.MEDAPP.CREATE);
  };

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('partner_list.settings'), href: ROUTES.SETTINGS.ROOT },
          {
            label: t('medapp.header'),
          },
        ]}
      />
      <PageHeader title={t('medapp.header')}>
        <Button startIcon={<Plus />} onClick={handleCreate}>
          {t('medapp.add')}
        </Button>
      </PageHeader>
      <MedAppFilters />
      <MedAppTable leadToCompanyConfiguration={leadToCompanyConfiguration} />
    </>
  );
};

export default MedApp;
