import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.less';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { ConfigProvider } from 'antd';
import 'typeface-inter';
import 'translations/i18n';
import locales from 'config/locales';
import i18next from 'i18next';
import { Language } from 'types';
import numeral from 'numeral';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
numeral.localeData().delimiters.thousands = ' ';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider locale={locales[i18next.language as Language].antd}>
        <App />
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
