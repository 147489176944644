import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import InputField from 'components/next/InputField/InputField';
import Button from 'components/next/Button/Button';
import { useRequired } from 'hooks/useRequired';
import * as api from 'api/auth';
import { checkOnlineStatus } from 'utils/helpers';

import { State } from '../PasswordRecovery';
import { schema, defaultValues, Values } from './Reset.schema';

const cssSubmit = css`
  margin-top: 24px;
  width: 150px;
`;

type Props = {
  setState: React.Dispatch<Partial<State>>;
};

const Reset = ({ setState }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { required } = useRequired(schema);

  const onSubmit = (values: Values) => {
    setLoading(true);

    api
      .sendPasswordResetSmsUsingPost({ login: values.login })
      .then(() => {
        setState({ step: 'verification', login: values.login });
      })
      .catch(() => {
        reset();

        notification.error({
          message: checkOnlineStatus(t('passwordRecovery.account_not_found')),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test="password-recovery-reset-form">
      <Controller
        name="login"
        control={control}
        render={({ onChange, value, name }) => (
          <InputField
            info={{ name, errors, required }}
            value={value}
            onChange={onChange}
            label={t('passwordRecovery.your_login')}
          />
        )}
      />

      <Button type="primary" size="large" htmlType="submit" loading={loading} className={cssSubmit}>
        {t('passwordRecovery.continue')}
      </Button>
    </form>
  );
};

export default Reset;
