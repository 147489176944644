import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import history from 'routes/history';
import { notification } from 'antd';
import _ from 'lodash';

import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageHeader from 'components/PageHeader';
import { Grid } from '@material-ui/core';
import Pickers from './components/Pickers';
import Buttons from './components/Buttons';
import Form from './components/Form';
import { useForm } from './hooks';
import { ROUTES } from 'constants/routes';
import { Values } from 'types/appointments';
import { checkPermissions } from 'roles/userCan';
import { createAppoinment } from 'store/appointment/actions';
import { createAppoinmentDrEleks } from 'store/institutions/actions';
import { AppState } from 'store/reducer';
import { formatTime } from './utils';

import styles from './Appointments.module.less';

const Appointments: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createNewAppointment = (data, onSuccess, onError) => dispatch(createAppoinment(data, onSuccess, onError));
  const createNewAppoinmentDrEleks = (data, onSuccess, onError) =>
    dispatch(createAppoinmentDrEleks(data, onSuccess, onError));

  const [isDoctorEleks, setIsDoctorEleks] = useState(false);

  const { data, values, setField, errors, handleSubmit, clearError, defaultValues, setIsDoctorEleksForm } = useForm();

  const setIsDoctorEleksClinic = (idDrEleks: boolean) => {
    setIsDoctorEleks(idDrEleks);
    setIsDoctorEleksForm(idDrEleks);
    if (!idDrEleks) {
      clearError('type');
      clearError('serviceDrEleks');
      clearError('doctor');
      clearError('dateSlotDrEleks');
    }
  };

  const userId = useSelector((store: AppState) => store.user?.authentication?.userId);

  const onSuccess = () => {
    notification.success({
      message: t('appointments.success_message'),
    });
    history.push(ROUTES.DASHBOARD.ROOT);
  };

  const onError = text => {
    notification.error({
      message: text,
    });
  };

  const onSubmit = (values: Values) => {
    let formatedData = {};

    if (isDoctorEleks) {
      formatedData = {
        contractId: values.contract.contractId,
        institutionId: values.clinic.id,
        caseId: values.type.claimId ?? '',
        serviceRef: values.serviceDrEleks.serviceID,
        userRef: values.dateSlotDrEleks.userRef,
        startTime: values.dateSlotDrEleks.timeTableStartTime,
        endTime: values.dateSlotDrEleks.timeTableEndTime,
        venueRef: values.dateSlotDrEleks.venueRef,
        claims: values.reason,
      };

      createNewAppoinmentDrEleks(formatedData, onSuccess, onError);
    } else {
      formatedData = {
        ...values,
        userId,
        contractId: values.contract.contractId,
        clinicId: values.clinic.id,
        caseId: values.type.claimId ?? '',
        service: {
          serviceTypeId: values.service.parentId,
          serviceId: values.service.id,
          // isOnline: false
        },
        dateSlot: {
          date: moment(values.dateSlot.date).format('DD-MM-YYYY'),
          timeFrom: formatTime(values.dateSlot.time.start),
          timeTo: formatTime(values.dateSlot.time.end),
        },
      };

      formatedData = _.omit(formatedData, ['clinic', 'contract', 'attributes', 'type']);

      createNewAppointment(formatedData, onSuccess, onError);
    }
  };

  const disabledData = {
    contract: { disabled: false, next: '', clear: [] },
    clinic: {
      disabled: false,
      next: 'type',
      clear: ['type', 'service', 'serviceDrEleks', 'doctor', 'dateSlot', 'dateSlotDrEleks'],
    },
    type: {
      disabled: _.isEqual(values.clinic, defaultValues.clinic),
      next: 'service',
      clear: ['service', 'serviceDrEleks', 'doctor', 'dateSlot', 'dateSlotDrEleks'],
    },
    service: { disabled: _.isEqual(values.type, defaultValues.type), next: 'dateSlot', clear: ['dateSlot'] },
    serviceDrEleks: {
      disabled: _.isEqual(values.type, defaultValues.type),
      next: 'doctor',
      clear: ['dateSlot', 'doctor', 'dateSlotDrEleks'],
    },
    doctor: {
      disabled: _.isEqual(values.serviceDrEleks, defaultValues.serviceDrEleks),
      next: 'dateSlot',
      clear: ['dateSlot', 'dateSlotDrEleks'],
    },
    dateSlot: {
      disabled: _.isEqual(values.service, defaultValues.service),
      next: '',
      clear: [],
    },
    dateSlotDrEleks: {
      disabled: _.isEqual(values.doctor, defaultValues.doctor),
      next: '',
      clear: [],
    },
  };

  const setFieldValue = (fieldName, value) => {
    const picked = disabledData[fieldName];
    if (picked.next) {
      disabledData[picked.next].disabled = false;
    }

    setField(fieldName, value);

    picked.clear.forEach((el, i) => {
      setField(el, defaultValues[el]);
      if (i !== 0) {
        disabledData[el].disabled = true;
      }
    });
  };

  return (
    <>
      <PageBreadcrumbs
        data={
          checkPermissions('CHATS', 'MENU')
            ? [
                { label: t('appointments.home'), href: ROUTES.DASHBOARD.ROOT },
                { label: t('appointments.title'), href: ROUTES.CONSULTATIONS.ROOT },
                { label: t('appointments.origin_route') },
              ]
            : [
                { label: t('appointments.home'), href: ROUTES.DASHBOARD.ROOT },
                { label: t('appointments.origin_route') },
              ]
        }
      />
      <PageHeader title={t('appointments.origin_route')} />
      <Grid className={styles.grid} container spacing={4}>
        <Grid className={styles.gridChild} item xs={6}>
          <Pickers
            {...{
              values,
              errors,
              data,
              setField: setFieldValue,
              clearError,
              disabledData,
              isDoctorEleks,
              setIsDoctorEleksClinic,
            }}
          />
        </Grid>
        <Grid className={styles.gridChild} item xs={6}>
          <Form {...{ values, errors, setField, clearError, isDoctorEleks }} />
          <Buttons onSubmit={() => handleSubmit(onSubmit)} />
        </Grid>
      </Grid>
    </>
  );
};

export default Appointments;
