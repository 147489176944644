export const getInsuranceProgram = (state, payload) => {
  let program = (state.contract?.insuranceProgram?.id && state.contract.insuranceProgram) || '';

  if (!program) {
    payload?.insurancePrograms?.map(item => {
      if (item.code.includes('MZ_B')) {
        program = item;
      } else if (item.code.includes('CV_A')) {
        program = item;
      }
    });
  }
  if (!program) {
    program = payload?.insurancePrograms?.[0];
  }

  return program;
};

export const getContractNumberForAPEXSK = (
  prefixCheck: string,
  identifier: string,
  year: string,
  contractNumber?: string,
) => {
  const prefix = prefixCheck.includes('TT') ? prefixCheck.split('TT')[1] : prefixCheck;
  const contractNumberForAPEXSK = `${prefix}/${identifier}/${year}${contractNumber || ''}`;
  return contractNumberForAPEXSK;
};
