import createInstance from './axios';
import { contractListType } from 'types/contractList';
import { makerRequestParams } from 'utils/helpers';
import { searchParamsType } from 'types/deviceSearch';
import moment from 'moment';
import momentTz from 'moment-timezone';
import axios, { AxiosResponse } from 'axios';
import { IContract, IUniqueEmei, IUniqueNumber, СontractInfoDto, ContractObjectsDto } from '../types/contractDetails';
import _ from 'lodash';
import { CONTRACT_TYPE } from 'components/DetailsCard/constants/Datails.constants';
import {
  AccountType,
  CurrentUserDto,
  ObjectSubtypeDto,
  ContractIntegrationHistoryDto,
  ContractDto,
  ContractConfigurationDto,
  WithdrawVerifyWarningsDto,
  ResultListDtoBrandView,
  OptionDto,
  AgentContractDto,
  InsuranceObjectDto,
  DeviceDetailsWrapperDto,
  CounterpartyDto,
  ResultListDtoContractPaymentView,
  ContractClaimDetailsDto,
  ContractTerminationDetailsDto,
  ResultListDtoContractView,
} from 'types/dto/contracts-service';
import { REST_Country } from 'types';
import { InsuranceProgramDetailsDto } from 'types/dto/configuration-service';

const contractsAPI = createInstance('CONTRACTS');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');
const contractsFilesAPI = createInstance('CONTRACTS', 'file');
const configApi = createInstance('CONFIGURATION');
const proxyApi = createInstance('PROXY');
const configService = createInstance('CONFIGURATION');

const ATTRIBUTES_REQUEST =
  'currencyCode,insuranceProgramName,originalContractNumber,status,number,phoneNumber,insuranceProductCode,clientName,insuranceObjectPid,insuranceObjectName,conclusionDate,startDate,endDate,insuranceAmount,paymentAmount,numberPayments,id,calculatedPaymentAmount,partnerDepartmentRegion,sellerName,sellerPhoneNumber,insuranceObjectType,verifiedAt,subAgreementNumber,subAgreementConclusionDate,participateInAgentAct,test';

interface ISendOffer {
  programId: number;
  partnerId?: number | string;
  contractId?: number;
  phoneNumber: string;
}

const _checkSubdivision = (subdivision: string) => {
  if (subdivision === 'test_units') return true;

  if (subdivision === 'all_except_test_units') return false;

  return null;
};

export const getCountries = () => {
  return axios.get<REST_Country[]>('https://restcountries.com/v2/all?fields=name');
};

export const getTablePage = ({ filters, sorting, pagination, privateFilterItems }: contractListType) => {
  const subdivisionType = filters.subdivisionId ? filters.subdivisionId.key : undefined;

  const privateFilters = JSON.parse(JSON.stringify(privateFilterItems)) || [];

  Object.keys(privateFilters).forEach(key => {
    if (privateFilters[key] === null || !privateFilters[key]) {
      delete privateFilters[key];
    } else {
      privateFilters[key] = makerRequestParams(privateFilters[key]);
    }
  });

  const otherFilters = {
    insuranceProgramId: filters.insuranceProgramId.id,
    partnerId: filters.insuranceCompanyId.partnerId,
    test: _checkSubdivision(subdivisionType),
    partnerDepartmentId: filters.subdivisionId ? filters.subdivisionId.id : null,
    insuranceCompanyId: filters.insuranceCompanyId.insuranceCompanyId,
  };

  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;

  const requestParams = {
    attributes: ATTRIBUTES_REQUEST,
    page: pagination.current,
    page_size: pagination.perPage,
    phoneNumber: filters.phoneNumber,
    conclusionDate: `${filters.dateFrom}..${moment(filters.dateTo).format('YYYY-MM-DD')}`,
    sorting: sortingQuery,
    distinct: true,
    ...otherFilters,
    ...privateFilters,
  };

  //@ts-ignore
  Object.keys(requestParams).forEach(key => {
    if (requestParams[key] !== 'test' && typeof requestParams[key] !== 'boolean') {
      return !requestParams[key] && delete requestParams[key];
    }
  });

  return contractsAPI.get<ResultListDtoContractView>(
    '/contracts?count&total&totalAttributes=paymentAmount,calculatedPaymentAmount',
    {
      params: {
        ...requestParams,
        current: true,
      },
    },
  );
};

export const getContracts = ({ ...params }) => {
  return contractsAPI.get<ResultListDtoContractView>('/contracts/?distinct', {
    params: {
      ...params,
      current: true,
      test: false,
    },
  });
};

export const getConfigs = (partnerId, insuranceProgramId) => {
  return contractsFilesAPI.get<ResultListDtoContractView>(
    `/contracts/documents/configurations?partnerId=${partnerId}&insuranceProgramId=${insuranceProgramId}`,
  );
};

export const finishContractsDocumentsUpload = (claimId: number): Promise<AxiosResponse<void>> => {
  return contractsFilesAPI.post(`/contracts/${claimId}/documents/finish-upload-files`);
};

export const documentUpload = async (claimId: number, documentTypeCode: String, formData: any) => {
  return contractsFilesAPI.post(`/contracts/${claimId}/documents/${documentTypeCode}/files`, formData);
};

// TODO fix type for axios response - temp types
export const getContractsDocumentsPreviewList = ({
  claimId,
  documentTypeCode,
  pageNumber,
}: {
  claimId: string;
  documentTypeCode: string;
  pageNumber: number;
  pageSize: number;
}): Promise<AxiosResponse<any>> => {
  return contractsFilesAPI.get(
    `/contracts/${claimId}/document-description/${documentTypeCode}/files?pageNumber=${pageNumber}&pageSize=10`,
  );
};

// TODO Add types
export const getDocumentUploadFileInfo = ({ claimId, documentTypeCode, fileKey }): Promise<AxiosResponse<any>> => {
  return contractsFilesAPI.get(`/contracts/${claimId}/documents/${documentTypeCode}/files/${fileKey}`, {
    responseType: 'arraybuffer',
  });
};

export const renameUploadFile = ({ claimId, documentTypeCode, fileKey, newName }) => {
  return contractsFilesAPI.put(`/contracts/${claimId}/documents/${documentTypeCode}/files/${fileKey}/name/${newName}`);
};

export const removeUploadFileFromAdditionalDocument = (
  claimId: string,
  documentTypeCode: string,
  fileKey,
): Promise<AxiosResponse<null>> => {
  return contractsFilesAPI.delete(`/contracts/${claimId}/documents/${documentTypeCode}/files/${fileKey}`);
};

export const downloadAllUploadFiels = ({ claimId, documentTypeCode }) => {
  return contractsFilesAPI.get(`/contracts/${claimId}/documents/${documentTypeCode}/zip`, {
    responseType: 'blob',
  });
};

export const documentDelete = async (id: number | string, documentTypeCode: String) => {
  return contractsFilesAPI.delete(`/contracts/${id}/documents/${documentTypeCode}/files`);
};

export const getSubAgreements = ({ ...params }) => {
  return contractsAPI.get<ResultListDtoContractView>('/contracts/sub-agreements/', {
    params: {
      ...params,
    },
  });
};

export interface DownloadAsXLS {
  dateFrom: string;
  dateTo: string;
  insuranceProgramId: number;
  partnerId: number;
  phoneNumber: string;
  partnerDepartmentId: string;
  current?: boolean;
  status?: string;
  insuranceCompanyId?: string;
  sorting?: any;
  privateFilterItems?: Record<string, any[]>;
}
export const downloadAsXLS = async ({
  dateFrom,
  dateTo,
  insuranceProgramId,
  partnerId,
  phoneNumber,
  partnerDepartmentId,
  current,
  status,
  insuranceCompanyId,
  sorting,
  privateFilterItems,
}: DownloadAsXLS) => {
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;
  const privateFilters = Object.entries(privateFilterItems ?? {}).reduce((acc, [key, value]) => {
    acc[key] = value.join('|');

    return acc;
  }, {});
  const timeZone = momentTz.tz.guess(true);

  const params = {
    current: current ?? undefined,
    insuranceProgramId,
    partnerId,
    conclusionDate: `${dateFrom}..${moment(dateTo).format('YYYY-MM-DD')}`,
    sorting: sortingQuery,
    phoneNumber: phoneNumber ?? undefined,
    partnerDepartmentId,
    distinct: true,
    status: status ?? undefined,
    insuranceCompanyId,
    timeZone,
    ...privateFilters,
  };

  return contractsAPI.get('/contracts/print/xlsx', { responseType: 'arraybuffer', params });
};

export const getPrivateFiltersTable = ({
  filters,
  pagination,
}: {
  filters: any;
  pagination: { current: number | string; perPage: number | string };
}) => {
  const subdivisionType = filters.subdivisionId?.key ? filters.subdivisionId.key : undefined;

  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  return contractsAPI.get<ResultListDtoContractView>(
    '/contracts?count&total&totalAttributes=paymentAmount,calculatedPaymentAmount',
    {
      params: {
        attributes: ATTRIBUTES_REQUEST,
        ..._.omit(filters, ['contractNumber', 'subdivisionId']),
        page_size: pagination.perPage,
        page: pagination.current,
        distinct: true,
        current: true,
        test: _checkSubdivision(subdivisionType),
      },
    },
  );
};

export const getContractDetails = async (id: number) => contractsAPI.get<ContractDto>(`/contracts/${id}`);

export const getTerminationsDetailsTable = async (id: number) =>
  contractsAPI.get<ContractTerminationDetailsDto>(`/contracts/${id}/termination-details`);

export const getRepaymentsDetailsTable = async (id: number, params?) =>
  contractsAPI.get<ContractClaimDetailsDto[]>(`/contracts/${id}/claim-details`, { params: { ...params } });

export const getPaymentDetailsTable = async (contractId, params?: any) =>
  contractsAPI.get<ResultListDtoContractPaymentView>(
    `/contract-payments/payments?count&total&sorting=expectedPaymentDate,id`,
    {
      params: {
        contractId: contractId,
        totalAttributes: 'expectedPaymentAmount,paymentAmount,leftToPay',
        attributes:
          'id,periodStartDate,periodEndDate,expectedPaymentAmount,expectedPaymentDate,paymentAmount,paymentDate,checkNumber,leftToPay,contractId,insuranceCompanyId,partnerId,partnerDepartmentId',
        ...params,
      },
    },
  );

export const getTablePageData = (contractId, { filters, sorting, pagination }) => {
  const otherFilters = {
    ...filters.insuranceCompanyId,
  };
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;
  if (otherFilters) delete otherFilters.name;
  const requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    sorting: sortingQuery,
    distinct: true,
    ...otherFilters,
  };
  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return contractsAPI.get<ResultListDtoContractPaymentView>('/contract-payments/payments?count&total', {
    params: {
      contractId: contractId,
      totalAttributes: 'expectedPaymentAmount,paymentAmount,leftToPay',
      attributes:
        'id,periodStartDate,periodEndDate,expectedPaymentAmount,expectedPaymentDate,paymentAmount,paymentDate,checkNumber,leftToPay,contractId,insuranceCompanyId,partnerId,partnerDepartmentId',
      ...requestParams,
    },
  });
};

export const getInsuranceProgramRisks = async (id: number) => {
  return configApi.get(`/insurance-programs/${id}/risks`);
};

export const getInsuranceProgramData = async (id: number) => {
  return contractsAPI.get(`/insurance-programs/${id}`);
};

export const getInstallmentTypes = async (id: number, insuranceSum: number, duration: number, durationUnit: string) =>
  configApi.get(`/insurance-programs/${id}/installment-payment-types`, {
    params: {
      contractInsuranceSum: insuranceSum,
      duration,
      durationUnit,
    },
  });

export const getInsuranceDetailsProgram = async (
  type: string,
  partnerId?: number | undefined,
  singDate?: string,
  initialDurationId?: number,
) => {
  const nowDate = moment().format('YYYY-MM-DD');
  const period = singDate ? moment(singDate).format('YYYY-MM-DD') : nowDate;
  const api = configApi;
  return api.get<InsuranceProgramDetailsDto[]>(
    `/insurance-programs/details?insuranceProductCode=${
      type ? type : CONTRACT_TYPE.MOBI
    }&partnerProgramActivationDate=<=${period}&partnerProgramDeactivationDate=>=${period}&visible=true${
      partnerId ? `&partnerId=${partnerId}` : ''
    }&applicablePeriodFrom=<=${period}&applicablePeriodTo=>${period}&distinct&prolongateFromId=${null}`,
  );
};

export const getInsurancePrograms = async (type?: string) =>
  contractsAPI.get(
    `/insurance-programs?attributes=id,name&distinct&insuranceProductCode=${type ? type : CONTRACT_TYPE.MOBI}`,
  );

export const getInsuranceObjectSubTypes = async (id: number) =>
  contractsAPI.get<OptionDto[]>(`/insurance-objects/subtypes`, {
    params: { insuranceProgramId: id },
  });

export const addContractDetails = async (contract: any) =>
  contractsAPI.post(`/contracts`, {
    ...contract,
  });

export const updateContractDetails = async (contract: any, contractId: any) =>
  contractsAPI.put(`/contracts/${contractId}`, {
    ...contract,
  });

export const getCounterParties = async (phoneNumber: string) => {
  return contractsAPI.get<CounterpartyDto[]>('/counterparties/search', {
    params: { phoneNumber },
  });
};

export const getImeiData = async (imei: string) => {
  return contractsAPI.get<DeviceDetailsWrapperDto>(`/device-details/imei/${imei}`);
};

export const getUniqueImeiData = async (data: IUniqueEmei): Promise<AxiosResponse<any>> => {
  return data.imei
    ? contractsAPI.post(`/insurance-objects/electronic-devices/imei/validation`, { ...data })
    : contractsAPI.post('/insurance-objects/vehicles/vin-number/validation', { ...data });
};

export const getUniqueNumber = async (data: IUniqueNumber): Promise<AxiosResponse<any>> => {
  return contractsAPI.post('/insurance-objects/vehicles/registration-number/validation', { ...data });
};

export const getDataCalculation = async (
  insuranceProgramId: number,
  saleSum: number,
  duration: number | string,
  partnerId: number,
  objectsQuantity?: number,
  isProlog?: boolean,
  originalContractId?: number,
  contractObjects?: ContractObjectsDto[],
  durationUnit?: string,
  insurancePolicyId?: number,
  contractDate?: string,
  prolongatedFromInsuranceRateId?: number,
  installmentPaymentPeriodType?: string,
  contractInfo?: СontractInfoDto,
  insuranceSumId?: number,
) => {
  return contractsAPI.post('/contracts/calculations', {
    insuranceProgramId,
    saleSum: saleSum ? saleSum : undefined,
    duration,
    durationUnit: durationUnit ? durationUnit : 'MONTHS',
    prolongation: isProlog,
    contractId: isProlog ? originalContractId : undefined,
    partnerId,
    objectsQuantity,
    contractObjects,
    insurancePolicyId,
    contractDate,
    prolongatedFromInsuranceRateId,
    installmentPaymentPeriodType,
    contractInfo,
    insuranceSumId,
  });
};

export const searchContracts = async (data?: searchParamsType) => {
  const requestParams = data || {};

  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return contractsAPI.get<InsuranceObjectDto[]>('insurance-objects', {
    // default values on backend: page: 1, page_size: 50
    params: {
      ...requestParams,
      current: true,
    },
  });
};

export const getTermninationAccountTypes = (initiatorCode?: string | number | undefined) =>
  contractsAPI.get<AccountType[]>(`/contract-terminations/account-types${initiatorCode ? `/${initiatorCode}` : ''}`);

export const getTermninationAccountTypesForCreation = (insuranceProgramId: number, terminationInitiatorId: number) =>
  configService.get<AccountType[]>(
    `/termination-configurations/account-types?insuranceProgramId=${insuranceProgramId}&terminationInitiatorId=${terminationInitiatorId}`,
  );

export const getInsuredEventAccountTypes = () => contractsAPI.get<AccountType[]>(`/claims/account-types`);

export const getAgentContracts = (id: string) => {
  return contractsAPI.get<AgentContractDto[]>(`/agent-contracts?partnerId=${id}`);
};

export const getUsersInfo = () => {
  return contractsAPI.get<CurrentUserDto>('/users/current');
};

export const downloadContractPdf = (id: number | string) => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/contracts/${id}/pdf-report`, {
    params: {
      timeZone,
    },
  });
};

export const sendOffer = (id: number): Promise<AxiosResponse<void>> => {
  return contractsPdfAPI.post<void>(`/contracts/${id}/send-offer`);
};

export const sendEmptyOffer = ({ programId, partnerId, contractId, phoneNumber }: ISendOffer) => {
  if (contractId) {
    return contractsPdfAPI.post(`/insurance-programs/send-common-offer`, phoneNumber, {
      params: {
        insuranceProgramId: programId,
        partnerId,
        contractId,
      },
    });
  }
  return contractsPdfAPI.post(`/insurance-programs/send-common-offer`, phoneNumber, {
    params: {
      insuranceProgramId: programId,
      partnerId,
    },
  });
};

export const signContractClaim = async (id: number, token?: string, russianCitizen?: boolean) =>
  contractsAPI.post<void>(
    `contracts/${id}/sign?${russianCitizen !== undefined ? `russianCitizen=${russianCitizen}&` : ''}token=${token}`,
  );

export const sendSignVerification = async (id: number) => contractsAPI.post<void>(`contracts/${id}/sign-verification`);

export const getRisks = async (id: number) => contractsAPI.get<OptionDto[]>(`contracts/${id}/risks`);

export const printContract = async (id: number, lang?: string) => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/contracts/${id}/pdf-report${lang ? `?claimReportLanguage=${lang}` : ''}`, {
    params: {
      timeZone,
    },
  });
};

export const getBrandsList = (brand?: string, type?: string) => {
  return contractsAPI.get<ResultListDtoBrandView>('/insurance-objects/brands', { params: { brand, type } });
};

export const withdrawSign = (id: number) => {
  return contractsAPI.post<void>(`/contracts/${id}/withdraw-sign`);
};

export const withdrawCheck = (id: number | undefined) => {
  return contractsAPI.post<ContractDto>(`/contracts/${id}/withdraw-verify`);
};

export const withDrawCheckWarnings = (id: number | undefined) => {
  return contractsAPI.get<WithdrawVerifyWarningsDto>(`contracts/${id}/withdraw-verify/warnings`);
};

export const getContractNumberEnding = params => {
  return contractsAPI.post<ContractConfigurationDto>(`/contracts/configurations`, params);
};

export const sendReconciliation = (params: Partial<IContract>, contractId: number) => {
  return contractsAPI.post<ContractDto>(`/contracts/${contractId}/verify`, params);
};

export const getTransferHistories = (originalContractId: number | string): Promise<AxiosResponse<any>> => {
  return contractsAPI.get<ContractIntegrationHistoryDto[]>(`/contracts/${originalContractId}/integration-histories`, {
    params: {
      attributes: 'operationType',
    },
  });
};

export const getEstateTypes = () => {
  return contractsAPI.get<('APARTMENT' | 'PRIVATE_HOUSE' | 'COTTAGE' | 'TOWN_HOUSE')[]>(
    '/insurance-objects/estate-types',
  );
};

export const getEstateSubTypes = insuranceProgramId => {
  return contractsAPI.get<ObjectSubtypeDto[]>(`/insurance-objects/types-subtypes`, {
    params: {
      objectType: 'ESTATE',
      insuranceProgramId: insuranceProgramId,
    },
  });
};

export const generateContractNumber = (insuranceProductCode: string) => {
  return contractsAPI.get(`/contracts/generate-contract-number?insuranceProductCode=${insuranceProductCode}`, {
    responseType: 'text',
  });
};

export const onSendContractOnSign = (id: number) => {
  return contractsAPI.post<void>(`/contracts/${id}/send-client-on-sign`);
};

export const getUserContracts = async () => {
  const { data } = await proxyApi.get(`/contracts/insurance-objects?current=true`);
  return data;
};
