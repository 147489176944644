import { createTypes } from 'redux-compose-reducer';
import { AppState } from '../reducer';
import { ThunkAction } from 'redux-thunk';
import { StoreType } from 'types/store';
import { Action } from 'redux';
import { notification } from 'antd';
import { getTerminationInsuranceCompanies, getTerminationInsuranceProgram } from 'api/adminList';
import { getPrivateFiltersTable } from 'api/adminList';
import { AllActions, TSetPagination, TSetSorting, TPending, TCompanyResult, TInsuranceCompanies } from './actionTypes';
import { TFilters } from 'types/AdminTypes';
import onDownload from '../../callbacks/onDownload';
import * as usersAPI from 'api/userCard';
import moment from 'moment-timezone';
import { ResultListDtoAuthUserView } from 'types/dto/contracts-service';

export const TYPES = createTypes('adminList', [
  'setAdminList',
  'adminListState',
  'setFilters',
  'setPagination',
  'setSorting',
  'getPrivateFiltersData',
  'setCurrentCompanies',
  'setAdminListPrivateFilters',
  'setCurrentInsuranceCompanies',
]);

type ThunkResult = ThunkAction<void, StoreType, null, Action>;

// action creators
export const setFilters = (data: TFilters): AllActions<TFilters> => ({ type: TYPES.setFilters, payload: { ...data } });

export const setPagination = (current: number, perPage: number): AllActions<TSetPagination> => ({
  type: TYPES.setPagination,
  payload: { current, perPage },
});

export const setSorting = ({ field, direction }: TSetSorting): AllActions<TSetSorting> => ({
  type: TYPES.setSorting,
  payload: { field, direction },
});

export const setPending = (pending: boolean): AllActions<TPending> => ({
  type: TYPES.adminListState,
  payload: { pending },
});

export const setInsuranceCompaniesData = ({
  insuranceCompanies,
}: TInsuranceCompanies): AllActions<TInsuranceCompanies> => ({
  type: TYPES.setCurrentInsuranceCompanies,
  payload: { insuranceCompanies },
});

export const setCompaniesData = ({ companies }: TCompanyResult): AllActions<TCompanyResult> => ({
  type: TYPES.setCurrentCompanies,
  payload: { companies },
});

export const setResultData = ({
  resultList,
  count,
}: ResultListDtoAuthUserView): AllActions<ResultListDtoAuthUserView> => ({
  type: TYPES.getPrivateFiltersData,
  payload: { resultList, count },
});

// thunks
export const getFilteredUsers = (filtersInfo?: any, t?): ThunkResult => async (dispatch, getState) => {
  dispatch(setPending(true));

  const {
    adminList: {
      pagination,
      sorting,
      filters: {
        dateFrom,
        dateTo,
        insuranceCompanyId: { id: partnerId, insuranceCompanyId },
        departmentId: { id: partnerDepartmentId },
        username,
      },
    },
  }: AppState = getState();

  const convertFilters = {
    partnerDepartmentId,
    partnerId,
    username,
    insuranceCompanyId: !partnerId ? insuranceCompanyId : undefined,
    signupDate: dateFrom && dateTo && `${dateFrom}T00:00:00..${dateTo}T23:59:59`,
    ...filtersInfo,
  };

  try {
    dispatch(setPagination(pagination.current, pagination.perPage));
    const {
      data: { resultList = [], count = 0 },
    } = await getPrivateFiltersTable({ filters: { ...filtersInfo, ...convertFilters }, pagination, sorting });
    dispatch(setResultData({ resultList, count }));

    if (!resultList.length) {
      notification.warning({
        message: t('popup.nothing_found'),
        description: t('popup.check_request'),
      });
    }
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.admin_try_again_later'),
    });
  } finally {
    dispatch(setPending(false));
  }
};

export const setInsuranceCompanies = t => async (dispatch: any, getState: any) => {
  const {
    user: { authentication },
  }: AppState = getState();

  const insuranceCompanyId = authentication?.tenant || null;

  try {
    const { data } = await getTerminationInsuranceCompanies(insuranceCompanyId);

    dispatch(setInsuranceCompaniesData({ insuranceCompanies: data }));
  } catch (error) {
    notification.error({
      message: t('popup.error_update_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

export const setCompanies = (t, id?: number) => async (dispatch: any, getState: any) => {
  const {
    user: { authentication },
  }: AppState = getState();

  const insuranceCompanyId = id ? id : authentication?.tenant;

  try {
    const { data = [] } = await getTerminationInsuranceProgram(insuranceCompanyId);

    dispatch(setCompaniesData({ companies: data }));
  } catch (error) {
    notification.error({
      message: t('popup.error_update_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

export const usersPdfDownload = (id: number, t) => onDownload(() => usersAPI.printUserCard(id), {}, t);

export const usersXSLXDownload = (t): ThunkResult => async (dispatch, getState) => {
  const {
    adminList: {
      filters: {
        dateFrom,
        dateTo,
        insuranceCompanyId: { id: partnerId, insuranceCompanyId },
        departmentId: { id: partnerDepartmentId },
        username,
      },
    },
  }: AppState = getState();

  const timeZone = moment.tz.guess(true);
  const setInsCompanyId = !partnerId ? insuranceCompanyId : undefined;
  const setPartnerId = !!setInsCompanyId ? null : partnerId;

  return onDownload(
    () =>
      usersAPI.printUsersXLSXList(
        {
          signupDate: dateFrom && dateTo && `${dateFrom}T00:00:00..${dateTo}T23:59:59`,
          partnerDepartmentId,
          insuranceCompanyId: setInsCompanyId,
          username: username || null,
          timeZone,
        },
        setPartnerId!,
      ),
    {},
    t,
  );
};

export const setAdminListPrivateFilters = (privateFiltersItems: any) => ({
  type: TYPES.setAdminListPrivateFilters,
  privateFiltersItems,
});
