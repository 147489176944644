const impColumns = t => {
  return [
    {
      title: '№',
      value: 'id',
      key: 'history1',
      dataIndex: 'id',
      width: 30,
    },
    {
      title: t('insurance_contracts.number_info'),
      value: 'details',
      key: 'history2',
      dataIndex: 'details',
      width: 100,
    },
    {
      title: t('insurance_contracts.success_info'),
      value: 'success',
      key: 'history3',
      dataIndex: 'success',
      width: 120,
      render: status => (status ? t('insurance_contracts.imported') : t('insurance_contracts.not_exported')),
    },
    {
      title: t('insurance_contracts.message_info'),
      value: 'message',
      key: 'history4',
      dataIndex: 'message',
      width: 140,
    },
  ];
};

export default impColumns;
