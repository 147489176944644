import { createTypes } from 'redux-compose-reducer';
import { AppState } from 'store/reducer';
import * as brandAPI from 'api/brand';
import { notification } from 'antd';
import { RESOLUTION } from 'screens/BrandsPage/constants/BrandsPage.constants';
import { IBrand } from 'screens/BrandsPage/components/List/interfaces/Table.interfaces';

export const TYPES = createTypes('brandList', [
  'setBrands',
  'setPagination',
  'setUpdate',
  'setError',
  'setSearchValue',
  'setSuccess',
  'setBrandsTypes',
  'setActionOnBrand',
]);

export const setError = (value: string) => (dispatch: any) => {
  dispatch({ type: TYPES.setError, error: value });
};
export const setSuccess = (value: boolean) => (dispatch: any) => {
  dispatch({ type: TYPES.setSuccess, value: value });
  dispatch({ type: TYPES.setError });
};
export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};
export const setSearchValue = (value: string) => (dispatch: any) => {
  dispatch({ type: TYPES.setSearchValue, value });
  dispatch({ type: TYPES.setUpdate, update: true });
};

export const setBrands = (type: string) => async (dispatch: any, getState: any) => {
  const {
    brands: { pagination, search },
  }: AppState = getState();
  dispatch({ type: TYPES.setBrands, pending: true, update: false });

  try {
    const response = await brandAPI.getBrands(pagination, search, type);

    dispatch({ type: TYPES.setBrands, payload: response.data, pending: false, update: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setBrands, pending: false, update: false });
  }
};

export const setActionOnBrand = (actionOnBrand: string) => ({
  type: TYPES.setActionOnBrand,
  actionOnBrand,
});

export const deleteBrand = (id: number, t) => async (dispatch: any) => {
  try {
    await brandAPI.deleteBrand(id);
    dispatch({ type: TYPES.setUpdate, update: true });
    notification.success({
      message: t('popup.brand_successfully_removed'),
    });
  } catch (error) {
    notification.error({
      description: t('popup.failed_delete_brand'),
      message: t('popup.error'),
    });
  }
};

export const editBrand = (brand: IBrand, t) => async (dispatch: any) => {
  try {
    await brandAPI.editBrand(brand.id, brand.brand, brand.type, brand.typeValue);
    notification.success({
      message: t('popup.brand_successfully_changed'),
    });
    dispatch({ type: TYPES.setUpdate, update: true });
    dispatch(setSuccess(true));
  } catch (err) {
    dispatch(setSuccess(false));
    if (err?.response?.data?.errors) {
      return err.response.data.errors.forEach((el: any) => {
        if (el.code === RESOLUTION.BRAND_ALREADY_EXISTS) {
          return dispatch(setError(t('popup.brand_already_in_system')));
        }
        notification.error({
          message: t('popup.something_went_wrong'),
          description: t('popup.failed_change_brand'),
        });
      });
    }
    notification.error({
      message: t('popup.something_went_wrong'),
      description: t('popup.failed_change_brand'),
    });
  }
};

export const addBrand = (value: string, typeValue: string, subType: string, t) => async (dispatch: any) => {
  try {
    await brandAPI.addBrand(value, typeValue, subType);
    notification.success({
      message: t('popup.brand_successfully_added_directory'),
    });
    dispatch({ type: TYPES.setUpdate, update: true });
    dispatch(setSuccess(true));
  } catch (err) {
    dispatch(setSuccess(false));
    if (err?.response?.data?.errors) {
      return err.response.data.errors.forEach((el: any) => {
        if (el.code === RESOLUTION.BRAND_ALREADY_EXISTS) {
          return dispatch(setError(t('popup.brand_already_in_system')));
        }
        notification.error({
          message: t('popup.something_went_wrong'),
          description: t('popup.failed_save_brand'),
        });
      });
    }
    notification.error({
      message: t('popup.something_went_wrong'),
      description: t('popup.failed_save_brand'),
    });
  }
};

export const getTypes = (insuranceObjectType: string) => async (dispatch: any) => {
  try {
    const response = await brandAPI.getBrandsTypes(insuranceObjectType);
    dispatch({ type: TYPES.setBrandsTypes, payload: { insuranceObjectTypes: response.data } });
  } catch (error) {
    dispatch({ type: TYPES.setBrandsTypes, payload: { errorMessage: error } });
  }
};
