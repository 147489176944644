import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown, Icon, Typography } from 'antd';

import locales from 'config/locales';
import { Language as ILanguage } from 'types';
import { css } from '@emotion/css';
import { theme } from 'styles';

const cssLanguage = css`
  font-size: 18px;
  color: ${theme.color.primary.main};
`;

const Language = () => {
  const { i18n } = useTranslation();

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const changeLocale = (locale: ILanguage) => () => {
    i18n.changeLanguage(locale);
    localStorage.setItem('language', locale);
  };

  const menu = (
    <Menu>
      {Object.entries(locales)
        .filter(([locale]) => locale !== i18n.language)
        .map(([locale, options]) => (
          <Menu.Item key={locale} onClick={changeLocale(locale as ILanguage)}>
            {options.title}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={onClick}>
        <Typography.Text className={cssLanguage}>{i18n.language}</Typography.Text> <Icon type="down" />
      </a>
    </Dropdown>
  );
};

export default Language;
