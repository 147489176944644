import { createTypes } from 'redux-compose-reducer';
import * as claimsAPI from 'api/claims';
import { AppState } from 'store/reducer';

export const TYPES = createTypes('claims', ['setPending', 'setError', 'setClaims']);

const setPending = (pending: boolean) => ({
  type: TYPES.setPending,
  payload: { pending },
});

const setError = error => ({
  type: TYPES.setError,
  payload: { error },
});

export const getClaims = () => async (dispatch, getState) => {
  const {
    claims: { claimStatus },
  }: AppState = getState();
  try {
    dispatch(setPending(true));
    const response = await claimsAPI.getClaims({ claimStatus });
    dispatch({ type: TYPES.setClaims, payload: { claims: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};
