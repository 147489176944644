import React from 'react';
import { Upload } from 'antd';

type Props = {
  uploadDataId?: string;
  accept?: string;
  showUploadList?: boolean;
  customRequest?: (file: any) => void;
} & React.InputHTMLAttributes<string>;

const InputUpload: React.FC<Props> = React.forwardRef(
  ({ uploadDataId, children, customRequest, showUploadList = true, accept }, ref: any) => {
    return (
      <Upload
        customRequest={customRequest}
        showUploadList={showUploadList}
        accept={accept}
        data-id={uploadDataId}
        ref={ref}
      >
        {children}
      </Upload>
    );
  },
);

export default InputUpload;
