import createInstance from './axios';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { insurerAdapter } from '../screens/TerminationsList/mockData';
import _ from 'lodash';
import { ResultListDtoContractTerminationView } from 'types/dto/contracts-service';

const termListAPI = createInstance('CONTRACTS');

export const getTablePage = ({ filters, sorting, pagination }: any) => {
  const requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    terminationDate: `${moment(filters.dateFrom).format('YYYY-MM-DD')}..${moment(filters.dateTo)
      .add(1, 'days')
      .format('YYYY-MM-DD')}`,
    sorting: sorting.direction === 'DESC' ? `-${sorting.field}` : `${sorting.field}`,
    insuranceProgramId: filters.insuranceProgramId?.id,
    partnerId: filters.insuranceCompanyId.id,
  };

  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);
  return termListAPI.get<ResultListDtoContractTerminationView>(
    'contract-terminations?count&total&totalAttributes=refundSum,claimPaymentSum',
    {
      params: requestParams,
    },
  );
};

export const makerRequestParams = (array: [], key: string) => {
  let str = '';
  Array.isArray(array)
    ? array.forEach((item, inx) => {
        if (inx === 0) {
          str = item;
          if (key === 'signed') {
            str = `${item === 'Діючий'}`;
          } else if (key === 'initiator') {
            str = _.invert(insurerAdapter)[item];
          }
        } else {
          if (key === 'signed') {
            str = `${str}|${item === 'Діючий'}`;
          } else if (key === 'initiator') {
            str = `${str}|${_.invert(insurerAdapter)[item]}`;
          } else {
            str = `${str}|${item}`;
          }
        }
      })
    : (str = array);
  return str;
};

export const getPrivateFiltersTable = ({ filters, pagination }: { filters: any; pagination: any }) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key], key);
    }
  });

  return termListAPI.get(`contract-terminations?count`, {
    params: {
      ...filters,
      page_size: pagination.perPage,
      page: pagination.current,
    },
  });
};

export const downloadTerminationXLS = async (data: any) => {
  const timeZone = momentTz.tz.guess(true);
  return termListAPI.get('/contract-terminations/print/xlsx', {
    responseType: 'arraybuffer',
    params: {
      ...data,
      timeZone,
    },
  });
};
