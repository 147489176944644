import React from 'react';
import numeral from 'numeral';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import Table from 'components/next/Table/Table';
import config from 'config/config';

import { TransformedRisk } from '../ByRisk.model';

interface Props {
  data: TransformedRisk[];
  currency: string;
}

const ByRiskTable = ({ data, currency }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<TransformedRisk>[] = [
    {
      title: t('dashboard_extend.byRisk.productName'),
      key: 'productName',
      render: (_, record) => record.productName,
    },
    {
      title: t('dashboard_extend.byRisk.riskName'),
      key: 'riskName',
      render: (_, record) => record.riskName,
    },
    {
      title: t('dashboard_extend.byRisk.countContracts'),
      key: 'countContracts',
      render: (_, record) => record.countContracts,
    },
    {
      title: t('dashboard_extend.byRisk.contractPaymentSum'),
      key: 'contractRiskPaymentSum',
      render: (_, record) => `${numeral(record.contractRiskPaymentSum).format(config.format.financial)} ${currency}`,
    },
    {
      title: t('dashboard_extend.byRisk.countClaims'),
      key: 'countClaims',
      render: (_, record) => record.countClaims,
    },
    {
      title: t('dashboard_extend.byRisk.claimPaymentSum'),
      key: 'claimRiskPaymentSum',
      render: (_, record) => `${numeral(record.claimRiskPaymentSum).format(config.format.financial)} ${currency}`,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={record => `${record.productId}-${record.riskId}`}
      scroll={{ x: 650, y: 265 }}
      pagination={false}
    />
  );
};

const Memoized = React.memo(ByRiskTable);

export default Memoized;
