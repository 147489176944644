import React from 'react';
import { Switch as BaseSwitch, Icon } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface Props extends SwitchProps {}

const Switch = (props: Props) => {
  return (
    <BaseSwitch unCheckedChildren={<Icon type="bar-chart" />} checkedChildren={<Icon type="table" />} {...props} />
  );
};

export default Switch;
