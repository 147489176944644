import React from 'react';
import { css, cx } from '@emotion/css';
import { Empty } from 'antd';

import BaseCard, { Props as CardProps } from 'components/next/Card/Card';

const cssCard = css`
  height: 465px;

  .ant-card-head-title {
    white-space: inherit;
    text-overflow: inherit;
  }
`;
export const cssTitle = css`
  margin-right: 8px;
`;
const cssIsTable = css`
  .ant-card-body {
    padding: 0 0 24px;
  }
`;

interface Props extends CardProps {
  isChart: boolean;
  hasData: boolean;
}

const Card = ({ isChart, className, hasData, ...props }: Props) => {
  return (
    <BaseCard className={cx(cssCard, { [cssIsTable]: !isChart }, className)} {...props}>
      {hasData ? props.children : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </BaseCard>
  );
};

export default Card;
