import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { actCreateType } from 'types/actsList';
import { AnyAction } from 'redux';
import config from 'config';

const initialState: actCreateType = {
  total: 0,
  data: [],
  filters: {
    dateFrom: '',
    dateTo: '',
    actDate: '',
    insuranceCompanyId: {
      id: null,
      name: null,
    },
    insuranceProgramId: {
      id: null,
      name: null,
    },
  },
  pending: false,
  error: false,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: '',
  },
  statistic: {
    amountToPay: 0,
    totalCount: 0,
    totalFeeSum: 0,
    totalInsurancePayments: 0,
  },
  actId: null,
  excludedContracts: [],
  companies: [],
  insuranceProgram: [],
};

const setTablePage = (state: actCreateType, { payload, pending }: AnyAction): actCreateType => {
  return {
    ...state,
    data: payload ? payload.contracts : initialState.data,
    total: payload ? payload.count : initialState.total,
    pending,
  };
};

const setStatistic = (state: actCreateType, { payload }: AnyAction): actCreateType => {
  return {
    ...state,
    statistic: payload?.statistic ? payload.statistic : initialState.statistic,
  };
};

const setPagination = (state: actCreateType, { payload }: AnyAction): actCreateType => {
  return {
    ...state,
    pagination: payload,
  };
};

const setFilters = (state: actCreateType, { payload }: AnyAction): actCreateType => {
  return {
    ...state,
    filters: payload,
  };
};

const setSorting = (state: actCreateType, { payload }: any): actCreateType => {
  const { field } = payload;
  return {
    ...state,
    sorting: {
      field: field,
    },
  };
};

const setActId = (state: actCreateType, { payload }: AnyAction): actCreateType => {
  return {
    ...state,
    actId: payload,
  };
};

const removeActId = (state: actCreateType): actCreateType => {
  return {
    ...state,
    actId: initialState.actId,
  };
};

const clearData = (state: actCreateType): actCreateType => {
  return {
    ...state,
    data: initialState.data,
    sorting: initialState.sorting,
    pagination: initialState.pagination,
    statistic: initialState.statistic,
    total: initialState.total,
  };
};

const addExcludedContract = (state: actCreateType, { payload }: any): actCreateType => {
  return {
    ...state,
    excludedContracts: [...state.excludedContracts, payload],
  };
};

const removeExcludedContract = (state: actCreateType, { payload }: any): actCreateType => {
  return {
    ...state,
    excludedContracts: state.excludedContracts.filter(
      (el: any) => (el.id === payload.id && el.terminated !== payload.terminated) || el.id !== payload.id,
    ),
  };
};

const clearExcludedContracts = (state: actCreateType): actCreateType => {
  return {
    ...state,
    excludedContracts: initialState.excludedContracts,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setTablePage,
    setPagination,
    setSorting,
    setFilters,
    setActId,
    removeActId,
    clearData,
    addExcludedContract,
    removeExcludedContract,
    clearExcludedContracts,
    setStatistic,
  },
});
