import login from './logIn.json';
import userCard from './userCard.json';
import actsList from './actsList.json';
import adminPanel from './adminPanel.json';
import contractList from './contractList.json';
import dashboard from './dashboard.json';
import search from './search.json';
import terminationList from './terminationList.json';
import insuredEventsList from './insuredEventsList.json';
import insuranceContracts from './insuranceContracts.json';
import registrationDevices from './registrationDevices.json';
import settings from './settings.json';
import sideMenu from './sideMenu.json';
import usersActivities from './usersActivities.json';
import departmentDetails from './departmentDetails.json';
import departments from './departments.json';
import insuranceProgramList from './insuranceProgramList.json';
import insuranceConfiguration from './insuranceConfiguration.json';
import insuranceCompanies from './insuranceCompanies.json';
import partnerCreation from './partnerCreation.json';
import partnerList from './partnerList.json';
import table from './table.json';
import brandsPage from './brandsPage.json';
import breadcrumbs from './breadcrumbs.json';
import contractDetails from './contractDetails.json';
import curtomerInfo from './curtomerInfo.json';
import noData from './noData.json';
import signup from './signup.json';
import passwordRecovery from './passwordRecovery.json';
import detailsCard from './detailsCard.json';
import generalInfo from './generalInfo.json';
import purchaseInfo from './purchaseInfo.json';
import sellerDashboard from './sellerDashboard.json';
import personInfo from './personInfo.json';
import carInfo from './carInfo.json';
import jutloInfo from './jutloInfo.json';
import actCreate from './actCreate.json';
import errorPage from './errorPage.json';
import actDetails from './actDetails.json';
import form from './form.json';
import modal from './modal.json';
import searchResult from './searchResult.json';
import insurance from './insurance.json';
import documentsTable from './documentsTable.json';
import clientSign from './clientSign.json';
import insuranceProgramDetails from './insuranceProgramDetails.json';
import insuredEvents from './insuredEvents.json';
import terminationDetails from './terminationDetails.json';
import popup from './popup.json';
import validation from './validation.json';
import status from './status.json';
import constants from './constants.json';
import chats from './chats.json';
import doctor from './doctor.json';
import consultations from './consultations.json';
import medapp from './medApp.json';
import registerOfTypes from './registerOfTypes.json';
import sublimits from './sublimits.json';
import deviceType from './deviceType.json';
import history from './history.json';
import enums from './enums.json';
import services from './services.json';
import schema from './schema.json';
import appointment from './appointments.json';
import dashboard_extend from './dashboard_extend.json';

export const RU_TRANSLATIONS = {
  ...login,
  ...userCard,
  ...chats,
  ...actsList,
  ...adminPanel,
  ...contractList,
  ...dashboard,
  ...search,
  ...terminationList,
  ...insuredEventsList,
  ...insuranceContracts,
  ...registrationDevices,
  ...settings,
  ...sideMenu,
  ...usersActivities,
  ...departmentDetails,
  ...departments,
  ...insuranceProgramList,
  ...insuranceConfiguration,
  ...insuranceCompanies,
  ...partnerCreation,
  ...partnerList,
  ...table,
  ...brandsPage,
  ...breadcrumbs,
  ...contractDetails,
  ...curtomerInfo,
  ...noData,
  ...signup,
  ...passwordRecovery,
  ...detailsCard,
  ...generalInfo,
  ...purchaseInfo,
  ...sellerDashboard,
  ...personInfo,
  ...carInfo,
  ...jutloInfo,
  ...actCreate,
  ...errorPage,
  ...actDetails,
  ...form,
  ...modal,
  ...searchResult,
  ...insurance,
  ...documentsTable,
  ...clientSign,
  ...insuranceProgramDetails,
  ...insuredEvents,
  ...terminationDetails,
  ...popup,
  ...validation,
  ...status,
  ...doctor,
  ...constants,
  ...consultations,
  ...medapp,
  ...registerOfTypes,
  ...sublimits,
  ...deviceType,
  ...history,
  ...enums,
  ...services,
  ...schema,
  ...appointment,
  ...dashboard_extend,
};
