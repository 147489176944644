import React from 'react';
import { StatusSuccess } from 'components/Icons';

const ConfirmationDocuments = () => {
  return [
    {
      title: '',
      dataIndex: 'claimStatus',
      key: 'claimStatus',
      value: 'claimStatus',
      width: 70,
      fixed: 'left',
      render: () => <StatusSuccess />,
    },
    {
      title: 'insured_events_list.claim_number_confirmation_table',
      dataIndex: 'claimNumber',
      key: 'claimNumber',
      value: 'claimNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.receipt_date_confirmation_table',
      dataIndex: 'receiptDate',
      value: 'receiptDate',
      key: 'receiptDate',
      width: 270,
    },
    {
      title: 'insured_events_list.claim_date_confirmation_table',
      dataIndex: 'claimDate',
      value: 'claimDate',
      key: 'claimDate',
      width: 150,
    },
    {
      title: 'insured_events_list.incident_date_confirmation_table',
      dataIndex: 'incidentDate',
      value: 'incidentDate',
      key: 'incidentDate',
      width: 150,
    },
    {
      title: 'insured_events_list.contract_number_confirmation_table',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      value: 'contractNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.signatory_confirmation_table',
      dataIndex: 'signatory',
      value: 'signatory',
      key: 'signatory',
      width: 200,
    },
    {
      title: 'insured_events_list.insurance_object_confirmation_table',
      dataIndex: 'insuranceObject',
      value: 'insuranceObject',
      key: 'insuranceObject',
      width: 150,
    },
    {
      title: 'insured_events_list.insurance_program_confirmation_table',
      dataIndex: 'insuranceProgram',
      value: 'insuranceProgram',
      key: 'insuranceProgram',
      width: 200,
    },
    {
      title: 'insured_events_list.risk_confirmation_table',
      dataIndex: 'risk',
      value: 'risk',
      key: 'risk',
      width: 200,
    },
    {
      title: 'insured_events_list.damage_confirmation_table',
      dataIndex: 'damage',
      value: 'damage',
      key: 'damage',
      width: 200,
      ellipsis: true,
    },
    {
      title: 'insured_events_list.total_doc_amount_confirmation_table',
      dataIndex: 'totalDocumentsAmount',
      value: 'totalDocumentsAmount',
      key: 'totalDocumentsAmount',
      width: 220,
    },
    {
      title: 'insured_events_list.loaded_doc_amount_confirmation_table',
      dataIndex: 'loadedDocumentsAmount',
      value: 'loadedDocumentsAmount',
      key: 'loadedDocumentsAmount',
      width: 230,
    },
    {
      title: '',
      key: 'action',
      value: 'actions',
      fixed: 'right',
      width: 50,
    },
  ];
};

export default ConfirmationDocuments;
