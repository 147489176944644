import _ from 'lodash';
import { makerRequestParams } from 'utils/helpers';
import createInstance from './axios/axios';
import { ResultListDtoPartnerView } from 'types/dto/contracts-service';

const partnersAPI = createInstance('CONTRACTS');

export const getPartnersList = async params =>
  partnersAPI.get<ResultListDtoPartnerView>(`/partners?count`, {
    params: {
      ...params,
      page_size: 300,
    },
  });

export const getPrivateFiltersTable = ({
  filters,
  pagination,
}: {
  filters: any;
  pagination: { current: number | string; perPage: number | string };
}) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  return partnersAPI.get<ResultListDtoPartnerView>('/partners?count', {
    params: {
      ..._.omit(filters),
      page_size: pagination.perPage,
      page: pagination.current,
      distinct: true,
    },
  });
};

export const getTablePageData = ({ filters, sorting, pagination }: any) => {
  const otherFilters = {
    ...filters.insuranceCompanyId,
  };
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;
  if (otherFilters) delete otherFilters.name;
  const requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    sorting: sortingQuery,
    distinct: true,
    ...otherFilters,
  };
  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return partnersAPI.get<ResultListDtoPartnerView>('/partners?count', {
    params: {
      ...requestParams,
    },
  });
};
