import React, { useEffect, useState } from 'react';
import impColumns from './utils/importColumns';
import expColumns from './utils/exportColumns';
import { formatCompanies } from './utils/formatter';
import { ImportedContractsHistory } from 'types/insuranceImport';
import { IInfoPagination } from 'api/insuranceContracts';
import { FilterIcon } from 'components/Icons';
import PrivateFilter from 'components/PrivateFilter';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilteredHistory,
  getFilteredPrivateData,
  getTablePage,
  setPagination,
  setSorting,
} from 'store/integrationHistory/actions';
import CustomTable from 'components/CustomTable';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import InputTooltip from 'components/InputTooltip';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/reducer';
import { IntegrationHistoryItemView } from 'types/dto/contracts-service';

interface ITable {
  data: IntegrationHistoryItemView[];
  loading: boolean;
  classes?: any;
  type: string;
  setInfoPagination: (pagination: IInfoPagination) => void;
  pagination: IInfoPagination;
  historyId: number;
  isModalOpen: boolean;
}

const InfoTable: React.FC<ITable> = ({ data, loading, classes, type, setInfoPagination, isModalOpen, historyId }) => {
  const [filtersInfo, setFiltersInfo] = useState({} || null);
  const [clearedFilters, setClearedFilters] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns =
    type === 'EXPORT_1C' ? { name: 'EXPORT', col: expColumns(t) } : { name: 'IMPORT', col: impColumns(t) };
  const {
    pagination: { perPage, current },
    total,
  } = useSelector((store: AppState) => ({
    pagination: store.integrationHistory.pagination,
    total: store.integrationHistory.total,
  }));

  useEffect(() => {
    if (!isModalOpen) {
      clearFilter();
      setFiltersInfo({});
    }
  }, [isModalOpen]);

  const clearFilter = () => {
    setClearedFilters(false);
  };

  const getTableData = () => {
    dispatch(getTablePage(historyId));
  };

  const getDataPrivateFilter = (filtersInfoo: { [key: string]: any } | null) => {
    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });

    dispatch(
      getFilteredPrivateData(
        historyId,
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const getDataFromPrivateFilter = (
    filtersInfoo: { [key: string]: any } | null,
    pagination?: { current: number; perPage: number },
  ) => {
    if (pagination) {
      setInfoPagination({ page: pagination.current, size: Number(pagination.perPage) || 1, total: 10 });
    } else {
      setInfoPagination({ page: 1, size: 10, total: 10 });
    }

    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });

    setFiltersInfo({
      ...filtersInfoo,
    });

    dispatch(
      getFilteredHistory(
        historyId,
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const filteredBySearching = (filterBy: string, value: string, type: string) => {
    if (type === 'date') {
      setFiltersInfo({
        [filterBy]: [`${value[0]}..${value[1]}`],
      });
    }

    getDataFromPrivateFilter(type === 'date' ? { [filterBy]: [`${value[0]}..${value[1]}`] } : { [filterBy]: value });
  };

  const getColumnSearchProps = (dataIndex: string, isDate?: boolean) => {
    // @ts-ignore,
    const filteredValue = filtersInfo[dataIndex] || null;
    return {
      filteredValue,
      filters:
        data &&
        data.map((item: any) => {
          if (dataIndex === 'success' && columns.name === 'EXPORT') {
            return {
              value: item[dataIndex]
                ? t('insurance_contracts.exported_info')
                : t('insurance_contracts.not_exported_info'),
            };
          }

          if (dataIndex === 'success' && columns.name === 'IMPORT') {
            return {
              value: item[dataIndex] ? t('insurance_contracts.imported') : t('insurance_contracts.not_exported'),
            };
          }

          return { value: String(item[dataIndex]).trim() };
        }),
      filterIcon: (filtered: boolean) => (
        <div data-id={`contract-list-private-filter-${dataIndex}-open-filter`}>
          <FilterIcon color={filtered ? '#fff' : null} className={classnames({ active: filtered })} />
        </div>
      ),
      filterDropdown: (record: any) => {
        return (
          <PrivateFilter
            dataId={`contract-list-private-filter-${dataIndex}`}
            record={record}
            dataIndex={dataIndex}
            filtersInfo={filtersInfo}
            setFiltersInfo={setFiltersInfo}
            getDataFromPrivateFilter={getDataFromPrivateFilter}
            getDataPrivateFilter={getDataPrivateFilter}
            filteredBySearching={filteredBySearching}
            isDate={isDate}
            getTablePage={getTableData}
            isClearedFilters={clearedFilters}
            clearAllFilters={clearFilter}
          />
        );
      },
    };
  };

  const columnList = columns.col.map(item => {
    const basicColumn = {
      ...item,
      ...getColumnSearchProps(item.value),
      sorter: true,
      width: 20,
    };
    switch (item.value) {
      case 'details':
        return {
          ...basicColumn,
          width: 30,
        };
      case 'id':
        return {
          ...basicColumn,
          sorter: false,
          filtered: false,
          filters: null,
          filterIcon: null,
          filterDropdown: null,
          width: 10,
        };
      case 'success':
        return {
          ...basicColumn,
          width: 30,
        };
      case 'message':
        return {
          ...basicColumn,
          width: 30,
          render: (value, rec) => {
            const isSuccess = rec.success;
            return (
              <>
                {isSuccess ? t('insurance_contracts.imported') : t('insurance_contracts.not_exported')}
                {!isSuccess && <InputTooltip>{value}</InputTooltip>}
              </>
            );
          },
        };
      default: {
        return basicColumn;
      }
    }
  });

  const clearFilters = () => {
    dispatch(getTablePage(historyId));
    setFiltersInfo({});
    setClearedFilters(true);
  };

  const handleFilters = (value: {}) => {
    setFiltersInfo({
      ...filtersInfo,
      ...value,
    });
  };

  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? 'ASC' : 'DESC';
      }
      return '';
    };

    const formatSorting = {
      field: sorting.field,
      direction: formatDirection(sorting.order),
    };
    dispatch(setSorting(formatSorting));
    getDataFromPrivateFilter(filtersInfo);
  };

  const onPageSizeChange = (pageSize: string): void => {
    dispatch(setPagination(1, Number(pageSize) || 0));
    getDataFromPrivateFilter(filtersInfo, { current: 1, perPage: Number(pageSize) });
  };

  const onPageChange = (page: number, pageSize?: number | undefined): void => {
    dispatch(setPagination(page, pageSize || 0));
    getDataFromPrivateFilter(filtersInfo, { current: page, perPage: pageSize || 0 });
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <CustomTable
        classes={classes}
        page={current}
        contentHeight={400}
        xValue={200}
        pageSize={perPage}
        total={total || 0}
        pending={loading || false}
        data={formatCompanies(data)}
        columns={columnList}
        filtersInfo={filtersInfo}
        clearFilters={clearFilters}
        handleFilters={handleFilters}
        handleTableChange={handleTableChange}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        rowKey={'id'}
      />
    </div>
  );
};

export default withStyles(styles)(InfoTable);
