import { IUserData, TRegions } from '../../types/userCard';

export const prepare = (data: any, status?: boolean | null) => {
  return (
    data &&
    data.map((item: any) => {
      return {
        active: item.active,
        insuranceProgram: item.insuranceProgram,
        endDate: null,
        startDate: null,
        newActive: item.newActive,
      };
    })
  );
};

export const prepare1 = (data: any, status?: boolean | null) => {
  return (
    data &&
    data.map((item: any) => {
      return {
        active: false,
        insuranceProgram: item,
        endDate: null,
        startDate: null,
        newActive: true,
      };
    })
  );
};

export const convertDepartment = (data: TRegions | null) => {
  return data
    ? {
        name: data?.name,
        id: data?.id,
      }
    : null;
};

export const addRegionId = (data: IUserData) => {
  return data.partnerDepartment
    ? {
        ...data.partnerDepartment,
        region: data.partnerDepartmentRegion!.name,
        regionId: data.partnerDepartmentRegion!.id,
      }
    : null;
};
