import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import { IInsProgramCreate } from './types';

export const initialState: IInsProgramCreate = {
  programData: {},
  insuranceCompanies: [],
  programTypes: [],
  managers: [],
  agents: [],
  errors: [],
  insTypes: [],
  insRules: [],
  risksList: [],
  chosenSettlement: [],
  tariffData: {},
  insurancePoliciesData: [],
  documents: [],
  document: '',
  insuranceObject: '',
  selectedDocumentsValues: {
    contractTemplate: null,
    claimTemplate: null,
    actTemplate: null,
    terminationTemplate: null,
  },
  parameters: {
    autoGenerationNumber: false,
    medApp: false,
    generalContract: false,
    prolongation: false,
    installments: false,
  },
  payments: {
    settlementTypes: {
      settlementTypes: [],
      risks: [],
      documents: null,
      isLoading: false,
      documentTypeNamesList: [],
      documentsConfiguration: null,
      documentsWhichAreNotConfigured: [],
    },
    refusalReasons: {
      templateData: [],
    },
  },
  terminationNotice: {
    terminationInitiatorsNames: [],
    acountTypes: [],
    terminationCauses: [],
    terminationsDocumentsNames: [],
  },
};

const getProgramData = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    programData: payload.data,
  };
};

const getInsuranceCompanies = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insuranceCompanies: payload.data.resultList,
  };
};

const getProgramsType = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    programTypes: payload.data,
  };
};

const getManagers = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    managers: payload.data,
  };
};

const getAgents = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    agents: payload.data,
  };
};

const setError = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  const cleanedErrors = [...state.errors, payload].filter(item => Object.keys(item)[0] !== Object.keys(payload)[0]);
  return {
    ...state,
    errors: [...cleanedErrors, payload],
  };
};

const getInsTypes = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insTypes: payload.data,
  };
};

const getInsRules = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insRules: payload.data,
  };
};

const getRisksList = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    risksList: payload.data,
  };
};

const setChosenSettlement = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    chosenSettlement: payload,
  };
};

const getTariffData = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    tariffData: payload,
  };
};

const getInsurancePolicie = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insurancePoliciesData: payload,
  };
};

const setDocuments = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    documents: payload,
  };
};

const setInsuranceObject = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insuranceObject: payload,
  };
};

const setSelectedDocumentsValues = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    selectedDocumentsValues: payload,
  };
};

const setParameters = (
  state: IInsProgramCreate,
  { payload: { fieldName, fieldValue } }: AnyAction,
): IInsProgramCreate => {
  return {
    ...state,
    parameters: {
      ...state.parameters,
      [fieldName]: fieldValue,
    },
  };
};

const setSettlementTypes = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return _changeSettlementTypes({ state, payload, fieldName: 'settlementTypes' });
};

const setSettlementTypesRisks = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return _changeSettlementTypes({ state, payload, fieldName: 'risks' });
};

const setDocumentTypeNamesList = (state: IInsProgramCreate, { payload: { data } }: AnyAction): IInsProgramCreate => {
  return _changeSettlementTypes({ state, payload: data, fieldName: 'documentTypeNamesList' });
};

const _changeSettlementTypes = ({ state, payload, fieldName }): IInsProgramCreate => {
  return {
    ...state,
    payments: {
      ...state.payments,
      settlementTypes: {
        ...state.payments.settlementTypes,
        [fieldName]: payload,
      },
    },
  };
};

const setTerminationInitiatorsNames = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      terminationInitiatorsNames: payload,
    },
  };
};

const setConfiguratedDocuments = (
  state: IInsProgramCreate,
  { payload: { isLoading, data } }: AnyAction,
): IInsProgramCreate => {
  return {
    ...state,
    payments: {
      ...state.payments,
      settlementTypes: {
        ...state.payments.settlementTypes,
        documents: data,
        isLoading,
      },
    },
  };
};

const setAccountTypes = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      acountTypes: payload,
    },
  };
};

const setDocumentsWhichAreNotConfigured = (
  state: IInsProgramCreate,
  { payload: { isLoading, data } }: AnyAction,
): IInsProgramCreate => {
  return {
    ...state,
    payments: {
      ...state.payments,
      settlementTypes: {
        ...state.payments.settlementTypes,
        documentsWhichAreNotConfigured: data,
        isLoading,
      },
    },
  };
};

const setTerminationCauses = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      terminationCauses: payload,
    },
  };
};

const setTemplateData = (state: IInsProgramCreate, { payload: { data } }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    payments: {
      ...state.payments,
      refusalReasons: {
        ...state.payments.refusalReasons,
        templateData: data,
      },
    },
  };
};

const setTerminationDocumentsNames = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      terminationsDocumentsNames: payload,
    },
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    getInsuranceCompanies,
    getProgramsType,
    getManagers,
    getAgents,
    setError,
    getProgramData,
    getTariffData,
    getInsTypes,
    getInsRules,
    getRisksList,
    setChosenSettlement,
    getInsurancePolicie,
    setDocuments,
    setInsuranceObject,
    setSelectedDocumentsValues,
    setParameters,
    setSettlementTypes,
    setSettlementTypesRisks,
    setConfiguratedDocuments,
    setDocumentTypeNamesList,
    setDocumentsWhichAreNotConfigured,
    setTemplateData,
    setTerminationInitiatorsNames,
    setAccountTypes,
    setTerminationCauses,
    setTerminationDocumentsNames,
  },
});
