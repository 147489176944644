import { createTypes } from 'redux-compose-reducer';
import * as appointmentsAPI from 'api/appointments';
import * as contractsAPI from 'api/contracts';

export const TYPES = createTypes('appointment', [
  'setPending',
  'setError',
  'setHistory',
  'cancelAppointment',
  'setSublimitsInfo',
  'addSublimitInfo',
  'editSublimitInfo',
  'deleteSublimitInfo',
  'getUserContracts',
  'createAppoinment',
  'setSubevent',
  'setClaim',
]);

const setPending = (pending: boolean) => ({
  type: TYPES.setPending,
  payload: { pending },
});

export const setError = error => ({
  type: TYPES.setError,
  payload: { error },
});

export const getHistory = data => async dispatch => {
  try {
    dispatch(setPending(true));
    const response = await appointmentsAPI.getHistory(data);
    dispatch({ type: TYPES.setHistory, payload: { history: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const clearHistory = () => async dispatch => {
  try {
    dispatch({ type: TYPES.setHistory, payload: { history: [] } });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const cancelAppointment = (id, successFn, errorFn) => async dispatch => {
  try {
    dispatch(setPending(true));
    await appointmentsAPI.cancelAppointment(id);
    dispatch({ type: TYPES.cancelAppointment });
    successFn();
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
    errorFn(error?.response?.data?.message);
  } finally {
    dispatch(setPending(false));
  }
};

export const getSublimitsInfo = id => async dispatch => {
  try {
    dispatch(setPending(true));
    const response = await appointmentsAPI.getSublimitsInfo(id);
    dispatch({ type: TYPES.setSublimitsInfo, payload: { sublimits: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const addSublimitInfo = data => async dispatch => {
  try {
    dispatch(setPending(true));
    await appointmentsAPI.addSublimitInfo(data);
    dispatch({ type: TYPES.addSublimitInfo });

    const response = await appointmentsAPI.getSublimitsInfo(data.insuranceProgramId);
    dispatch({ type: TYPES.setSublimitsInfo, payload: { sublimits: response } });
  } catch (error) {
    dispatch(setError(error?.response?.data?.errors[0]?.defaultMessage));
  } finally {
    dispatch(setPending(false));
  }
};

export const editSublimitInfo = (data, closeFn) => async dispatch => {
  try {
    dispatch(setPending(true));
    await appointmentsAPI.editSublimitInfo(data.id, data);
    dispatch({ type: TYPES.editSublimitInfo });

    const response = await appointmentsAPI.getSublimitsInfo(data.insuranceProgramId);
    dispatch({ type: TYPES.setSublimitsInfo, payload: { sublimits: response } });
    closeFn();
  } catch (error) {
    dispatch(setError(error?.response?.data?.errors[0]?.defaultMessage));
  } finally {
    dispatch(setPending(false));
  }
};

export const deleteSublimitInfo = data => async dispatch => {
  try {
    dispatch(setPending(true));
    await appointmentsAPI.deleteSublimitInfo(data.mainId);
    dispatch({ type: TYPES.deleteSublimitInfo });

    const response = await appointmentsAPI.getSublimitsInfo(data.insuranceProgramId);
    dispatch({ type: TYPES.setSublimitsInfo, payload: { sublimits: response } });
  } catch (error) {
    dispatch(setError(error?.response?.data?.errors[0]?.defaultMessage));
  } finally {
    dispatch(setPending(false));
  }
};

export const getUserContracts = () => async dispatch => {
  try {
    dispatch(setPending(true));
    const response = await contractsAPI.getUserContracts();
    dispatch({ type: TYPES.getUserContracts, payload: { userContracts: response } });
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const createAppoinment = (data, successFn, errorFn) => async dispatch => {
  try {
    dispatch(setPending(true));
    await appointmentsAPI.createAppointment(data);
    dispatch({ type: TYPES.createAppoinment });
    successFn();
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
    errorFn(error?.response?.data?.message);
  } finally {
    dispatch(setPending(false));
  }
};

export const getSubevent = id => async dispatch => {
  try {
    dispatch(setPending(true));
    const response = await appointmentsAPI.getSubevent(id);
    dispatch({ type: TYPES.setSubevent, payload: { subevent: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const clearSubevent = () => async dispatch => {
  try {
    dispatch({ type: TYPES.setSubevent, payload: { subevent: null } });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const getClaim = id => async dispatch => {
  try {
    dispatch(setPending(true));
    const response = await appointmentsAPI.getClaim(id);
    dispatch({ type: TYPES.setClaim, payload: { claimData: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};
