import React, { useEffect } from 'react';
import cx from 'classnames';
import { Modal } from 'antd';
import moment from 'moment';
import styles from 'screens/InsuranceContracts/List/components/InfoModal/style.module.less';
import InfoTable from 'screens/InsuranceContracts/List/components/tables/InfoTable/InfoTable';
import { IInfoModal } from 'screens/InsuranceContracts/List/components/InfoModal/types';
import { ImportedContractsHistory } from 'types/insuranceImport';
import { IInfoPagination } from 'api/insuranceContracts';
import { useDispatch } from 'react-redux';
import { resetData } from 'store/integrationHistory/actions';
import closeIcon from 'assets/img/layout/greyClose.svg';
import { useTranslation } from 'react-i18next';
import { IntegrationHistoryItemView } from 'types/dto/contracts-service';

type Props = {
  isModalOpen: boolean;
  info: IInfoModal;
  onCancel: () => void;
  tableData: IntegrationHistoryItemView[];
  tableDataLoading: boolean;
  setInfoPagination: (pagination: IInfoPagination) => void;
  infoPagination: IInfoPagination;
} & React.HTMLAttributes<HTMLButtonElement>;

const InfoModal: React.FC<Props> = ({
  isModalOpen,
  info,
  onCancel,
  tableData,
  tableDataLoading,
  setInfoPagination,
  infoPagination,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(resetData());
    }
  }, [isModalOpen]);

  return (
    <Modal
      centered
      footer={null}
      closable={false}
      visible={isModalOpen}
      className={cx(styles.container)}
      onCancel={onCancel}
      width={800}
    >
      <div className={styles.Info}>
        <div className={styles.close}>
          <div className={styles.closeIcon} onClick={onCancel}>
            <img src={closeIcon} width="16" height="16" alt="close icon" />
          </div>
        </div>
        <div>
          <b>{t('insurance_contracts.package_info')} </b>
          {` ${info.insuranceProgramName}`}
        </div>
        <div>
          <b>№</b>
          {` ${info.packageNumber}`}
        </div>
        <div>
          <b>{t('insurance_contracts.from_info')}</b>
          {` ${moment(info.importDate).format('DD.MM.YYYY')}`}
        </div>
      </div>
      <InfoTable
        data={tableData}
        loading={tableDataLoading}
        type={info.transferType}
        setInfoPagination={setInfoPagination}
        pagination={infoPagination}
        historyId={info.historyId}
        isModalOpen={isModalOpen}
      />
    </Modal>
  );
};

export default InfoModal;
