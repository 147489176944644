export type AnyObject = { [x: string]: any };

// #TODO migrate to BCP-47 language tag format
export enum Language {
  UA = 'UA',
  RU = 'RU',
  EN = 'EN',
}

export enum LanguageForApex {
  UZ = 'UZ',
  RU = 'RU',
}

export interface Params {
  id: string;
  tab: string;
}
export interface LocationState {}
export type UseHistory = LocationState | undefined;

export interface Option {
  value: string | number;
  key: string | number;
  title: string;
}

interface ErrorDto {
  code: string;
  details?: string;
}
export interface ErrorsDto {
  errors: ErrorDto[];
}
export interface ErrorEDto {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

export interface REST_Country {
  name: string;
  independent: boolean;
}

// TODO move to types/dto
export enum UserType {
  PERSON = 'PERSON',
  ENTREPRENEUR = 'ENTREPRENEUR',
  COMPANY = 'COMPANY',
}
