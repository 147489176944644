//libs
import { createTypes } from 'redux-compose-reducer';
import { notification } from 'antd';
//api
import {
  getInsProducts,
  getInsPrograms,
  getInsuranceContracts,
  getIntegrationTypes,
  getPartners,
  getPrivateFiltersTable,
  getTablePageData,
  ICustomPagination,
} from 'api/insuranceContracts';
//types
import { Dispatch } from 'redux';
import { AppState } from '../reducer';
import { AllActions } from './actionTypes';
import { ResultList, InsuranceImportDetail, IInsuranceData } from 'types/insuranceImport';
import { IntegrationItemView } from 'types/dto/contracts-service';
export const TYPES = createTypes('insuranceContracts', [
  'setData',
  'setLoading',
  'setPagination',
  'setFiltersDates',
  'setTablePage',
  'setPagination',
  'setSorting',
  'getPrivateFiltersValues',
  'getPrivateFiltersData',
  'getPrivateFilteredData',
  'setCurrentCompanies',
  'setTotalInformation',
  'setIntegrationTypes',
  'setPrograms',
  'setPartners',
  'setChosenPartner',
  'setChosenProducts',
  'setProgram',
  'setProducts',
]);

// action creators
export const setData = (data: IntegrationItemView[], count: number): AllActions<IInsuranceData> => ({
  type: TYPES.setData,
  payload: {
    data,
    count,
  },
});
export const setLoading = (loading: boolean): AllActions<boolean> => ({ type: TYPES.setLoading, payload: loading });
export const setPagination = (pagination: ICustomPagination): AllActions<ICustomPagination> => ({
  type: TYPES.setPagination,
  payload: pagination,
});

export const setSorting = ({ field, direction }: { field: string; direction: string }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};

//thunks
export const fetch = t => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch(setLoading(true));
  const {
    insuranceContracts: { pagination },
  } = getState();
  try {
    const {
      data: { resultList = [], count = 0 },
    } = await getInsuranceContracts(pagination);

    if (!resultList.length) {
      notification.warning({
        message: t('popup.nothing_found'),
        description: t('popup.check_request'),
      });
    }

    dispatch(setData(resultList, count));
    dispatch(setPagination({ ...pagination }));
  } catch (err) {
    notification.error({
      message: t('popup.loading_insurance_companies'),
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export const getFilteredContracts = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    insuranceContracts: { pagination, sorting, filters },
  }: AppState = getState();
  try {
    const { data } = await getPrivateFiltersTable({
      filters: {
        ...filters,
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-operationDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
      t,
    });

    dispatch({ type: TYPES.getPrivateFiltersData, payload: data });
    dispatch(setPagination({ ...pagination }));
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getFilteredData = (filtersInfo: object) => async (dispatch: any, getState: any) => {
  const {
    insuranceContracts: { pagination, sorting, filters },
  }: AppState = getState();
  try {
    const { data } = await getPrivateFiltersTable({
      filters: {
        ...filters,
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-operationDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
    });

    dispatch({ type: TYPES.getPrivateFiltersData, payload: data });
    dispatch(setPagination({ ...pagination }));
  } catch (error) {
    notification.error({
      message: 'Помилка застосування приватних фільтрів',
      description: 'Спробуйте ще раз пізніше',
    });
  }
};

// data in privat filter modal
export const getFilteredPrivateData = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    insuranceContracts: { pagination, sorting, filters },
  }: AppState = getState();
  try {
    const { data } = await getPrivateFiltersTable({
      filters: {
        ...filters,
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-operationDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination: {
        current: 1,
        perPage: pagination.perPage,
      },
      t,
    });

    dispatch({ type: TYPES.getPrivateFilteredData, payload: data });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getTablePage = (t?) => async (dispatch: any, getState: any) => {
  const {
    insuranceContracts: { pagination, sorting, filters },
  }: AppState = getState();

  dispatch({ type: TYPES.setTablePage, pending: true });
  try {
    const { data } = await getTablePageData({ pagination, sorting, filters }, t);

    dispatch({ type: TYPES.setTablePage, payload: data, pending: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setTablePage, pending: false });
  }
};

export const setFiltersDates = ({ importType, insuranceProgramId, partnerId, dateFrom, dateTo, productId }: any) => (
  dispatch: any,
) =>
  dispatch({
    type: TYPES.setFiltersDates,
    payload: {
      importType,
      insuranceProgramId,
      partnerId,
      dateFrom,
      dateTo,
      productId,
    },
  });

export const getIntegration = () => async (dispatch: any, getState: any) => {
  try {
    const res = await getIntegrationTypes();

    dispatch({ type: TYPES.setIntegrationTypes, payload: res.data.resultList ?? [] });
  } catch (error) {
    console.log(error);
  }
};

export const getPartnersData = () => async (dispatch: any) => {
  try {
    const request = await getPartners();
    dispatch({ type: TYPES.setPartners, payload: request.data.resultList ?? [] });
  } catch (error) {
    console.log(error);
  }
};

export const getProgram = (partnerId, productId) => async (dispatch: any) => {
  try {
    const request = await getInsPrograms(partnerId, productId);
    dispatch({ type: TYPES.setPrograms, payload: request.data.resultList ?? [] });
  } catch (error) {
    console.log(error);
  }
};

export const setProducts = partnerId => async (dispatch: any) => {
  try {
    const request = await getInsProducts(partnerId);
    dispatch({ type: TYPES.setProducts, payload: request.data.resultList ?? [] });
  } catch (error) {
    console.log(error);
  }
};
