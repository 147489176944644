import createInstance from './axios';
import { AxiosResponse } from 'axios';

import {
  ResultListDtoPartnerView,
  ResultListDtoDepartmentView,
  MonthInfoDto,
  SalesStatisticsDtoLong,
  SalesStatisticsDtoBigdecimal,
  ClaimPaymentsStatisticDto,
  ResultListDtoInsuranceCompanyView,
  CompanyView,
  DashboardInfoDto,
  DaysStatisticsDto,
  InsuranceProgramStatisticsDto,
  PartnerDepartmentStatisticsDto,
  PartnerStatisticsDto,
  ProductRiskStatisticsDto,
} from 'types/dto/contracts-service';
import { ResultListDtoInsuranceProgramView } from 'types/dto/configuration-service';

const contractApi = createInstance('CONTRACTS');
const contractPDFApi = createInstance('CONTRACTS', 'PDF');
const configApi = createInstance('CONFIGURATION');

export interface IOption {
  id: number;
  name: string;
}

export interface IStatistics {
  currentValue: number;
  previousValue: number;
}

export const getCurrentCompanies = () => {
  return contractApi.get<ResultListDtoPartnerView>('/partners/?distinct&page_size=300');
};

export const getSubdivisions = (partnerId?: number) => {
  const requestParams = {
    partnerId,
  };

  Object.keys(requestParams).forEach(key => {
    if (requestParams[key] !== 'test' && typeof requestParams[key] !== 'boolean') {
      return !requestParams[key] && delete requestParams[key];
    }
  });

  return contractApi.get<ResultListDtoDepartmentView>('/departments', {
    params: { ...requestParams, distinct: true },
  });
};

export const getMonthInfo = ({ ...params }) => {
  return contractApi.get<MonthInfoDto>('/statistics/month-info/', {
    params,
  });
};

export const getBrandSales = ({ ...params }) => {
  return contractApi.get<SalesStatisticsDtoLong[]>('/statistics/brand-sales', {
    params,
  });
};

export const getOrdinaryPayments = ({ ...params }) => {
  return contractApi.get<SalesStatisticsDtoBigdecimal[]>('/statistics/payments', {
    params: {
      ...params,
    },
  });
};

export const getСlaimPayments = ({ ...params }) => {
  return contractApi.get<ClaimPaymentsStatisticDto[]>('/statistics/claim-payments', {
    params: {
      ...params,
    },
  });
};

export const getInsuranceCompanies = (insuranceConpanyId?: number) => {
  const id = insuranceConpanyId ? insuranceConpanyId : '<>null';

  return contractApi.get<ResultListDtoInsuranceCompanyView>(
    `/insurance-companies/?distinct&attributes=id,name&insuranceCompanyId=${id}`,
  );
};

export const getInsuranceProgram = (id?: number) => {
  return configApi.get<ResultListDtoInsuranceProgramView>(
    `/insurance-programs/?attributes=id,name&distinct${id ? `&partnerId=${id}` : ''}`,
  );
};

export const getTerminationInsuranceProgram = (partnerId?: number, insuranceCompanyId?: number): Promise<any> => {
  const partnerIdLocal = partnerId ? partnerId : '<>null';
  const insuranceCompanyIdLocal = insuranceCompanyId ? insuranceCompanyId : '<>null';

  return contractApi
    .get<CompanyView[]>(
      `/companies?partnerId=${partnerIdLocal}&insuranceCompanyId=${insuranceCompanyIdLocal}&partnerDepartmentId=null`,
    )
    .then(response => ({
      data: response.data.map(el => ({ id: el.partnerId, name: el.name, shortName: el.shortName })),
    }));
};

export const insuranceCompanies = (): Promise<AxiosResponse<IOption[]>> => {
  const params = {
    distinct: true,
    attributes: ['id', 'name'].join(),
    page_size: -1,
  };

  return contractApi.get('/insurance-companies', { params });
};

export const getDashboardInfoUsingGet = (query: {
  endDate: string;
  insuranceCompanyId?: number;
  partnerDepartmentId?: number;
  partnerId?: number;
  startDate: string;
}) => {
  return contractApi.get<DashboardInfoDto>('/statistics/dashboard-info', { params: query });
};

export const printXlsxDaysStatisticsUsingPost = (daysStatistics: DaysStatisticsDto[]) => {
  return contractPDFApi.post<Blob>('/statistics/days/print/xlsx', daysStatistics, { responseType: 'blob' });
};

export const printXlsxInsuranceProgramStatisticsUsingPost = (programStatistics: InsuranceProgramStatisticsDto[]) => {
  return contractPDFApi.post<Blob>('/statistics/insurance-program/print/xlsx', programStatistics, {
    responseType: 'blob',
  });
};

export const printXlsxRiskStatisticsUsingPost = (riskStatistics: ProductRiskStatisticsDto[]) => {
  return contractPDFApi.post<Blob>('/statistics/risk/print/xlsx', riskStatistics, { responseType: 'blob' });
};

export const printXlsxPartnerDepartmentStatisticsUsingPost = (
  partnerDepartmentStatistics: PartnerDepartmentStatisticsDto[],
) => {
  return contractPDFApi.post<Blob>('/statistics/partner-department/print/xlsx', partnerDepartmentStatistics, {
    responseType: 'blob',
  });
};

export const printXlsxPartnerStatisticsUsingPost = (partnerStatistics: PartnerStatisticsDto[]) => {
  return contractPDFApi.post<Blob>('/statistics/partner/print/xlsx', partnerStatistics, {
    responseType: 'blob',
  });
};
