import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

// replace all svg with the line
// <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">

const Excel = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    <path
      d="M1004.928 132.352h-343.04V195.84h101.12v100.437333h-101.12v32h101.12v100.565334h-101.12v32.896h101.12v95.104h-101.12v38.101333h101.12v95.317333h-101.12v38.101334h101.12v95.872h-101.12v69.930666h343.04c5.418667-1.621333 9.941333-8.021333 13.568-19.114666 3.626667-11.178667 5.504-20.266667 5.504-27.136V143.957333c0-5.461333-1.877333-8.746667-5.504-9.898666a46.933333 46.933333 0 0 0-13.568-1.706667zM960.426667 824.149333h-164.565334v-95.786666h164.565334v95.872-0.085334z m0-133.888h-164.565334v-95.36h164.565334v95.36z m0-133.461333h-164.565334v-94.677333h164.565334v94.762666-0.085333z m0-128h-164.565334V328.362667h164.565334v100.48-0.042667z m0-133.077333h-164.565334v-99.84h164.565334v100.48-0.64zM0 116.522667v793.642666l604.16 104.490667V9.344L0 116.864v-0.341333z m358.101333 600.362666c-2.304-6.229333-13.141333-32.682667-32.341333-79.488a5199.36 5199.36 0 0 0-34.090667-81.749333h-1.066666l-64.810667 154.24-86.613333-5.845333 102.741333-192-94.08-192 88.32-4.650667 58.368 150.229333h1.152l65.92-157.056 91.264-5.76-108.672 207.786667 112 211.968-98.090667-5.76v0.085333z"
      p-id="17102"
    ></path>
  </svg>
);

const Plane = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    <path
      d="M898.272 91.424q25.152 29.728 6.848 84.576t-61.728 98.272l-92 92 91.424 397.728q2.848 10.848-6.848 18.848l-73.152 54.848q-4 3.424-10.848 3.424-2.272 0-4-0.576-8.576-1.728-12-9.152l-159.424-290.272-148 148 30.272 110.848q2.848 9.728-4.576 17.728l-54.848 54.848q-5.152 5.152-13.152 5.152l-1.152 0q-8.576-1.152-13.728-7.424l-108-144-144-108q-6.272-4-7.424-13.152-0.576-7.424 5.152-14.272l54.848-55.424q5.152-5.152 13.152-5.152 3.424 0 4.576 0.576l110.848 30.272 148-148-290.272-159.424q-8-4.576-9.728-13.728-1.152-9.152 5.152-15.424l73.152-73.152q8-7.424 17.152-4.576l380 90.848 91.424-91.424q43.424-43.424 98.272-61.728t84.576 6.848z"
      p-id="1008"
    ></path>
  </svg>
);

export type Type = 'excel' | 'plane';

export const iconMap: Record<Type, (props: IconProps) => JSX.Element> = {
  excel: props => <Icon component={Excel} {...props} />,
  plane: props => <Icon component={Plane} {...props} />,
};
