import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';

import moment from 'moment';
import { insuredEventsListType } from 'types/insuredEventsList';

const initialState: insuredEventsListType = {
  pagination: {
    current: 1,
    perPage: 50,
  },
  data: [],
  total: 0,
  filters: {
    claimDate: `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}..${moment().format('YYYY-MM-DD')}`,
    insuranceCompanyId: {
      id: null,
      name: null,
    },
    insuranceProgramId: {
      id: null,
      name: null,
    },
    insurancePartnerId: {
      id: null,
      name: null,
    },
  },
  chooseType: {},
  sorting: {
    field: '-claimDate',
    direction: 'DESC',
  },
  pending: false,
  activeTab: 'Реєстр страхових випадків',
  insuranceCompanies: [],
  partners: [],
  insurancePrograms: [],
  privateFilters: {},
  totalInfo: null,
  privateFilteredData: [],
  privateFilterItems: {},
  filteredActiveTab: '',
};

const fetch = (state: insuredEventsListType, { data, total }: any): insuredEventsListType => {
  return { ...state, data, total };
};

const setPrivateFilters = (state: insuredEventsListType, { privateFilters }: any): insuredEventsListType => {
  return {
    ...state,
    privateFilters: privateFilters,
  };
};

const setInsuredEventsListPrivateFilters = (
  state: insuredEventsListType,
  { privateFiltersItems }: any,
): insuredEventsListType => {
  return {
    ...state,
    privateFilterItems: privateFiltersItems,
  };
};

const setFilteredActiveTab = (state: insuredEventsListType, { filteredActiveTab }: any): insuredEventsListType => {
  return {
    ...state,
    filteredActiveTab: filteredActiveTab,
  };
};

const setPending = (state: insuredEventsListType, { pending }: any): insuredEventsListType => {
  return { ...state, pending };
};

const setSorting = (
  state: insuredEventsListType,
  { field, direction }: { type: string; field: string; direction: string },
): insuredEventsListType => {
  return { ...state, sorting: { field, direction } };
};

const setPagination = (
  state: insuredEventsListType,
  { page, perPage }: { type: string; page: number; perPage: number },
): insuredEventsListType => {
  return {
    ...state,
    pagination: {
      current: page,
      perPage: perPage,
    },
  };
};

const setFilters = (
  state: insuredEventsListType,
  { filters }: { type: string; [x: string]: any },
): insuredEventsListType => {
  return {
    ...state,
    filters,
  };
};

const setSelectedChoose = (
  state: insuredEventsListType,
  { chooseType }: { type: string; [x: string]: any },
): insuredEventsListType => {
  return {
    ...state,
    chooseType,
  };
};

const setActiveTab = (state: insuredEventsListType, { activeTab }: any): insuredEventsListType => ({
  ...state,
  activeTab,
});

const setInsuranceCompanies = (state: insuredEventsListType, { payload }: any): insuredEventsListType => {
  return {
    ...state,
    insuranceCompanies: payload.insuranceCompanies || state.insuranceCompanies,
  };
};

const setPartners = (state: insuredEventsListType, { payload }: any): insuredEventsListType => {
  return {
    ...state,
    partners: payload.partners || state.partners,
  };
};

const setInsuredELInsuranceProgram = (state: insuredEventsListType, { payload }: any): insuredEventsListType => ({
  ...state,
  insurancePrograms: payload.insurancePrograms || state.insurancePrograms,
});

const setTotalInformation = (state: insuredEventsListType, { payload }: AnyAction): insuredEventsListType => ({
  ...state,
  totalInfo: { ...payload },
});

const getPrivateFilteredData = (state: insuredEventsListType, { payload }: AnyAction): insuredEventsListType => {
  const { resultList }: { resultList: [] } = payload;
  return {
    ...state,
    privateFilteredData: resultList,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    fetch,
    setSorting,
    setFilters,
    setSelectedChoose,
    setPending,
    setActiveTab,
    setPagination,
    setPrivateFilters,
    setInsuredEventsListPrivateFilters,
    setFilteredActiveTab,
    setTotalInformation,
    setInsuranceCompanies,
    setPartners,
    getPrivateFilteredData,
    setInsuredELInsuranceProgram,
  },
});
