import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';

import InputContainer, { Margin, Info } from '../InputContainer/InputContainer';

interface Props extends CheckboxProps {
  margin?: Margin;
  info?: Info;
  classNameContainer?: string;
}

const Checkbox = ({ info, margin, classNameContainer, ...props }: Props) => {
  return (
    <InputContainer info={info} margin={margin} className={classNameContainer} colorBase="none">
      <AntdCheckbox data-test={info?.name} {...props} />
    </InputContainer>
  );
};

export default Checkbox;
