import React from 'react';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import { ROUTES } from 'constants/routes';
import PageHeader from 'components/PageHeader';
import { useTranslation } from 'react-i18next';
import MedAppCreateForm from './MedAppCreateForm';

const MedAppCreate = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('partner_list.settings'), href: ROUTES.SETTINGS.ROOT },
          { label: t('medapp.header'), href: ROUTES.MEDAPP.ROOT },
          {
            label: t('medapp.add'),
          },
        ]}
      />
      <PageHeader title={t('medapp.add')} />
      <MedAppCreateForm />
    </>
  );
};

export default MedAppCreate;
