import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DaysStatisticsDto } from 'types/dto/contracts-service';
import Button from 'components/next/Button/Button';
import Icon from 'components/next/Icon/Icon';
import * as api from 'api/statistics';
import onDownload from 'callbacks/onDownload';

import Card, { cssTitle } from '../Card/Card';
import Chart from './ByMonthChart/ByMonthChart';
import Table from './ByMonthTable/ByMonthTable';
import Switch from '../Switch/Switch';
import { transformDataForChart } from './ByMonth.model';

interface Props {
  data: DaysStatisticsDto[];
  currency: string;
}

const ByMonth = ({ data, currency }: Props) => {
  const { t } = useTranslation();
  const [isChart, setIsChart] = useState(true);

  const transformedDataforChart = useMemo(() => transformDataForChart(data), [data]);
  const hasData = Boolean(data.length);

  const onClickExcel = () => {
    onDownload(() => api.printXlsxDaysStatisticsUsingPost(data), {}, t);
  };

  return (
    <Card
      isChart={isChart}
      hasData={hasData}
      title={
        <>
          <span className={cssTitle}>{t('dashboard_extend.byMonth.title')}</span>
          {hasData && (
            <Switch
              checked={isChart}
              onChange={() => {
                setIsChart(!isChart);
              }}
            />
          )}
        </>
      }
      extra={
        hasData && (
          <Button onClick={onClickExcel}>
            <Icon type="excel" color="primary" />
            Excel
          </Button>
        )
      }
    >
      {isChart ? (
        <Chart data={transformedDataforChart} currency={currency} />
      ) : (
        <Table data={data} currency={currency} />
      )}
    </Card>
  );
};

export default ByMonth;
