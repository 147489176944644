import { notification } from 'antd';
//: { [key: string]: any }
export const errorsAdapter = t => {
  return {
    PHONE_NUMBER_ALREADY_REGISTERED: {
      code: t('validation.number_already_exists'),
      name: 'phoneNumber',
    },
    EMAIL_ALREADY_REGISTERED: {
      code: t('validation.email_already_exists'),
      name: 'email',
    },
    INVALID_OLD_PASSWORD: {
      name: 'oldPassword',
      code: t('validation.password_incorrect'),
    },
    PASSWORD_GLOBAL: t('validation.failed_change_password'),
    GLOBAL: t('validation.try_again_later'),
  };
};
export const errorHandler = (error: any, form: any, globalError: string, t) => {
  error?.response?.data?.errors?.forEach((el: any) => {
    if (errorsAdapter(t)[el.code]) {
      const name = `${errorsAdapter(t)[el.code]['name']}`;
      const errorMessage = errorsAdapter(t)[el.code]['code'];
      const value = form.getFieldValue(name);

      return form.setFields({
        [name]: {
          value: value,
          errors: [new Error(errorMessage)],
        },
      });
    }
    return notification.error({
      message: t('validation.something_went_wrong'),
      description: globalError,
    });
  });
};
