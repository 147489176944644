import { dateFormatter, moneyFormatter } from 'utils/helpers';

interface IContractsData {
  id: number;
  number: string;
  clientName: string;
  conclusionDate: string;
  subAgreementConclusionDate: string;
  startDate: string;
  endDate: string;
  status: [string];
  verifiedAt?: string;
  insuranceAmount: number;
  paymentAmount: number;
  numberPayments: number;
  calculatedPaymentAmount: number;
  imei: string;
  pid: string;
  subjectName: string;
  manufacturer: string;
  brand: string;
  model: string;
  feeSum: number;
  baseFeeSum: number;
  toPay: number;
  returnedPayment: number;
  returnedToPartner: number;
}

export const formatContractsData = (dataArr: [IContractsData], currency: string) => {
  if (!dataArr) {
    return [];
  } else {
    const newData = dataArr.map(data => ({
      ...data,
      insuranceAmount: `${moneyFormatter(data.insuranceAmount)}`,
      paymentAmount: `${moneyFormatter(data.paymentAmount)}`,
      calculatedPaymentAmount: `${moneyFormatter(data.calculatedPaymentAmount)}`,
      feeSum: `${moneyFormatter(data.feeSum)}`,
      baseFeeSum: `${moneyFormatter(data.baseFeeSum)}`,
      toPay: `${moneyFormatter(data.toPay)}`,
      returnedPayment: `${moneyFormatter(data.returnedPayment)}`,
      returnedToPartner: `${moneyFormatter(data.returnedToPartner)}`,
      conclusionDate: dateFormatter(data.conclusionDate),
      subAgreementConclusionDate: data.subAgreementConclusionDate ? dateFormatter(data.subAgreementConclusionDate) : '',
      startDate: dateFormatter(data.startDate),
      endDate: dateFormatter(data.endDate),
      verifiedAt: data.verifiedAt ? dateFormatter(data.verifiedAt) : '',
    }));
    return newData;
  }
};

export const modifySaleRegisterStatuses = (request: any) => {
  let data: Array<{ id: string; name: string }> = [];

  for (const [key, value] of Object.entries(request?.data)) {
    data.push({ id: key, name: value as string });
  }

  return data;
};
