import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';

import {
  changeContractsStatus,
  getActDetailsAction,
  getFilteredActsDetails,
  setPagination,
  setSorting,
} from 'store/actDetails/actions';
import { formatContractsData } from 'components/tables/ContractListTable/helper';
import { actDetailsSelector } from 'store/actDetails/selectors';
import CustomTable from 'components/CustomTable';
import columnsList from './columns';
import { FilterIcon } from 'components/Icons';
import { percentFormatter } from 'utils/helpers';
import PrivateFilter from 'components/PrivateFilter';
import styles from './ActDetailsTable.module.less';
import style from './styles';
import { useTranslation } from 'react-i18next';

interface IActDetailsTable {
  id?: string;
}

const ActDetailsTable = (props: IActDetailsTable) => {
  const { data, pagination, total, pending, actInfo } = useSelector(actDetailsSelector);
  const [clearedFilters, setClearedFilters] = useState<boolean>(false);
  const [filtersInfo, setInfo] = useState<any>({});
  const dispatcher = useDispatch();
  const { id } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      dispatcher(getActDetailsAction(id, actInfo));
    }
  }, []);

  const getStatus = statusValue => {
    return statusValue ? 'ACTIVE' : 'INACTIVE';
  };

  const onStatusChange = (status, row) => {
    const currentStatus = getStatus(status);
    dispatcher(changeContractsStatus(row.id, currentStatus));
  };

  const setFiltersInfo = (values: any) => {
    values && Object.keys(values).forEach(el => !values[el] && delete values[el]);
    setInfo({ ...values });
  };

  const onPageSizeChange = (pageSize: string): void => {
    dispatcher(setPagination(1, Number(pageSize) || 0));
    getDataFromPrivateFilter(filtersInfo, { current: 1, perPage: Number(pageSize) });
  };

  const onPageChange = (page: number, pageSize?: number | undefined): void => {
    dispatcher(setPagination(page, pageSize || 0));
    getDataFromPrivateFilter(filtersInfo, { current: page, perPage: pageSize || 0 });
  };

  const filteredBySearching = (filterBy: string, value: string, type: string) => {
    if (type === 'date') {
      setFiltersInfo({
        [filterBy]: [`${value[0]}..${value[1]}`],
      });
    }

    getDataFromPrivateFilter(type === 'date' ? { [filterBy]: [`${value[0]}..${value[1]}`] } : { [filterBy]: value });
  };

  const getDataPrivateFilter = (filtersInfoo: { [key: string]: any } | null) => {
    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });

    dispatcher(
      getFilteredActsDetails(
        id,
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const getTableData = () => {
    dispatcher(getActDetailsAction(id, actInfo));
  };

  const getColumnSearchProps = (dataIndex: string, isDate?: boolean, dropDown: boolean = true) => {
    const filteredValue = (filtersInfo && filtersInfo[dataIndex]) || null;
    return (
      dropDown && {
        filteredValue,
        filters:
          (data &&
            data.length &&
            dataIndex !== 'status' &&
            data.map((item: any) => {
              return { value: item[dataIndex] ? String(item[dataIndex]) : '' };
            })) ||
          [],
        filterIcon: (filtered: boolean) => (
          <div>
            <FilterIcon color={filtered ? '#fff' : null} className={classnames({ active: filtered })} />
          </div>
        ),
        filterDropdown: (record: any) => {
          return (
            <PrivateFilter
              record={record}
              dataIndex={dataIndex}
              filtersInfo={filtersInfo}
              setFiltersInfo={setFiltersInfo}
              getDataFromPrivateFilter={getDataFromPrivateFilter}
              getDataPrivateFilter={getDataPrivateFilter}
              filteredBySearching={filteredBySearching}
              isDate={isDate}
              isClearedFilters={clearedFilters}
              clearAllFilters={() => setClearedFilters(false)}
              getTablePage={getTableData}
            />
          );
        },
      }
    );
  };

  const getDataFromPrivateFilter = (additionalFilters: any, pagination?: any) => {
    dispatcher(setPagination(1, Number(perPage) || 1));
    if (pagination) {
      dispatcher(setPagination(pagination.current, Number(pagination.perPage) || 1));
    } else {
      dispatcher(setPagination(1, Number(perPage) || 1));
    }

    additionalFilters &&
      Object.keys(additionalFilters).forEach((key: any) => {
        if (!additionalFilters[key] || additionalFilters[key] === null || !additionalFilters[key].length) {
          additionalFilters && delete additionalFilters[key];
        }
      });

    setFiltersInfo({
      ...additionalFilters,
    });
    dispatcher(
      getFilteredActsDetails(
        id,
        {
          ...additionalFilters,
        },
        t,
      ),
    );
  };

  const clearFilters = () => {
    dispatcher(getActDetailsAction(id, actInfo));
    setFiltersInfo({});
    setClearedFilters(true);
  };

  const handleFilters = (value: {}) => {
    setFiltersInfo({
      ...filtersInfo,
      ...value,
    });
  };

  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? 'ASC' : 'DESC';
      }
      return '';
    };

    const formatSorting = {
      field: sorting.field,
      direction: formatDirection(sorting.order),
    };
    dispatcher(setSorting(formatSorting));
    getDataFromPrivateFilter(filtersInfo);
  };

  const columns = columnsList(t).map(({ title, value, width }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
      width: width ? width : 220,
      ...getColumnSearchProps(value),
      sorter: true,
    };
    switch (value) {
      case 'status': {
        return {
          ...basicColumn,
          render: (status: string, row) => {
            return (
              <div className={styles.toogleBtn}>
                <Switch
                  disabled={actInfo ? !!actInfo.actSignDate : false}
                  defaultChecked={true}
                  onChange={status => onStatusChange(status, row)}
                />
              </div>
            );
          },
          sorter: false,
        };
      }
      case 'feePercent':
        return {
          ...basicColumn,
          render: (value: number) => `${percentFormatter(value)} %`,
        };
      default: {
        return basicColumn;
      }
    }
  });

  const { current, perPage } = pagination;

  return (
    <CustomTable
      {...props}
      columns={columns}
      data={formatContractsData(data)}
      page={current}
      contentHeight={410}
      pageSize={perPage}
      total={total || 0}
      pending={pending || false}
      filtersInfo={filtersInfo}
      clearFilters={clearFilters}
      handleFilters={handleFilters}
      handleTableChange={handleTableChange}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      isRowClick={false}
    />
  );
};

export default withStyles(style)(ActDetailsTable);
