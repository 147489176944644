import { superstructResolver } from '@hookform/resolvers/superstruct';

import { AnyObject } from 'types';
import { Struct } from 'utils/struct';

export const getRequired = async (schema: Struct<any, any>, values: AnyObject) => {
  const { errors } = await superstructResolver(schema)(values);

  return errors;
};
