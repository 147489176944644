export default {
  ui: {
    pagination: {
      size: 50,
      options: [20, 50, 100, 150],
    },
    search: {
      delay: 300,
    },
  },
  roles: {
    ROLE_PARTNER_SELLER: {
      name: 'Продавець',
    },
    ROLE_PARTNER_MANAGER: {
      name: 'Менеджер ЦО Партнера',
    },
    ROLE_PARTNER_ADMIN: {
      name: 'Адміністратор ЦО Партнера',
    },
    ROLE_INSURANCE_MANAGER: {
      name: 'Менеджер СК',
    },
    ROLE_INSURANCE_CLAIM_MANAGER: {
      name: 'Менеджер СК по виплатам',
    },
    ROLE_INSURANCE_ADMIN: {
      name: 'Адміністратор СК',
    },
    ROLE_ADMIN: {
      name: 'Адміністратор системи',
    },
    ROLE_CLIENT: {
      name: 'Клієнт',
    },
    ROLE_HR_MANAGER: {
      name: 'HR менеджер',
    },
  },
  format: {
    date: 'DD.MM.YYYY',
    financial: '0,0.00',
  },
};
