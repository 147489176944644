import { createTypes } from 'redux-compose-reducer';
import { Dispatch } from 'redux';
import { AppState } from '../reducer';
import { IPagination } from '../../components/TableFooter/TableFooter';
import * as UserActivitiesAPI from 'api/UserActivities';
import onDownload from '../../callbacks/onDownload';
import { downloadActivitiesAsXLS } from 'api/UserActivities';
import moment from 'moment';

export const TYPES = createTypes('usersActivities', [
  'setData',
  'setQuery',
  'setLoading',
  'setPagination',
  'getUsersActionTypes',
  'removeQuery',
]);

export const fetch = (logContractId?: number) => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch({ type: TYPES.setLoading, payload: true });

  const {
    usersActivities: { query, pagination },
  } = getState();

  const {
    data: { resultList: activities = [], count = 0 },
  } = await UserActivitiesAPI.fetch(
    {
      ...query,
      ...(logContractId ? { logContractId } : {}),
    },
    pagination,
  );
  dispatch({ type: TYPES.setData, payload: { activities, count } });
  dispatch({ type: TYPES.setPagination, payload: { ...pagination, total: count } });
  dispatch({ type: TYPES.setLoading, payload: false });
};

export const setData = () => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setData, payload: [] });
};

export const setQuery = (query: any) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setQuery, payload: query });
};

export const removeQuery = (fieldName: string) => dispatch => {
  dispatch({ type: TYPES.removeQuery, payload: fieldName });
};

export const setLoading = (loading: boolean) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setLoading, payload: loading });
};

export const setPagination = (pagination: IPagination) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setPagination, payload: pagination });
};

export const getUsersActionTypes = () => async (dispatch: Dispatch) => {
  try {
    const response = await UserActivitiesAPI.getUserActivityActionTypes();
    dispatch({ type: TYPES.getUsersActionTypes, payload: response.data });
  } catch (e) {
    console.error(e);
  }
};

export const activitiesXSLXDownload = t => async (dispatch, getState) => {
  const {
    usersActivities: {
      query: { from, to, insuranceCompanyId, partnerId, partnerDepartmentId, login, shortName },
    },
  }: AppState = getState();

  return onDownload(
    () =>
      downloadActivitiesAsXLS({
        activityDate: `${moment(from).format('YYYY-MM-DD')}T00:00:00..${moment(to).format('YYYY-MM-DD')}T23:59:59`,
        timeZone: 'Europe/Kiev',
        insuranceCompanyId,
        partnerId,
        partnerDepartmentId,
        login,
        shortName,
      }),
    {},
    t,
  );
};
