import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/next/Button/Button';
import Icon from 'components/next/Icon/Icon';
import { InsuranceProgramStatisticsDto } from 'types/dto/contracts-service';
import onDownload from 'callbacks/onDownload';
import * as api from 'api/statistics';

import Card, { cssTitle } from '../Card/Card';
import Chart from './ByProgramChart/ByProgramChart';
import Table from './ByProgramTable/ByProgramTable';
import Switch from '../Switch/Switch';
import { transformData, transformDataForChart, getTotal } from './ByProgram.model';

interface Props {
  data: InsuranceProgramStatisticsDto[];
  currency: string;
}

const ByProgram = ({ data, currency }: Props) => {
  const { t } = useTranslation();
  const [isChart, setIsChart] = useState(true);

  const transformedData = useMemo(() => transformData(data), [data]);
  const transformedDataForChart = useMemo(() => transformDataForChart(transformedData), [transformedData]);
  const total = useMemo(() => getTotal(transformedData), [transformedData]);
  const hasData = Boolean(transformedData.length);

  const onClickExcel = () => {
    onDownload(() => api.printXlsxInsuranceProgramStatisticsUsingPost(data), {}, t);
  };

  return (
    <Card
      isChart={isChart}
      hasData={hasData}
      title={
        <>
          <span className={cssTitle}>{t('dashboard_extend.byProgram.title')}</span>
          {hasData && (
            <Switch
              checked={isChart}
              onChange={() => {
                setIsChart(!isChart);
              }}
            />
          )}
        </>
      }
      extra={
        hasData && (
          <Button onClick={onClickExcel}>
            <Icon type="excel" color="primary" />
            Excel
          </Button>
        )
      }
    >
      {isChart ? (
        <Chart data={transformedDataForChart} currency={currency} total={total} />
      ) : (
        <Table data={transformedData} currency={currency} total={total} />
      )}
    </Card>
  );
};

export default ByProgram;
