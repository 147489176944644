import { useSelector } from 'react-redux';
import { AppState } from '../store/reducer';

export const currencyAdapter = (name, currency?: string) =>
  ({
    UZS: 'UZS',
    UAH: currency || '₴',
  }[name]);

export const getCurrencyName = value =>
  ({
    UZS: 'UZS',
    '₴': 'UAH',
  }[value]);

export const useCurrency = (currency?: string) => {
  const {
    user: { insuranceCompanyCurrency },
  } = useSelector(({ user }: AppState) => ({
    user,
  }));

  return {
    currencyName: insuranceCompanyCurrency,
    currency: currencyAdapter(insuranceCompanyCurrency, currency) || currency || '₴',
  };
};
