import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

import history from 'routes/history';
import { addService, editService } from 'store/institutions/actions';
import { ROUTES } from 'constants/routes';
import { CardGrid, Cards, Text, ModalHeader, ModalText } from './styles';
import FormInputText from 'components/FormInputText';
import FormSelect from 'components/FormSelect';
import Button from 'components/Button';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { formatSublimitsData, formatServicesTypesData } from '../../utils';
import { IService, RawServiceType } from 'types/institutions';
import { ISublimit, ISubsublimit } from 'types/sublimits';

interface IServiceFormProps extends FormComponentProps {
  serviceData: IService;
  serviceTypes: RawServiceType[];
  sublimitsData: ISublimit[];
  subsublimitsData: ISubsublimit[];
  errorMessage: any;
  isEdit: boolean;
}

const ServiceForm: React.FC<IServiceFormProps> = ({
  serviceData,
  serviceTypes,
  sublimitsData,
  subsublimitsData,
  errorMessage,
  isEdit,
  form,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const addNewService = data => dispatch(addService(data));
  const editServiceById = (id, data) => dispatch(editService(id, data));

  useEffect(() => {
    if (errorMessage) {
      notification.error({
        message: errorMessage,
      });
    }
  }, [errorMessage]);

  const onSubmit = e => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          let formatedData = {};
          const subData = subsublimitsData.find(el => el.id === values.subSubLimitId);
          const subDataChildless = sublimitsData.find(el => el.id === values.subSubLimitId);

          if (subData) {
            formatedData = {
              ...values,
              subLimitId: subData.sublimitId,
              subLimitName: subData.sublimitName,
              subSubLimitId: subData.id,
              subSubLimitName: subData.name,
            };
          }

          if (subDataChildless) {
            formatedData = {
              ...values,
              subLimitId: subDataChildless.id,
              subLimitName: subDataChildless.name,
            };
            formatedData = _.omit(formatedData, ['subSubLimitId']);
          }

          isEdit ? editServiceById(serviceData.id, formatedData) : addNewService(formatedData);
        } catch (e) {
          console.log('error', e);
        }
      }
    });
  };

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  const onSubmitModal = () => {
    history.push(ROUTES.MEDAPP.SERVICES.ROOT);
  };

  return (
    <CardGrid>
      <Form name="ServiceForm" onSubmit={onSubmit}>
        <Cards>
          <Text size={18} weight={600} bottomM={20}>
            {isEdit ? t('services.edit_service') : t('services.add_service')}
          </Text>
          <Grid xs="auto" container item spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form?.getFieldDecorator('name', {
                  rules: [{ required: true, message: t('services.need_enter_service_name') }],
                  validateFirst: true,
                  initialValue: serviceData && isEdit ? serviceData.name : '',
                })(<FormInputText title={t('services.name_of_service')} />)}
              </Form.Item>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form?.getFieldDecorator('medicalServiceTypeId', {
                  rules: [{ required: true, message: t('services.need_select_service_type') }],
                  validateFirst: true,
                  initialValue: serviceData && isEdit ? serviceData.parent : '',
                })(
                  <FormSelect
                    title={t('services.type_of_service')}
                    onChange={() => {}}
                    options={formatServicesTypesData(serviceTypes)}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form?.getFieldDecorator('subSubLimitId', {
                  rules: [{ required: true, message: t('services.need_select_subsublimit') }],
                  validateFirst: true,
                  initialValue: serviceData && isEdit ? serviceData.subSubLimitId || serviceData.subLimitId : '',
                })(
                  <FormSelect
                    title={t('services.subsublimit')}
                    onChange={() => {}}
                    options={formatSublimitsData([...subsublimitsData, ...sublimitsData])}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form?.getFieldDecorator('currencyValue', {
                  rules: [{ required: false, message: t('sublimits.need_enter_subsublimit') }],
                  validateFirst: true,
                  initialValue: serviceData && isEdit ? serviceData.currencyValue : '',
                })(<FormInputText title={t('services.value')} type="number" />)}
              </Form.Item>
            </Grid>
          </Grid>
        </Cards>
        <Grid container spacing={2} alignItems={'center'} justify={'flex-end'}>
          <Grid item xs={'auto'}>
            <Button variant={'contained'} size={'large'} onClick={toggleModal}>
              {t('services.cancel')}
            </Button>
          </Grid>
          <Grid item xs={'auto'}>
            <Button type="submit" color={'primary'} size={'large'}>
              {t('services.save')}
            </Button>
          </Grid>
        </Grid>
      </Form>
      <ConfirmModal
        isModalOpen={isModalOpen}
        onSubmitModal={onSubmitModal}
        onClose={toggleModal}
        closeText={t('services.cancel')}
        submitText={t('services.modal_confirm')}
      >
        <ModalHeader>{t('services.modal_header')}</ModalHeader>
        <ModalText>{t('services.modal_text')}</ModalText>
      </ConfirmModal>
    </CardGrid>
  );
};

export default Form.create<IServiceFormProps>({ name: 'ServiceForm' })(ServiceForm);
