import React from 'react';
import classNames from 'classnames';
import { Select, Tooltip } from 'antd';

import styles from '../../filters.module.less';

const { Option } = Select;

export default (props: any) => {
  const {
    label,
    labelActive,
    value,
    handleFocus,
    options,
    optionLabel,
    dataOptionId,
    dataInputId,
    shortOptionLabel,
    isTooltip = true,
  } = props;
  const handleChange = (item, id) => {
    if (props.handleChange) {
      props.handleChange(id, item);
    }
  };
  return (
    <div className={classNames(styles.fieldContainer, styles.inputFieldContainer)}>
      <div className={classNames(styles.label, { [styles.labelActive]: !labelActive })}>{label}</div>
      <Select
        data-id={dataInputId}
        value={value}
        className={styles.selectField}
        onFocus={() => handleFocus && handleFocus('focus')}
        onBlur={() => handleFocus && handleFocus('blur')}
        onChange={(_, optionKey: { key: string }) => handleChange(_, +optionKey.key)}
        {...props}
      >
        {options.map((option: any, i) => {
          const optionValue = option[i === 0 ? optionLabel : shortOptionLabel || optionLabel];

          return (
            <Option value={option.name} key={option.id} data-id={dataOptionId}>
              {isTooltip ? (
                <Tooltip placement="topLeft" title={optionValue || ''}>
                  {optionValue}
                </Tooltip>
              ) : (
                optionValue
              )}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
