import React from 'react';
import { Select as AntSelect } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import { css, cx } from '@emotion/css';

import InputContainer, { Margin, Info, ColorBase, colorBaseMap } from '../InputContainer/InputContainer';
import { theme } from 'styles';

const cssRoot = css`
  width: 100%;

  .ant-select-selection {
    border-radius: 12px;
    border: none;
    background-color: ${theme.color.blueGray.light};
    box-shadow: none;
  }
`;

export interface LabeledValue {
  key?: string | number;
  value: string | number;
  label: React.ReactNode;
}

export interface Props extends SelectProps<SelectValue> {
  label?: string;
  margin?: Margin;
  info?: Info;
  classNameContainer?: string;
  // #TODO remove when full migrate to antd v4 will be done
  options: LabeledValue[];
  colorBase?: ColorBase;
}

const Select = ({
  info,
  label,
  classNameContainer,
  className,
  margin,
  options,
  colorBase = 'grey',
  ...props
}: Props) => {
  return (
    <InputContainer info={info} label={label} className={classNameContainer} margin={margin} colorBase={colorBase}>
      <AntSelect
        data-test={info?.name}
        // For setting null after clear value
        /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        // onChange={(value, ...args) => onChange && onChange(value ?? null, ...args)}
        // onSearch={(value) => onSearch && onSearch(value)}
        // optionFilterProp="label"
        className={cx(
          cssRoot,
          css`
            .ant-select-selection {
              background-color: ${colorBaseMap[colorBase]};
            }
          `,
          className,
        )}
        // find necessary id inside root, for showing options, cuz probably we can have more then 1 same id
        getPopupContainer={() =>
          info?.name ? (document.querySelector(`div#${info.name}`) as HTMLElement) : document.body
        }
        filterOption={(input, option) =>
          // @ts-ignore
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        // filterOption={(inputValue, option) => {
        //   const splittedInputValues = inputValue.trim().toLowerCase().split(' ').filter(Boolean);
        //   const optionLabel = option?.label?.toString().toLowerCase() ?? '';

        //   const foundValues = splittedInputValues.filter((item) => {
        //     return optionLabel.includes(item);
        //   });

        //   return foundValues.length === splittedInputValues.length;
        // }}
        {...props}
      >
        {options.map(o => (
          <AntSelect.Option key={o.key} value={o.value}>
            {o.label}
          </AntSelect.Option>
        ))}
      </AntSelect>
    </InputContainer>
  );
};

export default Select;
