import React, { useRef } from 'react';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import styles from './Consultations.module.less';
import { AppointmentIcon, ChatsIcon, HistoryIcon } from 'components/Icons';

const Consultations: React.FC = () => {
  const { t } = useTranslation();

  const { current: shortcuts } = useRef([
    { href: ROUTES.CONSULTATIONS.APPOINTMENTS, title: 'consultations.appointment', icon: AppointmentIcon },
    { href: ROUTES.CONSULTATIONS.CHATS, title: 'consultations.chats', icon: ChatsIcon },
    { href: ROUTES.CONSULTATIONS.HISTORY, title: 'consultations.history', icon: HistoryIcon },
  ]);

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('consultations.home'), href: ROUTES.DASHBOARD.ROOT },
          { label: t('consultations.page_title') },
        ]}
      />
      <PageHeader title={t('consultations.page_title')} />
      <Grid container spacing={4} wrap="wrap">
        {shortcuts.map(({ href, title, icon: IconComponent }, index) => (
          <Grid className={styles.listItem} item xs="auto" key={index}>
            <div className={styles.iconContainer}>
              <IconComponent />
            </div>
            <span className={styles.title}>{t(title)}</span>
            <Button className={styles.button} color="secondary" fullWidth onClick={() => history.push(href)}>
              {t('consultations.review')}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Consultations;
