import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import columnsList from 'components/tables/ContractDetailsTables/UserActivityTable/columns';
import PrivateFilter from 'components/PrivateFilter';
import styles from '../ContractDetailsTables.module.less';
import { FilterIcon } from 'components/Icons';
import { useDispatch, useSelector } from 'react-redux';

import { fetch, getUsersActionTypes, setQuery, setPagination, removeQuery } from 'store/usersActivities/actions';
import { paymentsSelector } from 'components/tables/ContractDetailsTables/UserActivityTable/selectors/selector';
import { roles } from '../../../../screens/Insurance/components/Appeals/columns';
import { userActivities } from 'screens/UserCard/partials/UserTable/patrials/UserActivity/constants';
import CustomTable from 'components/CustomTable';
import moment from 'moment';
import Timestamp from 'components/Timestamp/Timestamp';
import { parseTableData } from 'components/tables/ContractDetailsTables/UserActivityTable/helpers/parseTableData';
import { useTranslation } from 'react-i18next';

interface ITableProps {
  conclusionDate: string;
  logContractId: number;
}

export default ({ conclusionDate, logContractId }: ITableProps) => {
  const [filtersInfo, setFiltersInfo] = useState({} || null);
  const [clearedFilters, setClearedFilters] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    data,
    loading,
    query,
    pagination: { size, page, total },
  } = useSelector(paymentsSelector);

  useEffect(() => {
    dispatch(getUsersActionTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setQuery({
        ...query,
        from: moment(conclusionDate).toDate(),
        to: moment()
          .endOf('day')
          .toDate(),
        entityType: 'CONTRACT|CONTRACT_TERMINATION',
      }),
    );
  }, [conclusionDate]);

  useEffect(() => {
    if (!query?.from && !query?.to) {
      dispatch(
        setQuery({
          from: moment(conclusionDate).toDate(),
          to: moment()
            .endOf('day')
            .toDate(),
          entityType: 'CONTRACT|CONTRACT_TERMINATION',
          ...query,
        }),
      );
    }
  }, [query, conclusionDate]);

  const handleFilters = (value: {}) => {
    setFiltersInfo({
      ...filtersInfo,
      ...value,
    });
  };

  const getDataFromPrivateFilter = useCallback(
    (filtersInfoo: { [key: string]: any } | null) => {
      let newQuery = query;

      filtersInfoo &&
        Object.keys(filtersInfoo).forEach((key: any) => {
          if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
            filtersInfoo && delete filtersInfoo[key];
            delete newQuery[key];
          }
        });

      setFiltersInfo(prev => {
        return {
          ...prev,
          ...filtersInfoo,
        };
      });

      dispatch(
        setQuery({
          from: moment(conclusionDate).toDate(),
          to: moment()
            .endOf('day')
            .toDate(),
          entityType: 'CONTRACT|CONTRACT_TERMINATION',
          ...newQuery,
          ...filtersInfoo,
        }),
      );
    },
    [query, conclusionDate, filtersInfo],
  );

  useEffect(() => {
    if (logContractId) {
      dispatch(fetch(logContractId));
    }
  }, [logContractId, fetch]);

  const filteredBySearching = (filterBy: string, value: string, type: string) => {
    if (type === 'date') {
      setFiltersInfo({
        [filterBy]: [`${value[0]}..${value[1]}`],
      });
    }

    getDataFromPrivateFilter(type === 'date' ? { [filterBy]: [`${value[0]}..${value[1]}`] } : { [filterBy]: value });
  };

  const clearFilters = () => {
    setFiltersInfo({});
    setClearedFilters(true);
    setQuery({
      from: moment(conclusionDate).toDate(),
      to: moment()
        .endOf('day')
        .toDate(),
    });
  };

  const getDataPrivateFilter = (filtersInfoo: { [key: string]: any } | null) => {
    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });
  };

  const getColumnSearchProps = (dataIndex: string, isDate?: boolean, withoutFilter?: boolean) => {
    const filtersInfo = {};
    const filteredValue = null;

    return {
      filteredValue: withoutFilter ? false : filteredValue,
      filters: withoutFilter
        ? []
        : data &&
          data.activities &&
          data.activities.map((item: any) => {
            if (typeof item[dataIndex] === 'object') {
              return {
                value: item[dataIndex]?.name,
                currentValue: item[dataIndex]?.id,
              };
            }

            return {
              value: String(item[dataIndex]),
            };
          }),
      filterIcon: withoutFilter
        ? false
        : (filtered: boolean) => {
            let filterStatus = filtered;

            if (query && query[dataIndex]) {
              filterStatus = true;
            }

            return (
              <div>
                <FilterIcon color={filterStatus ? '#fff' : null} className={classnames({ active: filterStatus })} />
              </div>
            );
          },
      filterDropdown: withoutFilter
        ? false
        : (record: any) => {
            return (
              <PrivateFilter
                isContractFilter
                record={record}
                dataIndex={dataIndex}
                filtersInfo={filtersInfo}
                setFiltersInfo={setFiltersInfo}
                getDataFromPrivateFilter={getDataFromPrivateFilter}
                getDataPrivateFilter={getDataPrivateFilter}
                filteredBySearching={filteredBySearching}
                isDate={isDate}
                isClearedFilters={clearedFilters}
                clearAllFilters={clearFilters}
                clearFilter={removeQuery}
                logContractId={logContractId}
                isUserActivityTable={true}
              />
            );
          },
    };
  };

  const columns = columnsList(t).map(({ title, value }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
      width: 100,
      ...getColumnSearchProps(value),
      sorter: true,
    };
    switch (value) {
      case 'shortName':
      case 'partnerDepartment':
      case 'partner': {
        return {
          ...basicColumn,
          ...getColumnSearchProps(value),
        };
      }
      case 'activityDate': {
        return {
          ...basicColumn,
          ...getColumnSearchProps(value, true),
          render: (activityDate: string) => <Timestamp timestamp={moment.utc(activityDate).toDate()} />,
        };
      }
      case 'role': {
        return {
          ...basicColumn,
          render: (role: string) => roles[role],
        };
      }
      case 'action': {
        return {
          ...basicColumn,
          render: (value: string) => (value ? userActivities[value] : 'sssss'),
        };
      }
      case 'id': {
        return {
          ...basicColumn,
          sorter: false,
          filtered: false,
          filters: null,
          filterIcon: null,
          filterDropdown: null,
          width: 30,
          //@ts-ignore
          render: (t, r, index) => index + 1,
        };
      }
      default: {
        return basicColumn;
      }
    }
  });

  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? '' : '-';
      }
      return '';
    };

    const formatSorting = `${formatDirection(sorting.order)}${sorting.field}`;
    dispatch(
      setQuery({
        ...query,
        sorting: formatSorting,
      }),
    );
    dispatch(fetch(logContractId));
  };

  const clearQueries = () => {
    dispatch(
      setQuery({
        from: moment(conclusionDate).toDate(),
        to: moment()
          .endOf('day')
          .toDate(),
      }),
    );
    dispatch(fetch(logContractId));
    setFiltersInfo({});
    setClearedFilters(true);
  };

  const handlePageSize = size => {
    dispatch(
      setPagination({
        page,
        total,
        size,
      }),
    );

    dispatch(fetch(logContractId));
  };

  const handlePagination = (page, size) => {
    dispatch(
      setPagination({
        total,
        page,
        size,
      }),
    );

    dispatch(fetch(logContractId));
  };

  return (
    <>
      <CustomTable
        className={styles.table}
        page={page}
        contentHeight={410}
        pageSize={size}
        total={total || 0}
        pending={loading || false}
        data={parseTableData(data && data.activities ? data.activities : [])}
        columns={columns}
        filtersInfo={filtersInfo}
        clearFilters={clearQueries}
        handleFilters={handleFilters}
        handleTableChange={handleTableChange}
        onPageSizeChange={handlePageSize}
        onPageChange={handlePagination}
        rowKey={'id'}
      />
    </>
  );
};
