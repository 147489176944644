import { dateFormatter, getCorrectInsuranceObjectPid, getJutloType, moneyFormatter } from 'utils/helpers';

interface IContractsData {
  id: number;
  number: string;
  clientName: string;
  conclusionDate: string;
  subAgreementConclusionDate: string;
  startDate: string;
  endDate: string;
  status: [string];
  verifiedAt?: string;
  insuranceAmount: number;
  paymentAmount: number;
  numberPayments: number;
  calculatedPaymentAmount: number;
  imei: string;
  pid: string;
  subjectName: string;
  manufacturer: string;
  brand: string;
  model: string;
  insuranceObjectName: string;
  feeSum: number;
  baseFeeSum: number;
  toPay: number;
  returnedPayment: number;
  returnedToPartner: number;
  insuranceObjectPid: string;
}

export const formatContractsData = (dataArr: [IContractsData]) => {
  if (!dataArr) {
    return [];
  } else {
    const newData = dataArr.map(data => ({
      ...data,
      insuranceAmount: `${moneyFormatter(data.insuranceAmount)}`,
      paymentAmount: `${moneyFormatter(data.paymentAmount)}`,
      calculatedPaymentAmount: `${moneyFormatter(data.calculatedPaymentAmount)}`,
      feeSum: `${moneyFormatter(data.feeSum)}`,
      baseFeeSum: `${moneyFormatter(data.baseFeeSum)}`,
      insuranceObjectName: getJutloType(data.insuranceObjectName),
      toPay: `${moneyFormatter(data.toPay)}`,
      returnedPayment: `${moneyFormatter(data.returnedPayment)}`,
      returnedToPartner: `${moneyFormatter(data.returnedToPartner)}`,
      conclusionDate: dateFormatter(data.conclusionDate),
      subAgreementConclusionDate: data.subAgreementConclusionDate ? dateFormatter(data.subAgreementConclusionDate) : '',
      startDate: dateFormatter(data.startDate),
      endDate: dateFormatter(data.endDate),
      verifiedAt: data.verifiedAt ? dateFormatter(data.verifiedAt) : '',
      insuranceObjectPid: getCorrectInsuranceObjectPid(data.insuranceObjectPid ?? '', data.insuranceObjectName),
    }));
    return newData;
  }
};

export const getCorrectStatus = (filtersInfo, translatedStatuses) => {
  let correctFiltersInfo = filtersInfo;

  Object.keys(filtersInfo).forEach(item => {
    if (item === 'status') {
      const translatedValues = filtersInfo[item].map(item => translatedStatuses[item] || item);
      correctFiltersInfo = { ...filtersInfo, [item]: translatedValues };
    }
  });

  return correctFiltersInfo;
};
