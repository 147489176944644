import React from 'react';
import { useDispatch } from 'react-redux';

import PageBreadcrumbs from 'components/PageBreadcrumbs';
import Excel from 'components/Button/icons/Excel';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';

import { ROUTES } from 'constants/routes';
import RegistrationDevicesTable from 'components/tables/RegistrationDevicesTable';
import RegistrationDevicesFilters from 'components/filters/RegistrationDevicesFilters';
import { activitiesXSLXDownload } from 'store/registrationDevices/actions';
import { useTranslation } from 'react-i18next';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const downloadReport = () => {
    dispatch(activitiesXSLXDownload(t));
  };

  return (
    <>
      <PageBreadcrumbs
        data={[
          {
            label: t('registration_devices.home'),
            href: ROUTES.REGISTRATION_DEVICES.ROOT,
          },
          {
            label: t('registration_devices.register_of_equipment_sales'),
          },
        ]}
      />

      <PageHeader title={t('registration_devices.register_of_equipment_sales')}>
        <Button href={'#'} startIcon={<Excel />} onClick={downloadReport}>
          {t('registration_devices.export_to_Excel_button')}
        </Button>
      </PageHeader>

      <RegistrationDevicesFilters />
      <RegistrationDevicesTable />
    </>
  );
};
