const columnsList = t => {
  return [
    {
      title: t('contract_details.termination_number_terminations'),
      value: 'terminationNumber',
    },
    {
      title: t('contract_details.termination_date_terminations'),
      value: 'terminationDate',
    },
    {
      title: t('contract_details.end_date_terminations'),
      value: 'realEndDate',
    },
    {
      title: t('contract_details.cause_terminations'),
      value: 'cause',
    },
    {
      title: t('contract_details.refund_sum_terminations'),
      value: 'calculatedRefundSum',
    },
    {
      title: t('contract_details.status_terminations'),
      value: 'status',
    },
    {
      title: t('contract_details.actions_terminations'),
      value: 'actions',
    },
  ];
};

export default columnsList;
