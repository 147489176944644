import React from 'react';

export default (string: string, query: string, className: string) => {
  if (!query) {
    return (
      <span
        style={{
          whiteSpace: 'pre',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {string}
      </span>
    );
  }

  return (
    <span
      style={{
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {string.split(query).map((el, index) =>
        index % 2 !== 0 ? (
          <>
            <span className={className}>{query}</span>
            {el}
          </>
        ) : (
          el
        ),
      )}
    </span>
  );
};
