import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { brandListType } from 'types/brandList';
import { AnyAction } from 'redux';
import config from 'config';

const initialState: brandListType = {
  data: [],
  total: 0,
  pending: false,
  error: '',
  update: false,
  success: false,
  search: '',
  types: [],
  actionOnBrand: '',
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
};

const setSearchValue = (state: brandListType, { value }: AnyAction): brandListType => {
  return {
    ...state,
    search: value || '',
  };
};

const setBrands = (state: brandListType, { payload, pending, update = false }: AnyAction): brandListType => {
  return {
    ...state,
    update: update,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
    pending,
  };
};

const setError = (state: brandListType, { error = '' }: AnyAction): brandListType => {
  return {
    ...state,
    error: error,
  };
};

const setSuccess = (state: brandListType, { value = false }: AnyAction): brandListType => {
  return {
    ...state,
    success: value,
  };
};

const setPagination = (state: brandListType, { payload }: AnyAction): brandListType => {
  return {
    ...state,
    pagination: payload,
  };
};

const setUpdate = (state: brandListType, { update }: AnyAction): brandListType => {
  return {
    ...state,
    update: update,
  };
};

const setBrandsTypes = (state: brandListType, { payload }: AnyAction): brandListType => {
  return {
    ...state,
    types: payload.insuranceObjectTypes,
  };
};

const setActionOnBrand = (state: brandListType, { actionOnBrand }: AnyAction): brandListType => ({
  ...state,
  actionOnBrand,
});

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setUpdate,
    setBrands,
    setPagination,
    setSearchValue,
    setSuccess,
    setError,
    setBrandsTypes,
    setActionOnBrand,
  },
});
