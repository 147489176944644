import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
import { changeDocumentDates, changeDocumentInfo, changeUploadDocumentDates } from './helper';
import { changeDocumentDelete } from '../termination/helper';

interface IInsuredEvent {
  details: {
    documents: any[];
    previewList: any[];
  };
  isPreviewListLoading: boolean;
}

export const initialState: IInsuredEvent = {
  details: {
    documents: [],
    previewList: [],
  },
  isPreviewListLoading: false,
};

const setDocumentsData = (state: any, { payload }: AnyAction) => {
  return {
    ...state,
    details: {
      ...state.details,
      documents: payload,
    },
  };
};

//TODO: Maybe, in future better to crear all data
const clearDocumentsData = (state: any) => {
  return {
    ...state,
    details: {
      ...state.details,
      documents: [],
    },
  };
};

const setDocumentInfo = (state: any, { payload }: AnyAction) => {
  const { value, code } = payload;
  return {
    ...state,
    details: {
      ...state.details,
      documents: state.details.documents ? changeDocumentInfo(state.details.documents, value, code) : [],
    },
  };
};

const setDocumentDates = (state: any, { payload }: AnyAction) => {
  const { id, createDate, approveDate } = payload;
  return {
    ...state,
    details: {
      ...state.details,
      documents: changeDocumentDates(state.details.documents, createDate, approveDate, id),
    },
  };
};

const setUploadDocumentDates = (state: any, { payload }: AnyAction) => {
  const { id, createDate, approveDate } = payload;
  return {
    ...state,
    details: {
      ...state.details,
      documents: changeUploadDocumentDates(state.details.documents, createDate, approveDate, id),
    },
  };
};

const setInsuredDocumentDelete = (state: any, { payload }: AnyAction) => {
  const { id } = payload;
  return {
    ...state,
    details: {
      ...state.details,
      documents: changeDocumentDelete(state.details.documents, id),
    },
  };
};

const setPreviewList = (state: any, { payload }: AnyAction) => {
  return {
    ...state,
    details: {
      ...state.details,
      previewList: payload.previewList,
    },
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setDocumentInfo,
    setDocumentsData,
    setDocuments: setDocumentsData,
    clearDocumentsData,
    setDocumentDates,
    setInsuredDocumentDelete,
    setPreviewList,
    setUploadDocumentDates,
  },
});
