import React from 'react';
import classNames from 'classnames';
import { Input } from 'antd';
import { CloseIcon } from 'components/Icons';

import styles from '../../filters.module.less';

export default (props: any) => {
  const {
    label,
    labelActive,
    value,
    closable,
    placeholder,
    onChange,
    focusHandler,
    onCloseHandler,
    disabled = false,
    containerClassname,
    onKeyDown,
  } = props;

  return (
    <div className={classNames(styles.fieldContainer, styles.inputFieldContainer, containerClassname || '')}>
      <div className={classNames(styles.label, { [styles.labelActive]: !labelActive })}>{label}</div>
      <Input
        onChange={onChange}
        value={value}
        placeholder={labelActive ? placeholder : ''}
        suffix={
          closable ? (
            <div className={styles.clearButton} onClick={onCloseHandler}>
              <CloseIcon />
            </div>
          ) : null
        }
        disabled={disabled}
        onFocus={focusHandler}
        onBlur={focusHandler}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};
