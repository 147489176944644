import React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'components/Button/Button';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import Typography from 'components/Typography';
import { ROUTES } from 'constants/routes';
import { IPageHeader } from './interfaces/PageHeader.interfaces';
import style from './PageHeader.module.less';
import { useTranslation } from 'react-i18next';

const PageHeader: React.FC<IPageHeader> = ({ action, button, text, buttonText, additionalBreadcrumbs = [] }) => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <PageBreadcrumbs
        data={[{ label: t('brands_page.settings'), href: ROUTES.SETTINGS.ROOT }, ...additionalBreadcrumbs]}
      />
      <Grid container item justify="space-between" alignItems="center">
        <Grid item lg={10}>
          <Typography type={'heading'}>{text}</Typography>
        </Grid>
        <Grid item lg={2}>
          <Button onClick={action} fullWidth={true} {...button} color="primary">
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageHeader;
