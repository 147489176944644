import moment from 'moment';

export const formatDateFieldsToIso = (object, index, parseCallBack) => {
  const dateErrorFields: string[] = [];
  let dateError: any = null;

  for (let key in object) {
    if (key === 'commission' || key === 'paymentAmount' || key === 'СП Всего' || key === 'СП Невозврат') {
      object[key] = object[key].includes('"') ? object[key].replace('"', '') : object[key];
    }

    if (key === 'startDate' || key === 'endDate' || key === 'conclusionDate' || key === 'birthday') {
      const date = object[key];

      if (date.search(/\d{4}-\d{2}-\d{2}/) === -1) {
        object[key] = moment(date, 'DD,MM,YYYY').format('YYYY-MM-DD');
      }

      if (object[key] === 'Invalid date') {
        dateErrorFields.push(parseCallBack(key));
        dateError = {
          objectIndex: index + 2,
          errorFields: dateErrorFields,
        };
      }
    }
  }

  if (dateError) {
    object.dateError = dateError;
  }

  return object;
};
