import React from 'react';
// import Avatar from '../Avatar';
import { useSelector } from 'react-redux';
import { AppState } from 'store/reducer';
import { Message as TMessage } from 'types/chats';
import moment from 'moment';
import styles from './Message.module.less';
import classNames from 'classnames';

type MessageProps = TMessage & {
  companion: string;
  uuid: string;
};

const Message: React.FC<MessageProps> = ({ companion, type, publisher, timetoken, uuid, ...body }) => {
  const userId = useSelector((store: AppState) => (store.user.id ? String(store.user.id) : null));
  const id = userId || uuid;

  const bodyNode =
    type === 'plaintext' ? (
      body['body']
    ) : (
      <span className={styles.imageFile}>
        <img alt="" height="140" src={body['url']} />
      </span>
    );

  return (
    <div className={styles.message}>
      <div className={styles.textOuter}>
        {/* // Delete avatar */}
        {/* {publisher !== userId && <Avatar title={companion} dialog />} */}
        <div
          className={classNames(styles.text, {
            [styles.myMessage]: id === publisher,
          })}
        >
          {bodyNode}
        </div>
      </div>
      <div
        className={classNames(styles.timeOuter, {
          [styles.publisherIsCompanion]: id !== publisher,
        })}
      >
        <span className={styles.time}>
          {moment(new Date(parseInt(String(timetoken).slice(0, -4)))).format('HH:mm')}
        </span>
      </div>
    </div>
  );
};

export default Message;
