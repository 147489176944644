import { AdminTypes } from 'types/AdminTypes';
import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import moment from 'moment';
import config from 'config';

const initialState: AdminTypes = {
  resultList: [],
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'signupDate',
    direction: 'DESC',
  },
  filters: {
    dateFrom: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    insuranceCompanyName: '',
    insuranceCompany: '',
    departmentName: '',
    username: '',
    departmentId: {
      id: null,
      name: null,
    },
    insuranceCompanyId: {
      id: null,
      name: null,
    },
  },
  privateFilterItems: {},
  insuranceCompanies: [],
  companies: [],
  userNames: [],
  departments: [],
  count: 0,
  pending: false,
  error: false,
};

const setAdminList = (state: AdminTypes, { payload }: AnyAction): AdminTypes => ({
  ...state,
  resultList: payload.resultList || state.resultList,
  count: payload.count || state.count,
});

const adminListState = (state: AdminTypes, { payload }: AnyAction): AdminTypes => ({
  ...state,
  pending: payload.pending,
});

const setFilters = (state: AdminTypes, { payload }: AnyAction): AdminTypes => ({
  ...state,
  filters: payload || state.filters,
});

const setPagination = (state: AdminTypes, { payload }: AnyAction): AdminTypes => ({
  ...state,
  pagination: payload || state.pagination,
});

const setSorting = (state: AdminTypes, { payload }: AnyAction): AdminTypes => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const getPrivateFiltersData = (state: AdminTypes, { payload }: AnyAction): AdminTypes => ({
  ...state,
  resultList: payload.resultList || state.resultList,
  count: payload.count,
});

const setCurrentInsuranceCompanies = (state: AdminTypes, { payload }: any): AdminTypes => {
  return {
    ...state,
    insuranceCompanies: payload.insuranceCompanies || state.insuranceCompanies,
  };
};

const setCurrentCompanies = (state: AdminTypes, { payload }: any): AdminTypes => {
  return {
    ...state,
    companies: payload.companies || state.companies,
  };
};

const setAdminListPrivateFilters = (state: AdminTypes, { privateFiltersItems }: any): AdminTypes => {
  return {
    ...state,
    privateFilterItems: privateFiltersItems,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setAdminList,
    adminListState,
    setFilters,
    setPagination,
    setSorting,
    getPrivateFiltersData,
    setCurrentCompanies,
    setAdminListPrivateFilters,
    setCurrentInsuranceCompanies,
  },
});
