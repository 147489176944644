import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import history from 'routes/history';

import { ROUTES } from 'constants/routes';

import useStatistics from 'hooks/useStatistics';
import useDeviceTypes from 'hooks/useDeviceTypes';
import useCompanies from 'hooks/useCompanies';

import MonthInfo from './Widgets/MonthInfo';
import SoldBrands from './Widgets/SoldBrands/SoldBrands';
import OrdinaryPayments from './Widgets/OrdinaryPayments';
import ClaimPayments from './Widgets/ClaimPayments';
import Contracts from './Widgets/Contracts';
import MenuItem from '../uikit/MenuItem/MenuItem';
import Select from '../uikit/Select';
import Typography from '../Typography';
import { Box, Grid } from '@material-ui/core';
import Breadcrumbs from 'components/next/Breadcrumbs/Breadcrumbs';
import Menu, { MenuItem as BaseMenuItem } from 'components/next/Menu/Menu';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isShowComponent } from 'roles/userCan';
import { modifyDeviceTypes, modifyClaimPayments, modifyOrdinaryPayments } from './utils/translationHelper';
import {
  ObjectSubtypeDto,
  PartnerView,
  SalesStatisticsDtoBigdecimal,
  ClaimPaymentsStatisticDto,
} from 'types/dto/contracts-service';
import { LocationState } from 'types';

const Dashboard = () => {
  const { data: companies = [] } = useCompanies();
  const { data: deviceTypes = [] } = useDeviceTypes();
  const { t } = useTranslation();
  const location = useLocation<LocationState>();

  const [company, setCompany] = useState<PartnerView>();
  const [deviceType, setDeviceType] = useState<ObjectSubtypeDto>();
  const [modifiedDeviceTypes, setModifiedDeviceTypes] = useState([]);
  const [modifiedClaimPayments, setModifiedClaimPayments] = useState<ClaimPaymentsStatisticDto[]>([]);
  const [modifiedOrdinaryPayments, setModifiedOrdinaryPayments] = useState<SalesStatisticsDtoBigdecimal[]>([]);
  const currentLanguage = localStorage.getItem('language');

  useEffect(() => {
    if (!company && companies.length) {
      setCompany({ name: 'Усі' });
    }
  }, [company, companies]);

  useEffect(() => {
    if (deviceTypes.length) {
      setDeviceType(deviceTypes.find(d => d.name?.toLowerCase() === 'смартфони') || deviceTypes[0]);
    }
  }, [deviceTypes]);

  useEffect(() => {
    const modifiedDeviceTypesData = modifyDeviceTypes(deviceTypes, t);
    setModifiedDeviceTypes(modifiedDeviceTypesData);
  }, [deviceTypes]);

  const { contracts, monthInfo, brandSales, ordinaryPayments, claimPayments } = useStatistics({
    company,
    insuranceObjectType: deviceType ? deviceType.objectType : null,
    deviceTypeId: deviceType ? deviceType.id : null,
  });

  useEffect(() => {
    const modifiedClaimPaymentsData = modifyClaimPayments(claimPayments, t);
    setModifiedClaimPayments(modifiedClaimPaymentsData);
  }, [claimPayments]);

  useEffect(() => {
    const modifiedOrdinaryPaymentsData = modifyOrdinaryPayments(ordinaryPayments, t);
    setModifiedOrdinaryPayments(modifiedOrdinaryPaymentsData);
  }, [ordinaryPayments]);

  const onContractDetailsClick = useCallback(() => history.push(ROUTES.CONTRACTS_LIST.ROOT), []);
  const onDeviceTypeChange = useCallback(value => setDeviceType(deviceTypes.find((d: any) => d.id === value)), [
    deviceTypes,
  ]);

  const onChangeCompany = useCallback(name => setCompany((companies ?? []).find(c => c.name === name)), [companies]);

  const menu: BaseMenuItem[] = [
    {
      id: ROUTES.DASHBOARD.EXTEND,
      label: 'Dashboard-1',
      children: <Link to={ROUTES.DASHBOARD.EXTEND}>Dashboard-1</Link>,
    },
    {
      id: ROUTES.DASHBOARD.MAIN,
      label: 'Dashboard-2',
      children: <Link to={ROUTES.DASHBOARD.MAIN}>Dashboard-2</Link>,
    },
  ];

  return (
    <>
      <Breadcrumbs
        data={[
          { label: t('dash_board.home') },
          {
            label: menu.find(el => el.id === location.pathname)?.label ?? 'Unknown',
            overlay: Menu({ menu: menu.filter(t => t.id !== location.pathname) }),
          },
        ]}
      />
      <Grid container spacing={2} wrap={'wrap'}>
        <Grid item xs={12}>
          <Typography type={'heading'}> {t('dash_board.home')} </Typography>
          <Box m={1} />
          <Typography type={'p1'}>
            {t('dash_board.data_for_the_period_from')}{' '}
            {moment(new Date())
              .startOf('month')
              .locale(currentLanguage || 'uk')
              .format('D MMMM YYYY')}{' '}
            {t('dash_board.on')}{' '}
            {moment(new Date())
              .locale(currentLanguage || 'uk')
              .format('D MMMM YYYY')}{' '}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Select
            label={t('dash_board.the_company')}
            value={company?.name ?? ''}
            fullWidth={true}
            color={'white'}
            onChange={event => onChangeCompany(event.target.value)}
          >
            <MenuItem value="Усі" key="Усі">
              {t('dash_board.everyone')}
            </MenuItem>
            {companies.map(c => (
              <MenuItem value={c.name} key={c.name}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={9}>
          <MonthInfo readOnly monthInfo={monthInfo} />
        </Grid>
        {isShowComponent() && (
          <Grid item xs={6}>
            <SoldBrands
              data={brandSales}
              deviceTypes={modifiedDeviceTypes}
              deviceType={deviceType}
              onDeviceTypeChange={onDeviceTypeChange}
            />
          </Grid>
        )}
        <Grid item xs={isShowComponent() ? 3 : 6}>
          <OrdinaryPayments data={modifiedOrdinaryPayments} />
        </Grid>
        <Grid item xs={isShowComponent() ? 3 : 6}>
          <ClaimPayments data={modifiedClaimPayments} />
        </Grid>
        <Grid item xs={12}>
          <Contracts data={contracts} onContractDetailsClick={onContractDetailsClick} />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
