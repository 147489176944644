import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoBrand from './InfoBrand';
import { IAddBrand } from '../interfaces/modalBrands.interfaces';
import { addBrand, setSuccess } from 'store/brands/actions';
import { AppState } from 'store/reducer';
import { useTranslation } from 'react-i18next';

const AddBrand = ({ error, success, onClose, chosenTab }: IAddBrand) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [brandValue, setBrandValue] = useState<string>('');
  const [typeValue, setTypeValue] = useState<string>('');
  const [errors, setError] = useState<string>('');
  const [isNotEmpty, setNotEmpty] = useState<boolean>(!!brandValue.length);
  const { types } = useSelector(({ brands: { types } }: AppState) => ({ types }));

  useEffect(() => {
    setNotEmpty(!!brandValue.length);
  }, [brandValue]);

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    if (success) onClose();
    setError('');
    setBrandValue('');
    dispatch(setSuccess(false));
  }, [success]);

  const handleSubmit = () => {
    validateBrand();
    if (!errors) {
      dispatch(addBrand(brandValue, chosenTab, typeValue, t));
    }
  };

  const validateBrand = () => {
    if (!brandValue.length) {
      setError(t('brands_page.enter_brand_add'));
    }
    if (brandValue.search(/[А-Яа-яЁёЇїІіЄєҐґ']/g) !== -1) {
      setError(t('brands_page.enter_english_add'));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setError('');
    setBrandValue(value);
  };

  const handleType = value => {
    setError('');
    setTypeValue(value);
  };

  return (
    <InfoBrand
      isNotEmpty={isNotEmpty}
      error={errors}
      onBlur={validateBrand}
      onChange={handleChange}
      value={brandValue}
      label={t('brands_page.brand_information_add')}
      handleSubmit={handleSubmit}
      handleType={handleType}
      typeValue={typeValue}
      chosenTab={chosenTab}
      types={types}
    />
  );
};

export default AddBrand;
