export const initialState = {
  partnerCode: '',
  phoneNumber: '',
  email: '',
  token: '',
  firstName: '',
  middleName: '',
  lastName: '',
  // company: '',
  // department: '',
  password: '',
  confirmPassword: '',

  insuranceCompanyId: 0,
  partner: {
    id: 0,
    name: '',
  },
  partnerDepartmentId: 0,
};

export const initialActiveState = {
  phoneNumber: true,
  email: false,
  token: false,
  password: false,
  confirmPassword: false,
  partnerCode: false,
  firstName: false,
  middleName: false,
  lastName: false,
  company: true,
  department: false,
};
