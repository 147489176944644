import { createTypes } from 'redux-compose-reducer';
import { ThunkAction } from 'redux-thunk';
import i18next from 'i18next';
import { StoreType } from 'types/store';
import { Action } from 'redux';
import { AllActions, TUserData } from './actionTypes';
import { IUserData, TData } from 'types/userCard';
import { AppState } from '../reducer';
import { notification } from 'antd';
import { createUser, getUser, updateUser, getInsuranceProgram } from 'api/userCard';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import { cutPhoneNumber } from 'screens/UserCard/partials/PersonalInformation/utils';
import { errorHandler, errorsAdapter } from 'screens/UserCard/validation';
import { prepare, addRegionId, convertDepartment, prepare1 } from './userFormatters';
import { InsuranceProgramView } from 'types/dto/configuration-service';

export const TYPES = createTypes('adminList', [
  'setUserData',
  'setUserPending',
  'setUserProgram',
  'setUserPartner',
  'setUserPartnerDepartment',
  'setUserPartnerDepartmentRegion',
  'setUserRole',
  'setUserActivity',
  'setUserPrograms',
  'clearData',
  'setUserLocation',
  'setIsUserClient',
  'setLanguage',
]);

type ThunkResult = ThunkAction<void, StoreType, null, Action>;

// action creators
export const setPending = (pending: boolean): AllActions<boolean> => ({
  type: TYPES.setUserPending,
  payload: pending,
});

export const setLanguage = (language: string | null): AllActions<string | null> => ({
  type: TYPES.setLanguage,
  payload: language,
});

export const setUserData = ({ data }: TUserData): AllActions<TUserData> => ({
  type: TYPES.setUserData,
  payload: { data },
});

export const setLocation = (address: string): AllActions<string> => ({
  type: TYPES.setUserLocation,
  payload: address,
});

export const setProgram = (insuranceCompany: TData): AllActions<TData> => ({
  type: TYPES.setUserProgram,
  payload: insuranceCompany,
});

export const setPartner = (partner: TData): AllActions<TData> => ({
  type: TYPES.setUserPartner,
  payload: partner,
});

export const setPartnerDepartmentRegion = (partnerDepartmentRegion: TData | null): AllActions<TData | null> => ({
  type: TYPES.setUserPartnerDepartmentRegion,
  payload: partnerDepartmentRegion,
});

export const setPartnerDepartment = (partnerDepartment: TData | null): AllActions<TData | null> => ({
  type: TYPES.setUserPartnerDepartment,
  payload: partnerDepartment,
});

export const setRole = (role: string): AllActions<string> => ({
  type: TYPES.setUserRole,
  payload: role,
});

export const setIsClient = (isClient: boolean): AllActions<boolean> => ({
  type: TYPES.setIsUserClient,
  payload: isClient,
});

export const setActivity = (active: boolean): AllActions<boolean> => ({
  type: TYPES.setUserActivity,
  payload: active,
});

export const setPrograms = (data: InsuranceProgramView[]): AllActions<TData[]> => ({
  type: TYPES.setUserPrograms,
  payload: prepare1(data),
});

export const disablePrograms = (data: TData[]): AllActions<TData[]> => ({
  type: TYPES.setUserPrograms,
  payload: data,
});

export const clearUserData = () => ({ type: TYPES.clearData });

// thunks

export const createUserAccount = (fields: any, form: any, t): ThunkResult => async (dispatch, getState) => {
  dispatch(setPending(true));

  const {
    userCard: { data: userData },
  }: AppState = getState();

  try {
    const { data }: { data: IUserData } = await createUser({
      ...userData,
      partnerDepartment:
        userData.role === 'ROLE_SYSTEM_PARTNER' ? undefined : convertDepartment(userData.partnerDepartment),
      partnerDepartmentRegion:
        userData.role === 'ROLE_SYSTEM_PARTNER' ? undefined : convertDepartment(userData.partnerDepartmentRegion),
      insurancePrograms: prepare(userData.insurancePrograms, userData.active),
      insuranceCompany: userData?.insuranceCompany?.id ? userData?.insuranceCompany : null,
      partner: userData?.partner?.id ? userData?.partner : null,
      ...fields,
    });

    history.replace({
      pathname: ROUTES.ADMIN_PANEL.USER_CARD.ROOT.replace(':id', `${data.id}`),
    });

    dispatch(
      setUserData({
        data: {
          ...data,
          phoneNumber: cutPhoneNumber(data.phoneNumber!),
          partnerDepartment: addRegionId(data),
        },
      }),
    );
  } catch (error) {
    errorHandler(error, form, errorsAdapter(t).GLOBAL, t);
  } finally {
    dispatch(setPending(false));
  }
};

export const getUserById = (id: number): ThunkResult => async dispatch => {
  dispatch(setPending(true));

  try {
    const { data }: { data: IUserData } = await getUser(id);
    dispatch(
      setUserData({
        data: {
          ...data,
          phoneNumber: cutPhoneNumber(data.phoneNumber!),
          partnerDepartment: addRegionId(data),
        },
      }),
    );
  } catch (error) {
    notification.error({
      message: i18next.t('popup.error'),
      description: i18next.t('popup.try_again_products'),
    });
  } finally {
    dispatch(setPending(false));
  }
};

const getClearValues = data => {
  const havePartnerData = data.partner && Object.values(data.partner).every(item => item);
  const haveInsuranceCompanyData = data?.insuranceCompany
    ? Object.values(data.insuranceCompany).every(item => item)
    : null;
  if (!havePartnerData && !haveInsuranceCompanyData) {
    return {
      ...data,
      insuranceCompany: null,
      partner: null,
    };
  }

  if (!havePartnerData) {
    return {
      ...data,
      partner: null,
    };
  }

  if (!haveInsuranceCompanyData) {
    return {
      ...data,
      insuranceCompany: null,
    };
  }
  return data;
};

export const updateUserAccount = (fields: any, form: any, t): ThunkResult => async (dispatch, getState) => {
  dispatch(setPending(true));
  const {
    userCard: { data: userData },
  }: AppState = getState();

  const clearUserData = getClearValues(userData);
  try {
    const { data }: { data: IUserData } = await updateUser({
      ...clearUserData,
      partnerDepartment: convertDepartment(clearUserData.partnerDepartment),
      partnerDepartmentRegion: convertDepartment(clearUserData.partnerDepartmentRegion),
      insurancePrograms: prepare(clearUserData.insurancePrograms, clearUserData.active),
      ...fields,
    });

    dispatch(
      setUserData({
        data: {
          ...data,
          phoneNumber: cutPhoneNumber(data.phoneNumber!),
          partnerDepartment: addRegionId(data),
        },
      }),
    );
  } catch (error) {
    errorHandler(error, form, errorsAdapter(t).GLOBAL, t);
  } finally {
    dispatch(setPending(false));
  }
};

export const setNewInsurancePrograms = (partnerId, t): ThunkResult => async dispatch => {
  try {
    const { data } = partnerId && (await getInsuranceProgram({ insuranceCompanyId: partnerId }));

    dispatch(setPrograms(data.resultList ?? []));
  } catch (error) {
    notification.error({
      message: t('popup.downloading_insurance_programs'),
      description: t('popup.try_again_products'),
    });
  }
};
