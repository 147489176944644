export const ROUTES = {
  ROOT: '/',
  CLIENT_SIGN: '/contracts/external/:id',
  CLIENT_PAYMENT: '/contracts/external/:id/order/:id',
  PARTNER_AUTH: {
    LOGIN: '/login',
    PASSWORD_RECOVERY: '/password-recovery',
    INSURER_SIGNUP: '/client-signup',
  },
  CONSULTATIONS: {
    ROOT: '/consultations',
    APPOINTMENTS: '/consultations/appointments',
    CHATS: '/consultations/chats',
    HISTORY: '/consultations/history',
    SUBEVENT: '/consultations/history/:id',
  },
  JOIN_CONSULTATION_FOR_DOCTOR: '/j/:id',
  VIDEO_CHAT_FOR_DOCTOR: '/video-consultation',
  AUTH: {
    LOGIN: '/insurance-companies/:id/login',
    PASSWORD_RECOVERY: '/insurance-companies/:id/password-recovery',
    INSURER_SIGNUP: '/insurance-companies/:id/client-signup',
    MANAGER_SIGNUP: '/manager-signup',
  },
  DASHBOARD: {
    ROOT: '/dashboard',
    EXTEND: '/dashboard/extend',
    MAIN: '/dashboard/main',
  },
  CONTRACTS_LIST: {
    ROOT: '/contracts-list',
    CONTRACT: {
      ROOT: '/contracts-list/contract',
    },
  },
  REGISTRATION_DEVICES: {
    ROOT: '/registration-devices',
    EDIT: {
      ROOT: '/registration-devices/edit',
    },
  },
  ACTS_LIST: {
    ROOT: '/acts',
    CREATE: {
      ROOT: '/acts/create',
    },
    READ: {
      ROOT: '/acts/details/:id',
    },
  },
  INSURANCE_PROGRAMS: {
    ROOT: '/insurance-programs',
    PROGRAM: {
      ROOT: '/insurance-programs/:id',
    },
    CREATE: '/insurance-programs/create',
    READ: '/insurance-programs/:id',
  },
  SEARCH: {
    ROOT: '/search',
  },
  TERMINATIONS: {
    ROOT: '/contracts-list/contract/:id/terminations',
    READ: '/contracts-list/contract/:id/terminations/:terminationId',
  },
  TERMINATIONS_LIST: {
    ROOT: '/terminations-list',
  },
  INSURED_EVENTS: {
    LIST: {
      ROOT: '/insured-events',
    },
    READ: {
      ROOT: '/insured-events/:id',
    },
    CREATE: {
      ROOT: '/insured-events/create?contractId=:contractId',
    },
  },
  ADMIN_PANEL: {
    LIST: {
      ROOT: '/admin-panel',
    },
    USER_CARD: {
      CREATE: '/admin-panel/user-card/create',
      ROOT: '/admin-panel/user-card/:id',
    },
  },
  INSURANCE: {
    ROOT: '/insurance/:id',
  },
  USER_CARD: {
    ROOT: '/settings/user-card/:id',
  },
  USERS_ACTIVITIES: {
    ROOT: '/users-activities',
  },
  SETTINGS: {
    ROOT: '/settings',
  },
  DEPARTMENTS: {
    ROOT: '/departments',
    READ: {
      ROOT: '/departments/:id',
    },
    CREATE: {
      ROOT: '/departments/create',
    },
  },
  INSURANCE_COMPANIES: {
    LIST: {
      ROOT: '/settings/insurance-companies',
    },
    SETTINGS: {
      ROOT: '/settings/insurance-companies/settings/:id',
    },
    CREATE_SETTINGS: {
      ROOT: '/settings/insurance-companies/settings',
    },
  },
  INSURANCE_CONTRACTS: {
    LIST: {
      ROOT: '/settings/insurance-contracts',
    },
    CONTRACT: {
      READ: '/settings/insurance-contracts/:id',
    },
  },
  BRAND_PAGE: {
    READ: {
      ROOT: '/settings/brands',
    },
  },
  NOT_FOUND: {
    ROOT: '/404',
  },
  PARTNERS_LIST: {
    ROOT: '/settings/partners',
    CREATE: '/settings/partner/create',
    READ: {
      ROOT: '/settings/partner/create/:partnerId',
    },
  },
  MEDAPP: {
    ROOT: '/medapp',
    HEALTHFACILITIES: '/medapp/health-facilities',
    SUBLIMITS: {
      ROOT: '/medapp/sublimits',
      DETAILS: {
        ROOT: '/medapp/sublimits/:id',
      },
    },
    SERVICES: {
      ROOT: '/medapp/services',
      DETAILS: {
        ROOT: '/medapp/services/:id',
      },
    },
    CREATE: '/medapp/create',
    DETAILS: {
      ROOT: '/medapp/details/:id',
    },
  },
  REGISTER_TYPES: {
    ROOT: '/settings/register-types',
  },
};
