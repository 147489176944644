import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { Form, Button, Typography, Input } from 'antd';
import PhoneNumberInput from 'components/PhoneNumberInput';

import * as sign from '../../SignUp/SignUp.module.less';
import { IFirstStep } from '../types';
import { allowNumbers, RULES } from '../utils';
import { useTranslation } from 'react-i18next';

const FirstStep: FunctionComponent<IFirstStep> = ({
  form: { getFieldDecorator, getFieldValue },
  fieldsActiveState,
  handleFields,
  values,
}) => {
  const validateCode = (value: string) => {
    return value === '' || (value && value.toString().match(allowNumbers)) ? value : getFieldValue('edrpou');
  };
  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={3}>
        {' '}
        {t('signup.registration')} <br /> {t('signup.manager')}{' '}
      </Typography.Title>
      <Form.Item className={sign.signup_items}>
        {getFieldDecorator('partnerCode', {
          rules: [...RULES(t).EDRPOU],
          initialValue: values.partnerCode,
          normalize: validateCode,
        })(
          <Input
            size="large"
            autoComplete="off"
            onFocus={e => handleFields(e.type, 'partnerCode')}
            onBlur={e => handleFields(e.type, 'partnerCode')}
            data-id="ManagerSignup_partnerCode"
          />,
        )}
        <div
          className={classNames(sign.inputLabel, {
            [sign.labelActive]: fieldsActiveState.partnerCode || values.partnerCode,
          })}
        >
          {t('signup.usreou')}
        </div>
      </Form.Item>

      <Form.Item className={sign.signup_items}>
        {getFieldDecorator('email', {
          rules: RULES(t).EMAIL,
          initialValue: values.email,
          validateFirst: true,
        })(
          <Input
            size="large"
            autoComplete="off"
            data-id="ManagerSignup_email"
            onFocus={e => handleFields(e.type, 'email')}
            onBlur={e => handleFields(e.type, 'email')}
          />,
        )}
        <div
          className={classNames(sign.inputLabel, {
            [sign.labelActive]: fieldsActiveState.email || values.email,
          })}
        >
          {t('signup.enter_email')}
        </div>
      </Form.Item>
      <Form.Item
        className={classNames(sign.signup_items, sign.phoneinputLabel, {
          [sign.phonelabelActive]: fieldsActiveState.phoneNumber,
        })}
      >
        {getFieldDecorator('phoneNumber', {
          rules: RULES(t).PHONE,
          initialValue: values.phoneNumber,
        })(<PhoneNumberInput title={t('signup.enter_phone_number')} />)}
      </Form.Item>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
          {t('signup.next')}
        </Button>
      </Form.Item>
    </>
  );
};

export default FirstStep;
