import React, { useState, FunctionComponent, useEffect } from 'react';
import history from '../../../routes/history';
import classNames from 'classnames';
import { Form, Button, Typography } from 'antd';
import { useParams } from 'react-router-dom';

// images
import arrowLeft from 'assets/img/layout/arrowLeft.svg';

// components
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import LastStep from './steps/LastStep';
import HintCounter from './widgets/HintCounter';
import SpinnerModal from './widgets/SpinnerModal';
import Header from '../components/header';

// styles
import styles from '../auth.module.less';
import sign from '../SignUp/SignUp.module.less';
// utils
import { countdownDelay, passwordRegExp, asyncErr } from './utils';
import { initialActiveState, initialState } from './userState';
import { ROUTES } from 'constants/routes';
import { IManagerSignup, TActiveState, IStepValues, TDepartmentsType } from './types';

import {
  sendSignupToken,
  getSignupToken,
  setAsyncError,
  sendSignupData,
  validateUser,
} from './catchHandlers/getSignupToken';
import { checkInsuranceCode } from 'api/auth';
import { useTranslation } from 'react-i18next';

const ManagerSignUp: FunctionComponent<IManagerSignup> = ({ form, form: { getFieldValue, validateFields } }) => {
  const [step, setStep] = useState<number>(0);
  const [values, setValues] = useState<IStepValues>(initialState);
  const [fieldsActiveState, setFieldsActiveState] = useState<TActiveState>(initialActiveState);
  const [pending, setPending] = useState<boolean>(false);
  const { id: insuranceCode } = useParams();

  const getValidation = code => {
    checkInsuranceCode(code)
      .then(data => {
        localStorage.setItem('companyCode', code);
      })
      .catch(er => {
        history.push('/404');
      });
  };

  useEffect(() => {
    if (insuranceCode) {
      getValidation(insuranceCode);
    }
  }, []);

  const handleFields = (eventName: string, fieldName: string): void => {
    if (!!getFieldValue(fieldName)) return;
    setFieldsActiveState({ ...fieldsActiveState, [fieldName]: eventName === 'focus' });
  };

  const setPartnerId = (key: TDepartmentsType[]): void => {
    if (key.length === 1 && key[0].id) {
      setValues({ ...values, partnerDepartmentId: key[0].id });
    }
  };

  const onTimeEnd = (): void => {
    setStep(0);
    setValues({
      ...initialState,
      phoneNumber: values.phoneNumber,
      partnerCode: values.partnerCode,
      email: values.email,
    });
    setFieldsActiveState({ ...initialActiveState });
  };

  const changeStep = (step: number): void => setStep(step);
  const setPendingState = (state: boolean): void => setPending(state);
  const setBeforeSubmit = (fields: IStepValues): void =>
    setValues({ ...values, ...fields, token: fields.token || values.token });

  const onSuccess = (fields: IStepValues): void => {
    if (fields) {
      setValues({
        ...values,
        ...fields,
        token: fields.token || values.token,
        phoneNumber: values.phoneNumber,
        partnerCode: values.partnerCode,
        email: values.email,
      });
    }
    setStep(step + 1);
  };

  const loginHandler = (): void => {
    const code = localStorage.getItem('companyCode');
    history.push(code ? ROUTES.AUTH.LOGIN.replace(':id', `${code}`) : ROUTES.PARTNER_AUTH.LOGIN);
  };

  const resendSms = () => {
    getSignupToken(
      {
        ...values,
        phoneNumber: `+380${values.phoneNumber}`,
        partnerCode: values.partnerCode,
        email: values.email,
      },
      null,
      saveAfterResendCode,
      form,
      t,
    );
  };

  const saveAfterResendCode = (fields: IStepValues) => {
    if (fields) {
      setValues({ ...fields });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    validateFields((err, fields: IStepValues) => {
      if (err) return;

      switch (step) {
        case 0:
          setBeforeSubmit({ ...fields, phoneNumber: fields.phoneNumber });
          validateUser({ ...fields, phoneNumber: `+380${fields.phoneNumber}` }, setPendingState, onSuccess, form, t);
          break;
        case 1:
          setBeforeSubmit({ ...fields, token: fields.token });
          sendSignupToken(
            {
              ...fields,
              partnerCode: values.partnerCode,
              phoneNumber: `+380${fields.phoneNumber}`,
              email: values.email,
            },
            setPendingState,
            onSuccess,
            form,
            t,
          );
          break;
        case 2:
          const { password, confirmPassword } = fields;
          if (password === confirmPassword && passwordRegExp.test(password)) {
            const { partnerDepartment, company, ...rest } = fields;
            const { partnerDepartment: a, company: b, ...others } = values;
            const createdObject = {
              ...others,
              ...rest,
              phoneNumber: `+380${values.phoneNumber}`,
            };

            setBeforeSubmit(fields);
            sendSignupData(createdObject, setPendingState, onSuccess, form, t);
          } else {
            setAsyncError([{ field: 'confirmPassword', code: 'no_match' }], form, asyncErr(t), null, t);
          }
          break;
        case 3:
          loginHandler();
          break;
      }
    });
  };

  const redirectToLogin = () => {
    history.push(insuranceCode ? ROUTES.AUTH.LOGIN.replace(':id', `${insuranceCode}`) : ROUTES.PARTNER_AUTH.LOGIN);
  };
  const { t } = useTranslation();

  return (
    <div className={styles.authWrapper}>
      <Header />
      <div className={classNames(styles.formContainer, sign.signup_container, sign.manager_container)}>
        {step < 3 && <span className={sign.green_text}>{`${t('signup.step')} ${step + 1}/3`}</span>}
        <Form onSubmit={handleSubmit} className="login-form">
          {(step === 1 || step === 2) && <HintCounter step={step} delay={countdownDelay} onTimeEnd={onTimeEnd} />}
          {step === 0 && (
            <FirstStep form={form} fieldsActiveState={fieldsActiveState} handleFields={handleFields} values={values} />
          )}
          {step === 1 && (
            <SecondStep
              fieldsActiveState={fieldsActiveState}
              handleFields={handleFields}
              values={values}
              changeStep={changeStep}
              form={form}
              resendSms={resendSms}
            />
          )}
          {step === 2 && (
            <ThirdStep
              form={form}
              setPartnerId={setPartnerId}
              fieldsActiveState={fieldsActiveState}
              handleFields={handleFields}
              values={values}
            />
          )}
          {step === 3 && <LastStep values={values} />}
        </Form>
        {step < 3 && (
          <div className={sign.bottomLink}>
            {step > 0 ? (
              <div className={styles.backButton}>
                <Button
                  type="link"
                  ghost
                  onClick={() => {
                    setStep(0);
                    setFieldsActiveState(initialActiveState);
                  }}
                >
                  <img className={sign.leftIcon} src={arrowLeft} alt="right icon" />
                  <Typography.Text className={classNames(sign.small_text, sign.gray_text)}>
                    {t('signup.back')}
                  </Typography.Text>
                </Button>
              </div>
            ) : (
              <div className={classNames(styles.registration, sign.small_text, sign.gray_text)}>
                <span className={sign.leftIcon}>{t('signup.have_a_account')}</span>
                <span onClick={redirectToLogin} className={styles.link}>
                  <span className={classNames(sign.small_text, sign.green_text)} data-id="Return-Login-Button">
                    {t('signup.log_in')}
                  </span>
                </span>
              </div>
            )}
          </div>
        )}
        <SpinnerModal isOpen={pending} />
      </div>
    </div>
  );
};

export default Form.create<IManagerSignup>({ name: 'ManagerSignup' })(ManagerSignUp);
