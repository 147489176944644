export default () => ({
  root: {
    display: 'flex',
    overflow: 'auto',
    borderRadius: 14,
    backgroundColor: '#FFFFFF',
    '& > *:first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 14,
      borderBottomLeftRadius: 14,
    },
    '& > *:last-child': {
      borderTopRightRadius: 14,
      borderBottomRightRadius: 14,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  item: {
    flexGrow: 1,
  },
});
