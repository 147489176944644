import React from 'react';
import DropCustom from './TableToolsHandler';
import TableTools from './TableTools';

const columns = t => {
  return [
    {
      title: `№`,
      value: 'id',
      key: 'id',
      dataIndex: 'id',
      width: 70,
    },
    {
      title: `Брендова название`,
      value: 'brandName',
      key: 'brandName',
      dataIndex: 'brandName',
      width: 170,
    },
    {
      title: `Область`,
      value: 'locality',
      key: 'locality',
      dataIndex: 'locality',
      width: 170,
    },
    {
      title: `Населений пункт`,
      value: 'city',
      key: 'city',
      dataIndex: 'city',
      width: 170,
    },
    {
      title: `Фактична адреса`,
      value: 'street',
      key: 'street',
      dataIndex: 'street',
      width: 170,
    },
    {
      title: `Номер будинку`,
      value: 'houseNumber',
      key: 'houseNumber',
      dataIndex: 'houseNumber',
      width: 170,
    },
    {
      title: `Тип ЛПВ`,
      value: 'clinicType',
      key: 'clinicType',
      dataIndex: 'clinicType',
      width: 170,
    },
    {
      title: `Бренд`,
      value: 'brand',
      key: 'brand',
      dataIndex: 'brand',
      width: 170,
    },
    {
      title: '',
      key: 'id',
      value: 'id',
      width: 50,
      render: (id: any, record: any) => (
        <DropCustom Component={TableTools} data={record} record={record}>
          {' '}
        </DropCustom>
      ),
    },
  ];
};

export default columns;
