import React, { useEffect } from 'react';
import { Checkbox } from 'antd';
import { withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import CustomTable from 'components/CustomTable';
import columnsList from './columns';
import { actDetailsSelector } from 'store/actDetails/selectors';
import { changeAvailableStatus, getAvaliableContracts, setAvaliablePagination } from 'store/actDetails/actions';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const ModalTable = () => {
  const dispatcher = useDispatch();
  const { t } = useTranslation();
  const {
    avaliableContracts,
    avaliablePagination: { current, perPage },
    actInfo,
  } = useSelector(actDetailsSelector);
  useEffect(() => {
    dispatcher(getAvaliableContracts(actInfo));
  }, [current, perPage]);

  const onPageChange = (page: number, pageSize?: number | undefined): void => {
    dispatcher(setAvaliablePagination(page, pageSize || 0));
  };

  const getStatus = ({ target }) => (target.checked ? 'ACTIVE' : 'INACTIVE');

  const onChange = (status, row) => {
    const currentStatus = getStatus(status);
    dispatcher(changeAvailableStatus(row.id, currentStatus));
  };

  const columns = columnsList(t).map(({ title, value, width }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
      width: width || 10,
      sorter: false,
    };

    switch (value) {
      case 'choose': {
        return {
          ...basicColumn,
          title: '',
          render: (status: string, row) => {
            return (
              <div>
                <Checkbox
                  defaultChecked={false}
                  onChange={e => {
                    onChange(e, row);
                  }}
                />
              </div>
            );
          },
        };
      }
      default: {
        return basicColumn;
      }
    }
  });

  return (
    <div>
      <CustomTable
        page={current}
        contentHeight={510}
        pageSize={perPage}
        total={avaliableContracts.totalCount || 0}
        pending={false || false}
        data={avaliableContracts.contracts.length ? avaliableContracts.contracts : []}
        columns={columns}
        filtersInfo={() => {}}
        clearFilters={() => {}}
        handleFilters={() => {}}
        handleTableChange={() => {}}
        onPageSizeChange={() => {}}
        onPageChange={onPageChange}
        rowKey={'id'}
        disablePerPageChange={true}
        xValue={150}
      />
    </div>
  );
};

export default withStyles(styles)(ModalTable);
