import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
import { ITerminationDetailsType } from 'types/terminationTypes';
import { changeDocumentInfo, changeDocumentDates, changeDocumentDelete } from './helper';

//@ts-ignore
import update from 'update-js';

export const initialState: ITerminationDetailsType = {
  terminationDetails: {
    insuranceProgramId: null,
    partnerId: null,
    cause: undefined,
    initiator: undefined,
    terminationCauseCodeId: null,
    contract: {
      client: '',
      conclusionDate: '',
      contractNumber: '',
      endDate: '',
      id: 0,
      insuranceProgram: '',
      paymentAmount: 0,
      insuranceSumCurrency: '',
      insuranceProgramId: 0,
      startDate: '',
      insuranceProgramCode: '',
      prolongationEndDate: '',
      prolongationStartDate: '',
      signDate: '',
      originSignDate: '',
    },
    currency: '',
    id: null,
    realEndDate: '',
    estimatedRealEndDate: '',
    realTerm: 0,
    recipient: {
      accountNumber: '',
      accountType: '',
      // bankName: '',
      code: '',
      companyName: '',
      counterpartyType: '',
      firstName: '',
      id: 0,
      lastName: '',
      locality: '',
      middleName: '',
      phoneNumber: '',
      street: '',
      type: '',
    },
    refundSum: 0,
    terminationDate: '',
    terminationNumber: '',
    documents: [],
  },
  causes: [],
  initiators: [],
  accountTypes: [],
  errorMessage: '',
  wrongCode: null,
  loading: false,
  isReadOnly: false,
  disableSubmit: false,
  userType: '',
  contractTerminationId: null,
};

const termination = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      initiator: payload.initiator || state.terminationDetails.initiator,
      cause: payload.cause || state.terminationDetails.cause,
      recipient: {
        ...state.terminationDetails.recipient,
        accountNumber: payload.accountNumber || state.terminationDetails.recipient.accountNumber,
        accountType: payload.accountType || state.terminationDetails.recipient.accountType,
        // bankName: payload.bankName || state.terminationDetails.recipient.bankName,
        code: payload.code || state.terminationDetails.recipient.code,
      },
    },
  };
};

const setProlongDate = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      contract: {
        ...state.terminationDetails.contract,
        prolongationEndDate: payload.prolongationEndDate ?? '',
        prolongationStartDate: payload.prolongationStartDate ?? '',
      },
    },
  };
};

const setSignData = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      contract: {
        ...state.terminationDetails.contract,
        signDate: payload.signDate,
        originSignDate: payload.originSignDate,
      },
    },
  };
};

const setTerminationCause = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      cause: payload.cause,
    },
  };
};

const setInitiators = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    initiators: payload.initiators || state.initiators,
  };
};

const setAcountTypes = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    accountTypes: payload.accountTypes || state.accountTypes,
  };
};

const setInitiator = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      initiator: payload.initiator || state.terminationDetails.initiator,
    },
  };
};

const terminationId = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      id: payload.terminationId || state.terminationDetails.id,
    },
  };
};

const terminationTemplate = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: payload.terminationDetails || state.terminationDetails,
  };
};

const terminationState = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    loading: payload.loading,
    errorMessage: payload.errorMessage || state.errorMessage,
    causes: payload.causes || state.causes,
    isReadOnly: payload.isReadOnly || state.isReadOnly,
    wrongCode: payload.wrongCode || state.wrongCode,
    disableSubmit: payload.disableSubmit || state.disableSubmit,
    userType: payload.userType || state.userType,
    contractTerminationId: payload.contractTerminationId || state.contractTerminationId,
  };
};

const partnerProgramId = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => ({
  ...state,
  terminationDetails: {
    ...state.terminationDetails,
    insuranceProgramId: payload.insuranceProgramId,
    partnerId: payload.partnerId,
  },
});

const terminationName = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      recipient: {
        ...state.terminationDetails.recipient,
        ...payload,
      },
    },
  };
};

const setRefundSum = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return update(state, 'terminationDetails.refundSum', payload);
};

const setDocumentsData = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      documents: payload,
    },
  };
};

const setDocumentInfo = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  const { value, id } = payload;
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      documents: changeDocumentInfo(state.terminationDetails.documents, value, id),
    },
  };
};

const setDocumentDates = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  const { id, createDate, approveDate } = payload;
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      documents: changeDocumentDates(state.terminationDetails.documents, createDate, approveDate, id),
    },
  };
};

const setDocumentDelete = (state: ITerminationDetailsType, { payload }: AnyAction): ITerminationDetailsType => {
  const { id } = payload;
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      documents: changeDocumentDelete(state.terminationDetails.documents, id),
    },
  };
};

const clearTermination = (): ITerminationDetailsType => {
  return {
    ...initialState,
  };
};

const clearBankCredentials = (state: ITerminationDetailsType): ITerminationDetailsType => {
  return {
    ...state,
    terminationDetails: {
      ...state.terminationDetails,
      recipient: {
        ...state.terminationDetails.recipient,
        accountNumber: null,
        // bankName: null,
      },
    },
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    termination,
    terminationState,
    terminationTemplate,
    terminationId,
    clearTermination,
    terminationName,
    setRefundSum,
    setDocumentsData,
    setDocumentInfo,
    setDocumentDates,
    setDocumentDelete,
    clearBankCredentials,
    setTerminationCause,
    setInitiators,
    setInitiator,
    partnerProgramId,
    setProlongDate,
    setSignData,
    setAcountTypes,
  },
});
