export enum OWNER_EQUIPMENT_TYPE {
  INSURANCE_COMPANY = 'insured_events_list.insurance_company',
  INSURER = 'insured_events_list.inshured',
  BENEFICIARY = 'insured_events_list.beneficiary',
}

export enum STATUS_TYPE {
  INSURANCE_CASE = 'insured_events_list.insurance case',
  ON_WAY_TO_REPAIR = 'insured_events_list.on_way_to_repair',
  ON_WAY_TO_INSURANCE_COMPANY = 'insured_events_list.on_way_to_insurance_company',
  ON_WAY_TO_CLIENT = 'insured_events_list.on_way_to_client',
  REPAIR = 'insured_events_list.repair',
  SELLING = 'insured_events_list.selling',
  STEALING = 'insured_events_list.stealing',
  SOLD = 'insured_events_list.sold',
}

export enum RESOLUTION_TYPE {
  EXPRESS_PAYMENT = 'insured_events_list.express_payment_resolution',
  SHOP_SERVICE_REPAIR = 'insured_events_list.shop_service_repair',
  SELF_SERVICE_REPAIR = 'insured_events_list.self_service_repair',
  PAYMENT = 'insured_events_list.payment',
  SERVICE_STATION_PAYMENT = 'insured_events_list.service_station_payment',
}

export enum DELIVERY_TYPE {
  SELF_PIKUP = 'insured_events_list.self_pikup',
  NOVA_POSHTA = 'insured_events_list.new_post',
  OLX_DELIVERY = 'insured_events_list.olx_delivery',
  UKRPOSHTA = 'insured_events_list.ukrposhta',
  COURIER_SC = 'insured_events_list.courier_sc',
  COURIER_DELIVERY = 'insured_events_list.courier_delivery',
}

export enum REPAIR_STATUS_TYPE {
  ON_WAY_TO_REPAIR = 'insured_events_list.on_way_repair',
  UNDER_REPAIR = 'insured_events_list.under_repair',
  REPAIR_POSTPONED = 'insured_events_list.repairs_postponed',
  REPAIR_NOT_POSSIBLE = 'insured_events_list.pepairs_not_possible',
  REPAIR_COMPLETED = 'insured_events_list.renovation_completed',
  REPAIR_INEXPEDIENT = 'insured_events_list.repair_impractical',
}

export enum CLAIM_STATUS {
  PAYMENT = 'insured_events_list.payment_claim',
  ANNUL = 'insured_events_list.canceled_claim',
  REPORTED = 'insured_events_list.reported',
  PAYMENT_AWAITING = 'insured_events_list.payment_expected',
  PAYMENT_REJECTED = 'insured_events_list.denied',
  DOCUMENTS_COLLECTION = 'insured_events_list.submission_documents',
  PAYMENT_CALCULATION = 'insured_events_list.expert_evaluation',
}

export enum DELIVERY {
  UKRPOSHTA = 'insured_events_list.ukrposhta_delivery',
  SELF_PIKUP = 'insured_events_list.self_pickup',
  NOVA_POSHTA = 'insured_events_list.new_post_delivery',
  OLX_DELIVERY = 'insured_events_list.oxl_delivery',
  COURIER_SC = 'insured_events_list.courier_service_center',
  COURIER_DELIVERY = 'insured_events_list.courier_delivery_delivery',
}

export enum REPAIR_STATUS {
  UNDER_REPAIR = 'insured_events_list.under_repair_status',
  REPAIR_COMPLETED = 'insured_events_list.renovation_completed_status',
  ON_WAY_TO_REPAIR = 'insured_events_list.on_way_repair_status',
  REPAIR_POSTPONED = 'insured_events_list.repairs_postponed_status',
  REPAIR_NOT_POSSIBLE = 'insured_events_list.repairs_not_possible',
  REPAIR_INEXPEDIENT = 'insured_events_list.repair_impractical_status',
}
