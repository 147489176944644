export default {
  ROLE_CLIENT: {
    CLIENT_CARD: {},
    CHATS: {
      READ: true,
      BUTTONS: true,
      MENU: false,
    },
    SUBLIMITS: {
      READ: false,
    },
    DASHBOARD: {
      READ: true,
      ANALYTICS: false,
    },
    TERMINATION: {
      READ: true,
      CREATE: true,
      PRINT: true,
    },
    TERMINATIONS_LIST: {
      READ: false,
      PRINT: false,
    },
    INSURANCE: {
      READ: true,
      CREATE: true,
      PRINT: true,
    },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: true,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: false,
    },
    CONTRACTS_LIST_TOTAL: { READ: false },
    SEARCH: {
      READ: true,
      CONTRACT: false,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true,
      INSURANCE: true,
    },
    EXPRESSPAYMENT: {
      PAY: false,
      ALERT: true,
    },
    ACTS: {
      READ: false,
      PRINT: false,
      CREATE: false,
    },
    INSURANCE_LIST: {
      READ: false,
      PRINT: false,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
      LOCALITY: true,
      EMAIL: true,
    },
    USERS_ACTIVITIES: {
      READ: false,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    SALE_POINTS: {
      READ: true,
    },
  },
};
