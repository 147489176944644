import moment from 'moment';
import React from 'react';

import { moneyFormatter } from 'utils/helpers';
import {
  DELIVERY,
  STATUS_TYPE,
  CLAIM_STATUS,
  REPAIR_STATUS,
  RESOLUTION_TYPE,
  OWNER_EQUIPMENT_TYPE,
} from './constants/RegistrationDevicesConstants';

const columnsList = t => {
  return [
    {
      title: t('registration_devices.sale_register_status_table'),
      value: 'saleRegisterStatus',
      width: 220,
      render: (status: string) => t(STATUS_TYPE[status]),
    },
    {
      title: t('registration_devices.owner_equipment_table'),
      value: 'ownerEquipment',
      width: 200,
      render: (status: string) => t(OWNER_EQUIPMENT_TYPE[status]),
    },
    {
      title: t('registration_devices.insurance_object_name_table'),
      value: 'insuranceObjectName',
      width: 200,
    },
    {
      title: t('registration_devices.insurance_object_subtype_table'),
      value: 'insuranceObjectSubtype',
      width: 200,
    },
    {
      title: t('insured_events_list.pid_express_payment_table'),
      value: 'insuranceObjectPid',
      width: 250,
    },
    {
      title: t('registration_devices.contract_number_table'),
      value: 'contractNumber',
      width: 280,
    },
    {
      title: t('registration_devices.insurance_sum_table'),
      value: 'insuranceSum',
      width: 180,
      render: (text: number) => <span>{`${moneyFormatter(text)} ₴`}</span>,
    },
    {
      title: t('registration_devices.sale_revenue_table'),
      value: 'saleRevenue',
      width: 180,
      render: (text: number) => <span>{`${moneyFormatter(text)} ₴`}</span>,
    },
    {
      title: t('registration_devices.resolution_table'),
      value: 'resolution',
      width: 270,
      render: (status: string) => t(RESOLUTION_TYPE[status]),
    },
    {
      title: t('registration_devices.claim_status_table'),
      value: 'claimStatus',
      width: 270,
      render: (status: string) => t(CLAIM_STATUS[status]),
    },
    {
      title: t('registration_devices.incident_date_table'),
      value: 'incidentDate',
      width: 240,
      render: (status: string) => moment(status).format('DD.MM.YYYY'),
    },
    {
      title: t('registration_devices.partner_department_payout_table'),
      value: 'partnerDepartmentPayout',
      width: 300,
      ellipsis: true,
    },
    {
      title: t('registration_devices.paid'),
      value: 'paymentSum',
      width: 180,
      render: (text: number) => <span>{`${moneyFormatter(text)} ₴`}</span>,
    },
    {
      title: t('registration_devices.delivery_table'),
      value: 'delivery',
      width: 270,
      render: (status: string) => t(DELIVERY[status]),
    },
    {
      title: t('registration_devices.repair_equipment_delivery_address_table'),
      value: 'repairEquipmentDeliveryAddress',
      width: 280,
      ellipsis: true,
    },
    {
      title: t('registration_devices.invoice_number_table'),
      value: 'invoiceNumber',
      width: 200,
    },
    {
      title: t('registration_devices.service_center_table'),
      value: 'serviceCenter',
      width: 230,
      ellipsis: true,
    },
    {
      title: t('registration_devices.service_center_date_table'),
      value: 'serviceCenterDate',
      width: 230,
    },
    {
      title: t('registration_devices.damage_table'),
      value: 'damage',
      width: 280,
      ellipsis: true,
    },
    {
      title: t('registration_devices.repair_status_table'),
      value: 'repairStatus',
      width: 200,
      render: (status: string) => t(REPAIR_STATUS[status]),
    },
    {
      title: t('registration_devices.repair_cost_table'),
      value: 'repairCost',
      width: 180,
      render: (text: number) => <span>{`${moneyFormatter(text)} ₴`}</span>,
    },
    {
      title: t('registration_devices.dispatch_from_repair_date_table'),
      value: 'dispatchFromRepairDate',
      width: 240,
    },
    {
      title: t('registration_devices.dispatch_after_sale_delivery_table'),
      value: 'dispatchAfterSaleDelivery',
      width: 270,
      render: (status: string) => t(DELIVERY[status]),
    },
    {
      title: t('registration_devices.repair_equipment_invoice_number_table'),
      value: 'repairEquipmentInvoiceNumber',
      width: 180,
    },
    {
      title: t('registration_devices.receipt_date_table'),
      value: 'receiptDate',
      width: 300,
    },
    {
      title: t('registration_devices.amount_repair_cost_table'),
      value: 'amountRepairCost',
      width: 180,
      render: (text: number) => <span>{`${moneyFormatter(text)} ₴`}</span>,
    },
    {
      title: t('registration_devices.other_expenses_table'),
      value: 'otherExpenses',
      width: 180,
      render: (text: number) => <span>{`${moneyFormatter(text)} ₴`}</span>,
    },
  ];
};

export default columnsList;
