export const parsePersonFields = (personField: string) => {
  switch (personField) {
    case '№ договора':
      return 'contractNumber';
    case 'Порядочный номер':
      return 'orderNumber';
    case 'Фамилия':
      return 'lastName';
    case 'Имя':
      return 'firstName';
    case 'Отчество':
      return 'middleName';
    case 'Телефон':
      return 'phoneNumber';
    case 'День рождения':
      return 'birthday';
    case 'Email':
      return 'email';
    case 'Паспорт':
      return 'passport';
    case 'Страховая премия':
      return 'insuranceSum';
    case 'Дата начала действия договора':
      return 'startDate';
    case 'Дата конца действия договора':
      return 'endDate';
    default:
      return personField;
  }
};
