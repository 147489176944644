import { rules } from './rules';
import jwt from 'jsonwebtoken';
import { IAuthenticationTokenPayload } from '../types/Authentication';
import { Role } from 'types/dto/contracts-service';

export const getUserRole = () => {
  const token = localStorage.getItem('accessToken');

  const user = jwt.decode(token!) as IAuthenticationTokenPayload;

  if (user && user.AUTHORITIES_CLAIM) {
    return user.AUTHORITIES_CLAIM;
  }
};

export const checkPermissions = (page: string, action: string, isHideForClient?: boolean) => {
  const userRole = getUserRole();
  if (!userRole) return false;

  if (userRole === 'ROLE_CLIENT' && isHideForClient) return false;
  const permissions = rules[userRole];
  return permissions && permissions[page.toUpperCase()] && permissions[page.toUpperCase()][action.toUpperCase()];
};

export const roleApproved = () => {
  const userRole = getUserRole();
  return userRole && !!rules[userRole];
};

export const isShowComponent = () => {
  const userRole = getUserRole();

  if (!userRole) {
    return false;
  }

  return [
    Role.ROLE_ADMIN,
    Role.ROLE_INSURANCE_ADMIN,
    Role.ROLE_INSURANCE_MANAGER,
    Role.ROLE_INSURANCE_CLAIM_MANAGER,
    Role.ROLE_PARTNER_ADMIN,
    Role.ROLE_PARTNER_MANAGER,
    Role.ROLE_HR_MANAGER,
  ].includes(userRole);
};
