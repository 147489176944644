import { createTypes } from 'redux-compose-reducer';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { notification } from 'antd';

import { AppState } from '../reducer';
import { StoreType } from 'types/store';

import { getTablePage, getPrivateFiltersTable, downloadTerminationXLS } from 'api/terminationList';
import { getInsuranceProgram, getTerminationInsuranceProgram } from '../../api/statistics';
import { ITerminationList } from '../../types/terminationList';
import onDownload from '../../callbacks/onDownload';
import { checkPermissions, getUserRole } from 'roles/userCan';

export const TYPES = createTypes('terminationList', [
  'setTerminationList',
  'setPending',
  'setFilters',
  'setPagination',
  'setSorting',
  'getPrivateFiltersValues',
  'getPrivateFiltersData',
  'setCurrentCompanies',
  'setTotalInformation',
  'setTerminationListInsuranceProgram',
  'setTerminationListPrivateFilter',
]);

type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const getTerminationTable = (): ThunkResult => async (dispatch, getState) => {
  dispatch({ type: TYPES.setPending, payload: { pending: true } });

  try {
    const {
      terminationList: { pagination, sorting, filters },
    } = getState();

    const { resultList = [], count = 0, total = {} } = (await getTablePage({ pagination, sorting, filters })).data;

    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch({ type: TYPES.setTotalInformation, payload: total });
    dispatch({ type: TYPES.setTerminationList, payload: { resultList, count } });
  } catch (err) {
    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch({ type: TYPES.setTerminationList, payload: { error: err.message } });
  }
};

export const setFilters = (data: any): ThunkResult => dispatch =>
  dispatch({
    type: TYPES.setFilters,
    payload: {
      ...data,
    },
  });

export const setPagination = (current: number, perPage: number): ThunkResult => dispatch => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setSorting = ({ field, direction }: { field: string; direction: string }): ThunkResult => dispatch => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};

export const getFilteredContracts = (filtersInfo: object, t): ThunkResult => async (dispatch, getState) => {
  dispatch({ type: TYPES.setTerminationList, payload: { pending: true } });

  const {
    terminationList: {
      pagination,
      sorting,
      filters: {
        dateFrom,
        dateTo,
        insuranceCompanyId: { id: partnerId },
        insuranceProgramId: { id: partnerDepartmentId },
      },
    },
  }: AppState = getState();

  const convertFilters = {
    partnerDepartmentId,
    partnerId,
    terminationDate: dateFrom && dateTo && `${dateFrom}..${dateTo}`,
    ...filtersInfo,
  };

  try {
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
    const { resultList, count } = (
      await getPrivateFiltersTable({
        filters: {
          ...filtersInfo,
          ...convertFilters,
          sorting:
            (!sorting.direction && '-terminationDate') || sorting.direction === 'DESC'
              ? `-${sorting.field}`
              : `${sorting.field}`,
        },
        pagination,
      })
    ).data;

    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch({ type: TYPES.getPrivateFiltersData, payload: { resultList, count } });
  } catch (error) {
    dispatch({ type: TYPES.setPending, payload: { pending: false } });

    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

export const setTerminationListPrivateFilter = payload => dispatch => {
  dispatch({ type: TYPES.setTerminationListPrivateFilter, payload });
};

export const downloadTerminationReport = (dateFrom: string, dateTo: string, t): ThunkResult => async (
  dispatch,
  getState,
) => {
  const {
    terminationList: {
      filters: {
        insuranceCompanyId: { id: partnerId },
        insuranceProgramId: { id: partnerDepartmentId },
      },
      sorting,
    },
  }: AppState = getState();

  const defaultSorting = {
    field: 'terminationDate',
    direction: 'DESC',
  };

  let sortingPrint = sorting.field ? sorting : defaultSorting;
  const sortingQuery = sortingPrint.direction === 'DESC' ? `-${sortingPrint.field},-id` : `${sortingPrint.field},id`;

  const datas = {
    partnerDepartmentId,
    partnerId,
    sorting: sortingQuery,
    terminationDate: `${dateFrom}..${dateTo}`,
  };

  return onDownload(() => downloadTerminationXLS(datas), {}, t);
};

export const setCompanies = t => async (dispatch, getState: () => AppState) => {
  try {
    const {
      userCard: {
        data: { partner, insuranceCompany },
      },
    }: AppState = getState();

    dispatch({ type: TYPES.setTerminationList, payload: { pending: true } });

    let response;

    if (getUserRole() === 'ROLE_HR_MANAGER' && partner?.id && insuranceCompany?.id) {
      response = await getTerminationInsuranceProgram(partner.id, insuranceCompany.id);
    } else {
      response = await getTerminationInsuranceProgram();
    }

    const programId = response?.data?.[0].id;
    const program = (await getInsuranceProgram(programId)).data.resultList || [];
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch({ type: TYPES.setCurrentCompanies, payload: { companies: payload, insuranceProgram: program } });
    dispatch(getTerminationTable());
  } catch (error) {
    dispatch({ type: TYPES.setPending, payload: { pending: false } });

    notification.error({
      message: t('popup.update_filters'),
      description: t('popup.please_try_again_later'),
    });
  }
};

export const setTerminationListInsuranceProgram = (t, id): ThunkResult => async dispatch => {
  try {
    dispatch({ type: TYPES.setPending, payload: { pending: true } });
    const program = (await getInsuranceProgram(id)).data.resultList || [];
    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch({ type: TYPES.setTerminationListInsuranceProgram, payload: { insuranceProgram: program } });
  } catch (error) {
    dispatch({ type: TYPES.setPending, payload: { pending: false } });

    notification.error({
      message: t('popup.updating_program'),
      description: t('popup.try_again_products'),
    });
  }
};
