//@ts-nocheck
export const changeDocumentInfo = (arr, value, id) =>
  arr.map(document => {
    if (document.documentType.id === id) {
      return {
        ...document,
        information: value,
      };
    }
    return document;
  });

export const changeDocumentDates = (arr, createdDate, signDate, id) =>
  arr.map(document => {
    if (document.documentType.id === id) {
      return {
        ...document,
        receiptDate: createdDate || '',
        signDate: signDate || '',
      };
    }
    return document;
  });

export const changeDocumentDelete = (arr, id) =>
  arr.map(document => {
    if (document.documentType.id === id) {
      return {
        ...document,
        attachment: false,
        receiptDate: null,
      };
    }
    return document;
  });
