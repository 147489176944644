import createInstance from './axios';
import { ExpressPaymentDto, ValidationErrorDto, OptionDto } from 'types/dto/contracts-service';
const expressApi = createInstance('CONTRACTS');

interface IExpressPayment {
  claimId?: number | string;
  expressPaymentData?: any;
  rejectId?: number | string;
  paymentSystemType?: string;
}

export const getExpressPaymentTemplate = ({ claimId }: IExpressPayment) =>
  expressApi.get<ExpressPaymentDto>(`/claims/${claimId}/express-payments/templates`);

export const getPaymentsSystems = ({ claimId }: IExpressPayment) => {
  return expressApi.get<('FONDY' | 'CONCORD')[]>(`/insurance-companies/${claimId}/payment-systems`);
};

export const sendExpressPaymentTemplate = ({ expressPaymentData, claimId }: IExpressPayment) => {
  return expressApi.post<ValidationErrorDto[]>(`/claims/${claimId}/express-payments`, {
    ...expressPaymentData.expressPayment,
  });
};

export const signExpressPayment = ({ claimId, paymentSystemType }: IExpressPayment) =>
  expressApi.post<void>(`/claims/${claimId}/express-payments/sign`, {
    paymentSystemType: paymentSystemType || '',
  });

export const signPayment = ({ claimId, paymentSystemType }: IExpressPayment) =>
  expressApi.post<void>(`/claims/${claimId}/pay`, {
    paymentSystemType: paymentSystemType || '',
  });

export const getRejectionCauses = () => expressApi.get<OptionDto[]>(`claims/express-payments/rejection-causes`);

export const rejectExpressPayment = ({ claimId, rejectId }: IExpressPayment) =>
  expressApi.get<void>(`/claims/${claimId}/express-payments/reject`, { params: { rejectId } });

export const getExpressPaymentStatus = ({ claimId }: IExpressPayment) =>
  expressApi.get<
    | 'REPORTED'
    | 'DOCUMENTS_COLLECTION'
    | 'PAYMENT_CALCULATION'
    | 'PAYMENT_AWAITING'
    | 'PAYMENT'
    | 'PAYMENT_REJECTED'
    | 'ANNUL'
  >(`/claims/${claimId}/status`);
