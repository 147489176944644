import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useDispatch } from 'react-redux';
import { Grid } from 'antd-modern';

import { ErrorsDto, UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import InputField from 'components/next/InputField/InputField';
import Password from 'components/next/Password/Password';
import Checkbox from 'components/next/Checkbox/Checkbox';
import Button from 'components/next/Button/Button';
import AuthContainer from 'components/next/AuthContainer/AuthContainer';
import * as api from 'api/auth';
import Link from 'components/next/Link/Link';
import InputContainer from 'components/next/InputContainer/InputContainer';
import { useRequired } from 'hooks/useRequired';
import { getCurrentUser } from 'store/user/actions';

import { schema, defaultValues, Values } from './Login.schema';
import Language from './Language/Language';

const cssExternalLinks = css`
  margin-top: 16px;
`;
const cssSubmit = css`
  margin-top: 24px;
  width: 150px;
`;

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { required } = useRequired(schema);

  const onSubmit = async (values: Values) => {
    setLoading(true);

    try {
      const response = await api.loginUsingPost({ username: values.username.trim(), password: values.password.trim() });

      localStorage.setItem('accessToken', response.data.access_token);
      await dispatch(getCurrentUser());
      history.push(ROUTES.DASHBOARD.ROOT);
    } catch (error) {
      reset();

      const errorMap = {
        default: t('log_in.check_the_data'),
        USER_INACTIVE: t('log_in.user_inactive'),
        USER_ONLY_CLIENT: t('log_in.user_only_client'),
        USER_NOT_CLIENT: t('log_in.user_not_client'),
      };

      (error?.response?.data as ErrorsDto).errors.forEach(err => {
        const message = errorMap[err.code] ?? errorMap.default;

        notification.error({ message });
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContainer>
      <Typography.Title level={screens.lg ? 1 : 3}>{t('log_in.title')}</Typography.Title>

      <Row type="flex" justify="space-between" align="middle">
        <Col>{t('log_in.as_a_partner')}</Col>
        <Col>
          <Language />
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)} data-test="login-form">
        <Controller
          name="username"
          control={control}
          render={({ onChange, value, name }) => (
            <InputField
              info={{ name, errors, required }}
              onChange={onChange}
              value={value}
              label={t('log_in.your_login')}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ onChange, value, name }) => (
            <Password
              info={{ name, errors, required }}
              onChange={onChange}
              value={value}
              label={t('log_in.password')}
            />
          )}
        />

        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Controller
              name="remember"
              control={control}
              render={({ onChange, value, name }) => (
                <Checkbox
                  info={{ name, errors, required }}
                  onChange={({ target }) => onChange(target.checked)}
                  checked={value}
                >
                  {t('log_in.remember_me')}
                </Checkbox>
              )}
            />
          </Col>

          <Col>
            <InputContainer colorBase="none">
              <Link
                onClick={() => {
                  history.push(ROUTES.PARTNER_AUTH.PASSWORD_RECOVERY);
                }}
              >
                {t('log_in.forgot_password')}
              </Link>
            </InputContainer>
          </Col>
        </Row>

        <Button type="primary" htmlType="submit" size="large" loading={loading} className={cssSubmit}>
          {t('log_in.log_in')}
        </Button>
      </form>

      <div className={cssExternalLinks}>
        {t('log_in.no_account')}
        <Link
          onClick={() => {
            history.push(ROUTES.AUTH.MANAGER_SIGNUP);
          }}
        >
          {' '}
          {t('log_in.register')}
        </Link>
      </div>
    </AuthContainer>
  );
};

export default Login;
