import { ImportedContractsHistory } from 'types/insuranceImport';
import { IntegrationHistoryItemView } from 'types/dto/contracts-service';

export const formatCompanies = (dataArr: IntegrationHistoryItemView[]) => {
  return dataArr
    .reduce((acc: IntegrationHistoryItemView[], curr) => {
      if (curr) {
        if (curr.message === 'NOT LOADED') {
          curr.message = 'Не завантажено';
        }
        if (curr.message?.includes(': LOADED')) {
          curr.message = 'Завантажено';
        }
        if (curr.message?.toLowerCase() === 'required') {
          curr.message = 'Присутні незаповнені та/або некорентно заповнені поля';
        }
        if (curr.message?.toLowerCase() === 'invalid_length') {
          curr.message = 'Некоректна довжина та/або формат договору';
        }
        if (curr.message?.toLowerCase() === 'duplication') {
          curr.message = 'Договір імпортовано раніше';
        }
        if (!curr?.message) {
          curr.message = 'Договір успішно імпортовано';
        }
        if (curr.message === 'ConclusionDate after StartDate') {
          curr.message = 'Дата заключення не відповідає періоду дії договору';
        }

        acc.push(curr);
      }
      return acc;
    }, [])
    .map((arr, i) => {
      arr.id = i + 1;
      return arr;
    });
};
