import React, { useState } from 'react';
import { css, cx } from '@emotion/css';

import { AnyObject } from 'types';
import { theme } from 'styles';

const cssContainer = css`
  position: relative;
  border-radius: 12px;
  margin: 8px 0 16px;
`;
const cssContainerHasError = css`
  margin: 8px 0 0;
`;
const cssContainerHasMargin = css`
  margin: 0;
`;
const cssLabel = css`
  margin-left: 12px;
  padding-top: 5px;
  color: ${theme.color.blueGray.main};
  transition: all 0.25s;
`;
const cssError = css`
  color: ${theme.color.error.main};
  font-size: 12px;
`;
const cssAsterisk = css`
  color: ${theme.color.error.main};
`;

export type Margin = 'none' | 'normal';
export type ColorBase = 'none' | 'white' | 'grey';
export interface Info {
  name: string;
  errors: AnyObject;
  required: AnyObject;
}

export const colorBaseMap: Record<ColorBase, string> = {
  none: 'none',
  white: theme.color.basic.white,
  grey: theme.color.blueGray.light,
};

interface Props {
  label?: string;
  children: React.ReactNode;
  className?: string;
  margin?: Margin;
  info?: Info;
  colorBase?: ColorBase;
}

const InputContainer = ({ children, label, className, margin = 'normal', colorBase = 'grey', info }: Props) => {
  const [focus, setFocus] = useState(false);
  const hasError = Boolean(info?.errors[info.name]);

  return (
    <>
      <div
        id={info?.name}
        className={cx(
          cssContainer,
          css`
            background: ${colorBaseMap[colorBase]};
          `,
          { [cssContainerHasError]: hasError },
          { [cssContainerHasMargin]: margin === 'none' },
          className,
        )}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        {label && (
          <div
            className={cx(cssLabel, {
              [css`
                color: ${hasError ? theme.color.error.main : theme.color.primary.main};
              `]: focus,
            })}
          >
            {label}
            {`${info?.required[info.name]}` ? <span className={cssAsterisk}>*</span> : ''}
          </div>
        )}
        {children}
      </div>

      {info?.errors[info.name] && (
        <div className={cx(cssError, 'errorFormItem_message')}>{info.errors[info.name].message}</div>
      )}
    </>
  );
};

export default InputContainer;
