import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import classNames from 'classnames';

import styles from './AddModal.module.less';
import closeIcon from 'assets/img/layout/greyClose.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAvaliableContracts, updateActDetailsAction } from 'store/actDetails/actions';
import { actDetailsSelector } from 'store/actDetails/selectors';
import ModalTable from './ModalTable/ModalTable';
import { useTranslation } from 'react-i18next';

interface IAddModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  id?: string;
}

export default ({ isModalOpen, onClose, id }: IAddModalProps) => {
  const dispatcher = useDispatch();
  const { actInfo } = useSelector(actDetailsSelector);
  const [searchText, setSearchText] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    if (actInfo && actInfo.agentContractId && actInfo.partnerId) {
      dispatcher(getAvaliableContracts(actInfo));
    }
  }, [actInfo]);

  const onCloseRest = () => {
    setSearchText('');
    dispatcher(getAvaliableContracts(actInfo, { contractNumber: '' }));
    onClose();
  };

  const onSubmitModal = () => {
    dispatcher(updateActDetailsAction('contractsToAdd', id, t));
    onCloseRest();
  };

  const onChange = event => {
    setSearchText(event.target.value);
  };

  const onSearch = () => {
    dispatcher(getAvaliableContracts(actInfo, { contractNumber: searchText }));
  };

  return (
    <Modal
      centered
      footer={null}
      closable={false}
      visible={isModalOpen}
      className={classNames(styles.container)}
      onOk={onSubmitModal}
      onCancel={onCloseRest}
      width={600}
    >
      <div className={classNames(styles.modal_content)}>
        <div className={classNames(styles.close)}>
          <div className={classNames(styles.closeIcon)} onClick={onCloseRest}>
            <img src={closeIcon} width={16} height={16} alt="close icon" />
          </div>
        </div>
        <div className="content">
          <span className={classNames(styles.title_btn)}>{t('act_details.add_contract_modal')}</span>
          <div className={classNames(styles.search_block)}>
            <Input
              className={classNames(styles.search_input)}
              value={searchText}
              placeholder={t('act_details.search_modal')}
              onChange={onChange}
            />
            <Button className={classNames(styles.search_btn)} onClick={onSearch}>
              {t('act_details.search_button_modal')}
            </Button>
          </div>
          <div className={classNames(styles.search_table)}>
            <ModalTable />
          </div>
          <div className={classNames(styles.actions_btn)}>
            <button onClick={onCloseRest}>{t('act_details.cancel_button_modal')}</button>
            <button onClick={onSubmitModal}>{t('act_details.add_button_modal')}</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
