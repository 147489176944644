import createInstance from './axios/axios';
import { IPartner } from '../screens/PartnerCreation/components/PartnerForm/types';
import {
  IActivitylist,
  IInsCompanies,
  IInternetSalesTab,
  IPartnerOptionsTab,
  IPartnerRegionsTab,
  IRegions,
  ISubDivision,
  ISubDivisionsList,
  ITypes,
  IUsersList,
} from '../store/partnerCreate/types';
import { AxiosResponse } from 'axios';
import { IAgent } from '../screens/PartnerCreation/components/Table/types';
import {
  ResultListDtoDepartmentView,
  PartnerRegionDto,
  PartnerProgramDto,
  InsuranceProgramDto,
  AgentDto,
} from 'types/dto/contracts-service';
import { OptionDto } from 'types/dto/configuration-service';

interface IUsersParams {
  partnerId: number;
  insuranceCompanyId: number;
  pageSize: number;
  signupDate: string;
  page: number;
  sorting: string;
}

interface IActivityParams {
  activityDate: string;
  page: number;
  page_size: number;
  partnerId: number;
  sorting: string;
}

const partnersAPI = createInstance('CONTRACTS');
const configApi = createInstance('CONFIGURATION');

export const getInsuranceCompanies = async (): Promise<AxiosResponse<IInsCompanies[]>> => {
  return partnersAPI.get(`/insurance-companies?distinct`, {
    params: {
      attributes: 'id,name',
    },
  });
};

export const getPartnersTypes = async (): Promise<AxiosResponse<ITypes>> => {
  return partnersAPI.get(`/partners/partner-types`);
};

export const createPartner: any = async (data: IPartner): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.post('/partners', data);
};

export const updatePartner: any = async (partnerId: number, data: IPartner): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.put(`/partners/${partnerId}`, data);
};

export const getPartnerData = async (partnerId): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.get(`/partners/${partnerId}`);
};

export const getPartnerOptionsTabData = async (partnerId): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.get(`/partners/${partnerId}/program-parameters`);
};

// Internet Sales
export const getInternetSalesData = async (partnerId: number): Promise<AxiosResponse<IInternetSalesTab[]>> => {
  return partnersAPI.get(`/partners/${partnerId}/department-programs`);
};

export const removeInternetSales = async (
  partnerId: number,
  departmentProgramId: number,
): Promise<AxiosResponse<IInternetSalesTab[]>> => {
  return partnersAPI.delete(`/partners/${partnerId}/department/${departmentProgramId}/external-id`);
};

export const getRegionsData = async (partnerId: number) => {
  return partnersAPI.get<PartnerRegionDto[]>(`/partners/${partnerId}/regions`);
};

export const getCompaniesInternetSales = async (partnerId: number) => {
  return partnersAPI.get<OptionDto[]>(`/insurance-programs/available/internet-sale-creation`, {
    params: {
      partnerId,
    },
  });
};

export const getSubdivisionInternetSales = async (params: {
  partnerId: number;
  insuranceProgramId: number;
}): Promise<AxiosResponse<IInsCompanies[]>> => {
  return partnersAPI.get(`/departments/available/internet-sale-creation`, {
    params,
  });
};

export const createInternetSalesToken = async (
  partnerId: number,
  data: { insuranceProgramId: number; departmentId: number },
): Promise<AxiosResponse<any[]>> => {
  const { insuranceProgramId, departmentId } = data;

  return partnersAPI.post(
    `/partners/${partnerId}/external-id?` +
      new URLSearchParams({
        insuranceProgramId: insuranceProgramId.toString(),
        departmentId: departmentId.toString(),
      }),
  );
};

export const createRegion = async (partnerId: number, data: IPartnerRegionsTab[]): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.post(`/partners/${partnerId}/regions`, data);
};

export const updateRegion = async (
  partnerId: number,
  data: IPartnerRegionsTab,
  regionId?: number,
): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.put(`/partners/${partnerId}/regions/${regionId}`, data);
};

export const removeRegion = async (regionId: number): Promise<AxiosResponse<IAgent>> => {
  return partnersAPI.delete(`/partners/regions/${regionId}`);
};

export const updatePartnerOptionsTabData: any = async (
  partnerId: number,
  data: IPartnerOptionsTab,
): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.put(`/partners/${partnerId}/program-parameters`, data);
};

export const getAgentsData = async (partnerId: number) => {
  return partnersAPI.get<AgentDto[]>(`/agents`, {
    params: {
      partnerId,
    },
  });
};

export const sendAgent = async (data: IAgent): Promise<AxiosResponse<IAgent>> => {
  return partnersAPI.post(`/agents`, data);
};

export const editAgent = async (agentId: number, data: IAgent): Promise<AxiosResponse<IAgent>> => {
  return partnersAPI.put(`/agents/${agentId}`, data);
};

export const removeAgent = async (agentId: number): Promise<AxiosResponse<IAgent>> => {
  return partnersAPI.delete(`/agents/${agentId}`);
};

// в модалку
export const getInsuranceProgramsModal = async (partnerId: number) => {
  return partnersAPI.get<InsuranceProgramDto[]>(
    `/insurance-programs/available/partner-program-creation?partnerId=${partnerId}`,
  );
};

// в таблицу
export const getInsuranceProgramsTable = async (partnerId: number): Promise<AxiosResponse<any>> => {
  return configApi.get(
    `/insurance-programs?partnerId=${partnerId}&attributes=id,name,partnerProgramId,partnerId,partnerProgramActivationDate,partnerProgramDeactivationDate&distinct`,
  );
};

export const getProgramsData = async (partnerId: number) => {
  return partnersAPI.get<PartnerProgramDto>(`/partner-programs/${partnerId}`);
};

export const editProgram = async (partnerId, data): Promise<AxiosResponse<any>> => {
  return partnersAPI.put(`/partner-programs/${partnerId}`, data);
};

export const createProgram = async (data): Promise<AxiosResponse<any>> => {
  return partnersAPI.post('/partner-programs', data);
};

export const createSubdivision = async (data): Promise<AxiosResponse<ISubDivision>> => {
  return partnersAPI.post('/departments/import', data);
};

export const editSubdivision = async (partnerId, data): Promise<AxiosResponse<ISubDivision>> => {
  return partnersAPI.put(`/departments/${partnerId}`, data);
};

export const getSudivisionsList = async (partnerId: number, params) => {
  return partnersAPI.get<ResultListDtoDepartmentView>(`/departments?partnerId=${partnerId}&count=true`, {
    params: {
      ...params,
    },
  });
};

export const getUsersList = async (params: IUsersParams): Promise<AxiosResponse<IUsersList>> => {
  return partnersAPI.get(`/users?count`, {
    params,
  });
};

export const getActivityList = async (params: IActivityParams): Promise<AxiosResponse<IActivitylist>> => {
  return partnersAPI.get('/users/activity-logs?count', {
    params: {
      ...params,
      attributes:
        'shortName,activityDate,login,partnerDepartmentName,partnerName,role,action,entityId,id,partnerId,partnerDepartmentId',
    },
  });
};
