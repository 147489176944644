import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
import { changeDocumentReceiptDate } from './helper';
import { IApplication, IInsuredEvent } from 'types/InsuredEvent';
import { Action, IPageStore } from '../../types/store';
import { IInsuredCase, InsuredCase, IPaymentSumInfo } from '../../types/InsuredCase';

interface IInsuranceState extends IPageStore<IInsuredCase> {}

const initialState: IInsuranceState = {
  data: new InsuredCase(),
  loading: false,
};

const setInsuredEvent = (state: IInsuranceState, { payload }: Action<IInsuredEvent>): IInsuranceState => {
  return {
    ...state,
    data: {
      ...state.data,
      ...payload,
      applications:
        payload.applications &&
        payload.applications.sort((a, b) => Number(new Date(a.applicationDate)) - Number(new Date(b.applicationDate))),
    },
  };
};

const clearInsuredEvent = (state: IInsuranceState): IInsuranceState => {
  return {
    ...state,
    data: initialState.data,
  };
};

const setPaymentSumInfo = (state: IInsuranceState, { payload }: Action<IPaymentSumInfo>): IInsuranceState => {
  return {
    ...state,
    data: {
      ...state.data,
      paymentSumInfo: payload,
    },
  };
};

const setPaymentSum = (state: IInsuranceState, { payload }: AnyAction): IInsuranceState => {
  return {
    ...state,
    data: {
      ...state.data,
      paymentSumInfo: {
        ...state.data.paymentSumInfo,
        paymentSum: payload,
      },
    },
  };
};

const addNewAdditionalDeduction = (state: IInsuranceState): IInsuranceState => {
  const paymentSumInfo: Partial<IPaymentSumInfo> = state.data.paymentSumInfo || {};
  const additionalDeductions =
    paymentSumInfo && paymentSumInfo.additionalDeductions ? paymentSumInfo.additionalDeductions : [];

  return {
    ...state,
    data: {
      ...state.data,
      paymentSumInfo: {
        ...paymentSumInfo,
        additionalDeductions: [
          ...additionalDeductions,
          {
            id: null,
            name: `Нове утримання ${additionalDeductions.length + 1}`,
            value: 0,
          },
        ],
      },
    },
  };
};

const changeAdditionalDeduction = (state: IInsuranceState, { payload }: AnyAction): IInsuranceState => {
  const newDeductionsArr = state.data.paymentSumInfo!.additionalDeductions;
  //@ts-ignore
  newDeductionsArr[payload.index] = {
    ...newDeductionsArr![payload.index],
    value: payload.data.value,
    name: payload.data.name,
  };

  return {
    ...state,
    data: {
      ...state.data,
      paymentSumInfo: {
        ...state.data.paymentSumInfo,
        additionalDeductions: newDeductionsArr,
      },
    },
  };
};

const setDocumentsInfo = (state: IInsuranceState, { payload }: AnyAction): IInsuranceState => {
  return {
    ...state,
    data: {
      ...state.data,
      documents: payload,
    },
  };
};

const setDocumentReceiptDate = (state: IInsuranceState, { payload }: AnyAction): IInsuranceState => {
  return {
    ...state,
    data: {
      ...state.data,
      documents: changeDocumentReceiptDate(state.data.documents, payload),
    },
  };
};

const addApplication = (state: IInsuranceState, { payload }: Action<IApplication>): IInsuranceState => {
  return {
    ...state,
    data: {
      ...state.data,
      applications: [...(state.data.applications || []), payload],
    },
  };
};

const setLoading = (state: IInsuranceState, { payload }: Action<boolean>): IInsuranceState => ({
  ...state,
  loading: payload,
});

const setApplications = (state: IInsuranceState, action: Action<IApplication[]>): IInsuranceState => ({
  ...state,
  data: {
    ...state.data,
    applications: action.payload.sort(
      (a, b) => Number(new Date(a.applicationDate)) - Number(new Date(b.applicationDate)),
    ),
  },
});

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setLoading,
    setInsuredEvent,
    clearInsuredEvent,
    setPaymentSumInfo,
    setPaymentSum,
    addNewAdditionalDeduction,
    changeAdditionalDeduction,
    setDocumentsInfo,
    setDocumentReceiptDate,
    addApplication,
    setApplications,
  },
});
