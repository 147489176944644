import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { insuranceProgramsListType } from 'types/insuranceProgramsList';
import { AnyAction } from 'redux';
import moment from 'moment';
import config from 'config';

//TODO: Remove mock data
const initialState: insuranceProgramsListType = {
  data: [],
  total: 0,
  pending: false,
  error: false,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'createdDate',
    direction: 'DESC',
  },
  filters: {
    id: undefined,
    dateFrom: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    insuranceRules: '',
    insuranceTypeName: '',
    insuranceCompanyId: '',
    insuranceProgramName: '',
  },
  privateFilterItems: {},
  companies: [],
};

const setTablePage = (state: insuranceProgramsListType, { payload, pending }: AnyAction): insuranceProgramsListType => {
  return {
    ...state,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
    pending,
  };
};

const setPagination = (state: insuranceProgramsListType, { payload }: AnyAction): insuranceProgramsListType => {
  return {
    ...state,
    pagination: payload,
  };
};

const setSorting = (state: insuranceProgramsListType, { payload }: any): insuranceProgramsListType => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const getPrivateFiltersValues = (state: insuranceProgramsListType, { payload }: any): insuranceProgramsListType => {
  const { filterField, filterParams }: { filterField: string; filterParams: [] } = payload;
  return { ...state, privateFilterItems: { ...state.privateFilterItems, [filterField]: filterParams } };
};

const getPrivateFiltersData = (state: insuranceProgramsListType, { payload }: any): insuranceProgramsListType => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    data: resultList,
    total: count,
  };
};

const setFilters = (state: insuranceProgramsListType, { payload }: AnyAction): insuranceProgramsListType => {
  return {
    ...state,
    filters: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setTablePage,
    setPagination,
    setSorting,
    getPrivateFiltersValues,
    getPrivateFiltersData,
    setFilters,
  },
});
