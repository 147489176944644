import { notification } from 'antd';
import { createTypes } from 'redux-compose-reducer';

import { getPartnersList, getPrivateFiltersTable, getTablePageData } from 'api/partners';
import { AppState } from 'store/reducer';

export const TYPES = createTypes('partners', [
  'getPartnersList',
  'setPending',
  'setPagination',
  'getFilteredPartners',
  'setSorting',
  'getPrivateFiltersData',
  'setTablePage',
]);

export const setPending = (pending: boolean) => ({ type: TYPES.setPending, pending });

export const getPartners = ({ ...params }, t): any => async dispatch => {
  dispatch(setPending(true));
  try {
    const partners = (await getPartnersList(params)).data;
    dispatch({ type: TYPES.getPartnersList, payload: { data: partners.resultList, count: partners.count } });
  } catch (error) {
    notification.error({
      message: t('popup.getting_partners'),
      description: t('popup.try_again_products'),
    });
  } finally {
    dispatch(setPending(false));
  }
};

export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const getFilteredPartners = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    partnersList: { pagination, sorting },
  }: AppState = getState();

  try {
    const res = await getPrivateFiltersTable({
      filters: {
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-conclusionDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
    });

    dispatch({ type: TYPES.getPrivateFiltersData, payload: res.data });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getTablePage = () => async (dispatch: any, getState: any) => {
  const {
    partnersList: { pagination, sorting, filters },
  }: AppState = getState();

  dispatch({ type: TYPES.setTablePage, pending: true });

  try {
    const res = await getTablePageData({ pagination, sorting, filters });

    dispatch({ type: TYPES.setTablePage, payload: res.data, pending: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setTablePage, pending: false });
  }
};

export const getFilteredPrivateData = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    partnersList: {
      pagination,
      sorting,
      filters: { identifier, name, shortName, code, type },
    },
  }: AppState = getState();
  try {
    const res = await getPrivateFiltersTable({
      filters: {
        ...filtersInfo,
        identifier,
        name,
        shortName,
        code,
        type,
        sorting:
          (!sorting.direction && '-conclusionDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination: {
        current: 1,
        perPage: pagination.perPage,
      },
    });

    dispatch({ type: TYPES.getPrivateFilteredData, payload: res.data });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setSorting = ({ field, direction }: { field: string; direction: string }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};
