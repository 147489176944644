import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Menu, Icon, ConfigProvider, Tooltip } from 'antd';
import classNames from 'classnames';
import history from 'routes/history';
import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { logout } from 'api/auth';

import arrowRightIcon from 'assets/img/layout/arrowRight.svg';
import closeIcon from 'assets/img/layout/blackClose.svg';

import styles from './SideMenu.module.less';
import styled from 'styled-components';

import {
  LogoutIcon,
  SearchIcon,
  RegistryIcon,
  ActsIcon,
  // Icon for Notification item
  // BellIcon,
  SettingsIcon,
  QuestionIcon,
  LogoIcon,
  MenuSettingsIcon,
  TerminationIcon,
  InsuranceIcon,
  PersonalIconSidebar,
  EditContractIcon,
  HomeMenuIcon,
  RegistryTechnologyIcon,
  ChatsIconSidebar,
  SublimitsIconSidebar,
} from 'components/Icons';
import { NavLink } from 'react-router-dom';
import { checkPermissions } from 'roles/userCan';
import { SvgIcon } from '@material-ui/core';
import { getProducts } from 'store/insuranceProducts/actions';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { SOTA_TOV, TECHNO, VF_RETAIL_TOV } from 'constants/partners';
import { Language } from 'types';

const { Sider } = Layout;

interface IProps {
  openModal: () => void;
}
interface IStyled {
  insuranceCompanyName: string;
}

export const getTelegramUrl = partnerName => {
  switch (partnerName) {
    case SOTA_TOV:
      return 'https://t.me/joinchat/Sh50INDflysyYTRi';
    case TECHNO:
      return 'https://t.me/joinchat/-2b4p9K0xqwxMjVi';
    default:
      return 'https://t.me/shidzahid';
  }
};

const StyledMenu = styled(Menu)<IStyled>`
  .ant-menu-inline-collapsed-tooltip {
    width: auto;
    max-width: 300px;
  }
`;

const SideMenu = ({ openModal }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { login, partnerDepartmentName, partnerName, insuranceCompanyName, telegramChatLink } = useSelector(
    ({ user: { login, partnerDepartmentName, partnerName, insuranceCompanyName, telegramChatLink } }: AppState) => ({
      login,
      partnerDepartmentName,
      partnerName,
      insuranceCompanyName,
      telegramChatLink,
    }),
  );

  const [isCollapsed, setCollapse] = useState<boolean>(false);
  const [defaultKey, setDefaultKey] = useState<string>('');
  const siderWidth = insuranceCompanyName === 'ООО «Таджикско-Китайское страхование»' ? 330 : 310;

  useEffect(() => {
    // TODO fix this request
    dispatch(getProducts(t));
    const pathArr = history.location.pathname.split('/');
    const rootPath = pathArr[1];
    const isCreateContract = pathArr[2] === 'contract' && pathArr[3] === 'create';

    if (defaultKey === rootPath && defaultKey !== 'createContract' && !isCreateContract) return;

    if (rootPath === 'dashboard') setCollapse(true);
    if (rootPath !== 'dashboard') {
      if (isCreateContract) {
        return setDefaultKey('createContract');
      }
      setDefaultKey(rootPath);
    } else {
      setDefaultKey('dashboard');
    }
  }, [history.location]);

  const onLogoutClick = useCallback(async () => {
    await logout();
    sessionStorage.removeItem('currentCurrency');
    dispatch({ type: 'RESET_STORE' });
  }, []);

  useEffect(() => {
    document.body.style.overflow = !isCollapsed ? 'hidden' : 'auto';
  }, [isCollapsed]);

  const getTelegramPermission = () => {
    if (partnerName) {
      return [VF_RETAIL_TOV, SOTA_TOV, TECHNO].includes(partnerName);
    }
    return true;
  };

  const setCurrentLanguage = () => {
    const currentLanguage = localStorage.getItem('language');
    i18n.changeLanguage(currentLanguage ? currentLanguage : Language.UA).catch(err => console.log(err));
  };

  return (
    <>
      <ConfigProvider
        getPopupContainer={node => {
          if (node) {
            return node.parentNode as HTMLElement;
          }
          return document.body;
        }}
      >
        <Sider
          collapsible
          collapsed={isCollapsed}
          trigger={null}
          collapsedWidth={65}
          width={siderWidth}
          className={styles.sider}
          onClick={setCurrentLanguage}
        >
          <div id="siderMenu" className={styles.menusHolder}>
            <div className={styles.menusContainer}>
              <div>
                <StyledMenu
                  mode="inline"
                  selectedKeys={[defaultKey]}
                  onClick={() => setCollapse(true)}
                  insuranceCompanyName={insuranceCompanyName}
                >
                  <Menu.Item className={classNames(styles.withCustomLabel, styles.header, 'logo-wrapper')}>
                    <Icon
                      component={() => (
                        <div className={styles.button}>
                          <LogoIcon />
                        </div>
                      )}
                    />
                    <div className={styles.menuLogoCustomLabel}>{insuranceCompanyName}</div>
                  </Menu.Item>
                  <div
                    className={classNames(styles.button, styles.collapseButton, {
                      [styles.buttonMenuActive]: !isCollapsed,
                    })}
                    onClick={() => setCollapse(!isCollapsed)}
                  >
                    {isCollapsed && <img className={styles.menuIcon} src={arrowRightIcon} alt="right icon" />}
                    {!isCollapsed && <img className={styles.menuIcon} src={closeIcon} alt="close icon" />}
                  </div>
                  <Menu.Item className={styles.mainSection} key="dashboard">
                    <NavLink to={ROUTES.DASHBOARD.ROOT} data-id="Dashboard-Button">
                      <Icon
                        component={() => (
                          <div className={styles.button}>
                            <HomeMenuIcon />
                          </div>
                        )}
                      />
                      <span className={styles.title}>{t('side_menu.home')}</span>
                    </NavLink>
                  </Menu.Item>

                  {checkPermissions('CONTRACT', 'CREATE', true) && (
                    <Menu.Item key="createContract" data-id="Create-Contract-Button">
                      <NavLink onClick={openModal} to={'#'} data-id="Dashboard-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <EditContractIcon />
                            </div>
                          )}
                        />
                        <span onClick={openModal}>
                          <span className={styles.title}> {t('side_menu.new_contract')}</span>
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}

                  {checkPermissions('SEARCH', 'READ', true) && (
                    <Menu.Item key="search">
                      <NavLink to={ROUTES.SEARCH.ROOT} data-id="Search-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <SearchIcon />
                            </div>
                          )}
                        />
                        <span className={styles.title}>{t('side_menu.search')}</span>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {checkPermissions('CONTRACTS_LIST', 'READ', true) && (
                    <Menu.Item key="contracts-list">
                      <NavLink to={ROUTES.CONTRACTS_LIST.ROOT} data-id="Contract-List-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <RegistryIcon />
                            </div>
                          )}
                        />
                        <span className={classNames(styles.title, styles.nobreak_labels)}>
                          {t('side_menu.insurance_contracts')}
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {checkPermissions('ACTS', 'READ') && (
                    <Menu.Item key="acts">
                      <NavLink to={ROUTES.ACTS_LIST.ROOT} data-id="Acts-List-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <ActsIcon />
                            </div>
                          )}
                        />
                        <span className={classNames(styles.title, styles.nobreak_labels)}>
                          {t('side_menu.acts_of_work_performed')}
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {checkPermissions('CHATS', 'MENU') && (
                    <Menu.Item key="consultations">
                      <NavLink to={ROUTES.CONSULTATIONS.ROOT} data-id="Consultations">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <ChatsIconSidebar />
                            </div>
                          )}
                        />
                        <span className={classNames(styles.title, styles.nobreak_labels)}>
                          {t('side_menu.services')}
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {checkPermissions('TERMINATIONS_LIST', 'READ') && (
                    <Menu.Item key="terminations-list">
                      <NavLink to={ROUTES.TERMINATIONS_LIST.ROOT} data-id="Terminations-List-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <TerminationIcon />
                            </div>
                          )}
                        />
                        <span className={classNames(styles.title, styles.nobreak_labels)}>
                          {t('side_menu.register_of_ruptures')}
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}

                  {checkPermissions('ADMIN_PANEL', 'READ') && (
                    <Menu.Item key="admin-panel">
                      <NavLink to={ROUTES.ADMIN_PANEL.LIST.ROOT} data-id="Admin-Panel-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <PersonalIconSidebar />
                            </div>
                          )}
                        />
                        <span className={classNames(styles.title, styles.nobreak_labels)}>
                          {t('side_menu.register_system_users')}
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {checkPermissions('INSURANCE_LIST', 'READ') && (
                    <Menu.Item key="insured-events">
                      <NavLink to={ROUTES.INSURED_EVENTS.LIST.ROOT} data-id="Insured-Events-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <InsuranceIcon />
                            </div>
                          )}
                        />
                        <span className={classNames(styles.title, styles.nobreak_labels)}>
                          {t('side_menu.insurance_cases')}
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}
                  {checkPermissions('REGISTER_DEVICES', 'READ', true) && (
                    <Menu.Item key="registration-devices">
                      <NavLink to={ROUTES.REGISTRATION_DEVICES.ROOT} data-id="Registration-Devices-Button">
                        <Icon
                          component={() => (
                            <div className={styles.button}>
                              <RegistryTechnologyIcon />
                            </div>
                          )}
                        />
                        <span className={classNames(styles.title, styles.nobreak_labels)}>
                          {t('side_menu.register_equipment_sales')}
                        </span>
                      </NavLink>
                    </Menu.Item>
                  )}
                </StyledMenu>
              </div>
              <Menu selectable={false} selectedKeys={[defaultKey]} onClick={() => setCollapse(true)}>
                {/* <Menu.Item>
              <Icon
                component={() => (
                  <div className={styles.button}>
                    <BellIcon />
                  </div>
                )}
              />
              <span className={styles.title}>Повідомлення</span>
            </Menu.Item> */}
                <Menu.Item key={'settings'}>
                  <NavLink to={ROUTES.SETTINGS.ROOT} data-id="Settings-Button">
                    <Icon
                      component={() => (
                        <div className={styles.button}>
                          <SettingsIcon />
                        </div>
                      )}
                    />
                    <span className={styles.title}>{t('side_menu.settings')}</span>
                  </NavLink>
                </Menu.Item>
                {checkPermissions('HELP', 'READ') && (
                  <Menu.Item className={classNames(styles.withCustomLabel, 'custom-label-wrap', styles.defaultCursor)}>
                    <Icon
                      component={() => (
                        <div className={styles.button}>
                          <MenuSettingsIcon />
                        </div>
                      )}
                    />
                    <div className={styles.menuCustomLabel}>
                      <div>{t('side_menu.INRISK_system')}</div>
                      <span className={styles.helperText}>&#169; CHI Software</span>
                    </div>
                  </Menu.Item>
                )}
                {getTelegramPermission() && checkPermissions('TELEGRAM', 'READ') && (
                  <Menu.Item
                    onClick={() => {
                      window.open(!telegramChatLink ? getTelegramUrl(partnerName) : telegramChatLink, '_blank');
                    }}
                  >
                    <Icon
                      component={() => (
                        <div className={styles.button}>
                          <SvgIcon fontSize="small">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
                            </svg>
                          </SvgIcon>
                        </div>
                      )}
                    />
                    <span className={styles.title}>{t('side_menu.online_chat')}</span>
                  </Menu.Item>
                )}
                {checkPermissions('WIKI', 'READ') && (
                  <Menu.Item
                    onClick={() =>
                      window.open(
                        'http://www.mediawiki.inrisk.com.ua/wiki/%D0%A1%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%B0_%D0%BA%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D1%96%D1%8F_%22%D0%A1%D0%A5%D0%86%D0%94-%D0%97%D0%90%D0%A5%D0%86%D0%94%22',
                        '_blank',
                      )
                    }
                  >
                    <Icon
                      component={() => (
                        <div className={styles.button}>
                          <QuestionIcon />
                        </div>
                      )}
                    />
                    <span className={styles.title}>{t('side_menu.wiki_guide')}</span>
                  </Menu.Item>
                )}
                <div className={classNames(styles.customMenuItem, 'ant-menu-item')}>
                  <Tooltip
                    placement="right"
                    overlayClassName={classNames(styles['ant-popover-custom'], { [styles.hideTooltip]: !isCollapsed })}
                    getPopupContainer={() => {
                      const parent = document.getElementById('siderMenu');
                      return parent ? parent : document.body;
                    }}
                    align={{ offset: [15, 0] }}
                    trigger={'hover'}
                    title={
                      <div className={classNames(styles.menuCustomLabel, 'custom-label-wrap')}>
                        <div className={styles.userLabelCustom}>
                          <p>{login}</p>
                          {partnerDepartmentName && <p>{partnerDepartmentName}</p>}
                        </div>
                      </div>
                    }
                  >
                    <Icon component={() => <div className={classNames(styles.button, styles.avatar)}>@</div>} />
                  </Tooltip>
                  <div className={classNames(styles.withCustomLabel, 'custom-label-wrap')}>
                    <div className={classNames(styles.menuCustomLabel, styles.logOutLabel)}>
                      <div className={classNames(styles.userInfo)}>
                        <p>{login}</p>
                        <p>{partnerDepartmentName}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <Menu.Item onClick={onLogoutClick}>
                  <Icon
                    component={() => (
                      <div className={styles.button}>
                        <LogoutIcon className="logout-icon" />
                      </div>
                    )}
                  />
                  <span className={styles.title}>{t('side_menu.log_out')}</span>
                </Menu.Item>
              </Menu>
            </div>
          </div>
        </Sider>
        <div
          onClick={() => setCollapse(!isCollapsed)}
          className={classNames(styles.mask, { [styles.active]: !isCollapsed })}
        />
      </ConfigProvider>
    </>
  );
};

export default SideMenu;
