import React from 'react';
import { useDispatch } from 'react-redux';

import cs from 'classnames';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { setActionOnBrand } from 'store/brands/actions';
import { ITools } from 'types/brands';

import { EditBoxIcon, CrossIcon } from 'components/Icons';

import style from './TableTools.module.less';
import styles from 'components/tables/AdminTable/TableTools/TableTools.module.less';

const TableTools = ({ record, handleVisibleChange, open }: ITools) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openToConfiguration = (actionOnBrand: string) => {
    handleVisibleChange(false);
    dispatch(setActionOnBrand(actionOnBrand));
    open();
  };

  return (
    <div className={styles.container}>
      <Button className={cs(styles.button, style.button)} type="link" onClick={() => openToConfiguration('editBrand')}>
        <div className={style.wrapIcon}>
          <EditBoxIcon />
        </div>
        <span>{t('brands_page.edit')}</span>
      </Button>

      <Button
        className={cs(styles.button, style.button)}
        type="link"
        onClick={() => openToConfiguration('deleteBrand')}
      >
        <div className={style.wrapIcon}>
          <CrossIcon />
        </div>
        <span>{t('brands_page.remove')}</span>
      </Button>
    </div>
  );
};

export default TableTools;
