import { createTypes } from 'redux-compose-reducer';
import { ThunkAction } from 'redux-thunk';
import { StoreType } from 'types/store';
import { Action } from 'redux';
import * as expressApi from '../../api/expressPayments';
import { notification } from 'antd';
import { AppState } from '../reducer';
import { expressAdapter, fondyErrors } from 'constants/validationErrors';

export const TYPES = createTypes('expressPayment', [
  'expressPaymentTemplate',
  'getPaymentsSystems',
  'expressPaymentState',
  'clearExpressPayment',
  'expressPaymentFields',
  'setExpressPaymentFields',
  'setAccountType',
]);

type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const fetchExpressPaymentTemplate = (claimId: number | string, t): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.expressPaymentState, payload: { loading: true } });
  try {
    const expressPaymentData = (await expressApi.getExpressPaymentTemplate({ claimId })).data;

    if (expressPaymentData) {
      dispatch({ type: TYPES.expressPaymentTemplate, payload: { expressPaymentData, claimId } });
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      error?.response?.data?.errors.forEach(err => {
        if (err.code === 'EXPRESS_PAYMENT_IMPOSSIBLE') {
          return notification.error({
            message: t('popup.payment_not_possible'),
            description: t('popup.exceeds_terms_agreement'),
          });
        }
      });
    }
  } finally {
    dispatch({ type: TYPES.expressPaymentState, payload: { loading: false } });
  }
};

export const getPaymentSystems = (claimId: number, t): ThunkResult => async dispatch => {
  try {
    const { data } = await expressApi.getPaymentsSystems({ claimId });

    dispatch({ type: TYPES.getPaymentsSystems, payload: data });
  } catch (error) {
    notification.error({ message: t('popup.something_went_wrong') });
  }
};

export const setExpressPaymentFields = (formValues: any): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.expressPaymentFields, payload: { ...formValues } });
};

export const setNewFieldsValues = (formValues: any): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.setExpressPaymentFields, payload: { ...formValues } });
};

export const setAccountTypeNumber = (accountType: any): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.setAccountType, payload: { accountType } });
};

export const sendExpressTemplate = (cb: any, form: any, t): ThunkResult => async (dispatch, getState) => {
  dispatch({ type: TYPES.expressPaymentState, payload: { loading: true } });
  try {
    const {
      expressPayment: { expressPaymentData, claimId },
    }: AppState = getState();

    const convertedData = {
      expressPayment: expressPaymentData,
    };

    await expressApi.sendExpressPaymentTemplate({ expressPaymentData: convertedData, claimId: claimId! });
    dispatch({ type: TYPES.expressPaymentState, payload: { loading: false } });
    // disableBtn(true);
    cb();
    return notification.success({
      message: t('popup.sent'),
      description: t('popup.message_sent_manager'),
    });
  } catch (error) {
    // TODO handle all error types, codes
    if (error.response && error.response.status === 400) {
      return (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (el.code === 'CARD_ACCOUNT_TYPE_REQUIRED') {
            const value = form.getFieldValue('accountNumber');
            return form.setFields({
              accountNumber: {
                value: value,
                errors: [new Error(t('popup.enter_card_account'))],
              },
            });
          } else if (el.code === 'VERIFICATION_TOKEN_MISSING_OR_EXPIRED' || el.code === 'INVALID_VERIFICATION_TOKEN') {
            const value = form.getFieldValue('smsCode');
            return form.setFields({
              smsCode: {
                value: value,
                errors: [new Error(t('popup.your_code_invalid'))],
              },
            });
          } else if (el.code === 'PAYMENT_DECLINED_BY_PAYMENT_SYSTEM') {
            return notification.error({
              message: t('popup.please_check_data'),
            });
          }
          if (expressAdapter(t)[el.field]) {
            const name = `${expressAdapter(t)[el.field]['name']}`;
            const errorw = expressAdapter(t)[el.field][el.code];
            const value = form.getFieldValue(name);

            return form.setFields({
              [name]: {
                value: value,
                errors: [new Error(errorw)],
              },
            });
          }
        })
      );
    }

    if (error.response && error.response.status === 500) {
      return (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (fondyErrors(t)[el.code]) {
            return notification.error({
              message: fondyErrors(t)[el.code],
            });
          }
        })
      );
    }

    notification.error({
      message: t('popup.confirmation_error'),
    });
  } finally {
    dispatch({ type: TYPES.expressPaymentState, payload: { loading: false } });
  }
};

export const clearExpressPaymentData = () => ({ type: TYPES.clearExpressPayment });
