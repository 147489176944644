import styled from 'styled-components';

type Custom = {
  borderT?: boolean;
  borderB?: boolean;
  size?: number;
  width?: string;
  error?: boolean;
  lgWidth?: number;
  mb?: number;
  weight?: number;
  bottomM?: number;
  light?: boolean;
  sm?: boolean;
  buttons?: boolean;
  lgHeight?: number;
};

export const UserModule = styled.div`
  .ant-form-item {
    margin: 0;
  }

  .ant-form-explain,
  .user-explain {
    position: absolute;
    font-size: 11px;
  }

  // fix input styles
  .user-explain {
    bottom: -15px !important;
  }
`;

export const BorderedFilter = styled.div<Custom>`
  border-top: ${props => (props.borderT ? '1px solid #f0f1f5' : 'none')};
  border-bottom: ${props => (props.borderB ? '1px solid #f0f1f5' : 'none')};
  display: flex;
  min-width: 900px;
  align-items: center;
  padding-left: 10px;
  justify-content: space-between;

  background: #fff;
  border-radius: 14px;

  & > button {
    align-self: stretch;
  }
`;

export const Scrollable = styled.div`
  overflow: auto;
`;

export const FilterWithPadding = styled(BorderedFilter)`
  padding: 25px 16px;
`;

export const UserCardGrid = styled.div<Custom>`
  width: ${props => `${props.lgWidth}%`};

  @media screen and (max-width: 959px) {
    width: 100%;
    margin-bottom: ${props => (props.mb ? `${props.mb}px` : '0')};
  }

  // fix for broken phone number input styles

  .fix-phonenumber {
    input {
      transform: translate(17px, 20px);
    }

    .phone-prefix {
      top: 21px;
    }
  }
`;

export const Cards = styled.div<Custom>`
  padding: 20px 16px 16px;
  background: #fff;
  margin-bottom: 16px;
  border-radius: 14px;
  width: 100%;
  min-height: ${props => `${props.lgHeight}px`};
`;

export const FitContent = styled.div`
  flex-grow: 1;
  padding-right: 16px;
`;

export const Text = styled.p<Custom>`
  font-family: 'SF UI Display', Helvetica, Arial, sans-serif;
  font-size: ${props => (props.size ? `${props.size}px` : '14px')};
  color: #363636;
  white-space: pre-wrap;
  margin: ${props => (props.bottomM ? `0 5px ${props.bottomM}px 0` : '0 5px 0 0')};
  font-weight: ${props => (props.weight ? `${props.weight}` : '400')};
`;

export const SelectHolder = styled.div<Custom>`
  width: ${props => (props.sm ? 'auto' : 'auto')};
  min-width: ${props => (props.sm ? '100%' : '100%')};
  max-width: ${props => (props.light ? '280px' : 'auto')};

  background: ${props => (props.light ? '#fff' : '#eeeff4')};
  border-radius: 14px;
  position: relative;
  height: 56px;

  .form-select-holder {
    background: ${props => (props.light ? '#fff' : '#eeeff4')};
  }

  @media screen and (max-width: 1040px) {
    min-width: ${props => (props.sm ? '100%' : '280px')};
  }

  @media screen and (max-width: 860px) {
    min-width: ${props => (props.sm ? '100%' : '100%')};
  }
`;

export const ActionButtonHolder = styled.div<Custom>`
  & > button {
    width: 100%;
    padding: 20px 14px;
  }
`;

export const CustomizeSwitch = styled.div`
  .ant-switch {
    height: 25px;
    background: #eeeff4;
  }

  .ant-switch.ant-switch-checked {
    background: #3a9a3b;
  }

  .ant-switch-loading-icon,
  .ant-switch::after {
    height: 20px;
    width: 20px;
    top: 2px;
  }
`;

export const HeaderGrid = styled.div<Custom>`
  width: ${props => `${props.lgWidth}%`};
  display: flex;
  align-items: flex-end;
  padding: ${props => `${props.buttons ? '16px' : '0'}`};
  margin: ${props => `${props.buttons ? '0 -16px' : '0'}`};

  @media screen and (max-width: 1040px) {
    width: ${props => `${props.buttons ? '100%' : '100%'}`};
    align-self: ${props => `${props.buttons ? 'flex-end' : 'space-between'}`};
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media screen and (max-width: 1040px) {
    flex-direction: column-reverse;
  }
`;
