import React from 'react';
import { Grid } from '@material-ui/core';
import { Text } from 'screens/UserCard/styled';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import { ROUTES } from 'constants/routes';
import CustomBtn from 'components/CustomBtn';
import styles from './style.module.less';
import { useTranslation } from 'react-i18next';

interface IPageHeader {
  button: {
    variant?: string;
    size?: string;
    color?: string;
  };
  text: string;
  additionalBreadcrumbs?: any[];
  onExport: () => void;
  onImport: () => void;
}

const PageHeader: React.FC<IPageHeader> = ({ text, onExport, onImport, additionalBreadcrumbs = [] }) => {
  const { t } = useTranslation();
  return (
    <>
      <PageBreadcrumbs
        data={[
          {
            label: t('insurance_contracts.settings'),
            href: ROUTES.SETTINGS.ROOT,
          },
          ...additionalBreadcrumbs,
        ]}
      />
      <Grid container item justify="space-between" alignItems="center">
        <Text size={24} weight={600}>
          {text}
        </Text>
        <div>
          <CustomBtn theme="secondary" className={styles.Button} onClick={onExport}>
            {t('insurance_contracts.export_button')}
          </CustomBtn>
          <CustomBtn onClick={onImport}>{t('insurance_contracts.import_button')}</CustomBtn>
        </div>
      </Grid>
    </>
  );
};

export default PageHeader;
