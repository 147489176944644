import React from 'react';
import { css } from '@emotion/css';
import numeral from 'numeral';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { theme } from 'styles';
import CardContainer from 'components/next/CardContainer/CardContainer';
import config from 'config/config';

const cssItems = css`
  display: flex;
`;
const cssItem = css`
  flex-grow: 1;
  margin: 0 0 0 33px;
  position: relative;

  &:first-child {
    margin: 0;

    &:before {
      display: none;
    }
  }

  &:before {
    display: inline-block;
    content: '';
    height: 100%;
    top: 0%;
    width: 1px;
    position: absolute;
    left: -17px;
    background-color: ${theme.color.blueGray.main};
  }
`;
const cssContainer = css`
  display: flex;
  flex-wrap: wrap;
`;
const cssTotal = css`
  margin-right: 14px;
`;
const cssPaymentAmount = css`
  white-space: nowrap;
`;
const cssLabel = css`
  color: ${theme.color.blueGray.main};
`;

export interface Item {
  label: string;
  total?: number;
  paymentAmount: number;
}

interface ItemProps extends Item {
  currency: string;
}

const Item = ({ label, total, paymentAmount, currency }: ItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={cssItem}>
      <div className={cssLabel}>{label}</div>
      <div className={cssContainer}>
        {total ? (
          <Typography.Text strong className={cssTotal}>
            {total} {t('dashboard_extend.contract')}
          </Typography.Text>
        ) : null}
        <Typography.Text strong className={cssPaymentAmount}>
          {numeral(paymentAmount).format(config.format.financial)} {currency}
        </Typography.Text>
      </div>
    </div>
  );
};

interface Props {
  items: Item[];
  currency: string;
}

const Info = ({ items, currency }: Props) => {
  return (
    <CardContainer className={cssItems}>
      {items.map(item => {
        return <Item key={item.label} currency={currency} {...item} />;
      })}
    </CardContainer>
  );
};

export default Info;
