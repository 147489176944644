import React, { useEffect, useState } from 'react';
import { Icon, notification } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.less';
import { useAsync } from '../../hooks/useAsync';
import {
  getExternalContractData,
  getFondyData,
  getOfferFile,
  getPdfReport,
  sendExternalSMS,
  signExternalContract,
} from '../../api/auth';
import { CONTRACT_TYPE } from '../DetailsCard/constants/Datails.constants';
import { clientSignMessage } from '../../constants/contractData';
import Button from 'components/Button';
import FormInputText from '../FormInputText';
import { CollapsedContract } from './CollapsedContract';
import ResendSmsCode from '../auth/ManagerSignUp/widgets/ResendSmsCode';
import InsurerInfo from 'components/InsurerInfo/InsurerInfo';

export const ClientSign = () => {
  const history = useHistory();
  const { doFetch: fetchEternalContract, value: contract } = useAsync(getExternalContractData);
  const { doFetch: sendSMS } = useAsync(sendExternalSMS);
  const { doFetch: doSign, status: signStatus } = useAsync(signExternalContract);
  const { doFetch: doFondy, value: fondyValue, status: fondyStatus } = useAsync(getFondyData);
  const { doFetch: downloadPdf } = useAsync(getPdfReport);
  const { doFetch: getOffer, value } = useAsync(getOfferFile);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [russianCitizen, setRussianCitizen] = useState(true);
  const [code, setCode] = useState();
  const externalId = history.location.pathname.split('/')[3];
  const { t } = useTranslation();

  useEffect(() => {
    if (externalId) {
      fetchEternalContract(externalId);
      sendSMS(externalId);
    }
  }, []);

  useEffect(() => {
    if (contract?.data?.status === 'ACTIVE') {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [contract?.status]);

  useEffect(() => {
    if (signStatus === 'error') {
      notification.error({
        message: t('clientSign.your_code_invalid'),
      });
    }
  }, [signStatus]);

  useEffect(() => {
    if (fondyValue?.data?.checkoutUrl?.paymentId) {
      const {
        checkoutUrl: { responseStatus, checkoutUrl },
      } = fondyValue.data;

      if (responseStatus === 'success') {
        setTimeout(() => {
          window.open(checkoutUrl, '_blank') || window.location.assign(checkoutUrl ?? '');
        }, 1200);
      } else {
        notification.error({
          message: t('validation.something_went_wrong'),
        });
      }
    }
  }, [fondyValue?.data?.checkoutUrl?.paymentId]);

  const changeCode = ({ target: { value } }) => {
    setCode(value);
  };

  const signContract = () => {
    const res = doSign(externalId, code, !russianCitizen);
    res.finally(() => {
      fetchEternalContract(externalId);
    });
  };

  const getFondy = () => {
    try {
      doFondy(externalId);
    } catch (e) {
      console.log('e', e);
    }
  };

  const getProgramType = () => {
    switch (contract?.data?.insuranceProductCode) {
      case CONTRACT_TYPE.MOBI:
        return 'MOBI ZAHIST';
      default:
        return '';
    }
  };

  const handleCollapse = () => {
    setCollapsed(prevState => !prevState);
  };

  const downLoadPdfFile = () => {
    downloadPdf(externalId, undefined, t);
  };

  const downLoadOffer = () => {
    const data = getOffer(externalId);
    data
      .then(item => {
        if (value) {
          const link = document.createElement('a');
          // @ts-ignore
          link.href = value.data;
          link.target = '_blank';
          link.click();
        }
      })
      .catch(er => {
        console.log('er: ', er);
      });
  };

  const contractActive = moment().isBefore(contract?.data?.startDate);

  const getStatus = () => {
    if (contract?.data?.status === 'ACTIVE') {
      return contractActive
        ? `${t('clientSign.signed')} ${moment(contract?.data?.startDate).format('DD.MM.YYYY')}`
        : t('clientSign.acting');
    }
    return null;
  };

  const getActionsValidation = () => {
    if (contract?.data?.installmentPayment) {
      return !contract?.data?.clientSigned;
    }
    return true;
  };

  return (
    <div className={styles.Client__Container}>
      <div className={styles.Client__Form}>
        <div className={styles.Client__Header}>
          <div className={styles.Client__Status}>{getStatus()}</div>
          <div className={classNames(styles.Client__Contract, { [styles.Client__ContractActive]: collapsed })}>
            <div className={styles.Client__ContractTitle}>
              <span>
                {t('clientSign.electronic_contract')} "{getProgramType()}" від{' '}
                {moment(contract?.conclusionDate).format('DD.MM.YYYY')}
              </span>
            </div>
            <div className={styles.Client__ExpandableTitle} onClick={handleCollapse}>
              {collapsed && (
                <div className={styles.Client__CollapseContainer}>
                  <CollapsedContract contract={contract} />
                </div>
              )}
              <div>
                <span>{t('clientSign.read_more')}</span>
                <Icon
                  type="down"
                  className={classNames(styles.Client__Arrow, { [styles.Client__ArrowActive]: collapsed })}
                />
              </div>
            </div>
          </div>
        </div>
        {contract && contract.data && contract.data.status && contract.data.status !== 'ACTIVE' ? (
          <>
            <div className={styles.Client__Body}>
              <div className={styles.Client__MessageTitle}>
                <span>{t('clientSign.sent_code_phone_number')}</span>
              </div>
              <div className={styles.Client__Message}>
                <span>{clientSignMessage}</span>
              </div>
              <div className={styles.Client__Links}>
                <span onClick={downLoadPdfFile}>{t('clientSign.draft_contract')}</span>
                <span onClick={downLoadOffer}>{t('clientSign.conditions_contract')}</span>
              </div>
            </div>
            <div className={styles.Client__insuredInfo}>
              <InsurerInfo setRussianCitizen={setRussianCitizen} />
            </div>
            <div className={styles.Client__Actions}>
              {getActionsValidation() ? (
                <>
                  <FormInputText title="Код с SMS" onChange={changeCode} value={code} />
                  <Button size="large" className={styles.Client__ActionButton} color={'primary'} onClick={signContract}>
                    {t('clientSign.sign_button')}
                  </Button>
                </>
              ) : (
                <Button size="large" className={styles.Client__ActionButton} color={'primary'} onClick={getFondy}>
                  {t('clientSign.pay_button')}
                </Button>
              )}
            </div>
            {getActionsValidation() && (
              <div className={styles.Client__Resend}>
                <ResendSmsCode contractId={externalId} sendAction={sendSMS} size={20} />
              </div>
            )}
          </>
        ) : (
          <div className={styles.Client__Links}>
            <span onClick={downLoadOffer}>{t('clientSign.general_terms_contract')}</span>
            <span onClick={downLoadPdfFile}>{t('clientSign.individual_terms_contract')}</span>
          </div>
        )}
      </div>
    </div>
  );
};
