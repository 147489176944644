import { createTypes } from 'redux-compose-reducer';
import { AppState } from 'store/reducer';
import * as actsAPI from 'api/acts';
import { IContract, IFilters } from 'types/actsList';
import { notification } from 'antd';
import { getTerminationInsuranceProgram, getInsuranceProgram } from 'api/statistics';
import { getAgentContracts } from 'api/contracts';
import { formatContracts } from 'components/filters/ActsFilters/helper';
import {
  AllActions,
  TContractId,
  ThunkResult,
  IPending,
  TContracts,
  TSetCompaniesProgram,
  TSetPagination,
  TSetSorting,
} from './actionTypes';
import onDownload from '../../callbacks/onDownload';
import { downloadActsAsXLS } from 'api/acts';
import { AgentContractDto } from 'types/dto/contracts-service';

const defaultSorting = {
  field: 'actDate',
  direction: 'DESC',
};

export const TYPES = createTypes('actsList', [
  'setFilters',
  'setCurrentContract',
  'setTablePage',
  'setPagination',
  'setSorting',
  'setActsCompanies',
  'setActContracts',
  'setContractId',
  'setActsFiltersInsuranceProgram',
]);

// action creators

export const setFilters = (filters: IFilters): AllActions<IFilters> => ({
  type: TYPES.setFilters,
  payload: { ...filters },
});
export const setCurrentContract = (data: AgentContractDto): AllActions<AgentContractDto> => ({
  type: TYPES.setCurrentContract,
  payload: data,
});
export const setContractId = (id: number): AllActions<TContractId> => ({ type: TYPES.setContractId, payload: { id } });
export const setPagination = (current: number, perPage: number): AllActions<TSetPagination> => ({
  type: TYPES.setPagination,
  payload: { current, perPage },
});
export const setSorting = ({ field, direction }: TSetSorting): AllActions<TSetSorting> => ({
  type: TYPES.setSorting,
  payload: { field, direction },
});
export const setPending = (pending: boolean): IPending => ({ type: TYPES.setTablePage, pending });
export const setCompanyProgram = (payload: TSetCompaniesProgram): AllActions<TSetCompaniesProgram> => ({
  type: TYPES.setActsCompanies,
  payload,
});
export const setActsContracts = (payload: {
  contracts: AgentContractDto[];
}): AllActions<{ contracts: AgentContractDto[] }> => ({
  type: TYPES.setActContracts,
  payload,
});
// thunks

export const getTablePage = (): ThunkResult => async (dispatch, getState) => {
  dispatch(setPending(true));

  try {
    const {
      actsList: { pagination, sorting, filters, currentContract },
    } = getState();

    const payload = (
      await actsAPI.getTablePage({
        pagination,
        sorting: sorting.field ? sorting : defaultSorting,
        filters,
        currentContract,
      })
    ).data;

    dispatch({ type: TYPES.setTablePage, payload, pending: false });
    dispatch(setPagination(pagination.current, pagination.perPage));
  } catch (error) {
    dispatch(setPending(false));
  }
};
export const actPdfDownload = (id: number) => onDownload(() => actsAPI.downloadActPdf(id));
export const actExcelDownload = (id: number, dateFrom: string, dateTo: string, t) =>
  onDownload(() => actsAPI.downloadActExcel(id, dateFrom, dateTo), {}, t);

export const actAgentExcelDownload = (
  id: number | string,
  agentContractId: number | string,
  insuranceProgramId: number | string,
  dateFrom: string,
  dateTo: string,
  t: any,
) => onDownload(() => actsAPI.downloadActAgentExcel(id, agentContractId, insuranceProgramId, dateFrom, dateTo), {}, t);

export const setCompanies = (t): ThunkResult => async dispatch => {
  try {
    const payload = (await getTerminationInsuranceProgram()).data || [];
    const programId = payload?.[0].id;
    const program = (await getInsuranceProgram(programId)).data.resultList ?? [];

    dispatch(setCompanyProgram({ companies: payload, insuranceProgram: program }));

    if (payload && payload[0] && payload[0].id) dispatch(setContracts(payload[0].id, t));
  } catch (error) {
    notification.error({
      message: t('popup.error_update_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

export const setActsFiltersInsuranceProgram = (t, id): ThunkResult => async dispatch => {
  try {
    dispatch(setPending(true));
    const program = (await getInsuranceProgram(id)).data.resultList || [];
    dispatch(setPending(false));
    dispatch({ type: TYPES.setActsFiltersInsuranceProgram, payload: { insuranceProgram: program } });
  } catch (error) {
    dispatch(setPending(false));

    notification.error({
      message: t('popup.updating_program'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setContracts = (id: string, t): ThunkResult => async dispatch => {
  try {
    const payload = (await getAgentContracts(id)).data ?? [];
    dispatch(setActsContracts({ contracts: formatContracts(payload) }));
  } catch (error) {
    notification.error({
      message: t('popup.agent_update'),
      description: t('popup.please_try_again_later'),
    });
  }
};

export const actsXSLXDownload = t => async (dispatch, getState) => {
  const {
    actsList: { sorting, filters },
  }: AppState = getState();
  const sortItems = sorting.field ? sorting : defaultSorting;
  const {
    insuranceProgramId: { id: insuranceProgramId },
    insuranceCompanyId: { id: partnerId },
    agentContractId,
  } = filters;

  return onDownload(
    () =>
      downloadActsAsXLS({
        actDate: `${filters.dateFrom}..${filters.dateTo}`,
        sorting: sortItems.direction === 'DESC' ? `-${sortItems.field},-id` : `${sortItems.field},id`,
        agentContractId,
        insuranceProgramId,
        partnerId,
      }),
    {},
    t,
  );
};
