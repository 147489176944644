import React, { useCallback, useState } from 'react';
import { Menu } from './styled';
import MenuItem from 'components/uikit/MenuItem';
import Excel from 'components/Button/icons/Excel';
import { actExcelDownload, actAgentExcelDownload } from 'store/actsList/actions';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

const options = (t) => {
  return [
    {
      name: t("act_details.avr"),
      action: 'avr',
    },
    {
      name: t("act_details.register"),
      action: 'register',
    },
  ];
};

const DownloadButton = ({ id, actInfo }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);
  const { t } = useTranslation();

  const handleItemClick = useCallback(
    action => {
      switch (action) {
        case 'avr':
          id && actExcelDownload(Number(id), actInfo?.dateFrom, actInfo?.dateTo, t);
          break;
        case 'register':
        default:
          id &&
            actAgentExcelDownload(
              Number(id),
              actInfo?.agentContractId,
              actInfo?.insuranceProgramId,
              actInfo?.dateFrom,
              actInfo?.dateTo,
              t
            );
          break;
      }
      setAnchorEl(undefined);
    },
    [id, actInfo?.dateFrom, actInfo?.dateTo, actInfo?.insuranceProgramId, actInfo?.agentContractId],
  );

  return (
    <>
      <Button href={'#'} startIcon={<Excel />} onClick={(e: React.MouseEvent<HTMLDivElement>) => setAnchorEl(e.currentTarget)}>
        {t('act_details.export_excel_button')}
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 0,
          vertical: -54,
        }}
      >
        {options(t).map(item => (
          <MenuItem key={item.name} onClick={() => handleItemClick(item.action)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DownloadButton;
