import { IInsuranceConfiguration } from 'types/insuranceConfiguration';
import { fieldExeptions } from 'screens/InsuranceCompanies/InsuranceConfiguration/utils/utils';

export const initialState: IInsuranceConfiguration = {
  data: {
    id: null,
    abbreviation: '',
    enterpriseCode: '',
    name: '',
    phoneNumber: '',
    contactPersonFullName: '',
    createdDate: null,
    startDate: null,
    endDate: null,
    readOnlyFields: [...fieldExeptions],
    shortName: '',
    postAddress: '',
    ibanAccountNumber: '',
    bankName: '',
    directorPosition: '',
    directorFullName: '',
    customerSupportPhoneNumber: '',
    partnerSupportPhoneNumber: '',
    telegramChannel: '',
    contactPerson: null,
    insuranceManager: null,
    claimManager: null,
    logo: '',
  },
  loading: false,
  resolutions: undefined,
  currency: [],
};
