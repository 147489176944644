import contentDisposition from 'content-disposition';

export interface IDownloadOptions {
  name?: string;
}

const filename = (contentDispositionHeader: string): string =>
  decodeURIComponent(contentDisposition.parse(contentDispositionHeader).parameters.filename);

export const download = (data: Blob, headers: any, options: IDownloadOptions = {}) => {
  if (!headers['content-disposition'] && !options.name) {
    throw new Error('Either ContentDisposition header or options.name must be presented');
  }

  const name = options.name || filename(headers['content-disposition']);
  const type = headers['content-type'].split(';')[0];

  const file = new Blob([data], { type });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = fileURL;
  link.download = name;
  link.click();
};

export default download;
