import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash';

import Modal from '../../components/Modal';
import ItemsList, { selectors } from '../../components/List';
import Previewer from '../../components/Previewer';
import { ClinicPickerHeader } from 'screens/Appointments/components/ClinicPickerHeader';
import { Clinic } from 'screens/Appointments/components/Clinic';
import { useModal } from 'screens/Appointments/hooks/useModal';
import { Picker, WithVariants, WithErrors, WithSetter } from 'types/appointments';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';
import { defaultValues } from 'screens/Appointments/hooks/useForm';
import { ClinicFilters } from 'screens/Appointments/components/ClinicFilters';
import { IClinic, ClinicFilters as TClinicFilters } from 'types/institutions';

export const ClinicPicker: React.FC<Picker<IClinic> & WithErrors & WithVariants<IClinic> & WithSetter> = ({
  defaultValue,
  error,
  onClose,
  variants,
  fetchClinics,
  clearError,
  isDoctorEleks,
  setIsDoctorEleks,
  fetchDrEleksServices,
}) => {
  const { t } = useTranslation();

  const { isOpen, open, close, confirm, picked, onPick } = useModal(clinic => {
    setIsFiltersOpened(false);
    formMethods.reset();
    if (clinic) onClose(clinic);
  }, defaultValue);

  const [isFiltersOpened, setIsFiltersOpened] = useState(false);

  const [query, setQuery] = useState('');

  const defaultFilterValues = {
    locality: 'Не обрано',
    city: 'Не обрано',
    isOnline: false,
    clinicType: { id: '', label: '' },
  };

  const formMethods = useForm<TClinicFilters>({
    defaultValues: defaultFilterValues,
    shouldUnregister: false,
  });

  const data = formMethods.control.getValues();

  const onFiltersSubmit = () => {
    if (!_.isEqual(data, defaultFilterValues)) {
      let formatedFiltersData = {
        locality: data.locality ?? '',
        city: data.city !== defaultFilterValues.city ? data.city : '',
        propertyType: data.clinicType.id ?? null,
        isOnline: data.isOnline ?? '',
      };

      fetchClinics && fetchClinics({ ...formatedFiltersData, legalName: query });

      setIsFiltersOpened(false);
    }
  };

  const handleClinicClick = clinic => {
    onPick(picked?.id === clinic.id ? defaultValues.clinic : clinic);
  };

  const onArrowClick = () => {
    setIsFiltersOpened(false);
    formMethods.reset();
  };

  const handleQueryChange = (query: string) => {
    setQuery(query);
    fetchClinics && fetchClinics({ legalName: query });
  };

  const onSubmit = () => {
    clearError('clinic');
    if (picked?.integrationType === 'DOCTOR_ELEKS') {
      setIsDoctorEleks(true);
      fetchDrEleksServices && fetchDrEleksServices(picked.id);
    }
    if (isDoctorEleks) {
      setIsDoctorEleks(false);
    }
    confirm();
  };

  return (
    <>
      <Previewer
        displayValue={defaultValue.legalName}
        onClick={open}
        title={defaultValue.legalName || error ? t('appointments.clinic_title') : t('appointments.selector_clinic')}
        errorMessage={error ? t('appointments.selector_clinic') : ''}
      />
      {isOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setQuery('');
              onPick(defaultValue);
              close();
            }}
          >
            <Modal.Header
              title={isFiltersOpened ? t('appointments.filters') : t('appointments.clinics_list')}
              onClose={() => {
                setQuery('');
                onPick(defaultValue);
                close();
              }}
              renderArrow={isFiltersOpened}
              onArrowClick={() => onArrowClick()}
            />
            <Modal.Body>
              {isFiltersOpened ? (
                <ClinicFilters formMethods={formMethods} />
              ) : (
                <>
                  <ClinicPickerHeader
                    query={query}
                    onChange={handleQueryChange}
                    onClick={() => setIsFiltersOpened(true)}
                  />
                  <Scrollbars autoHeight autoHeightMax={MAX_SCROLLBAR_HEIGHT}>
                    <ItemsList
                      items={variants}
                      selector={selectors.byId(picked?.id)}
                      onItemClick={handleClinicClick}
                      itemBuilder={clinic => <Clinic isPicked={picked?.id === clinic.id} {...clinic} key={clinic.id} />}
                    />
                  </Scrollbars>
                </>
              )}
            </Modal.Body>
            <Modal.Confirm
              disabled={
                isFiltersOpened
                  ? _.isEqual(formMethods.watch(), formMethods.control.defaultValuesRef.current)
                  : _.isEqual(picked, defaultValue)
              }
              onClick={() => (isFiltersOpened ? onFiltersSubmit() : onSubmit())}
            />
          </OutsideClickHandler>
        </Modal>
      )}
    </>
  );
};
