import React from 'react';
import { css } from '@emotion/css';

import { theme } from 'styles';

const cssRoot = css`
  color: ${theme.color.primary.main};
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
`;

interface Props {
  children: React.ReactNode;
  onClick: () => void;
}

// #TODO adapter for Typography.Link

const Link = ({ children, ...props }: Props) => {
  return (
    <span className={cssRoot} {...props}>
      {children}
    </span>
  );
};

export default Link;
