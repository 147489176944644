import createInstance from './axios';
import { makerRequestParams } from 'utils/helpers';
import { TSetPagination } from 'store/adminList/actionTypes';
import {
  ResultListDtoPartnerView,
  ResultListDtoInsuranceCompanyView,
  ResultListDtoAuthUserView,
} from 'types/dto/contracts-service';

const adminListAPI = createInstance('CONTRACTS');

interface IExtendFilters {
  [key: string]: any;
}

interface IGetFiltersParams {
  filters: IExtendFilters;
  pagination: TSetPagination;
  sorting: any;
}

export const getPrivateFiltersTable = ({
  filters: { partnerId, ...filters },
  pagination,
  sorting,
}: IGetFiltersParams) => {
  const sort = {
    sorting: '-startDate,-id',
  };

  let normalizedParams = { ...filters };
  if (filters && typeof filters === 'object') {
    normalizedParams = Object.keys(filters).reduce((accum: any, key: string) => {
      if (filters[key]) {
        accum[key.replace('[]', '')] = Array.isArray(filters[key]) ? makerRequestParams(filters[key]) : filters[key];
      }
      return accum;
    }, {});
  }

  if (sorting && sorting.direction) {
    sort.sorting = sorting.direction === 'DESC' ? `-${sorting.field}` : `${sorting.field}`;
  }

  const partner = !!normalizedParams.insuranceCompanyId ? null : partnerId;
  return adminListAPI.get<ResultListDtoAuthUserView>(`/users?count${partner ? `&partnerId=${partner}` : ''}`, {
    params: {
      ...normalizedParams,
      page_size: pagination.perPage,
      page: pagination.current,
      ...sort,
    },
  });
};

export const getTerminationInsuranceCompanies = (id?): Promise<any> => {
  const partnerId = id ? Number(id) : '<>null';

  return adminListAPI
    .get<ResultListDtoInsuranceCompanyView>(
      `/insurance-companies/?distinct&attributes=id,name&insuranceCompanyId=${partnerId}`,
    )
    .then(res => ({
      data: (res.data.resultList ?? []).map(el => ({
        id: el.id,
        name: el.name,
      })),
    }));
};

export const getTerminationInsuranceProgram = (id?): Promise<any> => {
  const partnerId = id ? Number(id) : '<>null';

  return adminListAPI
    .get<ResultListDtoPartnerView>(`/partners/?distinct&page_size=300&insuranceCompanyId=${partnerId}`)
    .then(res => ({
      data: (res.data.resultList ?? []).map(el => ({
        id: el.partnerId,
        name: el.name,
        insuranceCompanyId: el.insuranceCompanyId,
        shortName: el.shortName,
      })),
    }));
};

export const disableUser = (ids: number[]) => {
  return adminListAPI.post<void>(`/users/deactivate`, { userIds: ids });
};
