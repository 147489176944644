import createInstance from './axios';
import momentTz from 'moment-timezone';
import {
  TerminationCauseDto,
  TerminationInitiatorDto,
  TerminationInitiatorDtoCode,
  TerminationDocumentConfigurationWithSumDto,
  ContractTerminationDto,
  FileStorageDto,
} from 'types/dto/contracts-service';
import { ResultListDtoInsuranceProgramView } from 'types/dto/configuration-service';

const termAPI = createInstance('CONTRACTS');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');
const configService = createInstance('CONFIGURATION');
interface ITermination {
  contractId?: number;
  terminationId?: string | number;
  terminationDetails?: any;
  token?: any;
  id?: number | string;
}

export const getTerminationCauses = async (initiatorCode?: string | number, insuranceProgramId?: null | number) =>
  termAPI.get<TerminationCauseDto[]>(
    `/contract-terminations/causes?terminationInitiator=${initiatorCode}&insuranceProgramId=${insuranceProgramId}`,
  );

export const getTerminationCausesForCreation = async (
  ionInitiatorId: string | number,
  insuranceProgramId: null | number,
) =>
  configService.get<TerminationCauseDto[]>(
    `/termination-configurations/termination-causes?terminationInitiatorId=${ionInitiatorId}&insuranceProgramId=${insuranceProgramId}`,
  );

export const getTerminationInitiators = async (contractId: number) =>
  termAPI.get<TerminationInitiatorDto[]>(`contract-terminations/initiators?contractId=${contractId}`);

export const getTerminationInitiatorsForCreation = async (insuranceProgramId: number) =>
  configService.get<TerminationInitiatorDto[]>(`/insurance-programs/${insuranceProgramId}/termination-initiators`);

export const getContractTermination = ({ terminationId }: ITermination) =>
  termAPI.get<ContractTerminationDto>(`/contract-terminations/${terminationId}`);

export const getContractTerminationTemplates = ({ contractId }: ITermination) =>
  termAPI.get<ContractTerminationDto>(`/contract-terminations/templates/?contractId=${contractId}`);

export const createContractTermination = (terminationDetails: any) =>
  termAPI.post<ContractTerminationDto>(`/contract-terminations/`, terminationDetails);

export const updateContractTermination = ({ id, terminationDetails }: ITermination) => {
  terminationDetails.documents.forEach((doc: any) => {
    // need to remove in future (backend issue)
    delete doc.id;
  });
  return termAPI.put<ContractTerminationDto>(`/contract-terminations/${id}`, terminationDetails);
};

export const getTerminationOtpDisabled = (programId: number) => {
  return configService.get<ResultListDtoInsuranceProgramView>(
    `/insurance-programs?attributes=id,name,terminationOtpDisabled&id=${programId}&distinct`,
  );
};

export const signTerminationVerification = ({ id, token = '' }: { id: number; token: string | undefined }) =>
  termAPI.post<void>(`/contract-terminations/${id}/sign?token=${token}`);

export const getTerminationVerificationToken = ({ id, terminationDetails }: ITermination) =>
  termAPI.post<void>(`/contract-terminations/${id}/sign-verification`, terminationDetails);

export const printContract = async (id: number) => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/contract-terminations/${id}/pdf-report`, {
    params: {
      timeZone,
    },
  });
};

export const getTerminationDocumentsAndSum = async (
  initiator: TerminationInitiatorDtoCode,
  contractId: number | string,
) => {
  return termAPI.get<TerminationDocumentConfigurationWithSumDto>('/contract-terminations/documents/configurations', {
    params: { initiator, contractId },
  });
};

export const documentUpload = async (terminationId: number, documentTypeCode: String, formData: any) => {
  return termAPI.post<FileStorageDto>(
    `/contract-terminations/${terminationId}/documents/${documentTypeCode}/files`,
    formData,
  );
};

export const downLoadFiles = async (page: string | undefined, id: number | string, documentTypeCode: String) => {
  const downLoadURL =
    page === 'termination'
      ? `/contract-terminations/${id}/documents/${documentTypeCode}/files`
      : `/claims/${id}/documents/${documentTypeCode}/files`;
  return termAPI.get(downLoadURL, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
      ContentType: 'application/x-www-form-urlencoded',
    },
  });
};

export const documentDelete = async (page: string | undefined, id: number | string, documentTypeCode: String) => {
  const downLoadURL =
    page === 'termination'
      ? `/contract-terminations/${id}/documents/${documentTypeCode}/files`
      : `/claims/${id}/documents/${documentTypeCode}/files`;
  return termAPI.delete(downLoadURL);
};
