import { useEffect } from 'react';
import { getUserContracts } from 'api/contracts';
import {
  getInstitutions,
  getServiceTypeServices,
  getAllServices,
  getDoctorEleksServices,
  getDoctorEleksDoctors,
  getDoctorEleksSlots,
} from 'api/institution';
import { getRepaymentsDetailsTable } from 'api/contracts';
import { useAsync } from 'hooks/useAsync';
import { APPOINTMENT_TYPES } from 'constants/appointments';

const dayCount = 14;

export const useData = () => {
  const { value: contracts, doFetch: fetchContracts } = useAsync(getUserContracts);
  const { value: serviceTypes, doFetch: fetchServiceTypes } = useAsync(getServiceTypeServices);
  const { value: services, doFetch: fetchServices } = useAsync(() =>
    getAllServices({ pagination: { current: 1, perPage: 300 }, sorting: null, filters: null }),
  );
  const { value: clinics, doFetch: fetchClinics } = useAsync(filters =>
    getInstitutions({ current: 1, perPage: 50 }, filters),
  );
  const { value: types, doFetch: fetchClaims } = useAsync(id =>
    getRepaymentsDetailsTable(id, { claimStatus: 'REPORTED' }),
  );

  const { value: drEleksServices, doFetch: fetchDrEleksServices } = useAsync(institutionId =>
    getDoctorEleksServices(institutionId),
  );
  const { value: drEleksDoctors, doFetch: fetchDrEleksDoctors } = useAsync(data =>
    getDoctorEleksDoctors(data.institutionId, data.serviceId),
  );
  const { value: drEleksSlots, doFetch: fetchDrEleksSlots } = useAsync(data =>
    getDoctorEleksSlots(data.institutionId, data.serviceId, data.doctorLogin, dayCount),
  );

  const defaultContract = localStorage.getItem('defaultContract');

  useEffect(() => {
    fetchContracts();
    fetchClinics();
    fetchServiceTypes();
    fetchServices();
  }, []);

  useEffect(() => {
    const contractId = defaultContract ? JSON.parse(defaultContract).contractId : 0;
    const cont = contracts ?? [];

    if (contractId || cont[0]) {
      fetchClaims(contractId || cont[0].contractId);
    }
  }, [defaultContract, contracts]);

  const formatTypes = () => {
    return APPOINTMENT_TYPES.map(el => {
      if (el.id === 2) {
        return {
          ...el,
          parentId: el.id,
          children: types?.data
            ? types.data.map(item => {
                return {
                  ...item,
                  parentId: el.id,
                  id: item.claimId,
                };
              })
            : [],
        };
      }
      return { ...el, parentId: el.id, children: [] };
    });
  };

  const serviceData =
    serviceTypes && services
      ? serviceTypes?.resultList.map(({ id, name, hasChildren, couldBeOnline }) => {
          const children = hasChildren
            ? services?.resultList.filter(({ parent }) => parent === id).map(el => ({ ...el, parentId: el.parent }))
            : [];

          return {
            id,
            name,
            couldBeOnline,
            children,
            parentId: id,
          };
        })
      : [];

  return {
    contracts: contracts ?? [],
    services: serviceData ?? [],
    clinics: clinics?.data?.resultList ?? [],
    types: types ? formatTypes() : [],
    drEleksServices: drEleksServices?.serviceDetails ?? [],
    drEleksDoctors: drEleksDoctors?.result ?? [],
    drEleksSlots: drEleksSlots?.freeSeanceDetails ?? [],
    fetchClaims,
    fetchClinics,
    fetchDrEleksServices,
    fetchDrEleksDoctors,
    fetchDrEleksSlots,
  };
};
