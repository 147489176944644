import { dateFormatter, moneyFormatter } from '../../utils/helpers';

export const pageSize = 3;
export const page = 1;
export const total = 1;

export const tableColWidth = {
  terminationNumber: 250,
  contractDateFrom: 170,
  contractDateTo: 170,
  terminationDate: 170,
  realDuration: 140,
  signDate: 150,
  realEndDate: 180,
  cause: 240,
  duration: 150,
  claimPaymentSum: 230,
  refundSum: 150,
  signed: 70,
  initiator: 200,
};

export const insurerAdapter = {
  INSURER: 'За ініціативою Покупця з утриманням',
  POLICY_HOLDER: "У зв'язку з поверненням товару згідно законодавства у 14-денний термін",
  INSURANCE_COMPANY: 'За ініціативою СК без утримання',
  INSURANCE_COMPANY_MAINTENANCE: 'За ініціативою СК з утриманням',
};

export const formatTerminationsData = (dataArr: any) => {
  if (!dataArr) {
    return [];
  } else {
    return dataArr.map((data: any) => ({
      ...data,
      status: data.signed ? 'ACTIVE' : 'DRAFT',
      realEndDate: data.realEndDate ? dateFormatter(data.realEndDate) : null,
      claimPaymentSum: `${moneyFormatter(data.claimPaymentSum)}`,
      refundSum: `${moneyFormatter(data.refundSum)}`,
      contractDateFrom: dateFormatter(data.contractDateFrom),
      contractDateTo: dateFormatter(data.contractDateTo),
      terminationDate: dateFormatter(data.terminationDate),
      signDate: data.signDate ? dateFormatter(data.signDate) : null,
      initiator: insurerAdapter[data.initiator],
    }));
  }
};
