export enum CONTRACT_TYPE {
  MOBI = 'MZ',
  COVID = 'CV',
  KASKO = 'MK',
  JUTLO = 'MM',
  INNOTECH = 'IN',
  MEDICINE = 'MED',
  VZR = 'VZR',
}

export enum INSURANCE_OBJECT_TYPE {
  ELECTRONIC_DEVICE = 'ELECTRONIC_DEVICE',
  PERSON = 'PERSON',
  VEHICLE = 'VEHICLE',
}

export const ClientFields = t => {
  return [
    {
      title: '№',
      fieldname: 'key',
      dataIndex: 'key',
      width: 10,
    },
    {
      title: t('person_info.last_name'),
      fieldname: `lastName`,
      dataIndex: 'lastName',
    },
    {
      title: t('person_info.first_name'),
      fieldname: `firstName`,
      dataIndex: 'firstName',
    },
    {
      title: t('person_info.middle_name'),
      fieldname: `middleName`,
      dataIndex: 'middleName',
    },
    {
      title: t('person_info.birthday'),
      fieldname: `birthday`,
      dataIndex: 'birthday',
    },
    {
      title: t('person_info.passport'),
      fieldname: `passport`,
      dataIndex: 'passport',
    },
    {
      title: '',
      fieldname: 'actions',
      width: 10,
    },
  ];
};
