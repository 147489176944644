import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
//@ts-ignore
import update from 'update-js';

import { getInsuranceProgram } from './helper';

import { contractDetailsType, ContractInfoDto } from 'types/contractDetails';
import { InsuranceProgramDetailsDto } from 'types/dto/configuration-service';

const initialState: contractDetailsType = {
  contract: {
    insuranceSumId: 0,
    contractInfo: {
      currency: undefined,
      franchise: undefined,
      region: undefined,
      purpose: undefined,
      currencyExchangeRate: 0,
      visitLocale: '',
    },
    id: null,
    clientId: 0,
    prolongateFromId: 0,
    initialInsuranceProgram: {},
    insurancePolicy: {
      id: 0,
      name: '',
    },
    objects: [
      {
        paymentAmountForObject: 0,
      },
    ],
    installmentPayments: {
      installmentPaymentPeriodType: '',
    },
    identifier: '090',
    terminationId: null,
    conclusionDate: '',
    contractNumber: '',
    insuranceProductCode: '',
    partnerId: undefined,
    contract: {},
    chosenPolicy: '',
    initialPolicy: '',
    objectConfiguration: {
      contractNumberEnding: 0,
    },
    pid: '',
    originalContractNumber: undefined,
    originalContractId: undefined,
    current: undefined, // самая последняя подписанная допка
    subAgreementNumber: undefined,
    hasDraftSubAgreement: undefined, // флаг если мы не можем создать допку, т.к. есть допка черновик

    beneficiaryUpdated: false, // false = beneficiary will be update with signatory, true = another beneficiary
    needClientSign: false,
    contractNumberEnding: '',
    contractNumberMainPart: '',
    contractNumberPrefix: '',
    signDate: '',
    prolongationSum: 0,
    calculatedPaymentAmount: 0,
    fixedPaymentAmount: 0, // for prolongation calc
    calculatedInsuranceRate: 0,
    initialCalculatedInsuranceRate: 0,
    initialInsuranceSum: 0,
    initialCalculatedPaymentAmount: 0,
    initialPaymentAmount: 0,
    initialInsuranceRate: 0,
    sellerPhoneNumber: '',
    sellerName: '',
    estateData: {
      estateObjectType: '',
      street: '',
      locality: '',
      // limit: 0,
    },
    contractObjects: [
      {
        brandModel: '',
        brand: '',
        model: '',
        id: 0,
        pid: '',
        checkNumber: '',
        checkNumberPrefix: '',
        saleDate: '',
        saleSum: 0,
        subtypeId: 2,
        type: '',
        email: '',
        passport: '',
        subtypeCode: '',
      },
    ],
    duration: 0,
    fixedDuration: 0, // for prolongation calc
    durationUnit: '',
    endDate: '',
    installmentPayment: false,
    initialProgramId: 0,
    insuranceProgram: {
      id: 0,
      name: '',
      code: '',
      installmentPaymentSubscription: false,
      clientSignContractEnabled: false,
      policies: [{ id: 0 }],
      insuranceProgramSmsConfiguration: {
        claimOtpDisabled: false,
        claimSendSmsDisabled: false,
        contractOtpDisabled: false,
        contractSendSmsDisabled: false,
        terminationOtpDisabled: false,
        terminationSendSmsDisabled: false,
      },
      installmentPayments: [
        {
          description: '',
          installmentPaymentPeriodType: '',
          minimalInsuranceSum: 0,
        },
      ],
    },
    insuranceRate: 0,
    paymentAmountForObject: 0,
    insuranceSum: 0,
    insuranceSumCurrency: '',
    paymentAmount: 0,
    paymentAmountCurrency: '',
    paymentStatus: '',
    risks: [
      {
        franchise: 0,
        id: 0,
        insuranceAmount: 0,
        name: '',
        paymentAmount: 0,
        riskNumber: 0,
      },
    ],
    signatory: {
      companyName: '',
      firstName: '',
      id: 0,
      lastName: '',
      locality: '',
      middleName: '',
      phoneNumber: '',
      street: '',
      type: '',
      citizenship: '',
      email: '',
    },
    subAgreementConclusionDate: '',
    subAgreementStartDate: '',
    subAgreementEndDate: '',
    contractBeneficiary: {
      arrivaldate: '',
      companyName: '',
      firstName: '',
      id: 0,
      lastName: '',
      locality: '',
      middleName: '',
      phoneNumber: '',
      street: '',
      type: '',
    },
    startDate: '',
    status: '',
    prolongationStartDate: '',
    prolongationEndDate: '',
    contractInstallmentPayment: {
      installmentPaymentPeriodType: '',
    },
    currencyExchangeRate: 0,
  },
  savedRisks: [],
  estateSubTypes: [],
  estateTypes: [],
  errorMessage: '',
  pending: false,
  savedContract: [],
  phoneData: [],
  imeiData: {
    deviceDetails: {
      brand: '',
      model: '',
    },
  },
  imeiSerialNumber: '',
  calculationData: {},
  contractNumber: '',
  generatedContractNumber: '',
  insurancePrograms: [
    {
      id: 1,
      name: '',
      code: '',
      installmentPaymentSubscription: false,
      clientSignContractEnabled: false,
      policies: [
        {
          id: 0,
          policyType: '',
          rates: [
            {
              contractNumberEnding: '',
              duration: {
                duration: 0,
                durationUnit: '',
              },
            },
          ],
        },
      ],
    },
  ],
  detailProgram: [
    {
      id: 1,
      name: '',
      code: '',
      installmentPayment: false,
      policies: [
        {
          id: 0,
          policyType: '',
          rates: [
            {
              contractNumberEnding: '',
              duration: {
                duration: 0,
                durationUnit: '',
              },
            },
          ],
        },
      ],
    },
  ],
  insuranceObjectTypes: [],
  insuranceProgramDuration: [],
  removeReconciliationWarnings: [],
  paymentsList: {
    loading: false,
    statistic: {},
    data: [],
    total: 0,
    pagination: {
      current: 1,
      perPage: 10,
    },
    sorting: {
      field: 'expectedPaymentDate',
      direction: 'DESC',
    },
    filters: {
      periodStartDate: '',
      periodEndDate: '',
      expectedPaymentAmount: '',
      expectedPaymentDate: '',
      paymentAmount: '',
      paymentDate: '',
      checkNumber: '',
      leftToPay: '',
      contractId: '',
      insuranceCompanyId: '',
      partnerId: '',
      partnerDepartmentId: '',
    },
    privateFilterItems: {},
    privateFilteredData: [],
  },
  countries: [],
  wrongCode: false,
  insuranceProductCode: '',
  newDocumentUpload: [],
  uploadLoader: false,
  isPhotoRequired: false,
};

const setEstateTypes = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  return {
    ...state,
    estateTypes: payload,
  };
};

const setEstateSybTypes = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  return {
    ...state,
    estateSubTypes: payload,
  };
};

const setEstateData = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  const { field, data } = payload;
  const objects = state.contract.contractObjects.map(item => {
    return field === 'estateObjectType'
      ? {
          ...item,
          [field]: data,
        }
      : {
          ...item,
        };
  });
  return {
    ...state,
    contract: {
      ...state.contract,
      contractObjects: objects,
      estateData: {
        ...state.contract.estateData,
        [field]: data,
      },
    },
  };
};

const setPaymentsList = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  return {
    ...state,
    paymentsList: {
      ...state.paymentsList,
      data: payload.resultList,
      total: payload.count,
      statistic: payload.total,
    },
  };
};

const getPrivateFiltersData = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  const { resultList, count }: { resultList: []; count: number } = payload;

  return {
    ...state,
    paymentsList: {
      ...state.paymentsList,
      data: resultList,
      total: count,
    },
  };
};

const setObjectConfiguration = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  return {
    ...state,
    contract: {
      ...state.contract,
      objectConfiguration: payload,
    },
  };
};

const setTablePage = (state: contractDetailsType, { payload, pending }: AnyAction): contractDetailsType => {
  return {
    ...state,
    paymentsList: {
      ...state.paymentsList,
      data: payload ? payload.resultList : initialState.paymentsList.data,
      total: payload ? payload.count : initialState.paymentsList.total,
      loading: pending,
    },
    wrongCode: payload ? payload.wrongCode : state.wrongCode,
  };
};

const setPagination = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  return {
    ...state,
    paymentsList: {
      ...state.paymentsList,
      pagination: {
        ...state.paymentsList.pagination,
        ...payload,
      },
    },
  };
};

const setSorting = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      paymentsList: {
        ...state.paymentsList,
        sorting: {
          field: field,
          direction: direction,
        },
      },
    };
  }
  return {
    ...state,
    paymentsList: {
      ...state.paymentsList,
      sorting: initialState.paymentsList.sorting,
    },
  };
};

const setInitialRates = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  return {
    ...state,
    contract: {
      ...state.contract,
      initialInsuranceRate: payload.insuranceRate,
      initialCalculatedInsuranceRate: payload.calculatedInsuranceRate,
      initialPaymentAmount: payload.paymentAmount,
    },
  };
};

const setContract = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  const program = state.insurancePrograms.find(item => item.id === payload?.contract?.insuranceProgram.id);
  const data = payload.contract || initialState.contract;
  return {
    ...state,
    pending: payload.pending,
    errorMessage: payload.errorMessage || initialState.errorMessage,
    contract: {
      ...state.contract,
      ...data,
      initialInsuranceSum: data.insuranceSum,
      initialCalculatedInsuranceRate: data.calculatedInsuranceRate,
      initialInsuranceRate: data.insuranceRate,
      initialCalculatedPaymentAmount: data.calculatedPaymentAmount,
      initialPaymentAmount: data.paymentAmount,
      contract: {
        ...data.contractInstallmentPayment,
      },
      chosenPolicy: state.contract.chosenPolicy,
      initialProgramId: data.insuranceProgram.id,
      insuranceProgram: {
        ...data.insuranceProgram,
        installmentPaymentSubscription: program?.installmentPaymentSubscription
          ? program?.installmentPaymentSubscription
          : data.installmentPaymentSubscription,
        clientSignContractEnabled: program?.clientSignContractEnabled,
        policies: program?.policies ? program?.policies : data.insuranceProgram.policies,
      },
    },
  };
};

const addContract = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    savedContract: payload.savedContract || initialState.savedContract,
  };
};

const getDataFromPhoneNumber = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    phoneData: payload.phoneData || initialState.phoneData,
  };
};

const getDataFromImei = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    imeiData: payload.imeiData || initialState.imeiData,
    contract: {
      ...state.contract,
      contractObjects: [
        {
          ...state.contract.contractObjects[0],
          pid: payload.imei,
          brand: payload.imeiData?.brand || initialState.contract.contractObjects[0]?.brand,
          model: payload.imeiData?.model || initialState.contract.contractObjects[0]?.model,
        },
      ],
    },
  };
};

const setImeiSerialNumber = (state: contractDetailsType, { payload }: AnyAction): contractDetailsType => {
  return {
    ...state,
    imeiSerialNumber: payload.imeiSerialNumber,
  };
};

const getUniqueImei = (state: contractDetailsType): any => {
  return {
    ...state,
  };
};

const getContractCalculation = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...payload,
      fixedDuration: state.contract.fixedDuration,
    },
  };
};

const setContractDuration = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      duration: payload.value,
      durationUnit: payload.units,
    },
  };
};

const setInsuranceProgram = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    contract: payload,
  };
};

const setContractNumber = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contractNumber: payload,
  };
};

const setGenNumber = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    generatedContractNumber: payload,
  };
};

const setSaleDate = (state: contractDetailsType, { payload }: any): any => {
  return update(state, 'contract.contractObjects.0.saleDate', payload);
};

const setConclusionDate = (state: contractDetailsType, { payload }: any): any => {
  return update(state, 'contract.conclusionDate', payload);
};

const getInsuranceProgramRisks = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      risks: payload,
    },
    savedRisks: payload,
  };
};

const updateContractDetails = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: payload,
  };
};

const getInsurancePrograms = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  const data = (payload.insurancePrograms as InsuranceProgramDetailsDto[]).find(
    item => item.id === getInsuranceProgram(state, payload).id,
  );
  return {
    ...state,
    contract: {
      ...state.contract,
      insuranceProgram: {
        ...getInsuranceProgram(state, payload),
        installmentPaymentSubscription: data?.installmentPaymentSubscription,
        clientSignContractEnabled: data?.clientSignContractEnabled,
        policies: data?.policies || initialState.contract.insuranceProgram.policies,
        installmentPayments: data?.installmentPayments,
      },
    },
    insurancePrograms: payload.insurancePrograms || initialState.insurancePrograms,
  };
};

const setInitialInsuranceProgram = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      initialInsuranceProgram: payload,
    },
  };
};

const setInsuranceDetailProgram = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    detailProgram: payload,
  };
};

const getInsuranceObjectSubTypes = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    insuranceObjectTypes: payload.insuranceObjectTypes || initialState.insuranceObjectTypes,
  };
};

const setInsuranceObjectSubTypeId = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractObjects: {
        ...state.contract.contractObjects,
        //TODO temp decition
        0: {
          ...state.contract.contractObjects[0],
          subtypeId: payload,
        },
      },
    },
  };
};

const setInsuranceProgramDuration = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    insuranceProgramDuration: payload.insuranceProgramDuration || initialState.insuranceProgramDuration,
  };
};

const setFixedDuration = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      fixedDuration: payload,
    },
  };
};

const setInsuranceContractSum = (state: contractDetailsType): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractObjects: {
        ...state.contract.contractObjects,
      },
    },
  };
};

const setCalculatedPaymentAmount = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      calculatedPaymentAmount: payload,
    },
  };
};

const setFixedPaymentAmount = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      fixedPaymentAmount: payload,
    },
  };
};

const setPaymentAmountForObject = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      paymentAmountForObject: payload,
    },
  };
};

const setContractNumberEnding = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractNumberEnding: payload,
    },
  };
};

const setContractSignatory = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      signatory: {
        ...state.contract.signatory,
      },
    },
  };
};

const resetSignatory = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      signatory: {
        ...state.contract.signatory,
        companyName: '',
        firstName: '',
        id: 0,
        lastName: '',
        locality: '',
        middleName: '',
        phoneNumber: '',
        street: '',
        type: '',
        citizenship: '',
        email: '',
      },
    },
  };
};

const resetContractBeneficiary = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractBeneficiary: {
        arrivaldate: '',
        companyName: '',
        firstName: '',
        id: 0,
        lastName: '',
        locality: '',
        middleName: '',
        phoneNumber: '',
        street: '',
        type: '',
        passport: '',
      },
    },
  };
};

const setContractBeneficiary = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractBeneficiary: {
        ...payload,
      },
    },
  };
};

const setContractInfo = (
  state: contractDetailsType,
  { payload }: { payload: ContractInfoDto },
): contractDetailsType => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractInfo: payload,
    },
  };
};

const setContractDeviceData = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractObjects: [
        {
          ...state.contract.contractObjects[0],
          brand: payload.brand,
          model: payload.model,
        },
      ],
    },
  };
};

const removeReconciliationWarnings = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    removeReconciliationWarnings: payload,
  };
};

const setBeneficiaryUpdated = (
  state: contractDetailsType,
  { payload: beneficiaryUpdated }: AnyAction,
): contractDetailsType => ({
  ...state,
  contract: {
    ...state.contract,
    beneficiaryUpdated,
  },
});

const setAccountNumber = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contract: {
        ...state.contract.contractInstallmentPayment,
        accountNumber: payload,
      },
    },
  };
};

const setChosenPolicy = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      chosenPolicy: payload,
    },
  };
};

const setIdentifier = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      identifier: payload,
    },
  };
};

const setNumberMainPart = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      contractNumberMainPart: payload,
    },
  };
};

const setStartDate = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      startDate: payload,
    },
  };
};

const setEndDate = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      endDate: payload,
    },
  };
};

const setInitialPolicy = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    contract: {
      ...state.contract,
      initialPolicy: payload,
    },
  };
};

const removeReconsiliation = (state: contractDetailsType): contractDetailsType => {
  return {
    ...state,
    contract: {
      ...state.contract,
      verifiedAt: '',
    },
  };
};

const resetInitialPaymentAmount = (state: contractDetailsType): contractDetailsType => {
  return {
    ...state,
    contract: {
      ...state.contract,
      initialPaymentAmount: 0,
    },
  };
};

const setInitialContractState = (): any => ({ ...initialState });

const getCountries = (state: contractDetailsType, { payload }: any): contractDetailsType => {
  return {
    ...state,
    countries: payload,
  };
};

const setInsuranceProductCode = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    insuranceProductCode: payload.code,
  };
};

const newDocumentUpload = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    newDocumentUpload: payload.uploadData,
  };
};

const testAction = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    uploadLoader: payload.uploadLoader,
  };
};

const setClientOnSignError = (state: contractDetailsType, { payload }: any): any => {
  return {
    ...state,
    isPhotoRequired: payload.isPhotoRequired,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setContract,
    addContract,
    getDataFromPhoneNumber,
    getContractCalculation,
    setInsuranceProgram,
    setContractNumber,
    setSaleDate,
    setConclusionDate,
    getInsuranceProgramRisks,
    setAccountNumber,
    updateContractDetails,
    setInsuranceDetailProgram,
    getInsurancePrograms,
    getInsuranceObjectSubTypes,
    setInsuranceObjectSubTypeId,
    setInitialContractState,
    setInsuranceProgramDuration,
    setInsuranceContractSum,
    setCalculatedPaymentAmount,
    setFixedPaymentAmount,
    setFixedDuration,
    setContractNumberEnding,
    setContractSignatory,
    setContractBeneficiary,
    setContractInfo,
    removeReconciliationWarnings,
    getDataFromImei,
    getUniqueImei,
    setContractDeviceData,
    setPagination,
    setPaymentsList,
    setSorting,
    getPrivateFiltersData,
    setTablePage,
    setBeneficiaryUpdated,
    setPaymentAmountForObject,
    setEstateTypes,
    setEstateData,
    setEstateSybTypes,
    setGenNumber,
    setContractDuration,
    removeReconsiliation,
    setChosenPolicy,
    setInitialPolicy,
    setInitialRates,
    resetInitialPaymentAmount,
    setIdentifier,
    setNumberMainPart,
    setImeiSerialNumber,
    setObjectConfiguration,
    setEndDate,
    setStartDate,
    getCountries,
    resetContractBeneficiary,
    resetSignatory,
    setInitialInsuranceProgram,
    setInsuranceProductCode,
    newDocumentUpload,
    testAction,
    setClientOnSignError,
  },
});
