import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import {
  fetch,
  getIntegration,
  getPartnersData,
  getProgram,
  setPagination,
  setProducts,
} from 'store/insuranceContracts/actions';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from './components/PageHeader';
import ContractsTable from './components/Table';
import ImportModal from './components/ImportModal';
import ExportModal from './components/ExportModal';
import InfoModal from 'screens/InsuranceContracts/List/components/InfoModal';
import moment from 'moment';
import { ICustomPagination, IInfoPagination } from 'api/insuranceContracts';
import { PACKAGE_NUMBER_FORMAT_VU } from 'screens/InsuranceContracts/List/components/constants';
import { IInfoModal } from 'screens/InsuranceContracts/List/components/InfoModal/types';
import { ContractsFilters } from './components/ContractsFiltres/ContractsFilters';
import { getHistoryData } from 'store/integrationHistory/actions';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/reducer';

interface IInsuranceCompanies {
  classes: any;
}

type modalInfo = {
  insuranceProgramName: string;
  packageNumber: string;
  importDate: string;
  transferType: string;
  historyId: number;
  id: number;
};

const InsuranceContracts: React.FC<IInsuranceCompanies> = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [isExportOpen, setExportOpen] = useState<boolean>(false);
  const [infoPagination, setInfoPagination] = useState<IInfoPagination>({ page: 1, size: 10, total: 10 });
  const [modalInfo, setModalInfo] = useState<modalInfo>({
    insuranceProgramName: '',
    packageNumber: '',
    importDate: '',
    transferType: '',
    historyId: 0,
    id: 0,
  });

  const {
    chosenPartner,
    chosenProducts,
    importedHistory,
    importedHistoryStatus,
    pagination,
    data,
    loading,
    contractTypeProducts,
    total,
  } = useSelector((store: AppState) => ({
    data: store.insuranceContracts.data,
    chosenPartner: store.insuranceContracts.chosenPartner,
    chosenProducts: store.insuranceContracts.chosenProducts,
    importedHistory: store.integrationHistory.importedHistory,
    importedHistoryStatus: store.integrationHistory.loading,
    pagination: store.insuranceContracts.pagination,
    loading: store.insuranceContracts.loading,
    contractTypeProducts: store.insuranceProducts.contractTypeProducts,
    total: store.insuranceContracts.total,
  }));

  useEffect(() => {
    dispatch(getIntegration());
    dispatch(getPartnersData());
  }, []);

  useEffect(() => {
    if (chosenPartner.id) {
      dispatch(setProducts(chosenPartner.id));
    }
    dispatch(getProgram(chosenPartner.id, chosenProducts.id));
  }, [chosenPartner.id, chosenProducts.id]);

  useEffect(() => {
    dispatch(getHistoryData(modalInfo.historyId, infoPagination));
  }, [infoPagination.page, infoPagination.size]);

  useEffect(() => {
    if (importedHistory) {
      setInfoPagination({
        ...infoPagination,
        // total: importedHistory.count,
      });
    }
  }, [importedHistory]);

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(fetch(t));
    }
  }, [isModalOpen]);

  const onPaginationChange = useCallback(
    (p: ICustomPagination) => {
      if (loading) return;
      dispatch(setPagination(p));
      dispatch(fetch(t));
    },
    [loading, dispatch],
  );

  const handleExport = useCallback(() => {
    setExportOpen(true);
  }, []);

  const handleImport = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleTableRowClick = useCallback((data: IInfoModal) => {
    setModalInfo({
      insuranceProgramName: data.insuranceProgramName,
      packageNumber: (PACKAGE_NUMBER_FORMAT_VU + data.packageNumber).slice(-10),
      importDate: moment(data.importDate).format('MM.DD.YYYY'),
      transferType: data.transferType,
      historyId: data.id,
      id: data.id,
    });

    setInfoModalOpen(true);
    dispatch(getHistoryData(data.id));
  }, []);

  const handleCancelInfoModal = useCallback(() => {
    setInfoModalOpen(false);
  }, []);

  const onCloseExport = () => {
    setExportOpen(false);
  };

  return (
    <>
      <PageHeader
        button={{ variant: 'contained', size: 'large' }}
        onExport={handleExport}
        onImport={handleImport}
        text={t('insurance_contracts.integration_with_other_systems')}
        additionalBreadcrumbs={[
          {
            label: t('insurance_contracts.integration_with_other_systems'),
          },
        ]}
      />
      <ContractsFilters />
      <ContractsTable
        classes={classes}
        pagination={pagination}
        data={data}
        loading={loading}
        onPaginationChange={onPaginationChange}
        onClick={handleTableRowClick}
        total={total}
      />
      <ExportModal isExportOpen={isExportOpen} onCloseExport={onCloseExport} products={contractTypeProducts} />
      <ImportModal
        onClose={() => {
          setModalOpen(false);
        }}
        isModalOpen={isModalOpen}
      />
      <InfoModal
        isModalOpen={isInfoModalOpen}
        info={modalInfo}
        onCancel={handleCancelInfoModal}
        tableData={importedHistory}
        tableDataLoading={importedHistoryStatus}
        setInfoPagination={setInfoPagination}
        infoPagination={infoPagination}
      />
    </>
  );
};

export default withStyles(styles)(InsuranceContracts);
