import React from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import Button from 'components/Button';
import Input from 'components/uikit/Input';
import FormSelect from 'components/FormSelect';
import { IInfoBrand } from '../interfaces/modalBrands.interfaces';

import styles from '../ModalBrand.module.less';

const InfoBrand = ({
  isNotEmpty = true,
  error,
  onBlur,
  onChange,
  value,
  label,
  handleSubmit,
  buttonText,
  types,
  handleType,
  typeValue,
  ...props
}: IInfoBrand) => {
  const { t } = useTranslation();

  return (
    <>
      <span className={styles.title}>{t('brands_page.brand_information_info')}</span>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            {...props}
            error={!!error}
            helperText={error}
            onBlur={onBlur}
            FormHelperTextProps={{
              error: !!error,
              children: error,
            }}
            fullWidth={true}
            value={value}
            onChange={onChange}
            label={label}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FormSelect
            selectDataId="Add-Brand-Type-Field"
            optionDataId="Add-Brand-Type-Option"
            title={t('brands_page.type_info')}
            options={types.map(item => ({ title: item.name, value: item.name, key: item.id }))}
            isRequired={true}
            onChange={handleType}
            getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
            value={typeValue}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button intent={'success'} disabled={!isNotEmpty} fullWidth={true} onClick={handleSubmit}>
            {buttonText || t('brands_page.add_info')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default InfoBrand;
