const columns = t => {
  return [
    {
      title: t('partner_list.identifier_table'),
      value: 'identifier',
      key: 'identifier',
      dataIndex: 'identifier',
      sorter: true,
      width: 170,
    },
    {
      title: t('partner_list.name_table'),
      value: 'name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: t('partner_list.short_name_table'),
      value: 'shortName',
      key: 'shortName',
      dataIndex: 'shortName',
      sorter: true,
    },
    {
      title: t('partner_list.code_table'),
      value: 'code',
      key: 'code',
      dataIndex: 'code',
      width: 150,
      sorter: true,
    },
    {
      title: t('partner_list.type_table'),
      value: 'type',
      key: 'type',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: t('partner_list.insurance_company_name_table'),
      value: 'insuranceCompanyName',
      key: 'insuranceCompanyName',
      dataIndex: 'insuranceCompanyName',
      sorter: true,
    },
  ];
};

export default columns;
