import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => (
  <SvgIcon fill="none" viewBox={'0 0 20 20'} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.0238 0.768403C10.8813 0.650903 10.6925 0.602153 10.51 0.635903L0.51 2.5109C0.21375 2.5659 0 2.8234 0 3.12465V16.8747C0 17.1747 0.21375 17.4334 0.51 17.4884L10.51 19.3634C10.5475 19.3709 10.5875 19.3747 10.625 19.3747C10.7688 19.3747 10.9113 19.3247 11.0238 19.2309C11.1675 19.1122 11.25 18.9347 11.25 18.7497V1.24965C11.25 1.0634 11.1675 0.887153 11.0238 0.768403ZM10 17.9959L1.25 16.3559V3.6434L10 2.0034V17.9959Z"
      fill="currentColor"
    />
    <path
      d="M19.375 3.12439H10.625C10.28 3.12439 10 3.40439 10 3.74939C10 4.09439 10.28 4.37439 10.625 4.37439H18.75V15.6244H10.625C10.28 15.6244 10 15.9044 10 16.2494C10 16.5944 10.28 16.8744 10.625 16.8744H19.375C19.72 16.8744 20 16.5944 20 16.2494V3.74939C20 3.40439 19.72 3.12439 19.375 3.12439Z"
      fill="currentColor"
    />
    <path
      d="M13.125 5.62439H10.625C10.28 5.62439 10 5.90439 10 6.24939C10 6.59439 10.28 6.87439 10.625 6.87439H13.125C13.47 6.87439 13.75 6.59439 13.75 6.24939C13.75 5.90439 13.47 5.62439 13.125 5.62439Z"
      fill="currentColor"
    />
    <path
      d="M13.125 8.12439H10.625C10.28 8.12439 10 8.40439 10 8.74939C10 9.09439 10.28 9.37439 10.625 9.37439H13.125C13.47 9.37439 13.75 9.09439 13.75 8.74939C13.75 8.40439 13.47 8.12439 13.125 8.12439Z"
      fill="currentColor"
    />
    <path
      d="M13.125 10.6244H10.625C10.28 10.6244 10 10.9044 10 11.2494C10 11.5944 10.28 11.8744 10.625 11.8744H13.125C13.47 11.8744 13.75 11.5944 13.75 11.2494C13.75 10.9044 13.47 10.6244 13.125 10.6244Z"
      fill="currentColor"
    />
    <path
      d="M13.125 13.1244H10.625C10.28 13.1244 10 13.4044 10 13.7494C10 14.0944 10.28 14.3744 10.625 14.3744H13.125C13.47 14.3744 13.75 14.0944 13.75 13.7494C13.75 13.4044 13.47 13.1244 13.125 13.1244Z"
      fill="currentColor"
    />
    <path
      d="M16.875 5.62439H15.625C15.28 5.62439 15 5.90439 15 6.24939C15 6.59439 15.28 6.87439 15.625 6.87439H16.875C17.22 6.87439 17.5 6.59439 17.5 6.24939C17.5 5.90439 17.22 5.62439 16.875 5.62439Z"
      fill="currentColor"
    />
    <path
      d="M16.875 8.12439H15.625C15.28 8.12439 15 8.40439 15 8.74939C15 9.09439 15.28 9.37439 15.625 9.37439H16.875C17.22 9.37439 17.5 9.09439 17.5 8.74939C17.5 8.40439 17.22 8.12439 16.875 8.12439Z"
      fill="currentColor"
    />
    <path
      d="M16.875 10.6244H15.625C15.28 10.6244 15 10.9044 15 11.2494C15 11.5944 15.28 11.8744 15.625 11.8744H16.875C17.22 11.8744 17.5 11.5944 17.5 11.2494C17.5 10.9044 17.22 10.6244 16.875 10.6244Z"
      fill="currentColor"
    />
    <path
      d="M16.875 13.1244H15.625C15.28 13.1244 15 13.4044 15 13.7494C15 14.0944 15.28 14.3744 15.625 14.3744H16.875C17.22 14.3744 17.5 14.0944 17.5 13.7494C17.5 13.4044 17.22 13.1244 16.875 13.1244Z"
      fill="currentColor"
    />
    <path
      d="M8.59632 12.0885L4.22132 7.08851C3.99132 6.82726 3.59757 6.80226 3.33882 7.02976C3.07882 7.25726 3.05257 7.65226 3.28007 7.91101L7.65507 12.911C7.77882 13.0523 7.95132 13.1248 8.12507 13.1248C8.27132 13.1248 8.41757 13.0735 8.53757 12.9698C8.79757 12.7423 8.82382 12.3485 8.59632 12.0885Z"
      fill="currentColor"
    />
    <path
      d="M8.50871 6.38187C8.23621 6.16812 7.84371 6.21937 7.63121 6.49062L3.25621 12.1156C3.04496 12.3881 3.09371 12.7819 3.36621 12.9931C3.48121 13.0819 3.61621 13.1244 3.74996 13.1244C3.93496 13.1244 4.11996 13.0419 4.24246 12.8844L8.61746 7.25937C8.82996 6.98562 8.78121 6.59312 8.50871 6.38187Z"
      fill="currentColor"
    />
  </SvgIcon>
);
