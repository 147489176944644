import React from 'react';

import { Progress } from 'antd';

const defaultColors = {
  '0%': '#108ee9',
  '100%': '#87d068',
};

interface IProgreesBar {
  value: number;
  strokeColor?: {
    [key: string]: string;
  };
}

export const ProgressBar: React.FC<IProgreesBar> = ({ value, strokeColor = defaultColors }) => {
  return (
    <>
      <Progress strokeColor={strokeColor} percent={value} />
    </>
  );
};
