import moment from 'moment';

import createInstance from './axios';
import { ResultListDtoInsuranceProductView } from 'types/dto/configuration-service';

const api = createInstance('CONFIGURATION');

export const getInsuranceProducts = (partnerId?: number) => {
  const baseParams = {
    distinct: true,
    attributes: ['id', 'name', 'code'].join(),
    deactivationDate: `>=${moment().format('YYYY-MM-DD')}`,
  };
  const params = partnerId ? { ...baseParams, partnerId } : baseParams;

  return api.get<ResultListDtoInsuranceProductView>('/insurance-products', { params });
};
