import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { css } from '@emotion/css';

import InputContainer, { Margin, Info } from '../InputContainer/InputContainer';
import { theme } from 'styles';

const cssRoot = css`
  .ant-input {
    border-radius: 12px;
    border: none;
    background-color: ${theme.color.blueGray.light};

    :focus {
      box-shadow: none;
    }
  }
`;

interface Props extends InputProps {
  label: string;
  margin?: Margin;
  info?: Info;
  classNameContainer?: string;
}

const Password = ({ info, label, classNameContainer, margin, ...props }: Props) => {
  return (
    <InputContainer info={info} label={label} className={classNameContainer} margin={margin}>
      <Input.Password data-test={info?.name} className={cssRoot} {...props} />
    </InputContainer>
  );
};

export default Password;
