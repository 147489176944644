import moment from 'moment';

const AnnulClaims = () => {
  return [
    {
      title: '',
      value: 'claimStatus',
      key: 'claimStatus',
      width: 70,
      fixed: 'left',
    },
    {
      title: 'insured_events_list.annul_by_user_annul_claims_table',
      value: 'annulByUser',
      key: 'annulByUser',
      width: 300,
    },
    {
      title: 'insured_events_list.annul_date_annul_claims_table',
      value: 'annulDate',
      key: 'annulDate',
      width: 200,
      render: elem => moment(elem).format('DD.MM.YYYY'),
    },
    {
      title: 'insured_events_list.claim_number_annul_claims_table',
      value: 'claimNumber',
      key: 'claimNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.contract_number_annul_claims_table',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      value: 'contractNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.incident_date_annul_claims_table',
      value: 'incidentDate',
      key: 'incidentDate',
      width: 200,
    },
    {
      title: 'insured_events_list.signatory_annul_claims_table',
      value: 'signatory',
      key: 'signatory',
      width: 200,
    },
    {
      title: 'insured_events_list.insurance_object_annul_claims_table',
      value: 'insuranceObject',
      key: 'insuranceObject',
      width: 200,
    },
    {
      title: 'insured_events_list.pid_express_payment_table',
      value: 'pid',
      key: 'pid',
      width: 220,
    },
    {
      title: 'insured_events_list.risk_annul_claims_table',
      value: 'risk',
      key: 'risk',
      width: 200,
    },
    {
      title: 'insured_events_list.resolution_type_annul_claims_table',
      value: 'resolutionType',
      key: 'resolutionType',
      width: 200,
    },
    {
      title: 'insured_events_list.expected_loss_sum_annul_claims_table',
      value: 'expectedLossSum',
      key: 'expectedLossSum',
      width: 200,
    },
    {
      title: 'insured_events_list.franchise_annul_claims_table',
      value: 'franchise',
      key: 'franchise',
      width: 150,
    },
    {
      title: 'insured_events_list.payment_amount_annul_claims_table',
      value: 'paymentAmount',
      key: 'paymentAmount',
      width: 200,
    },
    {
      title: 'insured_events_list.paid_annul_claims_table',
      value: 'paid',
      key: 'paid',
      width: 200,
    },
    {
      title: 'insured_events_list.payment_date_annul_claims_table',
      value: 'paymentDate',
      key: 'paymentDate',
      width: 200,
    },
    {
      title: 'insured_events_list.claim_date_annul_claims_table',
      value: 'claimDate',
      key: 'claimDate',
      width: 200,
    },
    {
      title: '',
      value: 'actions',
      key: 'actions',
      width: 50,
    },
  ];
};

export default AnnulClaims;
