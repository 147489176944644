import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Modal, notification, Row } from 'antd';
import * as XLSX from 'xlsx';

import FormSelect from 'components/FormSelect';
import CustomBtn from 'components/CustomBtn';
import InputUpload from 'components/InputUpload';
import LoadingSpinner from '../../../../../components/uikit/LoadingSpinner';

import closeIcon from 'assets/img/layout/greyClose.svg';

import { UPLOAD_ERRORS, UploadErrors } from './uploadErrors';

import { useAsync } from 'hooks/useAsync';
import {
  getContractExport,
  getProductsData,
  getTransferConfig,
  getTransferStatus,
  sendInsuranceContracts,
  sendAutoVerification,
} from 'api/insuranceContracts';

import cx from 'classnames';
import styles from './style.module.less';

import { getPartnersList } from 'api/partners';
import {
  parseContractFields,
  unParseDateFields,
} from 'screens/InsuranceContracts/List/components/ImportModal/helpers/parseContractFields';
import { parsePersonFields } from 'screens/InsuranceContracts/List/components/ImportModal/helpers/parsePersonFields';
import { parseResponseForSelectInput } from 'screens/InsuranceContracts/List/components/ImportModal/helpers/parseResponseForSelectInput';
import { formatDateFieldsToIso } from 'screens/InsuranceContracts/List/components/ImportModal/helpers/formatDateFieldsToIso';
import { useDispatch } from 'react-redux';
import { TYPES } from 'store/partnersList/actions';
import { useTranslation } from 'react-i18next';
import { DurationInput } from '../ExportModal/DurationDate';
import { ProgressBar } from '../../../../../components/Progress/Progress';
import { fetch } from '../../../../../store/insuranceContracts/actions';

type FileType = 'contracts' | 'users';

type DateError = {
  objectIndex: number;
  errorFields: string[];
};

type DateErrors = {
  contracts: DateError[];
  persons: DateError[];
};

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  form: any;
};

const ImportModal = ({ form, isModalOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { doFetch: importContracts, status: importStatus, value: importResponse } = useAsync(sendInsuranceContracts);
  const { doFetch: autoVerification, status: autoVerificationStatus, value: autoVerificationResponse } = useAsync(
    sendAutoVerification,
  );
  const { doFetch: getProducts, value: products } = useAsync(getProductsData);
  const { doFetch: fetchAllowedContracts, value: allowedContracts, status: countStatus } = useAsync(getContractExport);
  const { doFetch: getStatus, value: transferStatus } = useAsync(getTransferStatus);

  const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue }: any = form;
  const [contractsFile, setContractsFile] = useState('');
  const [usersFile, setUsersFile] = useState('');
  const [contracts, setContracts] = useState([]);
  const [users, setUsers] = useState([]);
  const [partnersList, setPartnersList] = useState<any>([]);
  const [configurationsList, setConfigurationList] = useState<any>([]);
  const [chosenConfig, setChosenConfig] = useState<any>('');
  const [isProgressVisible, showProgress] = useState<boolean>(false);
  const [percents, setPercents] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<number>(0);
  const [isEdak, setEdak] = useState<boolean>(false);
  const [isReconciliation, setReconciliation] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [contractTransferConfigurationId, setContractTransferConfigurationId] = useState<any>(null);
  const [configurationId, setConfigurationId] = useState<number | null>(null);

  const [uploadError, setUploadError] = useState<UploadErrors>({
    contracts: null,
    users: null,
  });
  const [isImportStarted, setImportStarted] = useState(false);
  const [dateError, setDateError] = useState<DateErrors>({
    contracts: [],
    persons: [],
  });

  const fileCounter = contracts.length > 0 && users.length > 0;
  // const isEdak = chosenConfig?.includes('EDAK');

  const convertToJson = (csv, fileType: FileType) => {
    let lines = csv.split(/\n/);
    let result: any = [];
    let headers = lines[0].split(',');
    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        const parseFields = fileType === 'contracts' ? parseContractFields : parsePersonFields;
        if (headers[j] !== '') {
          const field = parseFields(headers[j]);
          if (field) {
            obj[field] =
              headers[j] === 'К-во застрахованных' && isNaN(currentline[j]) ? currentline[j + 1] : currentline[j];
          }
        }
      }
      result.push(obj);
    }
    return result; // JavaScript object
  };

  const excelParser = (file, fileType: FileType) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = event => {
      /* Parse data */
      const bstr = event?.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      // @ts-ignore
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      const result = convertToJson(data, fileType);
      fileType === 'contracts' ? setContracts(result) : setUsers(result);
      fileType === 'contracts' ? setContractsFile(file.name) : setUsersFile(file.name);
    };
  };

  const errorHandler = (file, fileType: FileType) => {
    const fileSize = Math.ceil(file.size / 1000);
    let extFile = file.name.split('.');
    extFile = extFile[extFile.length - 1];

    if (extFile === 'xls' || extFile === 'xlsx') {
      if (fileSize < 10000) {
        excelParser(file, fileType);

        setUploadError((prev: UploadErrors) => ({
          ...prev,
          [fileType]: null,
        }));
      } else {
        setUploadError((prev: UploadErrors) => ({
          ...prev,
          [fileType]: 'wrongSize',
        }));
      }
    } else {
      setUploadError((prev: UploadErrors) => ({
        ...prev,
        [fileType]: 'wrongType',
      }));
    }
  };

  const onChangePartnerId = useCallback(async (_, { props }) => {
    const partnerId = props.value;
    setFieldsValue({ partnerId: partnerId });

    await getTransferConfig({ partnerId: partnerId, processType: 'IMPORT' }).then(response => {
      const data = parseResponseForSelectInput(response.data);
      setConfigurationList(data);
      if (data.length === 0) {
        setReconciliation(false);
        setEdak(false);
      }
      if (data && data.length) {
        setTimeout(() => {
          setEdak(data[0].title.includes('EDAK'));
          setReconciliation(data[0].title.includes('Автозвірка Продажів'));
          setContractTransferConfigurationId(data[0].value);
        }, 100);
      }
    });
    setContractsFile('');
    setUsersFile('');
    setContracts([]);
    setImportStarted(false);
    showProgress(false);
  }, []);

  const onChangeDownloadType = useCallback((_, { props }) => {
    const contractTransferConfigurationId = props.value;
    const configTitle = configurationsList.find(item => item.id === contractTransferConfigurationId)?.title;
    setChosenConfig(configTitle);
    setFieldsValue({ contractTransferConfigurationId: contractTransferConfigurationId });
    setContractTransferConfigurationId(contractTransferConfigurationId);
  }, []);

  const onUploadContracts = useCallback(({ file }) => {
    setFile(file);
    errorHandler(file, 'contracts');
  }, []);

  const onUploadUsers = useCallback(({ file }) => {
    errorHandler(file, 'users');
  }, []);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    if (e.fileList.length > 1 || fileCounter) {
      e.fileList.shift();
    }

    return e && e.fileList;
  };

  const onCancel = () => {
    setDateError({
      contracts: [],
      persons: [],
    });
    setImportStarted(false);
    setContractsFile('');
    setUsersFile('');
    setContracts([]);
    setUsers([]);
    onClose();
    setReconciliation(false);
    form.resetFields();
    setConfigurationList([]);
    setFile(null);
    showProgress(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    setDateError({
      contracts: [],
      persons: [],
    });
    validateFields((err, values) => {
      showProgress(true);
      const contracts = values.contracts
        ? values.contracts
            .filter(contract => contract.startDate && contract.endDate && contract.conclusionDate && contract.birthday)
            .map((contract, i) => formatDateFieldsToIso(contract, i, unParseDateFields))
            .map(contract => {
              if (contract.dateError) {
                setDateError((prev: any) => ({
                  ...prev,
                  persons: [...prev.persons, contract.dateError],
                }));
              }
              return contract;
            })
        : null;

      const persons = values.persons
        ? values.persons
            .filter(
              person =>
                (person.startDate && person.endDate && person.birthday) ||
                (person['Дата конца действия'] && person['Дата начала действия'] && person.birthday),
            )
            .map((person, i) => formatDateFieldsToIso(person, i, unParseDateFields))
            .map(person => {
              if (person.dateError) {
                setDateError((prev: any) => ({
                  ...prev,
                  contracts: [...prev.contracts, person.dateError],
                }));
              }
              return person;
            })
        : null;

      if (!isReconciliation) {
        importContracts({
          ...values,
          startSignDate: values.startSignDate?.format('YYYY-MM-DDT00:00:00'),
          endSignDate: values.endSignDate?.format('YYYY-MM-DDT23:59:59'),
          contracts: contracts,
          persons: persons,
        });
      }

      autoVerification({
        file: file,
        configurationId: configurationId,
      });

      setFile(null);
      setImportStarted(true);
    });
  };

  const exportRequests = (event, type: string) => {
    event.preventDefault();
    const { startSignDate, endSignDate, partnerId, insuranceProductId } = form.getFieldsValue();
    const contractTransferConfigurationId = configurationsList.find(item => item.title === chosenConfig)?.value;

    const requestsParams = {
      partnerId: partnerId,
      insuranceProductId: insuranceProductId,
      contractTransferConfigurationId,
      startSignDate: startSignDate.format('YYYY-MM-DDT00:00:00'),
      endSignDate: endSignDate.format('YYYY-MM-DDT23:59:59'),
    };

    switch (type) {
      case 'GET_ALLOWED_IMPORT':
        fetchAllowedContracts(requestsParams);
        return;
    }
  };

  useEffect(() => {
    getPartnersList({}).then(response => {
      const data = parseResponseForSelectInput(response.data.resultList ?? []);
      setPartnersList(data);
      dispatch({ type: TYPES.getPartnersList, payload: { data: data } });
    });

    return () => {
      dispatch({ type: TYPES.getPartnersList, payload: { data: [] } });
    };
  }, []);

  useEffect(() => {
    if (importResponse) {
      setIntervalId(
        setInterval(() => {
          getStatus(importResponse.data.id);
        }, 1000),
      );
    }
  }, [importResponse]);

  useEffect(() => {
    if (transferStatus && transferStatus.data) {
      setPercents(
        +(((transferStatus.data.transferred ?? 0) * 100) / (transferStatus.data.countToTransfer ?? 0)).toFixed(1),
      );
    }
    if (transferStatus && (transferStatus?.data.status === 'LOADED' || transferStatus?.data.status === 'ERROR')) {
      const resStatus = transferStatus.data.status === 'ERROR';
      notification[resStatus ? 'error' : 'info']({
        message: resStatus ? t('modal.something_went_wrong_documents') : '',
        description: `${t(
          transferStatus.data.status === 'ERROR'
            ? 'insurance_contracts.exported_contracts_import_error'
            : 'insurance_contracts.exported_contracts_import_success',
        )}`,
      });
      dispatch(fetch(t));
      clearInterval(intervalId);
    }
  }, [transferStatus]);

  // useEffect(() => {
  //   if (partnersList.length) {
  //     getTransferConfig({ partnerId: partnersList[0].key, processType: 'IMPORT' }).then(response => {
  //       const data = parseResponseForSelectInput(response.data);
  //       setConfigurationList(data);
  //     });
  //   }
  // }, [partnersList]);

  useEffect(() => {
    if (configurationsList && configurationsList.length) {
      setChosenConfig(configurationsList[0].title);
    }
  }, [configurationsList]);

  useEffect(() => {
    if (configurationsList && configurationsList.length) {
      const currenctConfig = configurationsList.find(item => item.value === contractTransferConfigurationId);
      setEdak(currenctConfig?.title.includes('EDAK'));
      setReconciliation(currenctConfig?.title.includes('Автозвірка Продажів'));
      if (currenctConfig?.value) {
        setConfigurationId(currenctConfig.value);
      }
    }
  }, [contractTransferConfigurationId, configurationsList]);

  useEffect(() => {
    if (autoVerificationStatus === 'success') {
      setDateError({
        contracts: [],
        persons: [],
      });
      setImportStarted(false);
      setContractsFile('');
      setUsersFile('');
      setContracts([]);
      setUsers([]);
      onClose();
      showProgress(false);
      notification.success({
        message: t('insurance_contracts.import_success'),
      });
      setReconciliation(false);
      setConfigurationList([]);
      form.resetFields();
    }
  }, [autoVerificationStatus]);

  useEffect(() => {
    if (autoVerificationResponse && autoVerificationResponse.data.status === 'LOADED') {
      setPercents(100);
    }
  }, [autoVerificationResponse]);

  useEffect(() => {
    const partnerId = form.getFieldValue('partnerId');
    if (partnerId) {
      getProducts(partnerId);
    }
  }, [form.getFieldValue('partnerId')]);

  const importBtnDisable = () => {
    if (isReconciliation && file !== null && file.size <= 10000000) {
      return false;
    }
    if (isEdak && allowedContracts) {
      return !allowedContracts.data.count;
    }
    return !fileCounter || importStatus === 'pending' || !getFieldValue('contractTransferConfigurationId');
  };

  return (
    <Modal
      centered
      footer={null}
      closable={false}
      visible={isModalOpen}
      className={cx(styles.container)}
      onCancel={onClose}
      width={800}
    >
      <div className={cx(styles.ModalContent)}>
        <div className={styles.ModalHeader}>
          <h2 className={styles.ModalTitle}>{t('insurance_contracts.data_import_import')}</h2>
          <div className={styles.Close}>
            <div className={cx(styles.CloseIcon)} onClick={onClose}>
              <img src={closeIcon} width="16" height="16" alt="close icon" />
            </div>
          </div>
        </div>
        <div className="content">
          <Form onSubmit={onSubmit}>
            {/* HEADER START */}
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item>
                  {getFieldDecorator('partnerId', {
                    initialValue: partnersList?.[0]?.value,
                  })(
                    <FormSelect
                      selectDataId="partners"
                      optionDataId="partner"
                      title={t('insurance_contracts.partner_import')}
                      options={partnersList}
                      onChange={onChangePartnerId}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item>
                  {getFieldDecorator('contractTransferConfigurationId', {
                    initialValue: configurationsList?.[0]?.value,
                  })(
                    <FormSelect
                      selectDataId="downloadTypes"
                      optionDataId="downloadType"
                      title={t('insurance_contracts.download_type_import')}
                      options={configurationsList}
                      onChange={onChangeDownloadType}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {/* HEADER END */}

            {isEdak ? (
              <Row>
                <Col span={11}>
                  <Form.Item>
                    {getFieldDecorator('insuranceProductId', {
                      initialValue: parseResponseForSelectInput(products?.data.resultList ?? [])?.[0]?.value,
                    })(
                      <FormSelect
                        selectDataId="products"
                        optionDataId="product"
                        title={t('insurance_contracts.product_import')}
                        options={parseResponseForSelectInput(products?.data.resultList ?? [])}
                        // onChange={onChangeDownloadType}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                  <DurationInput
                    form={form}
                    exportRequests={exportRequests}
                    countStatus={countStatus}
                    mode={'IMPORT'}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <h3>{t('insurance_contracts.files_to_download_import')}</h3>
                <Form.Item>
                  {getFieldDecorator('contracts', {
                    initialValue: [...contracts],
                    valuePropName: 'contracts',
                    getValueFromEvent: normFile,
                  })(
                    <InputUpload
                      customRequest={onUploadContracts}
                      showUploadList={false}
                      accept=".xls, .xlsx"
                      uploadDataId="contracts"
                    >
                      <div className={styles.Uploads}>
                        <CustomBtn theme="third">{t('insurance_contracts.select_contract_file_import')}</CustomBtn>
                        <span className={styles.FileCounter}>
                          {' '}
                          {`${contracts.length > 0 ? '1' : '0'} ${t('insurance_contracts.file_import')}${
                            contracts.length > 0 ? '' : 'ів'
                          } ${t('insurance_contracts.selected_import')}`}
                        </span>
                      </div>
                      {uploadError.contracts && (
                        <div className={styles.UploadError}>{UPLOAD_ERRORS[uploadError.contracts]}</div>
                      )}
                    </InputUpload>,
                  )}
                </Form.Item>
                {isReconciliation === false && (
                  <Form.Item>
                    {getFieldDecorator('persons', {
                      initialValue: [...users],
                      valuePropName: 'persons',
                      getValueFromEvent: normFile,
                    })(
                      <InputUpload
                        customRequest={onUploadUsers}
                        showUploadList={false}
                        accept=".xls, .xlsx"
                        uploadDataId="users"
                      >
                        <div className={styles.Uploads}>
                          <CustomBtn theme="third">
                            {t('insurance_contracts.select_the_file_of_insured_import')}
                          </CustomBtn>
                          <span className={styles.FileCounter}>
                            {' '}
                            {`${users.length > 0 ? '1' : '0'} ${t('insurance_contracts.file_import')}${
                              users.length > 0 ? '' : 'ів'
                            } ${t('insurance_contracts.selected_import')}`}
                          </span>
                        </div>
                        {uploadError.users && (
                          <div className={styles.UploadError}>{UPLOAD_ERRORS[uploadError.users]}</div>
                        )}
                      </InputUpload>,
                    )}
                  </Form.Item>
                )}
              </Row>
            )}

            {/* BUTTOND START */}
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <CustomBtn theme="third" minWidth="block" size="large" onClick={onCancel}>
                  {t('insurance_contracts.cancel_button_import')}
                </CustomBtn>
              </Col>
              <Col span={11}>
                <CustomBtn theme="forth" minWidth="block" size="large" disabled={importBtnDisable()} type="submit">
                  {t('insurance_contracts.import_button_import')}
                </CustomBtn>
              </Col>
            </Row>
            {/* BUTTOND END */}

            {/* BOX START */}
            <Row>
              <div className={styles.Screen}>
                <div className={styles.FindElements}>
                  <span>{t('insurance_contracts.preparation_for_import_import')}</span>
                  {allowedContracts?.data.count && <span>Знайдено {allowedContracts?.data.count} звернень</span>}
                </div>
                <div>{t('insurance_contracts.download_files_import')}</div>
                <ul className={styles.FileLoading}>
                  {contractsFile && (
                    <>
                      <li>
                        <em>{contractsFile}</em>
                      </li>
                      <li>{t('insurance_contracts.downloaded_import')}</li>
                    </>
                  )}
                  {usersFile && (
                    <>
                      <li>
                        <em>{usersFile}</em>
                      </li>
                      <li>{t('insurance_contracts.downloaded_import')}</li>
                    </>
                  )}
                </ul>
                {(dateError.contracts.length > 0 || dateError.persons.length > 0) && (
                  <>
                    <div className={styles.DateErrorsTitle}>
                      {t('insurance_contracts.invalid_date_in_files_import')}
                    </div>
                    <div className={styles.DateErrors}>
                      {dateError?.contracts &&
                        dateError.contracts.map((contractError, i) =>
                          i === 0 ? (
                            <div key={contractError.objectIndex}>
                              <div className={styles.DateErrorsSubtitle}>
                                <b>{t('insurance_contracts.register_of_agreements_import')}</b>
                              </div>
                              <div>
                                {t('insurance_contracts.line_import')}
                                {contractError.objectIndex}, {t('insurance_contracts.fields_import')}{' '}
                                {contractError.errorFields.join(', ')}
                              </div>
                            </div>
                          ) : (
                            <div key={contractError.objectIndex}>
                              {t('insurance_contracts.line_import')}
                              {contractError.objectIndex}, {t('insurance_contracts.fields_import')}{' '}
                              {contractError.errorFields.join(', ')}
                            </div>
                          ),
                        )}
                      {dateError?.persons &&
                        dateError.persons.map((personError, i) =>
                          i === 0 ? (
                            <div key={personError.objectIndex}>
                              <div className={styles.DateErrorsSubtitle}>
                                <b>{t('insurance_contracts.register_of_insured_import')}</b>
                              </div>
                              <div>
                                {t('insurance_contracts.line_import')}
                                {personError.objectIndex}, {t('insurance_contracts.fields_import')}{' '}
                                {personError.errorFields.join(', ')}
                              </div>
                            </div>
                          ) : (
                            <div key={personError.objectIndex}>
                              {t('insurance_contracts.line_import')}
                              {personError.objectIndex}, {t('insurance_contracts.fields_import')}{' '}
                              {personError.errorFields.join(', ')}
                            </div>
                          ),
                        )}
                    </div>
                  </>
                )}
                {isImportStarted && (
                  <>
                    {(importStatus === 'pending' || importStatus === 'success') && (
                      <div>{t('insurance_contracts.imports_import')}</div>
                    )}
                    {importStatus === 'error' && (
                      <>
                        <div className={styles.Error}>{t('insurance_contracts.file_verification_failed_import')}</div>
                      </>
                    )}
                    {importStatus === 'success' && (
                      <>
                        <div>
                          {t('insurance_contracts.Imported_import')}{' '}
                          {transferStatus && (transferStatus.data.transferred ?? 0) > 0
                            ? transferStatus.data.transferred
                            : 0}
                        </div>
                        <div>
                          {t('insurance_contracts.not_imported_import')}{' '}
                          {transferStatus
                            ? (transferStatus.data.countToTransfer ?? 0) - (transferStatus.data.transferred ?? 0)
                            : ''}
                        </div>
                      </>
                    )}
                  </>
                )}
                {isProgressVisible && (
                  <>
                    <div className={styles.Spinner}>
                      <LoadingSpinner />
                    </div>
                    <ProgressBar value={percents} />
                  </>
                )}
              </div>
            </Row>
            {/* BOX END */}
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default Form.create<any>({ name: 'importModal' })(ImportModal);
