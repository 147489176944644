import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Icon } from 'antd';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Typography, Checkbox, notification } from 'antd';
import * as auth from 'api/auth';
import { FormComponentProps } from 'antd/lib/form';
import { Select } from 'antd';
import Header from '../components/header';
import { getCurrentUser } from 'store/user/actions';
import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import styles from '../auth.module.less';
import { Box } from '@material-ui/core';
import ResendSmsCode from '../ManagerSignUp/widgets/ResendSmsCode';
import arrowLeft from '../../../assets/img/layout/arrowLeft.svg';
import sign from '../SignUp/SignUp.module.less';
import { checkInsuranceCode } from 'api/auth';
import { checkOnlineStatus, languages } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
// const autofillEnvs: string[] = ['https://insk.chisw.us', 'https://insk-prod.chisw.us', 'https://insk-dev3.chisw.us'];

const LoginForm = (props: FormComponentProps) => {
  const dispatch = useDispatch();
  const { getFieldDecorator } = props.form;
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [showPassword, toggleShowPassword] = useState(false);
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState<string>('');
  const currentICLanguage = localStorage.getItem('language');
  const { id: insCode } = useParams();
  const { t } = useTranslation();

  const [fieldsActiveState, setFieldsActiveState] = useState({
    username: false,
    password: false,
    otp: false,
  });

  const [isClient, setIsClient] = useState(false);
  const [fieldsValue, setFieldsValue] = useState({
    username: '',
    password: '',
    otp: '',
  });
  const { Option } = Select;

  useEffect(() => {
    if (currentICLanguage) {
      setCurrentSelectedLanguage(currentICLanguage);
    } else {
      setCurrentSelectedLanguage(i18n.language);
    }
  }, [currentICLanguage]);

  const getValidation = code => {
    checkInsuranceCode(code)
      .then(_ => {
        localStorage.setItem('companyCode', code);
      })
      .catch(_ => {
        history.push('/404');
      });
  };

  useEffect(() => {
    if (insCode && !isClient) {
      getValidation(insCode);
    }
  }, [insCode]);

  useEffect(() => {
    setIsClient(!!insCode);
  }, [insCode]);

  const handleFields = (eventName: string, fieldName: string) => {
    if (!!props.form.getFieldValue(fieldName)) {
      return;
    } else {
      if (eventName === 'focus') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: true });
      }
      if (eventName === 'blur') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: false });
      }
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError(false);
    props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const response = await auth.login({ ...values, password: values.password.trim() });
          localStorage.setItem('accessToken', response.data.access_token);
          await dispatch(getCurrentUser());
          history.push(ROUTES.DASHBOARD.ROOT);
        } catch (e) {
          handleErrors(e);
        }
      }
    });
  };

  const handleErrors = e => {
    if (e?.response?.data?.errors) {
      return e?.response?.data?.errors.forEach(error => {
        if (error.code === 'USER_INACTIVE') {
          return notification.error({
            message: t('log_in.user_inactive'),
          });
        } else if (error.code === 'USER_ONLY_CLIENT') {
          return notification.error({
            message: t('log_in.user_only_client'),
          });
        } else if (error.code === 'USER_NOT_CLIENT') {
          return notification.error({
            message: t('log_in.user_not_client'),
          });
        } else {
          setError(true);
        }
      });
    }
    setError(true);
  };

  const sendSms = values => {
    auth
      .login2fa(values)
      .then(response => {
        const id = response.data.identity;
        setFieldsValue({ ...values, id });
        setStep(1);
      })
      .catch(e => handleErrors(e));
  };

  const resendSms = () => {
    const { password, username } = fieldsValue;
    sendSms({ password, username });
  };

  // TODO: later, add login for customers

  const handle2faSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError(false);
    props.form.validateFields((err, values) => {
      if (!err) {
        if (step === 0) {
          if (insCode) {
            localStorage.setItem('companyCode', insCode);
          }
          sendSms({ ...values, password: values.password.trim() });
        }
        if (step === 1) {
          auth
            .login2faCheck({ ...fieldsValue, ...values })
            .then(async response => {
              localStorage.setItem('accessToken', response.data.access_token);
              await dispatch(getCurrentUser());
              history.push(ROUTES.DASHBOARD.ROOT);
            })
            .catch(e => handleErrors(e));
        }
      }
    });
  };

  const redirectToPassRecovery = () => {
    const path = insCode
      ? ROUTES.AUTH.PASSWORD_RECOVERY.replace(':id', `${insCode}`)
      : ROUTES.PARTNER_AUTH.PASSWORD_RECOVERY;
    history.push(path);
  };

  const redirectToSignUp = () => {
    const route = isClient ? ROUTES.AUTH.INSURER_SIGNUP : ROUTES.AUTH.MANAGER_SIGNUP;
    history.push(insCode ? route.replace(':id', `${insCode}`) : route);
  };

  const redirectToPartner = () => {
    localStorage.removeItem('companyCode');
    history.push(ROUTES.PARTNER_AUTH.LOGIN);
  };
  const changeProjectLanguage = (lng: string) => {
    setCurrentSelectedLanguage(lng);
    localStorage.setItem('language', lng);
    i18n.changeLanguage(String(lng)).catch(err => console.log(err));
  };

  return (
    <div className={styles.authWrapper}>
      <Header />
      <div className={styles.formContainer}>
        <Form onSubmit={isClient ? handle2faSubmit : handleSubmit} className="login-form">
          {step === 1 && <Typography className={styles.green_text}>{t('log_in.step')} 2/2</Typography>}
          {step === 1 && (
            <Typography.Title style={{ marginTop: '10px' }} level={3}>
              {t('log_in.code_from_sms')}
            </Typography.Title>
          )}
          {step === 0 && <Typography.Title level={3}>{t('log_in.title')}</Typography.Title>}

          {step === 0 && (
            <>
              <div className={styles.lngBlock}>
                <p>{isClient ? t('log_in.as_a_customer') : t('log_in.as_a_partner')}</p>
                <Select
                  value={currentSelectedLanguage}
                  dropdownClassName={styles.customDropdown}
                  onChange={changeProjectLanguage}
                >
                  {languages.map(
                    item =>
                      currentSelectedLanguage !== item.value && (
                        <Option key={item.value}>
                          <span className={styles.firstSymbols}> {item.value}</span>
                        </Option>
                      ),
                  )}
                </Select>
              </div>
              <Box m={1} />
              <Form.Item
                {...(error && {
                  validateStatus: 'error',
                  help: checkOnlineStatus(t('log_in.check_the_data')),
                })}
              >
                {getFieldDecorator('username', {
                  rules: [
                    {
                      required: true,
                      message: checkOnlineStatus(t('log_in.enter_email_or_phone')),
                    },
                    { min: 3, message: checkOnlineStatus(t('log_in.enter_email_or_phone')) },
                  ],
                  validateFirst: true,
                })(
                  <Input
                    autoComplete="off"
                    size="large"
                    onFocus={e => handleFields(e.type, 'username')}
                    onBlur={e => handleFields(e.type, 'username')}
                    type={'text'}
                    data-id="login_username"
                  />,
                )}
                <div className={classNames(styles.inputLabel, { [styles.labelActive]: fieldsActiveState.username })}>
                  {t('log_in.your_login')}
                </div>
              </Form.Item>
              <Form.Item
                className={classNames(styles.inputPassword, {
                  [styles.visible]: showPassword,
                  [styles.invisible]: !showPassword,
                })}
                {...(error && {
                  validateStatus: 'error',
                  help: checkOnlineStatus(t('log_in.check_the_data')),
                })}
              >
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: checkOnlineStatus(t('log_in.enter_password')) }],
                })(
                  <Input
                    name="some"
                    size="large"
                    autoComplete="off"
                    onFocus={e => handleFields(e.type, 'password')}
                    onBlur={e => handleFields(e.type, 'password')}
                    type={showPassword ? 'text' : 'password'}
                    data-id="login_password"
                    suffix={
                      <div onClick={() => toggleShowPassword(!showPassword)}>
                        <Icon type={showPassword ? 'eye' : 'eye-invisible'} />
                      </div>
                    }
                  />,
                )}
                <div className={classNames(styles.inputLabel, { [styles.labelActive]: fieldsActiveState.password })}>
                  {t('log_in.password')}
                </div>
              </Form.Item>
              <Form.Item>
                <div className={styles.userPasswordControls__container}>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(<Checkbox>{t('log_in.remember_me')}</Checkbox>)}
                  <span className={styles.passRecovery} onClick={redirectToPassRecovery}>
                    {t('log_in.forgot_password')}
                  </span>
                </div>
              </Form.Item>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  data-id={step === 0 && isClient ? 'Continue_Button' : 'SignIn_Button'}
                >
                  {step === 0 && isClient ? t('log_in.next') : t('log_in.log_in')}
                </Button>
              </Form.Item>
            </>
          )}

          {step === 1 && (
            <>
              <Typography.Text className={sign.notification_text} type="secondary">
                {t('log_in.sent_verification_code')}
              </Typography.Text>
              <Box m={5} />
              <Form.Item>
                {getFieldDecorator('username', {
                  rules: [
                    {
                      required: true,
                      message: checkOnlineStatus(t('log_in.enter_email_or_phone')),
                    },
                    { min: 3, message: checkOnlineStatus(t('log_in.enter_email_or_phone')) },
                  ],
                  validateFirst: true,
                  initialValue: fieldsValue.username,
                })(
                  <Input
                    autoComplete="off"
                    size="large"
                    disabled
                    onFocus={e => handleFields(e.type, 'username')}
                    onBlur={e => handleFields(e.type, 'username')}
                    type={'text'}
                    suffix={
                      <button onClick={() => setStep(0)} className={classNames(styles.zero_margins, styles.green_text)}>
                        {t('log_in.change')}
                      </button>
                    }
                  />,
                )}
                <div className={classNames(styles.inputLabel, { [styles.labelActive]: fieldsActiveState.username })}>
                  {t('log_in.your_login')}
                </div>
              </Form.Item>

              <Form.Item
                {...(error && {
                  validateStatus: 'error',
                  help: checkOnlineStatus(t('log_in.invalid_code')),
                })}
              >
                {getFieldDecorator('otp', {
                  rules: [{ required: true, message: checkOnlineStatus(t('log_in.enter_code')) }],
                })(
                  <Input
                    size="large"
                    onFocus={e => handleFields(e.type, 'otp')}
                    onBlur={e => handleFields(e.type, 'otp')}
                  />,
                )}
                <div className={classNames(styles.inputLabel, { [styles.labelActive]: fieldsActiveState.otp })}>
                  {t('log_in.code')}
                </div>
              </Form.Item>

              <ResendSmsCode sendAction={resendSms} zeroPadding />

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  data-id="SignIn_Button"
                >
                  {t('log_in.log_in')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
        {step > 0 ? (
          <div className={styles.backButton}>
            <Button
              style={{ padding: 0, display: 'flex', alignItems: 'center' }}
              type="link"
              ghost
              onClick={() => setStep(0)}
            >
              <img className={styles.leftIcon} src={arrowLeft} alt="right icon" />
              <Typography.Text className={classNames(styles.small_text, styles.gray_text)}>
                {t('log_in.return')}
              </Typography.Text>
            </Button>
          </div>
        ) : (
          <div className={styles.registration}>
            {t('log_in.no_account')}
            <span onClick={redirectToSignUp} className={styles.link}>
              &nbsp;{t('log_in.register')}
            </span>
            <div>
              {isClient && (
                <span className={styles.link} onClick={redirectToPartner}>
                  {t('log_in.as_a_partner')}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form.create({ name: 'login' })(LoginForm);
