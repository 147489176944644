import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import Messages from '../Messages';
import MessageInput from '../MessageInput';
import WarningPopup from '../WarningPopup';
import Typography from 'components/Typography';
import { useDOMSize, useIsOnline } from '../../hooks';
import { ClockIcon, AudioIcon, VideoIcon } from 'components/Icons';
import { Chat as ChatInterface, MeetingType } from 'types/chats';
import { useChat } from 'api/chats';

import styles from './Chat.module.less';

interface ChatProps {
  meeting: boolean;
  chat?: ChatInterface;
  onStartMeeting(meeting: MeetingType): void;
  startMeeting?: boolean;
}

const Chat: React.FC<ChatProps> = ({ meeting, chat, onStartMeeting, startMeeting }) => {
  const { t } = useTranslation();

  const { history, sendMessage, isPending, uuid } = useChat(chat);

  const isOnline = useIsOnline();

  const [isWarningPopup, setIsWarningPopup] = useState(false);

  const { size, ref } = useDOMSize<HTMLDivElement>();

  const HEIGHT_OFFSET = 139;

  useEffect(() => {
    if (isWarningPopup) {
      setIsWarningPopup(false);
    }
  }, [chat]);

  const handleCloseWarning = () => {
    setIsWarningPopup(false);
  };

  const handleCall = (meetingType: MeetingType) => {
    if (!chat) {
      return;
    }
    const current = Date.now();
    const startTime = new Date(chat.consultationDate).getTime();
    const endTime = startTime + Number(chat.duration ? Number(chat.duration) * 60000 : chat.consultationDuration);

    if (chat && current >= startTime && current <= endTime) {
      return onStartMeeting(meetingType);
    } else {
      setIsWarningPopup(true);
    }
  };

  const handleVideoCallStart = () => {
    handleCall(MeetingType.Video);
  };

  const handleAudioCallStart = () => {
    handleCall(MeetingType.Audio);
  };

  if (startMeeting && meeting) {
    handleCall(MeetingType.Video);
  }

  const headerNode = chat && (
    <header className={styles.chatHeader}>
      <div className={styles.doctorName}>
        <Typography type="title">
          {chat.doctorType}
          {' - '}
          {moment(chat.consultationDate).format('D MMMM')}
        </Typography>
        <p>
          {t('chats.start_at')} {moment(chat.consultationDate).format('kk:mm')}
        </p>
      </div>
      <div className={styles.meetingControls}>
        <VideoIcon onClick={handleVideoCallStart} />
        <AudioIcon onClick={handleAudioCallStart} />
      </div>
    </header>
  );

  const adviceNode = !meeting && (!isOnline || !chat) && (
    <div className={classNames(styles.advice, !isOnline && styles.adviceDisconnected)}>
      {isOnline ? t('chats.selector_chat') : t('chats.disconnected')}
    </div>
  );

  const warningPopupNode = isWarningPopup && size && !meeting && (
    <WarningPopup
      onClose={handleCloseWarning}
      width={size.width}
      height={size.height}
      icon={ClockIcon}
      title={t('chats.time_hasnt_come')}
      body={t('chats.you_cant_call')}
    />
  );

  return (
    <>
      {warningPopupNode}
      <div ref={ref} className={styles.chatOuter}>
        {adviceNode}
        {chat && (
          <>
            {!meeting && headerNode}
            {size && !isPending && (
              <Messages
                height={size.height - HEIGHT_OFFSET}
                uuid={uuid}
                history={history}
                companionName={chat.doctorType}
              />
            )}
            <MessageInput onSend={sendMessage} />
          </>
        )}
      </div>
    </>
  );
};

export default Chat;
