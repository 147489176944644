const ReinsuranceColumns = t => {
  return [
    {
      title: t('contract_details.reinsurer_table'),
      dataIndex: 'reinsurer',
      key: 'reinsurer',
      width: 180,
    },
    {
      title: t('contract_details.case_number_table'),
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      width: 150,
    },
    {
      title: t('contract_details.date_case_table'),
      dataIndex: 'dateCase',
      key: 'dateCase',
      width: 150,
    },
    {
      title: t('contract_details.bordero_number_table'),
      dataIndex: 'borderoNumber',
      key: 'borderoNumber',
      width: 150,
    },
    {
      title: t('contract_details.date_bordero_table'),
      dataIndex: 'dateBordero',
      key: 'dateBordero',
      width: 150,
    },
    {
      title: t('contract_details.object_case_table'),
      dataIndex: 'objectCase',
      key: 'objectCase',
      width: 150,
    },
    {
      title: t('contract_details.insurance_risk_table'),
      dataIndex: 'insuranceRisk',
      key: 'insuranceRisk',
      width: 180,
    },
    {
      title: t('contract_details.responsibility_table'),
      dataIndex: 'responsibility',
      key: 'responsibility',
      width: 200,
    },
    {
      title: t('contract_details.franchise_reinsurance_table'),
      dataIndex: 'franchise',
      key: 'franchise',
      width: 150,
    },
    {
      title: t('contract_details.countOf_payments_table'),
      dataIndex: 'countOfPayments',
      key: 'countOfPayments',
      width: 150,
    },
  ];
};
export default ReinsuranceColumns;
