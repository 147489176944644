import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { getTypes } from 'store/brands/actions';

import DeleteBrand from './components/DeleteBrand';
import EditBrand from './components/EditBrand';
import AddBrand from './components/AddBrand';

import { IBrandInfo } from './interfaces/modalBrands.interfaces';

const BrandsInfo = ({ success, data, isOpen, onClose, error, chosenTab, brandAction }: IBrandInfo) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTypes(chosenTab));
  }, [chosenTab]);

  return (
    <Modal visible={isOpen} centered onCancel={onClose} footer={null} destroyOnClose={true}>
      {brandAction === 'editBrand' && (
        <EditBrand
          onClose={onClose}
          success={success}
          buttonText={t('brands_page.save_changes')}
          error={error}
          data={data}
          chosenTab={chosenTab}
        />
      )}

      {brandAction === 'addBrand' && (
        <AddBrand onClose={onClose} success={success} error={error} chosenTab={chosenTab} />
      )}

      {brandAction === 'deleteBrand' && (
        <DeleteBrand
          submitText={t('brands_page.yes')}
          closeText={t('brands_page.no')}
          onClose={onClose}
          success={success}
          data={data}
        />
      )}
    </Modal>
  );
};

export default BrandsInfo;
