import React, { useEffect, useState } from 'react';
import { Checkbox, Button, Tooltip } from 'antd';
import { Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Form from 'antd/es/form';

import styles from './InsurerInfo.module.less';
import { detail, link, title } from './utils';

interface IInsurerInfo {
  setRussianCitizen: (russianCitizen: boolean) => void;
}

const InsurerInfo: React.FC<IInsurerInfo & FormComponentProps> = ({ form, setRussianCitizen }) => {
  const { getFieldDecorator, getFieldValue } = form;

  const russianCitizen = getFieldValue('russianCitizen');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setRussianCitizen(russianCitizen);
  }, [russianCitizen]);

  const onHandleOpen = () => {
    setIsOpen(true);
  };

  const onHandleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.insurerInfo}>
      <Tooltip
        placement="leftBottom"
        title={'Постановою КМУ №187від 03.03.22р. ЗАБОРОНЕНО будь які виплати на користь громадян та компаній РФ'}
      >
        <div className={styles.header}>
          <div>
            {getFieldDecorator('russianCitizen', {
              initialValue: true,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </div>
          <div className={styles.title}>{title}</div>
        </div>
      </Tooltip>

      {!isOpen && (
        <div className={styles.showMore}>
          <Button onClick={onHandleOpen} type="ghost">
            Детальніше
            <Icon type="down" />
          </Button>
        </div>
      )}

      <div className={`${styles.details} ${isOpen ? styles.open : ''}`}>
        {isOpen && (
          <>
            <div>
              <a target="_blanck" href={'https://zakon.rada.gov.ua/laws/show/187-2022-%D0%BF#Text'}>
                {link}
              </a>
            </div>
            {detail}
          </>
        )}
      </div>
      {isOpen && (
        <div className={styles.upIcon} onClick={onHandleClose}>
          <Icon type="up" />
        </div>
      )}
    </div>
  );
};

export default Form.create<IInsurerInfo & FormComponentProps>({ name: 'insurerInfo' })(InsurerInfo);
