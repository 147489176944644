import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import * as api from 'api/auth';
import Password from 'components/next/Password/Password';
import Button from 'components/next/Button/Button';
import { checkOnlineStatus } from 'utils/helpers';
import { ROUTES } from 'constants/routes';
import { UseHistory } from 'types';
import { useRequired } from 'hooks/useRequired';
import { theme } from 'styles';

import { schema, defaultValues, Values } from './ConfirmPassword.schema';
import { State } from '../PasswordRecovery';
import CountDown from '../CountDown/CountDown';

const cssSubmit = css`
  margin-top: 24px;
  width: 150px;
`;
const cssInfo = css`
  color: ${theme.color.gray.light};
`;

const COUNTDOWN_MS = 5 * 60 * 1000 - 1 * 1000;

type Props = {
  state: State;
};

const ConfirmPassword = ({ state }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_MS);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { required } = useRequired(schema);

  const onSubmit = (values: Values) => {
    setLoading(true);

    api
      .resetPasswordUsingPost({
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
        token: state.token.toString(),
      })
      .then(() => {
        history.push(ROUTES.PARTNER_AUTH.LOGIN);
        // #TODO add wrapper
        notification.success({
          message: checkOnlineStatus(t('passwordRecovery.your_password_has_been_changed')),
        });
      })
      .catch(() => {
        notification.error({
          message: 'Smth went wrong',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CountDown date={Date.now() + countdown} onTick={() => setCountdown(countdown - 1 * 1000)} />

      <form onSubmit={handleSubmit(onSubmit)} data-test="password-recovery-confirm-form">
        <Controller
          name="newPassword"
          control={control}
          render={({ onChange, value, name }) => (
            <Password
              info={{ name, errors, required }}
              value={value}
              onChange={onChange}
              label={t('passwordRecovery.new_password')}
            />
          )}
        />

        <Controller
          name="confirmNewPassword"
          control={control}
          render={({ onChange, value, name }) => (
            <Password
              info={{ name, errors, required }}
              value={value}
              onChange={onChange}
              label={t('passwordRecovery.duplicate_new_password')}
            />
          )}
        />

        <div className={cssInfo}>* {t('passwordRecovery.password_must_contain_at_least_8_characters')}</div>

        <Button type="primary" size="large" htmlType="submit" loading={loading} className={cssSubmit}>
          {t('passwordRecovery.save')}
        </Button>
      </form>
    </>
  );
};

export default ConfirmPassword;
