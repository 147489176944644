import React from 'react';
import styles from './styles';
import cs from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';
import { useTranslation } from 'react-i18next';

export interface ISubmitButtonProps extends ExtendButtonBase<ButtonTypeMap> {
  classes?: any;
  className?: string;
}

const SubmitButton: React.FC<ISubmitButtonProps> = ({ classes, children, className, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button {...props} className={cs(classes.root, className)}>
      {children || t('users_activities.install_button')}
    </Button>
  );
};
//@ts-ignore
export default withStyles(styles)(SubmitButton);
