import { notification } from 'antd';
import { createTypes } from 'redux-compose-reducer';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  createPartner,
  createProgram,
  createSubdivision,
  editAgent,
  editProgram,
  editSubdivision,
  getAgentsData,
  getInsuranceCompanies,
  getInsuranceProgramsModal,
  getInsuranceProgramsTable,
  getPartnerData,
  getPartnerOptionsTabData,
  getPartnersTypes,
  getProgramsData,
  getSudivisionsList,
  removeAgent,
  sendAgent,
  updatePartner,
  updatePartnerOptionsTabData,
  getRegionsData,
  createRegion,
  updateRegion,
  removeRegion,
  getInternetSalesData,
  removeInternetSales,
  getCompaniesInternetSales,
  getSubdivisionInternetSales,
  createInternetSalesToken,
} from 'api/partnerCreate';
import { IPartner } from 'screens/PartnerCreation/components/PartnerForm/types';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import { IAgent } from 'screens/PartnerCreation/components/Table/types';
import { StoreType } from 'types/store';
import { IPartnerOptionsTab, IPartnerRegionsTab, IProgram } from './types';

type ThunkResult = ThunkAction<void, StoreType, null, Action>;
type IAction<A> = (data: A, t) => ThunkResult;
type IUpdateAction<A, B> = (data: A, params: B, t) => ThunkResult;

export const TYPES = createTypes('partnersCreate', [
  'getInsuranceCompanies',
  'getPartnersTypes',
  'getPartnersData',
  'getPartnersOptionsTabData',
  'getPartnersRegionsTabData',
  'getPartnersInternetSalesTabData',
  'getAgentsData',
  'resetPartnerData',
  'getInsPrograms',
  'getProgram',
  'getInsProgramsModal',
  'resetProgram',
  'getRegions',
  'getSubDivisions',
  'setSorting',
  'setOptions',
  'getCompaniesInternetSales',
  'getSubdivisionInternetSales',
]);

const getValidationText = (data, t) => {
  if (data === 'name') {
    return t('popup.name');
  }
  if (data === 'departmentNumber') {
    return t('popup.prefix');
  }
};

export const getCompanies = (t): ThunkResult => async dispatch => {
  try {
    const { data } = await getInsuranceCompanies();
    dispatch({ type: TYPES.getInsuranceCompanies, payload: { data } });
  } catch (error) {
    notification.error({
      message: t('popup.getting_insurance_companies'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getTypes = (t): ThunkResult => async dispatch => {
  try {
    const { data } = await getPartnersTypes();
    dispatch({ type: TYPES.getPartnersTypes, payload: { data } });
  } catch (error) {
    notification.error({
      message: t('popup.getting_types'),
      description: t('popup.try_again_products'),
    });
  }
};

export const sendPartner = (id: number, data: IPartner, type: 'CREATE' | 'UPDATE', t) => async (): Promise<any> => {
  try {
    const res = type === 'CREATE' ? await createPartner(data) : await updatePartner(id, data);
    notification.success({
      message: t('popup.save'),
    });
    if (type === 'CREATE' && res && res.data) {
      history.push(ROUTES.PARTNERS_LIST.READ.ROOT.replace(':partnerId', res.data.id));
    }
  } catch (e) {
    console.log('e: ', e);
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors.forEach(item => {
        if (item.code === 'NOT_UNIQUE') {
          notification.error({
            message: item.field === 'code' && t('popup.partner_code_already_exists'),
          });
        }
      });
    }
    notification.error({
      message: t('popup.save_error'),
    });
  }
};

export const getData = (partnerId, t): ThunkResult => async dispatch => {
  try {
    const { data } = await getPartnerData(partnerId);
    dispatch({ type: TYPES.getPartnersData, payload: { data } });
  } catch (e) {
    notification.error({
      message: t('popup.retrieving_data'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setOptionsFieldValue = (name, value) => {
  return dispatch => {
    dispatch({
      type: TYPES.setOptions,
      payload: {
        name,
        value,
      },
    });
  };
};

export const sendOptionsTabData = (id: number, data: IPartnerOptionsTab, t) => async (): Promise<any> => {
  try {
    await updatePartnerOptionsTabData(id, data);
  } catch (e) {
    notification.error({
      message: t('popup.retrieving_data'),
      description: t('popup.try_again_products'),
    });
  }
};

export const sendRegionsData = (id: number, data: IPartnerRegionsTab[], t, type: string, regionId?: number) => async (
  dispatch,
): Promise<any> => {
  try {
    type === 'CREATE' ? await createRegion(id, data) : await updateRegion(id, data[0], regionId);
    await dispatch(getRegionsTabData(id, t));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    if (e.response.status === 400) {
      const { data } = e.response;
      if (data.errors) {
        notification.error({
          message: t('popup.region_already_exists'),
          description: t('popup.try_again_products'),
        });
        return;
      }
    }
    notification.error({
      message: t('popup.retrieving_data'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getAgents = (partnerId: number, t): ThunkResult => async dispatch => {
  try {
    const { data } = await getAgentsData(partnerId);
    dispatch({ type: TYPES.getAgentsData, payload: { data } });
  } catch (e) {
    notification.error({
      message: t('popup.getting_agents'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getOptionsTabData = (partnerId, t): ThunkResult => async dispatch => {
  try {
    const { data } = await getPartnerOptionsTabData(partnerId);

    dispatch({ type: TYPES.getPartnersOptionsTabData, payload: data });
  } catch (e) {
    notification.error({
      message: t('popup.retrieving_data'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getRegionsTabData = (regionsId, t): ThunkResult => async dispatch => {
  try {
    const { data } = await getRegionsData(regionsId);

    dispatch({ type: TYPES.getPartnersRegionsTabData, payload: data });
  } catch (error) {
    notification.error({
      message: t('popup.retrieving_data'),
      description: t('popup.try_again_products'),
    });
  }
};

export const deleteRegion: IAction<number> = (regionId, t) => async () => {
  try {
    await removeRegion(regionId);
    notification.success({
      message: t('popup.deleted'),
    });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

// Internet Sales
export const getInternetSalesTabData = (id: number, t): ThunkResult => async dispatch => {
  try {
    const { data } = await getInternetSalesData(id);
    dispatch({ type: TYPES.getPartnersInternetSalesTabData, payload: data });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const deleteInternetSales: IAction<number> = (departmentProgramId: number, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();

  try {
    if (id) {
      await removeInternetSales(id, departmentProgramId);

      dispatch(getInternetSalesTabData(id, t));

      notification.success({
        message: t('popup.deleted'),
      });
    }
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getCompaniesCreateToken = (partnerId: number, t) => async dispatch => {
  try {
    const { data } = await getCompaniesInternetSales(partnerId);
    dispatch({ type: TYPES.getCompaniesInternetSales, payload: data });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getSubdivisionCreateToken = (
  params: { partnerId: number; insuranceProgramId: number },
  t,
) => async dispatch => {
  try {
    const { data } = await getSubdivisionInternetSales(params);

    dispatch({ type: TYPES.getSubdivisionInternetSales, payload: data });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const sendInternetSalesToken = (params: { insuranceProgramId: number; departmentId: number }, t) => async (
  dispatch,
  getState,
) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();

  try {
    await createInternetSalesToken(id, params);

    notification.success({
      message: t('popup.save'),
    });

    dispatch(getInternetSalesTabData(id, t));
  } catch (e) {
    notification.error({
      message: t('popup.save_error'),
    });
  }
};

export const createAgent: IAction<IAgent> = (agentData, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await sendAgent(agentData);
    if (id) await dispatch(getAgents(id, t));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors.forEach(item => {
        if (item.code === 'NOT_UNIQUE_EMAIL') {
          notification.error({
            message: `${t('popup.agent_with_email_already_exists')}`,
          });
        }
        if (item.code === 'NOT_UNIQUE_PHONE_NUMBER') {
          notification.error({
            message: `${t('popup.agent_with_phone_already_exists')}`,
          });
        }
        if (item.code === 'NOT_UNIQUE_ENTERPRISE_CODE') {
          notification.error({
            message: `${t('popup.agent_with_ent_code_already_exists')}`,
          });
        }
      });
    }
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const updateAgent: IUpdateAction<IAgent, number> = (agentData, agentId, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await editAgent(agentId, agentData);
    if (id) await dispatch(getAgents(id, t));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors.forEach(item => {
        if (item.code === 'NOT_UNIQUE_EMAIL') {
          notification.error({
            message: `${t('popup.agent_with_email_already_exists')}`,
          });
        }
        if (item.code === 'NOT_UNIQUE_PHONE_NUMBER') {
          notification.error({
            message: `${t('popup.agent_with_phone_already_exists')}`,
          });
        }
        if (item.code === 'NOT_UNIQUE_ENTERPRISE_CODE') {
          notification.error({
            message: `${t('popup.agent_with_ent_code_already_exists')}`,
          });
        }
      });
    }
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const deleteAgent: IAction<number> = (agentId, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await removeAgent(agentId);
    if (id) await dispatch(getAgents(id, t));
    notification.success({
      message: t('popup.deleted'),
    });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getInsProgramsTable: IAction<number> = (insuranceCompanyId, t) => async dispatch => {
  try {
    const { data } = await getInsuranceProgramsTable(insuranceCompanyId);
    dispatch({ type: TYPES.getInsPrograms, payload: data.resultList });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getInsProgramsModal: IAction<number> = (partnerId, t) => async dispatch => {
  try {
    const { data } = await getInsuranceProgramsModal(partnerId);
    dispatch({ type: TYPES.getInsProgramsModal, payload: data });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getProgram: IAction<number> = partnerId => async dispatch => {
  const { data } = await getProgramsData(partnerId);

  dispatch({ type: TYPES.getProgram, payload: data });
};

export const sendProgram: IAction<IProgram> = (data, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await createProgram(data);
    if (id) await dispatch(getInsProgramsTable(id, t));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const updateProgram: IUpdateAction<number, IProgram> = (partnerId, data, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await editProgram(partnerId, data);
    if (id) await dispatch(getInsProgramsTable(id, t));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getRegions = t => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    const { data } = await getRegionsData(id);

    dispatch({ type: TYPES.getRegions, payload: { data } });
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getSubDivisions = (
  partnerId: number,
  params?: any,
  errors?: { mes: string; desc: string },
) => async dispatch => {
  try {
    const { data } = await getSudivisionsList(partnerId, params);

    dispatch({ type: TYPES.getSubDivisions, payload: data });
  } catch (e) {
    notification.error({
      message: errors?.mes,
      description: errors?.desc,
    });
  }
};

export const sendSubdivision: IAction<any> = (data, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await createSubdivision(data);
    if (id) await dispatch(getSubDivisions(id));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors.forEach(item => {
        if (item.code === 'NOT_UNIQUE') {
          notification.error({
            message: `${t('popup.subdivision_with_such')} ${getValidationText(item.field, t)} ${t(
              'popup.already_exists',
            )}`,
          });
        }
      });
    }
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const updateSubdivision: IUpdateAction<number, any> = (partnerId, data, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await editSubdivision(partnerId, data);
    if (id) await dispatch(getSubDivisions(id));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors.forEach(item => {
        if (item.code === 'NOT_UNIQUE') {
          notification.error({
            message: `${t('popup.subdivision_with_such')} ${getValidationText(item.field, t)} ${t(
              'popup.already_exists',
            )}`,
          });
        }
      });
    }
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setSorting: IAction<{ field: string; direction: string }> = ({ field, direction }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};
