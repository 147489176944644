export const FormRules = t => {
  const NAME_RULES = [
    {
      required: true,
      message: t('validation.enter_data'),
    },
    { min: 1, message: 'Мінімальна кількість - 1 символ' },
    { max: 50, message: 'Максимальна кількість - 50 символів' },
  ];

  return {
    NAME_RULES,
  };
};
