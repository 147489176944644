import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import Tooltip from '../Tooltip';
import theme from '../../Theme/index';

interface IPaymentsStructureChartProps {
  data: any[];
  partFromAll?: boolean;
  [x: string]: any;
}

const PaymentsStructureChart = ({ data, partFromAll, ...props }: IPaymentsStructureChartProps) => (
  <div {...props} style={{ position: 'relative' }}>
    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <ResponsivePie
        data={data}
        innerRadius={0.5}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        padAngle={0}
        cornerRadius={0}
        borderWidth={0}
        colors={theme.colors}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        tooltip={data => <Tooltip {...{ ...data, partFromAll }} />}
        theme={{
          tooltip: {
            container: {
              background: 'transparent',
              boxShadow: 'none',
            },
          },
        }}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  </div>
);

export default PaymentsStructureChart;
