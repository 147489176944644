import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import { setActionOnBrand, setBrands, setPagination } from 'store/brands/actions';
import { AppState } from 'store/reducer';

import PageHeader from './components/PageHeader';
import BrandsTable from './components/List';
import BrandsInfo from './components/modal';
import SeachBrand from './components/SearchBrand';
import TableTabs, { TableTabPane } from 'components/TableTabs';
import { INSURANCE_OBJECT_TYPE } from 'components/DetailsCard/constants/Datails.constants';

import styles from './BrandsPage.module.less';

const BrandsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data, success, actionOnBrand, error, current, perPage, total, pending, update } = useSelector(
    ({
      brands: {
        pagination: { current, perPage },
        actionOnBrand,
        success,
        data,
        error,
        pending,
        update,
        total,
      },
    }: AppState) => ({ data, success, error, current, perPage, total, pending, update, actionOnBrand }),
  );

  const [brandModal, setBrandModal] = useState<boolean>(false);
  const [chosenTab, setChosenTab] = useState<keyof typeof INSURANCE_OBJECT_TYPE>('ELECTRONIC_DEVICE');
  const [currentBrand, setCurrentBrand] = useState<object>({});
  const [brandAction, setBrandAction] = useState('');

  const pagination = {
    page: current,
    size: perPage,
    total: total || 0,
  };

  useEffect(() => {
    if (update) dispatch(setBrands(chosenTab));
  }, [update]);

  const handleCloseBrand = () => {
    setBrandModal(false);

    setBrandAction('');
  };

  const handleOpenBrand = (actionOnBrand: string) => {
    dispatch(setActionOnBrand(actionOnBrand));

    setBrandModal(true);
  };

  const handleEditBrand = (props: React.SetStateAction<object>) => {
    setCurrentBrand(props);

    setBrandModal(true);

    setBrandAction(actionOnBrand);
  };

  const onPageChange = (page: number, pageSize?: number): void => {
    dispatch(setPagination(page, pageSize || 0));
    dispatch(setBrands(chosenTab));
  };

  const onChangeTab = str => {
    setChosenTab(str);
    dispatch(setBrands(str));
  };

  useEffect(() => {
    if (actionOnBrand) {
      setBrandAction(actionOnBrand);
    }
  }, [actionOnBrand]);

  return (
    <>
      <Grid>
        <PageHeader
          action={() => handleOpenBrand('addBrand')}
          button={{ size: 'large', color: 'primary' }}
          text={t('brands_page.brand_guide')}
          buttonText={t('brands_page.add')}
          additionalBreadcrumbs={[
            {
              label: t('brands_page.brand_directory'),
            },
          ]}
        />
        <SeachBrand pagination={pagination} />
        <div className={styles.root}>
          <div className={classNames(styles.content)}>
            <TableTabs activeKey={chosenTab} onChange={onChangeTab}>
              <TableTabPane tab={t('brands_page.electronic_devices')} key={'ELECTRONIC_DEVICE'} />
              <TableTabPane tab={t('brands_page.vehicles')} key={'VEHICLE'} />
            </TableTabs>

            <BrandsTable
              open={handleEditBrand}
              loading={pending}
              paginations={pagination}
              data={data}
              onPageChange={onPageChange}
              chosenTab={chosenTab}
            />
          </div>
        </div>
      </Grid>

      <BrandsInfo
        brandAction={brandAction}
        success={success}
        error={error}
        isOpen={brandModal}
        data={currentBrand}
        onClose={handleCloseBrand}
        chosenTab={chosenTab}
      />
    </>
  );
};

export default BrandsPage;
