export const modifyInsTypes = (data: any, t: any) =>
  data.map(item => {
    switch (item.name) {
      case 'Страхування відповідальності забудовника перед третіми особами відповідно до Закону України "Про фінансово-кредитні механізми і управління майном при будівництві житла та операціях з нерухомістю"':
        return { ...item, name: t('insurance_program_modal_type_word_1') };
      case 'Cтрахування майнових ризиків користувача надр під час дослідно-промислового і промислового видобування та використання газу (метану) вугільних родовищ':
        return { ...item, name: t('insurance_program_modal_type_word_2') };
      case 'Cтрахування професійної відповідальності призначених органів з оцінки відповідності та визнаних незалежних організацій за шкоду, яку може бути заподіяно третім особам':
        return { ...item, name: t('insurance_program_modal_type_word_3') };
      case 'Cтрахування ризику невиплати гравцям призів у разі неплатоспроможності та/або банкрутства оператора державних лотерей':
        return { ...item, name: t('insurance_program_modal_type_word_4') };
      case `Страхування цивільної відповідальності суб'єкта господарювання за шкоду, яку може бути заподіяно третім особам унаслідок проведення вибухових робіт`:
        return { ...item, name: t('insurance_program_modal_type_word_5') };
      case 'Авіаційне страхування цивільної авіації':
        return { ...item, name: t('insurance_program_modal_type_word_6') };
      case 'Медичне страхування':
        return { ...item, name: t('insurance_program_modal_type_word_7') };
      case `Медичне страхування (безперервне страхування здоров'я)`:
        return { ...item, name: t('insurance_program_modal_type_word_8') };
      case 'Особисте страхування від нещасних випадків на транспорті':
        return { ...item, name: t('insurance_program_modal_type_word_9') };
      case `Особисте страхування медичних и фармацевтичних працівників (крім тих, які працюють в установах і організаціях, що фінансуються з Державного бюджету України) на випадок інфікування вірусом імунодефіциту людини під час виконання ними службових обов'язків`:
        return { ...item, name: t('insurance_program_modal_type_word_10') };
      case 'Особисте страхування працівників відомчої (крім тих, які працюють в установах і організаціях, що фінансуються з Державного бюджету України) та сільської пожежної охорони і членів добровільних пожежних дружин (команд)':
        return { ...item, name: t('insurance_program_modal_type_word_11') };
      case `Страхування будівельно-монтажних робіт забудовником відповідно до Закону України "Про фінансово-кредитні механізми і управління майном при будівництві житла та операціях з нерухомістю"`:
        return { ...item, name: t('insurance_program_modal_type_word_12') };
      case 'Страхування вантажів та багажу (вантажобагажу)':
        return { ...item, name: t('insurance_program_modal_type_word_13') };
      case 'Страхування виданих гарантій (порук) та прийнятих гарантій':
        return { ...item, name: t('insurance_program_modal_type_word_14') };
      case 'Страхування від вогневих ризиків та ризиків стихійних явищ':
        return { ...item, name: t('insurance_program_modal_type_word_15') };
      case 'Страхування від нещасних випадків':
        return { ...item, name: t('insurance_program_modal_type_word_16') };
      case 'Страхування від ризиків загибелі або пошкодження нерухомості, набутої в результаті управління майном':
        return { ...item, name: t('insurance_program_modal_type_word_17') };
      case 'Страхування відповідальності виробників (постачальників) продукції тваринного походження, ветеринарних препаратів, субстанцій за шкоду, заподіяну третім особам':
        return { ...item, name: t('insurance_program_modal_type_word_18') };
      case 'Страхування відповідальності власників водного транспорту (включаючи відповідальність перевізника)':
        return { ...item, name: t('insurance_program_modal_type_word_19') };
      case 'Страхування відповідальності власників повітряного транспорту (включаючи відповідальність перевізника)':
        return { ...item, name: t('insurance_program_modal_type_word_20') };
      case 'Страхування відповідальності власників собак (за переліком порід, визначених Кабінетом Міністрів України) щодо шкоди, яка може бути заподіяна третім особам':
        return { ...item, name: t('insurance_program_modal_type_word_21') };
      case 'Страхування відповідальності експортера та особи, яка відповідає за утилізацію (видалення) небезпечних відходів, щодо відшкодування шкоди, яку може бути заподіяно здоров’ю людини, власності та навколишньому природному середовищу під час транскордонного перевезення та утилізації (видалення) небезпечних відходів':
        return { ...item, name: t('insurance_program_modal_type_word_22') };
      case 'Страхування предмета іпотеки від ризиків випадкового знищення, випадкового пошкодження або псування':
        return { ...item, name: t('insurance_program_modal_type_word_23') };
      case `Страхування здоров'я на випадок хвороби`:
        return { ...item, name: t('insurance_program_modal_type_word_24') };
      case 'Страхування медичних витрат':
        return { ...item, name: t('insurance_program_modal_type_word_25') };
      case `Страхування відповідальності експортера та особи, яка відповідає за утилізацію "Страхування відповідальності морського перевізника та виконавця робіт, пов'язаних із обслуговуванням морського транспорту, щодо відшкодування збитків, завданих пасажирам, багажу, пошті, вантажу, іншим користувачам морського транспорту та третім особам`:
        return { ...item, name: t('insurance_program_modal_type_word_26') };
      case 'Страхування відповідальності морського судновласника':
        return { ...item, name: t('insurance_program_modal_type_word_27') };
      case 'Страхування відповідальності перед третіми особами (іншої, ніж передбачена видами 01.12-01.14)':
        return { ...item, name: t('insurance_program_modal_type_word_28') };
      case `Страхування відповідальності суб'єктів перевезення небезпечних вантажів на випадок настання негативних наслідків при перевезенні небезпечних вантажів`:
        return { ...item, name: t('insurance_program_modal_type_word_29') };
      case `Страхування відповідальності суб'єктів туристичної діяльності за шкоду, заподіяну життю чи здоров'ю туриста або його майну`:
        return { ...item, name: t('insurance_program_modal_type_word_30') };
      case `Страхування відповідальності щодо ризиків, пов'язаних з підготовкою до запуску космічної техніки на космодромі, запуском та експлуатацією її у космічному просторі`:
        return { ...item, name: t('insurance_program_modal_type_word_31') };
      case 'Страхування водного транспорту (морського внутрішнього та інших видів водного транспорту)':
        return { ...item, name: t('insurance_program_modal_type_word_32') };
      case 'Страхування врожаю с/г культур і багаторічних насаджень державними с/г підприємствами, врожаю зернових культур і цукрових буряків с/г підприємствами всіх форм власності':
        return { ...item, name: t('insurance_program_modal_type_word_33') };
      case `Страхування життя і здоров'я волонтерів на період надання ними волонтерської допомоги`:
        return { ...item, name: t('insurance_program_modal_type_word_34') };
      case `Страхування життя і здоров'я спеціалістів ветеринарної медицини`:
        return { ...item, name: t('insurance_program_modal_type_word_35') };
      case 'Страхування залізничного транспорту':
        return { ...item, name: t('insurance_program_modal_type_word_36') };
      case 'Страхування засобів водного транспорту':
        return { ...item, name: t('insurance_program_modal_type_word_37') };
      case 'Страхування інвестицій':
        return { ...item, name: t('insurance_program_modal_type_word_38') };
      case 'Страхування кредитів (у тому числі відповідальності позичальника за непогашення кредиту)':
        return { ...item, name: t('insurance_program_modal_type_word_39') };
      case 'Страхування ліній електропередач та перетворюючого обладнання передавачів електроенергії від пошкодження внаслідок впливу стихійних лих або техногенних катастроф та від протиправних дій третіх осіб':
        return { ...item, name: t('insurance_program_modal_type_word_40') };
      case 'Страхування майна, переданого у концесію':
        return { ...item, name: t('insurance_program_modal_type_word_41') };
      case 'Страхування майнових ризиків за угодою про розподіл продукції у випадках, передбачених Законом України "Про угоди про розподіл продукції" (1039-14)':
        return { ...item, name: t('insurance_program_modal_type_word_42') };
      case 'Страхування майнових ризиків при промисловій розробці родовищ нафти і газу у випадках, передбачених Законом України "Про нафту і газ" (2665-14)':
        return { ...item, name: t('insurance_program_modal_type_word_43') };
      case `Страхування медичних та інших працівників державних і комунальних закладів охорони здоров'я та державних наукових установ (крім тих, які працюють в установах і організаціях, що фінансуються з Державного бюджету України) на випадок захворювання на інфекційні хвороби, пов'язаного з виконанням ними професійних обов'язків в умовах підвищеного ризику зараження збудниками інфекційних хвороб`:
        return { ...item, name: t('insurance_program_modal_type_word_44') };
      case 'Страхування наземного транспорту (крім залізничного)':
        return { ...item, name: t('insurance_program_modal_type_word_45') };
      case `Страхування об'єктів космічної діяльності (космічна інфраструктура), які є власністю України, щодо ризиків, пов'язаних з підготовкою до запуску космічної техніки на космодромі, запуском та експлуатацією її у космічному просторі`:
        return { ...item, name: t('insurance_program_modal_type_word_46') };
      case `Страхування об'єктів космічної діяльності (наземна інфраструктура), перелік яких затверджується Кабінетом Міністрів України за поданням центрального органу виконавчої влади, що забезпечує формування державної політики у сфері космічної діяльності`:
        return { ...item, name: t('insurance_program_modal_type_word_47') };
      case `Страхування персоналу ядерних установок, джерел іонізуючого випромінювання, а також державних інспекторів з нагляду за ядерною та радіаційною безпекою безпосередньо на ядерних установках від ризику негативного впливу іонізуючого випромінювання на їхнє здоров'я за рахунок коштів ліцензіатів`:
        return { ...item, name: t('insurance_program_modal_type_word_48') };
      case 'Страхування повітряного транспорту':
        return { ...item, name: t('insurance_program_modal_type_word_49') };
      case 'Страхування працівників (крім тих, які працюють в установах і організаціях, що фінансуються з Державного бюджету України), які беруть участь у наданні психіатричної допомоги, в тому числі здійснюють догляд за особами, які страждають на психічні розлади':
        return { ...item, name: t('insurance_program_modal_type_word_50') };
      case 'Страхування професійної відповідальності осіб, діяльність яких може заподіяти шкоду третім особам, за переліком, встановленим Кабінетом Міністрів України':
        return { ...item, name: t('insurance_program_modal_type_word_51') };
      case 'Страхування сільськогосподарської продукції':
        return { ...item, name: t('insurance_program_modal_type_word_52') };
      case 'Страхування спортсменів вищих категорій':
        return { ...item, name: t('insurance_program_modal_type_word_53') };
      case 'Страхування судових витрат':
        return { ...item, name: t('insurance_program_modal_type_word_54') };
      case 'Страхування тварин (крім тих, що використовуються у цілях сільськогосподарського виробництва) на випадок загибелі, знищення, вимушеного забою, від хвороб, стихійних лих та нещасних випадків у випадках та згідно з переліком тварин, встановленими Кабінетом Міністрів України':
        return { ...item, name: t('insurance_program_modal_type_word_55') };
      case 'Страхування фінансових ризиків неотримання, невчасного отримання та отримання не у повній сумі платежів за договорами про іпотечні кредити та платежів за іпотечними сертифікатами':
        return { ...item, name: t('insurance_program_modal_type_word_56') };
      case 'Страхування фінансової відповідальності, життя і здоров’я тимчасового адміністратора, ліквідатора фінансової установи та працівників центрального органу виконавчої влади, що реалізує державну фінансову політику, які визначені ним для вирішення питань щодо участі держави у капіталізації банку':
        return { ...item, name: t('insurance_program_modal_type_word_57') };
      case 'Страхування фінансової відповідальності управителя майном за збитки при здійсненні управління цим майном':
        return { ...item, name: t('insurance_program_modal_type_word_58') };
      case 'Страхування цивільної відповідальності власників наземного транспорту (включаючи відповідальність перевізника)':
        return { ...item, name: t('insurance_program_modal_type_word_59') };
      case 'Страхування цивільної відповідальності громадян України, що мають увласності чи іншому законному володінні зброю, за шкоду, яка може бути заподіяна третій особі або її майну внаслідок володіння, зберігання чи використання цієї зброї"':
        return { ...item, name: t('insurance_program_modal_type_word_60') };
      case `Страхування цивільної відповідальності інвестора, в тому числі за шкоду, заподіяну довкіллю, здоров'ю людей, за угодою про розподіл продукції, якщо інше не передбачено такою угодою`:
        return { ...item, name: t('insurance_program_modal_type_word_61') };
      case 'Страхування цивільної відповідальності оператора ядерної установки за ядерну шкоду, яка може бути заподіяна внаслідок ядерного інциденту':
        return { ...item, name: t('insurance_program_modal_type_word_62') };
      case `Страхування цивільної відповідальності суб'єктів господарювання за шкоду, яку може бути заподіяно довкіллю або здоров'ю людей під час зберігання та застосування пестицидів і агрохімікатів`:
        return { ...item, name: t('insurance_program_modal_type_word_63') };
      case `Страхування цивільної відповідальності суб'єктів господарювання за шкоду, яку може бути заподіяно пожежами та аваріями на об'єктах підвищеної небезпеки, включаючи пожежовибухонебезпечні об'єкти та об'єкти, господарська діяльність на яких може призвести до аварій екологічного та санітарно-епідеміологічного характеру`:
        return { ...item, name: t('insurance_program_modal_type_word_64') };
      case `Страхування цивільної відповідальності суб'єктів космічної діяльності`:
        return { ...item, name: t('insurance_program_modal_type_word_65') };
      case `Страхування цивільно-правової відповідальності арбітражного керуючого (розпорядника майна, керуючого санацією, ліквідатора) за шкоду, яку може бути завдано у зв'язку з виконанням його обов'язків`:
        return { ...item, name: t('insurance_program_modal_type_word_66') };
      case 'Страхування цивільно-правової відповідальності власників наземних транспортних засобів (за внутрішніми договорами страхування)':
        return { ...item, name: t('insurance_program_modal_type_word_67') };
      case 'Страхування цивільно-правової відповідальності власників наземних транспортних засобів (за договорами міжнародного страхування)':
        return { ...item, name: t('insurance_program_modal_type_word_68') };
      case 'Страхування цивільно-правової відповідальності приватного нотаріуса':
        return { ...item, name: t('insurance_program_modal_type_word_69') };
      case 'Страхування майна (іншого, ніж передбачено видами 01.05-01.09)':
        return { ...item, name: t('insurance_program_modal_type_word_70') };
      case 'Страхування фінансових ризиків':
        return { ...item, name: t('insurance_program_modal_type_word_71') };
      case 'Страхование от несчастных случаев и болезней':
        return { ...item, name: t('insurance_program_modal_type_word_72') };
      case 'Добровольное страхование имущества':
        return { ...item, name: t('insurance_program_modal_type_word_73') };
      default:
        return item;
    }
  });

export const modifyRiskListData = (data: any, t: any) =>
  data.map(item => {
    switch (item.name) {
      case 'Стаціонарне лікування':
        return { ...item, name: t('insurance_program_modal_risk_word_1') };
      case 'Крадіжка/розбій':
        return { ...item, name: t('insurance_program_modal_risk_word_2') };
      case 'Механічне пошкодження,розбиття та/або пошкодження':
        return { ...item, name: t('insurance_program_modal_risk_word_3') };
      case 'Смерть':
        return { ...item, name: t('insurance_program_modal_risk_word_4') };
      case 'Відшкодування понесених медичних витрат при захворюванні COVID-19':
        return { ...item, name: t('insurance_program_modal_risk_word_5') };
      case 'Організація діагностування на COVID-19':
        return { ...item, name: t('insurance_program_modal_risk_word_6') };
      case 'Організації надання і оплати медичної допомоги на території Україні при захворюванні на COVID-19':
        return { ...item, name: t('insurance_program_modal_risk_word_7') };
      case 'Невідкладне амбулаторне лікування на до госпітальному етапі та/або в умовах амбулаторії, у відділенні денного стаціонару медичного закладу (візит лікаря, огляд і консультація, невідкладні діагностичні дослідження, амбулаторне хірургічне, терапевтичне лікування, придбання медикаментів) невідкладну госпіталізацію – невідкладні дослідження, терапевтичне лікування в стаціонарі, послуги медичного персоналу':
        return { ...item, name: t('insurance_program_modal_risk_word_8') };
      case 'Готельні послуги (вартість перебування у палаті стандартного типу, палаті інтенсивної терапії, реанімаційній палаті, призначений лікарем медичний догляд, придбання медикаментів), в тому числі покриття витрат на ізоляцію Застрахованої особи та осіб що перебували в близькому контакті зі Застрахованою особою':
        return { ...item, name: t('insurance_program_modal_risk_word_9') };
      case 'Придбання лікарських засобів, призначених лікарем, для надання невідкладної допомоги':
        return { ...item, name: t('insurance_program_modal_risk_word_10') };
      case 'Діагностування на COVID-19 осіб що перебували в близькому контакті зі Застрахованою особою в разі виявлення інфікування Застрахованої особи на COVID-19':
        return { ...item, name: t('insurance_program_modal_risk_word_11') };
      case 'Невідкладна стоматологічна допомога – стоматологічні послуги, що надаються Страхувальнику (Застрахованій особі) за медичними показаннями в межах встановлених лімітів відповідальності: в разі виникненні гострого зубного болю, що вимагає надання невідкладної стоматологічної допомоги – еквівалент 150 ЄВРО':
        return { ...item, name: t('insurance_program_modal_risk_word_12') };
      case 'Невідкладна акушерська допомога, надана Страхувальнику (Застрахованій особі) за медичними показаннями в разі загрози життю та здоров’ю Страхувальника (Застрахованої особи) за умови, що строк вагітності Страхувальника (Застрахованої особи) становив до 29 (двадцяти дев’яти) тижнів':
        return { ...item, name: t('insurance_program_modal_risk_word_13') };
      case 'Транспортування Страхувальника (Застрахованої особи), в разі клінічної необхідності, за медичними показаннями до лікарні або лікаря, що знаходяться у безпосередній близькості, каретою швидкої допомоги або іншим транспортним засобом;':
        return { ...item, name: t('insurance_program_modal_risk_word_14') };
      case 'Готельні послуги (вартість перебування у палаті стандартного типу, палаті інтенсивної терапії, реанімаційній палаті), медичні маніпуляції, призначені лікуючим лікарем, оплата медикаментів), на базі інфекційних відділень державних/відомчих клінік або обсервації в разі безсимптомного перебігу хвороби (COVID-19);':
        return { ...item, name: t('insurance_program_modal_risk_word_15') };
      case 'Репатріація транспортування Страхувальника (Застрахованої особи), з необхідним медичним супроводом (якщо такий супровід призначений лікарем та узгоджений із Асистанською компанією) від місця перебування цієї особи до місця його постійного проживання':
        return { ...item, name: t('insurance_program_modal_risk_word_16') };
      case 'Рішення про необхідність і можливість репатріації, а також про вибір засобу її здійснення і маршрут приймає Страховик за узгодженням із Асистанською компанією, медичним закладом та лікарем Страхувальника (Застрахованої особи)':
        return { ...item, name: t('insurance_program_modal_risk_word_17') };
      case 'Пошкодження водою':
        return { ...item, name: t('insurance_program_modal_risk_word_18') };
      case 'Гарантійна/не гарантійна несправність':
        return { ...item, name: t('insurance_program_modal_risk_word_19') };
      case 'Репатріація тіла Страхувальника (Застрахованої особи) у разі його смерті внаслідок нещасного випадку або раптового захворювання, в місце його постійного проживання, або поховання (кремація) тіла Страхувальника (Застрахованої особи) в місці перебування поза межами країни (місця) постійного проживання Страхувальника (Застрахованої особи). Всі заходи із надання цих послуг організовує виключно Асистанська компанія, за узгодженням із Страховиком':
        return { ...item, name: t('insurance_program_modal_risk_word_20') };
      case 'Екстрена медична допомога в умовах стаціонару':
        return { ...item, name: t('insurance_program_modal_risk_word_21') };
      case 'Екстрена медична допомога в амбулаторних умовах':
        return { ...item, name: t('insurance_program_modal_risk_word_22') };
      case 'Кінцевий пункт маршруту репатріації визначається за згодою сторін Договору. Зокрема, ним може бути аеропорт у місці постійного проживання, куди прибуває труна із тілом померлого, або митний пункт у місці постійного проживання, найближчий до її кордону':
        return { ...item, name: t('insurance_program_modal_risk_word_23') };
      case 'Для організації репатріації, родичі померлого повинні в найкоротший строк надати Страховику належним чином оформлені документи, що підтверджують їх родинний зв’язок із Страхувальником (Застрахованою особою), а також заяву - підтвердження про готовність забрати тіло померлого після перевезення труни, де постійно проживав Страхувальник (Застрахована особа)':
        return { ...item, name: t('insurance_program_modal_risk_word_24') };
      case 'Пожежа, вибух, удар блискавки, що викликав пожежу':
        return { ...item, name: t('insurance_program_modal_risk_word_25') };
      case 'Падiння лiтаючого апарату':
        return { ...item, name: t('insurance_program_modal_risk_word_26') };
      case 'ДТП за участю двох ТЗ':
        return { ...item, name: t('insurance_program_modal_risk_word_27') };
      case 'ДЦВ шкода майну':
        return { ...item, name: t('insurance_program_modal_risk_word_28') };
      case 'ДТП':
        return { ...item, name: t('insurance_program_modal_risk_word_29') };
      case 'СЛ (стихійне лихо)':
        return { ...item, name: t('insurance_program_modal_risk_word_30') };
      case 'Пожежа':
        return { ...item, name: t('insurance_program_modal_risk_word_31') };
      case 'ПДТО':
        return { ...item, name: t('insurance_program_modal_risk_word_32') };
      case 'Викрадення':
        return { ...item, name: t('insurance_program_modal_risk_word_33') };
      case 'ДЦВ за шкоду, заподіяну  майну третіх осіб внаслідок ДТП':
        return { ...item, name: t('insurance_program_modal_risk_word_34') };
      case 'ДЦВ за шкоду, заподіяну  життю та здоров`ю третіх осіб внаслідок ДТП':
        return { ...item, name: t('insurance_program_modal_risk_word_35') };
      case 'Смерть внаслідок НВ під час ДТП':
        return { ...item, name: t('insurance_program_modal_risk_word_36') };
      case 'Встановлення інвалідності внаслідок НВ під час ДТП':
        return { ...item, name: t('insurance_program_modal_risk_word_37') };
      case 'Тимчасова втрата працездатності внаслідок НВ під час ДТП':
        return { ...item, name: t('insurance_program_modal_risk_word_38') };
      case 'Пошкодження вогнем':
        return { ...item, name: t('insurance_program_modal_risk_word_39') };
      case 'Стихійні явища':
        return { ...item, name: t('insurance_program_modal_risk_word_40') };
      case 'Вплив води':
        return { ...item, name: t('insurance_program_modal_risk_word_41') };
      case 'Протиправні дії третіх осіб':
        return { ...item, name: t('insurance_program_modal_risk_word_42') };
      case 'Шкода, заподіяна життю, здоров’ю третіх осіб':
        return { ...item, name: t('insurance_program_modal_risk_word_43') };
      case 'Шкода, заподіяна їхньому майну':
        return { ...item, name: t('insurance_program_modal_risk_word_44') };
      case 'Знищення внаслідок пожежі, що сталася в результаті короткого замикання':
        return { ...item, name: t('insurance_program_modal_risk_word_45') };
      case 'Гостре захворювання':
        return { ...item, name: t('insurance_program_modal_risk_word_46') };
      case 'Хронічне захворювання в стадії загострення':
        return { ...item, name: t('insurance_program_modal_risk_word_47') };
      case 'Травми, опіки, отруєння та інші наслідки нещасних випадків, які трапились протягом дії договору страхування':
        return { ...item, name: t('insurance_program_modal_risk_word_48') };
      case 'Хронічне захворювання в стадії ремісії':
        return { ...item, name: t('insurance_program_modal_risk_word_49') };
      case 'Страховик визнає страховим випадком понесені медичні витрати в разі травмування Застрахованої особи, яке стало наслідком активного відпочинку (Active leisure) – нерегулярного заняття Застрахованою особою будь-яким видом спорту та фізичними вправами, в тому числі:':
        return { ...item, name: t('insurance_program_modal_risk_word_50') };
      case 'Знищення, пошкодження дисплейного модуля Майна (пристрою) внаслідок дії води':
        return { ...item, name: t('insurance_program_modal_risk_word_51') };
      case 'Знищення, пошкодження дисплейного модуля Майна (пристрою) внаслідок дії предметів':
        return { ...item, name: t('insurance_program_modal_risk_word_52') };
      case 'Знищення, пошкодження дисплейного модуля Майна (пристрою) внаслідок падіння':
        return { ...item, name: t('insurance_program_modal_risk_word_53') };
      case 'Знищення, пошкодження дисплейного модуля та/або задньої панелі Майна (пристрою) внаслідок дії води':
        return { ...item, name: t('insurance_program_modal_risk_word_54') };
      case 'Знищення, пошкодження дисплейного модуля та/або задньої панелі Майна (пристрою) внаслідок дії предметів':
        return { ...item, name: t('insurance_program_modal_risk_word_55') };
      case 'Знищення, пошкодження дисплейного модуля та/або задньої панелі Майна (пристрою) внаслідок падіння':
        return { ...item, name: t('insurance_program_modal_risk_word_56') };
      case 'Знищення, пошкодження Майна (пристрою) внаслідок дії води':
        return { ...item, name: t('insurance_program_modal_risk_word_57') };
      case 'Знищення, пошкодження Майна (пристрою) внаслідок самозаймання':
        return { ...item, name: t('insurance_program_modal_risk_word_58') };
      case 'Інвалідність':
        return { ...item, name: t('insurance_program_modal_risk_word_59') };
      case 'Травмування':
        return { ...item, name: t('insurance_program_modal_risk_word_60') };
      case 'Лабораторні тестування ПЛР на короновірусну хворобу COVID-19':
        return { ...item, name: t('insurance_program_modal_risk_word_61') };
      case 'Перевезення Страхувальника/ЗО автомобілем швидкої допомоги до медичного закладу':
        return { ...item, name: t('insurance_program_modal_risk_word_62') };
      case 'Обов‘язкова самоізоляція на одну ЗО, на одну добу':
        return { ...item, name: t('insurance_program_modal_risk_word_63') };
      case 'Послуги зв‘язку з Асистансом/Страховиком':
        return { ...item, name: t('insurance_program_modal_risk_word_64') };
      case 'Медична евакуація Страхувальника/ЗО до найближчого аеропорту України з супроводжуючою особою (за медичними показаннями)':
        return { ...item, name: t('insurance_program_modal_risk_word_65') };
      case 'Перевезення тіла Страхувальника/ЗО до найближчого аеропорту України':
        return { ...item, name: t('insurance_program_modal_risk_word_66') };
      case 'Екстрена стоматологічна допомога':
        return { ...item, name: t('insurance_program_modal_risk_word_67') };
      case 'Екстрена гінекологічна допомога':
        return { ...item, name: t('insurance_program_modal_risk_word_68') };
      case 'Медична евакуація Страхувальника/ЗО до місця постійного проживання з супроводжуючою особою (за медичними показаннями)':
        return { ...item, name: t('insurance_program_modal_risk_word_69') };
      case 'Перевезення тіла Страхувальника/ЗО до місця постійного проживання':
        return { ...item, name: t('insurance_program_modal_risk_word_70') };
      case 'Дострокове повернення Страхувальника/ЗО до місця постійного проживання у випадку смерті членів сім‘ї та близьких родичів Страхувальника/ЗО;':
        return { ...item, name: t('insurance_program_modal_risk_word_71') };
      case 'Инфекционное заболевание Застрахованного':
        return { ...item, name: t('insurance_program_modal_risk_word_72') };
      case 'Инвалидность Застрахованного':
        return { ...item, name: t('insurance_program_modal_risk_word_73') };
      case 'Травматические повреждения и/или заболевания определенного вида':
        return { ...item, name: t('insurance_program_modal_risk_word_74') };
      case 'Пожар, удар молнии, взрыв':
        return { ...item, name: t('insurance_program_modal_risk_word_75') };
      case 'Воздействие жидкости':
        return { ...item, name: t('insurance_program_modal_risk_word_76') };
      case 'Стихийные бедствия':
        return { ...item, name: t('insurance_program_modal_risk_word_77') };
      case 'Противоправные действия третьих лиц':
        return { ...item, name: t('insurance_program_modal_risk_word_78') };
      case 'Ущерб, причиненный жизни, здоровью третьих лиц':
        return { ...item, name: t('insurance_program_modal_risk_word_79') };
      case 'Ущерб, причиненный имуществу третьих лиц':
        return { ...item, name: t('insurance_program_modal_risk_word_80') };
      case 'банкрутство Страхувальника':
        return { ...item, name: t('insurance_program_modal_risk_word_81') };
      case 'банкрутство банку страхувальника':
        return { ...item, name: t('insurance_program_modal_risk_word_82') };
      default:
        return item;
    }
  });

export const modifyProgramsData = (data: any, t: any) =>
  data.map(item => {
    switch (item.name) {
      case 'Електронний договір добровільного комплексного страхування майна та фінансових ризиків за програмою «MOBI ZAHIST»':
        return { ...item, name: t('insurance_program_modal_programs_word_1') };
      case 'Електронний договір добровільного комплексного страхування наземного транспорту':
        return { ...item, name: t('insurance_program_modal_programs_word_2') };
      case 'Договір  оферта  добровільного страхування майна (приєднання)':
        return { ...item, name: t('insurance_program_modal_programs_word_3') };
      case 'Договір комплексного добровільного страхування майна (заява-акцепт)':
        return { ...item, name: t('insurance_program_modal_programs_word_4') };
      case 'Договір  вихідного перестрахування':
        return { ...item, name: t('insurance_program_modal_programs_word_5') };
      case 'Додаткова угода на розторгнення':
        return { ...item, name: t('insurance_program_modal_programs_word_6') };
      case 'Договір добровільного комплексного страхування майна та фінансових ризиків "(індивідуальні умови)"':
        return { ...item, name: t('insurance_program_modal_programs_word_7') };
      case 'Страхування  здоров`я на випадок захворювання на covid-19':
        return { ...item, name: t('insurance_program_modal_programs_word_8') };
      case 'Договор страхування осіб, що подорожують Україною (страхування медичних витрат)':
        return { ...item, name: t('insurance_program_modal_programs_word_9') };
      case 'Договір добровільного комплексного страхування майна та фінансових ризиків (індивідуальні умови)':
        return { ...item, name: t('insurance_program_modal_programs_word_10') };
      case 'Договір добровільного страхування наземного транспорту':
        return { ...item, name: t('insurance_program_modal_programs_word_11') };
      case 'Договір добровільного страхування майна фізичних осіб і відповідальності перед третіми особами':
        return { ...item, name: t('insurance_program_modal_programs_word_12') };
      case 'Страхування  здоров`я':
        return { ...item, name: t('insurance_program_modal_programs_word_13') };
      case 'Договор страхування осіб, що подорожують україною страхування медичних витрат':
        return { ...item, name: t('insurance_program_modal_programs_word_14') };
      case 'Страхования от несчастных случаев и болезней':
        return { ...item, name: t('insurance_program_modal_programs_word_15') };
      default:
        return item;
    }
  });
