import React, { useReducer, useState } from 'react';
import { css } from '@emotion/css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from 'antd-modern';
import { Typography } from 'antd';

import AuthContainer from 'components/next/AuthContainer/AuthContainer';
import Link from 'components/next/Link/Link';
import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';

import Verification from './Verification/Verification';
import ConfirmPassword from './ConfirmPassword/ConfirmPassword';
import Reset from './Reset/Reset';

const cssExternalLinks = css`
  margin-top: 16px;
`;

export type Step = 'reset' | 'verification' | 'confirmPassword';
export interface State {
  step: Step;
  token: number;
  login: string;
}

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const screens = Grid.useBreakpoint();
  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      step: 'reset',
      token: 0,
      login: '',
    },
  );

  const steps: Record<Step, React.ReactNode> = {
    reset: Reset({ setState }),
    verification: Verification({ setState, state }),
    confirmPassword: ConfirmPassword({ state }),
  };

  return (
    <AuthContainer>
      <>
        <Typography.Title level={screens.lg ? 1 : 3}>{t('passwordRecovery.pass_recovery')}</Typography.Title>

        {steps[state.step]}

        <div className={cssExternalLinks}>
          {t('passwordRecovery.no_account')}
          <Link
            onClick={() => {
              history.push(ROUTES.AUTH.MANAGER_SIGNUP);
            }}
          >
            {' '}
            {t('passwordRecovery.register')}
          </Link>
          <span> {t('passwordRecovery.or')}</span>
          <Link
            onClick={() => {
              history.push(ROUTES.PARTNER_AUTH.LOGIN);
            }}
          >
            {' '}
            {t('passwordRecovery.log_in')}
          </Link>
        </div>
      </>
    </AuthContainer>
  );
};

export default PasswordRecovery;
