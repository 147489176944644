import React, { FunctionComponent, useState } from 'react';
import { Button, Typography } from 'antd';
import sign from '../../SignUp/SignUp.module.less';
import CountDown from '../../../CountDown/CountDown';
import { useTranslation } from 'react-i18next';

type TResendSmsCode = {
  sendAction: (data?: any) => void;
  zeroPadding?: boolean;
  contractId?: number | null | string;
  size?: number | string;
};

const ResendSmsCode: FunctionComponent<TResendSmsCode> = ({ size = 16, sendAction, contractId, zeroPadding }) => {
  const [delay, setDelay] = useState<number>(120000);
  const [isButtonActive, setButtonState] = useState<boolean>(false);
  const { t } = useTranslation();

  const actionHandler = () => {
    sendAction(contractId ? contractId : undefined);
    setButtonState(false);
    setDelay(120000);
  };

  const renderer = ({ seconds, minutes, completed }: { seconds: number; minutes: number; completed: boolean }) => {
    if (completed) setButtonState(true);

    return <span>{`( ${minutes} хв. ${seconds} сек.)`}</span>;
  };

  return (
    <Button
      type="link"
      style={{ border: 'none', padding: zeroPadding ? '0' : '0 15px' }}
      disabled={!isButtonActive}
      ghost
      onClick={actionHandler}
    >
      <Typography.Text className={sign.notification_text} type="secondary">
        <span style={{ fontSize: size }}>{t('signup.send_again')}</span>
        {!!delay && (
          <CountDown
            renderer={renderer}
            delay={delay}
            autoStart={true}
            onTick={() => setDelay(delay - 1000)}
            onComplete={() => {
              setButtonState(true);
              setDelay(0);
            }}
          />
        )}
      </Typography.Text>
    </Button>
  );
};

export default ResendSmsCode;
