import { AxiosResponse } from 'axios';
import CreateInstance from './axios/axios';

import { IInsuranceParams } from 'types/registerTypes';
import { filtersDefaultValue } from 'screens/RegisterOfTypes/utils';

const configApi = CreateInstance('CONFIGURATION');

export const getinsuranceRulesData = async (): Promise<AxiosResponse> => {
  return await configApi.get(`/insurance-rules`);
};

export const getinsuranceTypesData = async (params: IInsuranceParams): Promise<AxiosResponse> => {
  return await _getinsuranceTableData({ ...params, path: 'insurance-types' });
};

export const getinsuranceRisksData = async (params: IInsuranceParams): Promise<AxiosResponse> => {
  return await _getinsuranceTableData({ ...params, path: 'risks' });
};

const _getinsuranceTableData = async ({
  isObligatory,
  ruleId,
  companyId,
  path,
  name,
  pageSize,
  page,
  sorting,
}: IInsuranceParams) => {
  const params = {
    count: true,
    page_size: pageSize,
    page,
  };

  if (!sorting?.direction) {
    params['sorting'] = 'id';
  } else if (sorting.direction === 'DESC') {
    params['sorting'] = '-id';
  } else if (sorting.direction === 'ASC') {
    params['sorting'] = 'id';
  }

  if (isObligatory !== undefined && isObligatory !== filtersDefaultValue) {
    params['isObligatory'] = isObligatory;
  }

  if (!!ruleId && ruleId !== filtersDefaultValue) {
    params['insuranceRuleId'] = ruleId;
  }

  if (!!companyId && companyId !== filtersDefaultValue) {
    params['insuranceCompanyId'] = companyId;
  }

  if (!!name && name !== filtersDefaultValue) {
    params['name'] = name;
  }

  return await configApi.get(`${path}/all`, {
    params: {
      ...params,
    },
  });
};

export const createInsuranceTypesData = async (body): Promise<AxiosResponse> => {
  return await configApi.post(`/insurance-types`, body);
};

export const updateInsuranceTypesData = async (body, id): Promise<AxiosResponse> => {
  return await configApi.put(`/insurance-types/${id}`, body);
};
export const updateInsuranceRisksData = async (body, id): Promise<AxiosResponse> => {
  return await configApi.put(`/risks/${id}`, body);
};

export const deleteInsuranceTypesData = async (id: number): Promise<AxiosResponse> => {
  return await configApi.delete(`/insurance-types/${id}`);
};

export const createInsuranceRisksData = async (body): Promise<AxiosResponse> => {
  return await configApi.post(`/risks`, body);
};

export const deleteInsuranceRisksData = async (id: number): Promise<AxiosResponse> => {
  return await configApi.delete(`/risks/${id}`);
};
