import React, { ReactNode } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import styles from './List.module.less';

interface ItemsListProps<T> {
  items: Array<T>;
  itemBuilder: (item: T, i: number, items: Array<T>) => ReactNode;
  selector?: (item: T) => boolean;
  onItemClick?: (item: T) => void;
  itemClassName?: string;
}

function List<T>({ items, itemBuilder, selector, onItemClick, itemClassName }: ItemsListProps<T>) {
  return (
    <ul className={styles.list}>
      {items.map((item, idx, arr) => (
        <li
          role="presentation"
          onClick={() => onItemClick && onItemClick(item)}
          className={classNames(
            styles.item,
            selector && selector(item) && styles.selected,
            selector && arr[idx + 1] && selector(arr[idx + 1]) && styles.disableBorder,
          )}
          key={idx}
        >
          <div className={classNames(styles.itemInner, itemClassName)}>{itemBuilder(item, idx, arr)}</div>
        </li>
      ))}
    </ul>
  );
}

export default List;

export const helpers = {
  idOnly: ({ id }) => id,
  numberOnly: ({ number }) => number,
};

export const builders = {
  byTranslationKey: t => ({ translationKey }) => {
    return t(translationKey);
  },
  byName: ({ name }) => name,
  byServiceName: ({ serviceName }) => serviceName,
  byUserName: ({ userName }) => userName,
  byClaim: ({ claimNumber, risk, claimDate }) => (
    <div className={styles.claimWrapper}>
      <div className={styles.claimHeader}>
        <span className={styles.claimNumber}>{claimNumber}</span>
        <span className={styles.claimDate}>{moment(claimDate).format('DD.MM.YYYY о HH:mm')}</span>
      </div>
      <span className={styles.claimRisk}>{risk}</span>
    </div>
  ),
  postfix: (...children) => item => <div className={styles.trailing}>{children.map(child => child(item))}</div>,
  node: component => () => component,
  whether: (checkFn, childBuilder) => item => checkFn(item) && childBuilder(item),
  combine: (...builders) => item => builders.map(builder => builder(item)),
  byNumber: component => ({ number, statusTranslationKey, status }) =>
    component({ number, statusTranslationKey, status }),
  byContract: component => ({ contractId, contractNumber, statusTranslationKey, contractStatus }) =>
    component({ contractId, contractNumber, statusTranslationKey, contractStatus }),
};

export const selectors = {
  byId: (...ids) => item => {
    return ids.some(id => id === item.id);
  },
  byClaimId: (...ids) => item => {
    return ids.some(id => id === item.claimId);
  },
  byServiceID: (...ids) => item => {
    return ids.some(id => id === item.serviceID);
  },
  byUserRef: (...refs) => item => {
    return refs.some(ref => ref === item.userRef);
  },
  byNumber: (...numbers) => item => numbers.some(num => num === item.number),
  byContractId: (...contractIds) => item => {
    return contractIds.some(el => el === item.contractNumber);
  },
  hasChildren: ({ children }) => children?.length > 0,
  checkClaim: ({ claimNumber }) => claimNumber,
};
