import { createTypes } from 'redux-compose-reducer';
import { Dispatch } from 'redux';
import _ from 'lodash';

import { notification } from 'antd';
import moment from 'moment';

import { AppState } from 'store/reducer';
import * as contractsAPI from 'api/contracts';
import { getCurrentCompanies, getInsuranceProgram, getSubdivisions } from 'api/statistics';
import { statusFormat } from 'utils/statuses';
import { getCorrectStatus } from 'components/tables/ContractListTable/helper';

export const TYPES = createTypes('contractList', [
  'setFiltersDates',
  'setTablePage',
  'setPagination',
  'setSorting',
  'removePrivateFiltersField',
  'resetPrivateFiltersValues',
  'getPrivateFiltersData',
  'getPrivateFilteredData',
  'setCurrentCompanies',
  'setTotalInformation',
  'setPrograms',
  'setPending',
  'setSubdivisions',
  'setPrivateFilterItems',
]);

interface IDates {
  dateFrom?: string;
  dateTo?: string;
  phoneNumber?: string;
  insuranceCompanyId?: any;
  insuranceCompanyName?: string;
  insuranceProgramId?: any;
  insuranceProgramName?: string;
  subdivisionId?: any;
  subdivisionName?: string;
}

export const setFiltersDates = ({
  dateFrom,
  dateTo,
  phoneNumber,
  insuranceCompanyId,
  insuranceCompanyName,
  insuranceProgramId,
  insuranceProgramName,
  subdivisionId,
  subdivisionName,
}: IDates) => (dispatch: Dispatch) =>
  dispatch({
    type: TYPES.setFiltersDates,
    payload: {
      dateFrom,
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
      phoneNumber,
      insuranceCompanyId,
      insuranceCompanyName,
      insuranceProgramId,
      insuranceProgramName,
      subdivisionId,
      subdivisionName,
    },
  });

export const getTablePage = t => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch({ type: TYPES.setPending, payload: { pending: true } });

  const {
    contractList: { pagination, sorting, filters, privateFilterItems },
  }: AppState = getState();
  try {
    const translatedStatuses = _.invert(statusFormat(t));
    const filtersInfoWithCorrectStatuses = getCorrectStatus(privateFilterItems, translatedStatuses);

    const response = await contractsAPI.getTablePage({
      pagination,
      sorting,
      filters,
      privateFilterItems: filtersInfoWithCorrectStatuses,
    });

    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch({ type: TYPES.setTablePage, payload: response.data });

    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
    dispatch({ type: TYPES.setTotalInformation, payload: response?.data?.total });
  } catch (error) {
    dispatch({ type: TYPES.setPending, payload: { pending: false } });
  }
};

export const setPagination = (current: number, perPage: number) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setSorting = ({ field, direction }: { field: string; direction: string }) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};

export const resetPrivateFiltersValues = () => dispatch => {
  dispatch({ type: TYPES.resetPrivateFiltersValues });
};

export const removePrivateFiltersField = (fieldName: string) => dispatch => {
  dispatch({ type: TYPES.removePrivateFiltersField, payload: fieldName });
};

export const setPrivateFilterItems = payload => dispatch => {
  dispatch({ type: TYPES.setPrivateFilterItems, payload });
};

export const getFilteredContracts = (filtersInfo: object, t) => async (
  dispatch: Dispatch,
  getState: () => AppState,
) => {
  dispatch({ type: TYPES.setPending, payload: { pending: true } });

  const {
    contractList: {
      pagination,
      sorting,
      filters: {
        dateFrom,
        dateTo,
        phoneNumber,
        insuranceCompanyId: { insuranceCompanyId, partnerId },
        insuranceProgramId: { id: insuranceProgramId },
        subdivisionId,
      },
    },
  }: AppState = getState();

  const translatedStatuses = _.invert(statusFormat(t));
  const filtersInfoWithCorrectStatuses = getCorrectStatus(filtersInfo, translatedStatuses);

  try {
    const response = await contractsAPI.getPrivateFiltersTable({
      filters: {
        subdivisionId,
        insuranceProgramId,
        insuranceCompanyId,
        partnerId,
        phoneNumber,
        conclusionDate: dateFrom && dateTo && `${dateFrom}..${dateTo}`,
        ...filtersInfoWithCorrectStatuses,
        sorting:
          (!sorting.direction && '-conclusionDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
    });
    const payload = response.data ? response.data : [];
    dispatch({ type: TYPES.getPrivateFiltersData, payload });
    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch({ type: TYPES.setTotalInformation, payload: response?.data?.total });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setPending, payload: { pending: false } });

    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

// data in privat filter modal
export const getFilteredPrivateData = (filtersInfo: object, t) => async (
  dispatch: Dispatch,
  getState: () => AppState,
) => {
  const {
    contractList: {
      pagination,
      sorting,
      filters: {
        dateFrom,
        dateTo,
        phoneNumber,
        insuranceCompanyId: { partnerId },
        insuranceProgramId: { id: partnerDepartmentId },
      },
    },
  }: AppState = getState();

  try {
    const response = await contractsAPI.getPrivateFiltersTable({
      filters: {
        insuranceProgramId: partnerDepartmentId,
        phoneNumber,
        partnerId,
        conclusionDate: dateFrom && dateTo && `${dateFrom}..${dateTo}`,
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-conclusionDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination: {
        current: 1,
        perPage: pagination.perPage,
      },
    });
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.getPrivateFilteredData, payload });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

export const setCompanies = t => async (dispatch: Dispatch<any>) => {
  dispatch({ type: TYPES.setPending, payload: { pending: true } });

  try {
    const response = await getCurrentCompanies();
    const payload = response.data.resultList ? response.data.resultList : [];

    dispatch({ type: TYPES.setCurrentCompanies, payload: { companies: payload, insuranceProgram: [] } });

    dispatch({ type: TYPES.setPending, payload: { pending: false } });
    dispatch(getTablePage(t));
  } catch (error) {
    dispatch({ type: TYPES.setPending, payload: { pending: false } });

    notification.error({
      message: t('popup.update_filters'),
      description: t('popup.please_try_again_later'),
    });
  }
};

export const setSubdivisions = (t, partnerId?: number) => async (dispatch: Dispatch) => {
  try {
    const subdivisions = (await getSubdivisions(partnerId)).data.resultList ?? [];

    dispatch({ type: TYPES.setSubdivisions, payload: { subdivisions } });
  } catch (error) {
    notification.error({
      message: t('popup.update_filters'),
      description: t('popup.please_try_again_later'),
    });
  }
};

export const setPrograms = () => async (dispatch: Dispatch) => {
  const program = (await getInsuranceProgram()).data.resultList || [];

  dispatch({ type: TYPES.setPrograms, payload: { insuranceProgram: program } });
};
