import _ from 'lodash';
import { AxiosResponse } from 'axios';

import createInstance from './axios';
import { actsListType } from 'types/actsList';
import { makerRequestParams } from 'utils/helpers';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  NewAgentActContractsReportDto,
  ResultListDtoAgentActView,
  AgentActInfoDto,
  AgentActsDto,
  AgentActDto,
} from 'types/dto/contracts-service';

const actsAPI = createInstance('CONTRACTS');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');

export const getActDetails = (id, { sorting, pagination }: any) => {
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;

  const requestParams = {
    sorting: sortingQuery,
    page: pagination.current,
    page_size: pagination.perPage,
  };

  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return actsAPI.get<ResultListDtoAgentActView>(
    `/agents/acts/${id}?count&total&totalAttributes=feeSum,paymentAmount,calculatedPaymentAmount,toPay`,
    {
      params: {
        ...requestParams,
      },
    },
  );
};

export const deleteAct = id => {
  return actsAPI.delete<void>(`/agents/acts/${id}`);
};

export const withDrawAct = id => {
  return actsAPI.post<void>(`/agents/acts/${id}/withdraw-confirmation`);
};

export const confirmAct = id => {
  return actsAPI.post<void>(`/agents/acts/${id}/confirm`);
};

export const getActInfo = id => {
  return actsAPI.get<AgentActInfoDto>(`/agents/acts/${id}/info`);
};

export const updateActDetails = (id, data) => {
  return actsAPI.put<void>(`/agents/acts/${id}`, {
    ...data,
  });
};

export const getPrivateFiltersTable = (
  id,
  {
    filters,
    pagination,
  }: {
    filters: any;
    pagination: { current: number | string; perPage: number | string };
  },
) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  return actsAPI.get<ResultListDtoAgentActView>(`/agents/acts/${id}?count`, {
    params: {
      ..._.omit(filters),
      page_size: pagination.perPage,
      page: pagination.current,
      distinct: true,
    },
  });
};

export const getTablePage = ({
  filters,
  sorting,
  pagination,
}: Pick<actsListType, 'filters' | 'pagination' | 'sorting' | 'currentContract'>) => {
  const {
    insuranceProgramId: { id: insuranceProgramId },
    insuranceCompanyId: { id: partnerId },
    agentContractId,
  } = filters;

  const requestParams: { [key: string]: any } = {
    page: pagination.current,
    page_size: pagination.perPage,
    actDate: `${filters.dateFrom}..${filters.dateTo}`,
    sorting: sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`,
    agentContractId,
    insuranceProgramId,
    partnerId,
  };
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return actsAPI.get<AgentActsDto>(
    '/agents/acts?attributes=countPaidContracts,actNumber,actDate,dateFrom,dateTo,countContracts,sumInsurancePayments,feeSum,baseFeeSum,actSum,confirmationDate,paid,id&distinct',
    {
      params: requestParams,
    },
  );
};

export const getActDefaultData = (id: number): any => {
  return actsAPI.get<AgentActDto>(`/agents/acts/init-creation/?agentContractId=${id}`);
};

export const getAvaliableContractsTablePage = ({
  sorting,
  pagination,
  filterDate,
  id,
  partnerId,
  insuranceProgramId,
  agentContractId,
}: any) => {
  const requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    filterDate: `<=${filterDate}`,
    sorting: sorting.field,
    partnerId,
    insuranceProgramId,
    agentContractId,
  };
  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return actsAPI.get<NewAgentActContractsReportDto>(`/agent-contracts/${id}/available/contracts?count`, {
    params: requestParams,
  });
};

export const getAvaliableContracts = ({
  pagination,
  sorting,
  partnerId,
  agentContractId,
  insuranceProgramId,
  filterDate,
  ...params
}) => {
  const requestParams = {
    ...params,
    page: pagination.current,
    page_size: pagination.perPage,
    filterDate: `<=${filterDate}`,
    sorting: sorting.field,
    partnerId,
    insuranceProgramId,
    agentContractId,
  };
  return actsAPI.get<NewAgentActContractsReportDto>(`/agent-contracts/${agentContractId}/available/contracts?count`, {
    params: requestParams,
  });
};

export const updateAgentAct = ({
  contractId,
  dateTo,
  excludedContracts,
  agentContractId,
  insuranceProgramId,
  partnerId,
  actDate,
}: any) => {
  let requestBody = {
    agentContractId,
    insuranceProgramId,
    partnerId,
    filterDate: `<=${dateTo}`,
    actDate,
  };

  if (!requestBody.insuranceProgramId) {
    delete requestBody.insuranceProgramId;
  }

  const actDateValue = requestBody.actDate;
  delete requestBody.actDate;

  return actsAPI.post<number>(`/agents/acts`, {
    actDateTo: `${dateTo}T00:00:00`,
    actDate: `${actDateValue}T00:00:00`,
    agentContractId: contractId,
    excludeContracts: excludedContracts,
    filters: {
      ...requestBody,
    },
  });
};

export const downloadActPdf = (id: number | string): Promise<AxiosResponse<Blob>> => {
  const timeZone = momentTz.tz.guess(true);

  return contractsPdfAPI.get(`/agents/acts/${id}/pdf-report`, {
    params: {
      timeZone,
    },
  });
};

export const downloadActExcel = (
  id: number | string,
  dateFrom: string,
  dateTo: string,
): Promise<AxiosResponse<Blob>> => {
  let requestParams = {
    id,
    actDate: `${dateFrom}..${moment(dateTo).format('YYYY-MM-DD')}`,
  };
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/agents/acts/${id}/xlsx-report`, {
    responseType: 'arraybuffer',
    params: {
      ...requestParams,
      timeZone,
    },
  });
};

export const downloadActAgentExcel = (
  id: number | string,
  agentContractId: number | string,
  insuranceProgramId: number | string,
  dateFrom: string,
  dateTo: string,
): Promise<AxiosResponse<Blob>> => {
  let requestParams = {
    id,
    insuranceProgramId,
    agentContractId,
    actDate: `${dateFrom}..${moment(dateTo).format('YYYY-MM-DD')}`,
  };
  const timeZone = momentTz.tz.guess(true);

  return contractsPdfAPI.get(`/agents/acts/${id}/contracts/xlsx-report`, {
    responseType: 'arraybuffer',
    params: {
      ...requestParams,
      timeZone,
    },
  });
};

export const downloadActsAsXLS = async params => {
  const timeZone = momentTz.tz.guess(true);
  return actsAPI.get('/agents/acts/print/xlsx', {
    responseType: 'arraybuffer',
    params: {
      ...params,
      timeZone,
    },
  });
};

export const getCreateActStatistic = ({
  contractId,
  dateTo,
  excludedContracts,
  agentContractId,
  insuranceProgramId,
  partnerId,
}) => {
  let requestBody = {
    agentContractId,
    insuranceProgramId,
    partnerId,
    filterDate: `<=${dateTo}`,
  };

  if (!requestBody.insuranceProgramId) {
    delete requestBody.insuranceProgramId;
  }

  return actsAPI.post<NewAgentActContractsReportDto>(`/agent-contracts/sum-calculation`, {
    actDateTo: `${dateTo}T00:00:00`,
    agentContractId: contractId,
    excludeContracts: excludedContracts,
    filters: {
      ...requestBody,
    },
  });
};
