import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import styles from './CreateModal.module.less';
import closeIcon from 'assets/img/layout/greyClose.svg';
import { AppState } from 'store/reducer';
import { Product } from 'components/modals/CreateModal/products/Products';
import { useTranslation } from 'react-i18next';
import { setInsuranceProductCode } from 'store/contractDetails/actions';

interface ICreateModal {
  isOpen: boolean;
  handleClose: () => void;
}

const CreateModal = ({ isOpen, handleClose }: ICreateModal) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((store: AppState) => store.insuranceProducts.products);

  const handleClick = code => {
    sessionStorage.setItem('insuranceProductCode', code);
    dispatch(setInsuranceProductCode(code));
  };

  return (
    <Modal
      visible={isOpen}
      centered
      className={styles.wrapper}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
      destroyOnClose={true}
      closable={false}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}> {t('contract_list.choose_an_insurance_program')} </div>
          <div className={styles.close} onClick={handleClose}>
            <div className={styles.closeIcon} data-id="Close-Icon">
              <img src={closeIcon} width="16" height="16" alt="close icon" />
            </div>
          </div>
        </div>
        <div className={styles.content} onClick={handleClose}>
          {products.map(item => {
            return (
              <Product
                name={item.name}
                code={item.code}
                key={item.id}
                handleClose={handleClose}
                handleClick={handleClick}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default CreateModal;
