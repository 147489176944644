import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DatePicker, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';

import {
  fetch as fetchInsuredEvents,
  setPartners,
  setInsuranceCompanies,
  setFilters,
  setInsuredELInsuranceProgram,
  setPagination,
  setSelectedChoose,
} from 'store/insuredEventsList/actions';
import { AppState } from 'store/reducer';

import { formatDatePeriod } from '../../tables/InsuredEventsTable/helper';
import {
  CHOOSE_FILTER_TYPE,
  chooseForOptionsFilter,
  defaultValuesAll,
  extraOptionsAll,
} from '../components/Select/options';
import FilterSelect from '../components/Select/Select';

import { getDatePickerLng } from 'utils/helpers';

import styles from './InsuredEventsFilters.module.less';

const DATE_FORMAT = 'DD.MM.YYYY';

export const baseTimes: { startTime: string; endTime: string } = {
  startTime: 'T00:00:00',
  endTime: 'T23:59:59',
};

const parseDateRange = (range: string) => {
  if (!range) {
    return {
      startDate: moment(new Date(), DATE_FORMAT).format('YYYY-MM-DD'),
      endDate: moment(new Date(), DATE_FORMAT).format('YYYY-MM-DD'),
    };
  }

  const [startDate, endDate] = range.split('..');
  return {
    startDate,
    endDate,
  };
};

const InsuredEventsFilters = ({ activeTab }: { activeTab?: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentLanguage = useSelector((store: AppState) => store.userCard.language);
  const role = useSelector((store: AppState) => store.user?.role);
  const {
    filters,
    chooseType: { chooseFilterName },
    filters: { insuranceProgramId, insuranceCompanyId, insurancePartnerId },
    pagination,
    insuranceCompanies,
    partners,
    insurancePrograms,
  } = useSelector(
    ({
      insuredEventsList: { filters, pagination, insuranceCompanies, partners, insurancePrograms, chooseType },
    }: AppState) => ({
      filters,
      pagination,
      insuranceCompanies,
      partners,
      insurancePrograms,
      chooseType,
    }),
  );

  const [state, setState] = useState<{ startDate: any; endDate: any }>(parseDateRange((filters as any).claimDate));

  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState(filters.insuranceCompanyId);
  const [selectedPartner, setSelectedPartner] = useState(filters.insuranceCompanyId);
  const [selectedProgram, setSelectedProgram] = useState(filters.insuranceProgramId);
  const [selectedChoose, setSelectedChooseType] = useState<string>('');
  const [fieldsActiveState, setFieldsActiveState] = useState({
    selectedInsuranceCompany: false,
    selectedPartner: false,
    selectedProgram: false,
  });

  const defaultinsuranceCompanyValue = role === 'ROLE_ADMIN' ? defaultValuesAll(t).name : insuranceCompanies[0]?.name;
  const insuranceCompanyOptions =
    role === 'ROLE_ADMIN' ? extraOptionsAll(insuranceCompanies, t) : [...insuranceCompanies];

  useEffect(() => {
    if (!insuranceCompanies?.length) {
      dispatch(setInsuranceCompanies(t));
    }

    if (role !== 'ROLE_ADMIN') {
      const filteredCompany = insuranceCompanies[0];
      dispatch(setPartners(t, null, filteredCompany?.id!));
    }

    setSelectedChooseType(t(CHOOSE_FILTER_TYPE.APPLICATION_DATE));
  }, []);

  useEffect(() => {
    if (insuranceCompanyId?.name || insurancePartnerId?.name || insuranceProgramId?.name || chooseFilterName) {
      setSelectedChooseType(chooseFilterName ? chooseFilterName : t(CHOOSE_FILTER_TYPE.APPLICATION_DATE));
      setSelectedPartner(insurancePartnerId);
      setSelectedProgram({ ...insuranceProgramId });
      setSelectedInsuranceCompany(insuranceCompanyId);
    }
  }, [insuranceCompanyId, insurancePartnerId, insuranceProgramId, chooseFilterName]);

  const handleSubmit = () => {
    dispatch(
      setFilters({
        ...filters,
        claimDate: formatDatePeriod(state.startDate, state.endDate),
        insuranceProgramId: selectedProgram,
        insuranceCompanyId: selectedInsuranceCompany || selectedPartner,
        insurancePartnerId: selectedPartner,
      }),
    );
    dispatch(
      setSelectedChoose({
        chooseFilterName: selectedChoose,
      }),
    );
    dispatch(setPagination(1, pagination.perPage));
    dispatch(fetchInsuredEvents({}, t));
  };

  const onStartDateChange = useCallback(
    (m, dateString) => {
      setState(prevState => ({
        ...prevState,
        startDate: moment(dateString, DATE_FORMAT).format('YYYY-MM-DD'),
      }));
      dispatch(
        setFilters({
          ...filters,
          claimDate: formatDatePeriod(moment(dateString, DATE_FORMAT).format('YYYY-MM-DD'), state.endDate),
        }),
      );
    },
    [filters],
  );

  const onEndDateChange = useCallback(
    (m, dateString) => {
      setState(prevState => ({
        ...prevState,
        endDate: moment(dateString, DATE_FORMAT).format('YYYY-MM-DD'),
      }));
      dispatch(
        setFilters({
          ...filters,
          claimDate: formatDatePeriod(state.startDate, moment(dateString, DATE_FORMAT).format('YYYY-MM-DD')),
        }),
      );
    },
    [filters],
  );

  const handleInsuranceCompanyChange = (name: any, el: { key: string }) => {
    setSelectedProgram(defaultValuesAll(t));
    setSelectedPartner(defaultValuesAll(t));
    setSelectedInsuranceCompany(defaultValuesAll(t));

    const filteredCompany = insuranceCompanies?.find((x: any) => x.name === name);

    if (Number(el.key) === 0) {
      setSelectedInsuranceCompany(defaultValuesAll(t));
    }

    if (filteredCompany) {
      setSelectedInsuranceCompany(filteredCompany);
    }

    dispatch(setPartners(t, null, filteredCompany?.id!));
  };

  const handleCompanyChange = (name: any, el: { key: string }) => {
    setSelectedProgram(defaultValuesAll(t));
    setSelectedPartner(defaultValuesAll(t));

    const filteredCompany = partners.find((x: any) => x.name === name);

    if (Number(el.key) === 0) {
      setSelectedPartner(defaultValuesAll(t));
    }

    if (filteredCompany) {
      setSelectedPartner(filteredCompany);
    }

    dispatch(setInsuredELInsuranceProgram(t, filteredCompany?.id!));
  };

  const handleProgramChange = (name: any, el: { key: string }) => {
    const filteredProgram = insurancePrograms.find((x: any) => x.name === name);

    if (Number(el.key) === 0) {
      setSelectedProgram(defaultValuesAll(t));
    }

    if (filteredProgram) {
      setSelectedProgram(filteredProgram);
    }
  };

  const handleChooseForChange = (name: string) => {
    setSelectedChooseType(name);
  };

  const handleFieldsFocus = (eventName: string, fieldName: string, force?: boolean) => {
    // @ts-ignore
    if (!!filters[fieldName] && !force) {
      return;
    } else {
      if (eventName === 'focus') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: true });
      }
      if (eventName === 'blur') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: false });
      }
    }
  };

  const getCurrentValue = (list: any): string => {
    return list.name || defaultValuesAll(t).name;
  };

  return (
    <div className={styles.container}>
      <FilterSelect
        showSearch
        label={t('insured_events_list.insurance_company_table')}
        labelActive={true}
        value={selectedInsuranceCompany?.name || defaultinsuranceCompanyValue}
        options={insuranceCompanyOptions.map((c: any) => ({
          ...c,
          value: c.name,
          key: c.name,
          id: c.id,
        }))}
        optionLabel="name"
        onSelect={(name: string, el: any) => handleInsuranceCompanyChange(name, el)}
        handleFocus={(e: any) => handleFieldsFocus(e.type, 'selectedInsuranceCompany')}
      />
      <FilterSelect
        showSearch
        label={t('insured_events_list.agency_company')}
        labelActive={true}
        value={getCurrentValue(selectedPartner)}
        options={extraOptionsAll(partners, t).map((c: any) => ({
          ...c,
          value: c.name,
          key: c.name,
          id: c.id,
        }))}
        optionLabel="name"
        shortOptionLabel="shortName"
        onSelect={(name: string, el: any) => handleCompanyChange(name, el)}
        handleFocus={(e: any) => handleFieldsFocus(e.type, 'selectedPartner')}
      />
      <FilterSelect
        showSearch
        label={t('insured_events_list.insurance_program')}
        labelActive={true}
        value={getCurrentValue(selectedProgram)}
        options={extraOptionsAll(insurancePrograms, t).map((c: any) => ({
          ...c,
          value: c.name,
          key: c.name,
          id: c.id,
        }))}
        optionLabel="name"
        onSelect={(name: string, el: any) => handleProgramChange(name, el)}
        handleFocus={(e: any) => handleFieldsFocus(e.type, 'selectedProgram')}
      />
      <FilterSelect
        label={t('insured_events_list.choose_for')}
        labelActive={true}
        value={selectedChoose}
        options={chooseForOptionsFilter(t)}
        optionLabel="name"
        handleFocus={() => {}}
        onSelect={handleChooseForChange}
      />
      <ConfigProvider locale={getDatePickerLng(currentLanguage)}>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('insured_events_list.call_period_from')}</div>
          <DatePicker
            value={moment(state.startDate)}
            allowClear={false}
            onChange={onStartDateChange}
            format={DATE_FORMAT}
            size="large"
            placeholder={t('insured_events_list.call_period_from')}
            className={styles.dateField}
          />
        </div>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('insured_events_list.call_period_on')}</div>
          <DatePicker
            value={moment(state.endDate)}
            allowClear={false}
            onChange={onEndDateChange}
            format={DATE_FORMAT}
            size="large"
            placeholder={t('insured_events_list.call_period_on')}
            className={styles.dateField}
          />
        </div>
      </ConfigProvider>
      <Button
        onClick={handleSubmit}
        size="large"
        type="primary"
        htmlType="submit"
        className={classNames('login-form-button', styles.searchButton)}
      >
        {t('insured_events_list.install_button')}
      </Button>
    </div>
  );
};

export default InsuredEventsFilters;
