import { createTypes } from 'redux-compose-reducer';
import history from 'routes/history';
import { AppState } from '../reducer';
import * as termApi from 'api/termination';
import { ThunkAction } from 'redux-thunk';
import { StoreType } from 'types/store';
import { Action } from 'redux';
import { formValues } from 'types/terminationTypes';

import { getContractDetails, getTermninationAccountTypes, getTermninationAccountTypesForCreation } from 'api/contracts';
import { ROUTES } from 'constants/routes';
import { notification } from 'antd';
import { termAdapter } from 'constants/validationErrors';
import { formatDocumentsData } from 'utils/helpers';
import onDownload from '../../callbacks/onDownload';
import _ from 'lodash';
import { TerminationInitiatorDtoCode } from 'types/dto/contracts-service';

export const TYPES = createTypes('setTermination', [
  'termination',
  'terminationTemplate',
  'terminationState',
  'terminationId',
  'terminationName',
  'clearTermination',
  'setDocumentsData',
  'setDocumentInfo',
  'setDocumentDates',
  'setDocumentDelete',
  'setRefundSum',
  'clearBankCredentials',
  'setTerminationCause',
  'setInitiators',
  'setInitiator',
  'partnerProgramId',
  'setProlongDate',
  'setSignData',
  'setAcountTypes',
]);

type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const setTerminationName = (fieldName: string, fieldValue: string): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.terminationName, payload: { [fieldName]: fieldValue } });
};

export const getTerminationClaimPdf = (terminationId: number, t: any): ThunkResult => async () => {
  await onDownload(() => termApi.printContract(terminationId), undefined, t);
};

export const fetchTerminationCauses = ({
  isUpdate,
  initiator,
  insuranceProgramId,
  initiatorId,
}: {
  isUpdate: boolean;
  initiator?: { code: number | string; name: string };
  insuranceProgramId?: number | null;
  initiatorId?: number;
}): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.terminationState, payload: { loading: true } });
  try {
    let response;
    if (isUpdate && initiator?.code && insuranceProgramId) {
      response = await termApi.getTerminationCauses(initiator?.code, insuranceProgramId);
    } else if (insuranceProgramId && initiatorId) {
      response = await termApi.getTerminationCausesForCreation(initiatorId, insuranceProgramId);
    }

    const ids = response.data.map(o => o.id);
    const filteredData = response.data.filter(({ id }, index) => !ids.includes(id, index + 1));

    dispatch({ type: TYPES.terminationState, payload: { loading: false, causes: filteredData } });
  } catch (error) {
    dispatch({ type: TYPES.terminationState, payload: { loading: false, errorMessage: error } });
  }
};

export const fetchInitiators = ({
  isUpdate,
  contractId,
  insuranceProgramId,
}: {
  isUpdate: boolean;
  contractId?: number;
  insuranceProgramId?: number | null;
}): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.terminationState, payload: { loading: true } });
  try {
    let response;
    if (isUpdate && contractId) {
      response = await termApi.getTerminationInitiators(contractId);
    } else if (insuranceProgramId) {
      response = await termApi.getTerminationInitiatorsForCreation(insuranceProgramId);
    }

    dispatch({ type: TYPES.setInitiators, payload: { initiators: response.data } });
    return response.data;
  } catch (error) {
    dispatch({ type: TYPES.terminationState, payload: { loading: false, errorMessage: error } });
  }
};

export const fetchAcountTypes = ({
  isUpdate,
  initiatorCode,
  insuranceProgramId,
  terminationInitiatorId,
}: {
  isUpdate: boolean;
  initiatorCode?: string;
  insuranceProgramId?: number | null;
  terminationInitiatorId?: number;
}): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.terminationState, payload: { loading: true } });
  try {
    let response;
    if (isUpdate && initiatorCode) {
      response = await getTermninationAccountTypes(initiatorCode);
    } else if (insuranceProgramId && terminationInitiatorId) {
      response = await getTermninationAccountTypesForCreation(insuranceProgramId, terminationInitiatorId);
    }

    dispatch({ type: TYPES.setAcountTypes, payload: { accountTypes: response.data } });
  } catch (error) {
    dispatch({ type: TYPES.terminationState, payload: { loading: false, errorMessage: error } });
  }
};

export const fetchTerminationTemplate = (id: number, t): ThunkResult => async (dispatch, getState) => {
  dispatch({ type: TYPES.terminationState, payload: { loading: true } });

  try {
    const {
      termination: { disableSubmit },
    }: AppState = getState();
    if (disableSubmit) {
      return history.push(`${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${id}`);
    }

    const terminationDetails = (await termApi.getContractTerminationTemplates({ contractId: id })).data;
    dispatch({
      type: TYPES.terminationTemplate,
      payload: {
        terminationDetails: {
          ...terminationDetails,
          // cause: terminationDetails.cause || terminationState.cause,
        },
      },
    });
  } catch (error) {
    error?.response?.data?.errors &&
      error.response.data.errors.forEach((el: any) => {
        if (el.code === 'POLICY_HOLDER_CAUSE_IMPOSSIBLE') {
          return notification.error({
            message: t('popup.impossible_break'),
          });
        }
      });
    dispatch({ type: TYPES.terminationState, payload: { loading: false, errorMessage: error } });
  } finally {
    dispatch({ type: TYPES.terminationState, payload: { loading: false } });
  }
};

export const setTerminationFields = (values: formValues): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.termination, payload: { ...values } });
};

export const clearTerminationBankCredentials = () => async (dispatch: any) => {
  dispatch({ type: TYPES.clearBankCredentials });
};

export const sendTerminationTemplate = (cb?: () => void, form?: any, documents?: any, t?): ThunkResult => async (
  dispatch,
  getState,
) => {
  dispatch({ type: TYPES.terminationState, payload: { loading: true } });

  try {
    const {
      termination: {
        terminationDetails,
        terminationDetails: { id: currentId }, // wait for the termination update
      },
    } = getState();
    let response = currentId
      ? (await termApi.updateContractTermination({ id: currentId, terminationDetails })).data
      : (await termApi.createContractTermination(terminationDetails)).data;
    const terminationId = response.id || currentId;

    const createRequestArr = () => {
      return documents.reduce((acc: any, doc: any) => {
        let requestArr: any = [];
        Array.from(doc.files).forEach((e: any) => {
          const formData = new FormData();
          formData.append('file', e);
          requestArr.push(termApi.documentUpload(terminationId ?? 0, doc.code, formData));
        });
        return [...acc, ...requestArr];
      }, []);
    };

    await Promise.all(createRequestArr())
      .then(async () => {
        notification.success({
          message: !cb ? t('popup.statement_saved') : t('popup.statement_has_been_saved'),
        });
      })
      .catch(_ => {
        notification.info({
          message: t('popup.error'),
          description: t('popup.some_documents_not_downloaded'),
          duration: 0,
        });
      })
      .finally(async () => {
        history.replace(
          ROUTES.TERMINATIONS.READ.replace(':id', (response.contract?.id ?? 0).toString()).replace(
            ':terminationId',
            (response.id ?? 0).toString(),
          ),
        );
        dispatch({ type: TYPES.terminationTemplate, payload: { response } });
        if (terminationId) dispatch(fetchTerminationTemplateById(+terminationId, cb));
      });
  } catch (error) {
    if (error?.response?.status === 400) {
      if (error?.response?.data?.errors) {
        return error.response.data.errors.forEach((el: any) => {
          if (termAdapter(t)[el.field]) {
            const name = `${termAdapter(t)[el.field]['name']}`;
            const errorw = termAdapter(t)[el.field][el.code];
            const value = form.getFieldValue(name);
            form.setFields({
              [name]: {
                value: value,
                errors: [new Error(errorw)],
              },
            });
          }

          if (el.code === 'POLICY_HOLDER_CAUSE_IMPOSSIBLE') {
            return notification.error({
              message: t('popup.impossible_break'),
            });
          } else {
            notification.error({
              message: t('popup.save_error'),
            });
          }
        });
      }
    }

    notification.error({
      message: t('popup.save_error'),
    });
    dispatch({ type: TYPES.terminationState, payload: { loading: false } });
  } finally {
    dispatch({ type: TYPES.terminationState, payload: { loading: false } });
  }
};

export const fetchTerminationTemplateById = (id: number, cb?: any): ThunkResult => async dispatch => {
  dispatch({ type: TYPES.terminationState, payload: { loading: true } });
  try {
    const terminationDetails = (await termApi.getContractTermination({ terminationId: id })).data;
    dispatch({ type: TYPES.terminationTemplate, payload: { terminationDetails } });
    dispatch({
      type: TYPES.terminationState,
      payload: { disableSubmit: !!terminationDetails.signDate, isReadOnly: !!terminationDetails.signDate },
    });
    cb && cb(terminationDetails.documents, id, terminationDetails);
  } catch (error) {
    dispatch({ type: TYPES.terminationState, payload: { errorMessage: error } });
  } finally {
    dispatch({ type: TYPES.terminationState, payload: { loading: false } });
  }
};

export const getVerificationCode = (
  id: number | string,
  terminationDetails: any,
  form: any,
  callback?: any,
  t?,
): ThunkResult => async dispatch => {
  try {
    await termApi.getTerminationVerificationToken({ id, terminationDetails });
    callback();
  } catch (error) {
    error?.response?.data?.errors &&
      error.response.data.errors.forEach((el: any) => {
        if (termAdapter(t)[el.field]) {
          const name = `${termAdapter(t)[el.field]['name']}`;
          const errorw = termAdapter(t)[el.field][el.code];
          const value = form.getFieldValue(name);
          form.setFields({
            [name]: {
              value: value,
              errors: [new Error(errorw)],
            },
          });
        }

        if (el.code === 'POLICY_HOLDER_CAUSE_IMPOSSIBLE') {
          return notification.error({
            message: t('popup.impossible_break'),
          });
        }
        if (el.code === 'CONTRACT_HAS_CLAIM_WITH_STATUS_PAYMENT_AWAITING') {
          return notification.error({
            message: t('popup.unable_sign'),
            description: t('popup.express_payment_made'),
          });
        }
      });
    dispatch({ type: TYPES.terminationState, payload: { loading: false, errorMessage: error } });
  }
};

export const sendVerificationCode = (token?: string, cb?: () => void): ThunkResult => async (dispatch, getState) => {
  dispatch({ type: TYPES.terminationState, payload: { loading: true } });

  try {
    const {
      termination: {
        terminationDetails: { id },
      },
    } = getState();
    let terminationId = id;
    if (!id) {
      terminationId = Number(history.location.pathname.split('/').reverse()[0]);
    }

    if (terminationId) {
      const data = { id: terminationId, token: token };
      await termApi.signTerminationVerification(data);
    }
    dispatch(fetchTerminationTemplateById(Number(terminationId)));
    // dispatch({ type: TYPES.terminationState, payload: { disableSubmit: true } });

    cb && cb();
  } catch (error) {
    dispatch({ type: TYPES.terminationState, payload: { wrongCode: error } });
  } finally {
    dispatch({ type: TYPES.terminationState, payload: { loading: false } });
  }
};

export const checkContractStatus = (id: number): ThunkResult => async dispatch => {
  const contract = (await getContractDetails(id)).data;
  dispatch({
    type: TYPES.terminationState,
    payload: {
      disableSubmit: contract && contract.status === 'FINISHED',
      userType: contract.signatory?.type,
      contractTerminationId: contract.terminationId,
    },
  });

  dispatch({
    type: TYPES.partnerProgramId,
    payload: {
      insuranceProgramId: contract.insuranceProgram?.id,
      partnerId: contract.partnerId,
    },
  });

  dispatch({
    type: TYPES.setProlongDate,
    payload: {
      prolongationStartDate: contract.prolongationStartDate,
      prolongationEndDate: contract.prolongationEndDate,
    },
  });

  dispatch({
    type: TYPES.setSignData,
    payload: {
      signDate: contract.signDate,
      originSignDate: contract.originalContractSignDate,
    },
  });

  if (contract.terminationId)
    history.replace(
      ROUTES.TERMINATIONS.READ.replace(':id', (contract.id ?? 0).toString()).replace(
        ':terminationId',
        (contract.terminationId ?? 0).toString(),
      ),
    );

  return contract && contract.status === 'FINISHED';
};

export const setTerminationId = (id: number): ThunkResult => dispatch => {
  dispatch({ type: TYPES.terminationId, payload: { terminationId: id } });
};

export const clearTerminationData = () => ({ type: TYPES.clearTermination });

export const getTerminationDocumentsAndSum = (
  initiator: TerminationInitiatorDtoCode,
  contractId: number | string,
  t,
): ThunkResult => async (dispatch: any, getState: any) => {
  try {
    const response = await termApi.getTerminationDocumentsAndSum(initiator, contractId);
    const configuration = response.data ? formatDocumentsData(response.data.configuration) : [];
    const refundSum = response.data.refundSum;

    const {
      termination: {
        terminationDetails: { documents = [] },
      },
    }: AppState = getState();

    dispatch({
      type: TYPES.setDocumentsData,
      payload: _.unionBy(documents, configuration, 'documentType.id'),
    });
    dispatch({ type: TYPES.setRefundSum, payload: refundSum });
  } catch (err) {
    if (err?.response?.data?.errors) {
      return err.response.data.errors.forEach((el: any) => {
        if (el.code === 'POLICY_HOLDER_CAUSE_IMPOSSIBLE') {
          return notification.error({
            message: t('popup.impossible_break'),
          });
        }
        //@ts-ignore
        if (!window.navigator.connection.downlink) {
          notification.error({
            message: t('popup.no_connection'),
            description: t('popup.check_your_network_connection'),
          });
        } else {
          notification.error({
            message: t('popup.something_went_wrong'),
            description: t('popup.load_document_list'),
          });
        }
      });
    }
    //@ts-ignore
    if (!window.navigator.connection.downlink) {
      notification.error({
        message: t('popup.no_connection'),
        description: t('popup.check_your_network_connection'),
      });
    } else {
      notification.error({
        message: t('popup.something_went_wrong'),
        description: t('popup.load_document_list'),
      });
    }
  }
};

export const setDocumentInfo = (value: string, id: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setDocumentInfo, payload: { value, id } });
};

export const setDocumentDates = ({
  id,
  createDate,
  approveDate,
}: {
  id: number;
  createDate?: string;
  approveDate?: string;
}) => (dispatch: any) => {
  dispatch({ type: TYPES.setDocumentDates, payload: { id, createDate, approveDate } });
};

export const deleteDocument = (id: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setDocumentDelete, payload: { id } });
};
