import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import * as api from 'api/auth';
import { useRequired } from 'hooks/useRequired';
import NumberField from 'components/next/NumberField/NumberField';
import Button from 'components/next/Button/Button';

import { schema, defaultValues, Values } from './Verification.schema';
import { State } from '../PasswordRecovery';
import CountDown from '../CountDown/CountDown';

const cssSubmit = css`
  margin-top: 24px;
  width: 150px;
`;

const COUNTDOWN_MS = 5 * 60 * 1000 - 1 * 1000;

type Props = {
  setState: React.Dispatch<Partial<State>>;
  state: State;
};

const Verification = ({ setState, state }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_MS);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { required } = useRequired(schema);

  const onSubmit = (values: Values) => {
    setLoading(true);

    api
      .isValidTokenUsingGet(state.login, values.token.toString())
      .then(() => {
        setState({ step: 'confirmPassword', token: Number(values.token) });
      })
      .catch(err => {
        reset();

        notification.error({
          message: t('passwordRecovery.check_the_data'),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CountDown date={Date.now() + countdown} onTick={() => setCountdown(countdown - 1 * 1000)} />

      <form onSubmit={handleSubmit(onSubmit)} data-test="password-recovery-verification-form">
        <Controller
          name="token"
          control={control}
          render={({ onChange, value, name }) => (
            <NumberField
              info={{ name, errors, required }}
              value={value}
              onChange={onChange}
              label={t('passwordRecovery.code_from_sms')}
              allowLeadingZeros
            />
          )}
        />

        <Button type="primary" size="large" htmlType="submit" loading={loading} className={cssSubmit}>
          {t('passwordRecovery.continue')}
        </Button>
      </form>
    </>
  );
};

export default Verification;
