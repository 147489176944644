import createInstance from './axios';
import { servicesTypesMock, servicesMock } from 'mocks/services';
import { clinicsMock } from 'mocks/clinics';
import { Clinic, RawServiceType, RawService, Service } from 'types/institutions';
import { ResultListDtoContractView } from 'types/dto/contracts-service';

const institutionApi = createInstance('INSTITUTION');

const contractsApi = createInstance('CONTRACTS');

export const getContracts = async () => {
  const searchAttributes = {
    count: '',
    total: '',
    totalAttributes: 'paymentAmount,calculatedPaymentAmount',
    attributes:
      'originalContractNumber,status,number,phoneNumber,insuranceProductCode,clientName,insuranceObjectPid,insuranceObjectName,conclusionDate,startDate,endDate,insuranceAmount,paymentAmount,numberPayments,id,calculatedPaymentAmount,partnerDepartmentRegion,sellerName,sellerPhoneNumber,insuranceObjectType,verifiedAt,subAgreementNumber,subAgreementConclusionDate,participateInAgentAct,test',
    conclusionDate: '2021-12-01..2021-12-03',
    status: 'ACTIVE',
    sorting: '-conclusionDate,-id',
    page_size: '50',
    page: '1',
    distinct: 'true',
    current: 'true',
  };

  const searchParams = new URLSearchParams(searchAttributes).toString();

  const {
    data: { resultList = [] },
  } = await contractsApi.get<ResultListDtoContractView>('/contracts?' + searchParams);

  return resultList;
};

export const getServices = async () => {
  const serviceTypes: Array<RawServiceType> = servicesTypesMock;

  const services = await Promise.all(
    serviceTypes.map(({ id, name, hasChildren, couldBeOnline }) => {
      const children: Array<RawService> = hasChildren ? servicesMock.filter(({ parentId }) => parentId === id) : [];

      const service: Service = {
        id,
        name,
        couldBeOnline,
        children,
        parentId: id,
      };

      return service;
    }),
  );

  return services;
};

export const getClinics = async () => {
  return clinicsMock as Array<Clinic>;
};

export const getLocalities = async () => {
  const { data } = await institutionApi.get('/address/localities');
  return data.localities;
};

export const getCities = async (localityName: string) => {
  const { data } = await institutionApi.get(`/address/cities/${localityName}`);
  return data.cities;
};

export const getInstitutions = (pagination: any, filters?): Promise<any> => {
  let requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    count: true,
    ...filters,
  };

  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return institutionApi.get(`/institutions`, {
    params: {
      ...requestParams,
    },
  });
};

export const getInstitution = async (id: number) => {
  const { data } = await institutionApi.get(`/institutions/${id}`);
  return data;
};

export const addInstitution = async (values: any): Promise<any> => {
  return await institutionApi.post(`/institutions/add`, {
    ...values,
  });
};

export const removeInstitution = async (id: number): Promise<any> => {
  return await institutionApi.delete(`/institutions/${id}`);
};

export const updateInstitution = async (values: any, id: number): Promise<any> => {
  return await institutionApi.put(`/institutions/${id}`, {
    ...values,
  });
};

export const getServiceTypeServices = async () => {
  const { data } = await institutionApi.get(`/service/type/services`);
  return data;
};

export const getAllServices = async ({ pagination, sorting, filters }) => {
  const filtersData = { ...filters };

  Object.keys(filtersData).forEach(key => {
    if (filtersData[key] === null || !filtersData[key]) {
      delete filtersData[key];
    } else {
      return filtersData[key];
    }
  });

  const sortingData = sorting
    ? { sorting: sorting.direction === 'DESC' ? `-${sorting.field}` : `${sorting.field}` }
    : {};

  const params = {
    page: pagination.current,
    page_size: pagination.perPage,
    count: true,
    ...sortingData,
    ...filtersData,
  };

  const { data } = await institutionApi.get('/service/services', {
    params,
  });
  return data;
};

export const getServiceById = async id => {
  const { data } = await institutionApi.get(`/service/${id}`);
  return data;
};

export const addService = async body => {
  const { data } = await institutionApi.post('/service/add', body);
  return data;
};

export const editService = async (id, body) => {
  const { data } = await institutionApi.put(`/service/${id}`, body);
  return data;
};

export const getInstitutionsClasses = async () => {
  const { data } = await institutionApi.get(`/institutions/classes`);
  return data;
};

export const getInstitutionsClassesByInsuranceProgramId = async id => {
  const { data } = await institutionApi.get(`/institutions/classes`, {
    params: {
      insuranceProgramId: id,
    },
  });
  return data;
};

export const getInstitutionsBrands = async () => {
  const { data } = await institutionApi.get(`/institutions/brands`);
  return data;
};

export const getInstitutionsBrandsByInsuranceProgramId = async id => {
  const { data } = await institutionApi.get(`/institutions/brands`, {
    params: {
      insuranceProgramId: id,
    },
  });
  return data;
};

export const getInsuranceProgramInstitutions = async (id: number) => {
  const { data } = await institutionApi.get(`/institutions/insurance-program/${id}`);
  return data;
};

export const addInsuranceProgram = async body => {
  const { data } = await institutionApi.post(`/institutions/insurance-program/add`, body);
  return data;
};

export const getDoctorEleksServices = async (id: number) => {
  const { data } = await institutionApi.get(`/doctor-eleks/services`, {
    params: {
      institutionId: id,
    },
  });
  return data;
};

export const getDoctorEleksDoctors = async (institutionId: number, serviceId: number) => {
  const { data } = await institutionApi.get(`/doctor-eleks/doctors`, {
    params: {
      institutionId: institutionId,
      serviceId: serviceId,
    },
  });
  return data;
};

export const getDoctorEleksSlots = async (
  institutionId: number,
  serviceId: number,
  doctorLogin: string,
  dayCount: number,
) => {
  const { data } = await institutionApi.get(`/doctor-eleks/slots`, {
    params: {
      institutionId: institutionId,
      serviceId: serviceId,
      doctorLogin: doctorLogin,
      dayCount: dayCount,
    },
  });
  return data;
};

export const createDoctorEleksAppoinment = async body => {
  const { data } = await institutionApi.post(`/doctor-eleks/appointment`, body);
  return data;
};
