export const formatTime = time => {
  let formatedTime = '';

  const formatHours = t => {
    if (String(t).length <= 1) {
      return `0${t}`;
    }
    return t;
  };

  if (time === parseInt(time, 10)) {
    formatedTime = `${formatHours(time)}:00`;
  } else {
    formatedTime = `${formatHours(Math.floor(time))}:30`;
  }

  return formatedTime;
};
