import i18n from 'i18next';
import { Language } from 'types';

export default {
  data: {
    id: null,
    status: null,
    lastName: null,
    firstName: null,
    middleName: null,
    phoneNumber: null,
    email: null,
    insuranceCompany: null,
    partner: null,
    partnerDepartment: null,
    partnerDepartmentRegion: null,
    address: null,
    role: null,
    signupDate: null,
    startDate: null,
    endDate: null,
    active: false,
    insurancePrograms: [],
    client: false,
  },
  pending: false,
  partners: [],
  insuranceCompanies: [],
  isReadOnly: false,
  disableSubmit: false,
  language: i18n.language as Language,
};
