export const userActivities = {
  LOGIN: 'Вхiд в систему',
  LOGOUT: 'Вихiд з системи',
  SIGN_WITHDRAWAL: 'Зняття підпису з договору',
  VERIFY_WITHDRAWAL: 'Зняття звірки з договору',
  SIGN_CONTRACT: 'Підпис договору',
  VERIFY_CONTRACT: 'Звірка договору',
  SIGN_EXPRESS_PAYMENT: 'Оплата експрес виплати',
  ORDINARY_PAYMENT: 'Оплата на картковий рахунок',
  REJECT_EXPRESS_PAYMENT: 'Відхилення експрес виплати',
  AUTO_PAYMENT: 'Автоматична виплата за договором',
  CREATE_NEW_ACT: 'Створення нового АВР',
  PAYMENT_CALCULATION: 'Оформлення суми на виплату',
  PAYMENT_AWAITING: 'Очікування виплати',
  PAYMENT: 'Виплата за договором',
  PAYMENT_REJECTED: 'Відхілення виплати',
  ANNUL_CONTRACT_CLAIM: 'Анулювання заяви на виплату',
  RESTORE_CONTRACT_CLAIM: 'Відновлення заяви на виплату',
  EDIT_USER_CARD: 'Внесення змін до картки користувача',
  SAVE_CONTRACT: 'Збереження чернетки договору',
  SAVE_CLAIM: 'Збереження чернетки заяви на виплату',
  NOTIFY_CLAIM_MANAGER: 'Повідомлення відправлено менеджеру СК',
  SAVE_TERMINATION: 'Збереження чернетки заяви на розірвання',
  DEFER_EXPRESS_PAYMENT: 'Відкладення заяви на експрес-виплату',
  CREATE_SUB_AGREEMENT_ON_VERIFY_CONTRACT: 'Створення додаткової угоди при звірці договору страхування',
  IMPORT_VISIT_UKRAINE: 'Імпорт договорів VisitUkraine',
  SAVE_DRAFT_PROLONGATION: 'Збереження чернетки пролонгації договору',
  SAVE_SUB_AGREEMENT: 'Збереження чернетки додаткової угоди',
  SIGN_SUB_AGREEMENT: 'Підпис додаткової угоди',
  SIGN_TERMINATION: 'Підпис заяви на розірвання',
  SIGN_CLAIM: 'Підпис заяви на виплату',
  REJECT_ORDINARY_PAYMENT: 'Відхилення виплати',
  EXPORT_CONTRACT: 'Експорт договорів',
  SIGN_ORDINARY_PAYMENT: 'Виплата по страховому випадку',
  UPDATE_SALE_REGISTER: 'Оновлення заяви в реєстрі реалізації техніки',
  REPORTED: 'Скасування експресс-виплати',
};

export const reverseUserActivities = {
  'Вхiд в систему': 'LOGIN',
  'Вихiд з системи': 'LOGOUT',
  'Зняття підпису з договору': 'SIGN_WITHDRAWAL',
  'Зняття звірки з договору': 'VERIFY_WITHDRAWAL',
  'Підпис договору': 'SIGN_CONTRACT',
  'Звірка договору': 'VERIFY_CONTRACT',
  'Оплата експрес виплати': 'SIGN_EXPRESS_PAYMENT',
  'Оплата на картковий рахунок': 'ORDINARY_PAYMENT',
  'Відхилення експрес виплати': 'REJECT_EXPRESS_PAYMENT',
  'Автоматична виплата за договором': 'AUTO_PAYMENT',
  'Створення нового АВР': 'CREATE_NEW_ACT',
  'Оформлення суми на виплату': 'PAYMENT_CALCULATION',
  'Очікування виплати': 'PAYMENT_AWAITING',
  'Виплата за договором': 'PAYMENT',
  'Відхілення виплати': 'PAYMENT_REJECTED',
  'Анулювання заяви на виплату': 'ANNUL_CONTRACT_CLAIM',
  'Відновлення заяви на виплату': 'RESTORE_CONTRACT_CLAIM',
  'Внесення змін до картки користувача': 'EDIT_USER_CARD',
  'Збереження чернетки договору': 'SAVE_CONTRACT',
  'Збереження чернетки заяви на виплату': 'SAVE_CLAIM',
  'Збереження чернетки заяви на розірвання': 'SAVE_TERMINATION',
  'Відкладення заяви на експрес-виплату': 'DEFER_EXPRESS_PAYMENT',
  'Створення додаткової угоди при звірці договору страхування': 'CREATE_SUB_AGREEMENT_ON_VERIFY_CONTRACT',
  'Імпорт договорів VisitUkraine': 'IMPORT_VISIT_UKRAINE',
  'Збереження чернетки пролонгації договору': 'SAVE_DRAFT_PROLONGATION',
  'Збереження чернетки додаткової угоди': 'SAVE_SUB_AGREEMENT',
  'Підпис додаткової угоди': 'SIGN_SUB_AGREEMENT',
  'Підпис заяви на розірвання': 'SIGN_TERMINATION',
  'Підпис заяви на виплату': 'SIGN_CLAIM',
  'Експорт договорів': 'EXPORT_CONTRACT',
  'Відхилення виплати': 'REJECT_ORDINARY_PAYMENT',
  'Виплата по страховому випадку': 'SIGN_ORDINARY_PAYMENT',
  'Оновлення заяви в реєстрі реалізації техніки': 'UPDATE_SALE_REGISTER',
};
