import CreateIstance from './axios';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import { IStepValues as ISendToken } from '../components/auth/ManagerSignUp/types';
import { TStepValues as TInsurerToken } from '../components/auth/SignUp/types';
import onDownload from '../callbacks/onDownload';
import momentTz from 'moment-timezone';
import { UserCredentialsDto, UserCredentialsWithOTP } from 'types/dto/auth-service';
import {
  ResetPasswordDto,
  SignupAccountDto,
  CompanyView,
  ClientSignupAccountDto,
  PaymentResultDto,
  PaymentButtonRequestDto,
  ResetPasswordRequestDto,
  SignupAccountBaseInfoDto,
} from 'types/dto/contracts-service';
import { AnyObject } from 'types';

const authAPI = CreateIstance('AUTH');
const contractApi = CreateIstance('CONTRACTS');

export const login = ({ username, password }: UserCredentialsDto) => {
  return authAPI.post(`api/auth/login`, { username, password });
};

export const checkInsuranceCode = (code: string) => {
  return contractApi.post<void>(`/insurance-companies/code/${code}/validation`);
};

export const login2fa = ({ username, password }: UserCredentialsDto) => {
  const code = localStorage.getItem('companyCode');
  return contractApi.post(`/auth/2fa/login?insuranceCompanyCode=${code}`, { username, password });
};

export const login2faCheck = ({ username, password, id, otp }: UserCredentialsWithOTP & { id: number }) =>
  contractApi.post(`/auth/2fa/check/${id}`, { username, password, otp });

export const sendResetPasswordSms = (login: string, isClient: boolean) => {
  const formData = new FormData();
  formData.append('login', login);
  const code = localStorage.getItem('companyCode');

  return isClient
    ? contractApi.post(`users/clients/password/send-reset-sms?insuranceCompanyCode=${code}`, {
        login,
      })
    : contractApi.post(`/users/password/send-reset-sms`, {
        login,
      });
};

export const verifySmsCode = (login: string, token: string) => {
  return contractApi.post<string>(`/users/password/reset/token`, {
    token,
    login,
  });
};

export const resetPassword = ({ newPassword, confirmNewPassword, token }: ResetPasswordDto) =>
  contractApi.post<void>(`/users/password/reset`, {
    confirmNewPassword,
    newPassword,
    token,
  });

export const logout = () => {
  const code = localStorage.getItem('companyCode');
  history.push(code ? ROUTES.AUTH.LOGIN.replace(':id', `${code}`) : ROUTES.PARTNER_AUTH.LOGIN);
  sessionStorage.removeItem('currentCurrency');
  return authAPI
    .post('/api/auth/logout')
    .then(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('companyCode');
    })
    .catch(() => {
      // history.push(ROUTES.AUTH.LOGIN);
      localStorage.removeItem('accessToken');
    });
};

export const validateUser = (fields: Pick<ISendToken, 'phoneNumber' | 'email' | 'partnerCode'>) => {
  return contractApi.post<void>('/manager-signup/validate-account', fields);
};

export const getSignupToken = (fields: Pick<ISendToken, 'phoneNumber' | 'email' | 'partnerCode'>) => {
  return contractApi.post<void>('/manager-signup/sms-verification', fields);
};

export const sendSignupToken = (fields: Pick<ISendToken, 'phoneNumber' | 'email' | 'partnerCode' | 'token'>) => {
  return contractApi.post<SignupAccountDto>(`/manager-signup/sms-verification/check`, fields);
};

export const getSignUpData = (fields: ISendToken) => {
  return contractApi.post<void>(`/manager-signup/`, fields);
};

export const getAdminPartnersList = (
  name: string,
  subtypeId: number | string,
  insuranceCompanyId?: number | string,
): Promise<any> => {
  return contractApi
    .get<CompanyView[]>(`/companies`, {
      params: {
        partnerId: subtypeId,
        name,
        insuranceCompanyId: subtypeId ? null : insuranceCompanyId,
      },
    })
    .then(response => ({
      data: response.data.map(el => ({ id: el.partnerDepartmentId, name: el.name })),
    }));
};

export const getDepartmentsList = (name: string, subtypeId: number | string): Promise<any> => {
  return contractApi
    .get<CompanyView[]>(`/companies?partnerDepartmentId=<>null&partnerId=${subtypeId}&name=${name}`)
    .then(response => ({
      data: response.data.map(el => ({ id: el.partnerDepartmentId, name: el.name })),
    }));
};

export const validateInsurerUser = (fields: Pick<TInsurerToken, 'phoneNumber'>) => {
  return contractApi.post<void>('/client-signup/validate-account', fields);
};

export const getInsurerSignupToken = (fields: Pick<TInsurerToken, 'phoneNumber'>) => {
  const code = localStorage.getItem('companyCode');
  return contractApi.post<void>(`/client-signup/sms-verification?insuranceCompanyCode=${code}`, fields);
};

export const sendInsurerSignupToken = (token: string, fields: Pick<TInsurerToken, 'phoneNumber' | 'token'>) => {
  return contractApi.post<ClientSignupAccountDto>(`/client-signup/sms-verification/check`, fields);
};

export const registerInsurer = (fields: TInsurerToken) => {
  return contractApi.post<void>(`/client-signup/`, fields);
};

export const getExternalContractData = (externalId: number): Promise<any> => {
  return contractApi.get(`/contracts/external/${externalId}`);
};

export const sendExternalSMS = (externalId: number) => {
  return contractApi.post<void>(`/contracts/external/${externalId}/client-sign-verification`);
};

export const signExternalContract = (externalId: number, token, russianCitizen: boolean) => {
  return contractApi.post<void>(
    `/contracts/external/${externalId}/client-sign?${
      russianCitizen !== undefined ? `russianCitizen=${russianCitizen}&` : ''
    }token=${token}`,
  );
};

export const getFondyData = (externalId: number) => {
  return contractApi.get<PaymentButtonRequestDto>(`/contract-payments/external/generate-payment/${externalId}`);
};

export const getOrderData = (orderId: number) => {
  return contractApi.get<PaymentResultDto>(`/contract-payments/payment-result/${orderId}`);
};

export const getPdfReport: any = (externalId: number, _, t: any) => {
  const timeZone = momentTz.tz.guess(true);
  return onDownload(
    () =>
      contractApi.get(`/contracts/external/${externalId}/pdf-report`, {
        responseType: 'blob',
        params: {
          timeZone,
        },
      }),
    undefined,
    t,
  );
};

export const getOfferFile = async (externalId: number) => {
  const value = await contractApi.get<string>(`/contracts/external/${externalId}/offer-url`, {
    responseType: 'text',
  });
  const link = document.createElement('a');
  link.href = value.data;
  link.click();
};

export const getOfferta = async (externalId: number) => {
  const value = await contractApi.get<string>(`/contracts/${externalId}/offer-url`, {
    responseType: 'text',
  });
  const link = document.createElement('a');
  link.href = value.data;
  link.target = '_blank';
  link.click();
};

export const sendPasswordResetSmsUsingPost = (dto: ResetPasswordRequestDto) =>
  contractApi.post<void>(`/users/password/send-reset-sms`, dto);

export const resetPasswordUsingPost = (dto: ResetPasswordDto) => contractApi.post<void>(`/users/password/reset`, dto);

export const isValidTokenUsingGet = (login: string, token: string) => {
  return contractApi.post<string>(`/users/password/reset/token`, {
    token,
    login,
  });
};

export const loginUsingPost = (dto: UserCredentialsDto) => {
  return authAPI.post<AnyObject>(`/api/auth/login`, dto);
};

export const validateAccountUsingPost1 = (dto: SignupAccountBaseInfoDto) => {
  return contractApi.post<void>('/manager-signup/validate-account', dto);
};

export const validateSmsVerificationUsingPost1 = (dto: SignupAccountBaseInfoDto) => {
  return contractApi.post<SignupAccountDto>(`/manager-signup/sms-verification/check`, dto);
};

export const sendSmsVerificationUsingPost1 = (fields: SignupAccountBaseInfoDto) => {
  return contractApi.post<void>('/manager-signup/sms-verification', fields);
};
