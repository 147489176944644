import React from 'react';
import { useSelector } from 'react-redux';

import styles from './ActDetailsStatistic.module.less';
import { actDetailsSelector } from 'store/actDetails/selectors';
import { moneyFormatter, percentFormatter } from 'utils/helpers';
import { useTranslation } from 'react-i18next';

const ActsDetailsStatistics = () => {
  const { actInfo } = useSelector(actDetailsSelector);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.agreements_concluded')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{actInfo?.contractsCount}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.terminated_contracts')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{actInfo?.terminatedContractsCount}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.amount_calculated_payments')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{`${moneyFormatter(actInfo?.contractCalculatedPaymentAmount)} ₴`}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.amount_payments_contracts')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{`${moneyFormatter(actInfo?.contractPaymentAmount)} ₴`}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.amount_actual_remuneration')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{`${moneyFormatter(actInfo?.feeSum)} ₴`}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.amount_calculated_remuneration')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{`${moneyFormatter(actInfo?.baseFeeSum)} ₴`}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.actual_attorneys_fee')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{`${percentFormatter(actInfo?.actualFeePercent)} %`}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.amount_refunds')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{`${moneyFormatter(actInfo?.returnedPaymentSum)} ₴`}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.amount_returned_principal')} </div>
          <div className={styles.values}>
            <div className={styles.value}>{`${moneyFormatter(actInfo?.returnedToPartnerSum)} ₴`}</div>
          </div>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.label}>{t('act_details.amount_remuneration_reporting_period')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{`${moneyFormatter(actInfo?.periodFeeSum)} ₴`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActsDetailsStatistics;
