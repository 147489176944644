import React from 'react';
import { css, cx } from '@emotion/css';
import { Card as BaseCard } from 'antd';
import { CardProps } from 'antd/lib/card';

const cssRoot = css`
  && {
    border-radius: 14px;
  }
`;

export interface Props extends CardProps {}

const Card = ({ className, ...props }: Props) => {
  return <BaseCard className={cx(cssRoot, className)} {...props} />;
};

export default Card;
