import { createTypes } from 'redux-compose-reducer';
import { AppState } from 'store/reducer';
import * as actsAPI from 'api/acts';
import { IFilters } from 'types/actsList';
import { notification } from 'antd';

export const TYPES = createTypes('actCreate', [
  'setTablePage',
  'setPagination',
  'setSorting',
  'setFilters',
  'setActId',
  'removeActId',
  'clearData',
  'addExcludedContract',
  'removeExcludedContract',
  'clearExcludedContracts',
  'setStatistic',
]);

export const getTablePage = () => async (dispatch: any, getState: any) => {
  const {
    actCreate: { pagination, sorting, filters },
    actsList: { currentContract },
  }: AppState = getState();

  dispatch({ type: TYPES.setTablePage, pending: true });

  try {
    const response = await actsAPI.getAvaliableContractsTablePage({
      pagination,
      sorting,
      partnerId: filters.insuranceCompanyId.id,
      insuranceProgramId: filters.insuranceProgramId.id,
      filterDate: `${filters.dateTo}`,
      agentContractId: currentContract.id,
      id: currentContract.id,
    });

    dispatch({
      type: TYPES.setTablePage,
      payload: response.data,
      pending: false,
    });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setTablePage, pending: false });
  }
};

export const getActsStatistic = t => async (dispatch: any, getState: any) => {
  const {
    actCreate: { filters, excludedContracts },
    actsList: { currentContract },
  }: AppState = getState();

  try {
    const response = await actsAPI.getCreateActStatistic({
      contractId: currentContract.id,
      dateTo: filters.dateTo,
      excludedContracts: excludedContracts.map((contract: any) => ({
        id: contract.id,
        terminated: contract.terminated,
      })),
      agentContractId: currentContract.id,
      insuranceProgramId: filters.insuranceProgramId.id,
      partnerId: filters.insuranceCompanyId.id,
    });

    dispatch({
      type: TYPES.setStatistic,
      payload: {
        statistic: {
          amountToPay: response.data.amountToPay ?? 0,
          totalCount: response.data.totalCount ?? 0,
          totalFeeSum: response.data.totalFeeSum ?? 0,
          totalInsurancePayments: response.data.totalInsurancePayments ?? 0,
        },
      },
    });
  } catch (error) {
    notification.error({
      message: t('popup.failed_calculate'),
    });
  }
};

export const setFilters = (filters: IFilters) => (dispatch: any) =>
  dispatch({ type: TYPES.setFilters, payload: { ...filters } });

export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setSorting = ({ field }: { field: string }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field } });
};

export const setActId = (id: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setActId, payload: id });
};

export const removeActId = () => (dispatch: any) => {
  dispatch({ type: TYPES.removeActId });
};
export const clearData = () => (dispatch: any) => {
  dispatch({ type: TYPES.clearData });
};

export const addExcludedContract = (id: any) => (dispatch: any) => {
  dispatch({ type: TYPES.addExcludedContract, payload: id });
};

export const removeExcludedContract = (id: any) => (dispatch: any) => {
  dispatch({ type: TYPES.removeExcludedContract, payload: id });
};

export const clearExcludedContracts = () => (dispatch: any) => {
  dispatch({ type: TYPES.clearExcludedContracts });
};
