// errors data
export const UPLOAD_ERRORS = {
  wrongType: 'Даний тип файлу не підтримується',
  wrongSize: 'Розмір файлу перевищує 10 Мб',
  empty: 'Файл не знайдено',
};
// types
type ErrorType = 'wrongType' | 'wrongSize' | 'empty' | null;
export type UploadErrors = {
  contracts: ErrorType;
  users: ErrorType;
};
