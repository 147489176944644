import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Table as ExpandedTable } from 'antd';
import cn from 'classnames';

import Table from 'components/Table';
import { ExpandedColumns, MiniColumns } from './columns';
import styles from './JutloDetailsTable.module.less';
import { getJutloType } from '../../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../../../../hooks/useCurrency';
import { AppState } from 'store/reducer';

interface IProps {
  data: any;
  objects: any;
}

export const JutloDetailsTable: React.FC<IProps> = ({ data, objects }) => {
  const { estateSubTypes, savedRisks, id, contractObjects } = useSelector((store: AppState) => ({
    risks: store.contractDetails.contract.risks,
    id: store.contractDetails.contract.id,
    estateSubTypes: store.contractDetails.estateSubTypes,
    savedRisks: store.contractDetails.savedRisks,
    contractObjects: store.contractDetails.contract.contractObjects,
    status: store.contractDetails.contract.status,
    estateData: store.contractDetails.contract.estateData,
    insuranceProgram: store.contractDetails.contract.insuranceProgram,
  }));
  const { currentCompanyCurrency } = useSelector((store: AppState) => ({
    currentCompanyCurrency: store.user.currentCompanyCurrency,
  }));

  const { currency } = useCurrency();
  const { t } = useTranslation();

  const renderExpandedTable = row => {
    const columns = ExpandedColumns(t, currentCompanyCurrency || currency).map(
      ({ title, dataIndex, key, width, ...rest }) => {
        const basicColumn = {
          title,
          dataIndex,
          key,
          width,
          ...rest,
        };
        switch (dataIndex) {
          case 'index':
            return {
              ...basicColumn,
              render: (row, record, index) => {
                return index + 1;
              },
            };
          default: {
            return basicColumn;
          }
        }
      },
    );

    const risksDataArray = Array.isArray(row.risks) ? row.risks : Array.of(row.risks);
    const tableData = risksDataArray.reduce((acc, item) => {
      const isExist = savedRisks.find(elem => elem.id === item.id);
      return isExist && !acc.includes(item) ? [...acc, { ...item, name: isExist.name }] : [...acc];
    }, []);

    const existedRisks = row.risks.reduce((acc, item) => {
      const isExist = savedRisks.find(elem => elem.id === item.id);
      return isExist && !acc.includes(item) ? [...acc, { ...item, name: isExist.name }] : [...acc, { ...item }];
    }, []);
    return (
      <ExpandedTable
        className={cn(styles.nestedTable)}
        columns={columns}
        dataSource={id ? existedRisks : tableData}
        pagination={false}
        bordered
        size="small"
        scroll={{ x: 1280 }}
        rowKey={(record: any) => record.id}
      />
    );
  };

  const mainTableData = estateSubTypes.reduce((acc, item) => {
    const searchedElem = objects.find(el => el.subtypeId === item.id);
    return searchedElem ? [...acc, { ...searchedElem, ...item, risks: searchedElem.risks }] : [...acc];
  }, []);

  const existedTableData = contractObjects.reduce((acc, item) => {
    const element = data.find(elem => elem.subtypeId === item.subtypeId);
    return element
      ? [
          ...acc,
          {
            ...item,
            ...element,
            risks: element.risks,
          },
        ]
      : [
          ...acc,
          {
            ...item,
            name: item.subtypeName,
            insuranceSumForObject: item.insuranceSum,
            paymentAmountForObject: item.paymentAmount,
          },
        ];
  }, []);

  const columns = MiniColumns(t, currentCompanyCurrency || currency).map(
    ({ title, dataIndex, key, width, ...rest }) => {
      const basicColumn = {
        title,
        dataIndex,
        key,
        width,
        ...rest,
      };
      switch (dataIndex) {
        case 'index':
          return {
            ...basicColumn,
            render: (row, record, index) => {
              return index + 1;
            },
          };
        case 'name':
          return {
            ...basicColumn,
            render: (row, record, index) => {
              return record.name;
            },
          };
        default: {
          return basicColumn;
        }
      }
    },
  );
  return (
    <Table
      scroll={{ x: 1280 }}
      className={styles.parentTable}
      dataSource={id ? existedTableData : mainTableData}
      columns={columns}
      expandIcon={({ expanded, onExpand, record }) =>
        expanded ? (
          <Icon type="up" style={{ float: 'right' }} onClick={e => onExpand(record, e)} />
        ) : (
          <Icon type="down" style={{ float: 'right' }} onClick={e => onExpand(record, e)} />
        )
      }
      expandIconColumnIndex={6}
      expandIconAsCell={false}
      expandRowByClick={true}
      pagination={false}
      bordered
      expandedRowRender={renderExpandedTable}
      rowKey={(record: any) => record.id}
    />
  );
};
