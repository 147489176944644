import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';

import { Form, Button, Typography, Input, Icon, Col, Row } from 'antd';
import DepartmentInput from '../widgets/DepartmentInput';

import * as sign from '../../SignUp/SignUp.module.less';
import styles from '../../auth.module.less';

import { IThirdStep } from '../types';
import { RULES, nameRegexp, passwordHint } from '../utils';
import { useTranslation } from 'react-i18next';

const ThirdStep: FunctionComponent<IThirdStep> = ({
  form,
  form: { getFieldDecorator, getFieldValue },
  fieldsActiveState,
  handleFields,
  values,
  setPartnerId,
}) => {
  const [showPassword, toggleShowPassword] = useState({
    confirmPassword: false,
    password: false,
  });

  const showPasswordAction = (label: string, action: boolean) => {
    toggleShowPassword({ ...showPassword, [label]: action });
  };

  const validateName = (value: string, fieldName: string) => {
    return value === '' || (value && value.match(nameRegexp)) ? value : getFieldValue(fieldName);
  };
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title level={3}>
        {t('signup.info')} <br /> {t('signup.about_manager')}
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item validateStatus="error" className={classNames(sign.signup_items, sign.noWidth)}>
            {getFieldDecorator('lastName', {
              rules: [...RULES(t).LAST_NAME],
              initialValue: values.lastName,
              validateFirst: true,
              normalize: value => validateName(value, 'lastName'),
            })(
              <Input
                size="large"
                onFocus={e => handleFields(e.type, 'lastName')}
                onBlur={e => handleFields(e.type, 'lastName')}
              />,
            )}
            <div
              className={classNames(sign.inputLabel, {
                [sign.labelActive]: fieldsActiveState.lastName || getFieldValue('lastName'),
              })}
            >
              {t('signup.last_name')}
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item validateStatus="error" className={classNames(sign.signup_items, sign.noWidth)}>
            {getFieldDecorator('firstName', {
              rules: [...RULES(t).FIRST_NAME],
              initialValue: values.firstName,
              validateFirst: true,
              normalize: value => validateName(value, 'firstName'),
            })(
              <Input
                size="large"
                onFocus={e => handleFields(e.type, 'firstName')}
                onBlur={e => handleFields(e.type, 'firstName')}
              />,
            )}
            <div
              className={classNames(sign.inputLabel, {
                [sign.labelActive]: fieldsActiveState.firstName || getFieldValue('firstName'),
              })}
            >
              {t('signup.name')}
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Form.Item validateStatus="error" className={classNames(sign.signup_items, sign.noWidth)}>
            {getFieldDecorator('middleName', {
              rules: RULES(t).MIDDLE_NAME,
              initialValue: values.middleName,
              validateFirst: true,
              normalize: value => validateName(value, 'middleName'),
            })(
              <Input
                size="large"
                onFocus={e => handleFields(e.type, 'middleName')}
                onBlur={e => handleFields(e.type, 'middleName')}
              />,
            )}
            <div
              className={classNames(sign.inputLabel, {
                [sign.labelActive]: fieldsActiveState.middleName || getFieldValue('middleName'),
              })}
            >
              {t('signup.middle_name')}
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ width: '102%' }} type="flex" justify="space-between">
        <Col span={6} xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item validateStatus="error" className={classNames(sign.signup_items, sign.noWidth)}>
            {getFieldDecorator('company', {
              initialValue: values?.partner?.name,
            })(<Input size="large" disabled={true} />)}
            <div
              className={classNames(sign.inputLabel, sign.gray_text, {
                [sign.labelActive]: fieldsActiveState.company || values?.partner?.name,
                [sign.labelDisabled]: true,
              })}
            >
              {t('signup.company')}
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <DepartmentInput
            value={''}
            form={form}
            fieldsActiveState={fieldsActiveState}
            setPartnerId={setPartnerId}
            values={values}
            handleFields={handleFields}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ width: '102%', minWidth: '102%' }} type="flex" justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            extra={t(passwordHint)}
            validateStatus="error"
            className={classNames(styles.inputPassword, sign.signup_items, sign.noWidth, {
              [styles.visible]: showPassword.password,
              [styles.invisible]: !showPassword.password,
            })}
          >
            {getFieldDecorator('password', {
              rules: [...RULES(t).PASSWORD],
              validateFirst: true,
            })(
              <Input
                size="large"
                autoComplete="off"
                onFocus={e => handleFields(e.type, 'password')}
                onBlur={e => handleFields(e.type, 'password')}
                type="text"
                suffix={
                  <div onClick={() => showPasswordAction('password', !showPassword.password)}>
                    <Icon type={showPassword.password ? 'eye' : 'eye-invisible'} />
                  </div>
                }
              />,
            )}
            <div
              className={classNames(sign.inputLabel, {
                [sign.labelActive]: fieldsActiveState.password || getFieldValue('password'),
              })}
            >
              {t('signup.enter_password')}
            </div>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            validateStatus="error"
            className={classNames(styles.inputPassword, sign.signup_items, sign.noWidth, {
              [styles.visible]: showPassword.confirmPassword,
              [styles.invisible]: !showPassword.confirmPassword,
            })}
          >
            {getFieldDecorator('confirmPassword', {
              rules: [...RULES(t).CONFIRM_PASSWORD],
              validateFirst: true,
            })(
              <Input
                type="text"
                autoComplete="off"
                suffix={
                  <div onClick={() => showPasswordAction('confirmPassword', !showPassword.confirmPassword)}>
                    <Icon type={showPassword.confirmPassword ? 'eye' : 'eye-invisible'} />
                  </div>
                }
                size="large"
                onFocus={e => handleFields(e.type, 'confirmPassword')}
                onBlur={e => handleFields(e.type, 'confirmPassword')}
              />,
            )}
            <div
              className={classNames(sign.inputLabel, {
                [sign.labelActive]: fieldsActiveState.confirmPassword || getFieldValue('confirmPassword'),
              })}
            >
              {t('signup.confirm_password')}
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
          {t('signup.next')}
        </Button>
      </Form.Item>
    </>
  );
};

export default ThirdStep;
