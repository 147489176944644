const columnsList = t => {
  return [
    {
      title: '№',
      value: 'number',
      key: 'a1',
      width: 70,
      filtered: false,
      filters: null,
      filterIcon: null,
      filterDropdown: null,
      sorter: false,
    },
    {
      title: t('services.name_of_service'),
      value: 'name',
      key: 'a2',
      sorter: true,
    },
    {
      title: t('services.type_of_service'),
      value: 'serviceTypeName',
      key: 'a3',
      sorter: true,
    },
    {
      title: t('services.subsublimit'),
      value: 'sublimitName',
      key: 'a4',
      filtered: false,
      filters: null,
      filterIcon: null,
      filterDropdown: null,
      sorter: false,
    },
    {
      title: t('services.value'),
      value: 'currencyValue',
      key: 'a5',
      filtered: false,
      filters: null,
      filterIcon: null,
      filterDropdown: null,
      sorter: true,
    },
    {
      title: '',
      value: 'actions',
      key: 'a6',
      width: 70,
      filtered: false,
      filters: null,
      filterIcon: null,
      filterDropdown: null,
      sorter: false,
    },
  ];
};

export default columnsList;
