import moment from 'moment';
import { notification } from 'antd';
import { createTypes } from 'redux-compose-reducer';
import * as actsAPI from 'api/acts';
import { AppState } from 'store/reducer';
import { AllActions, TSetPagination } from 'store/actsList/actionTypes';
import history from '../../routes/history';
import { ROUTES } from '../../constants/routes';
import { AgentActInfoDto } from 'types/dto/contracts-service';

interface IInfo {
  actDate: string;
  agentContractNumber: string;
  dateFrom: string;
  dateTo: string;
  insuranceProgramName: string;
  partnerName: string;
  agentContractDate: string;
}

export const TYPES = createTypes('actDetails', [
  'setActDetails',
  'setPending',
  'changeContractsStatus',
  'setActInfo',
  'setFiltersDates',
  'setPagination',
  'setSorting',
  'getPrivateFiltersData',
  'changeActInfo',
  'avaliableContracts',
  'setAvailablePagination',
  'changeAvailableContractsStatus',
]);

export const getActDetailsAction = (id?: string, actInfo?: AgentActInfoDto) => async (dispatch: any, getState: any) => {
  const {
    actDetails: { pagination, sorting },
  }: AppState = getState();

  dispatch({ type: TYPES.setActDetails, pending: true });
  try {
    const response = await actsAPI.getActDetails(id, { pagination, sorting, ...actInfo });

    dispatch({ type: TYPES.setActDetails, payload: response.data, pending: false, error: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setActDetails, pending: false, error: true });
  }
};

const getActiveContracts = contracts => {
  return contracts.filter(({ activeStatus }) => activeStatus === 'ACTIVE').map(item => item.id);
};

const getInActiveContracts = contracts => {
  return contracts.filter(({ activeStatus }) => activeStatus === 'INACTIVE').map(item => item.id);
};

export const updateActDetailsAction = (action: 'contractsToDelete' | 'contractsToAdd', id?: string, t?) => async (
  dispatch: any,
  getState: any,
) => {
  const {
    actDetails: { data, actInfo, avaliableContracts, pagination, sorting, avaliablePagination },
  }: AppState = getState();
  const activeContracts = getActiveContracts(data);
  const inActiveContracts = getInActiveContracts(data);
  const activeAvContracts = getActiveContracts(avaliableContracts.contracts);
  const inActiveAvContracts = getInActiveContracts(avaliableContracts.contracts);
  const requestObj = {
    actDate: moment(actInfo.actDate).format('YYYY-MM-DDThh:mm:ss'),
  };

  requestObj[action] =
    action === 'contractsToDelete'
      ? inActiveContracts.concat(inActiveAvContracts)
      : activeContracts.concat(activeAvContracts);

  const { agentContractId, partnerId, insuranceProgramId, dateTo } = actInfo;
  try {
    await actsAPI.updateActDetails(id, requestObj);
    const response = await actsAPI.getActDetails(id, { pagination, sorting, ...actInfo });
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.setActDetails, payload, pending: false, error: false });

    const res = await actsAPI.getAvaliableContracts({
      pagination: avaliablePagination,
      sorting: { field: 'contractNumber' },
      partnerId: partnerId,
      filterDate: `${dateTo}`,
      agentContractId: agentContractId,
      insuranceProgramId,
    });

    dispatch({ type: TYPES.avaliableContracts, payload: res.data, pending: false, error: false });
    notification.success({
      message: t('popup.information_successfully_saved'),
    });
  } catch (error) {
    if (error.response) {
      return (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (el.code === 'AGENT_ACT_ALREADY_CONFIRMED') {
            notification.error({
              message: t('popup.error'),
              description: t('popup.current_already_confirmed'),
            });
          }
        })
      );
    }
  }
};

export const deleteActDetails = (id?: string, t?) => async () => {
  try {
    const res = await actsAPI.deleteAct(id);
    if (res.status === 200) {
      history.push(ROUTES.ACTS_LIST.ROOT);
    }
  } catch (error) {
    if (error.response) {
      return (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (el.code === 'AGENT_ACT_ALREADY_CONFIRMED') {
            notification.error({
              message: t('popup.error'),
              description: t('popup.current_already_confirmed'),
            });
          }
        })
      );
    }
  }
};

export const withdrawAct = (id?: string, t?) => async (dispatch: any) => {
  try {
    const resp = await actsAPI.withDrawAct(id);
    if (resp.status === 200) {
      const res = await actsAPI.getActInfo(id);

      dispatch({ type: TYPES.setActInfo, payload: res.data, pending: false, error: false });
    }
  } catch (error) {
    if (error.response) {
      return (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (el.code === 'AGENT_ACT_DOES_NOT_CONFIRMED') {
            notification.error({
              message: t('popup.error'),
              description: t('popup.not_confirmed'),
            });
          }
          if (el.code === 'AGENT_ACT_IS_NOT_THE_LAST_ONE') {
            notification.error({
              message: t('popup.error'),
              description: t('popup.is_not_last'),
            });
          }
        })
      );
    }
  }
};

export const confirmActAction = (id?: string, t?) => async (dispatch: any) => {
  try {
    const resp = await actsAPI.confirmAct(id);
    if (resp.status === 200) {
      const responseInfo = await actsAPI.getActInfo(id);
      const payloadInfo = responseInfo.data ? responseInfo.data : [];
      dispatch({ type: TYPES.setActInfo, payload: payloadInfo, pending: false, error: false });
    }
  } catch (error) {
    if (error.response) {
      return (
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (el.code === 'AGENT_ACT_ALREADY_CONFIRMED') {
            notification.error({
              message: t('popup.error'),
              description: t('popup.already_confirmed'),
            });
          }
        })
      );
    }
  }
};

export const setActInfo = (params: AgentActInfoDto) => async (dispatch: any) => {
  dispatch({ type: TYPES.changeActInfo, pending: true });
  try {
    dispatch({ type: TYPES.changeActInfo, payload: params, pending: false, error: false });
  } catch (err) {
    dispatch({ type: TYPES.changeActInfo, pending: false, error: true });
  }
};

export const getActInfo = (id?: string) => async (dispatch: any) => {
  dispatch({ type: TYPES.setActInfo, pending: true });
  try {
    const response = await actsAPI.getActInfo(id);
    const payload = response.data ? response.data : [];
    dispatch({ type: TYPES.setActInfo, payload, pending: false, error: false });
  } catch (err) {
    dispatch({ type: TYPES.setActInfo, pending: false, error: true });
  }
};

export const getAvaliableContracts = (actInfo?: any, params?: any) => async (dispatch: any, getState: any) => {
  const {
    actDetails: {
      avaliablePagination,
      actInfo: { dateTo },
    },
  }: AppState = getState();
  const { agentContractId, partnerId, insuranceProgramId } = actInfo;
  try {
    const response = await actsAPI.getAvaliableContracts({
      pagination: avaliablePagination,
      sorting: { field: 'contractNumber' },
      partnerId: partnerId,
      filterDate: `${dateTo}`,
      agentContractId: agentContractId,
      insuranceProgramId,
      ...params,
    });
    const payload = response.data ? response.data : [];
    dispatch({ type: TYPES.avaliableContracts, payload, pending: false, error: false });
  } catch (error) {}
};

export const getFilteredActsDetails = (id, filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    actDetails: { pagination, sorting },
  }: AppState = getState();
  try {
    const res = await actsAPI.getPrivateFiltersTable(id, {
      filters: {
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-conclusionDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
    });

    dispatch({ type: TYPES.getPrivateFiltersData, payload: res.data });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

export const changeContractsStatus = (contractId: number, newStatus: string): any => async (
  dispatch: any,
  getState: any,
) => {
  const {
    actDetails: { data },
  }: AppState = getState();
  const currentContracts = data.map(item => {
    return {
      ...item,
      activeStatus: item.id === contractId ? newStatus : item.activeStatus || 'ACTIVE',
    };
  });
  dispatch({
    type: TYPES.changeContractsStatus,
    payload: currentContracts,
  });
};

export const changeAvailableStatus = (contractId: number, newStatus: string): any => async (
  dispatch: any,
  getState: any,
) => {
  const {
    actDetails: { avaliableContracts },
  }: AppState = getState();
  const currentAvailableContracts = avaliableContracts.contracts.map(item => {
    return {
      ...item,
      activeStatus: item.id === contractId ? newStatus : item.activeStatus || 'INACTIVE',
    };
  });
  dispatch({
    type: TYPES.changeAvailableContractsStatus,
    payload: currentAvailableContracts,
  });
};

export const setPagination = (current: number, perPage: number): AllActions<TSetPagination> => ({
  type: TYPES.setPagination,
  payload: { current, perPage },
});

export const setAvaliablePagination = (current: number, perPage: number): AllActions<TSetPagination> => ({
  type: TYPES.setAvailablePagination,
  payload: { current, perPage },
});

export const setSorting = ({ field, direction }: { field: string; direction: string }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};
