import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import { IUsersActivities } from 'types/IUsersActivities';
import { AnyAction } from 'redux';
import { IUserActivity } from '../../types/UserActivity';
import config from '../../config';

export interface IUsersActivitiesQuery {
  from: Date | string;
  to: Date | string;
}

export interface IUsersActivitiesStore extends IUsersActivities<IUserActivity[], any> {}

const initialState: IUsersActivitiesStore = {
  loading: false,
  data: [],
  query: undefined,
  pagination: { size: config.ui.pagination.size, page: 1, total: 0 },
  meta: undefined,
  actionUserTypes: {},
};

const setData = (state: IUsersActivitiesStore, action: AnyAction): IUsersActivitiesStore => {
  return { ...state, data: action.payload };
};

const setQuery = (state: IUsersActivitiesStore, action: AnyAction): IUsersActivitiesStore => {
  return { ...state, query: action.payload };
};

const setLoading = (state: IUsersActivitiesStore, action: AnyAction): IUsersActivitiesStore => {
  return { ...state, loading: action.payload };
};

const setPagination = (state: IUsersActivitiesStore, action: AnyAction): IUsersActivitiesStore => {
  return { ...state, pagination: action.payload };
};

const getUsersActionTypes = (state: IUsersActivitiesStore, action: AnyAction): IUsersActivitiesStore => {
  return { ...state, actionUserTypes: action.payload };
};

const removeQuery = (state: IUsersActivitiesStore, { payload: fieldName }: AnyAction): IUsersActivitiesStore => {
  const newQuery = state.query;

  if (newQuery) {
    delete newQuery[fieldName];
  }

  return {
    ...state,
    query: {
      ...newQuery,
    },
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setData,
    setQuery,
    setLoading,
    setPagination,
    getUsersActionTypes,
    removeQuery,
  },
});
