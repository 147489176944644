import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, Box } from '@material-ui/core';
import { notification } from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import { ROUTES } from 'constants/routes';
import { AppState } from 'store/reducer';
import history from 'routes/history';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import { CallDefault } from 'components/Icons';
import { getSubevent, clearSubevent } from 'store/appointment/actions';
import { checkPermissions } from 'roles/userCan';
import { StatusKey } from 'screens/History/components/Event/enum';
import Chip from 'components/Chip';
import FormInputText from 'components/FormInputText';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { getClaim, cancelAppointment } from 'store/appointment/actions';

import styles from './Subevent.module.less';

const MINHOURS = 6;

const Subevent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const subeventId = history.location.pathname.split('/')[3];

  const fetchSubevent = id => dispatch(getSubevent(id));
  const resetSubeventData = () => dispatch(clearSubevent());
  const fetchClaim = id => dispatch(getClaim(id));
  const cancelAppointmentById = (id, successFn, errorFn) => dispatch(cancelAppointment(id, successFn, errorFn));

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { subevent, claimData } = useSelector(({ appointment: { subevent, claimData } }: AppState) => ({
    subevent,
    claimData,
  }));

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  useEffect(() => {
    fetchSubevent(subeventId);
    if (history.location.state?.caseId) {
      fetchClaim(history.location.state.caseId);
    }

    return () => {
      resetSubeventData();
    };
  }, []);

  const renderStatus = () => {
    if (subevent) {
      switch (subevent.statusType) {
        case StatusKey.CANCELED:
          return (
            <Chip
              color={'red'}
              label={t('history.status_canceled')}
              data-id="Subevent-Status"
              className={styles.chip}
            />
          );
        case StatusKey.WAITING_FOR_CANCELLATION:
          return (
            <Chip
              color={'red'}
              label={t('history.status_waiting_for_canceled')}
              data-id="Subevent-Status"
              className={styles.chip}
            />
          );
        case StatusKey.COMPLETED:
          return (
            <Chip
              color={'green'}
              label={t('history.status_completed')}
              data-id="Subevent-Status"
              className={styles.chip}
            />
          );
        case StatusKey.PENDING:
          return (
            <Chip
              color={'yellow'}
              label={t('history.status_pending')}
              data-id="Subevent-Status"
              className={styles.chip}
            />
          );
        case StatusKey.PLANNED:
          return (
            <Chip
              color={'blue'}
              label={t('history.status_planned')}
              data-id="Subevent-Status"
              className={styles.chip}
            />
          );

        default:
          break;
      }
    }
  };

  const renderTime = () => {
    let time = '';

    if (subevent.dateSlot.time_from) {
      const date = history.location.state?.meetingTime ? moment(history.location.state.meetingTime).format('L') : '';
      time = `${date} ${t('history.subevent_from')} ${subevent.dateSlot.time_from} ${t('history.subevent_to')} ${
        subevent.dateSlot.time_to
      }`;
    }

    if (subevent.desirableSlot.desirableSlotFrom) {
      time = `${moment(subevent.desirableSlot.desirableSlotFrom.slice(0, 10)).format('L')} ${t(
        'history.subevent_from',
      )} ${subevent.desirableSlot.desirableSlotTo.slice(11, 16)} ${t(
        'history.subevent_to',
      )} ${subevent.desirableSlot.desirableSlotFrom.slice(11, 16)}`;
    }

    return time;
  };

  const onSuccess = () => {
    notification.success({
      message: t('history.success_message'),
    });
    history.push(ROUTES.DASHBOARD.ROOT);
  };

  const onError = text => {
    if (text) {
      notification.error({
        message: text,
      });
    } else {
      notification.error({
        message: t('history.subevent_error'),
      });
    }
  };

  const onButtonClick = () => {
    const timeDiff = history.location.state?.meetingTime
      ? moment.duration(moment(history.location.state.meetingTime).diff(moment(new Date())))
      : 0;

    if (timeDiff && timeDiff.asHours() >= 0 && timeDiff.asHours() <= MINHOURS) {
      toggleModal();
      notification.error({
        message: t('history.subevent_6_hour'),
      });
    } else {
      toggleModal();
      cancelAppointmentById(history.location.state?.id, onSuccess, onError);
    }
  };

  return (
    <div>
      <PageBreadcrumbs
        data={
          checkPermissions('CHATS', 'MENU')
            ? [
                { label: t('history.home'), href: ROUTES.DASHBOARD.ROOT },
                { label: t('history.consultations'), href: ROUTES.CONSULTATIONS.ROOT },
                { label: t('history.page_title'), href: ROUTES.CONSULTATIONS.HISTORY },
                { label: t('history.subevent_title') },
              ]
            : [
                { label: t('history.home'), href: ROUTES.DASHBOARD.ROOT },
                { label: t('history.page_title'), href: ROUTES.CONSULTATIONS.HISTORY },
                { label: t('history.subevent_title') },
              ]
        }
      />
      <PageHeader title={t('history.subevent_title')} withoutSpacing>
        {renderStatus()}
      </PageHeader>
      <Grid className={styles.grid} container spacing={2}>
        <Grid className={styles.gridItem} item xs={6}>
          <Paper className={classNames(styles.paper, styles.subeventPaper)} elevation={0}>
            {subevent && (
              <>
                {history.location.state?.contractNumber && (
                  <Box mb={2}>
                    <FormInputText
                      title={t('history.subevent_contract')}
                      value={history.location.state.contractNumber}
                      readOnly
                    />
                  </Box>
                )}
                {claimData && (
                  <Box mb={2}>
                    <FormInputText title={t('history.subevent_number')} value={claimData.claimNumber} readOnly />
                  </Box>
                )}
                {subevent.recordType && (
                  <Box mb={2}>
                    <FormInputText title={t('history.subevent_type')} value={subevent.recordType} readOnly />
                  </Box>
                )}
                {subevent.medicalService.serviceType && (
                  <Box mb={subevent.isOnline ? 1 : 2}>
                    <FormInputText
                      title={t('history.subevent_service')}
                      value={subevent.medicalService.serviceType}
                      readOnly
                      textarea
                      className={styles.textarea}
                    />
                  </Box>
                )}
                {subevent.isOnline && (
                  <div className={styles.isOnline}>
                    <span className={styles.onlineText}>{t('history.subevent_online')}</span>
                    <CallDefault />
                  </div>
                )}
                {(subevent.dateSlot.time_from || subevent.desirableSlot.desirableSlotFrom) && (
                  <Box mb={2}>
                    <FormInputText title={t('history.subevent_time')} value={renderTime()} readOnly />
                  </Box>
                )}
                {subevent.clinic?.name && (
                  <Box mb={2}>
                    <FormInputText title={t('history.subevent_clinic')} value={subevent.clinic.name} readOnly />
                  </Box>
                )}
                {subevent.reason && (
                  <Box mb={2}>
                    <FormInputText
                      title={t('history.subevent_reason')}
                      value={subevent.reason}
                      readOnly
                      textarea
                      className={styles.textarea}
                    />
                  </Box>
                )}
                {subevent.wishes && (
                  <Box mb={2}>
                    <FormInputText
                      title={t('history.subevent_wishes')}
                      value={subevent.wishes}
                      readOnly
                      textarea
                      className={styles.textarea}
                    />
                  </Box>
                )}
                {(subevent.statusType === StatusKey.PLANNED || subevent.statusType === StatusKey.PENDING) && (
                  <div className={styles.buttonWrapper}>
                    <Button intent="danger" onClick={toggleModal}>
                      {t('history.subevent_cancel')}
                    </Button>
                  </div>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <ConfirmModal
        isModalOpen={isModalOpen}
        onSubmitModal={onButtonClick}
        onClose={toggleModal}
        closeText={t('history.modal_cancel')}
        submitText={t('history.modal_submit')}
      >
        <h5 className={styles.modalHeader}>{t('history.modal_header')}</h5>
      </ConfirmModal>
    </div>
  );
};

export default Subevent;
