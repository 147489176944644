import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';

import { Form, Button, Typography, Input, Icon } from 'antd';

import * as sign from '../SignUp.module.less';
import styles from '../../auth.module.less';

import { IThirdStep } from '../types';
import { emailRegex } from '../../ManagerSignUp/utils';
import { RULES, passwordHint } from '../../ManagerSignUp/utils';
import { useTranslation } from 'react-i18next';

type TPasswords = {
  confirmPassword: boolean;
  password: boolean;
};

const ThirdStep: FunctionComponent<IThirdStep> = ({
  form: { getFieldDecorator, getFieldValue },
  fieldsActiveState,
  handleFields,
  values,
}) => {
  const [showPassword, toggleShowPassword] = useState<TPasswords>({
    confirmPassword: false,
    password: false,
  });

  const showPasswordAction = (label: string, action: boolean): void => {
    toggleShowPassword({ ...showPassword, [label]: action });
  };
  const { t } = useTranslation();

  return (
    <>
      <Typography.Title level={3}>{t('signup.registration')} </Typography.Title>

      <Form.Item className={sign.signup_items}>
        {getFieldDecorator('email', {
          rules: [
            {
              pattern: emailRegex,
              message: t('signup.please_enter_email'),
            },
          ],
          initialValue: values.email,
          validateFirst: true,
        })(
          <Input
            size="large"
            onFocus={e => handleFields(e.type, 'email')}
            onBlur={e => handleFields(e.type, 'email')}
          />,
        )}
        <div
          className={classNames(sign.inputLabel, {
            [sign.labelActive]: fieldsActiveState.email || getFieldValue('email'),
          })}
        >
          {t('signup.enter_email')}
        </div>
      </Form.Item>

      <Form.Item
        className={classNames(styles.inputPassword, sign.signup_items, {
          [styles.visible]: showPassword.password,
          [styles.invisible]: !showPassword.password,
        })}
      >
        {getFieldDecorator('password', {
          rules: RULES(t).PASSWORD,
          validateFirst: true,
        })(
          <Input
            size="large"
            autoComplete="off"
            onFocus={e => handleFields(e.type, 'password')}
            onBlur={e => handleFields(e.type, 'password')}
            type="text"
            suffix={
              <div onClick={() => showPasswordAction('password', !showPassword.password)}>
                <Icon type={showPassword.password ? 'eye' : 'eye-invisible'} />
              </div>
            }
          />,
        )}
        <div
          className={classNames(sign.inputLabel, {
            [sign.labelActive]: fieldsActiveState.password || getFieldValue('password'),
          })}
        >
          {t('signup.invent_password')}
        </div>
      </Form.Item>
      <Form.Item
        className={classNames(styles.inputPassword, sign.signup_items, {
          [styles.visible]: showPassword.confirmPassword,
          [styles.invisible]: !showPassword.confirmPassword,
        })}
      >
        {getFieldDecorator('confirmPassword', {
          rules: RULES(t).CONFIRM_PASSWORD,
          validateFirst: true,
        })(
          <Input
            type="text"
            autoComplete="off"
            suffix={
              <div onClick={() => showPasswordAction('confirmPassword', !showPassword.confirmPassword)}>
                <Icon type={showPassword.confirmPassword ? 'eye' : 'eye-invisible'} />
              </div>
            }
            size="large"
            onFocus={e => handleFields(e.type, 'confirmPassword')}
            onBlur={e => handleFields(e.type, 'confirmPassword')}
          />,
        )}
        <div
          className={classNames(sign.inputLabel, {
            [sign.labelActive]: fieldsActiveState.confirmPassword || getFieldValue('confirmPassword'),
          })}
        >
          {t('signup.confirm_password')}
        </div>
      </Form.Item>
      <span style={{ fontSize: '11px', color: '#A9B5BD' }} className={sign.gray_text}>
        {t(passwordHint)}
      </span>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
          {t('signup.log_in')}
        </Button>
      </Form.Item>
    </>
  );
};

export default ThirdStep;
