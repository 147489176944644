import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Icon, Modal, notification, Row, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import styles from './styles.module.less';
import closeIcon from 'assets/img/layout/greyClose.svg';
import FormSelect from 'components/FormSelect';
import { exportContracts, getTransferConfig, getContractExport, getTransferStatus } from 'api/insuranceContracts';
import { parseResponseForSelectInput } from 'screens/InsuranceContracts/List/components/ImportModal/helpers/parseResponseForSelectInput';
import { DurationInput } from './DurationDate';
import { getPartnersList } from 'api/partners';
import { getContractTypeProducts } from 'store/insuranceProducts/actions';
import { useAsync } from 'hooks/useAsync';
import { fetch } from 'store/insuranceContracts/actions';
import CustomBtn from 'components/CustomBtn';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'components/Progress/Progress';

interface IProducts {
  code: string;
  id: number;
  insuranceCompanyId: number;
  name: string;
  partnerId: number;
}

interface IExportModal {
  isExportOpen: boolean;
  onCloseExport: () => void;
  form: any;
  products: IProducts[];
}

interface IOptions {
  title: string;
  value: number;
  key: number;
}

const ExportModal = ({ isExportOpen, onCloseExport, form, products }: IExportModal) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getFieldDecorator, setFieldsValue, getFieldsValue } = form;
  const [partnersList, setPartnersList] = useState<IOptions[]>([]);
  const [configurationsList, setConfigurationList] = useState<IOptions[]>([]);
  const [partner, setPartner] = useState<number>(0);
  const [currentProgram, setCurrentProgram] = useState<number>(0);
  const [currentConfig, setCurrentConfig] = useState<number>(0);
  const [disableExport, setDisableExport] = useState<boolean>(false);
  const [isProgressVisible, showProgress] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<number>(0);
  const [percents, setPercents] = useState<number>(0);
  const {
    doFetch: fetchAllowedContracts,
    value: allowedContracts,
    status: countStatus,
    error: allowedError,
  } = useAsync(getContractExport);
  const { doFetch: sendExport, value: data, status } = useAsync(exportContracts);
  const { doFetch: getStatus, value: transferStatus } = useAsync(getTransferStatus);

  useEffect(() => {
    getPartnersList({}).then(response => {
      const data = parseResponseForSelectInput(response.data.resultList ?? []);
      setPartnersList(data);
      setPartner(data[0].value);
    });
  }, []);

  useEffect(() => {
    if (data) {
      setIntervalId(
        setInterval(() => {
          getStatus(data.data.id);
        }, 1000),
      );
    }
  }, [data]);

  useEffect(() => {
    if (transferStatus && transferStatus.data) {
      setPercents(
        +(((transferStatus.data.transferred ?? 0) * 100) / (transferStatus.data.countToTransfer ?? 0)).toFixed(1),
      );
    }
    if (transferStatus && transferStatus?.data.status === 'LOADED') {
      notification.info({
        message: `${t('insurance_contracts.exported_contracts_export_success')}`,
      });
      dispatch(fetch(t));
      clearInterval(intervalId);
    }
  }, [transferStatus]);

  useEffect(() => {
    if (partnersList.length) {
      getTransferConfig({ partnerId: partnersList[0]?.key, processType: 'EXPORT' }).then(response => {
        const data = parseResponseForSelectInput(response.data);
        setConfigurationList(data);
        if (data) {
          setCurrentConfig(data[0]?.value);
        }
      });
    }
  }, [partnersList]);

  useEffect(() => {
    if (products.length) {
      setFieldsValue({
        contractType: products[0].id,
      });
      setCurrentProgram(products[0].id);
    }
  }, [products]);

  useEffect(() => {
    getCurrentProducts();
  }, [partner]);

  useEffect(() => {
    if (allowedError && allowedError.response) {
      const { data } = allowedError.response;
      const trigger = data.errors.some(item => item.code === 'NOT_FOUND_CONTRACT_FOR_EXPORT');
      setDisableExport(trigger);
    } else {
      setDisableExport(false);
    }
  }, [allowedError]);

  const getCurrentProducts = () => {
    dispatch(getContractTypeProducts(t, partner));
  };

  const onChangePartnerId = useCallback(async (_, { props }) => {
    const partnerId = props.value;
    setFieldsValue({ partnerId: partnerId });
    setPartner(partnerId);
    await getTransferConfig({ partnerId: partnerId, processType: 'EXPORT' }).then(response => {
      const data = parseResponseForSelectInput(response.data);
      if (data) {
        setConfigurationList(data);
        setCurrentConfig(data[0].value);
      } else {
        setCurrentConfig(0);
        setConfigurationList([]);
        setDisableExport(true);
      }
    });
  }, []);

  const changeProgram = (val: number) => {
    setCurrentProgram(val);
  };

  const onChangeDownloadType = useCallback((_, { props }) => {
    const contractImportConfigurationId = props.value;
    setFieldsValue({ exportType: contractImportConfigurationId });
    setCurrentConfig(contractImportConfigurationId);
  }, []);

  const productsOptions = products.map(item => ({
    title: item.name,
    value: item.id,
    key: item.id,
  }));

  const onCencel = () => {
    onCloseExport();
  };

  const onExport = params => {
    showProgress(true);
    sendExport(params);
  };

  const exportRequests = (event, type: string) => {
    event.preventDefault();
    const { endSignDate, startSignDate } = getFieldsValue();
    const requestsParams = {
      partnerId: partner,
      insuranceProductId: currentProgram,
      contractTransferConfigurationId: currentConfig,
      startSignDate: startSignDate.format('YYYY-MM-DDT00:00:00'),
      endSignDate: endSignDate.format('YYYY-MM-DDT23:59:59'),
    };
    switch (type) {
      case 'EXPORT':
        onExport(requestsParams);
        return;
      case 'GET_ALLOWED_EXPORT':
        fetchAllowedContracts(requestsParams);
        return;
    }
  };

  return (
    <Modal
      centered
      footer={null}
      closable={false}
      visible={isExportOpen}
      className={cx(styles.container)}
      onCancel={onCloseExport}
      width={800}
    >
      <div className={styles.ExportContent}>
        <div className={styles.ExportHeader}>
          <h2>{t('insurance_contracts.data_export_export')}</h2>
          <div className={styles.CloseIcon}>
            <div className={cx(styles.CloseIcon)} onClick={onCencel}>
              <img src={closeIcon} width="16" height="16" alt="close icon" />
            </div>
          </div>
        </div>

        <div className={styles.Controlls}>
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item>
                  {getFieldDecorator('partnerId', {
                    initialValue: partnersList?.[0]?.value,
                  })(
                    <FormSelect
                      selectDataId="partners"
                      optionDataId="partner"
                      title={t('insurance_contracts.partner_export')}
                      options={partnersList}
                      onChange={onChangePartnerId}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item>
                  {getFieldDecorator('contractType', {
                    initialValue: productsOptions?.[0]?.value,
                  })(
                    <FormSelect
                      selectDataId="contractTypes"
                      optionDataId="contractType"
                      title={t('insurance_contracts.type_of_contract_export')}
                      options={productsOptions}
                      onChange={changeProgram}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Form.Item>
                  {getFieldDecorator('exportType', {
                    initialValue: configurationsList?.[0]?.value,
                  })(
                    <FormSelect
                      selectDataId="exportTypes"
                      optionDataId="exportType"
                      title={t('insurance_contracts.type_of_export_export')}
                      options={configurationsList}
                      onChange={onChangeDownloadType}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={11}>
                <DurationInput form={form} exportRequests={exportRequests} countStatus={countStatus} mode={'EXPORT'} />
              </Col>
            </Row>
            <div className={styles.actionButtons}>
              <Row type="flex" justify="space-between">
                <Col span={11}>
                  <CustomBtn onClick={onCencel} className={styles.cencel}>
                    {t('insurance_contracts.cancel_export')}
                  </CustomBtn>
                </Col>
                <Col span={11}>
                  <CustomBtn
                    onClick={e => exportRequests(e, 'EXPORT')}
                    className={styles.export}
                    disabled={!allowedContracts?.data.count || status === 'pending' || disableExport}
                  >
                    {status === 'pending' ? (
                      <Spin indicator={<Icon type="loading" spin />} />
                    ) : (
                      t('insurance_contracts.export_button_export')
                    )}
                  </CustomBtn>
                </Col>
              </Row>
              <Row>
                <div className={styles.Screen}>
                  <div>
                    {t('insurance_contracts.number_of_contracts')}{' '}
                    {allowedContracts && !allowedError ? allowedContracts.data.count : '0'}
                  </div>
                  <div>
                    {t('insurance_contracts.exported_contracts_modal_export')}{' '}
                    {transferStatus ? transferStatus.data.transferred : ''}
                  </div>
                  {isProgressVisible && <ProgressBar value={percents} />}
                </div>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default Form.create<any>({ name: 'exportModal' })(ExportModal);
