import moment from 'moment';

export const fieldExeptions: any = ['createdDate'];

export const convertTime = (date: string) => {
  if (date) {
    const localDate = date && moment(moment.utc(date).toDate()).local();
    return localDate ? moment(localDate).format('DD.MM.YYYY') : null;
  }
  return null;
};

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const getFullNumber = (phoneNumber: string) =>
  phoneNumber.includes('+380') ? phoneNumber : `+380${phoneNumber}`;

export const requiredCreateValues = [
  'abbreviation',
  'enterpriseCode',
  'name',
  'phoneNumber',
  'directorPosition',
  'directorFullName',
  'language',
  'claimManager.email',
  'shortName',
  'fullName',
  'claimManager.name',
  'insuranceManager.email',
  'insuranceManager.phoneNumber',
  'claimManager.phoneNumber',
  'insuranceManager.name',
  'ibanAccountNumber',
  'postAddress',
  'startDate',
  'createdDate',
  'code',
  'currency',
  'smsSenderName',
];

export const createMaxMin = t => [
  {
    fieldName: 'abbreviation',
    rule: {
      message: t('insurance_configuration.from_1_to_5_characters'),
      min: 1,
      max: 5,
    },
  },
  {
    fieldName: 'startDate',
    rule: { type: 'object', message: t('insurance_configuration.not_less_than_registration_date') },
  },
  {
    fieldName: 'createdDate',
    rule: { type: 'object', message: t('insurance_configuration.not_less_than_current') },
  },
  {
    fieldName: 'endDate',
    rule: { type: 'object', message: t('insurance_configuration.not_less_than_registration date') },
  },
  {
    fieldName: 'enterpriseCode',
    rule: {
      message: t('insurance_configuration.from_8_to_11_characters'),
      min: 8,
      max: 11,
    },
  },
  {
    fieldName: 'fullName',
    rule: {
      message: t('insurance_configuration.maximum_number_200_characters'),
      max: 200,
    },
  },
  {
    fieldName: 'shortName',
    rule: {
      message: t('insurance_configuration.maximum_number_100_characters'),
      max: 100,
    },
  },
  {
    fieldName: 'name',
    rule: {
      message: t('insurance_configuration.maximum_number_200_characters'),
      max: 200,
    },
  },
  {
    fieldName: 'phoneNumber',
    rule: {
      message: t('insurance_configuration.minimum_number_is_13_characters'),
      min: 13,
    },
  },
  {
    fieldName: 'postAddress',
    rule: {
      message: t('insurance_configuration.maximum_number_255_characters'),
      max: 255,
    },
  },
  {
    fieldName: 'email',
    rule: {
      message: t('insurance_configuration.maximum_number_50_characters'),
      max: 50,
    },
  },
  {
    fieldName: 'siteUrl',
    rule: {
      message: t('insurance_configuration.maximum_number_100_characters'),
      max: 100,
    },
  },
  {
    fieldName: 'ibanAccountNumber',
    rule: {
      message: t('insurance_configuration.from_20_to_29_characters'),
      min: 20,
      max: 29,
    },
  },
  {
    fieldName: 'bankName',
    rule: {
      message: t('insurance_configuration.maximum_number_100_characters'),
      max: 100,
    },
  },
  {
    fieldName: 'bankEnterpriseCode',
    rule: {
      message: t('insurance_configuration.minimum_number_6_characters'),
      min: 6,
    },
  },
  {
    fieldName: 'directorPosition',
    rule: {
      message: t('insurance_configuration.maximum_number_100_characters'),
      max: 100,
    },
  },
  {
    fieldName: 'directorFullName',
    rule: {
      message: t('insurance_configuration.maximum_number_255_characters'),
      max: 255,
    },
  },
  {
    fieldName: 'customerSupportPhoneNumber',
    rule: {
      message: t('insurance_configuration.minimum_number_is_13_characters'),
      min: 13,
    },
  },
  {
    fieldName: 'customerSupportEmail',
    rule: {
      message: t('insurance_configuration.maximum_number_50_characters'),
      max: 50,
    },
  },
  {
    fieldName: 'customerSupportUrl',
    rule: {
      message: t('insurance_configuration.maximum_number_100_characters'),
      max: 100,
    },
  },
  {
    fieldName: 'partnerSupportPhoneNumber',
    rule: {
      message: t('insurance_configuration.minimum_number_is_13_characters'),
      min: 13,
    },
  },
  {
    fieldName: 'telegramChannel',
    rule: {
      message: t('insurance_configuration.maximum_number_100_characters'),
      max: 100,
    },
  },
  {
    fieldName: 'contactPerson.name',
    rule: {
      message: t('insurance_configuration.maximum_number_200_characters'),
      max: 200,
    },
  },
  {
    fieldName: 'contactPerson.phoneNumber',
    rule: {
      message: t('insurance_configuration.minimum_number_is_13_characters'),
      min: 13,
    },
  },
  {
    fieldName: 'contactPerson.email',
    rule: {
      message: t('insurance_configuration.maximum_number_50_characters'),
      max: 50,
    },
  },
  {
    fieldName: 'insuranceManager.name',
    rule: {
      message: t('insurance_configuration.maximum_number_200_characters'),
      max: 200,
    },
  },
  {
    fieldName: 'insuranceManager.phoneNumber',
    rule: {
      message: t('insurance_configuration.minimum_number_is_13_characters'),
      min: 13,
    },
  },
  {
    fieldName: 'insuranceManager.email',
    rule: {
      message: t('insurance_configuration.maximum_number_50_characters'),
      max: 50,
    },
  },
  {
    fieldName: 'claimManager.name',
    rule: {
      message: t('insurance_configuration.maximum_number_200_characters'),
      max: 200,
    },
  },
  {
    fieldName: 'claimManager.phoneNumber',
    rule: {
      message: t('insurance_configuration.minimum_number_is_13_characters'),
      min: 13,
    },
  },
  {
    fieldName: 'claimManager.email',
    rule: {
      message: t('insurance_configuration.maximum_number_50_characters'),
      max: 50,
    },
  },
  {
    fieldName: 'claimManager.email',
    rule: {
      message: t('insurance_configuration.maximum_number_50_characters'),
      max: 50,
    },
  },
  {
    fieldName: 'code',
    rule: {
      message: t('insurance_configuration.maximum_number_6_characters'),
      max: 6,
    },
  },
  {
    fieldName: 'smsSenderName',
    rule: {
      message: t('insurance_configuration.maximum_number_50_characters'),
      max: 50,
    },
  },
];
