import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Paper } from '@material-ui/core';
import { ViewCallbackProperties } from 'react-calendar';
import classNames from 'classnames';
import moment from 'moment';

import { ROUTES } from 'constants/routes';
import { AppState } from 'store/reducer';
import history from 'routes/history';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import PlusIcon from 'components/Button/icons/Plus';
import { getHistory, clearHistory } from 'store/appointment/actions';
import Events from './components/Events';
import Calendar from './components/Calendar';
import { IHistory } from 'types/appointments';
import { checkPermissions } from 'roles/userCan';

import styles from './History.module.less';

const History: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultContract = localStorage.getItem('defaultContract');

  const fetchHistory = data => dispatch(getHistory(data));
  const resetHistoryData = () => dispatch(clearHistory());

  const userId = useSelector((store: AppState) => store.user?.authentication?.userId);
  const { historyData } = useSelector(({ appointment: { historyData } }: AppState) => ({
    historyData,
  }));

  const [highlightedDates, setHighlightedDates] = useState<string[] | null>(null);
  const [selectedDayData, setSelectedDayData] = useState<IHistory[]>([]);
  const [isHistoryDataLoaded, setIsHistoryDataLoaded] = useState<boolean>(false);

  const [viewDate, setViewDate] = useState(new Date());
  const [viewDates, setViewDates] = useState({
    startDate: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    endDate: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
  });

  const getCurrentDayData = useCallback(() => {
    const currentData = historyData.filter(
      ({ meetingTime }) => moment(meetingTime).format('DD-MM-YYYY') === moment(viewDate).format('DD-MM-YYYY'),
    );
    setSelectedDayData(currentData);
  }, [historyData, viewDate]);

  useEffect(() => {
    return () => {
      resetHistoryData();
    };
  }, []);

  useEffect(() => {
    getCurrentDayData();
  }, [viewDate]);

  useEffect(() => {
    const formatedData = {
      clientId: userId,
      dateFrom: viewDates.startDate,
      dateTo: viewDates.endDate,
      contractNumber: defaultContract ? JSON.parse(defaultContract).contractNumber : '',
    };
    fetchHistory(formatedData);
  }, [viewDates]);

  useEffect(() => {
    const dates = historyData.map(({ meetingTime }) => moment(meetingTime).format('DD-MM-YYYY'));
    setHighlightedDates(dates);

    if (historyData.length > 0 && !isHistoryDataLoaded) {
      setIsHistoryDataLoaded(true);
      getCurrentDayData();
    }
  }, [historyData]);

  const onMonthChange = (action: ViewCallbackProperties) => {
    if (
      moment(action.activeStartDate)
        .startOf('month')
        .format('YYYY-MM-DD') !== viewDates.startDate
    ) {
      setViewDates({
        startDate: moment(action.activeStartDate)
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment(action.activeStartDate)
          .endOf('month')
          .format('YYYY-MM-DD'),
      });
    }
  };

  const onAddButtonClick = () => {
    history.push(ROUTES.CONSULTATIONS.APPOINTMENTS);
  };

  const onSubeventClick = subevent => {
    history.push(ROUTES.CONSULTATIONS.SUBEVENT.replace(':id', subevent.id), {
      id: subevent.id,
      contractNumber: subevent.contractNumber,
      caseId: subevent.caseId,
      meetingTime: subevent.meetingTime,
    });
  };

  return (
    <div>
      <PageBreadcrumbs
        data={
          checkPermissions('CHATS', 'MENU')
            ? [
                { label: t('history.home'), href: ROUTES.DASHBOARD.ROOT },
                { label: t('history.consultations'), href: ROUTES.CONSULTATIONS.ROOT },
                { label: t('history.page_title') },
              ]
            : [{ label: t('history.home'), href: ROUTES.DASHBOARD.ROOT }, { label: t('history.page_title') }]
        }
      />
      <PageHeader title={t('history.page_title')}>
        <Button onClick={onAddButtonClick} startIcon={<PlusIcon />}>
          {t('history.service_record')}
        </Button>
      </PageHeader>
      <Grid className={styles.grid} container spacing={2}>
        <Grid className={styles.gridItem} item xs={6}>
          <Paper className={classNames(styles.paper, styles.eventsPaper)} elevation={0}>
            <Events events={selectedDayData} viewDate={viewDate} onSubeventClick={onSubeventClick} />
          </Paper>
        </Grid>
        <Grid className={classNames(styles.gridItem, styles.calendarGrid)} item xs={5}>
          <Paper className={classNames(styles.paper, styles.calendarPaper)} elevation={0}>
            <Calendar
              viewDate={viewDate}
              highlightedDates={highlightedDates}
              setViewDate={setViewDate}
              onMonthChange={onMonthChange}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default History;
