import { AnyAction, combineReducers } from 'redux';

import insuredEventsList from './insuredEventsList';
import contractList from './contractList';
import actsList from './actsList';
import actCreate from './actCreate';
import actDetails from './actDetails';
import contractDetails from './contractDetails';
import deviceSearch from './deviceSearch';
import termination from './termination';
import insuredEvent from './insuredEvent';
import insurance from './insurance';
import user from './user';
import expressPayment from './expressPayment';
import terminationList from './terminationList';
import adminList from './adminList';
import insuranceProducts from './insuranceProducts';
import privateOffice from './privateOffice';
import userCard from './userCard';
import usersActivities from './usersActivities';
import departments from './departments';
import departmentDetails from './departmentDetails';
import insuranceContracts from './insuranceContracts';
import integrationHistory from './integrationHistory';
import insuranceCompanies from './insuranceCompanies';
import insuranceProgramsList from './insuranceProgramsList';
import insuranceProgramDetails from './insuranceProgramDetails';
import insuranceConfiguration from './insuranceConfiguration';
import partnersList from './partnersList';
import partnerCreate from './partnerCreate';
import insuranceProgramCreate from './insuranceProgramCreate';
import brands from './brands';
import registrationDevices from './registrationDevices';
import sublimits from './sublimits';
import subevents from './subevents';
import ui from './ui';
import registerTypes from './registerTypes';
import medApp from './medApp';
import institutions from './institutions';
import claims from './claims';
import appointment from './appointment';

import { StoreType } from 'types/store';

const rootReducer = combineReducers<StoreType>({
  usersActivities,
  insuredEventsList,
  contractList,
  registrationDevices,
  actsList,
  actCreate,
  actDetails,
  contractDetails,
  termination,
  insuredEvent,
  insurance,
  deviceSearch,
  user,
  departments,
  departmentDetails,
  insuranceProgramsList,
  insuranceProgramDetails,
  expressPayment,
  terminationList,
  adminList,
  privateOffice,
  userCard,
  insuranceCompanies,
  insuranceConfiguration,
  brands,
  insuranceProducts,
  partnersList,
  partnerCreate,
  insuranceContracts,
  integrationHistory,
  insuranceProgramCreate,
  sublimits,
  subevents,
  ui,
  registerTypes,
  medApp,
  appointment,
  institutions,
  claims,
});

const appReducer = (state: ReturnType<typeof rootReducer>, action: AnyAction) =>
  action.type === 'RESET_STORE' ? rootReducer(rootReducer(undefined, action), action) : rootReducer(state, action);

export type AppState = ReturnType<typeof appReducer>;

export default appReducer;
