export default {
  // super admin
  ROLE_ADMIN: {
    // Услуги
    CHATS: {
      READ: true,
      BUTTONS: false,
      MENU: true,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: true,
    },
    // Анализ интерграционных процессов
    PROCESS_ANALYTICS: {
      READ: true,
      IMPORT: true,
    },
    // Аннуляция страховых событий
    ANNUL_INSURANCE: { CREATE: true },
    //search
    SEARCH: {
      READ: true,
      CONTRACT: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true, //can remove if we do not need express payment in search results
      INSURANCE: true,
    },
    // оплата экспресс выплаты на страховом событии
    EXPRESSPAYMENT: { PAY: true, ALERT: true, DELAY: true },
    // довідник брендів
    BRAND_PAGE: { READ: true },
    //Страхові компанії
    INSURANCE_COMPANIES: {
      LIST: true,
      SETTINGS: true,
      TOGGLE_SWITCH: true,
      CREATE: true,
    },
    //Програма страхування
    INSURANCE_PROGRAMS: { READ: true },
    // довідник підрозділів
    DEPARTMENTS: {
      READ: true,
      PARTNER_COMPANY: true,
      INSURANCE_COMPANY: true,
      PARTNER_TYPE: true,
    },
    //Реєстр активностей
    USERS_ACTIVITIES: { READ: true },
    //Реєстр активностей / новий користувач
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
      LOCALITY: true,
      EMAIL: true,
      PHONE: true,
      INSURANCE_COMPANY: true,
      INSURANCE_COMPANY_VISIBILITY: true,
      PARTNER_COMPANY: true,
      REGION: true,
      ROLE_TOGGLE: true,
      INSURANCE_PROGRAMS: true,
      USER_ACTIVITY: true,
      ROLES_LIST: true,
      CLIENT: true,
    },
    //Реєстр користувачів
    ADMIN_PANEL: { READ: true, USER_CARD: true, FIND_ALL_USERS: true },
    //страхові справи
    INSURANCE_LIST: { READ: true, PRINT: true },
    //реестр розірвань
    TERMINATIONS_LIST: { READ: true, PRINT: true },
    //Реєстр актів виконаних робіт
    ACTS: { READ: true, PRINT: true, CREATE: true },
    //Реєстр договорів страхування
    CONTRACTS_LIST: { READ: true, PRINT: true },
    CONTRACTS_LIST_TOTAL: { READ: true },
    //Головна
    DASHBOARD: { ANALYTICS: true, READ: false },
    //Налаштування
    SETTINGS: { READ: true },
    //сайд меню телеграм
    TELEGRAM: { READ: true },
    //сайд меню вики
    WIKI: { READ: true },
    //сайд меню версия системы
    HELP: { READ: true },
    //настройки Права та ролі
    ACCESS_POLICY: { READ: true },
    //просмотр страхового дела
    INSURANCE_DETAILS: { READ: true, PRINT: true },
    //договор страхования
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: false,
      WITHDRAWAL: true,
      EDIT: false,
      RECONCILIATION: true,
    },
    //заявление на расторжение
    TERMINATION: { READ: true, CREATE: true, PRINT: true },
    //заявление о страховом событии
    INSURANCE: { READ: true, CREATE: true, PRINT: true, PAYMENT_DURATION: true },
    //список партнеров
    PARTNERS_LIST: { READ: true },
    //регистрация техники
    REGISTER_DEVICES: {
      READ: true,
    },
    SALE_POINTS: {
      READ: true,
    },
  },
  // админ страховой
  ROLE_INSURANCE_ADMIN: {
    // Услуги
    CHATS: {
      READ: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    // Аннуляция страховых событий
    ANNUL_INSURANCE: { CREATE: true },

    SEARCH: {
      READ: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true, //can remove if we do not need express payment in search results
      INSURANCE: true,
      CONTRACT: true,
    },
    EXPRESSPAYMENT: { PAY: true, ALERT: true, DELAY: true },
    BRAND_PAGE: { READ: true },
    INSURANCE_COMPANIES: { LIST: true, SETTINGS: true, CREATE: false },
    INSURANCE_PROGRAMS: { READ: true },
    DEPARTMENTS: { READ: true, PARTNER_COMPANY: true, PARTNER_TYPE: true, INSURANCE_COMPANY: true },
    USERS_ACTIVITIES: { READ: true },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
      LOCALITY: true,
      EMAIL: true,
      PHONE: true,
      INSURANCE_COMPANY: true,
      PARTNER_COMPANY: true,
      REGION: true,
      ROLE_TOGGLE: true,
      INSURANCE_PROGRAMS: true,
      USER_ACTIVITY: true,
      ROLES_LIST: true,
      CLIENT: true,
      // INSURANCE_COMPANY_VISIBILITY: true,// ????????
    },
    ADMIN_PANEL: { READ: true, USER_CARD: true },
    INSURANCE_LIST: { READ: true, PRINT: true },
    TERMINATIONS_LIST: { READ: true, PRINT: true },
    ACTS: { READ: true, PRINT: true, CREATE: true },
    CONTRACTS_LIST: { READ: true, PRINT: true },
    CONTRACTS_LIST_TOTAL: { READ: true },
    DASHBOARD: { ANALYTICS: true, READ: false },
    SETTINGS: { READ: true },
    TELEGRAM: { READ: true },
    WIKI: { READ: true },
    HELP: { READ: true },
    ACCESS_POLICY: { READ: true },
    INSURANCE_DETAILS: { READ: true },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: false,
      WITHDRAWAL: true,
      RECONCILIATION: true,
      EDIT: true,
    },
    TERMINATION: { READ: true, CREATE: true, PRINT: true },
    INSURANCE: { READ: true, CREATE: true, PRINT: true, PAYMENT_DURATION: true },
    PARTNERS_LIST: { READ: true },
    // Анализ интерграционных процессов
    PROCESS_ANALYTICS: {
      READ: true,
      IMPORT: true,
    },
    // регистрация техники
    REGISTER_DEVICES: {
      READ: true,
    },
    SALE_POINTS: {
      READ: true,
    },
  },
  ROLE_PARTNER_ADMIN: {
    // Услуги
    CHATS: {
      READ: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    // Аннуляция страховых событий
    ANNUL_INSURANCE: { CREATE: true },

    // 3 администратор цо водафон
    ADMIN_PANEL: {
      READ: true,
      USER_CARD: true,
      FIND_ALL_USERS: false,
    },
    DASHBOARD: {
      ANALYTICS: true,
      READ: false,
    },
    SEARCH: {
      READ: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: false,
      INSURANCE: true,
    },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: false,
      WITHDRAWAL: true,
    },
    TERMINATION: {
      READ: true,
      CREATE: true,
      PRINT: false,
    },
    TERMINATIONS_LIST: {
      READ: false,
      PRINT: false,
    },
    INSURANCE: {
      READ: false,
      CREATE: false,
      PRINT: false,
    },
    ACTS: {
      READ: true,
      PRINT: true,
      CREATE: false,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: true,
    },
    CONTRACTS_LIST_TOTAL: { READ: true },
    INSURANCE_LIST: {
      READ: true,
      PRINT: false,
    },
    INSURANCE_DETAILS: {
      READ: false,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
      LOCALITY: true,
      EMAIL: true,
      PHONE: true,
      INSURANCE_COMPANY: true,
      INSURANCE_COMPANY_VISIBILITY: false,
      PARTNER_COMPANY: true,
      REGION: true,
      ROLE_TOGGLE: true,
      INSURANCE_PROGRAMS: true,
      USER_ACTIVITY: true,
      ROLES_LIST: true,
    },
    USERS_ACTIVITIES: {
      READ: true,
    },
    DEPARTMENTS: {
      READ: true,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: true,
    },
    INSURANCE_COMPANIES: {
      LIST: false,
      SETTINGS: false,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SALE_POINTS: {
      READ: true,
    },
  },
};
