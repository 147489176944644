import { notification } from 'antd';
import { getInsuranceProducts } from 'api/insuranceProducts';
import { createTypes } from 'redux-compose-reducer';
import * as InsuredEventsAPI from '../../api/InsuredEvents';

export const TYPES = createTypes('productsList', [
  'setPending',
  'setProducts',
  'setInsObjects',
  'setContractTypeProducts',
]);
export const setPending = (pending: boolean) => ({ type: TYPES.setPending, pending });
const setContractTypeProducts = (products: boolean) => ({
  type: TYPES.setContractTypeProducts,
  payload: { data: products },
});
const setProducts = (products: boolean) => ({ type: TYPES.setProducts, payload: { data: products } });

// TODO Add interfaces
export const getProducts = (t, partnerId?: number) => async dispatch => {
  dispatch(fetch(t, setProducts, partnerId));
};

export const getContractTypeProducts = (t, partnerId?: number) => async dispatch => {
  dispatch(fetch(t, setContractTypeProducts, partnerId));
};

const fetch = (t, method, partnerId?: number) => async dispatch => {
  dispatch(setPending(true));
  try {
    const program = (await getInsuranceProducts(partnerId)).data.resultList ?? [];
    dispatch(method(program));
  } catch (error) {
    notification.error({
      message: t('popup.getting_products'),
      description: t('popup.try_again_products'),
    });
  } finally {
    dispatch(setPending(false));
  }
};

export const getInsuranceObjects = (id: number | string | null, t) => async (dispatch: any) => {
  dispatch(setPending(true));
  try {
    const response = await InsuredEventsAPI.getInsObjects(id);
    const payload = response.data ? response.data : [];
    dispatch({ type: TYPES.setInsObjects, payload });
  } catch (err) {
    notification.error({
      message: t('popup.getting_products'),
      description: t('popup.try_again_products'),
    });
  } finally {
    dispatch(setPending(false));
  }
};
