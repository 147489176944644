import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import { AnyAction } from 'redux';
import config from '../../config';
import { ICompaniesState } from 'types/insuranceCompany';

const initialState: ICompaniesState = {
  loading: false,
  data: [],
  pagination: {
    size: config.ui.pagination.size,
    page: 1,
    total: 0,
  },
};

const setData = (state: ICompaniesState, action: AnyAction): ICompaniesState => {
  return { ...state, data: action.payload };
};

const setLoading = (state: ICompaniesState, action: AnyAction): ICompaniesState => {
  return { ...state, loading: action.payload };
};

const setPagination = (state: ICompaniesState, action: AnyAction): ICompaniesState => {
  return { ...state, pagination: action.payload };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setData,
    setLoading,
    setPagination,
  },
});
