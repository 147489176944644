import React, { FunctionComponent } from 'react';
import { Form, Button, Typography } from 'antd';
import { IStepValues } from '../types';

import * as sign from '../../SignUp/SignUp.module.less';

//@ts-ignore
import Presentation from 'assets/Presentation-InRisk.pdf';
import { useTranslation } from 'react-i18next';
interface IFirstStep {
  values: IStepValues;
}

const LastStep: FunctionComponent<IFirstStep> = ({ values }) => {
  const middleValue =
    values.middleName && values.middleName !== null && values.middleName !== 'null' ? values.middleName : '';
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title level={3}>
        {t('signup.respected')} (-а) <br />
        {`${values.lastName} ${values.firstName}${' ' + middleValue}!`}
      </Typography.Title>
      <p className={sign.greeting_subtitle}>{t('signup.thank_you_for_registering')} INRISK.</p>
      <p className={sign.greeting_text}>
        {t('signup.your_account_has_been_submitted')}
        <span className={sign.green_text}>{` ${values.email}`}</span>
      </p>
      <p className={sign.greeting_text}>
        {t('signup.before_working_with_the_system')}{' '}
        <a href={Presentation} rel="noopener noreferrer" target="_blank" className={sign.green_text}>
          {t('signup.user_manual')}
        </a>
      </p>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
          {t('signup.log_in')}
        </Button>
      </Form.Item>
    </>
  );
};

export default LastStep;
