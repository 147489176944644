import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Form, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Grid } from '@material-ui/core';

import { addSubsublimit, editSubsublimit } from 'store/sublimits/actions';

import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import { CardGrid, Cards, Text, ModalHeader, ModalText } from './styles';
import FormInputText from 'components/FormInputText';
import FormSelect from 'components/FormSelect';
import Button from 'components/Button';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { formatSublimitsData } from '../../utils';

interface ISubsublimitFormProps extends FormComponentProps {
  sublimitsData: any;
  subsublimitData: any;
  errorMessage: any;
  isEdit: boolean;
}

const SubsublimitForm: React.FC<ISubsublimitFormProps> = ({
  sublimitsData,
  subsublimitData,
  errorMessage,
  isEdit,
  form,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const addNewSubsublimit = data => dispatch(addSubsublimit(data));
  const editSubsublimitById = (id, data) => dispatch(editSubsublimit(id, data));

  const handleErrorMessage = () => {
    if (errorMessage.includes('SubSubLimit with name:') && errorMessage.includes('already exists')) {
      return <Trans i18nKey="sublimits.subsublimit_exist" values={{ name: form.getFieldValue('name') }} />;
    } else {
      return errorMessage;
    }
  };

  useEffect(() => {
    if (errorMessage) {
      notification.error({
        message: handleErrorMessage(),
      });
    }
  }, [errorMessage]);

  const onSubmit = e => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          isEdit ? editSubsublimitById(subsublimitData.id, values) : addNewSubsublimit(values);
        } catch (e) {
          console.log('error', e);
        }
      }
    });
  };

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  const onSubmitModal = () => {
    history.push(ROUTES.MEDAPP.SUBLIMITS.ROOT);
  };

  return (
    <CardGrid>
      <Form name="SubsublimitForm" onSubmit={onSubmit}>
        <Cards>
          <Text size={18} weight={600} bottomM={20}>
            {isEdit ? t('sublimits.edit_subsublimit') : t('sublimits.add_subsublimit')}
          </Text>
          <Grid xs="auto" container item spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form?.getFieldDecorator('name', {
                  rules: [{ required: true, message: t('sublimits.need_enter_subsublimit') }],
                  validateFirst: true,
                  initialValue: subsublimitData && isEdit ? subsublimitData.name : '',
                })(<FormInputText title={t('sublimits.subsublimit_name')} />)}
              </Form.Item>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form?.getFieldDecorator('sublimitId', {
                  rules: [{ required: true, message: t('sublimits.need_select_sublimit') }],
                  validateFirst: true,
                  initialValue: subsublimitData && isEdit ? subsublimitData.sublimitId : '',
                })(
                  <FormSelect
                    title={t('sublimits.select_sublimit')}
                    onChange={() => {}}
                    options={formatSublimitsData(sublimitsData)}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Grid>
          </Grid>
        </Cards>
        <Grid container spacing={2} alignItems={'center'} justify={'flex-end'}>
          <Grid item xs={'auto'}>
            <Button variant={'contained'} size={'large'} onClick={toggleModal}>
              {t('sublimits.cancel')}
            </Button>
          </Grid>
          <Grid item xs={'auto'}>
            <Button type="submit" color={'primary'} size={'large'}>
              {t('sublimits.save')}
            </Button>
          </Grid>
        </Grid>
      </Form>
      <ConfirmModal
        isModalOpen={isModalOpen}
        onSubmitModal={onSubmitModal}
        onClose={toggleModal}
        closeText={t('sublimits.cancel')}
        submitText={t('sublimits.modal_confirm')}
      >
        <ModalHeader>{t('sublimits.modal_header')}</ModalHeader>
        <ModalText>{t('sublimits.modal_text')}</ModalText>
      </ConfirmModal>
    </CardGrid>
  );
};

export default Form.create<ISubsublimitFormProps>({ name: 'SubsublimitForm' })(SubsublimitForm);
