import createInstance from './axios';
import { AxiosResponse } from 'axios';
import { IPagination } from '../components/TableFooter/TableFooter';
import moment from 'moment';
import momentTz from 'moment-timezone';

import {
  ResultListDtoDepartmentView,
  DepartmentDto,
  ResultListDtoInsuranceCompanyView,
  CompanyView,
} from 'types/dto/contracts-service';

const DepartmentsAPI = createInstance('CONTRACTS');

export const find = (params: any = {}, pagination: IPagination) => {
  const query = { ...params };
  if (params.period) {
    query.createdDate = `${moment(params.period.from).format('YYYY-MM-DD')}..${moment(params.period.to).format(
      'YYYY-MM-DD',
    )}`;
    query.period = undefined;
  }
  if (!query.regionId) {
    delete query.regionId;
  }

  if (!query.partnerDepartmentId) {
    delete query.partnerDepartmentId;
  }

  return DepartmentsAPI.get<ResultListDtoDepartmentView>('/departments', {
    params: {
      sorting: '-createdDate',
      page: pagination.page,
      page_size: pagination.size,
      count: true,
      ...query,
    },
  });
};

export const findById = (id: number) => {
  return DepartmentsAPI.get<DepartmentDto>('/departments/' + id);
};

export const regions = (query?: string, options: any = {}) => {
  return DepartmentsAPI.get<ResultListDtoDepartmentView>('departments', {
    params: {
      ...options,
      region: query,
      attributes: 'region,regionId',
      distinct: true,
    },
  });
};

export const getCounterParties = async (name: string) => {
  return DepartmentsAPI.get('/counterparties?distinct', {
    params: {
      name,
      userId: '<>null',
      attributes: 'name,id',
      page_size: 10,
    },
  });
};

export const departments = (query?: string, options: any = {}) => {
  return DepartmentsAPI.get<ResultListDtoDepartmentView>('departments', {
    params: {
      ...options,
      name: query,
      attributes: 'name,partnerDepartmentId',
      distinct: true,
    },
  });
};

export const xlsx = ({ period, ...query }: any): Promise<AxiosResponse<Blob>> => {
  const timeZone = momentTz.tz.guess(true);
  return DepartmentsAPI.get('/departments/print/xlsx', {
    responseType: 'blob',
    params: {
      ...query,
      timeZone,
      createdDate: `${moment(period.from).format('YYYY-MM-DD')}..${moment(period.to).format('YYYY-MM-DD')}`,
    },
  });
};

export const getPartner = () => {
  return DepartmentsAPI.get<CompanyView[]>('/companies?partnerId=<>null&partnerDepartmentId=null').then(res => ({
    data: res.data.map(el => ({ id: el.partnerId, name: el.name })),
  }));
};

export const getPartnerId = (insuranceCompanyId: number): Promise<any> => {
  return DepartmentsAPI.get<CompanyView[]>('/companies?partnerId=<>null&partnerDepartmentId=null', {
    params: {
      insuranceCompanyId,
    },
  }).then(res => ({
    data: res.data.map(el => ({ id: el.partnerId, name: el.name })),
  }));
};

export const getInsuranceCompanies = () => {
  return DepartmentsAPI.get<ResultListDtoInsuranceCompanyView>('/insurance-companies/?distinct&count', {
    params: {
      attributes: 'id,abbreviation,enterpriseCode,name,phoneNumber,contactPersonFullName,createdDate',
    },
  });
};

export const save = data => {
  return DepartmentsAPI.post<DepartmentDto>('/departments/', data);
};

export const update = data => {
  return DepartmentsAPI.put<DepartmentDto>('/departments/' + data.id, data);
};
