import React from 'react';
import { StatusSuccess } from 'components/Icons';

const DefaultColumns = () => {
  return [
    {
      title: '',
      dataIndex: 'claimStatus',
      key: 'claimStatus',
      value: 'claimStatus',
      fixed: 'left',
      render: () => <StatusSuccess />,
      width: 70,
    },
    {
      title: 'insured_events_list.claim_number_default_table',
      dataIndex: 'claimNumber',
      key: 'claimNumber',
      value: 'claimNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.incident_date_default_table',
      dataIndex: 'incidentDate',
      key: 'incidentDate',
      value: 'incidentDate',
      width: 200,
    },
    {
      title: 'insured_events_list.contract_number_default_table',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      value: 'contractNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.signatory_default_table',
      dataIndex: 'signatory',
      value: 'signatory',
      key: 'signatory',
      width: 200,
    },
    {
      title: 'insured_events_list.insurance_object_default_table',
      dataIndex: 'insuranceObject',
      key: 'insuranceObject',
      value: 'insuranceObject',
      width: 200,
    },
    {
      title: 'insured_events_list.expert_sum_default_table',
      dataIndex: 'expertSum',
      key: 'expertSum',
      value: 'expertSum',
      width: 200,
    },
    {
      title: 'insured_events_list.insurance_program_default_table',
      dataIndex: 'insuranceProgram',
      key: 'insuranceProgram',
      value: 'insuranceProgram',
      width: 200,
    },
    {
      title: 'insured_events_list.risk_default_table',
      dataIndex: 'risk',
      key: 'risk',
      value: 'risk',
      width: 200,
    },
    {
      title: 'insured_events_list.sign_date_default_table',
      dataIndex: 'signDate',
      key: 'signDate',
      value: 'signDate',
      width: 200,
    },
    {
      title: 'insured_events_list.claim_date_default_table',
      dataIndex: 'claimDate',
      key: 'claimDate',
      value: 'claimDate',
      width: 200,
    },
    // {
    //   title: 'Дата подання',
    //   dataIndex: 'signDate',
    //   key: 'signDate',
    // },
    // {
    //   title: 'Дата експ.',
    //   dataIndex: 'signDate',
    //   key: 'signDate',
    // },
    {
      title: 'insured_events_list.franchise_default_table',
      dataIndex: 'franchise',
      key: 'franchise',
      value: 'franchise',
      width: 200,
    },
    {
      title: 'insured_events_list.payment_dum_default_table',
      dataIndex: 'paymentSum',
      key: 'paymentSum',
      value: 'paymentSum',
      width: 200,
    },
    {
      title: 'insured_events_list.payment_date_default_table',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      value: 'paymentDate',
      width: 200,
    },
    {
      title: '',
      key: 'action',
      width: 70,
      value: 'actions',
    },
  ];
};

export default DefaultColumns;
