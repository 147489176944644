import React from 'react';
import { Breadcrumb } from 'antd';
import { BreadcrumbItemProps, BreadcrumbProps } from 'antd/lib/breadcrumb';

interface Item extends BreadcrumbItemProps {
  label: string;
  children?: React.ReactNode;
}

interface Props extends BreadcrumbProps {
  data: Item[];
}

const Breadcrumbs = ({ data, ...props }: Props) => {
  return (
    <Breadcrumb {...props}>
      {data.map(({ label, children, ...itemProps }) => (
        <Breadcrumb.Item key={label} {...itemProps}>
          {children ? children : label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
