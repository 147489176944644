import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { Action, IRegistryPageStore } from '../../types/store';
import { IDepartment } from '../../types/IDepartment';
import config from '../../config/config';
import { IPagination } from '../../components/TableFooter/TableFooter';

interface IDepartmentsState extends IRegistryPageStore {}

const initialState: IDepartmentsState = {
  data: [],
  loading: false,
  query: {},
  pagination: { size: config.ui.pagination.size, page: 1, total: 0 },
};

export const setLoading = (state: IDepartmentsState, action: Action<boolean>) => {
  return { ...state, loading: action.payload };
};

export const setData = (state: IDepartmentsState, action: Action<IDepartment[]>) => {
  return { ...state, data: action.payload };
};

export const setPagination = (state: IDepartmentsState, action: Action<IPagination>) => {
  return { ...state, pagination: action.payload };
};

export const setQuery = (state: IDepartmentsState, action: Action<any>) => {
  return { ...state, query: action.payload };
};

export const setSorting = (state: IDepartmentsState, action: Action<any>) => {
  return {
    ...state,
    query: {
      ...state.query,
      sorting: action.payload.sorting,
      direction: action.payload.direction,
    },
  };
};

export const clearSorting = (state: IDepartmentsState, action: Action<any>) => {
  return {
    ...state,
    query: {
      ...state.query,
      sorting: undefined,
      direction: undefined,
    },
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setData,
    setLoading,
    setPagination,
    setQuery,
    setSorting,
    clearSorting,
  },
});
