import { makeStyles } from '@material-ui/core/styles';
import { IUseSelectStylesProps } from './useSelectStyles';
import theme from '../../../styles/theme';

export interface IUseInputStylesProps extends IUseSelectStylesProps {
  height?: number;
  padding?: string;
  position?: 'absolute' | 'relative' | 'static' | 'sticky';
}

export default makeStyles({
  formControl: {
    margin: '0 !important',
    height: (props: IUseInputStylesProps) => props.height,
  },
  root: {
    position: (props: IUseInputStylesProps) => props.position,
    boxSizing: 'border-box',
    height: (props: IUseInputStylesProps) => props.height,
    borderRadius: 14,
    color: '#363636',
    fontSize: 15,
    backgroundColor: (props: IUseInputStylesProps) => props.color,
    padding: (props: IUseInputStylesProps) => props.padding || '28px 16px 10px 16px',
    minWidth: 140,
  },
  focused: {
    backgroundColor: (props: IUseInputStylesProps) => `${props.activeColor || '#EBF5EB'} !important`,
  },
  error: {
    backgroundColor: `${theme.pallete.table.red.light} !important`,
  },
});
