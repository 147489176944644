import { CONTRACT_TYPE } from 'components/DetailsCard/constants/Datails.constants';

const getRedirectState = (data: string) => {
  switch (data) {
    case 'PERSON':
      return CONTRACT_TYPE.COVID;
    case 'ELECTRONIC_DEVICE':
      return CONTRACT_TYPE.MOBI;
    case 'VEHICLE':
      return CONTRACT_TYPE.KASKO;
    case 'ESTATE':
      return CONTRACT_TYPE.JUTLO;
  }
};

export default getRedirectState;
