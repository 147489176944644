import React, { useState } from 'react';
import { Layout } from 'antd';
import SideMenu from './SideMenu';
import styles from './Layout.module.less';
import CreateModal from 'components/modals/CreateModal';

const { Content } = Layout;

interface ILayoutProps {
  children: React.ReactNode;
}

export const LayoutComponent = (props: ILayoutProps) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout className={styles.layout}>
      <SideMenu openModal={openModal} />
      <Layout className={styles.menuBackground}>
        <Content className={styles.contentContainer}>{props.children}</Content>
      </Layout>
      <CreateModal isOpen={isModalOpen} handleClose={closeModal} />
    </Layout>
  );
};
