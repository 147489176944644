import React, { ReactElement } from 'react';
import { Button as BaseButton, withStyles } from '@material-ui/core';
import styles from './styles';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { ButtonTypeMap } from '@material-ui/core/Button/Button';
import useStyles from './useStyles';
import cs from 'classnames';

export interface IButtonProps extends Omit<ExtendButtonBase<ButtonTypeMap>, 'disableElevation'> {
  classes?: any;
  className?: string;
  iconBackGround?: string;
  startIcon?: ReactElement;
  disabled?: boolean;
  intent?: 'none' | 'success' | 'danger' | 'warn' | 'black';
  disableRipple?: boolean;
  href?: string;
}

const intentClassNames = {
  none: 'intentNone',
  success: 'intentSuccess',
  danger: 'intentDanger',
  warn: 'intentWarn',
  black: 'intentBlack',
};

const Button: React.FC<IButtonProps> = ({
  startIcon,
  iconBackGround = '#EBF5EB',
  intent = 'none',
  className,
  disabled,
  disableRipple = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <BaseButton
      disabled={disabled}
      disableRipple={disableRipple}
      className={cs(className, classes[intentClassNames[intent]])}
      startIcon={
        startIcon
          ? React.cloneElement(startIcon, {
              viewBox: '0 0 20 20',
              width: 20,
              height: 20,
              iconbackcolor: iconBackGround,
            })
          : undefined
      }
      disableElevation={true}
      {...props}
    />
  );
};

//@ts-ignore
export default withStyles(styles)(Button);
