import React, { useState } from 'react';
import Timestamp from 'components/Timestamp/Timestamp';
import Input from '../../../../components/Table/components/Input/Input';
import { Dropdown } from 'antd';
import { MenuIcon } from '../../../../components/Icons';
import RowActions from './RowActions';
import moment from 'moment';

export const roles = {
  ROLE_PARTNER_SELLER: 'Продавець',
  ROLE_PARTNER_MANAGER: 'Менеджер ЦО Партнера',
  ROLE_PARTNER_ADMIN: 'Адміністратор ЦО Партнера',
  ROLE_INSURANCE_MANAGER: 'Менеджер СК',
  ROLE_INSURANCE_CLAIM_MANAGER: 'Менеджер СК по виплатам',
  ROLE_INSURANCE_ADMIN: 'Адміністратор СК',
  ROLE_CLIENT: 'Клієнт',
  ROLE_ADMIN: 'Адміністратор системи',
  ROLE_HR_MANAGER: 'HR менеджер',
};

export const reverseRoles = {
  Продавець: 'ROLE_PARTNER_SELLER',
  'Менеджер ЦО Партнера': 'ROLE_PARTNER_MANAGER',
  'Адміністратор ЦО Партнера': 'ROLE_PARTNER_ADMIN',
  'Менеджер СК': 'ROLE_INSURANCE_MANAGER',
  'Менеджер СК по виплатам': 'ROLE_INSURANCE_CLAIM_MANAGER',
  'Адміністратор СК': 'ROLE_INSURANCE_ADMIN',
  Клієнт: 'ROLE_CLIENT',
  'Адміністратор системи': 'ROLE_ADMIN',
  'HR менеджер': 'ROLE_HR_MANAGER',
};

const ActionDropdown = ({ id, record }: any) => {
  const [visible, setVisible] = useState(false);

  return (
    <div onClick={e => e.stopPropagation()}>
      <Dropdown
        visible={visible}
        onVisibleChange={v => setVisible(v)}
        overlay={() => (
          <RowActions
            onClick={() => setVisible(false)}
            editable={!record.ui.edit}
            id={id}
            data={record}
            onDelete={record.ui.onDelete}
            onEdit={record.ui.onEdit}
          />
        )}
        placement="bottomRight"
        trigger={['click']}
      >
        <span className="ant-dropdown-link">
          <MenuIcon />
        </span>
      </Dropdown>
    </div>
  );
};

const EditableField = (index: any) => (text: any, record: any) => {
  return record.ui.edit ? (
    <Input
      inputProps={{
        defaultValue: record[index],
        onBlur: (e: any) =>
          record.ui.onChange({
            ...record,
            [index]: e.target.value,
          }),
      }}
    />
  ) : (
    record[index]
  );
};

const columns = t => {
  return [
    {
      title: t('insurance.date_of_application'),
      value: 'applicationDate',
      key: 'applicationDate',
      dataIndex: 'applicationDate',
      render: (applicationDate: string) => <Timestamp timestamp={moment.utc(applicationDate).toDate()} />,
    },
    {
      title: t('insurance.role'),
      value: 'role',
      key: 'role',
      dataIndex: 'role',
      //@ts-ignore
      render: (role: string) => roles[role] || role,
    },
    {
      title: t('insurance.person_who_applied'),
      value: 'applicantName',
      key: 'applicantName',
      dataIndex: 'applicantName',
    },
    {
      title: t('insurance.details'),
      value: 'details',
      key: 'details',
      dataIndex: 'details',
      render: EditableField('details'),
    },
    {
      title: t('insurance.notes'),
      value: 'description',
      key: 'description',
      dataIndex: 'description',
      render: EditableField('description'),
    },
    {
      title: '',
      value: 'actions',
      key: 'actions',
      fixed: 'right' as 'right',
      width: 50,
      render: (id: any, record: any) => <ActionDropdown id={id} record={record} />,
    },
  ];
};

export default columns;
