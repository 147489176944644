import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import useLabelStyles from '../styles/useLabelStyles';
import useInputStyles from '../styles/useInputStyles';
import useHelperTextStyles from '../styles/useHelperTextStyles';

export interface ISelectProps extends Omit<TextFieldProps, 'color'> {
  color?: 'white' | 'gray';
  activeColor?: boolean;
  position?: 'absolute' | 'relative' | 'static' | 'sticky';
}

const colors = {
  white: '#FFF',
  gray: '#EEEFF4',
};

const Select: React.FC<ISelectProps> = React.forwardRef(
  (
    {
      color = 'gray',
      position = 'static',
      value,
      InputLabelProps = {},
      InputProps = {},
      FormHelperTextProps = {},
      activeColor,
      ...props
    },
    ref,
  ) => {
    const labelClasses = useLabelStyles();
    const helperTextClasses = useHelperTextStyles();
    const inputClasses = useInputStyles({
      color: colors[color],
      height: 56,
      activeColor: activeColor && colors.gray,
      position: position,
    });

    return (
      <TextField
        {...props}
        value={value || ''}
        variant={'standard'}
        ref={ref as any}
        InputLabelProps={{
          classes: labelClasses,
          ...InputLabelProps,
        }}
        InputProps={{
          classes: inputClasses,
          disableUnderline: true,
          ...InputProps,
        }}
        FormHelperTextProps={{
          classes: helperTextClasses,
          ...FormHelperTextProps,
        }}
      />
    );
  },
);

export default Select;
