import createInstance from './axios';
import { makerRequestParams } from 'utils/helpers';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { insuranceProgramsListType, InsuranceProgramPartnerType } from 'types/insuranceProgramsList';
import { AxiosResponse } from 'axios';

const programsAPI = createInstance('CONTRACTS');
const configApi = createInstance('CONFIGURATION');

export const getTablePage = ({ filters, sorting, pagination }: insuranceProgramsListType) => {
  const { dateFrom, dateTo, ...otherFilters } = filters;

  const requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    createdDate: `${moment(dateFrom).format('YYYY-MM-DD')}..${moment(dateTo).format('YYYY-MM-DD')}`,
    sorting: sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`,
    ...otherFilters,
  };
  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return configApi.get(
    'insurance-programs?attributes=id,code,name,createdDate,startDate,endDate,active,insuranceRules,insuranceTypeName,insuranceCompanyId&distinct&count',
    {
      params: requestParams,
    },
  );
};
export const getPrivateFiltersColumns = ({
  filterField,
  filterParams,
}: {
  filterField: string;
  filterParams: string;
}) => {
  const requestParams = {
    attributes: filterField,
    [filterField]: filterParams,
    sorting: filterField,
  };

  return programsAPI
    .get('/insurance-programs/?distinct', {
      params: requestParams,
    })
    .then(response => {
      return {
        data: response.data.resultList.map((item: any) => {
          return item[filterField].toString();
        }),
      };
    });
};

export const getPrivateFiltersTable = ({
  filters,
  pagination,
}: {
  filters: any;
  pagination: { current: number | string; perPage: number | string };
}) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  const { dateFrom, dateTo, ...otherFilters } = filters;

  return configApi.get(
    'insurance-programs?attributes=id,code,name,createdDate,startDate,endDate,active,insuranceRules,insuranceTypeName,insuranceCompanyId&distinct&count',
    {
      params: {
        ...otherFilters,
        page_size: pagination.perPage,
        page: pagination.current,
      },
    },
  );
};

export const getCompanies = (): Promise<any> => {
  return programsAPI.get('/companies?partnerId=null').then((response: any) => ({
    data: response.data.map((el: any) => ({ id: el.insuranceCompanyId, name: el.name })),
  }));
};

export const getInsurancePrograms = (
  params: any,
): Promise<AxiosResponse<{
  resultList: object[];
}>> => {
  return configApi.get('/insurance-programs', {
    params: {
      attributes: `id,name,insuranceCompanyId${params.partnerId ? ',partnerId' : ''}`,
      distinct: true,
      ...params,
    },
  });
};

export const getInsuranceProgramPartners = (
  id: number | string,
): Promise<AxiosResponse<InsuranceProgramPartnerType[]>> => {
  return programsAPI.get(`/insurance-programs/${id}/partner-agents`);
};

export const printUsersXLSXList = (params: any): Promise<any> => {
  Object.keys(params).forEach(key => {
    if (params[key] === null || !params[key]) {
      delete params[key];
    } else {
      params[key] = makerRequestParams(params[key]);
    }
  });
  const timeZone = momentTz.tz.guess(true);
  return programsAPI.get(`/insurance-programs/print/xlsx`, {
    responseType: 'arraybuffer',
    params: {
      ...params,
      timeZone,
    },
  });
};

export const getProgramInfo = (id: number): Promise<any> => programsAPI.get(`/insurance-programs/${id}`);
