import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';

import moment from 'moment';
import config from 'config';

import { contractListType } from 'types/contractList';
import { TYPES } from './actions';

const initialState: contractListType = {
  data: [],
  total: 0,
  pending: false,
  error: false,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'conclusionDate',
    direction: 'DESC',
  },
  filters: {
    dateFrom: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    phoneNumber: '',
    insuranceCompanyName: '',
    insuranceCompanyId: {
      insuranceCompanyId: null,
      partnerId: null,
      partnerDepartmentId: null,
      name: null,
    },
    insuranceProgramName: '',
    insuranceProgramId: {
      id: null,
      name: null,
    },
    subdivisionName: '',
    subdivisionId: { id: null, name: null, key: null },
  },
  privateFilterItems: {},
  companies: [],
  insuranceProgram: [],
  subdivisions: [],
  privateFilteredData: [],
  totalInfo: null,
};

const setTotalInformation = (state: contractListType, { payload }: AnyAction): contractListType => ({
  ...state,
  totalInfo: { ...payload },
});

const setFiltersDates = (state: contractListType, { payload }: AnyAction): contractListType => {
  return {
    ...state,
    filters: payload,
  };
};

const setTablePage = (state: contractListType, { payload }: AnyAction): contractListType => {
  return {
    ...state,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
  };
};

const setPending = (state: contractListType, { payload }: AnyAction): contractListType => {
  return {
    ...state,
    pending: payload ? payload.pending : initialState.pending,
  };
};

const setPagination = (state: contractListType, { payload }: AnyAction): contractListType => {
  return {
    ...state,
    pagination: payload,
  };
};

const setSorting = (state: contractListType, { payload }: any): contractListType => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const removePrivateFiltersField = (state: contractListType, { payload: fieldName }: any): contractListType => {
  const newPrivateFilterItems = state.privateFilterItems;

  if (newPrivateFilterItems) {
    delete newPrivateFilterItems[fieldName];
  }

  return {
    ...state,
    privateFilterItems: {
      ...newPrivateFilterItems,
    },
  };
};

const resetPrivateFiltersValues = (state: contractListType): contractListType => {
  return { ...state, privateFilterItems: {} };
};

const setPrivateFilterItems = (state: contractListType, { payload }: any) => {
  return {
    ...state,
    privateFilterItems: payload,
  };
};

const getPrivateFiltersData = (state: contractListType, { payload }: any): contractListType => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    data: resultList,
    total: count,
  };
};

const getPrivateFilteredData = (state: contractListType, { payload }: any): contractListType => {
  const { resultList }: { resultList: [] } = payload;
  return {
    ...state,
    privateFilteredData: resultList,
  };
};

const setCurrentCompanies = (state: contractListType, { payload }: any): contractListType => {
  return {
    ...state,
    filters: {
      ...state.filters,
      /* insuranceCompanyName:
        payload?.companies[0]?.name ||
        (state.companies && state.companies?.length && state.companies[0]?.name) ||
        state.filters?.insuranceCompanyName,*/
      // insuranceCompanyId:
      //   payload.companies[0] ||
      //   (state.companies && state.companies?.length && state.companies[0]) ||
      //   state.filters.insuranceCompanyId,
      // insuranceProgramId:
      //   payload?.insuranceProgram[0] || state?.insuranceProgram[0] || state?.filters?.insuranceProgramId,
      // insuranceProgramName:
      //   payload?.insuranceProgram[0]?.name || state?.insuranceProgram[0]?.name || state?.filters?.insuranceProgramName,
    },
    companies: payload.companies || state.companies,
    insuranceProgram: payload.insuranceProgram || state.insuranceProgram,
  };
};
const setPrograms = (state: contractListType, { payload }: any): contractListType => {
  return {
    ...state,
    insuranceProgram: payload.insuranceProgram || state.insuranceProgram,
  };
};

const setSubdivisions = (state: contractListType, { payload }: any): contractListType => {
  return {
    ...state,
    subdivisions: payload.subdivisions || state.subdivisions,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setFiltersDates,
    setTablePage,
    setPagination,
    setSorting,
    removePrivateFiltersField,
    resetPrivateFiltersValues,
    getPrivateFiltersData,
    getPrivateFilteredData,
    setCurrentCompanies,
    setTotalInformation,
    setPrograms,
    setPending,
    setSubdivisions,
    setPrivateFilterItems,
  },
});
