import { withStyles } from '@material-ui/core';
import BaseMenu from '@material-ui/core/Menu';

export const Menu = withStyles({
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px 0 12px 12px',
    width: 156,
    transform: 'translateX(-100%) !important',
  },
  list: {
    padding: 0,
  },
})(BaseMenu);
