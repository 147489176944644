import { AppState } from '../reducer';

export const actDetailsSelector = ({
  actDetails: { data, total, pending, pagination, actInfo, avaliableContracts, avaliablePagination, statistic },
}: AppState) => ({
  data,
  total,
  pending,
  pagination,
  actInfo,
  avaliableContracts,
  avaliablePagination,
  statistic,
});

export const actsListSelector = ({ actsList: { filters } }: AppState) => ({
  filters,
});
