import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import { AnyAction } from 'redux';
import { InsuranceImportState } from 'types/insuranceImport';
import moment from 'moment';
import config from 'config';

const initialState: InsuranceImportState = {
  loading: false,
  data: [],
  total: 0,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'operationDate',
    direction: 'DESC',
  },
  chosenPartner: {
    name: '',
    id: 0,
  },
  historySorting: {
    field: 'id',
    direction: 'DESC',
  },
  chosenProducts: {
    name: '',
    id: 0,
  },
  privateFilterItems: {},
  privateFilteredData: [],
  filters: {
    id: '',
    productId: 0,
    insuranceProgramId: 0,
    packageNumber: '',
    importType: '',
    importDate: '',
    entitiesToImportCount: '',
    importedCount: '',
    failedCount: '',
    initiator: '',
    partnerId: 0,
    dateFrom: moment().subtract(3, 'month'),
    dateTo: moment(),
  },
  integrationTypes: [],
  programs: [],
  products: [],
  partners: [],
};

const setProducts = (state: InsuranceImportState, action: AnyAction): InsuranceImportState => {
  return {
    ...state,
    products: action.payload,
  };
};

const setData = (state: InsuranceImportState, action: AnyAction): InsuranceImportState => {
  return {
    ...state,
    data: action.payload.data,
    total: action.payload.count,
  };
};

const setChosenPartner = (state: InsuranceImportState, action: AnyAction): InsuranceImportState => {
  return {
    ...state,
    chosenPartner: action.payload,
  };
};

const setChosenProducts = (state: InsuranceImportState, action: AnyAction): InsuranceImportState => {
  return {
    ...state,
    chosenProducts: action.payload,
  };
};

const setLoading = (state: InsuranceImportState, action: AnyAction): InsuranceImportState => {
  return { ...state, loading: action.payload };
};

const setPagination = (state: InsuranceImportState, action: AnyAction): InsuranceImportState => {
  return { ...state, pagination: action.payload };
};

const setTablePage = (state: InsuranceImportState, { payload, pending }: AnyAction): InsuranceImportState => {
  return {
    ...state,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
    loading: pending,
  };
};

const setFiltersDates = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  return {
    ...state,
    filters: payload,
  };
};

const setSorting = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const getPrivateFiltersValues = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  const { filterField, filterParams }: { filterField: string; filterParams: [] } = payload;
  return { ...state, privateFilterItems: { ...state.privateFilterItems, [filterField]: filterParams } };
};

const getPrivateFiltersData = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    data: resultList,
    total: count,
  };
};

const getPrivateFilteredData = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  const { resultList }: { resultList: [] } = payload;
  return {
    ...state,
    privateFilteredData: resultList,
  };
};

export const setIntegrationTypes = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  return {
    ...state,
    integrationTypes: payload,
  };
};

export const setPrograms = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  return {
    ...state,
    programs: payload,
  };
};

export const setPartners = (state: InsuranceImportState, { payload }: AnyAction): InsuranceImportState => {
  return {
    ...state,
    partners: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setData,
    setLoading,
    setPagination,
    setTablePage,
    setFiltersDates,
    setSorting,
    getPrivateFiltersValues,
    getPrivateFiltersData,
    getPrivateFilteredData,
    setIntegrationTypes,
    setPrograms,
    setPartners,
    setChosenPartner,
    setChosenProducts,
    setProducts,
  },
});
