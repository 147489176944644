import { CombinedState } from 'redux';
import { StoreType } from 'types/store';
import { createTypes } from 'redux-compose-reducer';
import { notification } from 'antd';
import moment from 'moment';

import { AppState } from 'store/reducer';

import * as api from 'api/registrationDevices';
import { getSaleRegisterStatusesData } from 'api/registrationDevices';
import { IRegistrationDevicesFilters } from 'types/registrationDevices';
import { modifySaleRegisterStatuses } from 'components/tables/RegistrationDevicesTable/helper';
import onDownload from 'callbacks/onDownload';

export const TYPES = createTypes('registrationDevices', [
  'setPending',
  'setSorting',
  'setTablePage',
  'setPagination',
  'getPrivateFiltersData',
  'getPrivateFilteredData',
  'getPrivateFiltersValues',
  'setFiltersDates',
  'setSaleRegisterStatuses',
]);

export const setPending = (pending: boolean) => ({ type: TYPES.setPending, pending });

export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

// Top filter
export const getFilteredRegistrationDevices = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    registrationDevices: { pagination, sorting, filters },
    contractDetails: {
      contract: { partnerId: contractPartnerId },
    },
    user: { authentication },
  }: AppState = getState();

  let insuranceCompanyId: string | number = '';

  if (!!contractPartnerId || !!authentication?.tenant) {
    insuranceCompanyId = contractPartnerId || authentication?.tenant || '';
  }

  try {
    const response = await api.getPrivateFiltersTable(
      {
        filters: {
          ...filters,
          ...filtersInfo,
          sorting:
            (!sorting.direction && '-incidentDate,-id') || sorting.direction === 'DESC'
              ? `-${sorting.field},-id`
              : `${sorting.field},id`,
        },
        pagination,
      },
      insuranceCompanyId,
    );
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.getPrivateFiltersData, payload });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getTablePage = () => async (dispatch: any, getState: any) => {
  const {
    registrationDevices: { pagination, sorting, filters },
    contractDetails: {
      contract: { partnerId: contractPartnerId },
    },
    user: { authentication },
  }: AppState = getState();

  let insuranceCompanyId: string | number = '';

  if (!!contractPartnerId || !!authentication?.tenant) {
    insuranceCompanyId = contractPartnerId || authentication?.tenant || '';
  }

  dispatch({ type: TYPES.setTablePage, pending: true });

  try {
    const response = await api.getTablePageData({ pagination, sorting, filters }, insuranceCompanyId);
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.setTablePage, payload, pending: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setTablePage, pending: false });
  }
};

export const getFilteredPrivateData = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    registrationDevices: { pagination, sorting, filters },
    contractDetails: {
      contract: { partnerId: contractPartnerId },
    },
    user: { authentication },
  }: AppState = getState();

  let insuranceCompanyId: string | number = '';

  if (!!contractPartnerId || !!authentication?.tenant) {
    insuranceCompanyId = contractPartnerId || authentication?.tenant || '';
  }

  try {
    const response = await api.getPrivateFiltersTable(
      {
        filters: {
          ...filters,
          ...filtersInfo,
          sorting:
            (!sorting.direction && '-incidentDate,-id') || sorting.direction === 'DESC'
              ? `-${sorting.field},-id`
              : `${sorting.field},id`,
        },
        pagination: {
          current: 1,
          perPage: pagination.perPage,
        },
      },
      insuranceCompanyId,
    );
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.getPrivateFilteredData, payload });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setSorting = ({ field, direction }: { field: string; direction: string }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};

export const setFiltersDates = (
  { dateTo, dateFrom, insuranceObjectPid, saleRegisterStatus, saleRegisterStatusName }: IRegistrationDevicesFilters,
  t: any,
) => (dispatch: any) => {
  saleRegisterStatusName = t('registration_devices.everyone');
  dispatch({
    type: TYPES.setFiltersDates,
    payload: {
      dateFrom,
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
      insuranceObjectPid,
      saleRegisterStatus,
      saleRegisterStatusName,
    },
  });
};

export const getSaleRegisterStatuses = () => async (dispatch: any) => {
  try {
    const request = await getSaleRegisterStatusesData();
    const data = modifySaleRegisterStatuses(request);

    dispatch({ type: TYPES.setSaleRegisterStatuses, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const activitiesXSLXDownload = t => async (dispatch: any, getState: () => CombinedState<StoreType>) => {
  const {
    registrationDevices: {
      filters: { dateFrom, dateTo, insuranceObjectPid, saleRegisterStatus },
      sorting,
    },
  }: AppState = getState();

  const data = {
    incidentDate: `${dateFrom}..${dateTo}`,
    timeZone: 'Europe/Kiev',
    sorting:
      (!sorting.direction && '-incidentDate,-id') || sorting.direction === 'DESC'
        ? `-${sorting.field},-id`
        : `${sorting.field},id`,
    insuranceObjectPid,
    saleRegisterStatus,
  };

  return onDownload(() => api.downloadActivitiesAsXLS(data), {}, t);
};
