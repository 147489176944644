import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { Form, Button, Typography } from 'antd';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { IFirstStep } from '../types';
import { RULES } from '../../ManagerSignUp/utils';

import * as sign from '../SignUp.module.less';
import { useTranslation } from 'react-i18next';

const FirstStep: FunctionComponent<IFirstStep> = ({ form: { getFieldDecorator }, fieldsActiveState, values }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title level={3}>
        {t('signup.registration_y')} <br /> {t('signup.registration_firstStep')}
      </Typography.Title>
      <Form.Item
        className={classNames(sign.signup_items, sign.phoneinputLabel, {
          [sign.phonelabelActive]: fieldsActiveState.phoneNumber,
        })}
      >
        {getFieldDecorator('phoneNumber', {
          rules: RULES(t).PHONE,
          initialValue: values.phoneNumber,
          validateFirst: true,
        })(<PhoneNumberInput title={t('signup.enter_phone_number')} />)}
      </Form.Item>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
          Далі
        </Button>
      </Form.Item>
    </>
  );
};

export default FirstStep;
