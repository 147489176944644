import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="54" height="54" rx="16" fill="#EEEFF4" />
    <path
      d="M22.612 17.7843C22.1793 17.3513 21.4774 17.3513 21.0448 17.7844L18.697 20.1351L16.3492 17.7844C15.9166 17.3513 15.2147 17.3513 14.782 17.7843C14.35 18.2167 14.3499 18.9173 14.7819 19.3498L17.1313 21.702L14.7819 24.0543C14.3499 24.4867 14.35 25.1874 14.782 25.6198C15.2147 26.0528 15.9166 26.0527 16.3492 25.6196L18.697 23.269L21.0448 25.6196C21.4774 26.0527 22.1793 26.0528 22.612 25.6198C23.044 25.1874 23.0441 24.4867 22.6121 24.0543L20.2627 21.702L22.6121 19.3498C23.0441 18.9173 23.044 18.2167 22.612 17.7843Z"
      fill="#A9B5BD"
    />
    <path
      d="M31.3875 25.6198C31.8202 26.0528 32.522 26.0527 32.9546 25.6196L35.3025 23.269L37.6503 25.6196C38.0829 26.0527 38.7848 26.0528 39.2174 25.6198C39.6495 25.1874 39.6495 24.4867 39.2176 24.0543L36.8681 21.702L39.2176 19.3498C39.6495 18.9173 39.6495 18.2167 39.2174 17.7843C38.7848 17.3513 38.0829 17.3513 37.6503 17.7844L35.3025 20.1351L32.9546 17.7844C32.522 17.3513 31.8202 17.3513 31.3875 17.7843C30.9555 18.2167 30.9554 18.9173 31.3874 19.3498L33.7368 21.702L31.3874 24.0543C30.9554 24.4867 30.9555 25.1874 31.3875 25.6198Z"
      fill="#A9B5BD"
    />
    <path
      d="M18.6884 35.6502C18.2563 36.0827 18.2563 36.7834 18.6884 37.2159C19.121 37.6489 19.8228 37.6489 20.2554 37.2159L20.5007 36.9704C24.3949 33.0832 30.6987 33.0832 34.5928 36.9704L34.8382 37.2159C35.2708 37.6489 35.9725 37.6489 36.4051 37.2159C36.8372 36.7834 36.8372 36.0827 36.4051 35.6502L36.1585 35.4034C31.3993 30.651 23.6942 30.651 18.9351 35.4034L18.6884 35.6502Z"
      fill="#A9B5BD"
    />
  </SvgIcon>
);
