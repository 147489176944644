import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Grid } from '@material-ui/core';

import { addSublimit, editSublimit } from 'store/sublimits/actions';

import FormInputText from 'components/FormInputText';
import Button from 'components/Button';

import { CardGrid, Cards, Text } from './styles';

interface ISublimitFormProps extends FormComponentProps {
  subsublimitData: any;
  isEdit: boolean;
}

const SublimitForm: React.FC<ISublimitFormProps> = ({ subsublimitData, isEdit, form }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addNewSublimit = data => dispatch(addSublimit(data));
  const editSublimitById = (id, data) => dispatch(editSublimit(id, data));

  const handleErrors = e => {
    if (e?.response?.data?.errors) {
      return e?.response?.data?.errors.forEach(error => {
        // TODO change code and message
        if (error.code === 'USER_INACTIVE') {
          return notification.error({
            message: t('sublimits.sublimit_added'),
          });
        }
      });
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          isEdit ? editSublimitById(subsublimitData.sublimitId, values) : addNewSublimit(values);

          form.resetFields();
          return notification.success({
            message: isEdit ? t('sublimits.sublimit_edited') : t('sublimits.sublimit_added'),
          });
        } catch (e) {
          handleErrors(e);
        }
      }
    });
  };

  return (
    <CardGrid mb={40}>
      <Form name="SubsublimitForm" onSubmit={onSubmit}>
        <Cards>
          <Text size={18} weight={600} bottomM={20}>
            {isEdit ? t('sublimits.edit_sublimit') : t('sublimits.add_sublimit')}
          </Text>
          <Grid xs="auto" container item spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={'auto'}>
              <Form.Item>
                {form?.getFieldDecorator('name', {
                  rules: [{ required: true, message: t('sublimits.need_enter_sublimit') }],
                  validateFirst: true,
                  initialValue: subsublimitData && isEdit ? subsublimitData.sublimitName : '',
                })(<FormInputText title={t('sublimits.sublimit_name')} />)}
              </Form.Item>
            </Grid>
          </Grid>
        </Cards>
        <Grid container spacing={2} alignItems={'center'} justify={'flex-end'}>
          <Grid item xs={'auto'}>
            <Button type="submit" color={'primary'} size={'large'}>
              {t('sublimits.save')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </CardGrid>
  );
};

export default Form.create<ISublimitFormProps>({ name: 'SublimitForm' })(SublimitForm);
