export const passwordRegExp = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{8,}$');
export const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const countdownDelay = 299000;
export const nameRegexp = /^[a-zA-Z\u0401\u0451\u0410-\u044fА-ЯҐЄЙІЇа-яіӣєїґ]([-'`](?![-'`+])|[a-zA-Z\u0401\u0451\u0410-\u044fА-ЯҐЄЙІЇа-яіӣєїґ]){0,50}$/;
export const allowNumbers = /^\d+$/gim;

export const passwordHint = 'validation.password_must_east_8_characters_long';

//form rules
export const RULES = t => {
  return {
    LAST_NAME: [
      { required: true, message: t('validation.enter_last_name') },
      { min: 1, message: t('validation.minimum_1_character') },
      { max: 50, message: t('validation.maximum_50_characters') },
    ],
    FIRST_NAME: [
      { required: true, message: t('validation.enter_name') },
      { min: 1, message: t('validation.minimum_1_character') },
      { max: 50, message: t('validation.maximum_50_characters') },
    ],
    MIDDLE_NAME: [{ max: 50, message: t('validation.maximum_50_characters') }],
    DEPARTMENT: [
      { required: true, message: t('validation.enter_subsection') },
      { min: 3, message: t('validation.start_your_search_with_3_digits') },
    ],
    PASSWORD: [
      { required: true, message: t('validation.enter_password') },
      { required: true, pattern: passwordRegExp, message: t('validation.password_not_requirements') },
    ],
    CONFIRM_PASSWORD: [{ required: true, message: t('validation.enter_password_again') }],
    PHONE: [
      { required: true, message: t('validation.enter_phone_number') },
      { min: 9, max: 9, message: t('validation.enter_phone_number_correctly') },
    ],
    EDRPOU: [
      { required: true, message: t('validation.enter_USREOU') },
      { min: 8, message: t('validation.minimum_8_characters') },
      { max: 8, message: t('validation.maximum_8_characters') },
    ],
    EMAIL: [
      { required: true, message: t('validation.enter_email') },
      { pattern: emailRegex, message: t('validation.enter_correctly_email') },
    ],
    TOKEN: [{ required: true, message: t('validation.enter_code_from_SMS') }],
  };
};

//async errors
export const asyncErr = (t?: any) => {
  if (!t) return;

  return {
    confirmPassword: {
      no_match: t('validation.password_not_match'),
    },
    phoneNumber: {
      REQUIRED: t('validation.enter_correctly_phone_number'),
      INVALID_LENGTH: t('validation.enter_correctly_phone_number'),
      INVALID_PARAMETER_SIZE: t('validation.enter_correctly_phone_number'),
      INVALID_PHONE_NUMBER_FORMAT: t('validation.enter_correctly_phone_number'),
    },
    ACCOUNT_ALREADY_EXISTS: t('validation.such_user_already_system'),
    VERIFICATION_TOKEN_MISSING_OR_EXPIRED: {
      message: t('validation.code_invalid'),
      field: 'token',
    },
    INVALID_VERIFICATION_TOKEN: {
      message: t('validation.code_invalid'),
      field: 'token',
    },
    PARTNER_NOT_FOUND: {
      field: 'partnerCode',
      message: t('validation.USREOU_not_registered'),
    },
    INVALID_PHONE_NUMBER: {
      message: t('validation.enter_correctly_phone_number'),
      field: 'phoneNumber',
    },
    EMAIL_ALREADY_REGISTERED: {
      message: t('validation.email_is_already_system'),
    },
    EMAIL_ALREADY_REGISTERED_FOR_ANOTHER_USER: {
      message: t('validation.email_already_another_user'),
    },
    EMAIL_ALREADY_REGISTERED_FOR_ANOTHER_COUNTERPARTY: {
      message: t('validation.email_already_another_user'),
    },
  };
};
