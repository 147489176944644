import moment from 'moment';
import { PACKAGE_NUMBER_FORMAT_VU } from 'screens/InsuranceContracts/List/components/constants';

const columns = t => {
  return [
    {
      title: t('insurance_contracts.insurance_product_name_table'),
      value: 'insuranceProductName',
      key: 's1',
      dataIndex: 'insuranceProductName',
      width: 70,
    },
    {
      title: t('insurance_contracts.insurance_program_name_table'),
      value: 'insuranceProgramName',
      key: 's2',
      dataIndex: 'insuranceProgramName',
      width: 110,
    },
    {
      title: t('insurance_contracts.package_number_table'),
      value: 'packageNumber',
      key: 's3',
      dataIndex: 'packageNumber',
      width: 60,
      render: number => (PACKAGE_NUMBER_FORMAT_VU + number).slice(-10),
    },
    {
      title: t('insurance_contracts.type_table'),
      value: 'transferType',
      key: 's4',
      dataIndex: 'transferType',
      width: 50,
    },
    {
      title: t('insurance_contracts.operation_date_table'),
      value: 'operationDate',
      key: 's5',
      dataIndex: 'operationDate',
      width: 70,
      render: date => moment(date).format('MM-DD-YYYY'),
    },
    {
      title: t('insurance_contracts.entities_to_transfer_count_table'),
      value: 'entitiesToTransferCount',
      key: 's6',
      dataIndex: 'entitiesToTransferCount',
      width: 30,
    },
    {
      title: t('insurance_contracts.success_count_table'),
      value: 'successCount',
      key: 's7',
      dataIndex: 'successCount',
      width: 90,
    },
    {
      title: t('insurance_contracts.failed_count_table'),
      value: 'failedCount',
      key: 's8',
      dataIndex: 'failedCount',
      width: 100,
    },
    {
      title: t('insurance_contracts.initiator_table'),
      value: 'initiator',
      key: 's9',
      dataIndex: 'initiator',
      width: 120,
    },
    {
      title: t('insurance_contracts.transfer_type_table'),
      value: 'type',
      key: 's10',
      dataIndex: 'type',
      width: 30,
    },
  ];
};

export default columns;
