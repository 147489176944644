import React from 'react';
import { PersonalIcon } from 'components/Icons';

const IncidentNotification = () => {
  return [
    {
      title: '',
      dataIndex: 'claimSource',
      key: 'claimSource',
      fixed: 'left',
      render: () => (
        <div style={{ textAlign: 'center' }}>
          <PersonalIcon />
        </div>
      ),
      width: 70,
      value: 'claimSource',
    },
    {
      title: 'insured_events_list.claim_number_table',
      dataIndex: 'claimNumber',
      key: 'claimId',
      width: 250,
      value: 'claimNumber',
    },
    {
      title: 'insured_events_list.claim_date_table',
      dataIndex: 'claimDate',
      key: 'claimDate',
      width: 200,
      value: 'claimDate',
    },
    {
      title: 'insured_events_list.incident_date_table',
      dataIndex: 'incidentDate',
      key: 'incidentDate',
      width: 200,
      value: 'incidentDate',
    },
    {
      title: 'insured_events_list.signatory_table',
      dataIndex: 'signatory',
      key: 'signatory',
      width: 200,
      value: 'signatory',
    },
    {
      title: 'insured_events_list.phone_table',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
      value: 'phone',
    },
    {
      title: 'insured_events_list.insurance_object_table',
      dataIndex: 'insuranceObject',
      key: 'insuranceObject',
      width: 150,
      value: 'insuranceObject',
    },
    {
      title: 'insured_events_list.insurance_risk_table',
      dataIndex: 'risk',
      key: 'risk',
      width: 200,
      value: 'risk',
    },
    {
      title: 'insured_events_list.damage_table',
      dataIndex: 'damage',
      key: 'damage',
      width: 200,
      value: 'damage',
      ellipsis: true,
    },
    {
      title: 'insured_events_list.recommendations_table',
      dataIndex: 'recommendations',
      key: 'recommendations',
      width: 200,
      value: 'recommendations',
    },
    {
      title: '',
      fixed: 'right',
      key: 'action12',
      value: 'actions',
    },
  ];
};

export default IncidentNotification;
