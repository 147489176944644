import { createTypes } from 'redux-compose-reducer';
import { Modals } from 'types/ui';

export const TYPES = createTypes('ui', ['toggleModal']);

export const toggleModal = ({ name, isOpen, data }: { name: keyof Modals; isOpen: boolean; data: any }) => (
  dispatch: any,
) => {
  dispatch({ type: TYPES.toggleModal, payload: { name: name, isOpen: isOpen, data } });
};
