import isEmail from 'is-email';
import * as structs from 'superstruct';
import { Dayjs } from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import i18n from 'translations/i18n';

export * from 'superstruct';

export const message = <T>(struct: structs.Struct<T, any>, message: string): structs.Struct<T, any> =>
  structs.define('message', value => (structs.is(value, struct) ? true : message));

export function string(t_callback?: () => string): structs.Struct<string, null> {
  return structs.define('string', value => {
    return typeof value === 'string' || (t_callback?.() ?? i18n.t('schema.required') ?? 'required');
  });
}

export function number(): structs.Struct<number, null> {
  return structs.define('number', value => {
    return (typeof value === 'number' && !isNaN(value)) || (i18n.t('schema.required') ?? 'required');
  });
}

export function date(): structs.Struct<Dayjs> {
  return customObject<Dayjs>();
}

export function customObject<T>(): structs.Struct<T> {
  return record(string(), structs.any()) as structs.Struct<T>;
}

function isObject(x: unknown): x is object {
  return typeof x === 'object' && x != null;
}

export function record<K extends string, V>(
  Key: structs.Struct<K>,
  Value: structs.Struct<V>,
): structs.Struct<Record<K, V>, null> {
  return new structs.Struct({
    type: 'record',
    schema: null,
    *entries(value) {
      if (isObject(value)) {
        for (const k in value) {
          const v = value[k];
          yield [k, k, Key];
          yield [k, v, Value];
        }
      }
    },
    validator(value) {
      return isObject(value) || (i18n.t('schema.required') ?? 'required');
    },
  });
}

export function enums<T extends number>(values: readonly T[]): structs.Struct<T, { [K in T[][number]]: K }>;
export function enums<T extends string>(values: readonly T[]): structs.Struct<T, { [K in T[][number]]: K }>;
export function enums<T extends number | string>(values: readonly T[]): any {
  const schema: any = {};

  for (const key of values) {
    schema[key] = key;
  }

  return new structs.Struct({
    type: 'enums',
    schema,
    validator(value) {
      return values.includes(value as any) || (i18n.t('schema.required') ?? 'required');
    },
  });
}

export function pattern<T extends string, S extends any>(
  struct: structs.Struct<T, S>,
  regexp: RegExp,
  t_callback?: () => string,
): structs.Struct<T, S> {
  return structs.refine(struct, 'pattern', value => {
    return regexp.test(value) || (t_callback?.() ?? 'invalid pattern');
  });
}

export function size<T extends string | number | Date | any[] | Map<any, any> | Set<any>, S extends any>(
  struct: structs.Struct<T, S>,
  min: number,
  max: number = min,
): structs.Struct<T, S> {
  return structs.refine(struct, 'size', value => {
    const message =
      min === max
        ? i18n.t('schema.size', { min }) || 'invalid min border of size'
        : i18n.t('schema.sizeRange', { min, max }) || 'invalid borders of size';

    if (typeof value === 'number' || value instanceof Date) {
      return (min <= value && value <= max) || message;
    } else if (value instanceof Map || value instanceof Set) {
      const { size } = value;
      return (min <= size && size <= max) || message;
    } else {
      const { length } = value as string | any[];
      return (min <= length && length <= max) || message;
    }
  });
}

export const nonempty = <T extends string | object | unknown[], S extends unknown>(
  struct: structs.Struct<T, S>,
  t_callback?: () => string,
) =>
  structs.refine<T, S>(struct, 'nonempty', value =>
    (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && isEmpty(value)) || value === ''
      ? t_callback?.() ?? i18n.t('schema.required') ?? 'required'
      : true,
  );

export const email = (): structs.Struct<string, null> =>
  structs.define(
    'email',
    value => (typeof value === 'string' && isEmail(value)) || (i18n.t('schema.email') ?? 'email'),
  );

export const refiner = <T, S>(struct: structs.Struct<T, S>, refiner: structs.Refiner<T>) =>
  structs.refine(struct, 'refiner', refiner);

export const integer = () =>
  structs.refine(
    number(),
    'integer',
    value => Number.isInteger(value) || (i18n.t('schema.numberInteger') ?? 'integer'),
  );

export const positive = <T extends number, S extends unknown>(struct: structs.Struct<T, S>) =>
  structs.refine<T, S>(struct, 'positive', value => value > 0 || (i18n.t('schema.numberPositive') ?? 'positive'));
