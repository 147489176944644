import React, { useEffect } from 'react';
import classNames from 'classnames';
import { DatePicker, Icon, Button, ConfigProvider } from 'antd';
import moment from 'moment';
import { Form, Spin } from 'antd';
import { getDatePickerLng } from 'utils/helpers';
import styles from './styles.module.less';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store/reducer';

interface IProps {
  exportRequests: any;
  form: any;
  countStatus: string;
  mode: 'EXPORT' | 'IMPORT';
}

export const DurationInput: React.FC<IProps> = ({ form, exportRequests, countStatus, mode }) => {
  const { getFieldDecorator, setFieldsValue } = form;
  const { t } = useTranslation();
  const currentLanguage = useSelector((store: AppState) => store.userCard.language);
  useEffect(() => {
    setFieldsValue({
      startSignDate: moment().subtract(3, 'months'),
      endSignDate: moment(),
    });
  }, []);

  const disableActDates = (current: any) => {
    return current > moment();
  };

  return (
    <div className={styles.DurationContent}>
      <div className={styles.dateBlock}>
        <span className={styles.title}>{t('insurance_contracts.signing_contract_with_export')}</span>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <Form.Item>
            {getFieldDecorator('startSignDate', {
              initialValue: moment().subtract(3, 'months'),
            })(
              <ConfigProvider locale={getDatePickerLng(currentLanguage)}>
                <DatePicker
                  allowClear={false}
                  showTime={false}
                  disabledDate={disableActDates}
                  getCalendarContainer={(trigger: any) => trigger.parentNode}
                  format={'DD.MM.YYYY'}
                  className={styles.dateField}
                  onChange={date => {
                    form.setFieldsValue({
                      startSignDate: date,
                    });
                  }}
                />
                ,
              </ConfigProvider>,
            )}
          </Form.Item>
        </div>
      </div>
      <div className={styles.dateBlock}>
        <span className={styles.title}>{t('insurance_contracts.signing_the_contract_on_export')}</span>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <Form.Item>
            {getFieldDecorator('endSignDate', {
              initialValue: moment(),
            })(
              <ConfigProvider locale={getDatePickerLng(currentLanguage)}>
                <DatePicker
                  allowClear={false}
                  showTime={false}
                  disabledDate={disableActDates}
                  getCalendarContainer={(trigger: any) => trigger.parentNode}
                  format={'DD.MM.YYYY'}
                  className={styles.dateField}
                  onChange={date => {
                    form.setFieldsValue({
                      endSignDate: date,
                    });
                  }}
                />
              </ConfigProvider>,
            )}
          </Form.Item>
        </div>
      </div>
      <div className={styles.button}>
        <Button className={styles.chooseBtn} onClick={e => exportRequests(e, `GET_ALLOWED_${mode}`)}>
          {countStatus === 'pending' ? (
            <Spin indicator={<Icon className={styles.spin} type="loading" spin />} />
          ) : (
            t('insurance_contracts.choose_button_export')
          )}
        </Button>
      </div>
    </div>
  );
};
