import { notification } from 'antd';
import download, { IDownloadOptions } from '../utils/download';

export interface IDownloadCallbackResult {
  data: Blob;
  headers: any;
}

export type DownloadCallback = () => Promise<IDownloadCallbackResult> | IDownloadCallbackResult;

export default async (cb: DownloadCallback, options?: IDownloadOptions, t?: any) => {
  // debuggeractsXSLXDownload
  notification.info({
    message: t('popup.download_will_start'),
  });

  try {
    const response = await cb();
    const { data, headers } = response;
    download(data, headers, options);
    return response;
  } catch (e) {
    console.error('Error excel downloading', e);
    notification.error({
      message: t('popup.upload_error_excel'),
      description: t('popup.try_again_later'),
    });

    throw e;
  }
};
