import React, { useReducer } from 'react';
import { Row, Col, Typography, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { SelectProps } from 'antd/lib/select';
import { PickerSharedProps } from 'rc-picker/lib/Picker';
import { PickerPanelSharedProps } from 'rc-picker/lib/PickerPanel';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import Select, { LabeledValue } from 'components/next/Select/Select';
import Breadcrumbs from 'components/next/Breadcrumbs/Breadcrumbs';
import { MonthPicker } from 'components/next/DatePicker/DatePicker';
import * as api from 'api/statistics';
import {
  DaysStatisticsDto,
  InsuranceProgramStatisticsDto,
  PartnerDepartmentStatisticsDto,
  PartnerStatisticsDto,
  PartnerView,
  ProductRiskStatisticsDto,
} from 'types/dto/contracts-service';
import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { ErrorsDto, LocationState } from 'types';
import Menu, { MenuItem } from 'components/next/Menu/Menu';

import Info, { Item } from './Info/Info';
import ByMonth from './ByMonth/ByMonth';
import ByProgram from './ByProgram/ByProgram';
import ByDepartment from './ByDepartment/ByDepartment';
import ByPartner from './ByPartner/ByPartner';
import ByRisk from './ByRisk/ByRisk';

const currencyMap: Record<string, string> = {
  UAH: 'грн',
};

interface State {
  companies: PartnerView[];
  hasLoadedCompanies: boolean;
  companyId: number;
  period: Dayjs;
  loading: boolean;
  infoItems: Item[];
  daysStatistics: DaysStatisticsDto[];
  programStatistics: InsuranceProgramStatisticsDto[];
  departmentStatistics: PartnerDepartmentStatisticsDto[];
  partnerStatistics: PartnerStatisticsDto[];
  risksStatistics: ProductRiskStatisticsDto[];
  currencyCode: string;
}

const DashboardExtend = () => {
  const { t } = useTranslation();
  const location = useLocation<LocationState>();
  const { insuranceCompanyId, partnerId } = useSelector((store: AppState) => ({
    insuranceCompanyId: store.userCard.data.insuranceCompany?.id,
    partnerId: store.userCard.data.partner?.id,
  }));
  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      companies: [],
      hasLoadedCompanies: false,
      // 0 has special role for showing "ALL"
      companyId: 0,
      period: dayjs(),
      loading: false,
      infoItems: [
        { label: t('dashboard_extend.sold'), paymentAmount: 0 },
        { label: t('dashboard_extend.terminated'), paymentAmount: 0 },
        { label: t('dashboard_extend.commission_fee'), paymentAmount: 0 },
        { label: t('dashboard_extend.paid'), paymentAmount: 0 },
      ],
      daysStatistics: [],
      programStatistics: [],
      departmentStatistics: [],
      partnerStatistics: [],
      risksStatistics: [],
      currencyCode: '',
    },
  );

  useAsyncEffect(async () => {
    try {
      setState({ loading: true });

      if (!state.hasLoadedCompanies) {
        const res = await api.getCurrentCompanies();

        setState({
          hasLoadedCompanies: true,
          companies: res.data.resultList ?? [],
        });
      }

      const company = state.companies.find(c => c.id === state.companyId);

      const res = await api.getDashboardInfoUsingGet({
        startDate: state.period
          .utc()
          .startOf('month')
          .format(),
        endDate: state.period
          .utc()
          .endOf('month')
          .format(),
        insuranceCompanyId: insuranceCompanyId ? insuranceCompanyId : company?.insuranceCompanyId,
        partnerId: partnerId ? partnerId : company?.partnerId,
      });

      setState({
        loading: false,
        infoItems: [
          {
            label: t('dashboard_extend.sold'),
            total: res.data.topDashboardInfoDto?.countContracts,
            paymentAmount: res.data.topDashboardInfoDto?.contractsPaymentsSum ?? 0,
          },
          {
            label: t('dashboard_extend.terminated'),
            total: res.data.topDashboardInfoDto?.countTerminations,
            paymentAmount: res.data.topDashboardInfoDto?.terminationsPaymentsSum ?? 0,
          },
          {
            label: t('dashboard_extend.commission_fee'),
            paymentAmount: res.data.topDashboardInfoDto?.agentsPaymentsSum ?? 0,
          },
          {
            label: t('dashboard_extend.paid'),
            total: res.data.topDashboardInfoDto?.countClaims,
            paymentAmount: res.data.topDashboardInfoDto?.claimsPaymentsSum ?? 0,
          },
        ],
        daysStatistics: res.data.daysStatistics ?? [],
        programStatistics: res.data.programStatistics,
        departmentStatistics: res.data.departmentStatistics,
        partnerStatistics: res.data.partnerStatistics,
        risksStatistics: res.data.risksStatistics,
        currencyCode: res.data.currencyCode ?? '',
      });
    } catch (error) {
      setState({ loading: false });

      if (error?.response.data.message) {
        notification.error({ message: error?.response.data.message });
      } else {
        (error?.response?.data as ErrorsDto).errors.forEach(err => {
          notification.error({ message: err.code });
        });
      }
    }
  }, [state.companyId, state.period]);

  const menu: MenuItem[] = [
    {
      id: ROUTES.DASHBOARD.EXTEND,
      label: 'Dashboard-1',
      children: <Link to={ROUTES.DASHBOARD.EXTEND}>Dashboard-1</Link>,
    },
    {
      id: ROUTES.DASHBOARD.MAIN,
      label: 'Dashboard-2',
      children: <Link to={ROUTES.DASHBOARD.MAIN}>Dashboard-2</Link>,
    },
  ];

  const onChangeCompany: SelectProps['onChange'] = value => {
    setState({ companyId: Number(value) });
  };

  const onChangeDate: PickerSharedProps<Dayjs>['onChange'] = value => {
    if (!value) {
      return;
    }

    setState({ period: value });
  };

  const disabledDate: PickerPanelSharedProps<Dayjs>['disabledDate'] = date => {
    return !(dayjs(date).isAfter('2018-01-01', 'year') && dayjs(date).isBefore(dayjs().endOf('month')));
  };

  const companyOptions: LabeledValue[] = ([0, 1].includes(state.companies.length)
    ? state.companies
    : [{ id: 0, name: t('dashboard_extend.ALL') }, ...state.companies]
  ).map(el => ({
    key: el.id ?? 0,
    value: el.id ?? 0,
    label: el.name,
  }));

  const currency = currencyMap[state.currencyCode] ?? state.currencyCode;

  return (
    <>
      <Breadcrumbs
        data={[
          { label: t('dashboard_extend.home') },
          {
            label: menu.find(el => el.id === location.pathname)?.label ?? 'Unknown',
            overlay: Menu({ menu: menu.filter(t => t.id !== location.pathname) }),
          },
        ]}
      />
      <Typography.Title level={3}>{t('dashboard_extend.home')}</Typography.Title>

      <Row type="flex" align="middle" gutter={[16, 16]}>
        <Col>
          <Typography.Text>{t('dashboard_extend.data_for_the_period')} </Typography.Text>
        </Col>

        <Col>
          <MonthPicker
            allowClear={false}
            defaultValue={state.period}
            format={'MMMM YYYY'}
            disabledDate={disabledDate}
            onChange={onChangeDate}
            margin="none"
          />
        </Col>
      </Row>

      <Row type="flex" gutter={[16, 16]}>
        <Col span={6}>
          <Select
            value={[0, 1].includes(state.companies.length) ? state.companies[0]?.id : state.companyId}
            options={companyOptions}
            label={t('dashboard_extend.company')}
            margin="none"
            onChange={onChangeCompany}
            disabled={state.companies.length === 1}
            loading={!state.hasLoadedCompanies}
            colorBase="white"
            showSearch
          />
        </Col>
        <Col span={18}>
          <Spin spinning={state.loading}>
            <Info items={state.infoItems} currency={currency} />
          </Spin>
        </Col>
      </Row>

      <Row type="flex" gutter={[16, 16]}>
        <Col md={12} span={24}>
          <Spin spinning={state.loading} size="large">
            <ByMonth data={state.daysStatistics} currency={currency} />
          </Spin>
        </Col>

        <Col md={12} span={24}>
          <Spin spinning={state.loading} size="large">
            <ByProgram data={state.programStatistics} currency={currency} />
          </Spin>
        </Col>
      </Row>

      <Row type="flex" gutter={[16, 16]}>
        <Col md={12} span={24}>
          {state.companies.length === 1 ? (
            <Spin spinning={state.loading} size="large">
              <ByDepartment data={state.departmentStatistics} currency={currency} />
            </Spin>
          ) : state.companyId === 0 ? (
            <Spin spinning={state.loading} size="large">
              <ByPartner data={state.partnerStatistics} currency={currency} />
            </Spin>
          ) : (
            <Spin spinning={state.loading} size="large">
              <ByDepartment data={state.departmentStatistics} currency={currency} />
            </Spin>
          )}
        </Col>

        <Col md={12} span={24}>
          <Spin spinning={state.loading} size="large">
            <ByRisk data={state.risksStatistics} currency={currency} />
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default DashboardExtend;
