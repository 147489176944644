import React, { ChangeEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import Input from 'components/Input';
import { PinFileIcon, SendMsgIcon } from 'components/Icons';
import { useTranslation } from 'react-i18next';
import styles from './MessageInput.module.less';

interface MessageInputProps {
  onSend(message: string | File): void;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSend }) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState('');

  const [isAnyFileExceedMaxSize, setIsAnyFileExceedMaxSize] = useState(false);

  // Max file size accepted by PubNub is 5 Mb
  // https://www.pubnub.com/docs/resources/limits#files
  const MAX_FILE_SIZE = 5000000;

  const MAX_LENGTH = 5000;

  useEffect(() => {
    const handleChange = e => {
      if (e.target.files) {
        const filesArr = [...e.target.files];
        const appropriateFiles = filesArr.filter(file => file.size <= MAX_FILE_SIZE);
        if (appropriateFiles.length !== filesArr.length) {
          setIsAnyFileExceedMaxSize(true);
        }
        appropriateFiles.forEach(onSend);
      }
      e.target.value = '';
    };
    ref.current?.addEventListener('change', handleChange);
    return () => {
      ref.current?.removeEventListener('change', handleChange);
    };
  }, [ref]);

  const handleAddFile = () => {
    if (ref.current) {
      setIsAnyFileExceedMaxSize(false);
      ref.current.click();
    }
  };

  const handleSend = () => {
    if (message) {
      onSend(message);
      setMessage('');
    }
  };

  const handleMessageChange: ChangeEventHandler<HTMLInputElement> = e => {
    setMessage(e.target.value);
  };

  const handleEnterDown: KeyboardEventHandler = e => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const handleFocus = () => {
    setIsAnyFileExceedMaxSize(false);
  };

  return (
    <>
      <input multiple accept="image/*" type="file" className={styles.inputFile} ref={ref} />
      {isAnyFileExceedMaxSize && <p className={styles.warning}>{t('chats.maxfilesize')}</p>}
      <div className={styles.inputOuter}>
        <PinFileIcon className={styles.iconFile} onClick={handleAddFile} />
        <Input
          fullWidth
          placeholder={t('chats.input_placeholder')}
          value={message}
          onChange={handleMessageChange}
          onKeyDown={handleEnterDown}
          onFocus={handleFocus}
          inputProps={{
            maxLength: MAX_LENGTH,
          }}
        />
        <SendMsgIcon className={styles.iconSend} onClick={handleSend} />
      </div>
    </>
  );
};

export default MessageInput;
