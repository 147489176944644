import React from 'react';
import { css, cx } from '@emotion/css';

import { theme } from 'styles';

const cssRoot = css`
  background-color: ${theme.color.basic.white};
  border-radius: 14px;
  padding: 8px;
`;

interface Props {
  children: React.ReactNode;
  className?: string;
}

const CardContainer = ({ children, className }: Props) => {
  return <div className={cx(cssRoot, className)}>{children}</div>;
};

export default CardContainer;
