import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash/fp';

import Modal from '../../components/Modal';
import Previewer from '../../components/Previewer';
import ItemsList, { builders, selectors } from '../../components/List';
import { useModal } from 'screens/Appointments/hooks/useModal';
import { IDoctor } from 'types/institutions';
import { Picker, WithVariants, WithErrors } from 'types/appointments';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';
import { defaultValues } from 'screens/Appointments/hooks/useForm';

export const DoctorPicker: React.FC<Picker<IDoctor> & WithErrors & WithVariants<IDoctor>> = ({
  variants,
  error,
  defaultValue,
  onClose,
  disabled,
  clearError,
  institutionId,
  serviceId,
  fetchDrEleksSlots,
}) => {
  const { t } = useTranslation();

  const { isOpen, open, close, confirm, picked, onPick } = useModal(onClose, defaultValue);

  const handleDoctorClick = doctor => {
    onPick(picked?.userRef === doctor.userRef ? defaultValues.doctor : doctor);
  };

  const onSubmit = () => {
    clearError('doctor');
    if (picked && institutionId && serviceId) {
      fetchDrEleksSlots &&
        fetchDrEleksSlots({
          institutionId: institutionId,
          serviceId: serviceId,
          doctorLogin: picked.userRef,
        });
    }
    confirm();
  };

  return (
    <>
      <Previewer
        displayValue={defaultValue.userName}
        onClick={open}
        title={defaultValue.userName || error ? t('appointments.doctor') : t('appointments.selector_doctor')}
        disabled={disabled}
        errorMessage={error ? t('appointments.selector_doctor') : ''}
      />
      {isOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              onPick(defaultValue);
              close();
            }}
          >
            <Modal.Header
              title={t('appointments.doctor_list')}
              onClose={() => {
                onPick(defaultValue);
                close();
              }}
            />
            <Modal.Body>
              <Scrollbars autoHeight autoHeightMax={MAX_SCROLLBAR_HEIGHT}>
                <ItemsList
                  items={variants}
                  itemBuilder={builders.byUserName}
                  selector={selectors.byUserRef(picked?.userRef)}
                  onItemClick={handleDoctorClick}
                />
              </Scrollbars>
            </Modal.Body>
            <Modal.Confirm disabled={isEqual(defaultValue, picked)} onClick={onSubmit} />
          </OutsideClickHandler>
        </Modal>
      )}
    </>
  );
};
