import React from 'react';
import styles from './InputTooltip.module.less';
import { Icon, Tooltip } from 'antd';

const InputTooltip = ({ children }) => {
  return (
    <Tooltip overlayClassName="tooltip_count" placement="bottomRight" title={<span>{children}</span>}>
      <Icon type="question-circle" className={styles.tooltipIcon} theme="filled" />
    </Tooltip>
  );
};

export default InputTooltip;
