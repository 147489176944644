//@ts-nocheck
import moment from 'moment';

export const changeDocumentReceiptDate = (arr, code) =>
  arr.map(document => {
    if (document.documentType.code === code) {
      return {
        ...document,
        receiptDate: moment().toISOString(),
      };
    }
    return document;
  });
