import createInstance from './axios';
import { useState, useEffect } from 'react';
import PubNub from 'pubnub';
import { useAsync } from 'hooks/useAsync';
import { usePubNub } from './pubNub';
import localForage from 'localforage';
import { Chat, Message, RawMessage } from 'types/chats';

const appointmentApi = createInstance('APPOINTMENT');

const getChats = async () => {
  const { data } = await appointmentApi.get('/chat/chats');
  return data;
};

const getChatHistory = (pubNub: PubNub) => async (chatId: string): Promise<Message[]> => {
  const cachedHistory = await localForage.getItem<Message[]>(chatId);

  if (cachedHistory) {
    return cachedHistory ?? [];
  }

  const history: Message[] = [];

  let endTimetoken: number;

  do {
    const olderMessages: RawMessage[] = await new Promise(resolve => {
      pubNub.fetchMessages(
        {
          channels: [chatId],
          count: 100,
          end: endTimetoken,
        },
        (err, result) => resolve(result.channels[chatId] ?? []),
      );
    });
    history.unshift(
      ...olderMessages.map(
        ({ timetoken, uuid, message }) =>
          ({
            timetoken,
            type: typeof message === 'string' ? 'plaintext' : 'file',
            publisher: uuid,
            ...(typeof message === 'string'
              ? {
                  body: message,
                }
              : {
                  title: message.file.name,
                  url: pubNub.getFileUrl({
                    channel: chatId,
                    ...message.file,
                  }),
                }),
          } as Message),
      ),
    );
    endTimetoken = olderMessages && olderMessages[0]?.timetoken;
  } while (history.length % 100 === 0);

  return history;
};

export const useChat = (chat?: Chat) => {
  const pubNub = usePubNub();

  const [history, setHistory] = useState<Message[]>([]);

  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    // Локально сохраняем всю историю сообщений
    if (chat && history.length > 0) {
      localForage.setItem(chat.channelId, history);
    }
  }, [history, chat]);

  useEffect(() => {
    if (!pubNub || !chat) {
      return;
    }

    setIsPending(true);

    // Прогружаем текущую историю сообщений
    getChatHistory(pubNub)(chat.channelId).then(history => {
      setHistory(history);
      setIsPending(false);
    });

    // Слушаем и ждем новых сообщений
    const pnHandler = {
      message: ({ channel, publisher, message: body, timetoken }) => {
        if (chat.channelId === channel) {
          setHistory(olderMessages => [...olderMessages, { body, publisher, timetoken, type: 'plaintext' }]);
        }
      },
      file: ({ channel, publisher, file, timetoken }) => {
        if (chat.channelId === channel) {
          const attachment: Message = {
            publisher,
            type: 'file',
            timetoken,
            title: file.name,
            url: pubNub.getFileUrl({
              channel,
              ...file,
            }),
          };
          setHistory(olderMessages => [...olderMessages, attachment]);
        }
      },
    };

    pubNub.addListener(pnHandler);

    return () => {
      pubNub.removeListener(pnHandler);
    };
  }, [pubNub, chat]);

  const uuid = pubNub.getUUID();

  const sendMessage = pubNub?.publish(chat?.channelId);

  return {
    history,
    sendMessage,
    isPending,
    uuid,
  };
};

export const useChats = (channelId?: string) => {
  const pubNub = usePubNub();

  const [opened, setOpened] = useState<Chat>();

  const { value: chats, doFetch: fetchChats } = useAsync(getChats);

  useEffect(() => {
    !channelId && fetchChats();
  }, []);

  useEffect(() => {
    pubNub?.subscribe({
      channels: chats?.map(({ channelId }) => channelId) ?? [channelId],
    });
  }, [pubNub, chats]);

  const onOpen = (chat: Chat) => {
    setOpened(chat);
  };

  return { chats: chats ?? [], opened, onOpen, uuid: pubNub.uuid };
};
