import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import TableFooter from 'components/TableFooter';
import styles from './TableTools.module.less';

const Tablex: React.FC<any> = ({ paginations, data, loading, onPageChange, columns, leadToCompanyConfiguration }) => {
  const { size, total, page } = paginations;
  const [pagination, setPagination] = useState<any>({ size, total, page });

  useEffect(() => {
    onPageChange(pagination.page, pagination.size);
  }, [pagination.size, pagination.page]);

  useEffect(() => {
    setPagination({ size, total, page });
  }, [size, total, page]);

  return (
    <>
      <Table
        scroll={{ x: 1280, y: `calc(100vh - ${380}px)` }}
        loading={loading}
        pagination={false}
        columns={columns}
        bordered
        dataSource={data}
        rowKey={(record: { id: string; brand: string }) => record.id}
        onRow={record => ({
          onClick: evt => {
            evt.stopPropagation();
            leadToCompanyConfiguration(record.id);
          },
        })}
      />
      <TableFooter pagination={paginations} onChange={setPagination} total={total} className={styles.footer} />
    </>
  );
};

export default Tablex;
