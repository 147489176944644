import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editBrand } from 'store/brands/actions';
import { IEditBrand } from '../interfaces/modalBrands.interfaces';
import InfoBrand from './InfoBrand';
import { setSuccess } from 'store/brands/actions';
import { AppState } from 'store/reducer';
import { useTranslation } from 'react-i18next';

const EditBrand = ({ data, error, success, buttonText, onClose, chosenTab }: IEditBrand) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [brandValue, setBrandValue] = useState<string>('');
  const [typeValue, setTypeValue] = useState<string>('');
  const [errors, setError] = useState<string>('');
  const { types } = useSelector(({ brands: { types } }: AppState) => ({ types }));

  useEffect(() => {
    setBrandValue(data.brand);
    if (data.subtype) {
      setTypeValue(data.subtype);
    }
  }, [data]);

  useEffect(() => {
    setError(error);
  }, [error]);

  useEffect(() => {
    if (success) onClose();
    setError('');
    dispatch(setSuccess(false));
  }, [success]);

  const handleSubmit = () => {
    if (!errors) {
      dispatch(editBrand({ id: data.id, brand: brandValue, type: chosenTab, typeValue }, t));
    }
  };

  const validateBrand = () => {
    if (!brandValue.length) {
      setError(t('brands_page.enter_brand_edit'));
    }
    if (brandValue.search(/[А-Яа-яЁёЇїІіЄєҐґ']/g) !== -1) {
      setError(t('brands_page.enter_english_edit'));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setError('');
    setBrandValue(value);
  };

  const handleType = value => {
    setError('');
    setTypeValue(value);
  };

  return (
    <InfoBrand
      buttonText={buttonText}
      error={errors}
      onChange={handleChange}
      onBlur={validateBrand}
      value={brandValue}
      label={t('brands_page.brand_information_edit')}
      handleSubmit={handleSubmit}
      types={types}
      typeValue={typeValue}
      handleType={handleType}
    />
  );
};

export default EditBrand;
