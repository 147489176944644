import { makeStyles } from '@material-ui/core/styles';
import theme from 'styles/theme';

export const style = makeStyles({
  button: {
    width: 'inherit',
    height: 'auto',
    borderRadius: '0px 14px 14px 0px',
    maxWidth: 200,
  },
  input: {
    '& .MuiInputBase-root': {
      borderRadius: '14px 0px 0px 14px',
    },
  },
});

export const helperTextClasses = makeStyles({
  root: {
    color: theme.pallete.table.red.main,
    position: 'absolute',
    top: '100%',
    left: 15,
    fontSize: 12,
    lineHeight: '12px',
  },
});
