import { emailRegex, passwordRegExp } from 'components/auth/ManagerSignUp/utils';
import { roleAdapter } from '../../../../components/tables/AdminTable/utils';
import { IUserData } from '../../../../types/userCard';

export const RULES = t => {
  return {
    EMAIL: [
      { required: true, message: t('person_info.please_enter_e-mail') },
      { pattern: emailRegex, message: t('person_info.please_enter_your_e-mail_correctly') },
    ],
    PHONE: [
      { required: true, message: t('person_info.please_enter_phone_number') },
      { min: 9, max: 9, message: t('person_info.enter_phone_number_correctly') },
    ],
    DEPARTMENT: [
      { required: true, message: t('person_info.please_enter_data') },
      { min: 3, message: t('person_info.start_your_search_with_3_characters') },
    ],
    REGION: [
      {
        required: true,
        message: t('person_info.enter_the_city_region_correctly'),
        min: 3,
      },
      {
        max: 255,
        message: t('person_info.please_enter_less_255_characters'),
      },
    ],
    STREET: [
      {
        required: true,
        message: t('person_info.enter_your_address_correctly'),
        min: 2,
      },
      {
        max: 255,
        message: t('person_info.please_enter_less_255_characters'),
      },
    ],
    PASSWORD: [
      { required: true, message: t('person_info.please_enter_password') },
      { required: true, pattern: passwordRegExp, message: t('person_info.password_does_not_meet_the_requirements') },
    ],
  };
};

export const cutPhoneNumber = (phone: string) => {
  return phone ? phone.replace('+380', '') : '';
};

export const fillPhoneNumber = (phone: string) => {
  return phone ? `+380${phone}` : '';
};
export const fields = [
  'lastName',
  'firstName',
  'middleName',
  // 'phoneNumber',
  'email',
  'regionAddress',
  'streetAddress',
  'partnerDepartment',
  'partnerDepartmentRegion',
  'role',
  'partner',
  'insuranceCompany',
  'switchAction',
];

export const formatUser = (data: any, t) => ({
  middleName: data.users.person.middleName || null,
  lastName: data.users.person.lastName,
  firstName: data.users.person.firstName,
  email: data.email,
  lang: data.lang,

  phoneNumber: fillPhoneNumber(data.phoneNumber),
  address: {
    locality: data.regionAddress,
    street: data.streetAddress,
  },
  role: data.role ? Object.keys(roleAdapter).find(key => data.role === t(roleAdapter[key])) : null,
});

export const formatUserData = (data: IUserData, t) => [
  {
    title: t('user_card.last_name'),
    value: data.lastName,
  },
  {
    title: t('user_card.first_name'),
    value: data.firstName,
  },
  {
    title: t('user_card.middle_name'),
    value: data.middleName,
  },
];
