import createInstance from './axios/axios';
import { AxiosResponse } from 'axios';
import {
  IAgents,
  ICompensation,
  IData,
  IDocument,
  IDocumentsConfiguration,
  IDocumentsWhichAreNotConfigured,
  IDocumentTypeNamesList,
  IInitData,
  IInsurancePolicies,
  IManagers,
  IProgramTypes,
  IRisk,
} from '../store/insuranceProgramCreate/types';
import { AgentDto } from 'types/dto/contracts-service';

const programsAPI = createInstance('CONTRACTS');
const configApi = createInstance('CONFIGURATION');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');

export const fetchProgramData = async (id): Promise<AxiosResponse<any>> => {
  return configApi.get(`/insurance-programs/${id}`);
};

export const createProgram = async (data): Promise<AxiosResponse<any>> => {
  return configApi.post('/insurance-programs', {
    ...data,
  });
};

export const updateProgram = async (id, data): Promise<AxiosResponse<any>> => {
  return configApi.put(`/insurance-programs/${id}`, {
    ...data,
  });
};

export const getProgramsTypeData = async (): Promise<AxiosResponse<IProgramTypes[]>> => {
  return programsAPI.get(`/contract-types`);
};

export const getManagersData = async (companyId): Promise<AxiosResponse<IManagers[]>> => {
  return programsAPI.get(`/counterparties/insurance-company/${companyId}`);
};

export const getAgentsData = async () => {
  return programsAPI.get<AgentDto[]>(`/agents/search?type=INTERNAL`);
};

export const getInsTypesData = async (): Promise<AxiosResponse<IInitData[]>> => {
  return configApi.get('/insurance-types');
};

export const getInsRulesData = async (): Promise<AxiosResponse<IInitData[]>> => {
  return configApi.get('/insurance-rules');
};

export const getInsRisksData = async (): Promise<AxiosResponse<any>> => {
  return configApi.get('/risks');
};

export const getRiskListData = async (id: number): Promise<AxiosResponse<any>> => {
  return configApi.get(`/risks?insuranceProgramId=${id}`);
};

export const saveRisksData = async (id: number, data: IData): Promise<AxiosResponse<IData>> => {
  return configApi.post(`/program-risks?insuranceProgramId=${id}`, {
    ...data,
  });
};

export const updateRisksData = async (id: number, data: IData): Promise<AxiosResponse<IData>> => {
  return configApi.put(`/program-risks/${id}`, {
    ...data,
  });
};

export const deleteRiskData = async (id: number): Promise<AxiosResponse<any>> => {
  return configApi.delete(`/program-risks/${id}`);
};

export const getTariffData = async (id): Promise<AxiosResponse> => {
  return configApi.get(`/insurance-rates/${id}`);
};

export const createTariffData = async (id, tariffData): Promise<AxiosResponse> => {
  return configApi.post(`/insurance-rates/insurance-policies/${id}`, tariffData);
};

export const updateTariffData = async (id, tariffData): Promise<AxiosResponse> => {
  return configApi.put(`/insurance-rates/${id}`, tariffData);
};

export const deleteTariffData = async (id: number): Promise<AxiosResponse> => {
  return configApi.delete(`/insurance-rates/${id}`);
};

export const deleteFixedTariffData = async (insuranceRateSumId: number): Promise<AxiosResponse> => {
  return configApi.delete(`/insurance-rates/insurance-rate-sums/${insuranceRateSumId}`);
};

export const createInsurancePolicieData = async (
  id: number,
  InsurancePolicieData: IInsurancePolicies,
): Promise<AxiosResponse> => {
  return configApi.post(`/insurance-policies?insuranceProgramId=${id}`, InsurancePolicieData);
};

export const updateInsurancePolicieData = async (
  id: number,
  InsurancePolicieData: IInsurancePolicies,
): Promise<AxiosResponse> => {
  return configApi.put(`/insurance-policies/${id}`, InsurancePolicieData);
};

export const deleteInsurancePolicieData = async (id: number): Promise<AxiosResponse> => {
  return configApi.delete(`/insurance-policies/${id}`);
};

export const createCompensationData = async (id: number, CompensationBody: ICompensation): Promise<AxiosResponse> => {
  return configApi.post(`/depreciation-rates/insurance-policies/${id}`, CompensationBody);
};

export const updateCompensationData = async (id: number, CompensationBody: ICompensation): Promise<AxiosResponse> => {
  return configApi.put(`/depreciation-rates/${id}`, CompensationBody);
};

export const deleteCompensationData = async (id: number): Promise<AxiosResponse> => {
  return configApi.delete(`/depreciation-rates/${id}`);
};

export const sendDocumentData = async (insuranceProgramId: number, file, docType: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  return configApi.post(
    `/document-templates/insurance-programs/${insuranceProgramId}/files?documentTypeCode=${docType}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const getDocumentsData = async (insuranceObjectType: string): Promise<AxiosResponse> => {
  return configApi.get(`/document-templates?objectType=${insuranceObjectType}`);
};

export const viewDocumentData = async (
  insuranceProgramId: number | undefined,
  documentTypeCode: string,
): Promise<AxiosResponse> => {
  return configApi.get(
    `/document-templates/insurance-programs/${insuranceProgramId}/files?documentTypeCode=${documentTypeCode}`,
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        ContentType: 'application/x-www-form-urlencoded',
      },
    },
  );
};

export const getDocumentData = async (templateFIleId: number): Promise<AxiosResponse> => {
  return contractsPdfAPI.get(`/document-templates/template-files/${templateFIleId}`, {
    responseType: 'arraybuffer',
  });
};

export const createInstallmentData = async (insuranceProgramId, installmentData): Promise<AxiosResponse> => {
  return configApi.post(`/installment-payments/insurance-programs/${insuranceProgramId}`, installmentData);
};

export const updateInstallmentData = async (insuranceProgramId, installmentData): Promise<AxiosResponse> => {
  return configApi.put(`/installment-payments/insurance-programs/${insuranceProgramId}`, installmentData);
};

export const getSettlementTypesData = async (insuranceProgramId): Promise<AxiosResponse> => {
  return configApi.get(`/claims-configurations/resolution-types?insuranceProgramId=${insuranceProgramId}`);
};

export const getSettlementTypesRisksData = async (
  insuranceProgramId: number,
  resolutionType: string,
): Promise<AxiosResponse<IRisk[]>> => {
  return configApi.get(
    `/claims-configurations/resolution-types/risks?resolutionType=${resolutionType}&insuranceProgramId=${insuranceProgramId}`,
  );
};

export const getConfiguratedDocumentsData = async (resolutionTypeId: number): Promise<AxiosResponse<IDocument[]>> => {
  return configApi.get(`/claims-configurations/resolution-types/${resolutionTypeId}/document-configurations`);
};

export const getDocumentTypeListByNameData = async (name: string): Promise<AxiosResponse<IDocumentTypeNamesList[]>> => {
  return configApi.get(`/documents/document-types?name=${name}`);
};

export const addDocumentsConfigurationData = async (
  resolutionTypeId,
  params,
): Promise<AxiosResponse<IDocumentsConfiguration>> => {
  return configApi.post(`/claims-configurations/resolution-types/${resolutionTypeId}/documents`, params);
};

export const getDocumentsWhichAreNotConfiguredData = async (
  insuranceProgramId: number,
): Promise<AxiosResponse<IDocumentsWhichAreNotConfigured>> => {
  return configApi.get(
    `/claims-configurations/resolution-type-configurations?insuranceProgramId=${insuranceProgramId}`,
  );
};

export const duplicateSelectedPairsData = async (
  resolutionTypeId: number,
  data: number[],
): Promise<AxiosResponse<IDocumentsWhichAreNotConfigured>> => {
  return configApi.post(`/claims-configurations/resolution-types/${resolutionTypeId}/duplicate`, data);
};

export const createRefusalReasonData = async (insuranceProgramId: number, data): Promise<AxiosResponse> => {
  return configApi.post(
    `/claims-configurations/payment-rejection-causes?insuranceProgramId=${insuranceProgramId}`,
    data,
  );
};

export const updateRefusalReasonData = async (paymentRejectionCauseId: number, data): Promise<AxiosResponse> => {
  return configApi.put(`/claims-configurations/payment-rejection-causes/${paymentRejectionCauseId}`, data);
};

export const deleteRefusalReasonData = async (paymentRejectionCauseId: number): Promise<AxiosResponse> => {
  return configApi.delete(`/claims-configurations/payment-rejection-causes/${paymentRejectionCauseId}`);
};

export const deleteDocumentsData = async (resolutionTypeId: number): Promise<AxiosResponse> => {
  return configApi.delete(`/claims-configurations/resolution-types/${resolutionTypeId}/document-configurations`);
};

export const getTerminationInitiatorsNamesData = async (): Promise<AxiosResponse> => {
  return configApi.get(`/termination-configurations/termination-initiators`);
};

export const getAccountTypesData = async (): Promise<AxiosResponse> => {
  return configApi.get(`/termination-configurations/allowed-account-types`);
};

export const getTerminationCausesData = async (): Promise<AxiosResponse> => {
  return configApi.get(`/termination-configurations/termination-causes`);
};

export const createTerminationInitiatorData = async (
  programId: number,
  terminationInitiatorData,
): Promise<AxiosResponse> => {
  return configApi.post(
    `/termination-configurations/termination-initiators/insurance-programs/${programId}`,
    terminationInitiatorData,
  );
};

export const deleteTerminationInitiatorData = async (terminationInitiatorId: number): Promise<AxiosResponse> => {
  return configApi.delete(`/termination-configurations/termination-initiators/${terminationInitiatorId}`);
};

export const updateTerminationInitiatorData = async (
  terminationInitiatorId: number,
  terminationInitiatorData,
): Promise<AxiosResponse> => {
  return configApi.put(
    `/termination-configurations/termination-initiators/${terminationInitiatorId}`,
    terminationInitiatorData,
  );
};

export const createTerminationDocumentsData = async (
  programId: number,
  terminationDocumentsData,
): Promise<AxiosResponse> => {
  return configApi.post(
    `/termination-configurations/document-configurations?insuranceProgramId=${programId}`,
    terminationDocumentsData,
  );
};

export const updateTerminationDocumentsData = async (
  documentConfigurationId: number,
  terminationDocumentsData,
): Promise<AxiosResponse> => {
  return configApi.put(
    `/termination-configurations/document-configurations/${documentConfigurationId}`,
    terminationDocumentsData,
  );
};

export const getTerminationDocumentsNamesData = async (documentName: string): Promise<AxiosResponse> => {
  return configApi.get(`/termination-configurations/document-types?name=${documentName}`);
};

export const deleteTerminationDocumentsData = async (documentConfigurationId: number): Promise<AxiosResponse> => {
  return configApi.delete(`/termination-configurations/document-configurations/${documentConfigurationId}`);
};
