import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Card, Modal, Popover, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import styles from './styles.module.less';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import { updateInstitution, getServiceTypeServices } from 'store/medApp/actions';
import { useDispatch, useSelector } from 'react-redux';
import { FormRules } from '../MedAppCreate/rules';
import { getLocalities, getCities } from 'api/institution';
import { AppState } from 'store/reducer';
import styled from 'styled-components';

//FORMS
import Button from 'components/Button';
import FormInputText from 'components/FormInputText';
import FormSelect from 'components/FormSelect';

import { useLocation } from 'react-router-dom';
import { getInstitution } from 'api/institution';
import { useAsync } from '../../hooks/useAsync';

interface IProps extends FormComponentProps {}

export const CheckBoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #eeeff4;
  border-radius: 12px;
  height: 200px;
  overflow-y: scroll;
`;

export const CheckBoxesTitle = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #a9b5bd;
  position: absolute;
  left: 16px;
  padding-top: 8px;
  width: 400px;
  background: #eeeff4;
  z-index: 9;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-left: 8px;
  label {
    margin-left: 8px;
  }
`;

export const MedAppDetailsForm: React.FC<IProps> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getFieldDecorator, resetFields } = form;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const location = useLocation();
  const locationArr = location.pathname.split('/');
  const { value: institutionData, doFetch: fetchInstitution } = useAsync(getInstitution);
  const { value: cities, doFetch: fetchCities } = useAsync(getCities);
  const { value: localities, doFetch: fetchLocalities } = useAsync(getLocalities);
  const { serviceTypeServices } = useSelector((store: AppState) => store.medApp);

  useEffect(() => {
    if (locationArr[3]) {
      fetchInstitution(locationArr[3]);
    }
  }, [locationArr[3]]);

  useEffect(() => {
    dispatch(getServiceTypeServices());
    fetchLocalities();
    fetchCities(`Харьковская`);
  }, []);

  const initMap = () => {
    setTimeout(() => {
      if (document.getElementById('map')) {
        const myLatlng = { lat: -25.363, lng: 131.044 };

        const map = new google.maps.Map(document.getElementById('map')!, {
          zoom: 4,
          center: myLatlng,
        });

        // Create the initial InfoWindow.
        let infoWindow = new google.maps.InfoWindow({
          content: 'Click the map to get Lat/Lng!',
          position: myLatlng,
        });

        infoWindow.open(map);

        // Configure the click listener.
        map.addListener('click', mapsMouseEvent => {
          // Close the current InfoWindow.
          infoWindow.close();

          // Create a new InfoWindow.
          infoWindow = new google.maps.InfoWindow({
            position: mapsMouseEvent.latLng,
          });
          setLatitude(mapsMouseEvent.latLng.lat());
          setLongitude(mapsMouseEvent.latLng.lng());
          setMapCoordinates(JSON.stringify(mapsMouseEvent.latLng.toJSON()));
          infoWindow.setContent(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2));
          infoWindow.open(map);
        });
      }
    }, 0);
  };

  useEffect(() => {
    initMap();
  }, [showMap]);

  useEffect(() => {
    setMapCoordinates(JSON.stringify(institutionData?.coordinates));
  }, [institutionData]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const popoverContent = <div id="map" style={{ height: 350, width: 500 }}></div>;

  const onSubmit = () => {
    form.validateFields((err: any, fieldsValue: any) => {
      if (!err) {
        let requestData: { [key: string]: any } = {
          companyId: fieldsValue.companyId,
          legalName: fieldsValue.legalName,
          brandName: fieldsValue.brandName,
          isOnline: fieldsValue.isOnline,
          locality: fieldsValue.locality,
          city: fieldsValue.city,
          street: fieldsValue.street,
          houseNumber: fieldsValue.houseNumber,
          latitude: latitude,
          longitude: longitude,
          informationSystem: fieldsValue.informationSystem,
          propertyType: fieldsValue.propertyType,
          brand: fieldsValue.brand,
          facilityType: fieldsValue.facilityType,
          facilityClassINSK: fieldsValue.facilityClassINSK,
          accountDetails: fieldsValue.accountDetails,
          phone: fieldsValue.phone,
        };

        dispatch(updateInstitution(requestData, fieldsValue.companyId));
      }
    });
  };

  return (
    <>
      <div className={styles.Form}>
        <Card
          title={<div className={styles.Form__Card}>{<span>{t('medapp.lpu_data')}</span>}</div>}
          style={{ borderRadius: '8px' }}
          bordered={false}
        >
          <Row gutter={[10, 5]}>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('companyId', {
                  initialValue: institutionData?.companyId,
                  rules: FormRules(t).NAME_RULES,
                })(<FormInputText title={'Код ЄДРПОУ'} isRequired={false} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('locality', {
                  initialValue: institutionData?.locality,
                  rules: FormRules(t).NAME_RULES,
                })(
                  <FormSelect
                    title={'Область'}
                    options={localities}
                    isRequired={true}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('city', {
                  initialValue: institutionData?.city,
                  rules: FormRules(t).NAME_RULES,
                })(
                  <FormSelect
                    title={'Населений пункт'}
                    options={cities}
                    isRequired={true}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('street', {
                  initialValue: institutionData?.street,
                  rules: FormRules(t).NAME_RULES,
                })(<FormInputText title={'Фактична адреса'} isRequired={true} className={styles.street_field} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('houseNumber', {
                  initialValue: institutionData?.houseNumber,
                  rules: FormRules(t).NAME_RULES,
                })(<FormInputText title={'Номер будинку'} isRequired={true} className={styles.houseNumber_field} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('ownership', {
                  rules: FormRules(t).NAME_RULES,
                })(
                  <FormSelect
                    title={'форма власності'}
                    options={['Державна', 'Відомча', 'Приватна']}
                    isRequired={true}
                    className={styles.ownership_field}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('legalName', {
                  initialValue: institutionData?.legalName,
                  rules: FormRules(t).NAME_RULES,
                })(<FormInputText title={'Юридична назва'} isRequired={true} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('brandName', {
                  initialValue: institutionData?.brandName,
                  rules: FormRules(t).NAME_RULES,
                })(<FormInputText title={'Брендова назва'} isRequired={true} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('brand', {
                  initialValue: institutionData?.brandName,
                  rules: FormRules(t).NAME_RULES,
                })(<FormInputText title={'Бренд'} isRequired={true} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('coordinates', {
                  initialValue: mapCoordinates,
                  rules: FormRules(t).NAME_RULES,
                })(
                  <Popover
                    content={popoverContent}
                    title={null}
                    trigger="click"
                    onVisibleChange={visible => setShowMap(visible)}
                    visible={showMap}
                  >
                    <FormInputText title={'Координати'} isRequired={true} value={mapCoordinates} />
                  </Popover>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('clinicType', {
                  initialValue: institutionData?.clinicType,
                  rules: FormRules(t).NAME_RULES,
                })(
                  <FormSelect
                    title={'Тип ЛПУ'}
                    options={[
                      'Вузькопрофільна клініка',
                      'Стоматологія',
                      'Амбулаторно-поліклінічна допомога',
                      'Виклик лікаря додому/офісу',
                      'Невідкладна допомога',
                      'Лабораторія',
                      'facilityType',
                      'Стаціонар',
                      'Багатопрофільна',
                    ]}
                    isRequired={true}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('phoneNumber', {
                  rules: FormRules(t).NAME_RULES,
                })(<FormInputText title={'Номер телефону'} isRequired={false} />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('facilityClassINSK', {
                  initialValue: institutionData?.facilityClassINSK,
                  rules: FormRules(t).NAME_RULES,
                })(
                  <FormSelect
                    title={'Клас ЛПУ'}
                    options={['A+', 'A', 'B', 'C', 'D']}
                    isRequired={true}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('isOnline', {
                  initialValue: institutionData?.isOnline
                    ? { title: 'Так', value: true, key: 'Так' }
                    : { title: 'Ні', value: false, key: 'Ні' },
                  rules: [
                    {
                      required: true,
                      message: t('validation.enter_data'),
                    },
                  ],
                })(
                  <FormSelect
                    title={'Консультація Online'}
                    options={[
                      { title: 'Так', value: true, key: 'Так' },
                      { title: 'Ні', value: false, key: 'Ні' },
                    ]}
                    isRequired={false}
                    getPopupContainer={(trigger: HTMLElement) => trigger.parentNode}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          {/* initialValue: institutionData?.propertyType, */}
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('propertyType', {
                  initialValue: institutionData?.propertyType,
                })(
                  <CheckBoxesWrapper>
                    <CheckBoxesTitle>Типи послуг</CheckBoxesTitle>
                    <CheckBoxContainer>
                      {serviceTypeServices?.map((item: any) => (
                        <Checkbox key={item.id}>{item.name}</Checkbox>
                      ))}
                    </CheckBoxContainer>
                  </CheckBoxesWrapper>,
                )}
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row gutter={[10, 5]}>
          <div className={styles.Form__Header}>
            <Col span={8} offset={16}>
              <div className={styles.Form__Actions}>
                <Button startIcon={undefined} onClick={() => showModal()}>
                  Відміна
                </Button>
                <Button color={'primary'} onClick={onSubmit}>
                  Зберігти
                </Button>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      {isModalVisible && (
        <Modal visible={isModalVisible} width={437} footer={null} onCancel={closeModal}>
          <div className={styles.Form__Modal_title}>Ви впевнені, що хочете повернутися до списку ЛПУ?</div>
          <div className={styles.Form__Modal_content}>Введені дані НЕ будуть збережені</div>
          <div className={styles.Form__Actions}>
            <Button className={styles.Form__Actions_Cancel} onClick={() => history.push(ROUTES.MEDAPP.ROOT)}>
              Відміна
            </Button>
            <Button color={'primary'} onClick={() => closeModal()}>
              Так, повернутись
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Form.create({ name: 'MedAppDetailsFormUpdae' })(MedAppDetailsForm);
