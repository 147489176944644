import React, { HTMLAttributes } from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import cs from 'classnames';
import Separator from './components/Separator';

export interface IFiltersBarProps extends HTMLAttributes<HTMLDivElement> {
  classes?: any;
}

const FiltersBar: React.FC<IFiltersBarProps> = ({ classes, className, children, ...props }) => (
  <div {...props} className={cs(className, classes.root)}>
    {React.Children.map(children, (c, index) => (
      <>
        {React.cloneElement(c as React.ReactElement, {
          ...(c ? (c as any).props : {}),
          className: cs(classes.item, c ? (c as any).props.className : null),
        })}{' '}
        {index + 2 < React.Children.count(children) ? <Separator /> : null}
      </>
    ))}
  </div>
);

export default withStyles(styles)(FiltersBar);
