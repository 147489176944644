import i18n from 'i18next';
import isEmail from 'is-email';

import { nonempty, string, refiner, object, Infer, boolean } from 'utils/struct';

export const schema = object({
  username: refiner(
    nonempty(
      string(() => i18n.t('log_in.enter_email_or_phone')),
      () => i18n.t('log_in.enter_email_or_phone'),
    ),
    value => {
      if (/^\+/.test(value.trim())) {
        return !/^\+\d+$/.test(value.trim()) ? i18n.t('schema.phoneNumber').toString() : true;
      }

      if (!isEmail(value.trim())) {
        return i18n.t('schema.email').toString();
      }

      return true;
    },
  ),
  password: refiner(
    nonempty(
      string(() => i18n.t('log_in.enter_password')),
      () => i18n.t('log_in.enter_password'),
    ),

    value => {
      if (!/^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{8,})*$/.test(value.trim())) {
        return i18n.t('passwordRecovery.password_must_contain_at_least_8_characters').toString();
      }

      return true;
    },
  ),
  remember: boolean(),
});

export type Values = Infer<typeof schema>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefaultValues = Record<keyof Values, any>;

export const defaultValues: DefaultValues = {
  username: null,
  password: null,
  remember: true,
};
