export const detail = `передбачена заборона будь-яких виплат на користь наступних осіб: - громадяни Російської Федерації, крім тих,
що проживають на території України на законних підставах; - юридичні особи, створені та зареєстровані
відповідно до законодавства Російської Федерації; - юридичні особи, створені та зареєстровані відповідно до
законодавства України, кінцевим бенефіціарним власником, членом або учасником (акціонером), що має частку в
статутному капіталі 10 і більше відсотків, якої є Російська Федерація, громадянин Російської Федерації, крім
того, що проживає на території України на законних підставах, або юридична особа, створена та зареєстрована
відповідно до законодавства Російської Федерації.`;

export const title = `Страхувальник/Вигодонабувач не пов'язаний з Російською Федерацією`;

export const link = `Постановою КМУ №187від 03.03.22р.`;
