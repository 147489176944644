import { Locale } from 'antd-modern/lib/locale-provider';
import en_GB from 'antd-modern/lib/locale/en_GB';
import ru_RU from 'antd-modern/lib/locale/ru_RU';
import uk_UA from 'antd-modern/lib/locale/uk_UA';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';

import { AnyObject, Language } from 'types';
import { EN_TRANSLATIONS } from 'translations/en';
import { RU_TRANSLATIONS } from 'translations/ru';
import { UA_TRANSLATIONS } from 'translations/ua';

// NOTE locale should be in BCP-47 language tag format (/^[a-z]{2}-[A-Z]{2}$/)
// 'Primary language subtag - Region subtag'
// locale validator https://schneegans.de/lv/
export type LocaleType = 'en-GB' | 'ru-RU' | 'uk-UA';
export interface LocaleOptions {
  title: string;
  translation: AnyObject;
  antd: Locale;
  dayjs: string;
}

const locales: Record<Language, LocaleOptions> = {
  EN: {
    title: 'English',
    translation: EN_TRANSLATIONS,
    antd: en_GB,
    dayjs: 'en',
  },
  RU: {
    title: 'Русский',
    translation: RU_TRANSLATIONS,
    antd: ru_RU,
    dayjs: 'ru',
  },
  UA: {
    title: 'Українська',
    translation: UA_TRANSLATIONS,
    antd: uk_UA,
    dayjs: 'uk',
  },
};

export default locales;
