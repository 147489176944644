import React from 'react';
import { IconProps } from 'antd/lib/icon';
import { css, cx } from '@emotion/css';

import { theme } from 'styles';

import { iconMap, Type } from './SVG';

type Color = 'primary' | 'inherit';

const colorMap: Record<Color, string> = {
  primary: theme.color.primary.main,
  // success: theme.color.success,
  // error: theme.color.error,
  // warning: theme.color.warning,
  // info: theme.color.info,
  // white: theme.color.white,
  inherit: 'inherit',
};

export interface Props extends IconProps {
  type: Type;
  color?: Color;
  size?: string;
}

const Icon = ({ type, color = 'inherit', className, ...props }: Props) => {
  const IconComponent = iconMap[type];

  return (
    <IconComponent
      className={cx(
        css`
          && {
            font-size: 16px;
            padding: 8px;
            border-radius: 8px;
            background: ${theme.color.primary.lightest};
            color: ${colorMap[color]};
          }
        `,
        className,
      )}
      {...props}
    />
  );
};

export default Icon;
