import React from 'react';
import { Table as BaseTable } from 'antd';
import { TableProps } from 'antd/lib/table';

interface Props<T> extends TableProps<T> {}

function Table<T extends object>(props: Props<T>) {
  return <BaseTable bordered {...props} />;
}

export default Table;
