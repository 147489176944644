import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import classnames from 'classnames';
import { invert } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppState } from 'store/reducer';
import {
  getFilteredPrivateData,
  getFilteredRegistrationDevices,
  getSaleRegisterStatuses,
  getTablePage,
  setPagination,
  setSorting,
} from 'store/registrationDevices/actions';
import PrivateFilter from 'components/PrivateFilter';
import CustomTable from 'components/CustomTable';
import { FilterIcon } from 'components/Icons';
import columnsList from './columns';
import {
  DELIVERY,
  STATUS_TYPE,
  CLAIM_STATUS,
  REPAIR_STATUS,
  RESOLUTION_TYPE,
  OWNER_EQUIPMENT_TYPE,
} from './constants/RegistrationDevicesConstants';

import styles from './styles';

export interface IRegistrationDevicesTableProps {
  classes: any;
}

const RegistrationDevices: React.FC<IRegistrationDevicesTableProps> = props => {
  const { t } = useTranslation();
  const [clearedFilters, setClearedFilters] = useState(false);
  const [filtersInfo, setFiltersInfo] = useState({} || null);

  const { current, perPage, data, total, pending, privateFilteredData } = useSelector(
    ({
      registrationDevices: {
        pagination: { current, perPage },
        data,
        total,
        pending,
        privateFilteredData,
      },
    }: AppState) => ({ current, perPage, data, total, pending, privateFilteredData }),
  );
  const dispatch = useDispatch();
  const fullData = privateFilteredData.concat(data);

  const getTablePageData = useMemo(() => {
    dispatch(getTablePage());
  }, [getTablePage, dispatch]);

  useEffect(() => {
    dispatch(getSaleRegisterStatuses());
  }, [getSaleRegisterStatuses]);

  const handleFilters = (value: {}) => {
    setFiltersInfo({
      ...filtersInfo,
      ...value,
    });
  };

  const onPageChange = (page: number, pageSize?: number | undefined): void => {
    dispatch(setPagination(page, pageSize || 0));
    getDataFromPrivateFilter(filtersInfo, { current: page, perPage: pageSize || 0 });
  };

  const onPageSizeChange = (pageSize: string): void => {
    dispatch(setPagination(1, Number(pageSize) || 0));
    getDataFromPrivateFilter(filtersInfo, { current: 1, perPage: Number(pageSize) });
  };

  const getDataFromPrivateFilter = (
    filtersInfoo: { [key: string]: any } | null,
    pagination?: { current: number; perPage: number },
  ) => {
    if (pagination) {
      dispatch(setPagination(pagination.current, Number(pagination.perPage) || 1));
    } else {
      dispatch(setPagination(1, Number(perPage) || 1));
    }

    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }

        if (key === 'saleRegisterStatus') {
          const constValue = t(invert(STATUS_TYPE)[filtersInfoo[key]]);
          filtersInfoo[key] = constValue;
        }

        if (key === 'ownerEquipment') {
          const constValue = t(invert(OWNER_EQUIPMENT_TYPE)[filtersInfoo[key]]);
          filtersInfoo[key] = constValue;
        }

        if (key === 'resolution') {
          const constValue = t(invert(RESOLUTION_TYPE)[filtersInfoo[key]]);
          filtersInfoo[key] = constValue;
        }

        if (key === 'claimStatus') {
          const constValue = t(invert(CLAIM_STATUS)[filtersInfoo[key]]);
          filtersInfoo[key] = constValue;
        }

        if (key === 'delivery') {
          const constValue = t(invert(DELIVERY)[filtersInfoo[key]]);
          filtersInfoo[key] = constValue;
        }

        if (key === 'dispatchAfterSaleDelivery') {
          const constValue = t(invert(DELIVERY)[filtersInfoo[key]]);
          filtersInfoo[key] = constValue;
        }

        if (key === 'repairStatus') {
          const constValue = t(invert(REPAIR_STATUS)[filtersInfoo[key]]);
          filtersInfoo[key] = constValue;
        }
      });

    setFiltersInfo({
      ...filtersInfoo,
    });

    dispatch(
      getFilteredRegistrationDevices(
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const filteredBySearching = (filterBy: string, value: string, type: string) => {
    if (type === 'search') {
      setFiltersInfo({
        [filterBy]: [value],
      });
    }

    if (type === 'date') {
      setFiltersInfo({
        [filterBy]: [`${value[0]}..${value[1]}`],
      });
    }

    getDataFromPrivateFilter(type === 'date' ? { [filterBy]: [`${value[0]}..${value[1]}`] } : { [filterBy]: value });
  };

  const clearFilter = () => {
    setClearedFilters(false);
  };

  const clearFilters = () => {
    dispatch(getTablePage());
    setFiltersInfo({});
    setClearedFilters(true);
  };

  const getColumnSearchProps = (dataIndex: string, isDate?: boolean) => {
    const filteredValue = (filtersInfo && filtersInfo[dataIndex]) || null;

    return {
      filteredValue,
      filters:
        fullData &&
        fullData.map((item: any) => {
          if (dataIndex === 'saleRegisterStatus') {
            return {
              value: t(STATUS_TYPE[item[dataIndex]]) || '',
            };
          }

          if (dataIndex === 'ownerEquipment') {
            return {
              value: t(OWNER_EQUIPMENT_TYPE[item[dataIndex]]) || '',
            };
          }

          if (dataIndex === 'resolution') {
            return {
              value: t(RESOLUTION_TYPE[item[dataIndex]]) || '',
            };
          }

          if (dataIndex === 'incidentDate') {
            return { value: moment(item[dataIndex]).format('DD.MM.YYYY') };
          }

          if (dataIndex === 'claimStatus') {
            return { value: t(CLAIM_STATUS[item[dataIndex]]) || '' };
          }

          if (dataIndex === 'delivery') {
            return { value: t(DELIVERY[item[dataIndex]]) || '' };
          }

          if (dataIndex === 'dispatchAfterSaleDelivery') {
            return { value: t(DELIVERY[item[dataIndex]]) || '' };
          }

          if (dataIndex === 'repairStatus') {
            return { value: t(REPAIR_STATUS[item[dataIndex]]) || '' };
          }

          return { value: String(item[dataIndex]) };
        }),
      filterIcon: (filtered: boolean) => (
        <div>
          <FilterIcon color={filtered ? '#fff' : null} className={classnames({ active: filtered })} />
        </div>
      ),
      filterDropdown: (record: any) => {
        return (
          <PrivateFilter
            record={record}
            dataIndex={dataIndex}
            filtersInfo={filtersInfo}
            setFiltersInfo={setFiltersInfo}
            getDataFromPrivateFilter={getDataFromPrivateFilter}
            getDataPrivateFilter={getDataPrivateFilter}
            filteredBySearching={filteredBySearching}
            isDate={isDate}
            getTablePage={() => getTablePageData}
            isClearedFilters={clearedFilters}
            clearAllFilters={clearFilter}
          />
        );
      },
    };
  };

  const columns = columnsList(t).map(({ title, value, width = 200, ...rest }) => {
    const basicColumn = {
      title,
      width,
      key: value,
      dataIndex: value,
      sorter: true,
      ...rest,
    };

    switch (value) {
      case 'saleRegisterStatus':
      case 'ownerEquipment':
      case 'insuranceObjectName':
      case 'insuranceObjectSubtype':
      case 'insuranceObjectPid':
      case 'contractNumber':
      case 'resolution':
      case 'incidentDate':
      case 'claimStatus':
      case 'serviceCenterDate':
      case 'repairStatus':
      case 'receiptDate':
      case 'dispatchFromRepairDate':
      case 'dispatchAfterSaleDelivery':
      case 'delivery': {
        return {
          ...basicColumn,
          ...getColumnSearchProps(value),
        };
      }
      default:
        return {
          ...basicColumn,
        };
    }
  });

  // filter with arrows
  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? 'ASC' : 'DESC';
      }
      return '';
    };

    const formatSorting = {
      field: sorting.field,
      direction: formatDirection(sorting.order),
    };

    dispatch(setSorting(formatSorting));
    getDataFromPrivateFilter(filtersInfo);
  };

  const getDataPrivateFilter = (filtersInfoo: { [key: string]: any } | null) => {
    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });

    dispatch(
      getFilteredPrivateData(
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const handleRowTable = ({ id: recId, insuranceObjectType }: any) => {
    console.log('------handleRowTable RegistrationDevicesFilters', recId, insuranceObjectType); // TODO
    // const id: number = recId;
    // history.push({
    //   pathname: `${ROUTES.REGISTRATION_DEVICES.EDIT.ROOT}/${id}`, // TODO
    //   state: getRedirectState(insuranceObjectType),
    // });
  };

  return (
    <CustomTable
      {...props}
      page={current}
      contentHeight={410}
      pageSize={perPage}
      total={total || 0}
      columns={columns}
      pending={pending || false}
      data={data}
      isRowClick={true}
      filtersInfo={filtersInfo}
      handleFilters={handleFilters}
      clearFilters={clearFilters}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      handleTableChange={handleTableChange}
      rowClickHandler={handleRowTable}
    />
  );
};

export default withStyles(styles)(RegistrationDevices);
