import React from 'react';
import styles from './OrdinaryPayments.module.less';
import PaymentsStructureChart from '../../Charts/PaymentsStructureChart';
import Legend from '../../Charts/Legend';
import normalizeChartData from '../../utils/normalizeChartData';
import { useTranslation } from 'react-i18next';
import { SalesStatisticsDtoBigdecimal } from 'types/dto/contracts-service';

interface IOrdinaryPaymentsProps {
  data: SalesStatisticsDtoBigdecimal[];
}

const OrdinaryPayments = ({ data: rawData }: IOrdinaryPaymentsProps) => {
  const { t } = useTranslation();

  const chartData = normalizeChartData(rawData, t);
  return (
    <div className={styles.small_charts}>
      <p> {t('dash_board.payment_structure')} %</p>
      <PaymentsStructureChart className={styles.chart} partFromAll={true} data={chartData} />
      <div className={styles.chart_legend}>
        <Legend data={chartData} partFromAll={true} />
      </div>
    </div>
  );
};

export default OrdinaryPayments;
