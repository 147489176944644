import React from 'react';
import BaseAutocomplete from '../../../uikit/Autocomplete';
import useInputStyles from '../../../uikit/styles/useInputStyles';
import theme from '../../../../styles/theme';
import { makeStyles } from '@material-ui/core/styles';
import useMenuStyles from '../Select/useMenuStyles';
import useLabelStyles from './useLabelStyles';
import { IAutocompleteProps as IBaseAutocompleteProps } from '../../../uikit/Autocomplete/Autocomplete';
import ClearButton from '../ClearButton';
import cs from 'classnames';

const useStyles = makeStyles({
  input: {
    minWidth: 220,
    height: 62,
    paddingRight: 36,
  },
  root: {
    position: 'relative',
  },
  clearButton: {
    position: 'absolute',
    top: 22,
    right: 16,
  },
});

export interface IAutocompleteProps extends IBaseAutocompleteProps {
  clearable?: boolean;
  onClear?: any;
  className?: string;
  disabledOpenQuery?: boolean;
}

const Autocomplete: React.FC<IAutocompleteProps> = React.forwardRef(
  ({ clearable, value, onClear, className, disabledOpenQuery = true, ...props }, ref) => {
    const classes = useStyles();
    const inputClasses = useInputStyles({
      color: theme.pallete.basic.white,
      activeColor: theme.pallete.basic.white,
      height: 62,
      padding: '32px 16px 12px 20px',
    });
    const menuClasses = useMenuStyles({ color: theme.pallete.basic.white, offset: 10 });

    const labelClasses = useLabelStyles();

    const onClearAll = () => {
      onClear();
      props.onChange('');
      props.onQueryChange('');
    };

    //@ts-ignore
    return (
      <div className={cs(classes.root, className)}>
        <BaseAutocomplete
          //@ts-ignore
          className={classes.input}
          //@ts-ignore
          ref={ref}
          value={value}
          {...props}
          fullWidth={true}
          disabledOpenQuery={disabledOpenQuery}
          MenuProps={{
            classes: menuClasses,
          }}
          InputLabelProps={{
            classes: labelClasses,
          }}
          InputProps={{
            classes: inputClasses,
          }}
        />
        {clearable && value !== '' && <ClearButton onClick={onClearAll} className={classes.clearButton} />}
      </div>
    );
  },
);

export default Autocomplete;
