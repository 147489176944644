const columnsList = () => {
  return [
    {
      title: '',
      value: 'claimStatus',
      key: 'claimStatus',
      width: 70,
      fixed: 'left',
    },
    {
      title: 'insured_events_list.claim_number_express_payment_table',
      value: 'claimNumber',
      key: 'claimNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.contract_number_express_payment_table',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      value: 'contractNumber',
      width: 250,
    },
    {
      title: 'insured_events_list.start_date_express_payment_table',
      value: 'startDate',
      key: 'startDate',
      width: 240,
    },
    {
      title: 'insured_events_list.end_date_express_payment_table',
      value: 'endDate',
      key: 'endDate',
      width: 220,
    },
    {
      title: 'insured_events_list.insurance_sum_express_payment_table',
      value: 'insuranceAmount',
      key: 'insuranceAmount',
      width: 200,
    },
    {
      title: 'insured_events_list.incident_date_express_payment_table',
      value: 'incidentDate',
      key: 'incidentDate',
      width: 200,
    },
    {
      title: 'insured_events_list.claim_date_express_payment_table',
      value: 'claimDate',
      key: 'claimDate',
      width: 200,
    },
    {
      title: 'insured_events_list.signatory_express_payment_table',
      value: 'signatory',
      key: 'signatory',
      width: 200,
    },
    {
      title: 'insured_events_list.insurance_object_express_payment_table',
      value: 'insuranceObject',
      key: 'insuranceObject',
      width: 200,
    },
    {
      title: 'insured_events_list.pid_express_payment_table',
      value: 'pid',
      key: 'pid',
      width: 230,
    },
    {
      title: 'insured_events_list.risk_express_payment_table',
      value: 'risk',
      key: 'risk',
      width: 200,
    },
    {
      title: 'insured_events_list.resolution_type_express_payment_table',
      value: 'resolutionType',
      key: 'resolutionType',
      width: 200,
    },
    {
      title: 'insured_events_list.expected_loss_sum_express_payment_table',
      value: 'expectedLossSum',
      key: 'expectedLossSum',
      width: 200,
    },
    {
      title: 'insured_events_list.franchise_express_payment_table',
      value: 'franchise',
      key: 'franchise',
      width: 150,
    },
    {
      title: 'insured_events_list.payment_amount_express_payment_table',
      value: 'paymentAmount',
      key: 'paymentAmount',
      width: 200,
    },
    {
      title: 'insured_events_list.paid_express_payment_table',
      value: 'paid',
      key: 'paid',
      width: 200,
    },
    {
      title: 'insured_events_list.payment_date_express_payment_table',
      value: 'paymentDate',
      key: 'paymentDate',
      width: 200,
    },
    {
      title: 'insured_events_list.payout_express_payment_table',
      value: 'partnerDepartmentPayout',
      key: 'partnerDepartmentPayout',
      width: 250,
    },
    {
      title: '',
      value: 'actions',
      key: 'actions',
      width: 50,
    },
  ];
};

export default columnsList;
