import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import cls from 'classnames';

import { Values } from 'types/appointments';
import Typography from 'components/Typography';
import { AppointmentTypePicker } from 'screens/Appointments/pickers/AppointmentTypePicker';
import { ContractPicker } from 'screens/Appointments/pickers/ContractPicker';
import { ServicePicker } from 'screens/Appointments/pickers/ServicePicker';
import { DateTimePicker } from 'screens/Appointments/pickers/DateTimePicker';
import { ClinicPicker } from 'screens/Appointments/pickers/ClinicPicker';
import { ServiceDrEleksPicker } from 'screens/Appointments/pickers/ServiceDrEleksPicker';
import { DoctorPicker } from 'screens/Appointments/pickers/DoctorPicker';
import { DateTimeDrEleksPicker } from 'screens/Appointments/pickers/DateTimeDrEleksPicker';
import { useData } from '../../hooks';

import styles from './Pickers.module.less';

interface PickersProps {
  values: Values;
  errors: Record<string, boolean>;
  setField(key: string, value: any): void;
  data: ReturnType<typeof useData>;
  clearError: (string) => void;
  disabledData: any;
  isDoctorEleks: boolean;
  setIsDoctorEleksClinic: (isDoctorEleks: boolean) => void;
}

const Pickers: React.FC<PickersProps> = ({
  values,
  errors,
  setField,
  data,
  clearError,
  disabledData,
  isDoctorEleks,
  setIsDoctorEleksClinic,
}) => {
  const { t } = useTranslation();

  return (
    <Paper className={cls(styles.pickersContainer, { [styles.doctorEleksPickers]: isDoctorEleks })} elevation={0}>
      <header>
        <Typography type="title">{t('appointments.pickers_title')}</Typography>
      </header>
      <section>
        <ContractPicker
          defaultValue={values.contract}
          onClose={value => setField('contract', value)}
          variants={data.contracts}
          fetchClaims={data.fetchClaims}
          setField={setField}
        />
        <ClinicPicker
          defaultValue={values.clinic}
          error={!!errors.clinic}
          onClose={clinic => setField('clinic', clinic)}
          variants={data.clinics}
          fetchClinics={data.fetchClinics}
          clearError={clearError}
          isDoctorEleks={isDoctorEleks}
          setIsDoctorEleks={setIsDoctorEleksClinic}
          fetchDrEleksServices={data.fetchDrEleksServices}
        />
        <AppointmentTypePicker
          defaultValue={values.type}
          error={!!errors.type}
          onClose={type => setField('type', type)}
          variants={data.types}
          disabled={disabledData.type.disabled}
          clearError={clearError}
        />
        {isDoctorEleks ? (
          <ServiceDrEleksPicker
            defaultValue={values.serviceDrEleks}
            error={!!errors.serviceDrEleks}
            onClose={serviceDrEleks => setField('serviceDrEleks', serviceDrEleks)}
            variants={data.drEleksServices}
            disabled={disabledData.serviceDrEleks.disabled}
            clearError={clearError}
            institutionId={values.clinic.id}
            fetchDrEleksDoctors={data.fetchDrEleksDoctors}
          />
        ) : (
          <ServicePicker
            defaultValue={values.service}
            error={!!errors.service}
            onClose={service => setField('service', service)}
            variants={data.services}
            disabled={disabledData.service.disabled}
            clearError={clearError}
          />
        )}
        {isDoctorEleks && (
          <DoctorPicker
            defaultValue={values.doctor}
            error={!!errors.doctor}
            onClose={doctor => setField('doctor', doctor)}
            variants={data.drEleksDoctors}
            disabled={disabledData.doctor.disabled}
            clearError={clearError}
            institutionId={values.clinic.id}
            serviceId={values.serviceDrEleks.serviceID}
            fetchDrEleksSlots={data.fetchDrEleksSlots}
          />
        )}
        {isDoctorEleks ? (
          <DateTimeDrEleksPicker
            defaultValue={values.dateSlotDrEleks}
            error={!!errors.dateSlotDrEleks}
            onClose={dateSlotDrEleks => setField('dateSlotDrEleks', dateSlotDrEleks)}
            variants={data.drEleksSlots}
            disabled={disabledData.dateSlotDrEleks.disabled}
            clearError={clearError}
          />
        ) : (
          <DateTimePicker
            defaultValue={values.dateSlot}
            error={!!errors.dateSlot}
            onClose={dateSlot => setField('dateSlot', dateSlot)}
            disabled={disabledData.dateSlot.disabled}
            clearError={clearError}
          />
        )}
      </section>
    </Paper>
  );
};

export default Pickers;
