import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
import config from 'config';
import moment from 'moment';

import { TYPES } from './actions';
import { IRegistrationDevices } from 'types/registrationDevices';

const initialState: IRegistrationDevices = {
  data: [],
  total: 0,
  pending: false,
  error: false,
  privateFilterItems: {},
  privateFilteredData: [],
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'incidentDate',
    direction: 'DESC',
  },
  filters: {
    dateFrom: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    saleRegisterStatus: '',
    saleRegisterStatusName: 'Усі',
    insuranceObjectPid: '',
  },
  saleRegisterStatuses: [],
};

const setTablePage = (state: IRegistrationDevices, { payload, pending }: AnyAction): IRegistrationDevices => {
  return {
    ...state,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
    pending,
  };
};

const setPending = (state: IRegistrationDevices, { pending }: AnyAction): IRegistrationDevices => {
  return {
    ...state,
    pending,
  };
};

const setPagination = (state: IRegistrationDevices, { payload }: AnyAction): IRegistrationDevices => {
  return {
    ...state,
    pagination: payload,
  };
};

const setSorting = (state: IRegistrationDevices, { payload }: AnyAction): IRegistrationDevices => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const getPrivateFiltersData = (state: IRegistrationDevices, { payload }: AnyAction): IRegistrationDevices => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    data: resultList,
    total: count,
  };
};

const getPrivateFilteredData = (state: IRegistrationDevices, { payload }: AnyAction): IRegistrationDevices => {
  const { resultList }: { resultList: [] } = payload;
  return {
    ...state,
    privateFilteredData: resultList,
  };
};

const setFiltersDates = (state: IRegistrationDevices, { payload }: AnyAction): IRegistrationDevices => {
  return {
    ...state,
    filters: payload,
  };
};

export const setSaleRegisterStatuses = (state: IRegistrationDevices, { payload }: AnyAction): IRegistrationDevices => {
  return {
    ...state,
    saleRegisterStatuses: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setPending,
    setSorting,
    setTablePage,
    setPagination,
    getPrivateFiltersData,
    getPrivateFilteredData,
    setFiltersDates,
    setSaleRegisterStatuses,
  },
});
