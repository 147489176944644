import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';

import { partnersList } from 'screens/PartnersList/selectors/selectors';
import PrivateFilter from 'components/PrivateFilter';
import CustomTable from 'components/CustomTable';
import { FilterIcon } from 'components/Icons';
import columns from './columns';
import styles from './styles';
import history from 'routes/history';
import {
  getFilteredPartners,
  getFilteredPrivateData,
  getTablePage,
  setPagination,
  setSorting,
} from 'store/partnersList/actions';
import { ROUTES } from '../../../../constants/routes';
import { useTranslation } from 'react-i18next';

interface IPartnerTableProps {
  classes: any;
}

const parsePartnerType = {
  SERVICE_COMPANY: 'Cервісна компанія',
  INSURANCE_AGENT: 'Cтраховий агент',
};

export const unParsePartnerType = {
  'Cервісна компанія': 'SERVICE_COMPANY',
  'Cтраховий агент': 'INSURANCE_AGENT',
};

const PartnersTable = (props: IPartnerTableProps) => {
  const { t } = useTranslation();
  const [clearedFilters, setClearedFilters] = useState(false);
  const [filtersInfo, setFiltersInfo] = useState({} || null);
  const {
    data,
    loading,
    total,
    pagination: { perPage, current },
  } = useSelector(partnersList);
  const dispatch = useDispatch();

  const handleFilters = (value: {}) => {
    setFiltersInfo({
      ...filtersInfo,
      ...value,
    });
  };

  const onPageSizeChange = (pageSize: string): void => {
    dispatch(setPagination(1, Number(pageSize) || 0));
    getDataFromPrivateFilter(filtersInfo, { current: 1, perPage: Number(pageSize) });
  };

  const onPageChange = (page: number, pageSize?: number | undefined): void => {
    dispatch(setPagination(page, pageSize || 0));
    getDataFromPrivateFilter(filtersInfo, { current: page, perPage: pageSize || 0 });
  };

  const getDataFromPrivateFilter = (
    filtersInfoo: { [key: string]: any } | null,
    pagination?: { current: number; perPage: number },
  ) => {
    if (pagination) {
      dispatch(setPagination(pagination.current, Number(pagination.perPage) || 1));
    } else {
      dispatch(setPagination(1, Number(perPage) || 1));
    }

    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });
    setFiltersInfo({
      ...filtersInfoo,
    });

    dispatch(
      getFilteredPartners(
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const getDataPrivateFilter = (filtersInfoo: { [key: string]: any } | null) => {
    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });

    dispatch(
      getFilteredPrivateData(
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const filteredBySearching = (filterBy: string, value: string, type: string) => {
    if (type === 'date') {
      setFiltersInfo({
        [filterBy]: [`${value[0]}..${value[1]}`],
      });
    }

    getDataFromPrivateFilter(type === 'date' ? { [filterBy]: [`${value[0]}..${value[1]}`] } : { [filterBy]: value });
  };

  const clearFilter = () => {
    setClearedFilters(false);
  };

  const getTablePageData = () => {
    dispatch(getTablePage());
  };

  const getColumnSearchProps = (dataIndex: string, isDate?: boolean) => {
    const filtersInfo = {};
    const filteredValue = null;

    return {
      filteredValue,
      filters:
        data &&
        data.map((item: any) => {
          if (dataIndex === 'type') {
            return { value: parsePartnerType[item.type] || null, currentValue: String(item[dataIndex]) };
          }
          return { value: String(item[dataIndex]) };
        }),
      filterIcon: (filtered: boolean) => (
        <div>
          <FilterIcon color={filtered ? '#fff' : null} className={classnames({ active: filtered })} />
        </div>
      ),
      filterDropdown: (record: any) => {
        return (
          <PrivateFilter
            record={record}
            dataIndex={dataIndex}
            filtersInfo={filtersInfo}
            setFiltersInfo={setFiltersInfo}
            getDataFromPrivateFilter={getDataFromPrivateFilter}
            getDataPrivateFilter={getDataPrivateFilter}
            filteredBySearching={filteredBySearching}
            isDate={isDate}
            getTablePage={getTablePageData}
            isClearedFilters={clearedFilters}
            clearAllFilters={clearFilter}
          />
        );
      },
    };
  };

  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? 'ASC' : 'DESC';
      }
      return '';
    };

    const formatSorting = {
      field: sorting.field,
      direction: formatDirection(sorting.order),
    };
    dispatch(setSorting(formatSorting));
    getDataFromPrivateFilter(filtersInfo);
  };

  const clearFilters = () => {
    dispatch(getTablePage());
    setFiltersInfo({});
    setClearedFilters(true);
  };

  const partnerData = data.map(item => {
    return {
      ...item,
      type: parsePartnerType[item.type],
    };
  });

  const columnsWithFilter = columns(t).map(item => {
    return {
      ...item,
      ...getColumnSearchProps(item.value),
    };
  });

  return (
    <CustomTable
      {...props}
      page={current}
      contentHeight={410}
      pageSize={perPage}
      total={total || 0}
      pending={loading || false}
      data={partnerData}
      columns={columnsWithFilter}
      filtersInfo={filtersInfo}
      clearFilters={clearFilters}
      handleFilters={handleFilters}
      handleTableChange={handleTableChange}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      rowKey={'id'}
      isRowClick={true}
      rowClickHandler={data => {
        history.push(ROUTES.PARTNERS_LIST.READ.ROOT.replace(':partnerId', String(data.partnerId)));
      }}
    />
  );
};

export default withStyles(styles)(PartnersTable);
