import i18n from 'i18next';

import { nonempty, pattern, string, refiner, object, Infer } from 'utils/struct';

export const schema = object({
  newPassword: pattern(nonempty(string()), /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{8,})*$/, () =>
    i18n.t('passwordRecovery.password_must_contain_at_least_8_characters'),
  ),
  confirmNewPassword: refiner(string(), (value, ctx) => {
    const { newPassword } = ctx.branch[0] as Partial<{ newPassword: string }>;

    if (value !== newPassword) {
      return i18n.t('schema.passwordMatch').toString();
    }

    return true;
  }),
});

export type Values = Infer<typeof schema>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefaultValues = Record<keyof Values, any>;

export const defaultValues: DefaultValues = {
  newPassword: null,
  confirmNewPassword: null,
};
