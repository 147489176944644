import React, { Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

import { LayoutComponent } from '../components/Layout/Layout';
import PrivateRoute from './PrivateRoute';
import Dashboard from 'screens/Dashboard/Dashboard';
import SellerDashboard from '../components/SellerDashboard/lazy';
import ContractList from '../screens/ContractList/lazy';
import ActsList from 'screens/ActsList/lazy';
import ActCreate from 'screens/ActCreate/lazy';
import InsuredEventsCreate from 'screens/InsuredEvents/create/lazy';
import InsuredEventsList from 'screens/InsuredEvents/list/lazy';
import InsuranceProgramsCreate from 'screens/InsuranceProgramCreate/lazy';
import Insurance from 'screens/Insurance/lazy';
import Search from 'screens/Search/lazy';
import Page404 from '../components/Page404';
import TerminationDetails from '../screens/TerminationDetails/lazy';
import TerminationList from 'screens/TerminationsList/lazy';
import InsuranceProgramsList from 'screens/InsuranceProgramsList/lazy';
import InsuranceProgramDetails from 'screens/InsuranceProgramDetails/lazy';
import BrandsPage from '../screens/BrandsPage/BrandsPage';
import history from './history';
import { AppState } from '../store/reducer';
import { userType } from '../types/user';
import { connect } from 'react-redux';
import { roleApproved } from 'roles/userCan';
import AdminPanel from 'screens/AdminPanel/lazy';
import PrivateOffice from '../screens/PrivateOffice/lazy';
import UserCard from 'screens/UserCard/lazy';
import UsersActivities from 'screens/UsersActivities/lazy';
import Settings from 'screens/Settings/lazy';
import InsuranceCompanies from 'screens/InsuranceCompanies/List/lazy';
import InsuranceConfiguration from 'screens/InsuranceCompanies/InsuranceConfiguration/lazy';
import Departments from 'screens/Departments/lazy';
import DepartmentDetails from 'screens/DepartmentDetails/lazy';
import ContractDetails from 'screens/ContractDetails/lazy';
import PageLoading from '../components/PageLoading';
import PartnersList from 'screens/PartnersList/lazy';
import PartnersCreation from 'screens/PartnerCreation/lazy';
import ActDetails from 'screens/ActDetails';
import InsuranceContracts from 'screens/InsuranceContracts/List';
import RegistrationDevices from 'screens/RegistrationDevices';
import Chats from 'screens/Chats';
import Consultations from 'screens/Consultations';
import Appointments from 'screens/Appointments';
import History from 'screens/History';
import MedApp from 'screens/MedApp';
import Sublimits from 'screens/Sublimits';
import SublimitDetails from 'screens/SublimitDetails';
import MedAppDetails from 'screens/MedAppDetails';
import MedAppCreate from 'screens/MedAppCreate';
import Services from 'screens/Services';
import ServiceDetails from 'screens/ServiceDetails';
import RegisterOfTypes from 'screens/RegisterOfTypes/lazy';
import Subevent from 'screens/Subevent';

export const LayoutRouting = ({ user }: { user: userType }) => {
  return (
    <>
      <LayoutComponent>
        <Suspense fallback={<PageLoading />}>
          <Router history={history}>
            <Switch>
              {user.authentication?.AUTHORITIES_CLAIM === 'ROLE_CLIENT' && (
                <PrivateRoute
                  exact
                  role={['DASHBOARD', 'READ']}
                  path={ROUTES.DASHBOARD.ROOT}
                  component={PrivateOffice}
                />
              )}
              {user.authentication?.AUTHORITIES_CLAIM === 'ROLE_HR_MANAGER' && (
                <PrivateRoute
                  exact
                  role={['DASHBOARD', 'READ']}
                  path={ROUTES.DASHBOARD.ROOT}
                  component={SellerDashboard}
                />
              )}
              {user.authentication?.AUTHORITIES_CLAIM === 'ROLE_PARTNER_SELLER' ? (
                <PrivateRoute
                  exact
                  role={['DASHBOARD', 'READ']}
                  path={ROUTES.DASHBOARD.ROOT}
                  component={SellerDashboard}
                />
              ) : (
                <PrivateRoute role={['DASHBOARD', 'ANALYTICS']} path={ROUTES.DASHBOARD.ROOT} component={Dashboard} />
              )}
              <PrivateRoute
                exact
                role={['CONTRACTS_LIST', 'READ']}
                path={ROUTES.CONTRACTS_LIST.ROOT}
                component={ContractList}
              />
              <PrivateRoute
                exact
                role={['CONTRACT', 'READ']}
                path={`${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/:id`}
                component={ContractDetails}
              />
              <PrivateRoute
                role={['CONTRACT', 'CREATE']}
                exact
                path={`${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/create`}
                component={ContractDetails}
              />
              <PrivateRoute role={['ACTS', 'READ']} exact path={ROUTES.ACTS_LIST.ROOT} component={ActsList} />
              <PrivateRoute role={['ACTS', 'READ']} exact path={ROUTES.ACTS_LIST.READ.ROOT} component={ActDetails} />
              <PrivateRoute role={['ACTS', 'CREATE']} exact path={ROUTES.ACTS_LIST.CREATE.ROOT} component={ActCreate} />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.ROOT} component={Consultations} />
              <PrivateRoute
                role={['CHATS', 'READ']}
                exact
                path={ROUTES.CONSULTATIONS.APPOINTMENTS}
                component={Appointments}
              />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.HISTORY} component={History} />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.SUBEVENT} component={Subevent} />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.CHATS} component={Chats} />
              <PrivateRoute
                exact
                role={['INSURANCE', 'CREATE']}
                path={ROUTES.INSURED_EVENTS.CREATE.ROOT}
                component={InsuredEventsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE', 'READ']}
                path={ROUTES.INSURED_EVENTS.READ.ROOT}
                component={InsuredEventsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_LIST', 'READ']}
                path={ROUTES.INSURED_EVENTS.LIST.ROOT}
                component={InsuredEventsList}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_DETAILS', 'READ']}
                path={ROUTES.INSURANCE.ROOT}
                component={Insurance}
              />
              <PrivateRoute role={['SEARCH', 'READ']} exact path={ROUTES.SEARCH.ROOT} component={Search} />
              <PrivateRoute
                exact
                role={['TERMINATION', 'CREATE']}
                path={[ROUTES.TERMINATIONS.ROOT]}
                component={TerminationDetails}
              />
              <PrivateRoute
                exact
                role={['TERMINATION', 'READ']}
                path={[ROUTES.TERMINATIONS.READ]}
                component={TerminationDetails}
              />
              <PrivateRoute
                exact
                role={['TERMINATIONS_LIST', 'READ']}
                path={ROUTES.TERMINATIONS_LIST.ROOT}
                component={TerminationList}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.ADMIN_PANEL.LIST.ROOT}
                component={AdminPanel}
              />
              <PrivateRoute
                exact
                role={['REGISTER_DEVICES', 'READ']}
                path={ROUTES.REGISTRATION_DEVICES.ROOT}
                component={RegistrationDevices}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'USER_CARD']}
                path={ROUTES.ADMIN_PANEL.USER_CARD.ROOT}
                component={UserCard}
              />
              <PrivateRoute
                exact
                role={['USER_CARD', 'READ']}
                user={user.authentication}
                path={ROUTES.USER_CARD.ROOT}
                component={UserCard}
              />
              <PrivateRoute
                exact
                role={['PROCESS_ANALYTICS', 'READ']}
                path={ROUTES.INSURANCE_CONTRACTS.LIST.ROOT}
                component={InsuranceContracts}
              />
              <PrivateRoute
                exact
                role={['PROCESS_ANALYTICS', 'READ']}
                path={ROUTES.INSURANCE_CONTRACTS.CONTRACT.READ}
                component={InsuranceContracts}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_COMPANIES', 'LIST']}
                path={ROUTES.INSURANCE_COMPANIES.LIST.ROOT}
                component={InsuranceCompanies}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_COMPANIES', 'SETTINGS']}
                path={ROUTES.INSURANCE_COMPANIES.SETTINGS.ROOT}
                component={InsuranceConfiguration}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_COMPANIES', 'SETTINGS']}
                path={ROUTES.INSURANCE_COMPANIES.CREATE_SETTINGS.ROOT}
                component={InsuranceConfiguration}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user.authentication}
                path={ROUTES.INSURANCE_PROGRAMS.ROOT}
                component={InsuranceProgramsList}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user.authentication}
                path={ROUTES.INSURANCE_PROGRAMS.CREATE}
                component={InsuranceProgramsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user.authentication}
                path={ROUTES.INSURANCE_PROGRAMS.READ}
                component={InsuranceProgramsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user.authentication}
                path={ROUTES.INSURANCE_PROGRAMS.PROGRAM.ROOT}
                component={InsuranceProgramDetails}
              />
              <PrivateRoute
                exact
                role={['BRAND_PAGE', 'READ']}
                user={user.authentication}
                path={ROUTES.BRAND_PAGE.READ.ROOT}
                component={BrandsPage}
              />
              <PrivateRoute
                exact
                role={['DEPARTMENTS', 'READ']}
                user={user.authentication}
                path={ROUTES.DEPARTMENTS.ROOT}
                component={Departments}
              />
              <PrivateRoute
                exact
                role={['DEPARTMENTS', 'READ']}
                user={user.authentication}
                path={ROUTES.DEPARTMENTS.READ.ROOT}
                component={DepartmentDetails}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.PARTNERS_LIST.ROOT}
                component={PartnersList}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.PARTNERS_LIST.CREATE}
                component={PartnersCreation}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.PARTNERS_LIST.READ.ROOT}
                component={PartnersCreation}
              />
              <Route exact path={ROUTES.MEDAPP.ROOT} component={MedApp} />
              <Route exact path={ROUTES.MEDAPP.SERVICES.ROOT} component={Services} />
              <Route exact path={ROUTES.MEDAPP.SERVICES.DETAILS.ROOT} component={ServiceDetails} />
              <Route exact path={ROUTES.MEDAPP.SUBLIMITS.ROOT} component={Sublimits} />
              <Route exact path={ROUTES.MEDAPP.SUBLIMITS.DETAILS.ROOT} component={SublimitDetails} />
              <Route exact path={ROUTES.MEDAPP.DETAILS.ROOT} component={MedAppDetails} />
              <Route exact path={ROUTES.MEDAPP.CREATE} component={MedAppCreate} />
              <Route exact path={ROUTES.REGISTER_TYPES.ROOT} component={RegisterOfTypes} />
              <Route exact path={ROUTES.USERS_ACTIVITIES.ROOT} component={UsersActivities} />
              <Route exact path={ROUTES.SETTINGS.ROOT} component={Settings} />
              <Route exact path={ROUTES.NOT_FOUND.ROOT} component={Page404} />
              {roleApproved() ? <Route component={Page404} /> : <Redirect to={ROUTES.AUTH.LOGIN} />}
            </Switch>
          </Router>
        </Suspense>
      </LayoutComponent>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({ user: state.user });

export default connect(mapStateToProps)(LayoutRouting);
