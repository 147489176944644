import i18n from 'i18next';
import isEmail from 'is-email';

import { nonempty, string, refiner, object, Infer } from 'utils/struct';

export const schema = object({
  login: refiner(
    nonempty(
      string(() => i18n.t('passwordRecovery.please_enter_your_e-mail_or_phone')),
      () => i18n.t('passwordRecovery.please_enter_your_e-mail_or_phone'),
    ),
    value => {
      if (/^\+/.test(value)) {
        return !/^\+\d+$/.test(value) ? i18n.t('schema.phoneNumber').toString() : true;
      }

      if (!isEmail(value)) {
        return i18n.t('schema.email').toString();
      }

      return true;
    },
  ),
});

export type Values = Infer<typeof schema>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefaultValues = Record<keyof Values, any>;

export const defaultValues: DefaultValues = {
  login: null,
};
