import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import PageBreadcrumbs from 'components/PageBreadcrumbs';
import { ROUTES } from 'constants/routes';
import ActDetailsTable from './components/ActDetailsTable';
import PageHeader from 'components/PageHeader';
import { checkPermissions } from 'roles/userCan';
import Button from 'components/Button';
import Plus from 'components/Button/icons/Plus';
import Close from 'components/Button/icons/Close';
import { EditContractIcon, ListIcon } from 'components/Icons';
import Checkmark from 'components/Button/icons/Checkmark';
import ActDetailsFilter from 'components/filters/ActDetailsFilters';
import {
  confirmActAction,
  deleteActDetails,
  getActDetailsAction,
  getActInfo,
  updateActDetailsAction,
  withdrawAct,
} from 'store/actDetails/actions';
import AddModal from './modal';
import ActsDetailsStatistics from './statistics/ActDetailsStattistic';
import { actDetailsSelector } from 'store/actDetails/selectors';

import DownloadButton from './components/DownloadButtonю';

import Chip from 'components/Chip';
import Typography from 'components/Typography';
import styles from './ActDetails.module.less';
import { useTranslation } from 'react-i18next';

export default () => {
  const { id } = useParams();
  const { actInfo } = useSelector(actDetailsSelector);
  const dispatcher = useDispatch();
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatcher(getActInfo(id));
    }
  }, []);

  const handleSubmit = () => {
    dispatcher(updateActDetailsAction('contractsToDelete', id, t));
  };

  const resetChanges = () => {
    dispatcher(getActDetailsAction(id));
    dispatcher(getActInfo(id));
  };

  const deleteAct = () => {
    dispatcher(deleteActDetails(id, t));
  };

  const drawAct = () => {
    dispatcher(withdrawAct(id, t));
  };

  const confirmAct = () => {
    dispatcher(confirmActAction(id, t));
  };

  const onClose = () => {
    setModalOpen(false);
  };

  const onOpen = () => {
    setModalOpen(true);
  };

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('act_details.home'), href: ROUTES.DASHBOARD.ROOT },
          {
            label: t('act_details.register_act_performed_works'),
            href: ROUTES.ACTS_LIST.ROOT,
          },
          {
            label: `${t('act_details.report_completed_works')} ${actInfo ? actInfo.agentActNumber : ''}`,
          },
        ]}
      />
      <PageHeader>
        <div className={styles.container}>
          <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={'auto'}>
              <Typography type={'heading'} data-id="Contract-Number">
                {t('act_details.report_completed_works')} {actInfo ? actInfo.agentActNumber : ''}
              </Typography>
            </Grid>
            {actInfo && (
              <Grid item xs={'auto'}>
                {!actInfo.actSignDate && (
                  <Chip color={'warn'} label={t('act_details.unconfirmed')} data-id="Contract-Status" />
                )}
                {actInfo.actSignDate && (
                  <Chip color={'success'} data-id="Contract-Status" label={t('act_details.confirmed')} />
                )}
              </Grid>
            )}
          </Grid>

          <div className={styles.btns_module}>
            <Grid container spacing={2} alignItems={'center'} justify={'flex-end'}>
              {checkPermissions('ACTS', 'PRINT') && (
                <>
                  <Grid item xs={'auto'}>
                    <DownloadButton id={id} actInfo={actInfo} />
                  </Grid>
                </>
              )}
              {checkPermissions('ACTS', 'CREATE') && (
                <>
                  <Grid item xs={'auto'}>
                    <Button onClick={deleteAct} iconBackGround="#FDEEEE" startIcon={<Close iconcolor="#EB5757" />}>
                      {t('act_details.remove_button')}
                    </Button>
                  </Grid>
                </>
              )}
              {checkPermissions('ACTS', 'CREATE') && (
                <>
                  <Grid item xs={'auto'}>
                    <Button
                      onClick={confirmAct}
                      startIcon={<Checkmark />}
                      disabled={actInfo ? !!actInfo.actSignDate : false}
                    >
                      {t('act_details.confirm_button')}
                    </Button>
                  </Grid>
                </>
              )}
              {checkPermissions('ACTS', 'CREATE') && (
                <>
                  <Grid item xs={'auto'}>
                    <Button onClick={drawAct} iconBackGround="#FDF7E4" startIcon={<Close iconcolor="#EB5757" />}>
                      {t('act_details.remove_confirmation_button')}
                    </Button>
                  </Grid>
                </>
              )}
              {checkPermissions('ACTS', 'CREATE') && (
                <>
                  <Grid item xs={'auto'}>
                    <Button
                      disabled={actInfo ? !!actInfo.actSignDate : false}
                      iconBackGround="#F6F8F8"
                      startIcon={<Plus />}
                      onClick={onOpen}
                    >
                      {t('act_details.add_contract_button')}
                    </Button>
                  </Grid>
                </>
              )}
              {checkPermissions('ACTS', 'CREATE') && (
                <>
                  <Grid item xs={'auto'}>
                    <Button
                      disabled={actInfo ? !!actInfo.actSignDate : false}
                      onClick={resetChanges}
                      iconBackGround="#F6F8F8"
                      startIcon={<ListIcon />}
                    >
                      {t('act_details.reform_button')}
                    </Button>
                  </Grid>
                </>
              )}
              {checkPermissions('ACTS', 'CREATE') && (
                <>
                  <Grid item xs={'auto'}>
                    <Button
                      disabled={actInfo ? !!actInfo.actSignDate : false}
                      onClick={handleSubmit}
                      startIcon={<EditContractIcon />}
                    >
                      {t('act_details.save_draft_button')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>
        <AddModal isModalOpen={isModalOpen} onClose={onClose} id={id} />
      </PageHeader>
      <ActDetailsFilter />
      <ActDetailsTable id={id} />
      <ActsDetailsStatistics />
    </>
  );
};
