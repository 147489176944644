import React from 'react';
import { CircularProgress as CircularProgressBase, withStyles } from '@material-ui/core';
import { CircularProgressProps } from '@material-ui/core/CircularProgress/CircularProgress';

export interface ILoadingSpinnerProps extends Omit<CircularProgressProps, 'color'> {}

const CircularProgress: React.FC<ILoadingSpinnerProps> = props => <CircularProgressBase size={20} {...props} />;

export default withStyles({
  circle: {
    color: '#A9B5BD',
  },
})(CircularProgress);
