import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { AnyObject } from 'types';
import { getRequired } from 'utils/common';
import { Struct } from 'utils/struct';

export const useRequired = <T extends AnyObject>(schema: Struct<T, any>) => {
  const [required, setRequired] = useState<T>({} as T);

  useAsyncEffect(async () => {
    const errors = await getRequired(schema, {});

    setRequired(errors as T);
  }, []);

  return { required };
};
