import React from 'react';
import { Button as BaseButton } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { css, cx } from '@emotion/css';

const cssRoot = css`
  && {
    border-radius: 14px;
    height: 46px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;
const cssSizeSmall = css`
  height: 40px;
`;
const cssSizeLarge = css`
  height: 50px;
`;

export interface Props extends ButtonProps {}

const Button = ({ className, ...props }: Props) => {
  return (
    <BaseButton
      className={cx(
        cssRoot,
        { [cssSizeSmall]: props.size === 'small' },
        { [cssSizeLarge]: props.size === 'large' },
        className,
      )}
      {...props}
    />
  );
};

export default Button;
