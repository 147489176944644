export enum ResolutionType {
  EXPRESS_PAYMENT = 'EXPRESS_PAYMENT',
  SHOP_SERVICE_REPAIR = 'SHOP_SERVICE_REPAIR',
  SELF_SERVICE_REPAIR = 'SELF_SERVICE_REPAIR',
  PAYMENT = 'PAYMENT',
  SERVICE_STATION_PAYMENT = 'SERVICE_STATION_PAYMENT',
  MEDICAL_ASSISTANCE = 'MEDICAL_ASSISTANCE',
  MEDICAL_INSTITUTION = 'MEDICAL_INSTITUTION',
  INSURED = 'INSURED',
}

export enum TerminationCauseDtoCode {
  DO_NOT_USE_DEVICE_INSURER = 'DO_NOT_USE_DEVICE_INSURER',
  DO_NOT_USE_DEVICE_CUSTOMER = 'DO_NOT_USE_DEVICE_CUSTOMER',
  VIOLATION_NON_FULFILLMENT_TERMS_CONTRACT_BY_INSURED = 'VIOLATION_NON_FULFILLMENT_TERMS_CONTRACT_BY_INSURED',
  DO_NOT_USE_VEHICLE_INSURER = 'DO_NOT_USE_VEHICLE_INSURER',
  DO_NOT_USE_VEHICLE_CUSTOMER = 'DO_NOT_USE_VEHICLE_CUSTOMER',
  CHANGE_TERMS = 'CHANGE_TERMS',
  WANT_TO_CHANGE_TERMS = 'WANT_TO_CHANGE_TERMS',
  ALREADY_HAVE_CONTRACT = 'ALREADY_HAVE_CONTRACT',
  NO_INSURANCE_REQUIRED = 'NO_INSURANCE_REQUIRED',
  RETURN_INSURED_DEVICE_TO_SELLER = 'RETURN_INSURED_DEVICE_TO_SELLER',
  EXCHANGE_OF_DEVICE = 'EXCHANGE_OF_DEVICE',
  EXHAUSTION_AGREEMENT_LIABILITY_LIMIT = 'EXHAUSTION_AGREEMENT_LIABILITY_LIMIT',
  NON_PAYMENT_BY_INSURED_NEXT_INSURANCE_PAYMENT = 'NON_PAYMENT_BY_INSURED_NEXT_INSURANCE_PAYMENT',
  OTHER_CAUSE = 'OTHER_CAUSE',
}

export interface TerminationCauseDto {
  code?: TerminationCauseDtoCode;

  /** @format int64 */
  id?: number;
  name?: string;
}

export enum AccountType {
  CARD = 'CARD',
  IBAN = 'IBAN',
  CASH_DESK = 'CASH_DESK ',
}

export enum TerminationInitiatorDtoCode {
  INSURER = 'INSURER',
  POLICY_HOLDER = 'POLICY_HOLDER',
  INSURANCE_COMPANY = 'INSURANCE_COMPANY ',
  INSURANCE_COMPANY_MAINTENANCE = 'INSURANCE_COMPANY_MAINTENANCE ',
}

export interface TerminationInitiatorDto {
  code: TerminationInitiatorDtoCode;
  name: string;
  idprogramId: number;
  id?: number;
}

export interface TerminationDocumentConfigurationWithSumDto {
  configuration?: TerminationDocumentConfigurationDto[];
  refundSum?: number;
}

export interface TerminationDocumentConfigurationDto {
  documentType?: DocumentTypeDto;
  informationObligatory?: boolean;
  obligatory?: boolean;
}

export enum DocumentTypeDtoCode {
  PASSPORT = 'PASSPORT',
  PASSPORT_PAGE_2 = 'PASSPORT_PAGE_2',
  PASSPORT_PAGE_3 = 'PASSPORT_PAGE_3',
  PASSPORT_RESIDENCE_PLACE = 'PASSPORT_RESIDENCE_PLACE',
  IDENTIFICATION_NUMBER = 'IDENTIFICATION_NUMBER',
  AUTHORITIES_INFORMATION = 'AUTHORITIES_INFORMATION',
  SERVICE_CENTER_CONCLUSION = 'SERVICE_CENTER_CONCLUSION',
  INVOICE = 'INVOICE',
  AGENT_ACT = 'AGENT_ACT',
  FISCAL_CHECK = 'FISCAL_CHECK',
  EQUIPMENT_WARRANTY_CARD = 'EQUIPMENT_WARRANTY_CARD',
  DAMAGED_EQUIPMENT_PHOTO = 'DAMAGED_EQUIPMENT_PHOTO',
  RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS = 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS',
  EXTRACT_FROM_UNIFIED_REGISTER_PRE_TRIAL_INVESTIGATIONS = 'EXTRACT_FROM_UNIFIED_REGISTER_PRE_TRIAL_INVESTIGATIONS',
  RESOLUTION_ON_CLOSING_CRIMINAL_PROCEEDINGS_WITH_INDICATION_GUILTY_PERSONSSIGNS_ILLEGAL_ACTIONS = 'RESOLUTION_ON_CLOSING_CRIMINAL_PROCEEDINGS_WITH_INDICATION_GUILTY_PERSONSSIGNS_ILLEGAL_ACTIONS',
  COURT_DECISION_TO_ESTABLISH_GUILT_OF_PERSON_WHO_CAUSED_DAMAGE = 'COURT_DECISION_TO_ESTABLISH_GUILT_OF_PERSON_WHO_CAUSED_DAMAGE',
  DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID = 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID',
  AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION = 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION',
  FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT = 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT',
  DOCUMENTS_WITH_IMEI = 'DOCUMENTS_WITH_IMEI',
  INSURANCE_FISCAL_CHECK = 'INSURANCE_FISCAL_CHECK',
  PACKAGING = 'PACKAGING',
  DAMAGED_EQUIPMENT = 'DAMAGED_EQUIPMENT',
  SERVICE_CENTER_ACCOUNT = 'SERVICE_CENTER_ACCOUNT',
  CHARGER_AND_OTHER_COMPONENTS = 'CHARGER_AND_OTHER_COMPONENTS',
  OTHER_DOCUMENTS = 'OTHER_DOCUMENTS',
  FISCAL_CHECK_OTHER_RISKS = 'FISCAL_CHECK_OTHER_RISKS',
  INSURANCE_FISCAL_CHECK_OTHER_RISKS = 'INSURANCE_FISCAL_CHECK_OTHER_RISKS',
  PASSPORT_OTHER_RISKS = 'PASSPORT_OTHER_RISKS',
  PASSPORT_PAGE_2_OTHER_RISKS = 'PASSPORT_PAGE_2_OTHER_RISKS',
  PASSPORT_RESIDENCE_PLACE_OTHER_RISKS = 'PASSPORT_RESIDENCE_PLACE_OTHER_RISKS',
  IDENTIFICATION_NUMBER_OTHER_RISKS = 'IDENTIFICATION_NUMBER_OTHER_RISKS',
  DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGED_EQUIPMENT_OTHER_RISKS = 'DAMAGED_EQUIPMENT_OTHER_RISKS',
  EQUIPMENT_WARRANTY_CARD_OTHER_RISKS = 'EQUIPMENT_WARRANTY_CARD_OTHER_RISKS',
  CHARGER_AND_OTHER_COMPONENTS_OTHER_RISKS = 'CHARGER_AND_OTHER_COMPONENTS_OTHER_RISKS',
  PACKAGING_OTHER_RISKS = 'PACKAGING_OTHER_RISKS',
  AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION_OTHER_RISKS = 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION_OTHER_RISKS',
  INVOICE_OTHER_RISKS = 'INVOICE_OTHER_RISKS',
  AGENT_ACT_OTHER_RISKS = 'AGENT_ACT_OTHER_RISKS',
  REPAIRED_DEVICE_PHOTO = 'REPAIRED_DEVICE_PHOTO',
  REPAIRED_DEVICE_PHOTO_OTHER_RISKS = 'REPAIRED_DEVICE_PHOTO_OTHER_RISKS',
  FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT_OTHER_RISKS = 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT_OTHER_RISKS',
  RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS_OTHER_RISKS = 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS_OTHER_RISKS',
  AUTHORITIES_INFORMATION_OTHER_RISKS = 'AUTHORITIES_INFORMATION_OTHER_RISKS',
  OTHER_DOCUMENTS_OTHER_RISKS = 'OTHER_DOCUMENTS_OTHER_RISKS',
  TERMINATION_FISCAL_CHECK = 'TERMINATION_FISCAL_CHECK',
  DAMAGED_EQUIPMENT_PHOTO_2_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_2_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGED_EQUIPMENT_PHOTO_3_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_3_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGED_EQUIPMENT_PHOTO_4_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_4_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGED_EQUIPMENT_PHOTO_5_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_5_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGED_EQUIPMENT_PHOTO_6_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_6_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  COVID_TEST = 'COVID_TEST',
  HOSPITAL_STAY_CERTIFICATE = 'HOSPITAL_STAY_CERTIFICATE',
  DOCTORS_APPOINTMENT = 'DOCTORS_APPOINTMENT',
  EXPENSES_DOCUMENTS = 'EXPENSES_DOCUMENTS',
  PASSPORT_OR_BIRTH_CERTIFICATE = 'PASSPORT_OR_BIRTH_CERTIFICATE',
  PAYMENT_RECIPIENTS_PASSPORT = 'PAYMENT_RECIPIENTS_PASSPORT',
  PAYMENT_RECIPIENTS_CODE = 'PAYMENT_RECIPIENTS_CODE',
  MEDICAL_PRACTICE_LICENSE_COPY = 'MEDICAL_PRACTICE_LICENSE_COPY',
  DEATH_CERTIFICATE = 'DEATH_CERTIFICATE',
  CAUSE_OF_DEATH_CERTIFICATE = 'CAUSE_OF_DEATH_CERTIFICATE',
  INHERITANCE_DOCUMENT = 'INHERITANCE_DOCUMENT',
  CONTRACT_CLAIM_REPORT = 'CONTRACT_CLAIM_REPORT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  PASSPORT_INSURANCE = 'PASSPORT_INSURANCE',
  PASSPORT_INSURANCE_PAGE_2 = 'PASSPORT_INSURANCE_PAGE_2',
  PASSPORT_INSURANCE_PAGE_3 = 'PASSPORT_INSURANCE_PAGE_3',
  IDENTIFICATION_NUMBER_INSURANCE = 'IDENTIFICATION_NUMBER_INSURANCE',
  PASSPORT_DRIVER = 'PASSPORT_DRIVER',
  PASSPORT_DRIVER_PAGE_2 = 'PASSPORT_DRIVER_PAGE_2',
  PASSPORT_DRIVER_PAGE_3 = 'PASSPORT_DRIVER_PAGE_3',
  IDENTIFICATION_NUMBER_DRIVER = 'IDENTIFICATION_NUMBER_DRIVER',
  VEHICLE_REGISTRATION_CERTIFICATE = 'VEHICLE_REGISTRATION_CERTIFICATE',
  AUTHORITY_RELEVANT_CERTIFICATE = 'AUTHORITY_RELEVANT_CERTIFICATE',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  CALCULATION_SERVICE_STATION = 'CALCULATION_SERVICE_STATION',
  OWNERSHIP_DOCUMENT = 'OWNERSHIP_DOCUMENT',
  SERVICE_DEPARTMENT_DOCUMENTS = 'SERVICE_DEPARTMENT_DOCUMENTS',
  INVESTIGATION_DOCUMENT = 'INVESTIGATION_DOCUMENT',
  LIST_OF_DAMAGED_PROPERTY = 'LIST_OF_DAMAGED_PROPERTY',
  AMOUNT_OF_DAMAGES_CONFIRMATION_DOCUMENT = 'AMOUNT_OF_DAMAGES_CONFIRMATION_DOCUMENT',
  INSURANCE_CONTRACT = 'INSURANCE_CONTRACT',
  INSURED_VEHICLE_REGISTRATION_CERTIFICATE = 'INSURED_VEHICLE_REGISTRATION_CERTIFICATE',
  INSURED_DRIVER_LICENSE = 'INSURED_DRIVER_LICENSE',
  INSURED_PASSPORT_DRIVER = 'INSURED_PASSPORT_DRIVER',
  INSURED_IDENTIFICATION_NUMBER_DRIVER = 'INSURED_IDENTIFICATION_NUMBER_DRIVER',
  RECRUITMENT_ORDER = 'RECRUITMENT_ORDER',
  CASUALTY_VEHICLE_REGISTRATION_CERTIFICATE = 'CASUALTY_VEHICLE_REGISTRATION_CERTIFICATE',
  CASUALTY_DRIVER_LICENSE = 'CASUALTY_DRIVER_LICENSE',
  CASUALTY_PASSPORT_DRIVER = 'CASUALTY_PASSPORT_DRIVER',
  CASUALTY_IDENTIFICATION_NUMBER_DRIVER = 'CASUALTY_IDENTIFICATION_NUMBER_DRIVER',
  REFERENCE_FROM_THE_COMPETENT_AUTHORITIES_OF_THE_MINISTRY_OF_INTERNAL_AFFAIRS_MINISTRY_OF_EMERGENCIES_EUROPEAN_PROTOCOL_ETC = 'REFERENCE_FROM_THE_COMPETENT_AUTHORITIES_OF_THE_MINISTRY_OF_INTERNAL_AFFAIRS_MINISTRY_OF_EMERGENCIES_EUROPEAN_PROTOCOL_ETC',
  DECISION_OF_THE_ADMINISTRATIVE_COURT = 'DECISION_OF_THE_ADMINISTRATIVE_COURT',
  DOCUMENT_FROM_THE_SERVICE_STATION_EXAMINATION_CALCULATION_OF_THE_INSURER = 'DOCUMENT_FROM_THE_SERVICE_STATION_EXAMINATION_CALCULATION_OF_THE_INSURER',
  CERTIFICATE_OF_TEMPORARY_INCAPACITY_FOR_WORK_SICK_LEAVES_MEDICAL_REPORTS_AND_BILLS = 'CERTIFICATE_OF_TEMPORARY_INCAPACITY_FOR_WORK_SICK_LEAVES_MEDICAL_REPORTS_AND_BILLS',
  MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP = 'MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP',
  ORIGINAL_INHERITANCE_DOCUMENT = 'ORIGINAL_INHERITANCE_DOCUMENT',
  APPLICATION_FOR_PAYMENT_OF_THE_INSURED_CASUALTY = 'APPLICATION_FOR_PAYMENT_OF_THE_INSURED_CASUALTY',
  BENEFICIARY_LETTER = 'BENEFICIARY_LETTER',
  ACT_OF_INSPECTION = 'ACT_OF_INSPECTION',
  PHOTO_OF_A_DAMAGED_CAR = 'PHOTO_OF_A_DAMAGED_CAR',
  RESOLUTION_OF_THE_ADMINISTRATIVE_COURT_OR_RESOLUTION_ON_THE_CLOSURE_OF_CRIMINAL_PROCEEDINGS = 'RESOLUTION_OF_THE_ADMINISTRATIVE_COURT_OR_RESOLUTION_ON_THE_CLOSURE_OF_CRIMINAL_PROCEEDINGS',
  SALE_REGISTER_ANALOGUE_PHOTO = 'SALE_REGISTER_ANALOGUE_PHOTO',
  SALE_REGISTER_PHOTO_1 = 'SALE_REGISTER_PHOTO_1',
  SALE_REGISTER_PHOTO_2 = 'SALE_REGISTER_PHOTO_2',
  SALE_REGISTER_PHOTO_3 = 'SALE_REGISTER_PHOTO_3',
  SALE_REGISTER_PHOTO_4 = 'SALE_REGISTER_PHOTO_4',
  SALE_REGISTER_PHOTO_5 = 'SALE_REGISTER_PHOTO_5',
  INSURANCE_COMPANY_LOGO = 'INSURANCE_COMPANY_LOGO',
  SERVICE_CENTER_EXPERTISE = 'SERVICE_CENTER_EXPERTISE',
  PASSPORT_DMS_INSURANCE_PROGRAM = 'PASSPORT_DMS_INSURANCE_PROGRAM',
  PASSPORT_OF_PAYER_OR_OTHER_DOCUMENT = 'PASSPORT_OF_PAYER_OR_OTHER_DOCUMENT',
  REFERRALS_FOR_CONSULTATIONS = 'REFERRALS_FOR_CONSULTATIONS',
  COPIES_OF_SURVEY_OR_RESEARCH_RESULTS = 'COPIES_OF_SURVEY_OR_RESEARCH_RESULTS',
  PRESCRIPTIONS_OR_OTHER_MEDICAL_DOCUMENTS_INDICATING_THE_NAME_OF_THE_INSURED_PERSON = 'PRESCRIPTIONS_OR_OTHER_MEDICAL_DOCUMENTS_INDICATING_THE_NAME_OF_THE_INSURED_PERSON',
  EXTRACT_FROM_OUTPATIENT_CARD = 'EXTRACT_FROM_OUTPATIENT_CARD',
  EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT = 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT',
  ORIGINAL_PAYMENT_DOCUMENT = 'ORIGINAL_PAYMENT_DOCUMENT',
  ACT_OF_PERFORMED_WORKS_RENDERED_SERVICE_THAT_PROVIDED_SERVICE = 'ACT_OF_PERFORMED_WORKS_RENDERED_SERVICE_THAT_PROVIDED_SERVICE',
  LICENSE_FOR_THE_IMPLEMENTATION_OF_RELEVANT_TYPE_OF_MEDICAL_PRACTICE_EXCEPT_PUBLIC_MEDICAL_INSTITUTIONS = 'LICENSE_FOR_THE_IMPLEMENTATION_OF_RELEVANT_TYPE_OF_MEDICAL_PRACTICE_EXCEPT_PUBLIC_MEDICAL_INSTITUTIONS',
  COPY_CONTRACT = 'COPY_CONTRACT',
  ORGANIZATION_ACCOUNT = 'ORGANIZATION_ACCOUNT',
  COPY_DOCUMENT_ACCIDENT_FACT = 'COPY_DOCUMENT_ACCIDENT_FACT',
  DISABLED_CHILD = 'DISABLED_CHILD',
  PASSPORT_RUS = 'PASSPORT_RUS',
  COPY_MEDICAL_CERTIFICATE_RUS = 'COPY_MEDICAL_CERTIFICATE_RUS',
  ORGANIZATION_ACCOUNT_RUS = 'ORGANIZATION_ACCOUNT_RUS',
  INHERITANCE_DOCUMENT_RUS = 'INHERITANCE_DOCUMENT_RUS',
  EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT_RUS = 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT_RUS',
  COPY_DOCUMENT_ACCIDENT_FACT_RUS = 'COPY_DOCUMENT_ACCIDENT_FACT_RUS',
  COPY_MEDICAL_CERTIFICATE_OF_MEDICAL_SOCIAL_COMMISSION_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS = 'COPY_MEDICAL_CERTIFICATE_OF_MEDICAL_SOCIAL_COMMISSION_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS',
  COPY_MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS = 'COPY_MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS',
  OTHER_DOCUMENTS_RUS = 'OTHER_DOCUMENTS_RUS',
  CHECK_PURCHASE_MEDICINES = 'CHECK_PURCHASE_MEDICINES',
  ORIGINALS_RECIPES = 'ORIGINALS_RECIPES',
}

export interface DocumentTypeDto {
  code?: DocumentTypeDtoCode;

  /** @format int64 */
  id?: number;
  name?: string;
}

export enum Role {
  ROLE_PARTNER_SELLER = 'ROLE_PARTNER_SELLER',
  ROLE_PARTNER_MANAGER = 'ROLE_PARTNER_MANAGER',
  ROLE_PARTNER_ADMIN = 'ROLE_PARTNER_ADMIN',
  ROLE_INSURANCE_MANAGER = 'ROLE_INSURANCE_MANAGER',
  ROLE_INSURANCE_CLAIM_MANAGER = 'ROLE_INSURANCE_CLAIM_MANAGER',
  ROLE_SYSTEM_PARTNER = 'ROLE_SYSTEM_PARTNER',
  ROLE_INSURANCE_ADMIN = 'ROLE_INSURANCE_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_SYSTEM = 'ROLE_SYSTEM',
  ROLE_AGGREGATOR = 'ROLE_AGGREGATOR',
  ROLE_HR_MANAGER = 'ROLE_HR_MANAGER',
}

export interface ResetPasswordDto {
  confirmNewPassword?: string;
  newPassword?: string;
  token?: string;
}

export interface SignupAccountDto {
  confirmPassword?: string;
  email?: string;
  firstName?: string;

  /** @format int64 */
  insuranceCompanyId?: number;
  lastName?: string;
  middleName?: string;
  partner?: OptionDto;
  partnerCode?: string;

  /** @format int64 */
  partnerDepartmentId?: number;
  password?: string;
  phoneNumber?: string;
  token?: string;
}

export interface OptionDto {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface CompanyView {
  /** @format int64 */
  insuranceCompanyId?: number;
  name?: string;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  shortName?: string;
}

export interface ClientSignupAccountDto {
  confirmPassword?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  token?: string;
}

export enum PaymentResultDtoContractPaymentStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  PENDING_BALANCE = 'PENDING_BALANCE',
  PAID_OUT = 'PAID_OUT',
}

export interface PaymentResultDto {
  contractPaymentStatus?: PaymentResultDtoContractPaymentStatus;
  error?: string;
}

export interface PaymentButtonRequestDto {
  checkoutUrl?: CheckoutUrlDto;
  contractPayments?: ContractPaymentDto[];
}

export interface CheckoutUrlDto {
  checkoutUrl?: string;
  paymentId?: string;
  responseStatus?: string;
}

export interface ContractPaymentDto {
  checkNumber?: string;
  expectedPaymentAmount?: number;

  /** @format date */
  expectedPaymentDate?: string;

  /** @format int64 */
  id?: number;
  leftToPay?: number;
  orderStatus?: 'CREATED' | 'PROCESSING' | 'DECLINED' | 'APPROVED' | 'EXPIRED' | 'REVERSED';
  paymentAmount?: number;

  /** @format date */
  paymentDate?: string;
  paymentStatus?: 'FAILURE' | 'SUCCESS' | 'PENDING' | 'PENDING_BALANCE' | 'PAID_OUT';

  /** @format date */
  periodEndDate?: string;

  /** @format date */
  periodStartDate?: string;
}

export interface ResultListDtoUserActivityLogView {
  /** @format int64 */
  count?: number;
  resultList?: UserActivityLogView[];
  total?: UserActivityLogView;
}

export interface UserActivityLogView {
  action?:
    | 'LOGIN'
    | 'LOGOUT'
    | 'SIGN_WITHDRAWAL'
    | 'VERIFY_WITHDRAWAL'
    | 'SIGN_CONTRACT'
    | 'VERIFY_CONTRACT'
    | 'SIGN_EXPRESS_PAYMENT'
    | 'ORDINARY_PAYMENT'
    | 'REJECT_EXPRESS_PAYMENT'
    | 'AUTO_PAYMENT'
    | 'CREATE_NEW_ACT'
    | 'PAYMENT_CALCULATION'
    | 'PAYMENT_AWAITING'
    | 'PAYMENT'
    | 'PAYMENT_REJECTED'
    | 'ANNUL_CONTRACT_CLAIM'
    | 'RESTORE_CONTRACT_CLAIM'
    | 'EDIT_USER_CARD'
    | 'SAVE_CONTRACT'
    | 'SAVE_CLAIM'
    | 'SAVE_TERMINATION'
    | 'DEFER_EXPRESS_PAYMENT'
    | 'CREATE_SUB_AGREEMENT_ON_VERIFY_CONTRACT'
    | 'IMPORT_VISIT_UKRAINE'
    | 'SAVE_DRAFT_PROLONGATION'
    | 'SAVE_SUB_AGREEMENT'
    | 'SIGN_SUB_AGREEMENT'
    | 'SIGN_TERMINATION'
    | 'SIGN_CLAIM'
    | 'EXPORT_CONTRACT'
    | 'EXPORT_CONTRACT_CLAIM'
    | 'IMPORT_EDAK_CONTRACT_CLAIM'
    | 'REJECT_ORDINARY_PAYMENT'
    | 'SIGN_ORDINARY_PAYMENT'
    | 'UPDATE_SALE_REGISTER'
    | 'IMPORT_PARTNER_REGIONS'
    | 'NOTIFY_CLAIM_MANAGER'
    | 'REPORTED'
    | 'SEND_OFFER'
    | 'GET_SIGNED_CONTRACT'
    | 'CONTRACT_PAYMENT_FIRST_PAY_BY_PAYMENT_SUBSCRIPTION'
    | 'CONTRACT_PAYMENT_REJECT_FIRST_PAY_BY_PAYMENT_SUBSCRIPTION'
    | 'CONTRACT_PAYMENT_NEXT_PAY_BY_PAYMENT_SUBSCRIPTION'
    | 'CONTRACT_PAYMENT_REJECT_NEXT_PAY_BY_PAYMENT_SUBSCRIPTION'
    | 'CONTRACT_PAYMENT_ANNUL_CONTRACT_BY_PAYMENT_SUBSCRIPTION'
    | 'CONTRACT_PAYMENT_STOP_SUBSCRIPTION'
    | 'CREATE_PARTNER_EXTERNAL_ID'
    | 'REMOVE_PARTNER_EXTERNAL_ID';

  /** @format date-time */
  activityDate?: string;
  details?: string;

  /** @format int64 */
  entityId?: number;
  entityType?:
    | 'USER'
    | 'CONTRACT'
    | 'CONTRACT_CLAIM'
    | 'AGENT_ACT'
    | 'CONTRACT_TERMINATION'
    | 'IMPORT_HISTORY'
    | 'EXPORT_CONTRACT'
    | 'EXPORT_CLAIM'
    | 'IMPORT_CLAIM'
    | 'SALE_REGISTER'
    | 'PARTNER_REGION'
    | 'PARTNER_EXTERNAL_ID';

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;

  /** @format int64 */
  logContractId?: number;
  login?: string;

  /** @format int64 */
  partnerDepartmentId?: number;
  partnerDepartmentName?: string;

  /** @format int64 */
  partnerId?: number;
  partnerName?: string;
  role?:
    | 'ROLE_PARTNER_SELLER'
    | 'ROLE_PARTNER_MANAGER'
    | 'ROLE_PARTNER_ADMIN'
    | 'ROLE_INSURANCE_MANAGER'
    | 'ROLE_INSURANCE_CLAIM_MANAGER'
    | 'ROLE_SYSTEM_PARTNER'
    | 'ROLE_INSURANCE_ADMIN'
    | 'ROLE_ADMIN'
    | 'ROLE_CLIENT'
    | 'ROLE_SYSTEM'
    | 'ROLE_AGGREGATOR'
    | 'ROLE_HR_MANAGER';
  shortName?: string;

  /** @format int64 */
  userCounterpartyId?: number;

  /** @format int64 */
  userId?: number;
}

export interface NewAgentActContractsReportDto {
  amountToPay?: number;
  contracts?: AgentActContractDto[];

  /** @format int64 */
  count?: number;
  totalBaseFeeSum?: number;

  /** @format int64 */
  totalCount?: number;
  totalFeeSum?: number;
  totalInsurancePayments?: number;
}

export interface AgentActContractDto {
  actualPaymentAmount?: number;
  baseFeeSum?: number;
  calculatedPaymentAmount?: number;
  clientName?: string;

  /** @format date */
  conclusionDate?: string;
  contractNumber?: string;
  feePercent?: number;
  feeSum?: number;

  /** @format int64 */
  id?: number;
  paymentAmount?: number;
  paymentNumber?: string;
  returnedPaymentSum?: number;
  returnedToPartnerSum?: number;
  terminated?: boolean;
  toPay?: number;
}

export interface ResultListDtoDepartmentView {
  /** @format int64 */
  count?: number;
  resultList?: DepartmentView[];
  total?: DepartmentView;
}

export interface DepartmentView {
  /** @format date */
  activationDate?: string;
  active?: boolean;

  /** @format date */
  createdDate?: string;

  /** @format date */
  deactivationDate?: string;
  departmentNumber?: string;
  director?: string;
  email?: string;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;
  locality?: string;
  name?: string;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  partnerName?: string;
  phoneNumber?: string;
  region?: string;

  /** @format int64 */
  regionId?: number;
  street?: string;
  type?: 'SALE_POINT' | 'INTERNET_SHOP' | 'TEST_SALE_POINT';
}

export interface UserDto {
  active?: boolean;
  address?: AddressDto;
  client?: boolean;
  email?: string;

  /** @format date-time */
  endDate?: string;
  firstName?: string;

  /** @format int64 */
  id?: number;
  insuranceCompany?: OptionDto;
  insurancePrograms?: UserInsuranceProgramDto[];
  lang?: string;
  lastName?: string;
  middleName?: string;
  partner?: OptionDto;
  partnerDepartment?: OptionDto;
  partnerDepartmentRegion?: OptionDto;
  phoneNumber?: string;
  role?:
    | 'ROLE_PARTNER_SELLER'
    | 'ROLE_PARTNER_MANAGER'
    | 'ROLE_PARTNER_ADMIN'
    | 'ROLE_INSURANCE_MANAGER'
    | 'ROLE_INSURANCE_CLAIM_MANAGER'
    | 'ROLE_SYSTEM_PARTNER'
    | 'ROLE_INSURANCE_ADMIN'
    | 'ROLE_ADMIN'
    | 'ROLE_CLIENT'
    | 'ROLE_SYSTEM'
    | 'ROLE_AGGREGATOR'
    | 'ROLE_HR_MANAGER';

  /** @format date-time */
  signupDate?: string;

  /** @format date-time */
  startDate?: string;
  status?: 'PENDING' | 'ACTIVE' | 'INACTIVE';
}

export interface AddressDto {
  locality?: string;
  street?: string;
}

export interface UserInsuranceProgramDto {
  active?: boolean;

  /** @format date-time */
  endDate?: string;
  insuranceProgram?: OptionDto;
  newActive?: boolean;

  /** @format date-time */
  startDate?: string;
}

export interface ObjectSubtypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  objectType?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
  subtypeCode?: 'STRUCTURAL_ELEMENTS' | 'INTERIOR_OBJECT' | 'CIVIL_RESPONSIBILITY' | 'MOVABLE';
}

export interface ResultListDtoPartnerView {
  /** @format int64 */
  count?: number;
  resultList?: PartnerView[];
  total?: PartnerView;
}

export interface PartnerView {
  code?: string;

  /** @format int64 */
  id?: number;
  identifier?: string;

  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceCompanyName?: string;
  name?: string;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  shortName?: string;
  telegramChatId?: string;
  telegramChatLink?: string;
  type?: 'SERVICE_COMPANY' | 'INSURANCE_AGENT';
}

export interface ResultListDtoDepartmentView {
  /** @format int64 */
  count?: number;
  resultList?: DepartmentView[];
  total?: DepartmentView;
}

export interface MonthInfoDto {
  calculatedRefundSum?: StatisticsDtoBigdecimal;
  insurancePayment?: StatisticsDtoBigdecimal;
  newClientCount?: StatisticsDtoInt;

  /** @format date */
  previousStartDate?: string;
  returnedClientCount?: StatisticsDtoInt;
  terminatedContract?: StatisticsDtoInt;
}

export interface StatisticsDtoBigdecimal {
  currentValue?: number;
  previousValue?: number;
}

export interface StatisticsDtoInt {
  /** @format int32 */
  currentValue?: number;

  /** @format int32 */
  previousValue?: number;
}

export interface SalesStatisticsDtoLong {
  name?: string;
  sales?: StatisticsDtoLong;
}

export interface StatisticsDtoLong {
  /** @format int64 */
  currentValue?: number;

  /** @format int64 */
  previousValue?: number;
}

export interface SalesStatisticsDtoBigdecimal {
  name?: string;
  sales?: StatisticsDtoBigdecimal;
}

export interface ClaimPaymentsStatisticDto {
  countPayments?: StatisticsDtoLong;
  name?: string;
  sumPayments?: StatisticsDtoBigdecimal;
}

export interface ResultListDtoInsuranceCompanyView {
  /** @format int64 */
  count?: number;
  resultList?: InsuranceCompanyView[];
  total?: InsuranceCompanyView;
}

export interface InsuranceCompanyView {
  abbreviation?: string;
  contactPersonFullName?: string;

  /** @format date-time */
  createdDate?: string;
  currencyCode?: string;

  /** @format date */
  endDate?: string;
  enterpriseCode?: string;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;
  name?: string;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  phoneNumber?: string;

  /** @format date */
  startDate?: string;
}

export interface CompanyView {
  /** @format int64 */
  insuranceCompanyId?: number;
  name?: string;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  shortName?: string;
}

export interface PartnerDto {
  acquirerBank?: string;
  acquirerBankUrl?: string;

  /** @format date */
  activationDate?: string;
  active?: boolean;
  address?: AddressDto;
  code?: string;

  /** @format date */
  createdDate?: string;

  /** @format date */
  deactivationDate?: string;
  directorFullName?: string;
  enterpriseCode?: string;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceCompanyName?: string;
  name?: string;
  phoneNumber?: string;
  shortName?: string;
  type?: 'SERVICE_COMPANY' | 'INSURANCE_AGENT';
}

export interface ResultListDtoPartnerView {
  /** @format int64 */
  count?: number;
  resultList?: PartnerView[];
  total?: PartnerView;
}

export interface ResultListDtoAuthUserView {
  /** @format int64 */
  count?: number;
  resultList?: AuthUserView[];
  total?: AuthUserView;
}

export interface AuthUserView {
  client?: string;
  email?: string;

  /** @format date-time */
  endDate?: string;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceCompanyName?: string;

  /** @format date-time */
  lastLoginDate?: string;

  /** @format int64 */
  partnerDepartmentId?: number;
  partnerDepartmentName?: string;

  /** @format int64 */
  partnerId?: number;
  partnerName?: string;
  phoneNumber?: string;
  role?:
    | 'ROLE_PARTNER_SELLER'
    | 'ROLE_PARTNER_MANAGER'
    | 'ROLE_PARTNER_ADMIN'
    | 'ROLE_INSURANCE_MANAGER'
    | 'ROLE_INSURANCE_CLAIM_MANAGER'
    | 'ROLE_SYSTEM_PARTNER'
    | 'ROLE_INSURANCE_ADMIN'
    | 'ROLE_ADMIN'
    | 'ROLE_CLIENT'
    | 'ROLE_SYSTEM'
    | 'ROLE_AGGREGATOR'
    | 'ROLE_HR_MANAGER';

  /** @format date-time */
  signupDate?: string;

  /** @format date-time */
  startDate?: string;
  status?: 'PENDING' | 'ACTIVE' | 'INACTIVE';
  username?: string;
}

export interface BrandDto {
  brand?: string;

  /** @format int64 */
  id?: number;
  subtype?: string;
  type?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
}

export interface ObjectSubtypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  objectType?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
  subtypeCode?: 'STRUCTURAL_ELEMENTS' | 'INTERIOR_OBJECT' | 'CIVIL_RESPONSIBILITY' | 'MOVABLE';
}

export interface ResultListDtoBrandView {
  /** @format int64 */
  count?: number;
  resultList?: BrandView[];
  total?: BrandView;
}

export interface BrandView {
  brand?: string;

  /** @format int64 */
  id?: number;
  subtype?: string;
  type?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
}

export interface DepartmentDto {
  /** @format date */
  activationDate?: string;
  active?: boolean;
  address?: DepartmentAddressDto;

  /** @format date */
  createdDate?: string;

  /** @format date */
  deactivationDate?: string;
  departmentNumber?: string;
  departmentPrograms?: DepartmentProgramDto[];
  directorFullName?: string;
  email?: string;

  /** @format int64 */
  id?: number;
  insuranceCompany?: OptionDto;
  name?: string;
  partner?: OptionDto;
  partnerType?: 'SERVICE_COMPANY' | 'INSURANCE_AGENT';
  phoneNumber?: string;
  region?: OptionDto;
  type?: 'SALE_POINT' | 'INTERNET_SHOP' | 'TEST_SALE_POINT';
}

export interface DepartmentAddressDto {
  locality?: string;
  street?: string;
}

export interface DepartmentProgramDto {
  /** @format date-time */
  activationDate?: string;
  active?: boolean;

  /** @format date-time */
  deactivationDate?: string;

  /** @format uuid */
  externalId?: string;

  /** @format date-time */
  externalIdCreated?: string;

  /** @format int64 */
  id?: number;
  insuranceProgram?: OptionDto;
  partnerDepartment?: OptionDto;
}

export interface ResultListDtoIntegrationHistoryItemView {
  /** @format int64 */
  count?: number;
  resultList?: IntegrationHistoryItemView[];
  total?: IntegrationHistoryItemView;
}

export interface IntegrationHistoryItemView {
  details?: string;

  /** @format int64 */
  historyId?: number;

  /** @format int64 */
  id?: number;
  message?: string;

  /** @format date-time */
  operationDate?: string;

  /** @format int64 */
  packageNumber?: number;
  success?: boolean;
  type?: 'IMPORT_VISIT_UKRAINE' | 'EXPORT_CONTRACT_1C' | 'EXPORT_CLAIM_1C' | 'IMPORT_EDAK' | 'EXPORT_EDAK';
}

export interface ExpressPaymentDto {
  /** @format date-time */
  claimDate?: string;
  claimNumber?: string;

  /** @format date-time */
  contractDate?: string;
  contractNumber?: string;
  insuranceObject?: string;
  paymentRecipient?: PaymentRecipientDto;
  paymentSum?: number;
  risk?: string;
}

export interface PaymentRecipientDto {
  accountNumber?: string;
  accountType?: 'CARD' | 'IBAN' | 'CASH_DESK';
  bankName?: string;
  code?: string;
  companyName?: string;
  counterpartyType?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';
  firstName?: string;

  /** @format int64 */
  id?: number;
  lastName?: string;
  locality?: string;
  middleName?: string;
  phoneNumber?: string;
  street?: string;
  type?: 'CLIENT' | 'SERVICE_CENTER' | 'CRS';
}

export interface ValidationErrorDto {
  code?: string;
  field?: string;
}

export interface CurrentUserDto {
  checkNumberPrefix?: string;

  /** @format int64 */
  id?: number;
  insuranceCompanyCurrency?: string;
  insuranceCompanyName?: string;
  lang?: string;
  locality?: string;
  login?: string;
  managerPhoneNumber?: string;
  name?: string;
  partnerDepartmentName?: string;
  partnerName?: string;
  phone?: string;
  role?:
    | 'ROLE_PARTNER_SELLER'
    | 'ROLE_PARTNER_MANAGER'
    | 'ROLE_PARTNER_ADMIN'
    | 'ROLE_INSURANCE_MANAGER'
    | 'ROLE_INSURANCE_CLAIM_MANAGER'
    | 'ROLE_SYSTEM_PARTNER'
    | 'ROLE_INSURANCE_ADMIN'
    | 'ROLE_ADMIN'
    | 'ROLE_CLIENT'
    | 'ROLE_SYSTEM'
    | 'ROLE_AGGREGATOR'
    | 'ROLE_HR_MANAGER';
  street?: string;
  telegramChatLink?: string;
}

export interface PartnerRegionDto {
  /** @format date */
  activationDate?: string;
  creatorName?: string;

  /** @format date */
  deactivationDate?: string;

  /** @format int64 */
  id?: number;
  name?: string;

  /** @format date */
  registrationDate?: string;
}

export interface PartnerProgramDto {
  /** @format date */
  activationDate?: string;
  aggregationEnabled?: boolean;

  /** @format int64 */
  aggregatorRootPartnerId?: number;

  /** @format date */
  deactivationDate?: string;

  /** @format int32 */
  expressAutoPaymentTimeoutInMin?: number;

  /** @format int32 */
  expressPaymentWorkingDays?: number;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;

  /** @format int64 */
  partnerId?: number;
  partnerProgramAgents?: PartnerProgramAgentDto[];
}

export interface PartnerProgramAgentDto {
  /** @format date */
  activationDate?: string;
  addedOnProgram?: boolean;

  /** @format int64 */
  agentId?: number;

  /** @format date */
  deactivationDate?: string;
  depreciationAdjustmentRate?: number;
  feePercent?: number;

  /** @format int64 */
  id?: number;
  insuranceAdjustmentRate?: number;
  name?: string;
}

export interface InsuranceProgramDto {
  active?: boolean;
  agentEmail?: string;
  agentName?: string;
  agentPhoneNumber?: string;
  businessExpensesPercent?: number;
  claimManagerEmail?: string;
  claimManagerName?: string;
  claimManagerPhoneNumber?: string;
  code?: string;
  contractType?: string;

  /** @format date */
  createdDate?: string;

  /** @format date */
  endDate?: string;

  /** @format int64 */
  id?: number;
  installmentPaymentPeriodTypes?: ('ONE_PAYMENT' | 'MONTHLY' | 'QUARTERLY' | 'SEMIANNUALLY')[];
  insuranceCompany?: string;
  name?: string;
  objectConfiguration?: object;
  programRisks?: ProgramRiskDto[];

  /** @format date */
  startDate?: string;
}

export interface ProgramRiskDto {
  expressPaymentAvailable?: boolean;
  franchise?: number;
  liabilityLimit?: number;
  risk?: OptionDto;
  rule?: OptionDto;
  sharePercent?: number;
}

export interface AgentDto {
  address?: AddressDto;

  /** @format date-time */
  agentContractDate?: string;
  agentContractNumber?: string;
  companyName?: string;
  counterpartyType?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';
  email?: string;
  enterpriseCode?: string;
  firstName?: string;

  /** @format int64 */
  id?: number;
  lastName?: string;
  middleName?: string;
  name?: string;

  /** @format int64 */
  partnerId?: number;
  phoneNumber?: string;
  visible?: boolean;
}

export interface InsuranceCompanyDto {
  abbreviation?: string;
  bankEnterpriseCode?: string;
  bankName?: string;
  bankPostAddress?: string;
  claimManager?: CounterpartyDto;
  code?: string;
  contactPerson?: CounterpartyDto;
  contactPersonFullName?: string;

  /** @format date-time */
  createdDate?: string;
  currency?: CurrencyDto;
  customerSupportEmail?: string;
  customerSupportPhoneNumber?: string;
  customerSupportUrl?: string;
  directorFullName?: string;
  directorPosition?: string;
  email?: string;

  /** @format date */
  endDate?: string;
  enterpriseCode?: string;
  fileStorage?: FileStorageDto;
  fullName?: string;
  ibanAccountNumber?: string;

  /** @format int64 */
  id?: number;
  insuranceManager?: CounterpartyDto;
  name?: string;
  partnerSupportPhoneNumber?: string;
  phoneNumber?: string;
  postAddress?: string;
  readOnlyFields?: string[];
  shortName?: string;
  siteUrl?: string;
  smsSenderName?: string;

  /** @format date */
  startDate?: string;
  telegramChannel?: string;
}

export interface CurrencyDto {
  code?: string;
  name?: string;
}

export interface CounterpartyDto {
  email?: string;

  /** @format int64 */
  id?: number;
  locality?: string;
  name?: string;
  phoneNumber?: string;
  street?: string;
  type?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';
}

export interface FileStorageDto {
  documentTypeCode?:
    | 'PASSPORT'
    | 'PASSPORT_PAGE_2'
    | 'PASSPORT_PAGE_3'
    | 'PASSPORT_RESIDENCE_PLACE'
    | 'IDENTIFICATION_NUMBER'
    | 'AUTHORITIES_INFORMATION'
    | 'SERVICE_CENTER_CONCLUSION'
    | 'INVOICE'
    | 'AGENT_ACT'
    | 'FISCAL_CHECK'
    | 'EQUIPMENT_WARRANTY_CARD'
    | 'DAMAGED_EQUIPMENT_PHOTO'
    | 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS'
    | 'EXTRACT_FROM_UNIFIED_REGISTER_PRE_TRIAL_INVESTIGATIONS'
    | 'RESOLUTION_ON_CLOSING_CRIMINAL_PROCEEDINGS_WITH_INDICATION_GUILTY_PERSONSSIGNS_ILLEGAL_ACTIONS'
    | 'COURT_DECISION_TO_ESTABLISH_GUILT_OF_PERSON_WHO_CAUSED_DAMAGE'
    | 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID'
    | 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION'
    | 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT'
    | 'DOCUMENTS_WITH_IMEI'
    | 'INSURANCE_FISCAL_CHECK'
    | 'PACKAGING'
    | 'DAMAGED_EQUIPMENT'
    | 'SERVICE_CENTER_ACCOUNT'
    | 'CHARGER_AND_OTHER_COMPONENTS'
    | 'OTHER_DOCUMENTS'
    | 'FISCAL_CHECK_OTHER_RISKS'
    | 'INSURANCE_FISCAL_CHECK_OTHER_RISKS'
    | 'PASSPORT_OTHER_RISKS'
    | 'PASSPORT_PAGE_2_OTHER_RISKS'
    | 'PASSPORT_RESIDENCE_PLACE_OTHER_RISKS'
    | 'IDENTIFICATION_NUMBER_OTHER_RISKS'
    | 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS'
    | 'DAMAGED_EQUIPMENT_OTHER_RISKS'
    | 'EQUIPMENT_WARRANTY_CARD_OTHER_RISKS'
    | 'CHARGER_AND_OTHER_COMPONENTS_OTHER_RISKS'
    | 'PACKAGING_OTHER_RISKS'
    | 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION_OTHER_RISKS'
    | 'INVOICE_OTHER_RISKS'
    | 'AGENT_ACT_OTHER_RISKS'
    | 'REPAIRED_DEVICE_PHOTO'
    | 'REPAIRED_DEVICE_PHOTO_OTHER_RISKS'
    | 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT_OTHER_RISKS'
    | 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS_OTHER_RISKS'
    | 'AUTHORITIES_INFORMATION_OTHER_RISKS'
    | 'OTHER_DOCUMENTS_OTHER_RISKS'
    | 'TERMINATION_FISCAL_CHECK'
    | 'DAMAGED_EQUIPMENT_PHOTO_2_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS'
    | 'DAMAGED_EQUIPMENT_PHOTO_3_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS'
    | 'DAMAGED_EQUIPMENT_PHOTO_4_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS'
    | 'DAMAGED_EQUIPMENT_PHOTO_5_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS'
    | 'DAMAGED_EQUIPMENT_PHOTO_6_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS'
    | 'COVID_TEST'
    | 'HOSPITAL_STAY_CERTIFICATE'
    | 'DOCTORS_APPOINTMENT'
    | 'EXPENSES_DOCUMENTS'
    | 'PASSPORT_OR_BIRTH_CERTIFICATE'
    | 'PAYMENT_RECIPIENTS_PASSPORT'
    | 'PAYMENT_RECIPIENTS_CODE'
    | 'MEDICAL_PRACTICE_LICENSE_COPY'
    | 'DEATH_CERTIFICATE'
    | 'CAUSE_OF_DEATH_CERTIFICATE'
    | 'INHERITANCE_DOCUMENT'
    | 'CONTRACT_CLAIM_REPORT'
    | 'QUESTIONNAIRE'
    | 'PASSPORT_INSURANCE'
    | 'PASSPORT_INSURANCE_PAGE_2'
    | 'PASSPORT_INSURANCE_PAGE_3'
    | 'IDENTIFICATION_NUMBER_INSURANCE'
    | 'PASSPORT_DRIVER'
    | 'PASSPORT_DRIVER_PAGE_2'
    | 'PASSPORT_DRIVER_PAGE_3'
    | 'IDENTIFICATION_NUMBER_DRIVER'
    | 'VEHICLE_REGISTRATION_CERTIFICATE'
    | 'AUTHORITY_RELEVANT_CERTIFICATE'
    | 'DRIVER_LICENSE'
    | 'CALCULATION_SERVICE_STATION'
    | 'OWNERSHIP_DOCUMENT'
    | 'SERVICE_DEPARTMENT_DOCUMENTS'
    | 'INVESTIGATION_DOCUMENT'
    | 'LIST_OF_DAMAGED_PROPERTY'
    | 'AMOUNT_OF_DAMAGES_CONFIRMATION_DOCUMENT'
    | 'INSURANCE_CONTRACT'
    | 'INSURED_VEHICLE_REGISTRATION_CERTIFICATE'
    | 'INSURED_DRIVER_LICENSE'
    | 'INSURED_PASSPORT_DRIVER'
    | 'INSURED_IDENTIFICATION_NUMBER_DRIVER'
    | 'RECRUITMENT_ORDER'
    | 'CASUALTY_VEHICLE_REGISTRATION_CERTIFICATE'
    | 'CASUALTY_DRIVER_LICENSE'
    | 'CASUALTY_PASSPORT_DRIVER'
    | 'CASUALTY_IDENTIFICATION_NUMBER_DRIVER'
    | 'REFERENCE_FROM_THE_COMPETENT_AUTHORITIES_OF_THE_MINISTRY_OF_INTERNAL_AFFAIRS_MINISTRY_OF_EMERGENCIES_EUROPEAN_PROTOCOL_ETC'
    | 'DECISION_OF_THE_ADMINISTRATIVE_COURT'
    | 'DOCUMENT_FROM_THE_SERVICE_STATION_EXAMINATION_CALCULATION_OF_THE_INSURER'
    | 'CERTIFICATE_OF_TEMPORARY_INCAPACITY_FOR_WORK_SICK_LEAVES_MEDICAL_REPORTS_AND_BILLS'
    | 'MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP'
    | 'ORIGINAL_INHERITANCE_DOCUMENT'
    | 'APPLICATION_FOR_PAYMENT_OF_THE_INSURED_CASUALTY'
    | 'BENEFICIARY_LETTER'
    | 'ACT_OF_INSPECTION'
    | 'PHOTO_OF_A_DAMAGED_CAR'
    | 'RESOLUTION_OF_THE_ADMINISTRATIVE_COURT_OR_RESOLUTION_ON_THE_CLOSURE_OF_CRIMINAL_PROCEEDINGS'
    | 'SALE_REGISTER_ANALOGUE_PHOTO'
    | 'SALE_REGISTER_PHOTO_1'
    | 'SALE_REGISTER_PHOTO_2'
    | 'SALE_REGISTER_PHOTO_3'
    | 'SALE_REGISTER_PHOTO_4'
    | 'SALE_REGISTER_PHOTO_5'
    | 'INSURANCE_COMPANY_LOGO'
    | 'SERVICE_CENTER_EXPERTISE'
    | 'PASSPORT_DMS_INSURANCE_PROGRAM'
    | 'PASSPORT_OF_PAYER_OR_OTHER_DOCUMENT'
    | 'REFERRALS_FOR_CONSULTATIONS'
    | 'COPIES_OF_SURVEY_OR_RESEARCH_RESULTS'
    | 'PRESCRIPTIONS_OR_OTHER_MEDICAL_DOCUMENTS_INDICATING_THE_NAME_OF_THE_INSURED_PERSON'
    | 'EXTRACT_FROM_OUTPATIENT_CARD'
    | 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT'
    | 'ORIGINAL_PAYMENT_DOCUMENT'
    | 'ACT_OF_PERFORMED_WORKS_RENDERED_SERVICE_THAT_PROVIDED_SERVICE'
    | 'LICENSE_FOR_THE_IMPLEMENTATION_OF_RELEVANT_TYPE_OF_MEDICAL_PRACTICE_EXCEPT_PUBLIC_MEDICAL_INSTITUTIONS'
    | 'COPY_CONTRACT'
    | 'ORGANIZATION_ACCOUNT'
    | 'COPY_DOCUMENT_ACCIDENT_FACT'
    | 'DISABLED_CHILD'
    | 'PASSPORT_RUS'
    | 'COPY_MEDICAL_CERTIFICATE_RUS'
    | 'ORGANIZATION_ACCOUNT_RUS'
    | 'INHERITANCE_DOCUMENT_RUS'
    | 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT_RUS'
    | 'COPY_DOCUMENT_ACCIDENT_FACT_RUS'
    | 'COPY_MEDICAL_CERTIFICATE_OF_MEDICAL_SOCIAL_COMMISSION_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS'
    | 'COPY_MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS'
    | 'OTHER_DOCUMENTS_RUS'
    | 'CHECK_PURCHASE_MEDICINES'
    | 'ORIGINALS_RECIPES';

  /** @format int64 */
  storageFileTotalPage?: number;
  storageFiles?: StorageFileDto[];
  type?:
    | 'CONTRACT_CLAIM_DOCUMENT'
    | 'CONTRACT_TERMINATION_DOCUMENT'
    | 'ML_EXPORT_DOCUMENT'
    | 'SALE_REGISTER_DOCUMENT'
    | 'INSURANCE_COMPANY_LOGO'
    | 'INSURANCE_PROGRAM_DOCUMENT';
}

export interface StorageFileDto {
  /** @format date-time */
  createdDate?: string;
  fileName?: string;
  fileWrapper?: FileWrapperDto;
  key?: string;
}

export interface FileWrapperDto {
  contentType?: string;

  /** @format byte */
  data?: string;
  filename?: string;
}

export interface IntegrationPackageInformationDto {
  /** @format int64 */
  countToTransfer?: number;

  /** @format int64 */
  id?: number;
  status?: 'INIT' | 'LOADING' | 'LOADED' | 'ERROR';

  /** @format int64 */
  transferred?: number;
}

export interface ResultListDtoIntegrationItemView {
  /** @format int64 */
  count?: number;
  resultList?: IntegrationItemView[];
  total?: IntegrationItemView;
}

export interface IntegrationItemView {
  /** @format int32 */
  entitiesToTransferCount?: number;

  /** @format int32 */
  failedCount?: number;

  /** @format int64 */
  id?: number;
  initiator?: string;

  /** @format int64 */
  insuranceProductId?: number;
  insuranceProductName?: string;

  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;

  /** @format date */
  operationDate?: string;

  /** @format int64 */
  packageNumber?: number;

  /** @format int64 */
  partnerId?: number;
  partnerName?: string;

  /** @format int32 */
  successCount?: number;
  transferType?: 'CONTRACTS' | 'CLAIMS';
  type?: 'IMPORT_VISIT_UKRAINE' | 'EXPORT_CONTRACT_1C' | 'EXPORT_CLAIM_1C' | 'IMPORT_EDAK' | 'EXPORT_EDAK';
}

export interface IntegrationItemCountDto {
  /** @format int32 */
  count?: number;
}

export interface ResultListDtoContractView {
  /** @format int64 */
  count?: number;
  resultList?: ContractView[];
  total?: ContractView;
}

export interface ContractView {
  beneficiaryLocality?: string;
  beneficiaryName?: string;
  beneficiaryPhoneNumber?: string;
  beneficiaryStreet?: string;
  beneficiaryType?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';
  calculatedPaymentAmount?: number;
  check_number?: string;
  clientLocality?: string;
  clientName?: string;
  clientStreet?: string;
  clientType?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';

  /** @format date */
  conclusionDate?: string;

  /** @format int64 */
  contractTerminationId?: number;
  currencyCode?: string;
  current?: boolean;

  /** @format date */
  endDate?: string;

  /** @format int64 */
  id?: number;
  insuranceAmount?: number;

  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceObjectName?: string;
  insuranceObjectPid?: string;
  insuranceObjectSubtype?: string;
  insuranceObjectType?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
  insuranceProductCode?: string;

  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;
  number?: string;

  /** @format int32 */
  numberPayments?: number;

  /** @format int64 */
  originalContractId?: number;
  originalContractNumber?: string;
  participateInAgentAct?: boolean;

  /** @format int64 */
  partnerCompanyId?: number;

  /** @format int64 */
  partnerDepartmentId?: number;
  partnerDepartmentRegion?: string;

  /** @format int64 */
  partnerId?: number;
  paymentAmount?: number;
  phoneNumber?: string;

  /** @format date */
  saleDate?: string;
  saleSum?: number;
  sellerName?: string;
  sellerPhoneNumber?: string;

  /** @format date */
  startDate?: string;
  status?: 'DRAFT' | 'ACTIVE' | 'FINISHED';

  /** @format date */
  subAgreementConclusionDate?: string;

  /** @format date */
  subAgreementEndDate?: string;

  /** @format int32 */
  subAgreementNumber?: number;

  /** @format date */
  subAgreementStartDate?: string;
  test?: boolean;

  /** @format date */
  verifiedAt?: string;
}

export interface ResultListDtoContractTerminationView {
  /** @format int64 */
  count?: number;
  resultList?: ContractTerminationView[];
  total?: ContractTerminationView;
}

export interface ContractTerminationView {
  claimPaymentSum?: number;

  /** @format date */
  contractDateFrom?: string;

  /** @format date */
  contractDateTo?: string;

  /** @format int64 */
  contractId?: number;
  contractNumber?: string;
  currency?: string;

  /** @format int32 */
  duration?: number;
  initiator?: string;

  /** @format int64 */
  insuranceCompanyId?: number;

  /** @format int64 */
  insuranceProgramId?: number;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;

  /** @format int64 */
  realDuration?: number;

  /** @format date */
  realEndDate?: string;
  refundSum?: number;

  /** @format date */
  signDate?: string;
  signed?: boolean;
  status?: 'DRAFT' | 'ACTIVE' | 'FINISHED';

  /** @format date */
  terminationDate?: string;

  /** @format int64 */
  terminationId?: number;
  terminationNumber?: string;
}

export interface ContractTerminationDto {
  cause?: TerminationCauseDto;
  contract?: TerminationContractDto;
  contractStatus?: 'DRAFT' | 'ACTIVE' | 'FINISHED';
  currency?: string;
  documents?: ContractTerminationDocumentDto[];

  /** @format date */
  estimatedRealEndDate?: string;

  /** @format int64 */
  id?: number;
  initiator?: TerminationInitiatorDto;

  /** @format date */
  realEndDate?: string;

  /** @format int32 */
  realTerm?: number;
  recipient?: PaymentRecipientDto;
  refundSum?: number;

  /** @format date */
  signDate?: string;

  /** @format date */
  terminationDate?: string;
  terminationNumber?: string;
}

export interface TerminationContractDto {
  client?: string;

  /** @format date */
  conclusionDate?: string;
  contractNumber?: string;

  /** @format date */
  endDate?: string;

  /** @format int64 */
  id?: number;
  insuranceProgram?: string;
  insuranceProgramCode?: string;

  /** @format int64 */
  insuranceProgramId?: number;
  insuranceSum?: number;
  insuranceSumCurrency?: string;
  paymentAmount?: number;

  /** @format date */
  startDate?: string;
}

export interface ContractTerminationDocumentDto {
  attachment?: boolean;
  documentType?: DocumentTypeDto;

  /** @format int64 */
  id?: number;
  information?: string;
  informationObligatory?: boolean;
  obligatory?: boolean;

  /** @format date-time */
  receiptDate?: string;
  refundSum?: number;

  /** @format date-time */
  signDate?: string;

  /** @format int64 */
  terminationId?: number;
}

export interface ObjectSubtypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  objectType?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
  subtypeCode?: 'STRUCTURAL_ELEMENTS' | 'INTERIOR_OBJECT' | 'CIVIL_RESPONSIBILITY' | 'MOVABLE';
}

export interface ContractIntegrationHistoryDto {
  integrationType?: 'IMPORT_VISIT_UKRAINE' | 'EXPORT_CONTRACT_1C' | 'EXPORT_CLAIM_1C' | 'IMPORT_EDAK' | 'EXPORT_EDAK';
  message?: string;

  /** @format date-time */
  operationDate?: string;

  /** @format int64 */
  packageNumber?: number;
  success?: boolean;
}

export interface ContractInstallmentPaymentDto {
  installmentPaymentPeriodType?: 'ONE_PAYMENT' | 'MONTHLY' | 'QUARTERLY' | 'SEMIANNUALLY';
}

export interface ContractDto {
  /** @format int64 */
  beneficiaryId?: number;
  calculatedInsuranceRate?: number;
  calculatedPaymentAmount?: number;

  /** @format int64 */
  clientId?: number;
  clientSigned?: boolean;

  /** @format date-time */
  conclusionDate?: string;
  contractBeneficiary?: ContractBeneficiaryDto;
  contractInfo?: object;
  contractInstallmentPayment?: ContractInstallmentPaymentDto;
  contractNumber?: string;
  contractNumberEnding?: string;
  contractNumberMainPart?: string;
  contractNumberPrefix?: string;
  contractObjects?: ContractObjectDto[];

  /** @format int32 */
  contractObjectsCount?: number;
  current?: boolean;

  /** @format int32 */
  duration?: number;
  durationUnit?:
    | 'Nanos'
    | 'Micros'
    | 'Millis'
    | 'Seconds'
    | 'Minutes'
    | 'Hours'
    | 'HalfDays'
    | 'Days'
    | 'Weeks'
    | 'Months'
    | 'Years'
    | 'Decades'
    | 'Centuries'
    | 'Millennia'
    | 'Eras'
    | 'Forever';

  /** @format date-time */
  endDate?: string;

  /** @format uuid */
  externalId?: string;
  hasClaims?: boolean;
  hasDraftSubAgreement?: boolean;

  /** @format int64 */
  id?: number;
  installmentPayment?: boolean;

  /** @format int64 */
  insuranceCompanyId?: number;
  insurancePolicy?: OptionDto;
  insuranceProductCode?: string;
  insuranceProgram?: OptionDto;
  insuranceRate?: number;
  insuranceSum?: number;
  insuranceSumCurrency?: string;

  /** @format int64 */
  insuranceSumId?: number;
  needClientSign?: boolean;

  /** @format int64 */
  originalContractId?: number;
  originalContractNumber?: string;

  /** @format date-time */
  originalContractSignDate?: string;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  paymentAmount?: number;
  paymentAmountCurrency?: string;
  paymentStatus?: 'UNPAID' | 'PARTIALLY_PAID' | 'PAID';
  programChanging?: boolean;

  /** @format int64 */
  prolongateFromId?: number;
  prolongated?: boolean;
  prolongation?: boolean;

  /** @format date-time */
  prolongationConclusionDate?: string;

  /** @format date-time */
  prolongationEndDate?: string;

  /** @format date-time */
  prolongationStartDate?: string;
  sellerName?: string;
  sellerPhoneNumber?: string;

  /** @format date-time */
  sentForSigningDate?: string;

  /** @format date-time */
  signDate?: string;
  signatory?: SignatoryDto;

  /** @format date-time */
  startDate?: string;
  status?: 'DRAFT' | 'ACTIVE' | 'FINISHED';

  /** @format date-time */
  subAgreementConclusionDate?: string;

  /** @format date-time */
  subAgreementEndDate?: string;

  /** @format int32 */
  subAgreementNumber?: number;

  /** @format date-time */
  subAgreementStartDate?: string;

  /** @format int64 */
  terminationId?: number;

  /** @format date-time */
  verifiedAt?: string;
}

export interface SignatoryDto {
  /** @format date */
  birthday?: string;
  citizenship?: string;
  companyName?: string;
  counterpartyType?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';
  email?: string;
  firstName?: string;
  inn?: string;
  insuranceProgramCode?: string;
  lastName?: string;
  locality?: string;
  middleName?: string;
  passport?: string;
  phoneNumber?: string;
  pinfl?: string;
  street?: string;
  type?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';
}

export interface ContractObjectDto {
  /** @format int64 */
  id?: number;
  pid?: string;
  risks?: ContractObjectRiskDto[];
  type?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
}

export interface ContractObjectRiskDto {
  calculatedPaymentAmount?: number;
  franchise?: number;
  franchiseValueType?: 'PERCENT' | 'FIXED_SUM' | 'NO_VALUE';

  /** @format int64 */
  id?: number;
  insuranceAmount?: number;
  name?: string;
  paymentAmount?: number;

  /** @format int32 */
  riskNumber?: number;
}

export interface ContractBeneficiaryDto {
  citizenship?: string;
  companyName?: string;
  counterpartyType?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';

  /** @format date */
  dateBirthday?: string;
  firstName?: string;

  /** @format date */
  idCardDateCreate?: string;
  idCardNumber?: string;
  idCardRecord?: string;
  identifyDocumentType?: 'ID_CARD' | 'PASSPORT';
  innCode?: string;
  insuranceProgramCode?: string;
  investContract?: string;

  /** @format date */
  investContractFrom?: string;
  lastName?: string;
  locality?: string;
  middleName?: string;
  passportCreator?: string;
  passportNumber?: string;
  passportSeries?: string;
  phoneNumber?: string;
  street?: string;
  type?: 'PERSON' | 'ENTREPRENEUR' | 'COMPANY';
}

export interface ContractConfigurationDto {
  contractNumberEnding?: string;
}

export interface WithdrawVerifyWarningsDto {
  warnings?: string[];
}

export interface AgentContractDto {
  /** @format date-time */
  contractDate?: string;
  contractNumber?: string;

  /** @format int64 */
  id?: number;
}

export interface InsuranceObjectDto {
  /** @format int64 */
  id?: number;
  name?: string;
  objectSubtypeName?: string;
  pid?: string;
  type?: 'ELECTRONIC_DEVICE' | 'PERSON' | 'VEHICLE' | 'ESTATE';
}

export interface DeviceDetailsWrapperDto {
  deviceDetails?: DeviceDetailsDto;
  status?: 'SUCCESS' | 'AUTOCOMPLETE_DISABLED' | 'IMEI_NOT_FOUND' | 'INVALID_IMEI' | 'RATE_LIMIT_EXCEEDED';
}

export interface DeviceDetailsDto {
  brand?: string;
  model?: string;
}

export interface ResultListDtoContractPaymentView {
  /** @format int64 */
  count?: number;
  resultList?: ContractPaymentView[];
  total?: ContractPaymentView;
}

export interface ContractPaymentView {
  checkNumber?: string;

  /** @format int64 */
  contractId?: number;
  expectedPaymentAmount?: number;

  /** @format date */
  expectedPaymentDate?: string;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;
  leftToPay?: number;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  paymentAmount?: number;

  /** @format date */
  paymentDate?: string;

  /** @format date */
  periodEndDate?: string;

  /** @format date */
  periodStartDate?: string;
}

export interface ContractClaimDetailsDto {
  /** @format date-time */
  claimDate?: string;

  /** @format int64 */
  claimId?: number;
  claimNumber?: string;
  claimStatus?:
    | 'REPORTED'
    | 'DOCUMENTS_COLLECTION'
    | 'PAYMENT_CALCULATION'
    | 'PAYMENT_AWAITING'
    | 'PAYMENT'
    | 'PAYMENT_REJECTED'
    | 'ANNUL';
  claimSum?: number;
  expectedLoss?: number;
  franchise?: number;
  franchiseType?: string;
  insuranceObject?: string;

  /** @format date-time */
  paymentDate?: string;
  paymentSum?: number;
  risk?: string;
}

export interface ContractTerminationDetailsDto {
  calculatedRefundSum?: number;
  cause?: string;

  /** @format int64 */
  id?: number;

  /** @format date-time */
  realEndDate?: string;

  /** @format int32 */
  realTerm?: number;

  /** @format date-time */
  signDate?: string;

  /** @format date-time */
  terminationDate?: string;
  terminationNumber?: string;
}

export interface ResultListDtoAgentActView {
  /** @format int64 */
  count?: number;
  resultList?: AgentActView[];
  total?: AgentActView;
}

export interface AgentActView {
  actualPaymentAmount?: number;

  /** @format int64 */
  agentActId?: number;

  /** @format int64 */
  agentContractId?: number;
  agentContractNumber?: string;
  baseFeeSum?: number;
  calculatedPaymentAmount?: number;
  clientName?: string;

  /** @format date */
  conclusionDate?: string;
  contractNumber?: string;
  currencyCode?: string;

  /** @format date */
  endDate?: string;
  feePercent?: number;
  feeSum?: number;

  /** @format date */
  filterDate?: string;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;

  /** @format int64 */
  insuranceProgramId?: number;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;
  paymentAmount?: number;
  paymentNumber?: string;
  returnedPayment?: number;
  returnedToPartner?: number;
  status?: 'DRAFT' | 'ACTIVE' | 'FINISHED';
  terminated?: boolean;
  toPay?: number;
}

export interface AgentActInfoDto {
  /** @format date */
  actDate?: string;

  /** @format date-time */
  actSignDate?: string;
  actualFeePercent?: number;
  agentActNumber?: string;

  /** @format date-time */
  agentContractDate?: string;

  /** @format int64 */
  agentContractId?: number;
  agentContractNumber?: string;
  baseFeeSum?: number;
  contractCalculatedPaymentAmount?: number;
  contractPaymentAmount?: number;

  /** @format int64 */
  contractsCount?: number;

  /** @format date */
  dateFrom?: string;

  /** @format date */
  dateTo?: string;
  feeSum?: number;

  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;

  /** @format int64 */
  partnerId?: number;
  partnerName?: string;
  periodFeeSum?: number;
  returnedPaymentSum?: number;
  returnedToPartnerSum?: number;

  /** @format int64 */
  terminatedContractsCount?: number;
}

export interface AgentActsDto {
  acts?: AgentActsView[];
  statistic?: AgentActStatisticsDto;

  /** @format int64 */
  totalRecords?: number;
}

export interface AgentActStatisticsDto {
  reportBaseFeeSum?: number;

  /** @format int64 */
  reportCountContracts?: number;

  /** @format int64 */
  reportCountPaidContracts?: number;

  /** @format int32 */
  reportRecords?: number;
  reportSumFee?: number;
  reportSumInsurancePayments?: number;
  reportSumPaid?: number;
  reportSumToPay?: number;
  totalBaseFeeSum?: number;

  /** @format int32 */
  totalRecords?: number;
  totalSumFee?: number;
  totalSumInsurancePayments?: number;
  totalSumToPay?: number;
}

export interface AgentActsView {
  /** @format date */
  actDate?: string;
  actNumber?: string;
  actSum?: number;

  /** @format int64 */
  agentContractId?: number;

  /** @format int64 */
  agentId?: number;
  baseFeeSum?: number;

  /** @format date */
  confirmationDate?: string;

  /** @format int64 */
  countContracts?: number;

  /** @format int64 */
  countPaidContracts?: number;

  /** @format date */
  dateFrom?: string;

  /** @format date */
  dateTo?: string;
  feeSum?: number;

  /** @format int64 */
  id?: number;

  /** @format int64 */
  insuranceCompanyId?: number;

  /** @format int64 */
  insuranceProgramId?: number;
  paid?: number;

  /** @format int64 */
  partnerDepartmentId?: number;

  /** @format int64 */
  partnerId?: number;

  /** @format date */
  paymentDate?: string;
  paymentDocumentNumber?: string;
  sumInsurancePayments?: number;
}

export interface AgentActDto {
  /** @format date-time */
  actDate?: string;
  actNumber?: string;

  /** @format date-time */
  confirmationDate?: string;

  /** @format int64 */
  countContracts?: number;

  /** @format int64 */
  countPaidContracts?: number;

  /** @format date-time */
  dateFrom?: string;

  /** @format date-time */
  dateTo?: string;
  documentPaymentNumber?: string;

  /** @format int64 */
  id?: number;

  /** @format date-time */
  paymentDate?: string;
  sumFee?: number;
  sumInsurancePayments?: number;
  sumPaid?: number;
  sumToPay?: number;
}

export interface ResetPasswordRequestDto {
  login?: string;
}

export interface SignupAccountBaseInfoDto {
  email?: string;
  partnerCode?: string;
  phoneNumber?: string;
  token?: string;
}

export interface DashboardInfoDto {
  currencyCode?: string;
  daysStatistics?: DaysStatisticsDto[];
  departmentStatistics?: PartnerDepartmentStatisticsDto[];
  partnerStatistics?: PartnerStatisticsDto[];
  programStatistics?: InsuranceProgramStatisticsDto[];
  risksStatistics?: ProductRiskStatisticsDto[];
  topDashboardInfoDto?: TopDashboardInfoDto;
}

export interface TopDashboardInfoDto {
  agentsPaymentsSum?: number;
  claimsPaymentsSum?: number;
  contractsPaymentsSum?: number;

  /** @format int64 */
  countClaims?: number;

  /** @format int64 */
  countContracts?: number;

  /** @format int32 */
  countTerminations?: number;
  terminationsPaymentsSum?: number;
}

export interface ProductRiskStatisticsDto {
  productCode?: string;

  /** @format int64 */
  productId?: number;
  productName?: string;
  riskStatisticsDtoList?: RiskStatisticsDto[];
}

export interface RiskStatisticsDto {
  claimRiskPaymentSum?: number;
  contractRiskPaymentSum?: number;

  /** @format int32 */
  countClaims?: number;

  /** @format int32 */
  countContracts?: number;
  riskCode?: string;

  /** @format int64 */
  riskId?: number;
  riskName?: string;
}

export interface InsuranceProgramStatisticsDto {
  contractPaymentSum?: number;

  /** @format int32 */
  countContracts?: number;
  programCode?: string;

  /** @format int64 */
  programId?: number;
  programName?: string;
}

export interface DaysStatisticsDto {
  claimPaymentSum?: number;
  contractPaymentSum?: number;

  /** @format int32 */
  countClaims?: number;

  /** @format int32 */
  countContracts?: number;

  /** @format date */
  signDate?: string;
}

export interface PartnerDepartmentStatisticsDto {
  claimPaymentSum?: number;
  contractPaymentSum?: number;

  /** @format int32 */
  countClaims?: number;

  /** @format int32 */
  countContracts?: number;

  /** @format int64 */
  partnerDepartmentId?: number;
  partnerDepartmentNumber?: string;
}

export interface PartnerStatisticsDto {
  claimPaymentSum?: number;
  contractPaymentSum?: number;

  /** @format int32 */
  countClaims?: number;

  /** @format int32 */
  countContracts?: number;
  partnerName?: string;
}
