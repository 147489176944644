import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import i18next from 'i18next';

import { ProductRiskStatisticsDto, RiskStatisticsDto } from 'types/dto/contracts-service';
import { Data } from './ByRiskChart/ByRiskChart';

export interface TransformedRisk extends RiskStatisticsDto, Omit<ProductRiskStatisticsDto, 'riskStatisticsDtoList'> {}

export const transformData = (data: ProductRiskStatisticsDto[]): TransformedRisk[] => {
  return data.reduce((acc: TransformedRisk[], d) => {
    const dataForTransfer = omit(d, ['riskStatisticsDtoList']);
    const items: TransformedRisk[] = (d.riskStatisticsDtoList ?? []).map(r => ({ ...r, ...dataForTransfer }));

    return acc.concat(items);
  }, []);
};

const TOP = 10;
export const transformDataForChart = (data: TransformedRisk[]): Data[] => {
  const dictionary = groupBy(data, data => data.riskName);
  const groupedData = Object.values(dictionary)
    .map(values => {
      return values.reduce(
        (acc, value) => {
          acc.claimRiskPaymentSum = (acc.claimRiskPaymentSum ?? 0) + (value.claimRiskPaymentSum ?? 0);
          acc.contractRiskPaymentSum = (acc.contractRiskPaymentSum ?? 0) + (value.contractRiskPaymentSum ?? 0);
          acc.countClaims = (acc.countClaims ?? 0) + (value.countClaims ?? 0);
          acc.countContracts = (acc.countContracts ?? 0) + (value.countContracts ?? 0);

          acc.productCode = value.productCode;
          acc.productId = value.productId;
          acc.productName = value.productName;
          acc.riskCode = value.riskCode;
          acc.riskId = value.riskId;
          acc.riskName = value.riskName;

          return acc;
        },
        {
          claimRiskPaymentSum: 0,
          contractRiskPaymentSum: 0,
          countClaims: 0,
          countContracts: 0,
        },
      );
    })
    .sort((a, b) => (b.contractRiskPaymentSum ?? 0) - (a.contractRiskPaymentSum ?? 0));

  const top: Data[] = groupedData.slice(0, TOP).reduce((acc: Data[], el) => {
    return acc.concat([
      {
        xField: el.riskName ?? '',
        yField: el.contractRiskPaymentSum ?? 0,
        seriesField: 'seriesField_1',
        raw: { ...el, seriesField_1: el.contractRiskPaymentSum ?? 0, seriesField_2: el.claimRiskPaymentSum ?? 0 },
      },
      {
        xField: el.riskName ?? '',
        yField: el.claimRiskPaymentSum ?? 0,
        seriesField: 'seriesField_2',
        raw: { ...el, seriesField_1: el.contractRiskPaymentSum ?? 0, seriesField_2: el.claimRiskPaymentSum ?? 0 },
      },
    ]);
  }, []);
  const othersRaw = groupedData.slice(TOP).reduce(
    (acc, el) => {
      acc.claimRiskPaymentSum = (acc.claimRiskPaymentSum ?? 0) + (el.claimRiskPaymentSum ?? 0);
      acc.contractRiskPaymentSum = (acc.contractRiskPaymentSum ?? 0) + (el.contractRiskPaymentSum ?? 0);
      acc.countClaims = (acc.countClaims ?? 0) + (el.countClaims ?? 0);
      acc.countContracts = (acc.countContracts ?? 0) + (el.countContracts ?? 0);

      return acc;
    },
    {
      claimRiskPaymentSum: 0,
      contractRiskPaymentSum: 0,
      countClaims: 0,
      countContracts: 0,
    },
  );

  const others: Data[] = [
    {
      xField: i18next.t('dashboard_extend.OTHER'),
      yField: othersRaw.contractRiskPaymentSum ?? 0,
      seriesField: 'seriesField_1',
      raw: {
        ...othersRaw,
        riskName: 'OTHER',
        seriesField_1: othersRaw.contractRiskPaymentSum ?? 0,
        seriesField_2: othersRaw.claimRiskPaymentSum ?? 0,
      },
    },
    {
      xField: i18next.t('dashboard_extend.OTHER'),
      yField: othersRaw.claimRiskPaymentSum ?? 0,
      seriesField: 'seriesField_2',
      raw: {
        ...othersRaw,
        riskName: 'OTHER',
        seriesField_1: othersRaw.contractRiskPaymentSum ?? 0,
        seriesField_2: othersRaw.claimRiskPaymentSum ?? 0,
      },
    },
  ];

  return data.length > TOP ? [...top, ...others] : top;
};
