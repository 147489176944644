import { notification } from 'antd';
import { Dispatch } from 'redux';
import { createTypes } from 'redux-compose-reducer';

import { getContractsHistories, getPrivateFiltersHistory, getTablePageHistory } from 'api/insuranceContracts';
import { AppState } from 'store/reducer';

export const TYPES = createTypes('integrationHistory', [
  'setHistoryData',
  'setLoading',
  'setTablePage',
  'setSorting',
  'getPrivateFiltersData',
  'setPagination',
  'resetData',
]);

export const setLoading = (value: boolean) => {
  return {
    type: TYPES.setLoading,
    payload: value,
  };
};

export const resetData = () => {
  return {
    type: TYPES.resetData,
  };
};

export const getHistoryData = (id: number, pagination?: { page: number; size: number; total: number }) => async (
  dispatch: Dispatch,
  getState: () => AppState,
) => {
  const sorting = getState().integrationHistory.sorting;
  try {
    dispatch(setLoading(true));
    const response = await getContractsHistories(sorting, id, pagination);
    dispatch({ type: TYPES.setHistoryData, payload: response.data });
    dispatch(setLoading(false));
  } catch (error) {
    console.log('error: ', error);
  }
};

export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const getFilteredHistory = (id: number, filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    integrationHistory: { pagination, sorting },
  }: AppState = getState();

  try {
    const { data } = await getPrivateFiltersHistory(id, {
      filters: {
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-contractNumber,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
    });

    dispatch({ type: TYPES.getPrivateFiltersData, payload: data });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getTablePage = id => async (dispatch: any, getState: any) => {
  const {
    integrationHistory: { pagination, sorting, filters },
  }: AppState = getState();

  dispatch({ type: TYPES.setTablePage, pending: true });

  try {
    const { data } = await getTablePageHistory(id, { pagination, sorting, filters });

    dispatch({ type: TYPES.setTablePage, payload: data, pending: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setTablePage, pending: false });
  }
};

export const getFilteredPrivateData = (id, filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    integrationHistory: {
      pagination,
      sorting,
      filters: { contractNumber, success, message },
    },
  }: AppState = getState();
  try {
    const { data } = await getPrivateFiltersHistory(id, {
      filters: {
        ...filtersInfo,
        contractNumber,
        success,
        message,
        sorting:
          (!sorting.direction && '-conclusionDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination: {
        current: 1,
        perPage: pagination.perPage,
      },
    });

    dispatch({ type: TYPES.getPrivateFilteredData, payload: data });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setSorting = ({ field, direction }: { field: string; direction: string }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};
