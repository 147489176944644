import IncidentNotification from './IncidentNotification';
import ConfirmationDocuments from './ConfirmationDocuments';
import DefaultColumns from '../components/PayAmountTable/columns';
import AnnulClaims from './AnnulClaims';
import columnsList from '../../../../components/tables/InsuredEventsTable/columns';

export const PREDEFINED_FILTERS: { [x: string]: any } = {
  'Повідомлення про випадок': {
    claimStatus: 'REPORTED',
    sorting: '-claimDate',
  },
  'Документи на підтвердження': {
    claimStatus: 'DOCUMENTS_COLLECTION',
    sorting: '-receiptDate',
  },
  'Оформлення суми до виплати': {
    claimStatus: 'PAYMENT_CALCULATION',
    sorting: '-claimDate',
  },
  'Реєстр страхових випадків': {
    sorting: '-claimDate',
  },
  'Експрес-виплата': {
    claimStatus: 'PAYMENT_AWAITING',
    sorting: '-claimDate',
    resolutionType: 'EXPRESS_PAYMENT',
  },
  Анульовані: {
    claimStatus: 'ANNUL',
  },
};

export const PREDEFINED_COLUMNS: { [x: string]: any } = {
  'Повідомлення про випадок': IncidentNotification,
  'Документи на підтвердження': ConfirmationDocuments,
  'Оформлення суми до виплати': DefaultColumns,
  'Реєстр страхових випадків': columnsList,
  'Експрес-виплата': columnsList,
  Анульовані: AnnulClaims,
};
