import React, { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Menu, Trigger, TriigerIcon } from './styled';
import { Label, Value } from '../../styled';
import config from 'config';
import MenuItem from '../../../uikit/MenuItem';
import { useTranslation } from 'react-i18next';

export interface IPaginationSizeSelectProps {
  options?: number[];
  value: number;
  dataId?: string;
  dataIdOptions?: string;
  onChange: (value: number) => void;
}

const PaginationSizeSelect = ({
  value,
  onChange,
  dataId,
  dataIdOptions,
  options = config.ui.pagination.options,
}: IPaginationSizeSelectProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const handleItemClick = useCallback(o => {
    onChange(Number(o));
    setAnchorEl(undefined);
  }, []);

  return (
    <>
      <Trigger data-id={dataId} onClick={(e: React.MouseEvent<HTMLDivElement>) => setAnchorEl(e.currentTarget)}>
        <Grid container justify={'space-between'} spacing={2} alignItems={'center'}>
          <Grid item xs>
            <Label> {t('contract_list.show_footer')} </Label>
            <Value>
              {value} {t('contract_list.objects_pages_footer')}
            </Value>
          </Grid>
          <Grid item>
            <TriigerIcon fontSize="small" style={{ transform: `rotate(${anchorEl ? 180 : 0}deg)` }} />
          </Grid>
        </Grid>
      </Trigger>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 0,
          vertical: -54,
        }}
      >
        {options.map(o => (
          <MenuItem key={o} data-id={dataIdOptions} onClick={() => handleItemClick(o)}>
            {o} {t('contract_list.objects_pages_footer')}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PaginationSizeSelect;
