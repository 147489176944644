import moment from 'moment';
import { omit } from 'lodash';
import momentTz from 'moment-timezone';

import createInstance from './axios';
import { makerRequestParams } from 'utils/helpers';

const ATTRIBUTES_REQUEST =
  'id,claimStatus,partnerDepartmentPayout,repairEquipmentDeliveryAddress,insuranceObjectSubtype,saleRegisterStatus,ownerEquipment,insuranceObjectName,insuranceObjectPid,insuranceObjectType,contractNumber,insuranceSum,saleRevenue,resolution,incidentDate,paymentSum,delivery,invoiceNumber,serviceCenter,serviceCenterDate,damage,repairStatus,repairCost,dispatchFromRepairDate,repairEquipmentDelivery,repairEquipmentInvoiceNumber,receiptDate,amountRepairCost,otherExpenses,saleDate,salePrice,dispatchAfterSaleInvoiceNumber,dispatchDate,dispatchAfterSaleDelivery,transferEquipmentToBuyerDate';

const registrationDevicesAPI = createInstance('CONTRACTS');

// Top filter
export const getTablePageData = ({ filters, sorting, pagination }: any, insuranceCompanyId: string | number) => {
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;

  const requestParams = {
    attributes: ATTRIBUTES_REQUEST,
    ...omit(filters, ['dateFrom', 'dateTo', 'saleRegisterStatusName']),
    page: pagination.current,
    page_size: pagination.perPage,
    incidentDate: `${filters.dateFrom}..${moment(filters.dateTo).format('YYYY-MM-DD')}`,
    sorting: sortingQuery,
    distinct: true,
    insuranceCompanyId,
  };

  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return registrationDevicesAPI.get('/sale-registers?count', {
    params: {
      ...requestParams,
    },
  });
};

// Body filter
export const getPrivateFiltersTable = (
  {
    filters,
    pagination,
  }: {
    filters: any;
    pagination: { current: number | string; perPage: number | string };
  },
  insuranceCompanyId: string | number,
) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  const requestParams = {
    attributes: ATTRIBUTES_REQUEST,
    ...omit(filters, ['dateFrom', 'dateTo', 'saleRegisterStatusName']),
    incidentDate: `${filters.dateFrom}..${moment(filters.dateTo).format('YYYY-MM-DD')}`,
    page_size: pagination.perPage,
    page: pagination.current,
    distinct: true,
    insuranceCompanyId,
  };

  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return registrationDevicesAPI.get('/sale-registers?count', {
    params: {
      ...requestParams,
    },
  });
};

export const getSaleRegisterStatusesData = () => {
  return registrationDevicesAPI.get('sale-registers/equipment-sale-statuses');
};

export const downloadActivitiesAsXLS = async (params: any) => {
  Object.keys(params).forEach(key => !params[key] && delete params[key]);

  const timeZone = momentTz.tz.guess(true);
  return registrationDevicesAPI.get('sale-registers/print/xlsx', {
    responseType: 'arraybuffer',
    params: {
      ...params,
      timeZone,
    },
  });
};
