import { createTypes } from 'redux-compose-reducer';
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';

import * as contractsAPI from 'api/contracts';
import { IAuthenticationTokenPayload } from '../../types/Authentication';
import { getUserById } from 'store/userCard/actions';

export const TYPES = createTypes('currentUser', [
  'setCurrentUser',
  'deleteCurrentUser',
  'setAuthentication',
  'removeAuthentication',
  'setCurrentCompanyCurrency',
]);

export const setAuthentication = (authentication: IAuthenticationTokenPayload) => ({
  type: TYPES.setAuthentication,
  payload: authentication,
});

export const getCurrentUser = () => async (dispatch: any) => {
  try {
    const response = await contractsAPI.getUsersInfo();
    const accessToken = localStorage.getItem('accessToken');

    dispatch({ type: TYPES.setCurrentUser, payload: response.data });

    if (!accessToken) {
      throw new Error("token isn't exist");
    }

    const decoded = jwt.decode(accessToken) as IAuthenticationTokenPayload;
    dispatch(setAuthentication(decoded));

    if (decoded.expirationDate >= dayjs().valueOf()) {
      await dispatch(getUserById(response.data.id ?? 0));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteCurrentUser = () => (dispatch: any) => {
  dispatch({ type: TYPES.deleteCurrentUser });
};

export const removeAuthentication = () => ({
  type: TYPES.removeAuthentication,
});

export const setCurrentCompanyCurrency = (data: string) => ({
  type: TYPES.setCurrentCompanyCurrency,
  payload: data,
});
