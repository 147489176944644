import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';

import { TYPES } from './actions';
import { IPartnerStore } from './types';
import config from 'config';

export const initialState: IPartnerStore = {
  loading: false,
  data: [],
  total: 0,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'name',
    direction: 'DESC',
  },
  filters: {
    identifier: '',
    name: '',
    shortName: '',
    code: '',
    type: '',
  },
  privateFilterItems: {},
  privateFilteredData: [],
};

const getPartnersList = (state: IPartnerStore, { payload }: AnyAction): IPartnerStore => {
  return {
    ...state,
    data: payload.data,
    total: payload.count,
  };
};

const setPending = (state: IPartnerStore, { pending }: AnyAction): IPartnerStore => {
  return {
    ...state,
    loading: pending,
  };
};

const setPagination = (state: IPartnerStore, { payload }: AnyAction): IPartnerStore => {
  return {
    ...state,
    pagination: payload,
  };
};

const setTablePage = (state: IPartnerStore, { payload, pending }: AnyAction): IPartnerStore => {
  return {
    ...state,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
    loading: pending,
  };
};

const getPrivateFiltersData = (state: IPartnerStore, { payload }: any): IPartnerStore => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    data: resultList,
    total: count,
  };
};

const setSorting = (state: IPartnerStore, { payload }: any): IPartnerStore => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    getPartnersList,
    setPending,
    setPagination,
    getPrivateFiltersData,
    setSorting,
    setTablePage,
  },
});
