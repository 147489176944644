import React from 'react';
import history from 'routes/history';
import { useDispatch } from 'react-redux';

import styles from '../CreateModal.module.less';
import socketIcon from 'assets/img/layout/socket.svg';
import covidIcon from 'assets/img/layout/coronavirus.svg';
import car from 'assets/img/layout/Car.svg';
import jutlo from 'assets/img/layout/jutlo.svg';
import medicine from 'assets/img/layout/medicine.svg';
import plane from 'assets/img/layout/plane.svg';
import { ROUTES } from 'constants/routes';
import { setBeneficiaryUpdated } from 'store/contractDetails/actions';
import { CONTRACT_TYPE } from 'components/DetailsCard/constants/Datails.constants';
import { useTranslation } from 'react-i18next';

interface IProduct {
  name: string;
  code: string;
  handleClose: () => void;
  handleClick?: (code: string) => void;
}

export const Product = ({ name, code, handleClose, handleClick }: IProduct) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const redirectOn = (redirectParam: string) => {
    history.push({ pathname: `${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/create`, state: redirectParam });
    handleClose();
    history.go(0);
  };

  const getImg = code => {
    switch (code) {
      case CONTRACT_TYPE.MOBI:
      case CONTRACT_TYPE.INNOTECH:
        return socketIcon;
      case CONTRACT_TYPE.COVID:
        return covidIcon;
      case CONTRACT_TYPE.JUTLO:
        return jutlo;
      case CONTRACT_TYPE.MEDICINE:
        return medicine;
      case CONTRACT_TYPE.VZR:
        return plane;
      default:
        return car;
    }
  };

  const getAlt = code => {
    switch (code) {
      case CONTRACT_TYPE.MOBI:
      case CONTRACT_TYPE.INNOTECH:
        return 'socket icon';
      case CONTRACT_TYPE.COVID:
        return 'covid icon';
      case CONTRACT_TYPE.JUTLO:
        return 'mini icon';
      case CONTRACT_TYPE.MEDICINE:
        return 'medicine icon';
      case CONTRACT_TYPE.VZR:
        return 'vzr icon';
      default:
        return 'car icon';
    }
  };

  const getName = code => {
    switch (code) {
      case CONTRACT_TYPE.MOBI:
        return t('modal.mobile_protection_products');
      case CONTRACT_TYPE.INNOTECH:
        return 'INNOTECH';
      case CONTRACT_TYPE.COVID:
        return 'COVID-19';
      case CONTRACT_TYPE.KASKO:
        return t('modal.car_insurance_products');
      case CONTRACT_TYPE.JUTLO:
        return t('modal.dwelling_products');
      case CONTRACT_TYPE.MEDICINE:
        return t('modal.medicine');
      case CONTRACT_TYPE.VZR:
        return 'ВЗР';
      default:
        return '';
    }
  };

  const onClickProduct = () => {
    if (handleClick) {
      handleClick(code);
    }
    dispatch(setBeneficiaryUpdated(false));
    redirectOn.call(null, code);
  };

  return (
    <div className={styles.content_item} onClick={onClickProduct} data-id={`${name}-Product`}>
      <div className={styles.content_icon}>
        <img src={getImg(code)} alt={getAlt(code)} className={styles.icon} />
      </div>
      <div className={styles.content_text}>{getName(code)}</div>
    </div>
  );
};
