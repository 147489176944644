import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { IUserCard } from 'types/userCard';
import { AnyAction } from 'redux';
import userState from './initialState';

const initialState: IUserCard = userState;

const setUserData = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  data: payload.data || state.data,
});

const setUserPending = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  pending: payload,
});

const setUserLocation = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  data: {
    ...state.data,
    address: {
      ...state.data.address,
      street: state.data.address!.street || null,
      locality: payload || state.data.address!.locality || null,
    },
  },
});

const setUserProgram = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  data: {
    ...state.data,
    insuranceCompany: payload || state.data.insuranceCompany,
  },
});

const setUserPartner = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  data: {
    ...state.data,
    partner: payload || state.data.partner,
  },
});

const setUserPartnerDepartment = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  data: {
    ...state.data,
    partnerDepartment: payload,
  },
});

const setUserPartnerDepartmentRegion = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  data: {
    ...state.data,
    partnerDepartmentRegion: payload,
  },
});

const setUserRole = (state: IUserCard, { payload }: AnyAction): IUserCard => {
  return {
    ...state,
    data: {
      ...state.data,
      role: payload || state.data.role,
    },
  };
};

const setIsUserClient = (state: IUserCard, { payload }: AnyAction): IUserCard => {
  return {
    ...state,
    data: {
      ...state.data,
      client: payload,
    },
  };
};

const setUserActivity = (state: IUserCard, { payload }: AnyAction): IUserCard => {
  return {
    ...state,
    data: {
      ...state.data,
      active: payload,
      status: payload ? 'ACTIVE' : 'INACTIVE',
    },
  };
};

const setUserPrograms = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  data: {
    ...state.data,
    insurancePrograms: payload,
  },
});

const setLanguage = (state: IUserCard, { payload }: AnyAction): IUserCard => ({
  ...state,
  language: payload,
});

const clearData = (state: IUserCard, { payload }: AnyAction): IUserCard => {
  return {
    ...state,
    data: initialState.data,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setUserData,
    setUserPending,
    setUserProgram,
    setUserPartner,
    setUserPartnerDepartment,
    setUserPartnerDepartmentRegion,
    setUserRole,
    setUserActivity,
    setUserPrograms,
    setUserLocation,
    clearData,
    setIsUserClient,
    setLanguage,
  },
});
