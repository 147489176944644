import React, { useEffect } from 'react';
import { Checkbox } from '../Checkbox';
import { useTranslation } from 'react-i18next';
import { ClinicFilters as TClinicFilters } from 'types/institutions';
import { Select } from '../Select';
import { getLocalities, getCities } from 'api/institution';
import { useAsync } from 'hooks/useAsync';
import { UseFormMethods } from 'react-hook-form';
import { CLINICS_TYPES, CLINICS_TYPES_DESCRIPTOR } from 'constants/appointments';
import styles from './ClinicFilters.module.less';

interface ClinicFiltersProps {
  formMethods: UseFormMethods<TClinicFilters>;
}

export const ClinicFilters: React.FC<ClinicFiltersProps> = ({ formMethods }) => {
  const { t } = useTranslation();

  const { value: cities, doFetch: fetchCities } = useAsync(getCities);

  const { value: localities, doFetch: fetchLocalities } = useAsync(getLocalities);

  const watch = formMethods.watch('locality');

  useEffect(() => {
    fetchLocalities();
  }, []);

  useEffect(() => {
    fetchCities(formMethods.control.getValues().locality);
  }, [watch]);

  return (
    <div className={styles.clinicFilters}>
      <Select
        name="locality"
        control={formMethods.control}
        label={t('appointments.locality')}
        options={localities ?? []}
      />
      <Select name="city" control={formMethods.control} label={t('appointments.city')} options={cities ?? []} />
      <section>
        <span>{t('appointments.clinic_type')}</span>
        <Checkbox
          name="clinicType"
          control={formMethods.control}
          radios={[
            { id: CLINICS_TYPES.PUBLIC, label: t(CLINICS_TYPES_DESCRIPTOR[CLINICS_TYPES.PUBLIC]) },
            { id: CLINICS_TYPES.PRIVATE, label: t(CLINICS_TYPES_DESCRIPTOR[CLINICS_TYPES.PRIVATE]) },
          ]}
        />
      </section>
      <section>
        <span>{t('appointments.visit_type')}</span>
        <Checkbox name="isOnline" control={formMethods.control} label={t('appointments.could_be_available')} />
      </section>
    </div>
  );
};
