export default () => ({
  root: {
    background: 'none',
  },
  table: {
    overflow: 'hidden',
    '& .ant-table-thead tr > th': {
      background: '#ffffff !important',
    },
    '& .ant-table-fixed-right .ant-table-body-inner': {
      overflow: 'visible !important',
      overflowY: 'auto !important',
    },
    '& .ant-table-fixed-right .ant-table-body-outer': {
      marginBottom: '0px !important',
    },
  },
  footer: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  select: {
    maxWidth: 220,
  },
});
