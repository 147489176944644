import styled from 'styled-components';

interface IProps {
  isHeader?: boolean;
}

export const Wrapper = styled.div`
  border-bottom: 1px solid rgba(149, 159, 166, 0.2);
  display: flex;
  margin-bottom: 20px;
  padding: 20px;

  &:last-child {
    border-bottom: none;
    margin-bottom: none;
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
`;

export const Time = styled.span`
  font-family: 'Inter';
  color: #a9b5bd;
  font-size: 14px;
  line-height: 150%;
`;

export const EventBody = styled.div`
  background-color: #f6f8f8;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  cursor: pointer;
`;

export const ServiceWrapper = styled.div`
  border-bottom: 1px solid rgba(149, 159, 166, 0.2);
`;

export const StatusWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
`;

const Status = styled.div<IProps>`
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Inter';
  font-size: ${props => (props.isHeader ? '14px' : '8px')};
  font-weight: 600;
  line-height: 150%;
  margin: ${props => (props.isHeader ? '0 0 0 20px' : '0 10px 0 0')};
  padding: ${props => (props.isHeader ? '8px 14px' : '6px 10px')};
  width: fit-content;
`;

export const CanceledStatus = styled(Status as any)`
  background: #e2353b;
`;

export const CompletedStatus = styled(Status as any)`
  background: #459e3c;
`;

export const PendingStatus = styled(Status as any)`
  background: #f2994a;
`;

export const PlannedStatus = styled(Status as any)`
  background: #47a5ea;
`;

export const ServiceName = styled.div`
  color: #3e3e3e;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 10px;
`;

export const InstitutionWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 10px;
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;

export const InstitutionText = styled.span`
  color: #3e3e3e;
  font-family: 'Inter';
  font-size: 11px;
  font-weight: 500;
  line-height: 150%;
`;
