import createInstance from './axios';

const appointmentApi = createInstance('APPOINTMENT');

export const getSubevents = async () => {
  const requestParams = {
    count: true,
  };

  const { data } = await appointmentApi.get('/subevent/subevents', {
    params: {
      ...requestParams,
    },
  });
  return data;
};

export const sendConsultationRating = async body => {
  const { data } = await appointmentApi.post('/subevent/rating', body);
  return data;
};
