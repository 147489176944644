import i18n, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Language } from 'types';
import locales from 'config/locales';

const resources = Object.entries(locales).reduce((acc, [key, value]) => {
  acc[key as Language] = {
    translation: value.translation,
  };

  return acc;
}, {} as Record<Language, ResourceLanguage>);

const lng = localStorage.getItem('language') ?? Language.EN;

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng,
    fallbackLng: Language.EN,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(err => console.error(err));

export default i18n;
