import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, ConfigProvider } from 'antd';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { deleteDuplicatesFromList, getDatePickerLng, getUniqueListBy } from 'utils/helpers';
import styles from './styles.module.less';
import FilterSelect from 'components/filters/components/Select/Select';
import { AppState } from 'store/reducer';
import { getTablePage, setFiltersDates, setPagination, TYPES } from 'store/insuranceContracts/actions';
import { useTranslation } from 'react-i18next';
import { getActionType } from '../Table/utils/formatter';

export const ContractsFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((store: AppState) => store.userCard.language);
  const { filters, pagination, integrationTypes, partners, products, programs, chosenProducts } = useSelector(
    ({
      insuranceContracts: { filters, pagination, integrationTypes, programs, partners, chosenProducts },
      insuranceProducts: { products },
    }: AppState) => ({
      filters,
      products,
      pagination,
      integrationTypes,
      programs,
      partners,
      chosenProducts,
    }),
  );

  const { importType, insuranceProgramId, partnerId, dateFrom, dateTo, productId } = filters;

  const [filtersParams, setFiltersParams] = useState({
    importType,
    insuranceProgramId,
    dateFrom,
    dateTo,
    partnerId,
    productId,
  });

  const insuranceProductValue = products.find(product => product.id === filtersParams.productId)?.name;
  const insuranceProgramValue = programs.find(program => program.id === filtersParams.insuranceProgramId)?.name;
  const contractsPartnerValue = partners.find(partner => partner.id === filtersParams.partnerId)?.shortName;

  useEffect(() => {
    setFiltersParams({
      ...filtersParams,
      insuranceProgramId: 0,
    });
  }, [chosenProducts.id]);

  useEffect(() => {
    setFiltersParams({
      importType,
      insuranceProgramId,
      dateFrom,
      dateTo,
      partnerId,
      productId,
    });
  }, [importType, insuranceProgramId, partnerId, dateFrom, dateTo]);

  const changeProgramm = (name: string) => {
    const filteredPrograms = programs ? programs.find((c: any) => c.name === name) : 0;
    setFiltersParams({
      ...filtersParams,
      insuranceProgramId: filteredPrograms ? filteredPrograms.id ?? 0 : 'Усі',
    });
  };

  const changeProduct = (name: string) => {
    const filteredProduct = programs ? products.find((c: any) => c.name === name) : 0;
    dispatch({ type: TYPES.setChosenProducts, payload: filteredProduct ? filteredProduct : { id: 0, name: '' } });
    setFiltersParams({
      ...filtersParams,
      productId: filteredProduct ? filteredProduct.id : 'Усі',
    });
  };

  const changePartner = (name: string) => {
    const filteredPartner = partners ? partners.find((c: any) => c.name === name) : 0;
    if (filteredPartner) {
      dispatch({ type: TYPES.setChosenPartner, payload: filteredPartner });
    } else {
      dispatch({ type: TYPES.setChosenPartner, payload: { name: '', id: 0 } });
    }
    setFiltersParams({
      ...filtersParams,
      partnerId: filteredPartner ? filteredPartner.id ?? 0 : 'Усі',
    });
  };

  const handleDateChange = ({ dateFrom, dateTo }: { dateFrom: Moment; dateTo: Moment }) => {
    setFiltersParams({ ...filtersParams, dateFrom, dateTo });
  };

  const changeIntegrationType = (id: string) => {
    const filteredTypes =
      integrationTypes || id === '0' ? integrationTypes.find((c: any) => getActionType(t)[c.type] === id) : 0;
    setFiltersParams({
      ...filtersParams,
      importType: filteredTypes ? getActionType(t)[filteredTypes.type ?? ''] : t('insurance_contracts.everyone_select'),
    });
  };

  const setFiltersValues = () => {
    dispatch(setFiltersDates({ ...filtersParams }));
  };

  const handleSubmit = () => {
    setFiltersValues();
    dispatch(setPagination({ current: 1, perPage: pagination.perPage }));
    dispatch(getTablePage(t));
  };

  return (
    <div className={styles.container}>
      <FilterSelect
        showSearch
        label={t('insurance_contracts.integration_process')}
        labelActive={filtersParams.importType || t('insurance_contracts.everyone_select')}
        value={filtersParams.importType || t('insurance_contracts.everyone_select')}
        data-id="contract-list-filter-partner"
        dataInputId="contract-list-filter-partner-input"
        dataOptionId="contract-list-filter-partner-options"
        options={getUniqueListBy(
          [
            {
              title: t('insurance_contracts.everyone_select'),
              value: t('insurance_contracts.everyone_select'),
              name: t('insurance_contracts.everyone_select'),
              key: 0,
              id: 0,
            },
          ].concat(
            integrationTypes.map(({ type }) => {
              return {
                title: getActionType(t)[type ?? ''],
                value: getActionType(t)[type ?? ''],
                key: getActionType(t)[type ?? ''],
                name: getActionType(t)[type ?? ''],
                id: getActionType(t)[type ?? ''],
              };
            }),
          ),
          'title',
        )}
        optionLabel="value"
        onSelect={changeIntegrationType}
      />
      <FilterSelect
        showSearch
        label={t('insurance_contracts.insurance_product')}
        labelActive={filtersParams.productId || t('insurance_contracts.everyone_select')}
        value={insuranceProductValue || t('insurance_contracts.everyone_select') || false}
        data-id="contract-list-filter-partner"
        dataInputId="contract-list-filter-partner-input"
        dataOptionId="contract-list-filter-partner-options"
        options={[
          {
            title: t('insurance_contracts.everyone_select'),
            value: t('insurance_contracts.everyone_select'),
            name: t('insurance_contracts.everyone_select'),
            key: 0,
            id: 0,
          },
          ...products.map(item => {
            return {
              title: item.name,
              value: item.name,
              name: item.name,
              key: item.id,
              id: item.id,
            };
          }),
        ]}
        optionLabel="value"
        onSelect={changeProduct}
      />
      <FilterSelect
        showSearch
        label={t('insurance_contracts.insurance_program')}
        labelActive={filtersParams.insuranceProgramId || t('insurance_contracts.everyone_select')}
        value={insuranceProgramValue || t('insurance_contracts.everyone_select') || false}
        data-id="contract-list-filter-partner"
        dataInputId="contract-list-filter-partner-input"
        dataOptionId="contract-list-filter-partner-options"
        options={[
          {
            title: t('insurance_contracts.everyone_select'),
            value: t('insurance_contracts.everyone_select'),
            name: t('insurance_contracts.everyone_select'),
            key: 0,
            id: 0,
          },
          ...deleteDuplicatesFromList(programs).map(item => {
            return {
              title: item.name,
              value: item.name,
              name: item.name,
              key: item.id,
              id: item.id,
            };
          }),
        ]}
        optionLabel="value"
        onSelect={changeProgramm}
      />
      <FilterSelect
        showSearch
        label={t('insurance_contracts.partner')}
        labelActive={filtersParams.partnerId || t('insurance_contracts.everyone_select')}
        value={contractsPartnerValue || t('insurance_contracts.everyone_select') || false}
        data-id="contract-list-filter-partner"
        dataInputId="contract-list-filter-partner-input"
        dataOptionId="contract-list-filter-partner-options"
        options={[
          {
            title: t('insurance_contracts.everyone_select'),
            value: t('insurance_contracts.everyone_select'),
            name: t('insurance_contracts.everyone_select'),
            key: 0,
            id: 0,
          },
          ...deleteDuplicatesFromList(partners).map(item => ({
            title: item.name,
            value: item.name,
            name: item.name,
            key: item.id,
            id: item.id,
            shortName: item.shortName,
          })),
        ]}
        optionLabel="value"
        shortOptionLabel="shortName"
        onSelect={changePartner}
      />
      <ConfigProvider locale={getDatePickerLng(currentLanguage)}>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('insurance_contracts.period_from')}</div>
          <DatePicker
            data-id="contract-list-filter-date-start"
            defaultValue={moment(filtersParams.dateFrom)}
            allowClear={false}
            onChange={(m, dateString) => {
              handleDateChange({
                dateTo: filtersParams.dateTo,
                dateFrom: moment(dateString, 'DD.MM.YYYY'),
              });
            }}
            format={'DD.MM.YYYY'}
            size="large"
            placeholder={t('insurance_contracts.choose_a_date')}
            className={styles.dateField}
          />
        </div>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('insurance_contracts.period_by')}</div>
          <DatePicker
            data-id="contract-list-filter-date-start"
            defaultValue={moment(filtersParams.dateTo)}
            allowClear={false}
            onChange={(m, dateString) => {
              handleDateChange({
                dateTo: moment(dateString, 'DD.MM.YYYY'),
                dateFrom: filtersParams.dateFrom,
              });
            }}
            format={'DD.MM.YYYY'}
            size="large"
            placeholder={t('insurance_contracts.choose_a_date')}
            className={styles.dateField}
          />
        </div>
      </ConfigProvider>
      <Button
        data-id="contract-list-filter-submit"
        onClick={handleSubmit}
        size="large"
        type="primary"
        htmlType="submit"
        className={classNames('login-form-button', styles.searchButton)}
      >
        {t('insurance_contracts.install_button')}
      </Button>
    </div>
  );
};
