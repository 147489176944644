import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import { AppState } from 'store/reducer';

import { getTablePage, setFiltersDates, setPagination } from 'store/registrationDevices/actions';
import { getDatePickerLng } from 'utils/helpers';
import FilterInput from '../components/Input';
import FilterSelect from '../components/Select/Select';
import styles from './styles.module.less';
import { useTranslation } from 'react-i18next';

const DATE_FORMAT = 'DD.MM.YYYY';

const RegistrationDevicesFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLanguage = useSelector((store: AppState) => store.userCard.language);

  const { filters, pagination, saleRegisterStatuses } = useSelector(
    ({ registrationDevices: { filters, pagination, saleRegisterStatuses } }: AppState) => ({
      filters,
      pagination,
      saleRegisterStatuses,
    }),
  );

  const { dateFrom, dateTo, insuranceObjectPid, saleRegisterStatus, saleRegisterStatusName } = filters;
  const [filtersParams, setFiltersParams] = useState({
    dateFrom,
    dateTo,
    insuranceObjectPid,
    saleRegisterStatus,
    saleRegisterStatusName,
  });

  const [fieldsActiveState, setFieldsActiveState] = useState({
    insuranceObjectPid: null,
    saleRegisterStatus: null,
    saleRegisterStatusName: null,
  });

  useEffect(() => {
    setFiltersParams({
      dateFrom,
      dateTo,
      insuranceObjectPid,
      saleRegisterStatus,
      saleRegisterStatusName,
    });
  }, [dateFrom, dateTo, insuranceObjectPid, saleRegisterStatus, saleRegisterStatusName]);

  const setFiltersValues = useCallback(filtersParams => {
    dispatch(setFiltersDates({ ...filtersParams }, t));
  }, []);

  useEffect(() => {
    setFiltersValues(filtersParams);
  }, [filtersParams]);

  const handleFieldsFocus = (eventName: string, fieldName: string, force?: boolean) => {
    if (!!filtersParams[fieldName] && !force) return;

    setFieldsActiveState({ ...fieldsActiveState, [fieldName]: eventName === 'focus' });
  };

  const handleDateChange = ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
    setFiltersParams({ ...filtersParams, dateFrom, dateTo });
  };

  const handleSubmit = () => {
    dispatch(setPagination(1, pagination.perPage));
    dispatch(getTablePage());
  };

  const setStatusId = (id: string) => {
    const filteredCompany: { id: string; name: string } | undefined = saleRegisterStatuses?.find(
      (item: any) => item.id === id,
    );

    setFiltersParams({
      ...filtersParams,
      saleRegisterStatus: filteredCompany ? filteredCompany!.id : '',
      saleRegisterStatusName: filteredCompany ? filteredCompany!.id : id,
    });
  };

  return (
    <div className={styles.container}>
      <FilterInput
        label={t('insured_events_list.pid_express_payment_table')}
        labelActive={fieldsActiveState.insuranceObjectPid}
        onChange={(e: any) => setFiltersParams({ ...filtersParams, insuranceObjectPid: e.target.value.trim() })}
        value={filtersParams.insuranceObjectPid}
        closable
        onCloseHandler={() => {
          setFiltersParams({ ...filtersParams, insuranceObjectPid: '' });
          handleFieldsFocus('blur', 'insuranceObjectPid', true);
        }}
        focusHandler={(e: any) => handleFieldsFocus(e.type, 'insuranceObjectPid')}
      />

      <FilterSelect
        label={t('registration_devices.status')}
        labelActive={filtersParams.saleRegisterStatusName || fieldsActiveState.saleRegisterStatusName}
        value={filtersParams.saleRegisterStatusName}
        options={[
          { title: t('registration_devices.everyone'), value: 'Усі', key: 'Усі', id: 'Усі', name: 'Усі' },
          ...saleRegisterStatuses.map((item: any) => {
            return {
              title: item.name,
              value: item.name,
              key: item.id,
              id: item.id,
              name: item.id,
            };
          }),
        ]}
        optionLabel="value"
        handleFocus={(e: any) => handleFieldsFocus(e.type, 'saleRegisterStatus')}
        onSelect={setStatusId}
      />

      <FilterSelect
        showSearch
        disabled
        label={t('registration_devices.service_center')}
        labelActive={false}
        value={null}
        options={[{ title: 'Усі', value: 'Усі', key: 0, id: 0 }]}
        optionLabel="value"
        onSelect={null}
      />
      <ConfigProvider locale={getDatePickerLng(currentLanguage)}>
        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('registration_devices.period_with')}</div>
          <DatePicker
            defaultValue={moment(filtersParams.dateFrom)}
            allowClear={false}
            onChange={(m, dateString) => {
              handleDateChange({
                dateTo: filtersParams.dateTo,
                dateFrom: moment(dateString, DATE_FORMAT).format('YYYY-MM-DD'),
              });
            }}
            format={DATE_FORMAT}
            size="large"
            placeholder={t('registration_devices.choose_a_date')}
            className={styles.dateField}
          />
        </div>

        <div className={classNames(styles.fieldContainer, styles.dateFieldContainer)}>
          <div className={styles.label}>{t('registration_devices.period_after')}</div>
          <DatePicker
            defaultValue={moment(filtersParams.dateTo)}
            allowClear={false}
            onChange={(m, dateString) => {
              handleDateChange({
                dateFrom: filtersParams.dateFrom,
                dateTo: moment(dateString, DATE_FORMAT).format('YYYY-MM-DD'),
              });
            }}
            format={DATE_FORMAT}
            size="large"
            placeholder={t('registration_devices.choose_a_date')}
            className={styles.dateField}
          />
        </div>
      </ConfigProvider>
      <Button
        onClick={handleSubmit}
        size="large"
        type="primary"
        htmlType="submit"
        className={classNames('login-form-button', styles.searchButton)}
      >
        {t('registration_devices.install_button')}
      </Button>
    </div>
  );
};

export default RegistrationDevicesFilters;
