import { ITerminationList } from 'types/terminationList';
import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TYPES } from './actions';
import moment from 'moment';
import config from 'config';

const initialState: ITerminationList = {
  resultList: [],
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'terminationDate',
    direction: 'DESC',
  },
  filters: {
    dateFrom: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    insuranceCompanyName: '',
    insuranceCompanyId: {
      id: null,
      name: null,
    },
    insuranceProgramId: {
      id: null,
      name: null,
    },
    insuranceProgramName: '',
  },
  privateFilterItems: {},
  companies: [],
  insuranceProgram: [],
  count: 0,
  pending: false,
  error: false,
  total: null,
};

const setTotalInformation = (state: ITerminationList, { payload }: AnyAction): ITerminationList => ({
  ...state,
  total: { ...payload },
});

const setTerminationList = (state: ITerminationList, { payload }: AnyAction): ITerminationList => {
  return {
    ...state,
    resultList: payload.resultList || state.resultList,
    count: payload.count || state.count,
  };
};

const setPending = (state: ITerminationList, { payload }: AnyAction): ITerminationList => {
  return {
    ...state,
    pending: payload ? payload.pending : initialState.pending,
  };
};

const setFilters = (state: ITerminationList, { payload }: AnyAction): ITerminationList => {
  return {
    ...state,
    filters: payload || state.filters,
  };
};

const setPagination = (state: ITerminationList, { payload }: AnyAction): ITerminationList => {
  return {
    ...state,
    pagination: payload || state.pagination,
  };
};

const setSorting = (state: ITerminationList, { payload }: AnyAction): ITerminationList => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const getPrivateFiltersData = (state: ITerminationList, { payload }: AnyAction): ITerminationList => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    resultList: resultList || state.resultList,
    count: count || state.count,
  };
};

const setTerminationListPrivateFilter = (state: ITerminationList, { payload }: any) => {
  return {
    ...state,
    privateFilterItems: payload,
  };
};

const setCurrentCompanies = (state: ITerminationList, { payload }: any): ITerminationList => ({
  ...state,
  filters: {
    ...state.filters,
    insuranceCompanyName:
      payload.companies[0].name ||
      (state.companies && state.companies.length && state.companies[0].name) ||
      state.filters.insuranceCompanyName,
    insuranceCompanyId:
      payload.companies[0] ||
      (state.companies && state.companies.length && state.companies[0]) ||
      state.filters.insuranceCompanyId,
    // insuranceProgramId: payload.insuranceProgram[0] || state.insuranceProgram[0] || state.filters.insuranceProgramId,
    // insuranceProgramName:
    //   payload.insuranceProgram[0].name || state.insuranceProgram[0].name || state.filters.insuranceProgramName,
  },
  companies: payload.companies || state.companies,
  insuranceProgram: payload.insuranceProgram || state.insuranceProgram,
});

const setTerminationListInsuranceProgram = (state: ITerminationList, { payload }: any): ITerminationList => ({
  ...state,
  insuranceProgram: payload.insuranceProgram || state.insuranceProgram,
});

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setTerminationList,
    setPending,
    setFilters,
    setPagination,
    setSorting,
    getPrivateFiltersData,
    setCurrentCompanies,
    setTotalInformation,
    setTerminationListInsuranceProgram,
    setTerminationListPrivateFilter,
  },
});
