import React from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { Input } from 'antd';
import { css } from '@emotion/css';

import InputContainer, { Margin, Info } from '../InputContainer/InputContainer';
import { theme } from 'styles';

const cssRoot = css`
  border-radius: 12px;
  border: none;
  background-color: ${theme.color.blueGray.light};
`;

export interface Props extends NumberFormatProps {
  label?: string;
  margin?: Margin;
  info?: Info;
  handleChange?: (value: number | null) => void;
}

const NumberField = ({ info, label, margin = 'normal', handleChange, onValueChange, ...props }: Props) => {
  const handleValue = ({ floatValue }: NumberFormatValues) => {
    handleChange && handleChange(floatValue ?? null);
  };

  return (
    <InputContainer info={info} label={label} margin={margin}>
      <NumberFormat
        data-test={info?.name}
        customInput={Input}
        onValueChange={onValueChange || handleValue}
        className={cssRoot}
        {...props}
      />
    </InputContainer>
  );
};

export default NumberField;
