import React from 'react';
import styles from './TableTabs.module.less';
import { Tabs } from 'antd';
import cs from 'classnames';
import { TabsProps } from 'antd/lib/tabs';

const TableTabs: React.FC<TabsProps> = ({ className, ...props }) => (
  <Tabs className={cs(styles.root, className)} {...props} />
);

export default TableTabs;
