import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  
  & svg line {
    stroke: #EAECED !important;
  }
  
  & svg > g > g:nth-child(3) > line {
    stroke: transparent !important;
  }
  
  & svg > g > g:nth-child(3) > g > text, 
   svg > g > g:nth-child(2) > g > text {
    fill: #A9B5BD !important;
    font-size: 10px;
  }
`;
