import React, { ReactNode } from 'react';
import { Input } from 'antd';
import styles from './FormInputText.module.less';
import cx from 'classnames';

interface IFormInputText extends Partial<HTMLInputElement> {
  autoComplete?: string;
  name?: string;
  title?: string;
  value?: any;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: any;
  onChange?: (value: any) => any;
  onBlur?: (...args: any) => any;
  onFocus?: (...args: any) => any;
  onClick?: (...args) => void;
  isRequired?: any;
  dataId?: string;
  className?: string;
  suffix?: string | ReactNode;
  clearAddress?: boolean;
  textarea?: boolean;
}

export default class FormInputText extends React.Component<IFormInputText> {
  render() {
    const {
      title,
      value,
      readOnly,
      onChange,
      placeholder,
      isRequired,
      disabled,
      autoComplete = 'off',
      dataId,
      className,
      onBlur,
      onFocus,
      onClick,
      clearAddress,
      suffix,
      textarea = false,
      ...props
    }: any = this.props;

    const { TextArea } = Input;

    return (
      <div className={cx(styles.formInput__container, className)}>
        <p>
          {title}
          {isRequired && <span>*</span>}
        </p>
        {textarea ? (
          <TextArea
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            data-id={dataId}
            suffix={suffix}
            {...props}
          />
        ) : (
          <Input
            value={value}
            readOnly={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            data-id={dataId}
            suffix={suffix}
            {...props}
          />
        )}
      </div>
    );
  }
}
