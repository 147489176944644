import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import ChatsList from './components/ChatsList';
import Chat from './components/Chat';
import Meeting from './components/Meeting';
import { useChats } from 'api/chats';
import { MeetingType } from 'types/chats';
import { ROUTES } from 'constants/routes';
import PageHeader from 'components/PageHeader';
import { checkPermissions } from 'roles/userCan';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { withPubNub } from 'api/pubNub';
import Rating from './components/Rating';
import { sendConsultationRating } from 'store/subevents/actions';

import styles from './Chats.module.less';

const Chats: React.FC = () => {
  const { t } = useTranslation();

  const { chats, opened, onOpen } = useChats();
  const dispatch = useDispatch();
  const sendRating = data => dispatch(sendConsultationRating(data));

  const [meeting, setMeeting] = useState<MeetingType>();
  const [isRatingModalOpen, setIsRatingModalOpen] = useState<boolean>(false);
  const [ratingValue, setRatingValue] = useState<number | null>(0);

  const stopMeeting = () => {
    setMeeting(void 0);
    setIsRatingModalOpen(true);
  };

  const onRating = () => {
    sendRating({
      channelId: opened?.channelId,
      rating: ratingValue,
    });
    setIsRatingModalOpen(false);
  };

  return (
    <>
      <PageBreadcrumbs
        data={
          checkPermissions('CHATS', 'MENU')
            ? [
                { label: t('chats.home'), href: ROUTES.DASHBOARD.ROOT },
                { label: t('chats.origin_route'), href: ROUTES.CONSULTATIONS.ROOT },
                { label: t('chats.title') },
              ]
            : [{ label: t('chats.home'), href: ROUTES.DASHBOARD.ROOT }, { label: t('chats.title') }]
        }
      />
      <PageHeader title={t('chats.title')} />
      <Grid className={styles.outer} container spacing={2}>
        <Grid item xs={3}>
          <ChatsList chats={chats} opened={opened} onOpen={onOpen} />
        </Grid>
        {opened && meeting && (
          <Grid item xs={6}>
            <Meeting opened={opened} type={meeting} onClose={stopMeeting} />
          </Grid>
        )}
        <Grid item xs={meeting ? 3 : 9}>
          <Chat chat={opened} meeting={!!meeting} onStartMeeting={setMeeting} />
        </Grid>
      </Grid>
      <ConfirmModal
        isModalOpen={isRatingModalOpen}
        onSubmitModal={onRating}
        onClose={() => setIsRatingModalOpen(false)}
        closeText={t('chats.modal_cancel')}
        submitText={t('chats.modal_submit')}
        disabled={!ratingValue}
      >
        <div className={styles.modalHeader}>{t('chats.modal_header')}</div>
        <div className={styles.ratingWrapper}>
          <Rating value={ratingValue} setValue={setRatingValue} />
        </div>
        <div className={styles.modalText}>{t('chats.modal_text')}</div>
      </ConfirmModal>
    </>
  );
};

export default withPubNub(Chats);
