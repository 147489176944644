import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';

import { TYPES } from './actions';
import { IActDetails } from 'types/actDetails';

const initialState: IActDetails = {
  total: 0,
  data: [],
  statistic: {
    feeSum: 0,
    paymentAmount: 0,
    toPay: 0,
    terminated: false,
  },
  avaliableContracts: {},
  avaliablePagination: {
    current: 1,
    perPage: 5,
  },
  actInfo: {
    actDate: '',
    actSignDate: '',
    actualFeePercent: 0,
    agentActNumber: '',
    agentContractDate: '',
    agentContractId: 0,
    agentContractNumber: '',
    baseFeeSum: 0,
    contractCalculatedPaymentAmount: 0,
    contractPaymentAmount: 0,
    contractsCount: 0,
    dateFrom: '',
    dateTo: '',
    feeSum: 0,
    insuranceProgramId: 0,
    insuranceProgramName: '',
    partnerId: 0,
    partnerName: '',
    terminatedContractsCount: 0,
    returnedPaymentSum: 0,
    returnedToPartnerSum: 0,
    periodFeeSum: 0,
  },
  sorting: {
    field: 'conclusionDate',
    direction: 'DESC',
  },
  pending: false,
  error: false,
  pagination: {
    current: 1,
    perPage: 10,
  },
};

const setActDetails = (state: IActDetails, { payload, pending, error }: AnyAction): IActDetails => {
  return {
    ...state,
    data: payload ? payload.resultList : initialState.data,
    total: payload ? payload.count : initialState.total,
    statistic: payload ? payload.total : initialState.statistic,
    error: error,
    pending,
  };
};

const avaliableContracts = (state: IActDetails, { payload, pending, error }: AnyAction): IActDetails => {
  return {
    ...state,
    avaliableContracts: payload ? payload : initialState.data,
    error: error,
    pending,
  };
};

const setActInfo = (state: IActDetails, { payload, pending, error }: AnyAction): IActDetails => {
  return {
    ...state,
    actInfo: payload,
    pending,
    error,
  };
};

const setPending = (state: IActDetails, { pending }: AnyAction): IActDetails => {
  return {
    ...state,
    pending,
  };
};

const changeContractsStatus = (state: IActDetails, { payload }: AnyAction): IActDetails => {
  return {
    ...state,
    data: payload ? payload : initialState.data,
  };
};

const changeAvailableContractsStatus = (state: IActDetails, { payload }: AnyAction): IActDetails => {
  return {
    ...state,
    avaliableContracts: {
      ...state.avaliableContracts,
      contracts: payload ? payload : initialState.data,
    },
  };
};

const setPagination = (state: IActDetails, { payload }: AnyAction): IActDetails => {
  return {
    ...state,
    pagination: payload,
  };
};

const setAvailablePagination = (state: IActDetails, { payload }: AnyAction): IActDetails => {
  return {
    ...state,
    avaliablePagination: payload,
  };
};

const setSorting = (state: IActDetails, { payload }: any): IActDetails => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const getPrivateFiltersData = (state: IActDetails, { payload }: any): IActDetails => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    data: resultList,
    total: count,
  };
};

const changeActInfo = (state: IActDetails, { payload, pending, error }: any): IActDetails => {
  return {
    ...state,
    actInfo: {
      ...state.actInfo,
      ...payload,
    },
    pending,
    error,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setActDetails,
    setPending,
    changeContractsStatus,
    setPagination,
    setSorting,
    getPrivateFiltersData,
    setActInfo,
    changeActInfo,
    avaliableContracts,
    setAvailablePagination,
    changeAvailableContractsStatus,
  },
});
