import i18n from 'i18next';

import { object, Infer, string } from 'utils/struct';

export const schema = object({
  token: string(),
});

export type Values = Infer<typeof schema>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefaultValues = Record<keyof Values, any>;

export const defaultValues: DefaultValues = {
  token: null,
};
