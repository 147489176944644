import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Input, Checkbox, Button, Icon } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import debounce from 'lodash.debounce';

import { AppState } from 'store/reducer';

import styles from 'components/PrivateFilter/PrivateFilter.module.less';

const CheckboxGroup = Checkbox.Group;

interface IProps {
  record: {
    filters: { value: string }[];
    clearFilters: () => void;
    confirm: () => void;
  };
  dataIndex: string;
  filteredBySearching?: (filterBy: string, value: string, type: string) => void;
  getDataFromPrivateFilter: (
    additionalFilters: { name?: Array<string>; serviceTypeName?: Array<string> },
    pagination?: { current: number; perPage: number },
  ) => void;
  filtersInfo: { name?: Array<string>; serviceTypeName?: Array<string> };
  setFiltersInfo: (values: { name?: Array<string>; serviceTypeName?: Array<string> }) => void;
  isClearedFilters?: boolean | undefined;
  clearAllFilters?: () => void;
}

interface IFilters {
  value: string;
}

interface IState {
  checkedList: CheckboxValueType[] | any;
  indeterminate: boolean;
  checkAll: boolean;
}

const PrivateFilter = ({
  record,
  filteredBySearching = () => null,
  dataIndex,
  getDataFromPrivateFilter,
  filtersInfo,
  setFiltersInfo,
  isClearedFilters,
  clearAllFilters,
}: IProps) => {
  const [checkBoxData, setCheckBoxData] = useState<IState>({
    checkedList: [],
    indeterminate: true,
    checkAll: false,
  });
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState('');
  const [filterOptions, setFilterOptions] = useState<Array<string>>([]);
  const { privateFilterItems } = useSelector(({ adminList: { privateFilterItems } }: AppState) => ({
    privateFilterItems,
  }));

  useEffect(() => {
    const { filters } = record;
    // @ts-ignore
    const extraFilters = privateFilterItems[dataIndex] || [];
    setFilterOptions([...new Set([...filters.map(({ value }: { value: string }) => value), ...extraFilters])]);
  }, [record, privateFilterItems, dataIndex, setFilterOptions]);

  useEffect(() => {
    if (privateFilterItems && privateFilterItems[dataIndex]) {
      setCheckBoxData(prev => ({
        ...prev,
        checkedList: privateFilterItems[dataIndex],
      }));
    }
  }, []);

  useEffect(() => {
    if (!!isClearedFilters) {
      setCheckBoxData({
        checkedList: [],
        indeterminate: true,
        checkAll: false,
      });
      setFilterBy('');

      clearAllFilters && clearAllFilters();
    }
  }, [isClearedFilters]);

  useEffect(() => {
    if (!Object.keys(filtersInfo).length) {
      setCheckBoxData({
        checkedList: [],
        indeterminate: true,
        checkAll: false,
      });
      setFilterBy('');
    }
  }, [filtersInfo]);

  const handleSearch = debounce((value: string) => {
    setFilterBy(value);
    filteredBySearching(dataIndex, value, 'search');
    // @ts-ignore
    setFilterOptions([
      ...new Set([...checkBoxData.checkedList, ...record.filters.map((item: IFilters) => item.value)]),
    ]);
  }, 10);

  const handleFilterValues = (checkedList: CheckboxValueType[]): void => {
    const { setSelectedKeys }: any = record;

    setCheckBoxData({
      checkedList,
      indeterminate: checkedList.length < filterOptions.length,
      checkAll: checkedList.length === filterOptions.length,
    });

    setSelectedKeys(checkedList);
  };

  const onCheckAllChange = (e: any) => {
    const { checked } = e.target;
    const value: IState = {
      checkedList: checked ? filterOptions.map(item => item) : [],
      indeterminate: false,
      checkAll: checked,
    };
    setCheckBoxData(value);
  };

  const closeFilter = () => {
    const { confirm } = record;

    confirm();
    setFilterBy('');
  };

  const handleReset = () => {
    const { confirm } = record;
    setCheckBoxData({
      checkedList: [],
      indeterminate: true,
      checkAll: false,
    });

    confirm();
    setFilterBy('');
    const filters = { ...filtersInfo };

    Object.keys(filters).forEach(key => key === dataIndex && delete filters[key]);
    setFiltersInfo({ ...filters, [dataIndex]: null });

    getDataFromPrivateFilter({ ...filters });
  };

  const filteredContracts = () => {
    const { confirm } = record;

    confirm();

    const findDate = checkBoxData.checkedList;

    let finalOption = findDate;

    getDataFromPrivateFilter({ ...filtersInfo, [dataIndex]: [...finalOption] });
  };

  const filteredItems = filterOptions.filter(
    item =>
      item &&
      item !== 'undefined' &&
      item !== 'null' &&
      item.toLocaleLowerCase().indexOf(filterBy.toLocaleLowerCase()) !== -1,
  );

  return (
    <div className={styles.filterContainer}>
      <Card
        title={t('admin_panel.filters_private')}
        bordered={false}
        extra={<span onClick={closeFilter}>&times;</span>}
      >
        <Input
          placeholder={t('admin_panel.search_private')}
          prefix={<Icon type="search" style={{ color: '#A9B5BD', marginTop: 5 }} />}
          onChange={evt => handleSearch(evt.target.value)}
          value={filterBy}
        />
        <div className={styles.filterContainer_content}>
          <div style={{ borderBottom: '1px solid #E9E9E9' }}>
            <Checkbox
              style={{ padding: '9px 0 9px 16px' }}
              indeterminate={checkBoxData.indeterminate}
              onChange={onCheckAllChange}
              checked={checkBoxData.checkAll}
            >
              {t('admin_panel.select_all_private')}
            </Checkbox>
          </div>
          <div className={styles.filterContainer_content_ItemsWrapper}>
            <CheckboxGroup
              options={Array.from(new Set(filteredItems))}
              value={checkBoxData.checkedList}
              onChange={handleFilterValues}
            />
          </div>
        </div>
        <div className={styles.footerFilterCard}>
          <Button type="link" onClick={handleReset}>
            {t('admin_panel.clean_private')}
          </Button>
          <Button type="link" onClick={filteredContracts}>
            {t('admin_panel.filter_private')}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default PrivateFilter;
