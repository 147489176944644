import styled from 'styled-components';

type Custom = {
  borderB?: boolean;
  borderT?: boolean;
  bottomM?: number;
  buttons?: boolean;
  error?: boolean;
  lgHeight?: number;
  lgWidth?: number;
  light?: boolean;
  mb?: number;
  size?: number;
  sm?: boolean;
  weight?: number;
  width?: string;
};

export const CardGrid = styled.div<Custom>`
  width: 100%;

  // fix for broken phone number input styles
  .fix-phonenumber {
    input {
      transform: translate(17px, 20px);
    }

    .phone-prefix {
      top: 21px;
    }
  }
`;

export const Cards = styled.div<Custom>`
  background: #fff;
  border-radius: 14px;
  margin-bottom: 16px;
  min-height: ${props => `${props.lgHeight}px`};
  padding: 20px 16px 16px;
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const Text = styled.p<Custom>`
  color: #363636;
  font-family: 'SF UI Display', Helvetica, Arial, sans-serif;
  font-size: ${props => (props.size ? `${props.size}px` : '14px')};
  font-weight: ${props => (props.weight ? `${props.weight}` : '400')};
  margin: ${props => (props.bottomM ? `0 5px ${props.bottomM}px 0` : '0 5px 0 0')};
  white-space: pre-wrap;
`;

export const ModalHeader = styled.h5`
  color: #363636;
  font-family: 'SF UI Display', Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
`;

export const ModalText = styled.p`
  color: #a9b5bd;
  font-family: 'Abel';
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
`;
