import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

import Table from 'components/next/Table/Table';
import config from 'config/config';
import { PartnerDepartmentStatisticsDto } from 'types/dto/contracts-service';

interface Props {
  data: PartnerDepartmentStatisticsDto[];
  currency: string;
}

const ByDepartmentTable = ({ data, currency }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<PartnerDepartmentStatisticsDto>[] = [
    {
      title: t('dashboard_extend.byDepartment.partnerDepartment'),
      key: 'partnerDepartmentNumber',
      render: (_, record) => `ТП №${record.partnerDepartmentNumber}`,
    },
    {
      title: t('dashboard_extend.byDepartment.countContracts'),
      key: 'countContracts',
      render: (_, record) => record.countContracts,
    },
    {
      title: t('dashboard_extend.byDepartment.contractPaymentSum'),
      key: 'contractPaymentSum',
      render: (_, record) => `${numeral(record.contractPaymentSum).format(config.format.financial)} ${currency}`,
    },
    {
      title: t('dashboard_extend.byDepartment.countClaims'),
      key: 'countClaims',
      render: (_, record) => record.countClaims,
    },
    {
      title: t('dashboard_extend.byDepartment.claimPaymentSum'),
      key: 'claimPaymentSum',
      render: (_, record) => `${numeral(record.claimPaymentSum).format(config.format.financial)} ${currency}`,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={record => (record.partnerDepartmentId ?? 0).toString()}
      scroll={{ x: 600, y: 265 }}
      pagination={false}
    />
  );
};

const Memoized = React.memo(ByDepartmentTable);

export default Memoized;
