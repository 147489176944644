import React, { useCallback } from 'react';
import Table from 'components/Table';
import columnsList from './columns';
import DateLabel from 'components/DateLabel';

import styles from '../ContractDetailsTables.module.less';
import { moneyFormatter } from 'utils/helpers';
import { statusLabelsData } from 'utils/statuses';
import Chip from '../../../Chip';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import { checkPermissions } from '../../../../roles/userCan';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCurrency } from '../../../../hooks/useCurrency';
import { AppState } from 'store/reducer';
import { ContractClaimDetailsDto } from 'types/dto/contracts-service';

interface ITableProps {
  repaymentsDetails: ContractClaimDetailsDto[];
}

const getStatusLabel = (status: string, t) => {
  const resTag = statusLabelsData(t).find((tag: any) => tag.status === status);
  if (!!resTag) {
    return <Chip color={(resTag as any).label} label={resTag.title} />;
  }
};

export default ({ repaymentsDetails }: ITableProps) => {
  const { t } = useTranslation();
  const { currentCompanyCurrency } = useSelector((store: AppState) => ({
    currentCompanyCurrency: store.user.currentCompanyCurrency,
  }));

  const { currency } = useCurrency();

  const columns = columnsList(t).map(({ title, value }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
      width: 100,
    };
    switch (value) {
      case 'claimDate':
      case 'paymentDate': {
        return {
          ...basicColumn,
          render: (date: any) => <DateLabel>{date}</DateLabel>,
        };
      }
      case 'risk': {
        return {
          ...basicColumn,
          width: 150,
        };
      }
      case 'expectedLoss':
      case 'franchise':
      case 'claimSum':
      case 'paymentSum': {
        return {
          ...basicColumn,
          width: 100,
          render: (value: number) => `${moneyFormatter(value)} ${currentCompanyCurrency || currency}`,
        };
      }
      case 'claimStatus': {
        return {
          ...basicColumn,
          width: 150,
          render: (status: string) => getStatusLabel(status, t),
        };
      }
      default: {
        return basicColumn;
      }
    }
  });

  const handleRowClick = useCallback((record: any) => {
    record.claimId && history.push(ROUTES.INSURED_EVENTS.READ.ROOT.replace(':id', String(record.claimId)));
  }, []);

  return (
    <Table
      className={styles.table}
      columns={columns}
      onRow={record => ({
        onClick: evt => {
          evt.stopPropagation();
          checkPermissions('INSURANCE', 'READ') && handleRowClick(record);
        },
      })}
      pagination={false}
      scroll={{ x: 1280 }}
      rowKey={record => `${record.claimNumber}`}
      dataSource={repaymentsDetails}
      bordered
    />
  );
};
