import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { Form, Button, Typography, Input } from 'antd';
import PhoneNumberInput from 'components/PhoneNumberInput';

import { ISecondStep } from '../types';
import { RULES } from '../utils';

import * as sign from '../../SignUp/SignUp.module.less';
import ResendSmsCode from '../widgets/ResendSmsCode';
import { useTranslation } from 'react-i18next';

const SecondStep: FunctionComponent<ISecondStep> = ({
  form: { getFieldDecorator },
  fieldsActiveState,
  handleFields,
  values,
  changeStep,
  resendSms,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography.Title level={3}>{t('signup.code_from_sms')}</Typography.Title>
      <Typography.Text className={sign.notification_text} type="secondary" />
      <Form.Item
        className={classNames(sign.signup_items, sign.phoneinputLabel, {
          [sign.phonelabelActive]: fieldsActiveState.phoneNumber,
        })}
      >
        {getFieldDecorator('phoneNumber', {
          rules: [...RULES(t).PHONE],
          initialValue: values.phoneNumber,
        })(
          <PhoneNumberInput
            title={t('signup.phone_number')}
            disabled={true}
            suffix={
              <button onClick={() => changeStep(0)} className={classNames(sign.zero_margins, sign.green_text)}>
                {t('signup.change')}
              </button>
            }
          />,
        )}
      </Form.Item>
      <Form.Item className={sign.signup_items}>
        {getFieldDecorator('token', {
          rules: [...RULES(t).TOKEN],
        })(
          <Input
            size="large"
            onFocus={e => handleFields(e.type, 'token')}
            onBlur={e => handleFields(e.type, 'token')}
          />,
        )}
        <div className={classNames(sign.inputLabel, { [sign.labelActive]: fieldsActiveState.token })}>
          {t('signup.code_from_sms')}
        </div>
      </Form.Item>
      <ResendSmsCode sendAction={resendSms} />
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="login-form-button">
          {t('signup.next')}
        </Button>
      </Form.Item>
    </>
  );
};

export default SecondStep;
