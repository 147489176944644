import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Device, Chat } from 'types/chats';

const useOpenTok = (opened: Chat) => {
  const [microphone, setMicrophone] = useState(true);
  const [camera, setCamera] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const { location } = useHistory();

  const otProperties = {
    session: {
      sessionId: location?.state?.sessionId ?? opened.sessionId,
      token: location?.state?.token ?? opened.token,
      apiKey: process.env.REACT_APP_VONAGE_API_KEY ?? '',
    },
    subscriber: {
      showControls: false,
      width: '100%',
      height: '100%',
    },
    publisher: {
      showControls: false,
      width: 160,
      height: 100,
      publishAudio: microphone,
      // publishVideo: meetingType === MeetingType.Video && camera,
      publishVideo: camera,
      videoSource: isScreenSharing ? 'screen' : undefined,
    },
  };

  const toggleScreenSharing = () => {
    setIsScreenSharing(isScreenSharing => !isScreenSharing);
  };

  const toggle = {
    [Device.Camera]: setCamera,
    [Device.Microphone]: setMicrophone,
  };

  const setDevice = (device: Device, state: boolean) => {
    toggle[device](state);
  };

  return {
    otProperties,
    devices: {
      microphone,
      camera,
    },
    setDevice,
    isScreenSharing,
    toggleScreenSharing,
  };
};

export default useOpenTok;
