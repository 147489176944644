import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Login from 'components/auth/Login';
import LoginNext from 'screens/Login/Login';
import PasswordRecovery from 'components/auth/PasswordRecovery';
import PasswordRecoveryNext from 'screens/PasswordRecovery/PasswordRecovery';
import SignUp from 'components/auth/SignUp';
import ManagerSignUp from 'components/auth/ManagerSignUp';
import ManagerSignUpNext from 'screens/SignUp/SignUp';
import LayoutRouting from './LayoutRouting';

import history from './history';
import ErrorPageBoundary from './ErrorPageBoundary';
import { ClientSign } from '../components/ClientSign/ClientSign';
import { PaymentResultPage } from '../components/ClientSign/pages/PaymentResultPage';
import JoinConsultationForDoctor from 'screens/JoinConsultationForDoctor';
import VideoChatForDoctor from 'screens/VideoChatForDoctor';

const Routes = () => (
  <Router history={history}>
    <ErrorPageBoundary>
      <Switch>
        <Redirect exact from={ROUTES.ROOT} to={ROUTES.PARTNER_AUTH.LOGIN} />
        <Route exact path={ROUTES.JOIN_CONSULTATION_FOR_DOCTOR} component={JoinConsultationForDoctor} />
        <Route exact path={ROUTES.VIDEO_CHAT_FOR_DOCTOR} component={VideoChatForDoctor} />
        <Route exact path={ROUTES.PARTNER_AUTH.LOGIN} component={LoginNext} />
        <Route exact path={ROUTES.AUTH.LOGIN} component={Login} />
        <Route exact path={ROUTES.PARTNER_AUTH.PASSWORD_RECOVERY} component={PasswordRecoveryNext} />
        <Route exact path={ROUTES.AUTH.PASSWORD_RECOVERY} component={PasswordRecovery} />
        <Route exact path={ROUTES.AUTH.INSURER_SIGNUP} component={SignUp} />
        <Route exact path={ROUTES.AUTH.MANAGER_SIGNUP} component={ManagerSignUp} />
        {/* <Route exact path={ROUTES.AUTH.MANAGER_SIGNUP + '1'} component={ManagerSignUpNext} /> */}
        <Route exact path={ROUTES.CLIENT_SIGN} component={ClientSign} />
        <Route exact path={ROUTES.CLIENT_PAYMENT} component={PaymentResultPage} />
        <Route component={LayoutRouting} />
      </Switch>
    </ErrorPageBoundary>
  </Router>
);

export default Routes;
