import React from 'react';
import { Empty, Select, Tooltip } from 'antd';
import { ChevronIcon } from 'components/Icons';

import styles from './FormSelect.module.less';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

interface IFormSelect {
  title?: string;
  options?: any[];
  value?: string | number | undefined | string[];
  placeholder?: string;
  getPopupContainer?: any;
  isRequired?: boolean;
  disabled?: boolean;
  onChange?: (value: any, o: any) => void;
  dataId?: string;
  selectDataId?: string;
  optionDataId?: string;
  className?: string;
  dropdownMenuStyle?: object;
  dropdownMatchSelectWidth?: boolean;
  showSearch?: boolean;
  defaultValue?: string | number | undefined;
  mode?: 'default' | 'multiple';
  onSearch?: (value: string) => void;
  filterOption?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onselect?: (value: string) => void;
}

class FormSelect extends React.Component<IFormSelect> {
  render() {
    const {
      title,
      options,
      value,
      placeholder,
      onChange,
      isRequired,
      disabled,
      dataId,
      selectDataId,
      optionDataId,
      className,
      dropdownMenuStyle,
      dropdownMatchSelectWidth = true,
      showSearch = false,
      defaultValue,
      mode = 'default',
      onSearch,
      filterOption,
      onFocus,
      onBlur,
      onselect,
      t,
    }: any = this.props;

    return (
      <div data-id={dataId} className={classNames('form-select-holder', styles.formSelect__container, className)}>
        <p>
          {title}
          {isRequired && <span>*</span>}
        </p>
        <Select
          value={value}
          showSearch={showSearch}
          suffixIcon={<ChevronIcon />}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          data-id={selectDataId}
          dropdownStyle={dropdownMenuStyle}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          notFoundContent={<Empty description={t('no_data.no_data_antd')} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          mode={mode}
          onSearch={onSearch}
          filterOption={filterOption}
          onFocus={onFocus}
          onBlur={onBlur}
          onSelect={onselect}
        >
          {options &&
            options.map((elem: any) => {
              return (
                <Select.Option
                  className={styles.Option}
                  value={elem.value || elem}
                  key={elem.key || elem}
                  data-id={optionDataId}
                >
                  <Tooltip title={elem.title || elem}>{elem.title || elem}</Tooltip>
                </Select.Option>
              );
            })}
        </Select>
      </div>
    );
  }
}

export default withTranslation()(FormSelect);
