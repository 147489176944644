import { createTypes } from 'redux-compose-reducer';
import { AppState } from 'store/reducer';
import * as programsAPI from 'api/insurancePrograms';
import moment from 'moment';
import { notification } from 'antd';
import onDownload from '../../callbacks/onDownload';
import momentTz from 'moment-timezone';
import { Dispatch } from 'redux';

export const TYPES = createTypes('insuranceProgramsList', [
  'setTablePage',
  'setPagination',
  'setSorting',
  'getPrivateFiltersValues',
  'getPrivateFiltersData',
  'setFilters',
]);

export const getTablePage = () => async (dispatch: any, getState: any) => {
  const {
    insuranceProgramsList: { pagination, sorting, filters },
  }: AppState = getState();

  dispatch({ type: TYPES.setTablePage, pending: true });

  try {
    const response = await programsAPI.getTablePage({ pagination, sorting, filters });
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.setTablePage, payload, pending: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setTablePage, pending: false });
  }
};

export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setSorting = ({ field, direction }: { field: string; direction: string }) => (dispatch: any) => {
  dispatch({ type: TYPES.setSorting, payload: { field, direction } });
};

export const setFilters = (filters: object) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setFilters, payload: filters });
};

export const getPrivateFiltersValues = (
  {
    filterField,
    filterParams,
  }: {
    filterField: string;
    filterParams: string;
  },
  t,
) => async (dispatch: any) => {
  let params = '';

  if (filterField === 'endDate' || filterField === 'startDate' || filterField === 'createdDate') {
    params = `${moment(filterParams[0]).format('YYYY-MM-DD')}..${moment(filterParams[1]).format('YYYY-MM-DD')}`;
  } else {
    params = filterParams;
  }
  try {
    const response = await programsAPI.getPrivateFiltersColumns({
      filterParams: params,
      filterField,
    });
    const paramsList: any = response.data ? response.data : [];
    const checkValues =
      filterField === 'active'
        ? paramsList.map((stat: string) => {
            return stat ? true : false;
          })
        : paramsList;
    dispatch({ type: TYPES.getPrivateFiltersValues, payload: { filterField, filterParams: checkValues } });
  } catch (error) {
    notification.error({
      message: 'Помилка оновлення фільтрів',
      description: 'Спробуйте ще раз пізніше',
    });
  }
};

export const getFilteredContracts = (filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    insuranceProgramsList: {
      sorting,
      pagination,
      filters: { dateFrom, dateTo, insuranceRules, insuranceTypeName, insuranceCompanyId, insuranceProgramName },
    },
  }: AppState = getState();

  try {
    const response = await programsAPI.getPrivateFiltersTable({
      filters: {
        insuranceRules,
        insuranceTypeName,
        insuranceCompanyId,
        insuranceProgramName,
        createdDate:
          dateFrom && dateTo && `${moment(dateFrom).format('YYYY-MM-DD')}..${moment(dateTo).format('YYYY-MM-DD')}`,
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-createdDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
    });
    const payload = response.data ? response.data : [];
    dispatch({ type: TYPES.getPrivateFiltersData, payload });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};

export const programsXSLXDownload = t => async (dispatch, getState) => {
  const {
    insuranceProgramsList: {
      filters: { dateFrom, dateTo, insuranceRules, insuranceTypeName, insuranceCompanyId, insuranceProgramName },
    },
  }: AppState = getState();

  const timeZone = momentTz.tz.guess(true);

  return onDownload(
    () =>
      programsAPI.printUsersXLSXList({
        createdDate:
          dateFrom && dateTo && `${moment(dateFrom).format('YYYY-MM-DD')}..${moment(dateTo).format('YYYY-MM-DD')}`,
        insuranceRules,
        insuranceTypeName: insuranceTypeName,
        insuranceCompanyId,
        name: insuranceProgramName,
        timeZone,
      }),
    {},
    t,
  );
};
