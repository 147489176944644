import createInstance from './axios';
import { AxiosResponse } from 'axios';
import { InsuranceImportDetail } from 'types/insuranceImport';
import _ from 'lodash';
import moment from 'moment';
import { makerRequestParams } from 'utils/helpers';
import { getActionType } from '../screens/InsuranceContracts/List/components/Table/utils/formatter';
import {
  OptionDto,
  ResultListDtoInsuranceProductView,
  ResultListDtoInsuranceProgramView,
} from 'types/dto/configuration-service';
import {
  ResultListDtoIntegrationHistoryItemView,
  IntegrationPackageInformationDto,
  ResultListDtoIntegrationItemView,
  IntegrationItemCountDto,
  ResultListDtoPartnerView,
} from 'types/dto/contracts-service';

type Contract = {
  contractNumber: string;
  conclusionDate: string;
  lastName: string;
  firstName: string;
  middleName: string;
  phoneNumber: string;
  birthday: string;
  startDate: string;
  endDate: string;
  citizenship: string;
  insuredPerson: string;
  email: string;
  paymentAmount: string;
  commission: string;
  agent: string;
  objectCount: string;
  insuranceSum: string;
  checkNumber: string;
  paymentSystem: string;
  insuranceProgram: string;
};

type Person = {
  contractNumber: string;
  orderNumber: string;
  lastName: string;
  firstName: string;
  middleName: string;
  phoneNumber: string;
  birthday: string;
  email: string;
  passport: string;
  insuranceSum: string;
  startDate: string;
  endDate: string;
};

type RequestData = {
  partner: string;
  downloadType: string;
  contracts: Contract[];
  persons: Person[];
};

type List = {
  resultList: InsuranceImportDetail[];
  count: number;
};

interface IResultList {
  id: number;
  name: string;
  code: string;
  identifier: string;
  type: string;
  shortName: string;
}

interface ConfigurationList {
  data: {
    resultList: IResultList[];
    count: number;
  };
}

export interface ICustomPagination {
  current: number;
  perPage: number;
}

export interface IInfoPagination {
  page: number;
  size: number;
  total: number;
  t?: any;
}

interface IParams {
  partnerId: string;
  insuranceProductId: string;
  contractTransferConfigurationId: string;
  startSignDate: string;
  endSignDate: string;
}

const api = createInstance('CONTRACTS');
const apiFilesAPI = createInstance('CONTRACTS', 'uploadFile');
const configApi = createInstance('CONFIGURATION');

export const sendInsuranceContracts = (reqObj: RequestData) =>
  api.post<IntegrationPackageInformationDto>(`/integrations/import`, { ...reqObj });

export const getProductsData = partnerId => {
  return configApi.get<ResultListDtoInsuranceProductView>(
    `/insurance-products/?distinct=&partnerId=${partnerId}&attributes=id,name,code`,
  );
};

export const getTransferConfig = async params => {
  return configApi.get<OptionDto[]>(`/integrations`, {
    params: {
      partnerId: params.partnerId,
      processType: params.processType,
    },
  });
};

export const getInsuranceContracts = (pagination: ICustomPagination) => {
  return api.get<ResultListDtoIntegrationItemView>('/integrations?count&sorting=-operationDate,-id', {
    params: {
      attributes:
        'id,transferType,insuranceProductId,insuranceProductName,partnerId,partnerName,insuranceProductId,insuranceProductName,insuranceProgramId,insuranceProgramName,packageNumber,type,operationDate,entitiesToTransferCount,successCount,failedCount,initiator',
      page: pagination.current,
      page_size: pagination.perPage,
    },
  });
};

export const getContractsHistories = (
  sorting: { field: string; direction: string },
  id?: number,
  pagination?: IInfoPagination,
) => {
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;
  return api.get<ResultListDtoIntegrationHistoryItemView>(`/integrations/histories?count&historyId=${id}`, {
    params: {
      page: pagination?.page || 1,
      page_size: pagination?.size || 10,
      sorting: sortingQuery,
    },
  });
};

export const getContractExport = (params: IParams) => {
  return api.post<IntegrationItemCountDto>(`/integrations/count`, {
    ...params,
  });
};

export const exportContracts = (params: IParams) => {
  return api.post<IntegrationPackageInformationDto>('/integrations/export', {
    ...params,
  });
};

export const getTransferStatus = (transferPackageId: number) => {
  return api.get<IntegrationPackageInformationDto>(`/integrations/status/${transferPackageId}`);
};

export const getPrivateFiltersTable = ({
  filters,
  pagination,
  t,
}: {
  filters: any;
  pagination: { current: number | string; perPage: number | string };
  t?: any;
}) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  const date =
    filters.operationDate && filters.operationDate.includes('|')
      ? filters.operationDate
          .split('|')
          .map(item => moment(item, 'DD.MM.YYYY').format('YYYY-MM-DD'))
          .join('|')
      : moment(filters.operationDate, 'DD.MM.YYYY').format('YYYY-MM-DD');

  const formattedType = filters.importType ? _.invert(getActionType(t))[filters.importType] : undefined;
  const transferType = filters.transferType ? formattedType : undefined;

  const spareData =
    filters.dateFrom &&
    filters.dateTo &&
    `${moment(filters.dateFrom).format('YYYY-MM-DD')}..${moment(filters.dateTo).format('YYYY-MM-DD')}`;

  return api.get<ResultListDtoIntegrationItemView>('/integrations?count', {
    params: {
      attributes:
        'id,transferType,insuranceProductId,insuranceProductName,partnerId,partnerName,insuranceProductId,insuranceProductName,insuranceProgramId,insuranceProgramName,packageNumber,type,operationDate,entitiesToTransferCount,successCount,failedCount,initiator',
      ..._.omit(filters, ['dateTo', 'dateFrom', 'importType', 'type', 'productId']),
      transferType,
      type: filters.importType !== 'Усі' ? formattedType : undefined,
      operationDate: date === 'Invalid date' ? spareData : date,
      page_size: pagination.perPage,
      insuranceProductId: filters.productId !== 'Усі' ? filters.productId : undefined,
      page: pagination.current,
    },
  });
};

export const getTablePageData = ({ filters, sorting, pagination }: any, t?) => {
  const findType = filters.importType ? _.invert(getActionType(t))[filters.importType] : undefined;
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;
  const requestParams = {
    attributes:
      'id,transferType,insuranceProductId,insuranceProductName,partnerId,partnerName,insuranceProductId,insuranceProductName,insuranceProgramId,insuranceProgramName,packageNumber,type,operationDate,entitiesToTransferCount,successCount,failedCount,initiator',
    page: pagination.current,
    page_size: pagination.perPage,
    operationDate:
      filters.dateFrom &&
      filters.dateTo &&
      `${moment(filters.dateFrom).format('YYYY-MM-DD')}..${moment(filters.dateTo).format('YYYY-MM-DD')}`,
    ..._.omit(filters, ['dateFrom', 'dateTo', 'importType', 'productId']),
    insuranceProgramId: filters.insuranceProgramId !== 'Усі' ? filters.insuranceProgramId : undefined,
    type: filters.importType !== 'Усі' ? findType : undefined,
    partnerId: filters.partnerId !== 'Усі' ? filters.partnerId : undefined,
    insuranceProductId: filters.productId !== 'Усі' ? filters.productId : undefined,
    sorting: sortingQuery,
    distinct: true,
  };
  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);
  return api.get<ResultListDtoIntegrationItemView>('/integrations?count', {
    params: {
      ...requestParams,
    },
  });
};

export const getIntegrationTypes = () => {
  return api.get<ResultListDtoIntegrationItemView>('/integrations?attributes=type&distinct');
};

export const getPrograms = () => {
  return api.get<ResultListDtoIntegrationItemView>('/integrations?attributes=insuranceProgramId&distinct');
};

export const getPartners = () => {
  return api.get<ResultListDtoPartnerView>('/partners?&page_size=300&attributes=id,name,shortName');
};

export const getInsPrograms = (partnerId: string, productId?: number) => {
  return configApi.get<ResultListDtoInsuranceProgramView>(
    `/insurance-programs?attributes=id,name&partnerId=${partnerId || '<>null'}&distinct&${
      productId ? `insuranceProductId=${productId}` : ''
    }`,
  );
};

export const getInsProducts = (partnerId: string) => {
  return configApi.get<ResultListDtoInsuranceProductView>(`/insurance-products?distinct&partnerId=${partnerId}`);
};

export const getPrivateFiltersHistory = (id, { filters, pagination }) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  return api.get<ResultListDtoIntegrationHistoryItemView>(`/integrations/histories?count&historyId=${id}`, {
    params: {
      ..._.omit(filters),
      page: pagination?.current || 1,
      page_size: pagination?.perPage || 10,
    },
  });
};

export const getTablePageHistory = (id, { filters, sorting, pagination }: any) => {
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;
  const requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    sorting: sortingQuery,
    distinct: true,
  };
  //@ts-ignore
  Object.keys(requestParams).forEach(key => !requestParams[key] && delete requestParams[key]);

  return api.get<ResultListDtoIntegrationHistoryItemView>(`/integrations/histories?count&historyId=${id}`, {
    params: {
      ...requestParams,
    },
  });
};

export const sendAutoVerification = (reqObj: any) => {
  const formData = new FormData();
  formData.append('file', reqObj.file);
  return apiFilesAPI.post(`/integrations/auto-verification/${reqObj.configurationId}`, formData, reqObj.file);
};
