import moment from 'moment';

interface IroleAdapter {
  [key: string]: string;
}

export const roleAdapter: IroleAdapter = {
  ROLE_ADMIN: 'admin_panel.system_administrator',
  ROLE_PARTNER_SELLER: 'admin_panel.seller',
  ROLE_PARTNER_MANAGER: 'admin_panel.partner_office_manager',
  ROLE_PARTNER_ADMIN: 'admin_panel.administrator_partners_central_office',
  ROLE_INSURANCE_MANAGER: 'admin_panel.sc_manager',
  ROLE_INSURANCE_CLAIM_MANAGER: 'admin_panel.payments_sc_manager',
  ROLE_INSURANCE_ADMIN: 'admin_panel.sc_administrator',
  ROLE_CLIENT: 'admin_panel.client',
  ROLE_SYSTEM_PARTNER: 'admin_panel.partner_system',
  ROLE_HR_MANAGER: 'admin_panel.HR_manager',
};

export const findRoleAdapter = t => {
  return {
    ROLE_ADMIN: t('admin_panel.system_administrator'),
    ROLE_PARTNER_SELLER: t('admin_panel.seller'),
    ROLE_PARTNER_MANAGER: t('admin_panel.partner_office_manager'),
    ROLE_PARTNER_ADMIN: t('admin_panel.administrator_partners_central_office'),
    ROLE_INSURANCE_MANAGER: t('admin_panel.sc_manager'),
    ROLE_INSURANCE_CLAIM_MANAGER: t('admin_panel.payments_sc_manager'),
    ROLE_INSURANCE_ADMIN: t('admin_panel.sc_administrator'),
    ROLE_CLIENT: t('admin_panel.client'),
    ROLE_SYSTEM_PARTNER: t('admin_panel.partner_system'),
    ROLE_HR_MANAGER: t('admin_panel.HR_manager'),
  };
};

export const formatData = (data: any[], t) => {
  return data?.map((el: any) => ({
    ...el,
    role: el.role ? t(roleAdapter[el.role]) : '',
  }));
};
export const formatDate = (date: string) => {
  const localDate = moment(moment.utc(date).toDate()).local();
  return moment(localDate).format('DD.MM.YYYY / HH:mm:ss');
};

export const statusAdapter: IroleAdapter = {
  INACTIVE: 'user_card.deactivated',
  ACTIVE: 'user_card.activated',
  PENDING: 'user_card.expects',
};
