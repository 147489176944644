import { deleteDuplicatesFromList } from 'utils/helpers';

export const allOptionInsurance = {
  contractNumber: 'Усі',
  id: 'Усі',
  key: 'Усі',
  name: 'Усі',
  value: 'Усі',
};

export enum CHOOSE_FILTER_TYPE {
  EVENT_DATE = 'insured_events_list.the_date_of_event',
  APPLICATION_DATE = 'insured_events_list.date_of_application',
  PAYMENT_DATE = 'insured_events_list.date_of_payment',
}

export const getAllOptionInsuranceAct = t => ({
  id: 0,
  name: t('insurance_contracts.everyone_select'),
  value: t('insurance_contracts.everyone_select'),
  label: t('insurance_contracts.everyone_select'),
  key: t('insurance_contracts.everyone_select'),
});

export const defaultValuesAll = t => ({ name: t('contract_list.everyone'), key: 'all', id: 0 });

export const allOptionInsuranceProgram = (t?: any) => ({ value: 0, label: t('dash_board.everyone') });

export const chooseForOptionsFilter = t => {
  const { EVENT_DATE, APPLICATION_DATE, PAYMENT_DATE } = CHOOSE_FILTER_TYPE;
  return [
    { name: t(EVENT_DATE), id: t(EVENT_DATE) },
    { name: t(APPLICATION_DATE), id: t(APPLICATION_DATE) },
    { name: t(PAYMENT_DATE), id: t(PAYMENT_DATE) },
  ];
};

export const extraOptionsAll = (list: any, t) => {
  const listWithoutDuplicates = deleteDuplicatesFromList(list);
  return [defaultValuesAll(t), ...listWithoutDuplicates];
};
