import React, { FunctionComponent, useState } from 'react';
import { AutoComplete, Input, Form, notification } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import * as sign from '../../SignUp/SignUp.module.less';
import { RULES } from '../utils';
import * as auth from 'api/auth';
import { IDepartmentList, TDepartmentsType } from '../types';
import { useTranslation } from 'react-i18next';

const DepartmentInput: FunctionComponent<IDepartmentList> = ({
  fieldsActiveState,
  values,
  handleFields,
  setPartnerId,
  form: { getFieldDecorator, setFieldsValue, setFields },
}) => {
  const [valueInput, setValueInput] = useState<string>('');
  const [dataSource, setDataSource] = useState<TDepartmentsType[]>([]);
  const { t } = useTranslation();

  const handleBlur = (): void => {
    handleFields('blur', 'department');
    const findObject = dataSource.filter(data => data.name.toLowerCase() == valueInput.toLowerCase()); //eslint-disable-line

    if (dataSource.length > 0 && findObject && findObject.length) {
      setFieldsValue({ partnerDepartment: valueInput });
      setPartnerId(findObject);
      return;
    }

    setValueInput('');
    setDataSource([]);
    setFields({
      partnerDepartment: {
        value: '',
        errors: [new Error(t('signup.no_data_found'))],
      },
    });
  };

  const handleInput = _.debounce(async (value: string) => {
    if (!value) {
      setValueInput(value);
      setDataSource([]);
      return;
    }
    try {
      if (value && value.length >= 3) {
        setValueInput(value);

        const { data } = await auth.getDepartmentsList(value, values.partner.id);
        setDataSource(data);
      }
    } catch (e) {
      notification.error({ message: t('signup.loading_units') });
    }
  }, 200);

  const handleSelect = (value: any) => {
    const findObject = dataSource.filter(data => +data.id === +value);
    findObject && setValueInput(findObject[0].name);
  };

  return (
    <Form.Item className={classNames(sign.signup_items, sign.noWidth)} validateStatus="error">
      {getFieldDecorator('partnerDepartment', {
        rules: [...RULES(t).DEPARTMENT],
        initialValue: '',
        validateFirst: true,
      })(
        <AutoComplete
          onBlur={handleBlur}
          onSelect={handleSelect}
          onSearch={handleInput}
          dataSource={dataSource.map((item: any) => ({ text: item.name, value: item.id })) || []}
          onFocus={() => handleFields('focus', 'department')}
          filterOption={(inputValue, option) => {
            return (
              option?.props?.children
                ?.toString()
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            );
          }}
        >
          <Input />
        </AutoComplete>,
      )}
      <div
        style={{ zIndex: 1 }}
        className={classNames(sign.inputLabel, sign.gray_text, {
          [sign.labelActive]: fieldsActiveState.department || valueInput,
        })}
      >
        {t('signup.units')}
      </div>
    </Form.Item>
  );
};

export default DepartmentInput;
