import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Input from './../Input';
import { ClickAwayListener } from '@material-ui/core';
import MenuItem from '../MenuItem';
import useMenuStyles, { IUseMenuStylesProps } from '../../FiltersBar/components/Select/useMenuStyles';
import theme from '../../../styles/theme';
import { makeStyles } from '@material-ui/core/styles';
import LoadingSpinner from './components/LoadingSpinner';
import useInputStyles from '../styles/useInputStyles';
import mark from './mark';
import NoData from '../NoData';
import Popper from '@material-ui/core/Popper';

export interface IAutocompleteProps<Option = any> {
  loading?: boolean;
  options: Array<Option>;
  onChange: (option: Option, key?: number) => void;
  onQueryChange: (query: string) => void;
  value?: any;
  MenuProps?: any;
  InputProps?: any;
  InputLabelProps?: any;
  label?: string;
  labelKey?: string;
  valueKey?: string;
  fullWidth?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  noSelected?: boolean;
  isRequired?: boolean;
  disabledOpenQuery?: boolean;
}

const useStyles = makeStyles({
  selected: {
    color: theme.pallete.primary.main,
  },
});

const styles = makeStyles({
  paper: {
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.12)',
    borderRadius: '0 0 14px 14px',
    clipPath: 'inset(0px -50px -50px)',
    marginTop: (props: IUseMenuStylesProps) => (typeof props.offset === 'undefined' ? -15 : -props.offset - 1),
  },
  root: {
    backgroundColor: (props: IUseMenuStylesProps) => props.color || 'white',
    padding: (props: IUseMenuStylesProps) =>
      typeof props.offset === 'undefined' ? '14px 0 0 0' : props.offset + 'px 0 0 0',
    maxHeight: '300px',
    overflow: 'auto',
    margin: '-13px -2px -10px',
    borderRadius: '10px',
    paddingBottom: '10px',
  },
  list: {
    backgroundColor: (props: IUseMenuStylesProps) => props.color || 'white',
    padding: (props: IUseMenuStylesProps) =>
      typeof props.offset === 'undefined' ? '14px 0 0 0' : props.offset + 'px 0 0 0',
  },
});

const Autocomplete: React.FC<IAutocompleteProps> = React.forwardRef(
  (
    {
      onQueryChange,
      loading,
      onChange,
      value,
      fullWidth,
      labelKey = 'label',
      valueKey = 'value',
      options = [],
      InputProps = {},
      MenuProps = {},
      InputLabelProps = {},
      noSelected = false,
      isRequired = false,
      disabledOpenQuery = false,
      ...props
    },
    ref,
  ) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const selected = useMemo(() => options.find(o => o[valueKey] === value), [valueKey, value, options, menuVisible]);
    const [query, setQuery] = useState(selected ? selected[labelKey] : value);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const menuClasses = useMenuStyles({
      color: theme.pallete.blueGray.light,
      offset: 10,
      height: '100vh',
      width: '100vw',
    });
    const classes1 = styles({ color: theme.pallete.basic.white, offset: 0, height: '100vh', width: '100vw' });
    const holderRef = useRef<HTMLDivElement>(null);
    const rootRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const classes = useStyles();
    const inputClasses = useInputStyles({
      color: theme.pallete.blueGray.light,
      activeColor: theme.pallete.blueGray.light,
      height: 56,
    });

    const inputValue = menuVisible ? query : selected ? selected[labelKey] : value;

    useEffect(() => {
      setAnchorEl(rootRef.current);
    }, []);

    useEffect(() => {
      if (!noSelected) {
        setQuery(selected ? selected[labelKey] : '');
      }
    }, [selected, noSelected]);

    const open = useCallback(() => {
      if (!anchorEl) {
        return;
      }

      setWidth(anchorEl.offsetWidth);
      setMenuVisible(true);
      if (!disabledOpenQuery) {
        setQuery(' №');
      }
    }, [anchorEl]);

    const close = useCallback(() => {
      setTimeout(() => {
        setMenuVisible(false);
      }, 200);
    }, [query]);

    const onChoose = option => {
      const selectedItem = options.find(opt => opt?.value === option);
      onChange(option, selectedItem.key);
      close();
    };

    const clickAwayHandler = e => {
      if (holderRef?.current && holderRef?.current?.contains(e.target as Element)) return;
      close();
    };

    return (
      <div ref={holderRef}>
        <input readOnly style={{ display: 'none' }} value={value} ref={ref} />
        <Input
          {...props}
          ref={rootRef}
          value={disabledOpenQuery ? value : inputValue}
          autoComplete={'nofill'}
          fullWidth={fullWidth}
          onChange={e => {
            onChange(e.target.value);
            onQueryChange(e.target.value);
            setQuery(e.target.value);
          }}
          // onBlur={() => setQuery('')}
          // onFocus={() => setQuery('')}
          onClick={() => open()}
          InputProps={{
            classes: inputClasses,
            ...InputProps,
          }}
          InputLabelProps={{
            ...InputLabelProps,
            shrink: menuVisible || !!inputValue,
            focused: menuVisible,
            required: isRequired,
          }}
        />
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={clickAwayHandler}>
          <Popper
            open={menuVisible}
            className={classes1.root}
            classes={menuClasses}
            style={{ width, zIndex: 2, marginTop: 60 }}
            placement="bottom"
            disablePortal={false}
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
            }}
            anchorEl={anchorEl}
            {...MenuProps}
          >
            <>
              {loading ? (
                <MenuItem className={classes1.list}>
                  <LoadingSpinner />
                </MenuItem>
              ) : options.length ? (
                options.map((option, id) => (
                  <MenuItem
                    key={option[valueKey] + id}
                    selected={option[valueKey] === value}
                    onClick={() => onChoose(option[valueKey])}
                    value={option[valueKey]}
                  >
                    {mark(option[labelKey], query, classes.selected)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <NoData style={{ margin: '0 auto' }} />
                </MenuItem>
              )}
            </>
          </Popper>
        </ClickAwayListener>
      </div>
    );
  },
);

export default Autocomplete;
