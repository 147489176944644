import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { setSuccess, deleteBrand } from 'store/brands/actions';

import { IDeleteBrand } from '../interfaces/modalBrands.interfaces';

import styles from '../ModalBrand.module.less';

const DeleteBrand: React.FC<IDeleteBrand> = ({ data, submitText, closeText, onClose, success }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (success) onClose();
    dispatch(setSuccess(false));
  }, [success]);

  const hanldeDeleteBrand = () => {
    dispatch(deleteBrand(data.id, t));
    onClose();
  };

  return (
    <>
      <span className={styles.title}>{t('brands_page.want_delete')}</span>

      <div className={classNames(styles.actions_btn)}>
        <button onClick={onClose}>{closeText}</button>
        <button onClick={hanldeDeleteBrand}>{submitText}</button>
      </div>
    </>
  );
};

export default DeleteBrand;
