const columnsList = t => {
  return [
    {
      title: t('act_details.choose'),
      value: 'choose',
      key: 'choose',
      dataIndex: 'choose',
      sorter: false,
      width: 4,
    },
    {
      title: t('act_details.number'),
      value: 'contractNumber',
      key: 'contractNumber',
      dataIndex: 'contractNumber',
      sorter: false,
      width: 40,
    },
  ];
};

export default columnsList;
