export default () => ({
  root: {
    backgroundColor: '#3A9A3B',
    padding: '22px 49px',
    textTransform: 'none',
    minWidth: 180,
    maxWidth: 180,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    flexGrow: 0,
    lineHeight: '18px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#43AB44',
    },
    '&:disabled': {
      backgroundColor: '#B2D6B2',
      color: '#FFFFFF',
    },
  },
});
