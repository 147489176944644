import { createTypes } from 'redux-compose-reducer';
import { Dispatch } from 'redux';
import * as DepartmentsAPI from './../../api/departments';
import { AppState } from '../reducer';
import { IPagination } from '../../components/TableFooter/TableFooter';
import onDownload from '../../callbacks/onDownload';

export const TYPES = createTypes('departments', [
  'setLoading',
  'setData',
  'setPagination',
  'setQuery',
  'setSorting',
  'clearSorting',
]);

export const setLoading = (loading: boolean) => ({ type: TYPES.setLoading, payload: loading });
export const setQuery = (query: any) => ({ type: TYPES.setQuery, payload: query });
export const setPagination = (pagination: IPagination) => ({ type: TYPES.setPagination, payload: pagination });
export const setSorting = (sorting: any) => ({ type: TYPES.setSorting, payload: sorting });
export const clearSorting = () => ({ type: TYPES.clearSorting });

export const fetch = () => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch(setLoading(true));
  const { pagination, query } = getState().departments;
  try {
    const {
      data: { resultList: departments = [], count = 0 },
    } = await DepartmentsAPI.find(query, pagination);
    dispatch({ type: TYPES.setData, payload: departments });
    dispatch(setPagination({ ...pagination, total: count }));
  } catch (e) {
    console.error(e);
  }
  dispatch(setLoading(false));
};

export const exportExcel = t => async (dispatch: Dispatch, getState: () => AppState) => {
  const query = getState().departments.query;
  onDownload(() => DepartmentsAPI.xlsx(query), {}, t);
};
