import React, { useState } from 'react';
import columns from './utils/columns';
import { IInfoModal } from 'screens/InsuranceContracts/List/components/InfoModal/types';
import PrivateFilter from 'components/PrivateFilter';
import classnames from 'classnames';
import { FilterIcon } from 'components/Icons';
import { useDispatch } from 'react-redux';
import {
  getFilteredContracts,
  getFilteredPrivateData,
  getTablePage,
  setPagination,
  setSorting,
} from 'store/insuranceContracts/actions';
import { ICustomPagination } from 'api/insuranceContracts';
import CustomTable from 'components/CustomTable';
import moment from 'moment';
import { PACKAGE_NUMBER_FORMAT_VU } from '../constants';
import { useTranslation } from 'react-i18next';
import { getActionType } from './utils/formatter';

interface ITable {
  pagination: ICustomPagination;
  data: any;
  loading: boolean;
  onPaginationChange: (pagination: ICustomPagination) => void;
  classes: any;
  onClick: (data: IInfoModal) => void;
  total: number;
}

const CompaniesTable: React.FC<ITable> = ({ total, pagination, data, loading, onClick }) => {
  const [filtersInfo, setFiltersInfo] = useState({} || null);
  const [clearedFilters, setClearedFilters] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getDataFromPrivateFilter = (
    filtersInfoo: { [key: string]: any } | null,
    pagination?: { current: number; perPage: number },
  ) => {
    if (pagination) {
      dispatch(setPagination({ current: pagination.current, perPage: Number(pagination.perPage) || 1 }));
    } else {
      dispatch(setPagination({ current: 1, perPage: 10 }));
    }

    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });

    setFiltersInfo({
      ...filtersInfoo,
    });
    dispatch(
      getFilteredContracts(
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const getDataPrivateFilter = (filtersInfoo: { [key: string]: any } | null) => {
    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });

    dispatch(
      getFilteredPrivateData(
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const filteredBySearching = (filterBy: string, value: string) => {
    getDataFromPrivateFilter({ [filterBy]: value });
  };

  const getColumnSearchProps = (dataIndex: string, isDate?: boolean) => {
    // @ts-ignore,
    const filteredValue = filtersInfo[dataIndex] || null;
    return {
      filteredValue,
      filters:
        data &&
        data.map((item: any) => {
          if (dataIndex === 'type') {
            return {
              value: item[dataIndex] === 'CONTRACTS' ? t('insurance_contracts.contracts') : item[dataIndex] || '',
              currentValue: String(item[dataIndex]),
            };
          }

          if (dataIndex === 'transferType') {
            const value = String(item[dataIndex]).split('_');
            const firstPart = value[0] === 'EXPORT' ? t('insurance_contracts.export') : t('insurance_contracts.import');
            const lastPart = value.slice(1).join('') === 'VISITUKRAINE' ? 'VisitUkraine' : '1С';
            return {
              value: `${firstPart} ${lastPart}`,
              currentValue: String(item[dataIndex]),
            };
          }

          if (dataIndex === 'operationDate') {
            return {
              value: moment(item[dataIndex]).format('DD.MM.YYYY'),
            };
          }

          if (dataIndex === 'operationType') {
            return {
              value: item[dataIndex] === 'EXPORT' ? t('insurance_contracts.export') : t('insurance_contracts.import'),
            };
          }

          if (dataIndex === 'packageNumber') {
            return {
              value: (PACKAGE_NUMBER_FORMAT_VU + item[dataIndex]).slice(-10),
              currentValue: String(item[dataIndex]),
            };
          }

          return { value: String(item[dataIndex]).trim() };
        }),
      filterIcon: (filtered: boolean) => (
        <div data-id={`contract-list-private-filter-${dataIndex}-open-filter`}>
          <FilterIcon color={filtered ? '#fff' : null} className={classnames({ active: filtered })} />
        </div>
      ),
      filterDropdown: (record: any) => {
        const currentRecord = data.filter(item =>
          record.filters.some(val => val.value === item.originalContractNumber),
        );
        const recordForFilters = currentRecord.map(item => {
          return { value: item.subAgreementNumber ? item.number : item.originalContractNumber };
        });

        const recordForFilter = {
          ...record,
          filters: recordForFilters,
        };

        return (
          <PrivateFilter
            dataId={`contract-list-private-filter-${dataIndex}`}
            record={dataIndex === 'originalContractNumber' ? recordForFilter : record}
            dataIndex={dataIndex}
            filtersInfo={filtersInfo}
            setFiltersInfo={setFiltersInfo}
            getDataFromPrivateFilter={getDataFromPrivateFilter}
            getDataPrivateFilter={getDataPrivateFilter}
            filteredBySearching={filteredBySearching}
            isDate={isDate}
            getTablePage={() => dispatch(getTablePage())}
            isClearedFilters={clearedFilters}
            clearAllFilters={() => setClearedFilters(false)}
          />
        );
      },
    };
  };

  const allColumns = columns(t).map(({ title, value, width }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
      width: width ? width : 220,
      ...getColumnSearchProps(value),
      sorter: true,
    };
    switch (value) {
      case 'insuranceProgramName':
      case 'insuranceProductName':
        return {
          ...basicColumn,
          render: name => {
            return name ? name : '-';
          },
        };
      case 'packageNumber':
        return {
          ...basicColumn,
          render: number => {
            return (PACKAGE_NUMBER_FORMAT_VU + number).slice(-10);
          },
        };
      case 'successCount':
        return {
          ...basicColumn,
          width: 80,
        };
      case 'transferType':
        return {
          ...basicColumn,
          width: 100,
          render: val => {
            return val === 'CONTRACTS' ? t('insurance_contracts.contracts') : t('insurance_contracts.claims');
          },
        };
      case 'operationDate':
        return {
          ...basicColumn,
          width: 100,
          render: val => {
            return val ? moment(val).format('DD.MM.YYYY') : '-';
          },
        };
      case 'entitiesToTransferCount':
        return {
          ...basicColumn,
          width: 70,
        };
      case 'importedCount':
        return {
          ...basicColumn,
          width: 100,
        };
      case 'initiator':
      case 'failedCount':
        return {
          ...basicColumn,
          width: 85,
        };
      case 'type':
        return {
          ...basicColumn,
          width: 85,
          render: val => {
            if (!val) {
              return '-';
            }
            return getActionType(t)[val];
          },
        };
      default: {
        return basicColumn;
      }
    }
  });

  const handleFilters = (value: {}) => {
    setFiltersInfo({
      ...filtersInfo,
      ...value,
    });
  };

  const clearFilters = () => {
    dispatch(getTablePage());
    setFiltersInfo({});
    setClearedFilters(true);
  };

  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? 'ASC' : 'DESC';
      }
      return '';
    };

    const formatSorting = {
      field: sorting.field,
      direction: formatDirection(sorting.order),
    };
    dispatch(setSorting(formatSorting));
    getDataFromPrivateFilter(filtersInfo);
  };

  const onPageSizeChange = (pageSize: string): void => {
    dispatch(setPagination({ current: 1, perPage: Number(pageSize) || 0 }));
    getDataFromPrivateFilter(filtersInfo, { current: 1, perPage: Number(pageSize) });
  };

  const onPageChange = (page: number, pageSize?: number | undefined): void => {
    dispatch(setPagination({ current: page, perPage: pageSize || 0 }));
    getDataFromPrivateFilter(filtersInfo, { current: page, perPage: pageSize || 0 });
  };

  const rowClick = (record: any) => {
    onClick({
      insuranceProgramName: record.insuranceProgramName,
      packageNumber: record.packageNumber,
      importDate: record.operationDate,
      transferType: record.transferType,
      id: record.id,
      historyId: record.historyId,
    });
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <CustomTable
        columns={allColumns}
        data={data}
        page={pagination.current}
        contentHeight={410}
        pageSize={pagination.perPage}
        total={total || 0}
        pending={loading || false}
        filtersInfo={filtersInfo}
        handleFilters={handleFilters}
        clearFilters={clearFilters}
        handleTableChange={handleTableChange}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        rowKey={(record: any) => `${record.id}`}
        isRowClick={true}
        rowClickHandler={rowClick}
      />
    </div>
  );
};

export default CompaniesTable;
