export interface IDepartmentInsuranceProgram {
  active: boolean;
  name: string;
  activationDate: string;
  deactivationDate: string;
}

export interface IDepartment {
  id: number | null;
  activationDate: string | null;
  createdDate: string | null;
  deactivationDate: string | null;
  director: string | null;
  insuranceCompanyId: number | null;
  locality: string | null;
  partnerDepartmentId: number | null;
  name: string | null;
  partnerId: number | null;
  partnerName: string | null;
  phoneNumber: string | null;
  region: string | null;
  regionId: number | null;
  status: string | null;
}

export class Department implements IDepartment {
  activationDate = null;
  createdDate = null;
  deactivationDate = null;
  director = null;
  id = null;
  insuranceCompanyId = null;
  locality = null;
  partnerDepartmentId = null;
  name = null;
  partnerId = null;
  partnerName = null;
  phoneNumber = null;
  region = null;
  regionId = null;
  status = null;
}

export interface IDepartmentDetails {
  id: number | null;
  name: string | null;
  active: boolean | null;
  type: 'SALE_POINT' | 'INTERNET_SHOP';
  partnerType: 'SERVICE_COMPANY' | 'INSURANCE_AGENT';
  partner: {
    id?: number | null;
    name?: string | null;
  } | null;
  createdDate: string | null;
  activationDate: string | null;
  deactivationDate: string | null;
  address: {
    locality: string | null;
    street: string | null;
  } | null;
  region: {
    id: number | null;
    name: string | null;
  } | null;
  departmentPrograms: IDepartmentInsuranceProgram[];
  insuranceCompanyName: string | null;
  insuranceCompany: { id: number | null; name: string | null };
  phoneNumber: string | null;
  email: string | null;
  departmentNumber: undefined | string;
  directorFullName: undefined | string;
}

export class DepartmentDetails implements IDepartmentDetails {
  id = null;
  name = null;
  active = null;
  type = 'SALE_POINT' as 'SALE_POINT';
  partnerType = 'SERVICE_COMPANY' as 'SERVICE_COMPANY';
  partner = {
    id: null,
    name: null,
  };
  createdDate = null;
  activationDate = null;
  deactivationDate = null;
  address = {
    locality: null,
    street: null,
  };
  region = {
    id: null,
    name: null,
  };
  departmentPrograms = [];
  insuranceCompanyName = null;
  insuranceCompany: {
    id: null;
    name: null;
  };
  phoneNumber = null;
  email = null;
  departmentNumber = undefined;
  directorFullName = undefined;
}
