import React, { useEffect } from 'react';
import Routes from '../routes/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'store/userCard/actions';
import { fetch } from 'store/insuranceConfiguration/actions';
import i18n from 'i18next';
import { AppState } from 'store/reducer';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const currentLanguage = localStorage.getItem('language');
  const { data } = useSelector(({ userCard: { data } }: AppState) => ({ data }));
  const currentCurrency = useSelector((store: AppState) => store.insuranceConfiguration.data);
  const { t } = useTranslation();
  const { lang, insuranceCompany } = data;

  useEffect(() => {
    if (insuranceCompany?.id) {
      dispatch(fetch(insuranceCompany.id, t));
    }
  }, [insuranceCompany]);

  useEffect(() => {
    if (currentCurrency) {
      // @ts-ignore:next-line
      currentCurrency.currency && sessionStorage.setItem('currentCurrency', currentCurrency.currency.code);
    }
  }, [currentCurrency]);

  useEffect(() => {
    if (currentLanguage || lang) {
      dispatch(setLanguage(lang ? lang.toUpperCase() : currentLanguage));
      i18n.changeLanguage(String(lang ? lang.toUpperCase() : currentLanguage)).catch(err => console.log(err));
      if (typeof lang === 'string') {
        localStorage.setItem('language', lang.toUpperCase());
      }
    }
  }, [lang]);

  return <Routes />;
};

export default App;
