import { AnyAction } from 'redux';
import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { IntegrationHistoryItemView } from 'types/dto/contracts-service';

interface IPagination {
  current: number;
  perPage: number;
}

interface IFilters {
  contractNumber: string;
  success: string;
  message: string;
}

interface ISorting {
  field: string;
  direction: string;
}

export interface IHistoryInitialData {
  importedHistory: IntegrationHistoryItemView[];
  loading: boolean;
  pagination: IPagination;
  filters: IFilters;
  total: number;
  sorting: ISorting;
}

const initialState: IHistoryInitialData = {
  importedHistory: [],
  total: 0,
  loading: false,
  pagination: {
    current: 1,
    perPage: 10,
  },
  sorting: {
    field: 'success',
    direction: 'ASC',
  },
  filters: {
    contractNumber: '',
    success: '',
    message: '',
  },
};

const resetData = (state: IHistoryInitialData): IHistoryInitialData => {
  return {
    ...state,
    pagination: initialState.pagination,
    sorting: initialState.sorting,
  };
};

const setHistoryData = (state: IHistoryInitialData, action: AnyAction): IHistoryInitialData => {
  return {
    ...state,
    importedHistory: action.payload.resultList,
    total: action.payload.count,
  };
};

const setLoading = (state: IHistoryInitialData, action: AnyAction): IHistoryInitialData => {
  return {
    ...state,
    loading: action.payload,
  };
};

const setTablePage = (state: IHistoryInitialData, { payload, pending }: AnyAction): IHistoryInitialData => {
  return {
    ...state,
    importedHistory: payload ? payload.resultList : initialState.importedHistory,
    total: payload ? payload.count : initialState.total,
    loading: pending,
  };
};

const getPrivateFiltersData = (state: IHistoryInitialData, { payload }: any): IHistoryInitialData => {
  const { resultList, count }: { resultList: []; count: number } = payload;
  return {
    ...state,
    importedHistory: resultList,
    total: count,
  };
};

const setSorting = (state: IHistoryInitialData, { payload }: any): IHistoryInitialData => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sorting: initialState.sorting,
  };
};

const setPagination = (state: IHistoryInitialData, { payload }: AnyAction): IHistoryInitialData => {
  return {
    ...state,
    pagination: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setHistoryData,
    setLoading,
    setTablePage,
    setSorting,
    getPrivateFiltersData,
    setPagination,
    resetData,
  },
});
