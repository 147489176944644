import { IInsuredEvent } from '../../../types/InsuredEvent';
import { dateFormatter, moneyFormatter } from './../../../utils/helpers';
import moment from 'moment';

export const formatInsuredEvents = (dataArr: IInsuredEvent[]) => {
  return dataArr
    ? dataArr.map((data: any) => ({
        ...data,
        status: data.signDate,
        incidentDate: data.incidentDate && dateFormatter(data.incidentDate),
        paymentDate: data.paymentDate ? dateFormatter(data.paymentDate) : '',
        claimDate: data.claimDate && dateFormatter(data.claimDate),
        franchise: data.franchise && `${data.franchise * 100}%`,
        paymentAmount: data.paymentAmount && `${moneyFormatter(data.paymentAmount)} ₴‎`,
        paid: data.paid && `${moneyFormatter(data.paid)} ₴‎`,
        expectedLossSum: data.expectedLossSum && `${moneyFormatter(data.expectedLossSum)} ₴‎`,
        startDate: data.startDate && dateFormatter(data.startDate),
        endDate: data.endDate && dateFormatter(data.endDate),
        insuranceAmount: data.insuranceAmount && `${moneyFormatter(data.insuranceAmount)}`,
      }))
    : [];
};

export const formatDatePeriod = (start: string, end: string) =>
  `${moment(start, 'YYYY-MM-DD')
    .startOf('day')
    .format('YYYY-MM-DD')}..${moment(end, 'YYYY-MM-DD')
    .endOf('day')
    .format('YYYY-MM-DD')}`;

export const statusAdapter = {
  REPORTED: 'Повідомлено',
  DOCUMENTS_COLLECTION: 'Збір документів',
  PAYMENT_CALCULATION: 'Оформлення суми на виплату',
  PAYMENT_AWAITING: 'Очікування виплати',
  PAYMENT: 'Виплата',
  PAYMENT_REJECTED: 'Відхилення виплати',
  ANNUL: 'Анульовано',
};

export const revertStatusAdapter = {
  Повідомлено: 'REPORTED',
  'Збір документів': 'DOCUMENTS_COLLECTION',
  'Оформлення суми на виплату': 'PAYMENT_CALCULATION',
  'Очікування виплати': 'PAYMENT_AWAITING',
  Виплата: 'PAYMENT',
  'Відхилення виплати': 'PAYMENT_REJECTED',
  Анульовано: 'ANNUL',
};

export const resolutionTypes = {
  EXPRESS_PAYMENT: 'Експрес - виплата',
  SHOP_SERVICE_REPAIR: 'Ремонт через магазин',
  SELF_SERVICE_REPAIR: 'Ремонт через сервісний центр, обраний самостійно',
  PAYMENT: 'Виплата',
};
