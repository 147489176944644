import createInstance from './axios/axios';
import { IUserData } from '../types/userCard';
import { AxiosResponse } from 'axios';
import momentTz from 'moment-timezone';
import _ from 'lodash';
import { Role, ResultListDtoUserActivityLogView, ResultListDtoDepartmentView } from 'types/dto/contracts-service';
import { ResetPasswordWithOldDto } from 'types/dto/auth-service';
import { ResultListDtoInsuranceProgramView } from 'types/dto/configuration-service';

interface IData {
  partnerId?: number;
  insuranceCompanyId?: number;
}

const api = createInstance('CONTRACTS');
const authApi = createInstance('AUTH');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');
const configApi = createInstance('CONFIGURATION');

export const getUserRoles = () => {
  return api.get<Role[]>('/users/roles/');
};

export const createUser = (data: IUserData): Promise<AxiosResponse<IUserData>> => {
  return api.post('/users/', {
    ...data,
  });
};

export const getUser = (id: number): Promise<AxiosResponse<IUserData>> => {
  return api.get(`/users/${id}`);
};

export const updateUser = (data: IUserData): Promise<AxiosResponse<IUserData>> => {
  return api.put(`/users/${data.id}`, {
    ...data,
  });
};

export const getInsuranceProgram = (data: IData) => {
  return configApi.get<ResultListDtoInsuranceProgramView>('/insurance-programs?attributes=id,name&distinct', {
    params: { ...data },
  });
};

export const getPartnerDepartment = (name: string, partnerId: number, regionId: number) => {
  const params: { partnerId: number; name: string; regionId?: number } = {
    partnerId,
    name,
  };
  if (regionId) params['regionId'] = regionId;
  return api
    .get<ResultListDtoDepartmentView>(`/departments?distinct&attributes=regionId,region,name,id&active=true`, {
      params,
    })
    .then(res => {
      return {
        data: res?.data?.resultList ?? [],
      };
    });
};

export const getPartnerDepartmentRegion = (region: string, partnerId: number) => {
  return api
    .get<ResultListDtoDepartmentView>(`/departments?distinct&attributes=regionId,region,name,id`, {
      params: {
        partnerId,
        region,
      },
    })
    .then(response => {
      const uniqueData = _.uniqBy(response?.data?.resultList, 'region');

      return {
        data: uniqueData.map(el => {
          return {
            id: el.regionId,
            name: el.region,
            department: el.name,
            departmentId: el.id,
          };
        }),
      };
    });
};

export const sendUserPassword = (values: ResetPasswordWithOldDto, id: number) => {
  return authApi.put(`/api/user/${id}/password/reset`, { ...values });
};

export const printUserCard = (id: number): Promise<AxiosResponse<Blob>> => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/users/${id}/pdf-report`, {
    params: {
      timeZone,
    },
  });
};

export const printUsersXLSXList = (params: any, partnerId?: number | string): Promise<AxiosResponse<Blob>> => {
  const timeZone = momentTz.tz.guess(true);
  return api.get(`/users/print/xlsx${partnerId || partnerId === null ? `?partnerId=${partnerId}` : ''}`, {
    responseType: 'arraybuffer',
    params: {
      ...params,
      timeZone,
    },
  });
};

export const getUserActivity = (entityId: number | string, activityDate?: string, role?: Role) => {
  let attributes =
    role === Role.ROLE_CLIENT
      ? 'activityDate,shortName,login,action,userId,entityId,entityType'
      : 'activityDate,shortName,login,action,userId';

  const additionalParams =
    role === Role.ROLE_CLIENT
      ? {
          entityType: 'USER',
          entityId,
        }
      : {};

  return api.get<ResultListDtoUserActivityLogView>(`/users/activity-logs`, {
    params: {
      attributes,
      userId: entityId,
      activityDate,
      page_size: 100,
      ...additionalParams,
    },
  });
};
