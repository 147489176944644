export const theme = {
  color: {
    primary: {
      main: '#3A9A3B',
      light: '#4BA84C',
      lighter: '#B2D6B2',
      lightest: '#EBF5EB',
    },
    error: {
      main: '#EB5757',
      light: '#FDEEEE',
    },
    blueGray: {
      main: '#A9B5BD',
      light: '#EEEFF4',
      lighter: '#F0F1F5',
      lightest: '#F6F8F8',
    },
    gray: {
      main: '#363636',
      light: '#6C6D70',
      lighter: '#F7F7F9',
    },
    basic: {
      black: '#000000',
      white: '#FFFFFF',
    },
  },
};
