import React from 'react';
import styles from './Avatar.module.less';
import classNames from 'classnames';

interface AvatarProps {
  title?: string;
  sidebar?: boolean;
  dialog?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ title = '', sidebar, dialog }) => (
  // "data-is" attribute is required for animating
  <div data-is="avatar" className={classNames(styles.avatar, sidebar && styles.sidebar, dialog && styles.dialog)}>
    {title
      .split(' ')
      .map(title => title.charAt(0))
      .slice(0, 2)
      .join('')}
  </div>
);

export default Avatar;
