import React from 'react';
import FiltersBar from '../../../components/FiltersBar';
import Select from '../../../components/FiltersBar/components/Select/Select';
import MenuItem from '../../../components/uikit/MenuItem/MenuItem';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Autocomplete from '../../../components/FiltersBar/components/Autocomplete';
import moment from 'moment';
import DateRangePicker from '../../../components/FiltersBar/components/DateRangePicker/DateRangePicker';
import SubmitButton from '../../../components/FiltersBar/components/SubmitButton/SubmitButton';

const useStyles = makeStyles(styles);

const MedAppFilters = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const clinicTypes = [
    'Вузькопрофільна клініка',
    'Стоматологія',
    'Амбулаторно-поліклінічна допомога',
    'Виклик лікаря додому/офісу',
    'Невідкладна допомога',
    'Лабораторія',
    'facilityType',
    'Стаціонар',
    'Багатопрофільна',
  ];
  const facilityClassINSK = ['A+', 'A', 'B', 'C', 'D'];

  return (
    <FiltersBar {...props}>
      <Select
        className={classes.select}
        label={`Бренд`}
        // value={filters.insuranceCompanyId || ''}
        // onChange={event => onFiltersChange({ ...filters, insuranceCompanyId: event.target.value })}
      >
        {/* {insuranceCompany?.map((item: { id: number; name: string }) => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))} */}
      </Select>
      <Autocomplete
        label={`Область`}
        clearable={true}
        // onClear={() => onFiltersChange({ ...filters, id: undefined })}
        // value={filters.id !== undefined ? checkValueInsuranceProgramms() : ''}
        // loading={insuranceProgramsAutocomplete.loading}
        onQueryChange={() => {}}
        options={[]}
        onChange={() => {}}
      />
      <Autocomplete
        label={`Брендова назва`}
        clearable={true}
        onQueryChange={() => {}}
        options={[]}
        onChange={() => {}}
      />
      <Autocomplete label={`МИС`} clearable={true} onQueryChange={() => {}} options={[]} onChange={() => {}} />
      <Select
        className={classes.select}
        label={`Тип клініки`}
        // value={filters.insuranceCompanyId || ''}
        // onChange={event => onFiltersChange({ ...filters, insuranceCompanyId: event.target.value })}
      >
        {clinicTypes.map(item => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <Select
        className={classes.select}
        label={`Клас клініки`}
        // value={filters.insuranceCompanyId || ''}
        // onChange={event => onFiltersChange({ ...filters, insuranceCompanyId: event.target.value })}
      >
        {facilityClassINSK.map(item => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <SubmitButton children={`Пошук`} />
    </FiltersBar>
  );
};

export default MedAppFilters;
