import React from 'react';
import BaseCountDown, { CountdownProps, zeroPad } from 'react-countdown';

export interface Props extends CountdownProps {
  className?: string;
}

const CountDown = ({ className, ...props }: Props) => {
  const renderer: CountdownProps['renderer'] = ({ minutes, seconds }) => (
    <span className={className}>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );

  return <BaseCountDown renderer={renderer} {...props} />;
};

export default CountDown;
