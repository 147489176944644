import { AxiosResponse } from 'axios';
import momentTz from 'moment-timezone';
import { omit } from 'lodash';

import createInstance from './axios';
import onDownload from 'callbacks/onDownload';

import { IDocumentsPreview, IDocumentTypeCodes, IInsuredEvent, IInsuredEventDocument } from 'types/InsuredEvent';
import { IPaymentSumInfo } from '../types/InsuredCase';

import { makerRequestParams } from 'utils/helpers';
import { baseTimes } from '../components/filters/InsuredEventsFilters/InsuredEventsFilters';

const InsuredEventsAPI = createInstance('CONTRACTS');
const InsuredEventsPDFAPI = createInstance('CONTRACTS', 'pdf');
const InsuredEventConfigService = createInstance('CONFIGURATION');
const InsuredEventContractsService = createInstance('CONTRACTS');

export const fetch = (params): Promise<AxiosResponse<any>> => {
  return InsuredEventsAPI.get('/claims?count&distinct=true&total&totalAttributes=expectedLossSum,paymentAmount,paid', {
    params,
  });
};

export const getById = async (id: number): Promise<IInsuredEvent> => {
  const { data: contract } = await InsuredEventsAPI.get('/claims/' + id);
  return contract;
};

export const create = (data: IInsuredEvent) => InsuredEventsAPI.post('/claims/', data);

export const annul = id => InsuredEventsAPI.post(`/claims/${id}/annul`);

export const restore = id => InsuredEventsAPI.post(`/claims/${id}/restore`);

export const update = async (id: number, data: IInsuredEvent) => {
  return InsuredEventsAPI.put('/claims/' + id, data);
};

export const getOptDisabled = (programId: number) => {
  return InsuredEventConfigService.get(
    `/insurance-programs?attributes=id,name,claimOtpDisabled&id=${programId}&distinct`,
  );
};

export const signAndVerificationInsuredEvent = async (claimId: number) =>
  InsuredEventsPDFAPI.post(`/claims/${claimId}/sign-verification`);

export const signInsuredEvent = async (claimId: number, token: string): Promise<AxiosResponse> =>
  InsuredEventsPDFAPI.post(`/claims/${claimId}/sign?token=${token}`);

export const getTemplate = (documentId: string) => InsuredEventsAPI.get('/claims/templates?contractId=' + documentId);

export const getRiskDocuments = (id: number) => InsuredEventsAPI.get('/claims/documents/configurations?riskId=' + id);

export const documentUpload = async (claimId: number, documentTypeCode: String, formData: any) =>
  InsuredEventsAPI.post(`/claims/${claimId}/documents/${documentTypeCode}/files`, formData);

export const downloadPDF = (documentId: string, documentType: string): Promise<AxiosResponse<Blob>> => {
  let insuranceType = '';
  if (documentType) {
    insuranceType = documentType === 'insuranceAct' ? 'insurance-act' : 'insurance-claim';
  }

  const timeZone = momentTz.tz.guess(true);
  return InsuredEventsPDFAPI.get(`/claims/${documentId}/pdf-report/${insuranceType}`, {
    params: {
      timeZone,
      documentType,
    },
  });
};

export const expressPaymentSum = async (params: {
  contractId: number;
  incidentDate: string;
  insuranceObjectPid?: string | null;
  riskId?: number | null;
  resolutionType?: string | null;
}): Promise<number> => {
  const { data } = await InsuredEventsAPI.post('/claims/claim-sum', {
    ...params,
  });
  return data;
};

export const getPaymentSumInfo = (id: number): Promise<AxiosResponse<IPaymentSumInfo[]>> => {
  return InsuredEventsAPI.get(`/claims/${id}/payment-calculations`);
};

export const savePaymentSumInfo = (
  id: number,
  data: Partial<IPaymentSumInfo>,
): Promise<AxiosResponse<IPaymentSumInfo>> => {
  return InsuredEventsAPI.put(`/claims/${id}/payment-calculations`, data);
};

export const getDocuments = (riskId?: number): Promise<AxiosResponse<IInsuredEventDocument[]>> => {
  return InsuredEventsAPI.get(`/claims/documents/${riskId ? `configurations?riskId=${riskId}` : ''}`);
};
// TODO fix type for axios response - temp types
export const getDocumentsPreviewList = ({
  claimId,
  documentTypeCode,
  pageNumber,
}: {
  claimId: string;
  documentTypeCode: string;
  pageNumber: number;
  pageSize: number;
}): Promise<AxiosResponse<IDocumentsPreview>> => {
  return InsuredEventsAPI.get(
    `/claims/${claimId}/document-description/${documentTypeCode}/files?pageNumber=${pageNumber}&pageSize=10`,
  );
};

// TODO Add types
export const getDocumentFileInfo = ({ claimId, documentTypeCode, fileKey }): Promise<AxiosResponse<any>> => {
  return InsuredEventsAPI.get(`/claims/${claimId}/documents/${documentTypeCode}/files/${fileKey}`, {
    responseType: 'arraybuffer',
  });
};

export const downloadAllFiels = ({ claimId, documentTypeCode }) => {
  return InsuredEventsAPI.get(`/claims/${claimId}/documents/${documentTypeCode}/zip`, {
    responseType: 'blob',
  });
};

export const removeFileFromAdditionalDocument = (
  claimId: string,
  documentTypeCode: string,
  fileKey,
): Promise<AxiosResponse<null>> => {
  return InsuredEventsAPI.delete(`/claims/${claimId}/documents/${documentTypeCode}/files/${fileKey}`);
};

export const renameFile = ({ claimId, documentTypeCode, fileKey, newName }) => {
  return InsuredEventsAPI.put(`/claims/${claimId}/documents/${documentTypeCode}/files/${fileKey}/name/${newName}`);
};

export const updateDocuments = async (id: number, documents: any): Promise<AxiosResponse<IInsuredEventDocument[]>> => {
  return await InsuredEventsAPI.post(`/claims/${id}/documents`, documents);
};

export const updateClaimStatus = async (id: number, status: string): Promise<AxiosResponse<IInsuredEvent>> => {
  return await InsuredEventsAPI.put(`/claims/${id}/update-status?claimStatus=${status}`);
};

export const updateClaimStatusToReject = async (
  id: number,
  status: string,
  rejectionId?: number,
): Promise<AxiosResponse<IInsuredEvent>> => {
  return await InsuredEventsAPI.put(`/claims/${id}/update-status?claimStatus=${status}&rejectionId=${rejectionId}`);
};

export const excelExport = (params: any): Promise<AxiosResponse<Blob>> => {
  const timeZone = momentTz.tz.guess(true);
  if (params.paymentDate || params.incidentDate) {
    let [startDate, endDate] = params.claimDate.split('..');
    startDate += baseTimes.startTime;
    endDate += baseTimes.endTime;
    params.claimDate = `${startDate}..${endDate}`;
  }
  return InsuredEventsAPI.get('/claims/print/xlsx?distinct=true', {
    responseType: 'blob',
    params: {
      ...params,
      timeZone,
    },
  });
};

export const notifyClient = (id: number): Promise<AxiosResponse<void>> => {
  return InsuredEventsAPI.post(`/claims/${id}/documents/notify-client`);
};

export const expressPaymentDelay = (id: number): Promise<AxiosResponse<void>> => {
  return InsuredEventsAPI.post(`/claims/${id}/express-payments/defer`);
};

export const finishDocumentsUpload = (claimId: number): Promise<AxiosResponse<void>> => {
  return InsuredEventsAPI.post(`/claims/${claimId}/documents/finish-upload-files`);
};

export const getInsObjects = (id: string | number | null) => {
  return InsuredEventsAPI.get(`/contracts/${id}/insurance-objects`);
};

export const getInsuranceTimer = (id: string | number) =>
  InsuredEventsAPI.get(`claims/${id}/express-payments/countdown-timer`);

export const getInsuranceActAsXLS = async (id: string | number, contractClaimNumber, actCreationDate) => {
  const timeZone = momentTz.tz.guess(true);
  return InsuredEventsAPI.get(
    `/claims/${id}/xlsx-report?contractClaimId=${id}&contractClaimNumber=${contractClaimNumber}&actCreationDate=${actCreationDate}`,
    {
      responseType: 'arraybuffer',
      params: {
        timeZone,
      },
    },
  );
};

export const downloadInsuranceActAsXLS = (
  id: string | number,
  contractClaimNumber: string | number,
  actCreationDate: string | number,
) => {
  return onDownload(() => getInsuranceActAsXLS(id, contractClaimNumber, actCreationDate));
};

export const confirmPayment = async (params: {
  contractClaimId: string | number;
  orderNumber: string;
  paymentDate: string;
  paymentSum: number;
}): Promise<AxiosResponse<any>> => {
  return InsuredEventsAPI.post('/claims/confirm-payment', { ...params });
};

// private filters InsuredEvents Table
export const getFilteredContracts = ({ filters }: { filters: any }) => {
  Object.keys(filters).forEach(key => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });

  return InsuredEventsAPI.get('/claims', {
    params: {
      ...omit(filters, ['insuranceCompanyId', 'insuranceProgramId', 'insurancePartnerId', 'claimDate']),
      incidentDate: filters.claimDate,
      sorting: '-incidentDate',
      distinct: true,
    },
  });
};

export const sendDocumentTypeCodes = (params: IDocumentTypeCodes): Promise<AxiosResponse<void>> => {
  return InsuredEventContractsService.post(`/claims/email-notification/documents`, params);
};
