const columnsList = t => {
  return [
    {
      title: '',
      value: 'status',
      width: 90,
    },
    {
      title: t('act_details.contract_number_table'),
      value: 'contractNumber',
      width: 240,
    },
    {
      title: t('act_details.client_name_table'),
      value: 'clientName',
      width: 240,
    },
    {
      title: t('act_details.conclusion_date_table'),
      value: 'conclusionDate',
      width: 200,
    },
    {
      title: t('act_details.calculated_payment_amount_table'),
      value: 'calculatedPaymentAmount',
    },
    {
      title: t('act_details.payment_amount_table'),
      value: 'paymentAmount',
    },
    {
      title: t('act_details.payment_number_table'),
      value: 'paymentNumber',
    },
    {
      title: t('act_details.fee_sum_table'),
      value: 'feeSum',
    },
    {
      title: t('act_details.fee_percent_table'),
      value: 'feePercent',
      width: 240,
    },
    {
      title: t('act_details.returned_payment_table'),
      value: 'returnedPayment',
    },
    {
      title: t('act_details.returned_to_partner_table'),
      value: 'returnedToPartner',
      width: 300,
    },
    {
      title: t('act_details.base_fee_sum_table'),
      value: 'baseFeeSum',
    },
    {
      title: t('act_details.to_pay_table'),
      value: 'toPay',
    },
    {
      title: t('act_details.agent_contract_number_table'),
      value: 'agentContractNumber',
    },
  ];
};

export default columnsList;
