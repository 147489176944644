import React from 'react';
import theme from '../../../Theme/index';

const ChartBar = ({ x, y, height, onMouseEnter, onMouseLeave, onClick, showTooltip, hideTooltip, data, tooltip, ...props }: any) => {
  const color = theme.colors[data.index % theme.colors.length];
  const width = props.width > 26 ? 26 : props.width;
  const handleTooltip = (e: any) => showTooltip(tooltip(data.data), e);
  const handleMouseEnter = (e: any) => {
    onMouseEnter(data.data, e);
    showTooltip(tooltip(data.data), e);
  };
  const handleMouseLeave = (e: any) => {
    onMouseLeave(data.data, e);
    hideTooltip(e);
  };

  return <rect
    onMouseMove={handleTooltip}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onClick={onClick}
    x={x - (width / 2) + (props.width / 2)}
    y={y}
    rx="15"
    ry="15"
    width={width}
    height={height}
    fill={color}/>;
};

export default ChartBar;
