import * as contractsFilesAPI from '../../../../api/contracts';

export default (docFiles: any[], createdId: any) => {
  return docFiles.reduce((acc: any, doc: any) => {
    let requestArr: any = [];
    const mainFormData = new FormData();

    Array.from(doc.files).forEach((file: any) => {
      mainFormData.append('file', file, file.name);
    });

    requestArr.push(contractsFilesAPI.documentUpload(Number(createdId), doc.code, mainFormData));
    return [...acc, ...requestArr];
  }, []);
};
