import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DashboardExtend from 'screens/DashboardExtend/DashboardExtend';
import DashboardMain from 'components/Dashboard';
import { ROUTES } from 'constants/routes';
import PrivateRoute from 'routes/PrivateRoute';
import Page404 from 'components/Page404';

const Dashboard = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        role={['DASHBOARD', 'ANALYTICS']}
        path={ROUTES.DASHBOARD.EXTEND}
        component={DashboardExtend}
      />
      <PrivateRoute exact role={['DASHBOARD', 'ANALYTICS']} path={ROUTES.DASHBOARD.MAIN} component={DashboardMain} />

      <Route exact path={ROUTES.DASHBOARD.ROOT} component={() => <Redirect to={ROUTES.DASHBOARD.EXTEND} />} />

      <Route component={Page404} />
    </Switch>
  );
};

export default Dashboard;
