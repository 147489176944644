import React, { createRef, useEffect, useMemo, useState } from 'react';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import moment from 'moment';
import classNames from 'classnames';

import { AudioChatCompanionIcon, VideoDotIcon } from 'components/Icons';
import MeetingControls from '../MeetingControls';
import { useDOMSize } from 'screens/Chats/hooks';
import useOpenTok from 'api/opentok';
import { useTranslation } from 'react-i18next';
import { MeetingType, Chat } from 'types/chats';
import { useModal } from 'screens/Appointments/hooks/useModal';
import Modal from 'screens/Appointments/components/Modal';
import Devices from '../Devices';

import styles from './Meeting.module.less';
import './overrides.less';

interface MeetingProps {
  opened: Chat;
  type: MeetingType;
  userName?: {
    lastName: string;
    firstName: string;
    middleName: string;
  };
  onClose(): void;
}

const OVERLAY_OFFSET = 185;
const OVERLAY_OFFSET_WITH_CAMERA = 250;
const AUDIO_ICON_SIDE = 126;
const CONTROLS_HEIGHT = 96;

const Meeting: React.FC<MeetingProps> = ({ type, opened, userName, onClose }) => {
  const { t } = useTranslation();

  const onCloseDevices = () => {};

  const { otProperties, setDevice, devices, isScreenSharing, toggleScreenSharing } = useOpenTok(opened);
  const { isOpen, open, close, confirm } = useModal(onCloseDevices, devices);
  const { ref, size } = useDOMSize<HTMLDivElement>();

  const [videoEnabled, setVideoEnabled] = useState(false);
  const [isCompanionConnected, setIsCompanionConnected] = useState(false);
  const [isCompanionAudioOnly, setIsCompanionAudioOnly] = useState(false);
  const [audioLevel, setAudioLevel] = useState(0);
  const [publisher, setPublisher] = useState(null);

  const startTime = new Date(opened.consultationDate).getTime();
  const endTime = moment(startTime).add(
    opened.duration ? opened.duration : Number(opened.consultationDuration) / 60000,
    'minutes',
  );
  const [timeLeft, setTimeLeft] = useState('30:00');

  const subscriberRef = createRef<any>();
  const publisherRef = createRef<any>();

  useEffect(() => {
    if (publisherRef) {
      setPublisher(publisherRef?.current.getPublisher());
    }
  }, [publisherRef]);

  useEffect(() => {
    const incrementDiff = setInterval(getTimeLeft, 1000);
    return () => {
      clearInterval(incrementDiff);
    };
  }, []);

  useEffect(() => {
    if (timeLeft === '00:00') {
      onClose();
    }
  }, [timeLeft]);

  useEffect(() => {
    setIsCompanionConnected(!!subscriberRef.current);
    setIsCompanionAudioOnly(!subscriberRef.current?.context?.stream?.hasVideo);
  }, [subscriberRef]);

  const audioOnlyUiStyle = useMemo(
    () =>
      size && {
        top: size.height / 2 - CONTROLS_HEIGHT,
        left: (size.width - AUDIO_ICON_SIDE) / 2,
      },
    [size],
  );

  const overlayUiStyle = useMemo(
    () =>
      size && {
        top: size.height - OVERLAY_OFFSET,
        left: 0,
        width: size.width,
      },
    [size],
  );

  const overlayUiWithCameraStyle = useMemo(
    () =>
      size && {
        top: size.height - OVERLAY_OFFSET_WITH_CAMERA,
        left: 0,
        width: size.width,
      },
    [size],
  );

  const getTimeLeft = () => {
    const dur = moment().diff(endTime, 'seconds');

    if (dur >= 0) {
      setTimeLeft('00:00');
      return null;
    }
    const minutes = Math.floor(Math.abs(dur) / 60);
    const seconds = Math.abs(dur) - minutes * 60;
    const convertSeconds = () => {
      if (seconds.toString().length < 2) {
        return `0${seconds}`;
      }
      return seconds;
    };

    setTimeLeft(`${minutes}:${convertSeconds()}`);
  };

  const durationNode = (
    <div className={styles.duration}>
      {type === MeetingType.Video && <VideoDotIcon />}
      {timeLeft}
      {' / '}
      {opened.duration ? opened.duration : Number(opened.consultationDuration) / 60000}
      {':00'}
    </div>
  );

  const subscriberEventHandlers = () => ({
    // streamCreated: event => {
    //   console.log('Publisher stream created!', event);
    // },
    // streamDestroyed: event => {
    //   console.log('Publisher stream destroyed!', event);
    // },
    videoDisabled: event => {
      setVideoEnabled(false);
    },
    videoEnabled: event => {
      setVideoEnabled(true);
    },
  });

  const publisherEventHandlers = () => ({
    audioLevelUpdated: event => {
      let movingAvg: any = null;
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }

      let logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      setAudioLevel(Number((logLevel * 10).toFixed()));
    },
    accessAllowed: function(event) {
      // console.log('accessAllowed');
      // The user has granted access to the camera and mic.
    },
    accessDenied: function accessDeniedHandler(event) {
      // console.log('accessDenied');
      // The user has denied access to the camera and mic.
    },
    accessDialogOpened: function(event) {
      // console.log('accessDialogOpened');
      // The Allow/Deny dialog box is opened.
    },
    accessDialogClosed: function(event) {
      // console.log('accessDialogClosed');
      // The Allow/Deny dialog box is closed.
    },
  });

  return (
    <div ref={ref} className={styles.container}>
      {!isCompanionConnected && <p className={styles.waiting}>{t('chats.companion_waiting')}</p>}
      {/* Тут нужно использовать стили вместо conditional rendering, потому что
          прежде, чем мы получим доступ к сессии, она должна создаться ¯\_(ツ)_/¯. 
          `createSession` по каким-то причинам выкидывает ошибку 
          (https://github.com/opentok/opentok-react/issues/144), так что это
          единственный способ создания видеочата с помощью `opentok-react` 
          (другой — настраивать все руками через `opentok`) */}
      <div className={classNames(styles.videoMeetOuter, !isCompanionConnected && styles.hiddenMeet)}>
        <OTSession {...otProperties.session}>
          <OTStreams>
            <OTSubscriber
              ref={subscriberRef}
              properties={otProperties.subscriber}
              eventHandlers={subscriberEventHandlers()}
            />
          </OTStreams>
          <OTPublisher
            ref={publisherRef}
            properties={otProperties.publisher}
            eventHandlers={publisherEventHandlers()}
          />
        </OTSession>
      </div>
      {isCompanionConnected && (
        <>
          {isCompanionAudioOnly && (
            <div className={styles.audioOnlyUiStyle} style={audioOnlyUiStyle}>
              <AudioChatCompanionIcon />
            </div>
          )}
          <div
            className={classNames(styles.videoOverlayUi, { [styles.overlayUiWithoutCamera]: !videoEnabled })}
            style={!videoEnabled ? overlayUiWithCameraStyle : overlayUiStyle}
          >
            <div className={styles.companionName}>
              {userName
                ? `${userName.lastName ?? ''} ${userName.firstName ?? ''} ${userName.middleName ?? ''}`
                : opened.doctorType}
            </div>
            {durationNode}
          </div>
        </>
      )}
      <MeetingControls
        onToggleScreenSharing={toggleScreenSharing}
        onClose={onClose}
        isScreenSharing={isScreenSharing}
        devices={devices}
        setDevice={setDevice}
        onOpenMenu={open}
        type={type}
      />

      {isOpen && (
        <Modal className={styles.modal}>
          <Modal.Header title={t('chats.devices_header')} className={styles.modalHeader} onClose={close} />
          <Modal.Body className={styles.modalBody}>
            <Devices publisher={publisher} audioLevel={audioLevel} />
          </Modal.Body>
          <Modal.Confirm onClick={confirm} />
          {/* disabled={isEqual(defaultValue, picked)}  */}
        </Modal>
      )}
    </div>
  );
};

export default Meeting;
