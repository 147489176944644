import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';

import Table from 'components/Table';
import TableFooter from 'components/TableFooter';
import { ITable, IPagination } from './interfaces/Table.interfaces';

import DropCustom from './TableToolsHandler';
import TableTools from './TableTools';

import style from './styles';

const columns = (cb, t) => {
  return [
    {
      title: t('brands_page.brand_name'),
      dataIndex: 'brand',
      key: 's1',
    },
    {
      title: '',
      key: 's2',
      value: 'id',
      fixed: 'right' as 'right',
      width: 50,
      render: (id: number, record: any) => (
        <DropCustom Component={TableTools} open={cb} data={record} record={record} />
      ),
    },
  ];
};

const BrandsTable: React.FC<ITable> = ({ paginations, data, loading, onPageChange, classes, open }) => {
  const { size, total, page } = paginations;
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<IPagination>({ size, total, page });

  useEffect(() => {
    onPageChange(pagination.page, pagination.size);
  }, [pagination.size, pagination.page]);

  useEffect(() => {
    setPagination({ size, total, page });
  }, [size, total, page]);

  return (
    <>
      <Table
        className={classes.table}
        scroll={{ y: `calc(100vh - ${415}px)` }}
        loading={loading}
        pagination={false}
        columns={columns(open, t)}
        bordered
        dataSource={data}
        rowKey={(record: { id: string; brand: string }) => record.id}
      />
      <TableFooter className={classes.footer} pagination={paginations} onChange={setPagination} total={total} />
    </>
  );
};

export default withStyles(style)(BrandsTable);
