import moment from 'moment';
import { RISK_TYPE } from '../components/DetailsCard/types/Jutlo.interfaces';
import 'moment/locale/ru';
import 'moment/locale/uk';
import 'moment/locale/eu';
import RU from 'antd/lib/locale/ru_RU';
import EN from 'antd/lib/locale/en_US';
import UA from 'antd/lib/locale/uk_UA';
import { CONTRACT_TYPE } from '../components/DetailsCard/constants/Datails.constants';
import { TJS } from 'constants/partners';
import { Language } from 'types';

export const dateFormatter = (date: string) => {
  return moment(date).format('DD.MM.YYYY');
};

export const moneyFormatter = (money: number | null = 0, fixedCount?: number) => {
  if (money === null) {
    return '0.00';
  }
  const n = String(reverseMoneyToNumber(String(money)).toFixed(fixedCount ?? 2)),
    p = n.indexOf('.');

  const formated = n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) => (p < 0 || i < p ? `${m} ` : m));
  const commaPattern = /(\d+)(\d{3})(\.\d*)*$/;
  const callback = (match, p1, p2, p3) => p1.replace(commaPattern, callback) + ' ' + p2 + (p3 || '');
  return formated.replace(commaPattern, callback);
};

export const percentFormatter = (value: number | null = 0) => Number(value) * 100;

export const reverseMoneyToNumber = (str: string) => {
  const splinted = String(str)?.split(' ');
  return Number(splinted?.join(''));
};

export const makerRequestParams = (array: []) => {
  let str = '';

  Array.isArray(array)
    ? array.forEach((item, inx) => {
        if (inx === 0) {
          str = str + item;
        } else {
          str = `${str}|${item}`;
        }
      })
    : (str = array);

  return str;
};

export const phoneNumberMask = (value: string, code?: string) => {
  let result = '';

  if (value[0]) {
    // result += value[0] + (value[1] || '') + (value[2] || '') + (value[3] || '');
    result += code ?? '+380';
  }
  if (value[4]) {
    result += ' (' + value[4] + (value[5] || '');
  }
  if (value[6]) {
    result += ')-' + value[6] + (value[7] || '') + (value[8] || '');
  }
  if (value[9]) {
    result += '-' + value[9] + (value[10] || '');
  }
  if (value[11]) {
    result += '-' + value[11] + (value[12] || '');
  }
  return result;
};

export const nameHelper = ['Прізвище', 'Ім’я', 'По батькові'];
export const namesInfo = t => [
  t('termination_details.surname'),
  t('termination_details.user_name'),
  t('termination_details.middleName'),
];
export const getIsTJS = (insuranceProgram: string) =>
  insuranceProgram.includes('ACCIDENT') || insuranceProgram.includes('ВЗР');

export const formatDocumentsData = (dataArr: any) =>
  dataArr.map((data: any) => ({
    obligatory: data.obligatory,
    information: '',
    signDate: '',
    receiptDate: '',
    documentType: data.documentType,
    informationObligatory: data.informationObligatory,
    visible: data.visible,
    allowMultipleFiles: data.allowMultipleFiles,
  }));

export const splitString = (str: string) => {
  const rx = /\b(?:type|degree)\s+M{0,4}(?:C[MD]|D?C{0,3})(?:X[CL]|L?X{0,3})(?:I[XV]|V?I{0,3})\b|\S+/g;
  return str.match(rx) || [];
};

export const maxTickValue = (data: any[]) => {
  if (data.length) {
    let max = data.reduce((accum, el) => Math.max(accum, el.value), data[0].value);
    return max >= 12 ? 12 : max;
  } else {
    return 10;
  }
};

export const checkOnlineStatus = (errorMessage: string) => {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    return errorMessage;
  } else {
    return 'Перевірте підключення до інтернету або зверніться до технічної підтримки';
  }
};

export const getJutloType = type => {
  switch (type) {
    case RISK_TYPE.APARTMENT:
      return 'Квартира';
    case RISK_TYPE.PRIVATE_HOUSE:
      return 'Приватний будинок';
    case RISK_TYPE.COTTAGE:
      return 'Котедж';
    case RISK_TYPE.TOWN_HOUSE:
      return 'Таунхаус';
    case 'Приватний будинок':
      return 'Жилой дом';
    case 'Котедж':
      return 'Коттедж';
    default:
      return type;
  }
};

// TODO Delte after deploy
export const getMyHomeType = type => {
  switch (type) {
    case RISK_TYPE.APARTMENT:
      return 'Квартира';
    case RISK_TYPE.PRIVATE_HOUSE:
      return 'Жилой дом';
    case RISK_TYPE.COTTAGE:
      return 'Коттедж';
    case RISK_TYPE.TOWN_HOUSE:
      return 'Таунхаус';
    default:
      return '';
  }
};

export const languages = [
  { title: 'Українська', key: 'Українська', value: Language.UA },
  { title: 'Русский', key: 'Русский', value: Language.RU },
  { title: 'Английский', key: 'Английский', value: Language.EN },
];

export const getDatePickerLng = (language: Language) => {
  const datePickerLng = {
    [Language.RU]: RU,
    [Language.EN]: EN,
    [Language.UA]: UA,
  };
  return datePickerLng[language];
};

export const dateFormat = 'DD.MM.YYYY';

export const defaultValuesOfSubdivisions = t => [
  { name: t('contract_list.everyone'), key: 'all', id: 0 },
  { name: t('contract_list.all_except_test_units'), key: 'all_except_test_units', id: 0 },
  { name: t('contract_list.test_units'), key: 'test_units', id: 0 },
];

export const defaultValuesOfAll = t => [{ name: t('contract_list.everyone'), key: 'all', id: 0 }];

export const sortCustomArray = arr => {
  let len = arr.length;
  for (let i = 0; i < len; i++) {
    if (arr[i].code === CONTRACT_TYPE.MEDICINE && len !== 1) {
      for (let j = 0; j < len; j++) {
        if (arr[j].code === CONTRACT_TYPE.MEDICINE) {
          let tmp = arr[j];
          arr[j] = arr[1];
          arr[1] = tmp;
        }
        if (arr[j].code === CONTRACT_TYPE.COVID) {
          let tmp = arr[j];
          arr[j] = arr[2];
          arr[2] = tmp;
        }
      }
    }
  }
  return arr;
};

export const validationOptionsTab = (minute, day) => {
  return minute < 999 && minute > 0 && day < 999 && day > 0;
};

export const restrictNumber = name => {
  if (name) {
    return name.replace(new RegExp(/[^\d]/, 'ig'), '');
  }
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()];
};

export const deleteDuplicatesFromList = (list: { [key: string]: any }[]) => {
  const namesList = list.map(item => item.name);
  return list.filter((item, idx) => namesList.indexOf(item.name) === idx);
};

export const getCorrectInsuranceObjectPid = (insuranceObjectPid: string, insuranceObjectName: string) => {
  return insuranceObjectPid.includes('коммуникации')
    ? `${getJutloType(insuranceObjectName)}, ${insuranceObjectPid.split('коммуникации')[1]}`
    : insuranceObjectPid;
};

export const currentCurrency = () => {
  const currentCurrency = sessionStorage.getItem('currentCurrency');
  if (currentCurrency) {
    return currentCurrency;
  } else {
    return '₴';
  }
};
