import React from 'react';
import ItemsList from '../../components/List';
import { Scrollbars } from 'react-custom-scrollbars';
import styles from './Time.module.less';
import classNames from 'classnames';
import { Time } from 'types/appointments';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';

export const formatHours = (hour: number): string => {
  const integer = Math.trunc(hour);
  const fractional = hour - integer;
  return [(integer < 10 ? '0' : '') + integer, fractional * 60 + (fractional === 0 ? '0' : '')].join(':');
};

interface TimePickerProps {
  time: Time;
  onChange: (value: Time) => void;
}

export const TimePicker: React.FC<TimePickerProps> = ({ time, onChange }) => {
  const PLANK_HEIGHT_STANDARD = 54;

  const slots = Array(18)
    .fill(9)
    .map((h, i) => h + i * 0.5);

  const handleClick = (hour: number) => {
    if (
      !time.start ||
      (time.end && hour > time.end && hour - time.end > 0.5) ||
      (time.start && hour < time.start && time.start - hour > 0.5)
    ) {
      return onChange({ start: hour, end: hour + 0.5 });
    }
    if (time.start === hour) {
      return onChange({ start: undefined, end: undefined });
    }

    onChange({
      start: hour > time.start && hour === time.end ? time.start : hour,
      end: time.end && hour >= time.end ? hour + 0.5 : time.end,
    });
  };

  const selector = (hour: number) => {
    return (
      time?.start === hour ||
      (time.start && time.end && hour - time.start === 0.5 && hour !== time.end) ||
      (time.start && time.end && hour > time.start && hour < time.end)
    );
  };

  const factorHeight = (hour: number) => {
    const factor = time.end && time.start ? (time.end - time.start) / 0.5 : 1;
    return PLANK_HEIGHT_STANDARD * (time.start === hour ? factor : 1);
  };

  return (
    <Scrollbars autoHeight autoMaxHeight={MAX_SCROLLBAR_HEIGHT}>
      <ItemsList
        itemClassName={styles.listItem}
        items={slots}
        itemBuilder={h => {
          return time.start && time.end && h > time?.start && h < time?.end ? null : (
            <div onClick={() => handleClick(h)} className={styles.item}>
              <p className={styles.hours}>{formatHours(h)}</p>
              <div
                className={classNames(styles.plank, selector(h) && styles.selected)}
                style={{
                  height: factorHeight(h),
                }}
              />
            </div>
          );
        }}
      />
    </Scrollbars>
  );
};
