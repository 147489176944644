export const parseTableData = data => {
  data.map(tableRowData => {
    if (tableRowData?.partnerDepartmentId) {
      tableRowData.partnerDepartment = {
        id: tableRowData?.partnerDepartmentId,
        name: tableRowData?.partnerDepartmentName,
      };
    }

    if (tableRowData?.partnerId) {
      tableRowData.partner = {
        id: tableRowData?.partnerId,
        name: tableRowData?.partnerName,
      };
    }

    return tableRowData;
  });
  return data;
};
