import React from 'react';

import cx from 'classnames';
import styles from './styles/index.module.less';

type GeneralProps = {
  size?: 'normal' | 'large';
  theme?: 'primary' | 'secondary' | 'third' | 'forth';
  minWidth?: 'block';
};

type Props = {} & GeneralProps &
  Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'disabled'> &
  React.HTMLAttributes<HTMLElement>;

const CustomBtn: React.FC<Props> = ({
  className,
  children,
  type = 'button',
  size = 'normal',
  theme = 'primary',
  minWidth,
  ...props
}) => {
  const classNames = cx(
    styles.Button,
    styles[size],
    styles[theme],
    minWidth ? styles[`width_${minWidth}`] : null,
    className,
  );

  return (
    <button {...props} type={type} className={classNames}>
      {children}
    </button>
  );
};

export default CustomBtn;
