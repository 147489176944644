import React from 'react';
import Typography from 'components/Typography';
import Avatar from '../Avatar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Chat } from 'types/chats';
import { AppState } from 'store/reducer';
import styles from './ChatsList.module.less';
import { useDOMSize } from '../../hooks';
import Scrollbar from '../Scrollbar';
import ChatsListItem from '../ChatsListItem';

interface Props {
  chats: Array<Chat>;
  onOpen(chat: Chat): void;
  opened?: Chat;
}

const ChatsList: React.FC<Props> = ({ chats, onOpen, opened }) => {
  const { t } = useTranslation();

  const userName = useSelector((store: AppState) => store.user.name);

  const { ref, size } = useDOMSize<HTMLDivElement>();

  const HEADER_HEIGHT = 165;

  const isOpened = (id: string) => {
    return opened?.channelId === id;
  };

  return (
    <div ref={ref} className={styles.outer}>
      <header className={styles.header}>
        <div className={styles.heading}>
          <Typography type="title">{t('chats.my_consultations')}</Typography>
        </div>
        <div className={styles.userCard}>
          <Avatar title={userName ?? ''} />
          <Typography type="p1">{userName}</Typography>
        </div>
      </header>
      {size && (
        <Scrollbar height={size.height - HEADER_HEIGHT}>
          <ul className={styles.chats}>
            {chats.map(chat => (
              <ChatsListItem {...chat} key={chat.channelId} onClick={() => onOpen(chat)} isOpened={isOpened} />
            ))}
          </ul>
        </Scrollbar>
      )}
    </div>
  );
};

export default ChatsList;
