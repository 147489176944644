import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import InputContainer, { Margin, Info, ColorBase, colorBaseMap } from '../InputContainer/InputContainer';
import { css, cx } from '@emotion/css';

const cssRoot = css`
  border-radius: 12px;
  border: none;

  :focus {
    box-shadow: none;
  }
`;

interface Props extends InputProps {
  label?: string;
  margin?: Margin;
  info?: Info;
  colorBase?: ColorBase;
}

const InputField = ({ info, label, margin, colorBase = 'grey', className, ...props }: Props) => {
  return (
    <InputContainer info={info} label={label} margin={margin} colorBase={colorBase}>
      <Input
        data-test={info?.name}
        className={cx(
          cssRoot,
          css`
            background-color: ${colorBaseMap[colorBase]};
          `,
          className,
        )}
        {...props}
      />
    </InputContainer>
  );
};

export default InputField;
