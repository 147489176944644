export const parseContractFields = (contractField: string) => {
  switch (contractField) {
    case '№ договора':
      return 'contractNumber';
    case 'Дата заключения':
      return 'conclusionDate';
    case 'Фамилия':
      return 'lastName';
    case 'Имя':
      return 'firstName';
    case 'Отчество':
      return 'middleName';
    case 'Телефон':
      return 'phoneNumber';
    case 'День рождения':
      return 'birthday';
    case 'Дата начала действия':
      return 'startDate';
    case 'Дата конца действия':
      return 'endDate';
    case 'Гражданство':
      return 'citizenship';
    case 'Застрахованное лицо 1 (ФИО)':
      return 'insuredPerson';
    case 'Email':
      return 'email';
    case 'Страховая премия':
      return 'paymentAmount';
    case 'Комиссия':
      return 'commission';
    case 'Агент':
      return 'agent';
    case 'К-во застрахованных':
      return 'objectCount';
    case 'Страховая сумма':
      return 'insuranceSum';
    case 'Номер заказа':
      return 'checkNumber';
    case 'Платёжная система':
      return 'paymentSystem';
    case 'Продукт':
      return 'insuranceProgram';
    default:
      return contractField;
  }
};

export const unParseDateFields = (contractField: string) => {
  switch (contractField) {
    case 'conclusionDate':
      return 'Дата заключения';
    case 'birthday':
      return 'День рождения';
    case 'startDate':
      return 'Дата начала действия';
    case 'endDate':
      return 'Дата конца действия';
    default:
      return contractField;
  }
};
