import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { notification } from 'antd';

import history from '../../routes/history';

import { ROUTES } from '../../constants/routes';

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

type Service =
  | 'AUTH'
  | 'CALCULATION'
  | 'LOCATIONS'
  | 'CONFIGURATION'
  | 'CONTRACTS'
  | 'CHAT'
  | 'PROXY'
  | 'INSTITUTION'
  | 'APPOINTMENT';

const baseURLMap: Record<Service, string> = {
  AUTH: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_AUTH_SERVICE}`,
  CALCULATION: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_CALCULATION_SERVICE}`,
  LOCATIONS: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_LOCATION_SERVICE}`,
  CONFIGURATION: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_CONFIGURATION_SERVICE}`,
  CONTRACTS: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_CONTRACTS_SERVICE}`,
  CHAT: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_CHATS_SERVICE}`,
  PROXY: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_PROXY_SERVICE}`,
  INSTITUTION: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_INSTITUTION_SERVICE}`,
  APPOINTMENT: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_APPOINTMENT_SERVICE}`,
};

const getAxiosInstance = (service: Service, flag?: String) => {
  switch (flag) {
    case 'pdf':
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
      });
    case 'file':
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      });
    case 'uploadFile':
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'json',
      });
    default:
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        responseType: 'json',
      });
  }
};

const createInstance = (service: Service, flag?: String) => {
  const axiosInstance = getAxiosInstance(service, flag);

  function getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  axiosInstance.interceptors.request.use(request => {
    if (getAccessToken() !== null) {
      request.headers.Authorization = `Bearer ${getAccessToken()}`;
    }
    return request;
  });

  axiosInstance.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      const originalRequest: AxiosRequestConfig = error.config;
      const statusCodeFamily = Math.floor((error.request?.status ?? 0) / 100);

      // for Network Errors
      if (statusCodeFamily === 0) {
        notification.error({ message: 'Перевірте підключення до інтернету або спробуйте пізніше' });

        return Promise.reject(undefined);
      }

      // for server errors
      if (statusCodeFamily === 5) {
        notification.error({ message: 'Зверніться до технічної підтримки' });

        return Promise.reject(undefined);
      }

      if (error.response?.status === 401 && getAccessToken() !== null && window.location.pathname !== '/login') {
        if (!isRefreshing) {
          isRefreshing = true;

          const refreshURL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_AUTH_SERVICE}/api/auth/refresh_token`;

          axios
            .get(refreshURL, { headers: { Authorization: `Bearer ${getAccessToken()}` } })
            .then(({ data }) => {
              axiosInstance.defaults.headers.Authorization = `Bearer ${data.access_token}`;
              localStorage.setItem('accessToken', data.access_token);
              onRefreshed(data.access_token);
              refreshSubscribers = [];
              isRefreshing = false;
            })
            .catch(() => {
              const code = localStorage.getItem('companyCode');
              history.push(code ? ROUTES.AUTH.LOGIN.replace(':id', `${code}`) : ROUTES.PARTNER_AUTH.LOGIN);
              isRefreshing = false;
            });
        }

        const retryOrigReq = new Promise(resolve => {
          subscribeTokenRefresh((token: string) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosInstance(originalRequest));
          });
        });

        return retryOrigReq;
      }

      return Promise.reject(error);
    },
  );

  const subscribeTokenRefresh = (cb: (token: string) => void) => {
    refreshSubscribers.push(cb);
  };

  const onRefreshed = (token: string) => {
    refreshSubscribers.map(cb => cb(token));
  };

  return axiosInstance;
};

export default createInstance;
