import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/css';

import BaseCountDown, { Props as BaseCountDownprops } from 'components/next/CountDown/CountDown';
import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';

const cssRoot = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #818588;
`;

interface Props extends BaseCountDownprops {}

const CountDown = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  return (
    <div className={cssRoot}>
      <div>{t('passwordRecovery.time_left')}</div>
      <BaseCountDown onComplete={() => history.push(ROUTES.PARTNER_AUTH.LOGIN)} {...props} />
    </div>
  );
};

export default CountDown;
