//@ts-nocheck
export const changeDocumentInfo = (arr, value, code) =>
  arr.map(document => {
    if (document.documentType.code === code) {
      return {
        ...document,
        information: value || null,
      };
    }
    return document;
  });

export const changeDocumentDates = (arr, createdDate, signDate, id) =>
  arr.map(document => {
    if (document.documentType.id === id) {
      return {
        ...document,
        receiptDate: createdDate || null,
        signDate: signDate || null,
      };
    }
    return document;
  });

export const changeUploadDocumentDates = (arr, createdDate, signDate, id) =>
  arr.map(document => {
    if (document.id === id) {
      return {
        ...document,
        receiptDate: createdDate || null,
        signDate: signDate || null,
      };
    }
    return document;
  });
