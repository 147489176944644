import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { userType } from 'types/user';
import { AnyAction } from 'redux';

const initialState: userType = {
  login: '',
  insuranceCompanyCurrency: '',
  currentCompanyCurrency: '',
  partnerDepartmentName: '',
  checkNumberPrefix: '',
  authentication: undefined,
  id: 0,
  role: undefined,
  locality: '',
  street: '',
  partnerName: '',
  managerPhoneNumber: '',
  name: '',
  phone: '',
  insuranceCompanyName: '',
};

const setCurrentUser = (state: userType, { payload }: AnyAction): userType => {
  return {
    ...state,
    login: payload ? payload.login : initialState.login,
    partnerDepartmentName: payload ? payload.partnerDepartmentName : initialState.partnerDepartmentName,
    checkNumberPrefix: payload ? payload.checkNumberPrefix : initialState.checkNumberPrefix,
    locality: payload ? payload.locality : initialState.locality,
    street: payload ? payload.street : initialState.street,
    partnerName: payload ? payload.partnerName : initialState.partnerName,
    managerPhoneNumber: payload ? payload.managerPhoneNumber : initialState.managerPhoneNumber,
    insuranceCompanyName: payload ? payload.insuranceCompanyName : initialState.insuranceCompanyName,
    ...(typeof payload === 'object' ? payload : {}),
  };
};

const deleteCurrentUser = (): userType => {
  return initialState;
};

const setAuthentication = (state: userType, { payload }: AnyAction): userType => ({
  ...state,
  authentication: payload,
});

const setCurrentCompanyCurrency = (state: userType, { payload }: AnyAction): userType => {
  return {
    ...state,
    currentCompanyCurrency: payload,
  };
};

const removeAuthentication = (state: userType): userType => ({ ...state, authentication: undefined });

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setCurrentUser,
    deleteCurrentUser,
    setAuthentication,
    removeAuthentication,
    setCurrentCompanyCurrency,
  },
});
