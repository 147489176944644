import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Modal from '../../components/Modal';
import OutsideClickHandler from 'react-outside-click-handler';
import { isEqual } from 'lodash';
import moment from 'moment';

import { IDateSlot, Picker, ITime, WithErrors, WithVariants } from 'types/appointments';
import { ISlots } from 'types/institutions';
import Previewer from 'screens/Appointments/components/Previewer';
import { useModal } from 'screens/Appointments/hooks/useModal';
import styles from './TimePicker.module.less';
import { ChevronRightIcon } from 'components/Icons';
import { Calendar } from '../../components/Calendar';
import { formatHours, TimePickerDrEleks } from '../TimePickerDrEleks';

export const DateTimeDrEleksPicker: React.FC<Picker<IDateSlot> & WithVariants<ISlots> & WithErrors> = ({
  defaultValue,
  error,
  onClose,
  variants,
  disabled,
  clearError,
}) => {
  const { t } = useTranslation();

  const { isOpen, open, close, confirm, picked, onPick } = useModal(onClose, defaultValue);

  const dates = variants.length ? variants.map(el => moment(el.timeTableStartTime).format('DD-MM-YYYY')) : [];

  const slots =
    picked?.date && variants.length
      ? variants
          .filter(el => moment(el.timeTableStartTime).format('DD-MM-YYYY') === moment(picked.date).format('DD-MM-YYYY'))
          .map(item => {
            const timeSlot = moment(item.timeTableStartTime);
            return {
              ...item,
              slot: timeSlot.minutes() === 30 ? timeSlot.hour() + 0.5 : timeSlot.hour(),
            };
          })
      : [];

  const displayValue = [
    defaultValue.date && moment(defaultValue.date).format('D MMMM'),
    [defaultValue.time?.start, defaultValue.time?.end]
      .filter(Boolean)
      .map(formatHours)
      .join(' - '),
  ]
    .filter(Boolean)
    .join(' ');

  const [isCalendarOpened, setIsCalendarOpened] = useState(false);

  const handleDateChange = (date: Date) => {
    onPick({
      time: picked?.time ?? {},
      date,
      timeTableEndTime: picked?.timeTableEndTime ?? '',
      timeTableStartTime: picked?.timeTableStartTime ?? '',
      userRef: picked?.userRef ?? '',
      venueRef: picked?.venueRef ?? -1,
    });
    setIsCalendarOpened(false);
  };

  const handleTimeChange = (time: ITime) => {
    onPick({
      ...time,
      date: picked?.date,
    });
  };

  const onSubmit = () => {
    clearError('dateSlot');
    clearError('dateSlotDrEleks');
    confirm();
  };

  return (
    <>
      <Previewer
        displayValue={displayValue}
        onClick={open}
        title={displayValue || error ? t('appointments.datetime_title') : t('appointments.selector_datetime')}
        disabled={disabled}
        errorMessage={error ? t('appointments.selector_datetime') : ''}
      />
      {isOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              onPick(defaultValue);
              setIsCalendarOpened(false);
              close();
            }}
          >
            <Modal.Header
              title={t('appointments.datetime_title')}
              renderArrow={isCalendarOpened}
              onArrowClick={() => setIsCalendarOpened(false)}
              onClose={() => {
                onPick(defaultValue);
                setIsCalendarOpened(false);
                close();
              }}
            />
            <Modal.Body className={styles.modalBody}>
              {isCalendarOpened ? (
                <Calendar date={picked?.date ?? new Date()} dates={dates} onChange={handleDateChange} />
              ) : (
                <>
                  <section
                    onClick={() => setIsCalendarOpened(true)}
                    className={classNames(styles.section, styles.datePicker)}
                    role="presentation"
                  >
                    <span>{t('appointments.date')}</span>
                    <div>
                      <span className={classNames(styles.value, picked?.date && styles.filled)}>
                        {picked?.date ? moment(picked.date).format('DD MMMM') : t('appointments.selector_date')}
                      </span>
                      <ChevronRightIcon className={styles.chevronRight} />
                    </div>
                  </section>
                  <section className={styles.section}>
                    <span>{t('appointments.time')}</span>
                    <span className={classNames(styles.value, picked?.time?.start && styles.filled)}>
                      {picked?.time?.start
                        ? [picked.time?.start, picked.time?.end].map(formatHours).join(' - ')
                        : t('appointments.selector_time')}
                    </span>
                  </section>
                  <TimePickerDrEleks time={picked?.time ?? {}} slots={slots} onChange={handleTimeChange} />
                </>
              )}
            </Modal.Body>
            {!isCalendarOpened && <Modal.Confirm disabled={isEqual(picked, defaultValue)} onClick={onSubmit} />}
          </OutsideClickHandler>
        </Modal>
      )}
    </>
  );
};
