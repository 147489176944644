import React from 'react';
import ReactCalendar, { ViewCallbackProperties } from 'react-calendar';
import moment from 'moment';

import { SubtractMonthIcon, SubtractYearIcon, AddMonthIcon, AddYearIcon } from 'components/Icons';

import 'react-calendar/dist/Calendar.css';
import './override.less';

interface CalendarProps {
  viewDate: Date;
  highlightedDates: string[] | null;
  setViewDate: (date: Date) => void;
  onMonthChange: (action: ViewCallbackProperties) => void;
}

const Calendar: React.FC<CalendarProps> = ({ viewDate, highlightedDates, setViewDate, onMonthChange }) => {
  return (
    <>
      <div className="archive-calendar-wrapper">
        <ReactCalendar
          value={viewDate}
          onChange={setViewDate}
          // showFixedNumberOfWeeks={6}
          view="month"
          prevLabel={<SubtractMonthIcon />}
          nextLabel={<AddMonthIcon />}
          formatMonthYear={(_: string, date: Date) => moment(date).format('MMMM YYYY')}
          formatShortWeekday={(_: string, date: Date) => moment(date).format('dd')}
          tileClassName={({ date }) => {
            if (highlightedDates && highlightedDates.find(d => d === moment(date).format('DD-MM-YYYY'))) {
              return 'highlight';
            } else {
              return '';
            }
          }}
          prev2Label={<SubtractYearIcon />}
          next2Label={<AddYearIcon />}
          onActiveStartDateChange={action => onMonthChange(action)}
        />
      </div>
    </>
  );
};

export default Calendar;
